import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  LOGOUT,
  CLEAR_TOKEN,
  CLEAR_PROFILE,
  LOGIN,
  SET_AUTHENTICATE,
  LOAD_TOKEN,
  LOAD_PROFILE,
  UPDATE_PROFILE,
  LOAD_SESSION_ID,
  CLEAR_SESSION_ID,
  CHECK_USER_PREMIUM,
} from './constants';
import { loadProfile, loadToken } from './actions';
import { makeSelectProfile } from './selectors';
import { makeSelectCurrentLang } from '../MetadataProvider/selectors';
import * as authService from '~/services/auth';
import Cookies from '~/services/Cookies';
import Config, { keyConfig } from '~/config';
import { USER_ROLES } from '~/utils/constants';

function* logout() {
  const profile = yield select(makeSelectProfile());
  const currentLang = yield select(makeSelectCurrentLang());

  yield put({ type: CLEAR_TOKEN });
  yield put({ type: CLEAR_PROFILE });
  yield put({ type: SET_AUTHENTICATE, isAuthenticate: false });
  Cookies.remove('token');
  Cookies.remove('profile');

  if (profile.role !== USER_ROLES.CREATOR && process.browser) {
    let sessionId = Cookies.get('sessionId');
    const ssoRedirectURL = `${Config.getSiteURL()}`;
    window.location.href = `${
      Config.ssoServerUrl
    }/logout?sessionID=${sessionId}&serviceURL=${encodeURIComponent(
      ssoRedirectURL,
    )}`;
    Cookies.remove('sessionId');
  }
}

function* login(params) {
  console.log('loginnnnnnnn', params);
  const { token, profile } = params;

  Cookies.set('token', token);
  Cookies.set('profile', profile);
  Cookies.set('accountType', USER_ROLES.CREATOR);

  yield put(loadToken(token));
  yield put(loadProfile(profile));
  yield put({ type: SET_AUTHENTICATE, isAuthenticate: true });
}

function loadTokenToCookie(action) {
  Cookies.set('token', action.token);
}

function* loadProfileToCookie(action) {
  Cookies.set('profile', action.profile);

  const response = yield call(authService.checkPremiumUser);

  console.log('loadProfileToCookieloadProfileToCookie', response);

  yield put({
    type: CHECK_USER_PREMIUM,
    checkPremium: response,
  });
}

function loadSessionIdToCookies(action) {
  Cookies.set('sessionId', action.sessionId);
}

export function* updateProfile(params) {
  console.log('updateProfileeeeeeeeeeee', params);
  const response = yield call(authService.getProfile, params);
  yield put({
    type: LOAD_PROFILE,
    profile: response.profile,
  });
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* auth() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(LOGOUT, logout);
  yield takeLatest(LOAD_TOKEN, loadTokenToCookie);
  yield takeLatest(LOAD_PROFILE, loadProfileToCookie);
  yield takeLatest(UPDATE_PROFILE, updateProfile);
  yield takeLatest(LOAD_SESSION_ID, loadSessionIdToCookies);
}
