import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Image from './Image';

@connect(
  createStructuredSelector({
    profile: (state) => state.auth.profile,
  }),
)
class GridGallery extends Component {
  constructor(props) {
    super(props);
    const { images } = this.props;
    this.state = {
      images,
      thumbnails: this.renderThumbs(this.getClientWidth(), images),
      containerWidth: 0,
    };

    this.onResize = this.onResize.bind(this);
    this._onResize = debounce(this.onResize, 200);
    this.emptyImages = [0, 1, 2, 4, 5, 6, 7, 8, 9]; // max number images in a row is 10
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this._onResize);
  }

  /* eslint-disable-next-line */
  componentWillReceiveProps(np) {
    const { images } = this.state;
    const { maxRows } = this.props;
    if (images !== np.images || maxRows !== np.maxRows) {
      this.setState({
        images: np.images,
        thumbnails: this.renderThumbs(this.getClientWidth(), np.images),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('resize', this._onResize);
  }

  onResize() {
    if (!this.galleryRef) return;
    const clientWidth = this.getClientWidth();
    this.setState({
      containerWidth: clientWidth,
      thumbnails: this.renderThumbs(clientWidth),
    });
  }

  getClientWidth() {
    if (this.galleryRef) {
      return Math.floor(this.galleryRef.clientWidth) - 1;
    }
    return 1440;
  }

  setThumbScale(item) {
    let ratio = 1;
    if (item.meta_data) {
      ratio = item.meta_data.width / item.meta_data.height;
    }
    item.scaleWidth = Math.floor(this.props.rowHeight * ratio);
  }

  buildImageRow = (items, containerWidth) => {
    const { margin } = this.props;
    const row = [];
    let len = 0;
    const imgMargin = 2 * margin;
    while (items.length > 0 && len < containerWidth) {
      const item = items.shift();
      row.push(item);
      len += item.scaleWidth + imgMargin;
    }

    for (const j in row) {
      const item = row[j];
      item.marginLeft = 0;
      item.vwidth = item.scaleWidth;
    }
    return row;
  };

  renderThumbs = (containerWidth, images = this.state.images) => {
    if (!images) return [];
    if (containerWidth === 0) return [];
    const items = images.slice();
    for (const t in items) {
      this.setThumbScale(items[t]);
    }

    const thumbs = [];
    const rows = [];
    while (items.length > 0) {
      rows.push(this.buildImageRow(items, containerWidth));
    }

    for (const r in rows) {
      for (const i in rows[r]) {
        const item = rows[r][i];
        if (this.props.maxRows) {
          if (r < this.props.maxRows) {
            thumbs.push(item);
          }
        } else {
          thumbs.push(item);
        }
      }
    }
    return thumbs;
  };

  generateTitle = (item) => {
    let title = item.title || {};

    if (typeof item.title === 'string') {
      try {
        title = JSON.parse(item.title);
      } catch (e) {
        title = {};
      }
    }

    return title;
  };

  render() {
    const { showImageActions } = this.props;

    return (
      <div className="gallery-container">
        <div
          className="gallery-images"
          id={this.props.id}
          ref={(c) => {
            this.galleryRef = c;
          }}
          style={{ justifyContent: 'center' }}
        >
          {this.state.thumbnails.map((item, idx) => (
            <Image
              key={item._id}
              item={item}
              index={item._id}
              margin={this.props.margin}
              width={this.props.colWidth}
              vwidth={item.vwidth}
              height={this.props.rowHeight}
              role={this.props.profile.role}
              premiumOnly={!!item.premium_only}
              forcePremium={this.props.forcePremium}
              showActions={showImageActions}
              api={this.props.api}
            />
          ))}
          {this.emptyImages.map((item, idx) => (
            <div
              key={idx}
              className="empty-image"
              style={{
                width: this.props.colWidth,
                margin: this.props.margin,
                WebkitUserSelect: 'none',
                position: 'relative',
                float: 'left',
                cursor: 'pointer',
                background: '#eee',
                padding: '0px',
              }}
            />
          ))}
          <div style={{ clear: 'both' }} />
        </div>
        {/* <noscript>
          {this.state.images.map((item, idx) => {
            const title = this.generateTitle(item);
            return (
              <img
                src={item.thumbnail}
                key={item.thumbnail}
                title={
                  title[
                    `title_${locales.convertToLongType(
                      this.props.currentLang,
                    )}`
                  ]
                }
                alt={
                  title[
                    `title_${locales.convertToLongType(
                      this.props.currentLang,
                    )}`
                  ]
                }
              />
            );
          })}
        </noscript> */}
        <style jsx global>
          {`
            .gallery-images {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
            }
            .gallery-images .empty-image {
              height: 0;
              padding-top: 0;
              padding-bottom: 0;
              margin-top: 0;
              margin-bottom: 0;
            }
            .gallery-images .gallery-image-container {
              position: relative;
              float: left;
              cursor: pointer;
              background: #eee;
              padding: 0px;
              user-select: none;
              -webkit-user-select: none;
              border-radius: 2px;
            }
            .gallery-images .gallery-ads-container {
              background-color: #f2f3f4;
              width: 100%;
              text-align: center;
            }
            .gallery-images .gallery-image-hover {
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              transition: 0.2s;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-radius: 2px;
              overflow: hidden;
            }
            .gallery-images .gallery-image-hover .image-actions-container {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              padding: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .image-actions {
              min-width: 60px;
              max-width: 100px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .action-button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 30px;
              margin-top: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .number-nices {
              padding-left: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .dropdown-menu__ac {
              width: 100%;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-arrow-left {
              border-width: 5px;
              border-right-width: 0;
              left: -5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-wrapper-left {
              padding-right: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-wrapper {
              right: 100%;
              bottom: 0;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-wrapper
              .submenu {
              min-width: fit-content;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .dropdown-left-options {
              display: flex;
              flex-direction: row;
            }
            .gallery-images
              .gallery-image-hover
              .button-social-share
              .facebook {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              color: #3b5998;
            }
            .gallery-images .gallery-image-hover .button-social-share .twitter {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              color: #1da1f2;
              border-right-width: 0;
            }
            .gallery-images
              .gallery-image-hover
              .button-social-share
              .social-network-share {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
            }
            .gallery-images
              .gallery-image-hover
              .button-social-share
              .social-network-share:hover {
              color: #fff;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-premium
              .asset-option {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 30px;
              border-radius: 0px;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-premium
              .asset-option:first-child {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-premium
              .asset-option:last-child {
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              border-right-width: 0;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-free
              .submenu-wrapper-left {
              top: calc(50% - 30px);
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-free
              .premium-hint-assets {
              min-width: 150px;
              min-height: 50px;
              font-size: 12px;
              text-align: center;
              padding: 7px;
            }
            .gallery-images .gallery-title {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              overflow: hidden;
            }
            .gallery-images .gallery-title .image-title {
              padding: 5px 10px;
            }
            .gallery-images .gallery-title .image-info {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              background: #1b2833;
              opacity: 0.5;
              color: white;
              padding: 5px 10px;
              overflow: hidden;
            }
            .gallery-images .gallery-title .image-info .creator-info {
              flex: 1;
              flex-wrap: wrap;
              color: #fff;
            }
            .gallery-images
              .gallery-title
              .image-info
              .creator-info
              .creator-name,
            .gallery-images
              .gallery-title
              .image-info
              .creator-info
              .link-creator-name {
              font-size: 14px;
            }
            .gallery-images
              .gallery-title
              .image-info
              .creator-info
              .link-creator-name:hover {
              text-decoration: underline;
            }
            .gallery-images .gallery-title .image-info .creator-avatar {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
            .gallery-images .gallery-title .image-info .number-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 15px;
            }
            .gallery-images .gallery-title .image-info .number-icon {
              margin-right: 5px;
            }
            .gallery-images .gallery-image {
              color: #fff;
              flex-direction: column-reverse;
              align-content: center;
              border-radius: 2px;
            }
            .gallery-images .gallery-image:hover .gallery-image-hover {
              transition: 0.3s;
              opacity: 1;
              background-color: rgba(0, 0, 0, 0.4);
            }
          `}
        </style>
      </div>
    );
  }
}

GridGallery.propTypes = {
  images: PropTypes.array.isRequired,
  id: PropTypes.string,
  currentLang: PropTypes.string,
  maxRows: PropTypes.number,
  margin: PropTypes.number,
  lastRowHide: PropTypes.bool,
  colWidth: PropTypes.string || PropTypes.number,
  rowHeight: PropTypes.number,
  forcePremium: PropTypes.bool,
};

GridGallery.defaultProps = {
  id: 'ReactGridGallery',
  margin: 5,
  lastRowHide: false,
  forcePremium: false,
};

export default GridGallery;
