export const LOAD_COLLECTIONS = 'containers/CollectionPage/LOAD_COLLECTIONS';
export const LOAD_COLLECTIONS_SUCCESS =
  'containers/CollectionPage/LOAD_COLLECTIONS_SUCCESS';
export const LOAD_COLLECTIONS_ERROR =
  'containers/CollectionPage/LOAD_COLLECTIONS_ERROR';
export const CHANGE_PAGE = 'containers/CategoryPage/CHANGE_PAGE';

export const LOAD_COLLECTIONS_OF_MONTHS =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_MONTHS';
export const LOAD_COLLECTIONS_OF_MONTHS_SUCCESS =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_MONTHS_SUCCESS';
export const LOAD_COLLECTIONS_OF_MONTHS_ERROR =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_MONTHS_ERROR';
