import Router from 'next/router';
import { i18n } from 'next-i18next';
import Config from '~/config';
import { supportedLanguages } from '~/../next-i18next.config';
import { locales } from '~/utils';

function push(href, asPath, options = {}) {
  const { useI18n = true, host, ...routerOptions } = options;
  let asLink;
  const i18nRegex = new RegExp(`^/(${supportedLanguages.join('|')})/`);
  const isLocaleInPath = supportedLanguages.some((lng) =>
    location.pathname.includes(`/${lng}`),
  );
  if (Config.isChina()) {
    asLink = asPath || href;
    if (i18nRegex.test(asLink)) asLink = asLink.substring(3);
  } else {
    asLink = asPath || href;
    if (useI18n) {
      let rawAsLink = '';
      if (asPath) {
        rawAsLink = asPath;
      } else {
        rawAsLink = href.pathname || href;
      }
      if (i18nRegex.test(rawAsLink)) rawAsLink = rawAsLink.substring(3);

      asLink = `${
        rawAsLink.startsWith('/') ? `${rawAsLink}` : `/${rawAsLink}`
      }`;
      if (isLocaleInPath) {
        asLink = `/${locales.convertToShortType(i18n.language)}${asLink}`;
        if (typeof href === 'string') {
          href = `/${locales.convertToShortType(i18n.language)}${href}`;
        }
      }
    }
  }

  if (host) {
    return Router.push(`${host}${href}`, `${host}${asLink}`, routerOptions);
  }
  Router.push(href, asLink, routerOptions);
}

export default { push };
