import { combineReducers } from 'redux';
// import Immutable from 'seamless-immutable';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  MODAL_SUBMIT,
  LOAD_PRICE_PREMIUM,
  LOAD_PRICE_PREMIUM_SUCCESS,
  LOAD_PRICE_PREMIUM_ERROR,
  LOAD_SINGLE_PRICE_PREMIUM,
  LOAD_SINGLE_PRICE_PREMIUM_SUCCESS,
  LOAD_SINGLE_PRICE_PREMIUM_ERROR,
  SELECTED_PRICE_PREMIUM,
  SHOW_LOADING_FULL_SCREEN,
  SHOW_LOADING_FULL_SCREEN_WECHAT,
  SHOW_LOADING_FULL_SCREEN_CARD,
  HIDE_LOADING_FULL_SCREEN,
  UPDATE_COUNTDOWN_SUCCESS,
  CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_FAILED,
  CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_SUCCESS,
  SELECTED_SINGLE_PRICE_PREMIUM,
  INIT_POLLING_PAYMENT_INTENT,
  STOP_POLLING_PAYMENT_INTENT,
  INIT_COUNTDOWN_SUCCESS,
} from './constants';

const initialState = {
  showModal: false,
};

function paymentModalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        showModal: true,
      };

    case MODAL_SUBMIT:
    case CLOSE_MODAL:
      return {
        showModal: false,
      };

    default:
      return state;
  }
}

const initialStatePremium = {
  prices: {},
  priceSelected: '',
  isSale: false,
  trial: [],
  saleDate: {},
  error: false,
  loading: false,
  end_date: '',
  start_date: '',
  isTrial: false,
  trialEndDate: '',
  coupon: {},
  countdownDiscount: null,
};

function premiumReducer(state = initialStatePremium, action) {
  switch (action.type) {
    case LOAD_PRICE_PREMIUM:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_PRICE_PREMIUM_SUCCESS:
      Object.keys(action.prices).map((key) => {
        const item = action.prices[key];
        item.active =
          item.plan === action.priceSelected ||
          Object.keys(action.prices).length === 1;
      });
      return {
        ...state,
        loading: false,
        prices: action.prices,
        isSale: action.isSale,
        saleDate: action.saleDate,
        trial: action.trial,
        end_date: action.end_date,
        start_date: action.start_date,
        priceSelected: action.priceSelected,
        isTrial: action.isTrial,
        trialEndDate: action.trialEndDate,
        // coupon: action.coupon,
        // countdownDiscount: action.countdownDiscount,
      };

    case LOAD_PRICE_PREMIUM_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case SELECTED_PRICE_PREMIUM: {
      const prices = { ...state.prices };
      let priceSelected = '';
      Object.keys(prices).map((key) => {
        const item = prices[key];

        if (item.plan === action.plan) {
          item.active = true;
        } else {
          item.active = false;
        }
        if (item.active) {
          priceSelected = item.plan;
        }
      });
      return {
        ...state,
        priceSelected,
        prices,
      };
    }
    case INIT_COUNTDOWN_SUCCESS:
      return {
        ...state,
        countdownDiscount: {
          ...state.countdownDiscount,
          remainingDurationInMilliseconds:
            action.remainingDurationInMilliseconds,
          typeCountdown: action.typeCountdown,
          isActive: action.isActive,
          coupon: action.coupon,
        },
      };
    case UPDATE_COUNTDOWN_SUCCESS: {
      const { remainingDurationInMilliseconds, isActive } = action;
      return {
        ...state,
        countdownDiscount: {
          ...state.countdownDiscount,
          remainingDurationInMilliseconds,
          isActive,
        },
      };
    }
    default:
      return state;
  }
}

const initialStateSinglePremium = {
  prices: {},
  priceSelected: {},
  error: false,
  loading: false,
  paymentIntent: null,
  pollingPaymentIntent: null,
};

function singlePremiumReducer(state = initialStateSinglePremium, action) {
  switch (action.type) {
    case LOAD_SINGLE_PRICE_PREMIUM:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LOAD_SINGLE_PRICE_PREMIUM_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case LOAD_SINGLE_PRICE_PREMIUM_SUCCESS:
      Object.keys(action.prices).map((key) => {
        const item = action.prices[key];
        item.active =
          item.type === action.priceSelected.type ||
          Object.keys(action.prices).length === 1;
      });
      return {
        ...state,
        loading: false,
        prices: action.prices,
        priceSelected: action.priceSelected,
      };

    case SELECTED_SINGLE_PRICE_PREMIUM: {
      const prices = { ...state.prices };
      let priceSelected = '';
      Object.keys(prices).map((key) => {
        const item = prices[key];
        if (item.type === action.plan.type) {
          item.active = true;
        } else {
          item.active = false;
        }
        if (item.active) {
          priceSelected = item;
        }
      });
      return {
        ...state,
        priceSelected,
        prices,
      };
    }
    case CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_SUCCESS:
      return {
        ...state,
        paymentIntent: action.paymentIntent,
      };

    case INIT_POLLING_PAYMENT_INTENT:
      return {
        ...state,
        pollingPaymentIntent: action.pollingPaymentIntent,
      };

    case STOP_POLLING_PAYMENT_INTENT:
      clearInterval(state.pollingPaymentIntent);
      return {
        ...state,
        pollingPaymentIntent: null,
      };

    default:
      return state;
  }
}

export const initialStateLoad = {
  fullScreen: false,
  message: '',
  wechat: {},
  currenPaymentMethod: 'card',
};

function loadDisplay(state = initialStateLoad, action) {
  switch (action.type) {
    case SHOW_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: true,
      };
    case SHOW_LOADING_FULL_SCREEN_CARD:
      return {
        ...state,
        fullScreen: true,
        currentPaymentMethod: 'card',
      };
    case SHOW_LOADING_FULL_SCREEN_WECHAT:
      return {
        ...state,
        fullScreen: true,
        wechat: action.wechat,
        currentPaymentMethod: 'wechat',
      };
    case HIDE_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  result: premiumReducer,
  singlePremium: singlePremiumReducer,
  load: loadDisplay,
  modal: paymentModalReducer,
});
