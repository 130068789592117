import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import Config from '~/config';
import * as UploaderService from '~/services/my-page/uploadFile';
import { withI18next } from '~/../hocs/_withI18next';

@withI18next()
class InputFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableClick: false,
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { t } = this.props;
    if (rejectedFiles.length) {
      toast.error(
        t('Status'),
        t('this_file_type_is_not_accepted', {
          types: this.props.types.join('、'),
        }),
      );
    }
    if (!acceptedFiles.length) {
      return;
    }
    this.setState({ disableClick: true }, () => {
      toast.success(t('Status'), t('Uploading'));
      UploaderService.uploadFile(acceptedFiles[0])
        .then((fileUrl) => {
          this.setState({ disableClick: false });
          this.props.onChange({
            target: { value: fileUrl, file: acceptedFiles[0] },
          });
          toast.success(t('Status'), t('Upload success'), {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        })
        .catch(() => {
          toast.error(t('Status'), t('Upload failed, please try again later'));
          this.setState({ disableClick: false });
        });
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col-xs-7 col-sm-8">
          <input
            className="form-control mypage-input"
            type={this.props.type}
            value={this.props.value}
            disabled={this.state.disableClick}
            placeholder={this.props.placeholder}
            readOnly={true}
          />
        </div>
        <div className="col-xs-5 col-sm-4">
          <Dropzone
            className="btn mypage-btn-primary btn-up btn-block"
            multiple={this.props.multiple}
            accept={this.props.accept}
            disableClick={this.state.disableClick}
            onDrop={this.onDrop}
            minSize={this.props.minSize}
            maxSize={this.props.maxSize}
          >
            <i
              className={`fa ${
                this.state.disableClick ? 'fa-spinner fa-pulse fa-w' : ''
              }`}
            />
            &nbsp;
            {t('Choice')}
          </Dropzone>
        </div>
      </div>
    );
  }
}

InputFile.defaultProps = {
  type: 'url',
  types: [],
  value: '',
  accept: 'application/photoshop,image/jpeg,image/png',
  minSize: 0,
  maxSize: 100 * 1024 * 1024,
  multiple: false,
  onChange: () => ({}),
  uploadFor: 'data',
};

export default InputFile;
