import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/lib/Modal';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import classNames from 'clsx';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import { makeSelectCurrentLanguage } from '~/containers/Language/selectors';

@withI18next()
class ErrorMessageModal extends React.PureComponent {
  render() {
    const {
      t,
      modal,
      title,
      description,
      currentLang,
      switchAccount,
      handleClose,
      onLogout,
    } = this.props;
    const modalClassName = classNames(
      'member-login-modal',
      `${Config.getPrefixContent('text-color')}`,
    );

    return (
      <Modal
        bsSize="sm"
        show={modal}
        onHide={handleClose}
        className={modalClassName}
      >
        <Modal.Body>
          <div className="error-message-modal">
            <FontAwesomeIcon
              style={{ width: 50, color: 'rgb(252, 108, 110)' }}
              icon={faExclamationCircle}
              fixedWidth
            />
            <div
              style={{ fontSize: '16px' }}
              className="error-message-title"
              dangerouslySetInnerHTML={{
                __html: this.props.t(`${title}`, {
                  currentLanguage: currentLang,
                }),
              }}
            />
            <div
              className="error-message-description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            {switchAccount && (
              <div className="pt20">
                <button
                  onClick={onLogout}
                  className={`btn btn-ac-primary btn-${Config.serviceType} btn-subcribe`}
                >
                  {t('Logout')}
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
        <style jsx>
          {`
            .error-message-modal {
              width: 100%;
              min-width: 400px;
              background-color: #fff;
              border: 1px solid #eff0f1;
              border-radius: 2px;
              padding: 24px;
              text-align: center;
            }
            .error-message-modal .error-message-title {
              color: #6e6e70;
              font-size: 20px;
              font-weight: 500;
              padding-bottom: 10px;
              padding-top: 10px;
            }
            .error-message-modal .error-message-description {
              color: #6e6e70;
            }
          `}
        </style>
      </Modal>
    );
  }
}

ErrorMessageModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  modal: PropTypes.bool,
  switchAccount: PropTypes.bool,
  handleClose: PropTypes.func,
};

ErrorMessageModal.defaultProps = {
  title: '',
  description: '',
  modal: false,
};
const mapStateToProps = createStructuredSelector({
  currentLang: makeSelectCurrentLanguage(),
});
export default connect(mapStateToProps)(ErrorMessageModal);
