import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import withI18next from '~/../hocs/_withI18next';
import config from '~/config';
import FontIcomoonIcon from '~/components/FontIcomoonIcon';
import DropdownMenu from '~/components/DropdownMenu';
import CircleCloseIcon from '~/components/svg/circle-close.svg';
import IconSearch from '~/components/svg/icon-search-download-history.svg';
import IconExpand from '~/components/svg/icon-expand-download-history.svg';
import IconReset from '~/components/svg/icon-reset-download-history.svg';
import CheckBox from '~/components/CheckBoxInput';
import Config from '~/config';

@withI18next()
class FilterData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        status: '',
        fileType: '',
        sortByPage: 'createdAt.desc',
        itemPerPage: 10,
        keyword: '',
        hasExtraLicense: false,
      },
    };
  }

  handleChangeInput(name, e) {
    console.log('vo trong roi', name, e);
    // const value =
    //   e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (name === 'hasExtraLicense') {
      const filter = Object.assign({}, this.state.filter, {
        hasExtraLicense: !this.state.filter.hasExtraLicense,
      });
      this.setState({ filter }, () => this.handleFilter(filter));
    } else {
      if (name === 'sortByPage') {
        const filter = Object.assign({}, this.state.filter, {
          [name]: e === this.state.filter[name] ? 'createdAt.desc' : e,
        });
        this.setState({ filter }, () => this.handleFilter(filter));
      } else {
        const filter = Object.assign({}, this.state.filter, {
          [name]: e === this.state.filter[name] ? '' : e,
        });
        this.setState({ filter }, () => this.handleFilter(filter));
      }
    }
  }

  handleChangeKeyword(e) {
    this.setState({
      filter: Object.assign({}, this.state.filter, { keyword: e.target.value }),
    });
  }

  handleSubmit(e, value) {
    e.preventDefault();
    if (value === 'reset') {
      this.handleFilter(Object.assign({}, this.state.filter, { keyword: '' }));
    } else {
      this.handleFilter(Object.assign({}, this.state.filter));
    }
  }

  handleFilter(filter) {
    const { sortByPage, ...filterParams } = filter;
    const sortParams = sortByPage.split('.');
    return this.props.triggerEventChangeFilter({
      ...filterParams,
      [sortParams[0]]: sortParams[1],
    });
  }

  renderFileType() {
    const { t } = this.props;
    if (config.serviceType === 'photo_ac') {
      return (
        <div className="list-file">
          <CheckBox
            selected={this.state.filter.fileType === ''}
            setSelected={this.handleChangeInput.bind(this, 'fileType')}
            text={t('All')}
            value=""
          />
          <CheckBox
            selected={this.state.filter.fileType === 'psd'}
            setSelected={this.handleChangeInput.bind(this, 'fileType')}
            text={'psd'}
            value="psd"
          />
          <CheckBox
            selected={this.state.filter.fileType === 'jpeg'}
            setSelected={this.handleChangeInput.bind(this, 'fileType')}
            text={'jpeg'}
            value="jpeg"
          />
        </div>
      );
    }
    if (config.serviceType === 'design_ac') {
      return (
        <div>
          <CheckBox
            selected={this.state.filter.fileType === ''}
            setSelected={this.handleChangeInput.bind(this, 'fileType')}
            text={t('All')}
            value=""
          />
        </div>
      );
    }
    return (
      <div className="list-file">
        <CheckBox
          selected={this.state.filter.fileType === ''}
          setSelected={this.handleChangeInput.bind(this, 'fileType')}
          text={t('All')}
          value=""
        />
        <CheckBox
          selected={this.state.filter.fileType === 'jpg'}
          setSelected={this.handleChangeInput.bind(this, 'fileType')}
          text={'JPG'}
          value="jpg"
        />
        <CheckBox
          selected={this.state.filter.fileType === 'png'}
          setSelected={this.handleChangeInput.bind(this, 'fileType')}
          text={'PNG'}
          value="png"
        />
        <CheckBox
          selected={this.state.filter.fileType === 'ai'}
          setSelected={this.handleChangeInput.bind(this, 'fileType')}
          text={'AI/EPS'}
          value="ai"
        />
        {Config.isSilhouetteAC() && (
          <CheckBox
            selected={this.state.filter.fileType === 'svg'}
            setSelected={this.handleChangeInput.bind(this, 'fileType')}
            text={'SVG'}
            value="svg"
          />
        )}
      </div>
    );
  }
  renderIconSetting = (t) => {
    return (
      <div className="header-filter">
        <div className="text-header">{t('sort_filter_history_download')}</div>
        <div className="icon-header-filter">
          {/* <FontAwesomeIcon icon={faCog} style={{ width: '1em' }} /> */}
          <IconExpand />
        </div>
        <style jsx>
          {`
            .header-filter {
              width: 214px;
              height: 44px;
              border: 1px solid #000000;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 16px;
            }
          `}
        </style>
      </div>
    );
  };
  render() {
    const { t, lng } = this.props;
    return (
      <div className="container-filter">
        <div className="container-keyword" style={{ padding: 0 }}>
          <form
            onSubmit={this.handleSubmit.bind(this)}
            className="input-group-keyword"
          >
            <span
              className="input-group-btn"
              onClick={this.handleSubmit.bind(this)}
            >
              <IconSearch />
            </span>
            <input
              type="text"
              style={{ height: 'auto' }}
              className="input-keyword"
              value={this.state.filter.keyword}
              placeholder={this.props.placeholder || t('Property name')}
              onChange={this.handleChangeKeyword.bind(this)}
            />
            {this.state.filter.keyword.length > 0 && (
              <div
                className="icon-clear-keyword-container"
                onClick={(e) => {
                  this.setState({
                    filter: Object.assign({}, this.state.filter, {
                      keyword: '',
                    }),
                  });
                  this.handleSubmit(e, 'reset');
                }}
              >
                <IconReset />
              </div>
            )}
          </form>
        </div>
        <div className="container-filter-sort">
          <DropdownMenu
            className="config"
            pullRight
            id="control-number-filter"
            header={this.renderIconSetting(t)}
            position="bottom"
          >
            <div className="menu-list">
              <ul className="list-unstyled">
                <div className="list-type">
                  <CheckBox
                    value="createdAt.desc"
                    selected={this.state.filter.sortByPage === 'createdAt.desc'}
                    setSelected={this.handleChangeInput.bind(
                      this,
                      'sortByPage',
                    )}
                    text={'Newest to oldest'}
                  />
                  <CheckBox
                    value="createdAt.asc"
                    selected={this.state.filter.sortByPage === 'createdAt.asc'}
                    setSelected={this.handleChangeInput.bind(
                      this,
                      'sortByPage',
                    )}
                    text={'Odest to newest'}
                  />
                </div>

                <div className="container-type">{this.renderFileType()}</div>
                <div className="container-type">
                  <CheckBox
                    value={this.state.filter.hasExtraLicense}
                    selected={this.state.filter.hasExtraLicense}
                    setSelected={this.handleChangeInput.bind(
                      this,
                      'hasExtraLicense',
                    )}
                    text={t('only_extra_license')}
                  />
                  {/* <label
                    className="filter-normal-label"
                    htmlFor="hasExtraLicense"
                  >
                    <input
                      className="input-extra-license"
                      id="hasExtraLicense"
                      name="hasExtraLicense"
                      type="checkbox"
                      checked={this.state.filter.hasExtraLicense}
                      onChange={this.handleChangeInput.bind(
                        this,
                        'hasExtraLicense',
                      )}
                    />
                    {t('only_extra_license')}
                  </label> */}
                </div>
              </ul>
            </div>
          </DropdownMenu>
        </div>
        <style jsx>
          {`
            .input-group {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: nowrap;
            }
            .input-group-keyword {
              display: flex;
              border: 1px solid #1e242a;
              border-radius: 4px;
              height: 44px;
            }
            .input-keyword {
              flex-grow: 1;
              border: none;
              margin: 0 4px;
            }
            .input-group-btn {
              width: 30px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .icon-clear-keyword-container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              cursor: pointer;
            }
            .container-filter {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
            @media (max-width: 1200px) {
              .input-group {
                flex-direction: column;
              }
              .container-filter {
                display: flex;
                flex-direction: column;
                gap: 24px;
              }
              .container-filter-sort {
                display: flex;
                justify-content: flex-end;
              }
            }
          `}
        </style>
        <style jsx global>{`
          .submenu {
            padding: 12px;
          }
          .list-type,
          .list-file {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          .list-unstyled {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        `}</style>
      </div>
    );
  }
}

FilterData.defaultProps = {
  minWidth: '380px',
  placeholder: '',
  triggerEventChangeFilter: () => ({}),
  preNextEvent: false,
};
FilterData.propTypes = {
  minWidth: PropTypes.string,
  triggerEventChangeFilter: PropTypes.func,
};
export default FilterData;
