// TODO: Replace font icomoon by font awesome
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import MobileView from './MobileView';
import TabletView from './TabletView';
import profileMenuProvider from '~/../hocs/profileMenuProvider';
import withI18next from '~/../hocs/_withI18next';
import { logout } from '~/containers/AuthProvider/actions';
import { makeSelectProfile } from '~/containers/AuthProvider/selectors';
import { makeSelectCurrentLang } from '~/containers/MetadataProvider/selectors';

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
  currentLang: makeSelectCurrentLang(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch,
  );

@connect(mapStateToProps, mapDispatchToProps)
@withI18next()
@profileMenuProvider()
class UserHeaderInfo extends React.Component {
  render() {
    return (
      <div className="user-header-info">
        <TabletView {...this.props} />
        <MobileView {...this.props} />
        <style jsx>
          {`
            .user-header-info {
              height: 100%;
            }
          `}
        </style>
      </div>
    );
  }
}

export default UserHeaderInfo;
