import moment from 'moment';
import axios from './interceptor-axios';
import Config from '../config';

// function getComments(params) {
//  const request = {
//    method: "GET",
//    url   : `${Config.apiUrl.apiNotice}/comment`,
//    data  : {},
//  };
//  return axios(request);
//   return Promise.resolve({
//     collection: [
//       {
//         id        : 1,
//         text      : "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n",
//         data      : "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n",
//         name      : "test",
//         avatar    : "/static/images/avatars/f24.png",
//         comment_on: "",
//         user_id   : 34,
//         is_creator: false,
//         data_id   : "",
//         creator_id: 6,
//         created_at: moment().unix(),
//         updated_at: "",
//       },
//       {
//         id        : 2,
//         text      : "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n",
//         data      : "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n",
//         name      : "test",
//         avatar    : "/static/images/avatars/f9.png",
//         comment_on: "",
//         user_id   : 34,
//         is_creator: false,
//         data_id   : "",
//         creator_id: 6,
//         created_at: moment().unix(),
//         updated_at: "",
//         deleted_at: "",
//       },
//       {
//         id        : 3,
//         text      : "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n",
//         data      : "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n",
//         name      : "test",
//         avatar    : "/static/images/avatars/f6.png",
//         comment_on: "",
//         user_id   : 34,
//         is_creator: true,
//         data_id   : "",
//         creator_id: 6,
//         created_at: moment().unix(),
//         updated_at: "",
//         deleted_at: "",
//       },
//     ],
//     has_next  : true,
//     page      : params.page || 1,
//   });
// }

// function postComment(params) {
//   console.log(`params`, params);
//  const request = {
//    method: "POST",
//    url   : `${Config.apiUrl.apiNotice}/comment`,
//    data  : {},
//  };
//  return axios(request);
// return Promise.resolve({
//   comment: {
//     id        : 1,
//     text      : params.text,
//     data      : params.text,
//     name      : "test",
//     avatar    : "/static/images/avatars/f24.png",
//     comment_on: "",
//     user_id   : 34,
//     is_creator: false,
//     data_id   : "",
//     creator_id: 6,
//     created_at: moment().unix(),
//     updated_at: "",
//   }
// });
// }

function getComments(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiDownloadComment}/comment`,
    params: {
      service_type: Config.serviceType,
      max_results: params.max_results || 5,
      page: params.page || 1,
      data_id: params.data_id || null,
      user_id: params?.user_id,
    },
  };
  return axios(request);
}

function getReplyComments(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiDownloadComment}/comment/reply`,
    params: {
      service_type: Config.serviceType,
      max_results: params.max_results || 5,
      page: params.page || 1,
      comment_id: params.comment_id || null,
      data_id: params.data_id || null,
    },
  };
  return axios(request);
}

function postReplyComments(params) {
  console.log('postReplyComments', params);
  const request = {
    method: 'post',
    url: `${Config.apiUrl.apiDownloadComment}/comment/reply`,
    data: {
      service_type: Config.serviceType,
      comment_id: params.comment_id || null,
      data_id: params.data_id || null,
      user_id: params.user_id,
      text: params.text.text,
    },
  };
  return axios(request);
}

function postComments(params) {
  console.log('postReplyComments', params);
  const request = {
    method: 'post',
    url: `${Config.apiUrl.apiDownloadComment}/comment/submit`,
    data: {
      service_type: Config.serviceType,
      data_id: params.data_id || null,
      user_id: params.user_id,
      text: params.text.text,
    },
  };
  return axios(request);
}
function postLikeComment(params) {
  console.log('postLikeComment', params);
  const request = {
    method: 'post',
    url: `${Config.apiUrl.apiDownloadComment}/comment/like`,
    data: {
      service_type: Config.serviceType,
      data_id: params.data_id || null,
      user_id: params.user_id,
      comment_id: params.comment_id || null,
    },
  };
  return axios(request);
}
export {
  getComments,
  getReplyComments,
  postReplyComments,
  postComments,
  postLikeComment,
};
