export default {
  routersPathName: {
    NOTIFICATION: {
      path: '/my-page/notification',
      routeName: 'notification',
    },
    PROFILE: {
      path: '/personal-info',
      routeName: 'profile',
    },
    SUBSCRIPTION_HISTORY: {
      path: '/my-page/subscription-history',
      routeName: 'subscriptionHistory',
    },
    DOWNLOAD_HISTORY: {
      path: '/my-page/download-history',
      routeName: 'downloadHistory',
    },
    PASSWORD_SETTING: {
      path: '/my-page/password-setting',
      routeName: 'passwordSetting',
    },
    PATTERN_PRESENT: {
      path: '/my-page/pattern-present',
      routeName: 'patternPresent',
    },
    INQUIRY: {
      path: '/my-page/inquiry',
      routeName: 'inquiry',
    },
    HELP_PAGE: {
      path: '/help-page',
      routeName: 'help-page',
    },
    CERTIFICATE: {
      path: '/my-page/certificate',
      routeName: 'certificate',
    },
    MEMBER_INFORMATION: {
      path: '/my-page/creator-info',
      routeName: 'member-information',
    },
    CREATOR_PROFILE: {
      path: '/my-page/creator-profile',
      routeName: 'creatorProfile',
    },
    UPLOAD_POST_PHOTO: {
      path: '/my-page/post-photo',
      routeName: 'upload',
    },
    UPLOAD_ABOUT_TAG: {
      path: '/my-page/about-tag',
      routeName: 'upload',
    },
    EDIT_PHOTO: {
      path: '/my-page/edit-photo',
      routeName: 'upload',
    },
    PHOTO_LIST: {
      path: '/my-page/photo-list',
      routeName: 'photoList',
    },
    PAYMENT: {
      path: '/my-page/payment',
      routeName: 'payment',
    },
    MODEL_RELEASE: {
      path: '/my-page/upload-model',
      routeName: 'modelRelease',
    },
    MODEL_RELEASE_LIST: {
      path: '/my-page/model-list',
      routeName: 'modelRelease',
    },
    MODEL_RELEASE_DATA_LIST: {
      path: '/my-page/model-detail',
      routeName: 'modelRelease',
    },
    PROPERTY_RELEASE: {
      path: '/my-page/upload-property',
      routeName: 'propertyRelease',
    },
    PROPERTY_RELEASE_LIST: {
      path: '/my-page/property-list',
      routeName: 'propertyRelease',
    },
    PROPERTY_RELEASE_DATA_LIST: {
      path: '/my-page/property-detail',
      routeName: 'propertyRelease',
    },
    COMMENT_LIST: {
      path: '/my-page/comment-list',
      routeName: 'commentList',
    },
    RANKING: {
      path: '/my-page/ranking',
      routeName: 'ranking',
    },
    FOLLOW: {
      path: '/my-page/follow',
      routeName: 'follow',
    },
    REQUEST_LIST: {
      path: '/my-page/request-list',
      routeName: 'requestList',
    },
    CONTACT_US: {
      path: '/my-page/contact',
      routeName: 'contactUS',
    },
    CONTACT_US_MAIN: {
      path: '/inquiry',
      routeName: 'Contact Us',
    },
  },
};

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
