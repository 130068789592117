import { createSelector } from 'reselect';

const selectDataCategory = state => state.categoryPage;

const selectFilter = state => state.categoryPage.filter;
const makeSelectPhotos = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.photos,
);

const makeSelectError = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.error,
);

const makeSelectSlug = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.slug,
);

const makeSelectQuery = state => state.categoryPage.query;

const makeSelectPage = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.page,
);

const makeSelectTotalPage = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.totalPage,
);

const makeSelectCategory = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.category,
);

const makeSelectLoading = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.loading,
);

const makeSelectTotalItem = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.totalItem,
);

const makeSelectHasNext = () => createSelector(
  selectDataCategory,
  dataCategoryState => dataCategoryState.has_next,
);

const makeSelectOrderBy = () => createSelector(
  selectFilter,
  filterState => filterState.orderBy,
);

const makeSelectPerPage = () => createSelector(
  selectFilter,
  filterState => filterState.perPage,
);

export {
  selectDataCategory,
  makeSelectPhotos,
  makeSelectError,
  makeSelectPage,
  makeSelectTotalPage,
  makeSelectTotalItem,
  makeSelectOrderBy,
  makeSelectPerPage,
  makeSelectSlug,
  makeSelectCategory,
  makeSelectLoading,
  makeSelectHasNext,
};
