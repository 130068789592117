import {
  LOAD_TOKEN,
  CLEAR_TOKEN,
  LOAD_PROFILE,
  CLEAR_PROFILE,
  SET_AUTHENTICATE,
  UPDATE_ROLE,
  UPDATE_STATUS,
  CHECK_USER_PREMIUM,
} from './constants';

export const initialState = {
  token: '',
  profile: {},
  isAuthenticated: false,
  checkPremium: {},
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TOKEN:
      return { ...state, token: action.token };

    case CLEAR_TOKEN:
      return { ...state, token: '' };

    case SET_AUTHENTICATE:
      return { ...state, isAuthenticated: action.isAuthenticate };

    case LOAD_PROFILE:
      return { ...state, profile: action.profile || {} };

    case UPDATE_ROLE:
      return { ...state, profile: { ...state.profile, role: action.role } };

    case UPDATE_STATUS:
      return { ...state, profile: { ...state.profile, status: action.status } };

    case CLEAR_PROFILE:
      return { ...state, profile: {} };

    case CHECK_USER_PREMIUM:
      return {
        ...state,
        checkPremium: action.checkPremium,
      };

    default:
      return state;
  }
}

export default authReducer;
