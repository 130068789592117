import {
  LOAD_DATA_CATEGORY,
  FILTER_ORDER_BY,
  FILTER_PER_PAGE,
  CHANGE_PAGE,
  LOAD_DATA_CATEGORY_SIMILAR,
  LOAD_DATA_CATEGORY_OLD,
  NICE_PHOTO,
} from './constants';

export function loadDataCategory(payload, api) {
  return {
    type: LOAD_DATA_CATEGORY,
    payload,
    api,
  };
}

export function loadDataCategoryOld(payload, api) {
  return {
    type: LOAD_DATA_CATEGORY_OLD,
    payload,
    api,
  };
}

export function loadDataSimilarCategory(payload, api) {
  return {
    type: LOAD_DATA_CATEGORY_SIMILAR,
    payload,
    api,
  };
}

export function changeOrder(value) {
  return {
    type: FILTER_ORDER_BY,
    value,
  };
}

export function changePerPage(value) {
  return {
    type: FILTER_PER_PAGE,
    value,
  };
}

export function changePage(page) {
  return {
    type: CHANGE_PAGE,
    page,
  };
}
export function nicePhoto(value) {
  return {
    type: NICE_PHOTO,
    value,
  };
}
