import { combineReducers } from 'redux';
import {
  SEARCH_PHOTO,
  SEARCH_PHOTO_REQUEST,
  SEARCH_PHOTO_SUCCESS,
  SEARCH_PHOTO_ERROR,
  FILTER_PER_PAGE,
  FILTER_ORDER_BY,
  CHANGE_PAGE,
  CHANGE_PERSONALIZE,
  SEARCH_OTHER_SERVICES_SUCCESS,
  // SEARCH_OTHER_SERVICES,
  SEARCH_OTHER_SERVICES_REQUEST,
  SEARCH_OTHER_SERVICES_ERROR,
  INIT_OTHER_SERVICE_SEARCH_RESULTS,
  REMOVE_NICE_PHOTO_SUCCESS,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
  SEARCH_OTHER_SERVICES_CLEAR,
} from './constants';
import { SEARCH_RESULT_ORDER, SERVICE_TYPES } from '~/utils/constants';

import config from '~/config';

export const initialState = {
  photos: [],
  suggest: [],
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
  hasPersonalize: true,
  query: {},
  loadingOtherServices: false,
  otherServices: [],
};

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_PHOTO:
      return {
        ...state,
        query: {
          highlightID: action.payload?.highlightID || null,
          category: action.payload.category || '',
          excludeCategory: action.payload.excludeCategory || '',
          keyword: action.payload.keyword || '',
          excludeKeyword: action.payload.excludeKeyword || '',
          creatorName: action.payload.creatorName || '',
          excludeCreatorName: action.payload.excludeCreatorName || '',
          per_page: action.payload.per_page || 100,
          page: action.payload.page || 1,
          orderBy:
            action.payload.orderBy === 'all_images'
              ? SEARCH_RESULT_ORDER.ALL_IMAGES
              : action.payload.orderBy || SEARCH_RESULT_ORDER.POPULAR,
          color: action.payload.color || 'all',
          modelCount: action.payload.modelCount || '-2',
          modelAge: action.payload.modelAge || '',
          shape: action.payload.shape || 'all',
          imageType: action.payload.imageType || '',
          modelRelease: action.payload.modelRelease || '',
          is_tag: action.payload.is_tag || false,
          image_token: action.payload.image_token || '',
          license: action.payload.license || '',
          imageId: action.payload.imageId || '',
          imageTitle: action.payload.title || '',
        },
        // loading: true,
        // error: false,
      };
    case SEARCH_PHOTO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SEARCH_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        photos: action.collection,
        suggest: action.suggest || [],
        has_next: action.has_next,
        page: action.page || 1,
        totalPage: action.total_page || 0,
        totalItem: action.total_item || 0,
      };
    case SEARCH_PHOTO_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case INIT_OTHER_SERVICE_SEARCH_RESULTS: {
      let otherServices = [];
      if (config.isPhotoAC()) {
        otherServices = [SERVICE_TYPES.ILLUST_AC, SERVICE_TYPES.SILHOUETTE_AC];
      } else if (config.isIllustAC()) {
        otherServices = [SERVICE_TYPES.PHOTO_AC, SERVICE_TYPES.SILHOUETTE_AC];
      } else if (config.isSilhouetteAC()) {
        otherServices = [SERVICE_TYPES.PHOTO_AC, SERVICE_TYPES.ILLUST_AC];
      }
      return {
        ...state,
        otherServices: otherServices.map((serviceType) => ({ serviceType })),
      };
    }
    case SEARCH_OTHER_SERVICES_REQUEST:
      return {
        ...state,
        loadingOtherServices: true,
      };
    case SEARCH_OTHER_SERVICES_SUCCESS:
      return {
        ...state,
        loadingOtherServices: false,
        otherServices: action.payload,
      };
    case SEARCH_OTHER_SERVICES_CLEAR:
      return {
        ...state,
        otherServices: action.payload,
      };
    case SEARCH_OTHER_SERVICES_ERROR:
      return {
        ...state,
        loadingOtherServices: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case CHANGE_PERSONALIZE:
      return {
        ...state,
        hasPersonalize: action.hasPersonalize,
      };
    case NICE_PHOTO_SUCCESS: {
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id)
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          return item;
        }),
      };
    }

    case REMOVE_NICE_PHOTO_SUCCESS: {
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id)
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          return item;
        }),
      };
    }

    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id)
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          return item;
        }),
      };
    default:
      return state;
  }
}

const filterInitState = {
  orderBy: SEARCH_RESULT_ORDER.POPULAR,
  perPage: '100',
};

function filterReducer(state = filterInitState, action) {
  switch (action.type) {
    case FILTER_ORDER_BY:
      return {
        ...state,
        orderBy: action.value,
      };
    case FILTER_PER_PAGE:
      return {
        ...state,
        perPage: action.value,
      };
    default:
      return state;
  }
}

export default combineReducers({
  search: searchReducer,
  filter: filterReducer,
});
