import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '~/config';
import I18nLink from '~/components/I18nLink';
import themeVariables from '~/shared/themeVariables';

const NextPageButtonCustom = (props) => {
  const { host, pathname, query, as, t, type, style, target, label } = props;

  let buttonLabel = label;
  if (!label) {
    if (type == 'next_page') {
      buttonLabel = t('next_page_button');
    }
    if (type == 'see_more') {
      buttonLabel = t('See more');
    }
  }
  return (
    <div className="next-page-button" style={style}>
      <I18nLink href={{ pathname, query }} as={as} host={host}>
        <a className="btn-next-page" target={target}>
          {buttonLabel}{' '}
          {/* <span>
            
            <img
              src={`${Config.staticPrefix}/static/assets/svg/next-page.svg`}
              className="next-page-icon"
              alt="next-page"
              width={19}
              height={19}
            />
          </span> */}
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{
              width: '19px',
              marginLeft: 4,
            }}
          />
        </a>
      </I18nLink>
      <style jsx>
        {`
          .next-page-button {
            max-width: 100%;
            width: fit-content;
            padding: 0 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            white-space: wrap;
            align-items: center;
            align-self: center;
            text-align: center;
            height: 44px;
            background: #1b2833;
            border-radius: ${Config.isIllustAC() ? '40px' : '4px'};
          }
          .btn-next-page {
            max-width: 100%;
            width: fit-content;
            font-size: 16px;
            color: ${themeVariables.colors.white};
            border: none;
          }
          .next-page-button:hover {
            background: ${themeVariables.colors.primary};
          }
          .btn-next-page:focus {
            text-decoration: none;
          }
        `}
      </style>
    </div>
  );
};

export default NextPageButtonCustom;
