import { call, put, takeLatest } from 'redux-saga/effects';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from './constants';
import * as authService from '~/services/auth';

function* resetPassword(params) {
  const { resolve, reject, password, token } = params;
  try {
    yield call(authService.resetPassword, password, token);
    yield put({
      type: RESET_PASSWORD_SUCCESS,
    });

    resolve();
  } catch (error) {
    yield put({ type: RESET_PASSWORD_ERROR, error });
    reject(error);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
