import { call, put, takeLatest, select } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  LOAD_SIMILAR_DATA,
  LOAD_SIMILAR_DATA_SUCCESS,
  LOAD_SIMILAR_DATA_ERROR,
} from './constants';
import { makeSelectCollection } from './selectors';
import * as photoPageService from '~/services/photo-page';
import * as similarPageService from '~/services/similar-page';
import {
  NICE_PHOTO,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
} from '~/components/Gallery/constants';
import Config from '~/config';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';

function* loadSimilar(action) {
  const { payload, api } = action;
  console.log('payload nhan vao ne', payload, Config.serviceType);
  try {
    const apiFunction = similarPageService.getSimilarData;

    const dataPayload = {
      collection: [],
      data: [],
      has_next: true,
      photo_similar: [],
      page: 1,
      silhouette_similar: [],
      total_page: 1,
      total_item: 1,
    };
    // const {
    //   // collection,
    //   // has_next,
    //   // page,
    //   // data,
    //   // silhouette_similar,
    //   // total_page,
    //   // total_item,
    //   // photo_similar,
    // } = yield call(apiFunction, payload);

    const data = yield call(apiFunction, payload);

    dataPayload.collection = data?.collection || [];
    dataPayload.data = data?.data || null;
    dataPayload.has_next = data?.has_next || true;
    dataPayload.page = data?.page || 1;
    dataPayload.silhouette_similar = data?.silhouette_similar || [];
    dataPayload.total_page = data?.total_page || 1;
    dataPayload.total_item = data?.total_item || 1;
    dataPayload.photo_similar = data?.photo_similar || [];

    if (dataPayload.collection && dataPayload.collection.length > 0) {
      dataPayload.collection = dataPayload.collection.map((item) => {
        if (item?.filename) {
          item.thumbnail = Config.renderThumbnailImageByFileName(
            item.filename,
            // payload?.service_type_for_similar || Config.serviceType,
          );
        }
        return item;
      });
    }

    const pageInt = parseInt(dataPayload.page);
    console.log(
      'sau khi gọi xong o saga',
      // collection,
      // dataPayload,
      // has_next,
      // photo_similar,
      // silhouette_similar,
    );
    yield put({
      type: LOAD_SIMILAR_DATA_SUCCESS,
      // collection,
      // data,
      // has_next,
      // photo_similar,
      // page: pageInt,
      // silhouette_similar,
      // total_page,
      // total_item,
      ...dataPayload,
    });
  } catch (error) {
    yield put({
      type: LOAD_SIMILAR_DATA_ERROR,
      error: (error.response && error.response.data) || {},
    });
  }
}

function* nicePhoto(action) {
  try {
    const photos = yield select(makeSelectCollection());
    const searchedPhoto = photos.find((item) => item.id === action.id);
    if (!searchedPhoto) return;
    if (searchedPhoto.is_nice) {
      return yield put({
        type: OPEN_MODAL,
        title: i18n.t(Config.getPrefixContent('already_nice')),
      });
    }
    yield put({
      type: NICE_PHOTO_SUCCESS,
      id: action.id,
    });
    yield call(photoPageService.nicePhoto, action.id);
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

export default function* loadSimilarSaga() {
  yield takeLatest(LOAD_SIMILAR_DATA, loadSimilar);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
