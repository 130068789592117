import React from 'react';
import QRCode from 'qrcode.react';
import Config from '~/config';

const PAYMENT_METHODS = {
  WECHAT: 'wechat',
  ALIPAY: 'alipay',
  CARD: 'card',
};

const LoadingDefault = ({
  fullScreen,
  wechat,
  currentPaymentMethod = null,
  t,
  message,
  cancelWechatPayment,
}) => {
  if (!process.browser) return null;
  if (!fullScreen) return null;

  // const $body = $('body');
  // if (!fullScreen) {
  //   $body.css('overflow', '');
  //   return <div />;
  // }
  // $body.css('overflow', 'hidden');

  const renderMessageWeChat = () => (
    <>
      <div className="wechat-qrcode-loading">
        <QRCode value={wechat.qrCodeURL} size={150} />
      </div>
      <div
        className="text-loading"
        dangerouslySetInnerHTML={{
          __html: t('register_premium_wechat_message'),
        }}
      />
      <button
        className={`btn btn-ac-primary btn-${Config.serviceType} btn-cancle-wechat-payment`}
        onClick={cancelWechatPayment}
      >
        <span>{t('cancel_payment')}</span>
      </button>
    </>
  );

  const renderMessageCard = () => (
    <div
      className="text-loading"
      dangerouslySetInnerHTML={{ __html: t('register_premium_message_pay') }}
    />
  );

  const renderMessageAliPay = () => {};

  const renderMessageByPaymentMethod = () => {
    if (currentPaymentMethod == PAYMENT_METHODS.WECHAT) {
      return renderMessageWeChat();
    }
    if (currentPaymentMethod == PAYMENT_METHODS.ALIPAY) {
      return renderMessageAliPay();
    }
    return renderMessageCard();
  };

  const renderMessageByOthers = () => (
    <div
      className="text-loading"
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );

  const renderMessage = () => {
    console.log(currentPaymentMethod);
    if (currentPaymentMethod) {
      return renderMessageByPaymentMethod();
    }
    return renderMessageByOthers();
  };

  return (
    <div className="ac-loading-default">
      <div className="loader-default-bg" />
      <div className="ac-loading-content">
        {renderMessage()}
        <div className="loader loader-default is-active" />
      </div>
      <style jsx global>
        {`
          .loader {
            color: #fff;
            box-sizing: border-box;
          }

          .loader:after,
          .loader:before {
            box-sizing: border-box;
            display: none;
          }

          .loader.is-active:after,
          .loader.is-active:before {
            display: block;
          }

          .ac-loading-default {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            color: #fff;
            z-index: 2000;
          }
          .ac-loading-default .loader-default-bg {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1050;
            background-color: rgba(0, 0, 0, 0.8);
          }
          .ac-loading-default .ac-loading-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 20px;
            z-index: 999999;
          }
          .ac-loading-default .ac-loading-content .wechat-qrcode-loading {
            margin-bottom: 50px;
          }
          .ac-loading-default .ac-loading-content .btn-cancle-wechat-payment {
            margin-top: 30px;
            margin-bottom: 30px;
          }
          .ac-loading-default .ac-loading-content .loader-default:after {
            content: '';
            width: 48px;
            height: 48px;
            border: 8px solid #fff;
            border-left-color: transparent;
            border-radius: 50%;
            animation: rotation 1s linear infinite;
            margin-top: 15px;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0);
            }
            to {
              transform: rotate(359deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingDefault;
