import axios from '../interceptor-axios';
import config from '~/config';
import { fileHelper } from '~/utils';

export const MODEL = 'model';
export const PROPERTY = 'property';
export const AVATAR = 'avatar';
export const HEADER = 'header';
export const DATA = 'data';
export const POSTPHOTO = 'postphoto';
export const IDENTITY = 'identity';

function beforeUpload(file_type, upload_for = AVATAR) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiS3Service}/generate/url`,
    params: {
      file_type,
      upload_for,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function beforePostPhotoUpload(file_type, upload_for = AVATAR) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiS3Service}/generate/url`,
    params: {
      file_type,
      upload_for,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function beforeDesignUpload(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiS3Service}/generate/url`,
    params: {
      extensions: params.extensions,
      data_type: params.data_type,
      upload_for: params.upload_for,
      file_sizes: params.file_sizes,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

/**
 *
 * @param {File} file
 * @param {string|POSTPHOTO|IDENTITY|MODEL|PROPERTY|AVATAR|HEADER|DATA|*} upload_for
 * @returns {Promise}
 */
function uploadFile(file, upload_for = AVATAR) {
  const xhr = new XMLHttpRequest();
  return beforeUpload(file.type, upload_for).then((result) => {
    xhr.open('PUT', result.link.uploadUrl);
    xhr.send(file);
    return new Promise(
      (resolve, reject) =>
        (xhr.onload = () =>
          xhr.status === 200 ? resolve(result.link) : reject(xhr)),
    );
  });
}

/**
 *
 * @param {File} file
 * @param {string|POSTPHOTO|IDENTITY|MODEL|PROPERTY|AVATAR|HEADER|DATA|*} upload_for
 * @returns {Promise}
 */
function uploadFilePostPhoto(file, upload_for = AVATAR) {
  const xhr = new XMLHttpRequest();
  return beforePostPhotoUpload(file.type, upload_for).then((result) => {
    xhr.open('PUT', result.link.uploadUrl);
    xhr.send(file);
    return new Promise(
      (resolve, reject) =>
        (xhr.onload = () =>
          xhr.status === 200 ? resolve(result.link) : reject(xhr)),
    );
  });
}

/**
 *
 * @param {File} file
 * @param {string|POSTPHOTO|IDENTITY|MODEL|PROPERTY|AVATAR|HEADER|DATA|*} upload_for
 * @returns {Promise}
 */
function uploadDesignPhoto(file, upload_for = DATA) {
  const xhr = new XMLHttpRequest();
  return beforeDesignUpload({
    upload_for,
    extensions: fileHelper.getExtensionWithFileType(file),
    file_sizes: file.size,
  }).then((result) => {
    xhr.open('PUT', result[0].uploadUrl);
    xhr.send(file);
    return new Promise(
      (resolve, reject) =>
        (xhr.onload = () =>
          xhr.status === 200 ? resolve(result) : reject(xhr)),
    );
  });
}

function beforeUploadToSearch({ filename, content_type, token }) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiInitSearchImageService}`,
    data: {
      filename,
      content_type,
      service_type: config.serviceType,
      token: token,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios(request);
}

function uploadPhotoSearch(file, token) {
  console.log('--- uploadPhotoSearch ---');
  console.log(file);
  const xhr = new XMLHttpRequest();
  return beforeUploadToSearch({
    filename: file.name,
    content_type: file.type,
    token,
  }).then(uploadFile);
  function uploadFile(result) {
    console.log(' --- check uploadFile --- ');
    console.log(result);
    xhr.open('PUT', result.upload_url);
    // xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
    return new Promise(
      (resolve, reject) =>
        (xhr.onload = () =>
          xhr.status === 200 ? resolve(result) : reject(xhr)),
    );
  }
}

function beforeUploadFileContactUsPage({ filename, file_type, token }) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiNotice}/contact/presigned-url`,
    data: {
      filename,
      file_type,
      service_type: config.serviceType,
      token: token,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios(request);
}
async function uploadMediaContactUs(file, token) {
  console.log('--- uploadMediaContactUs ---');
  console.log(file);

  const xhr = new XMLHttpRequest();

  return beforeUploadFileContactUsPage({
    filename: file[0].name,
    file_type: file[0].type.split('/')[1],
    token,
  }).then(uploadFile);

  function uploadFile(result) {
    xhr.open('PUT', result.link);
    // xhr.setRequestHeader('Content-Type', file[0].type);
    xhr.send(file[0]);
    return result;
    // return new Promise(
    //   (resolve, reject) =>
    //     (xhr.onload = () =>
    //       xhr.status === 200 ? resolve(result) : reject(xhr)),
    // );
  }
}
export {
  uploadFile,
  uploadFilePostPhoto,
  uploadPhotoSearch,
  beforeUpload,
  beforeUploadToSearch,
  uploadMediaContactUs,
};
