import {
  CHANGE_KEYWORD,
  CHANGE_EXCLUDE_KEYWORD,
  CHANGE_CATEGORY,
  CHANGE_EXCLUDE_CATEGORY,
  LOAD_DATA_SEARCH,
  CHANGE_IMAGE_TYPE,
  CHANGE_IMAGE_ORDERBY,
  CHANGE_IMAGE_SHAPE,
  CHANGE_IMAGE_ID,
  CHANGE_LICENSE,
  CHANGE_IMAGE_TITLE,
  CLEAR_SEARCH,
  CHANGE_CREATOR_NAME,
  CHANGE_EXCLUDE_CREATOR_NAME,
  CHANGE_COLOR,
  CHANGE_MODEL_COUNT,
  CHANGE_MODEL_AGE,
  CHANGE_ORIENTATION,
  CHANGE_MODEL_RELEASE,
  CHANGE_OPEN_ORDER_BY,
  CHANGE_OPEN_IMAGE_TYPE,
  CHANGE_OPEN_ORIENTATION,
  CHANGE_OPEN_KEYWORDS,
  CHANGE_OPEN_CATEGORY,
  CHANGE_OPEN_COLOR,
  CHANGE_OPEN_NUMBER_OF_PEOPLE,
  CHANGE_OPEN_AGE,
  CHANGE_OPEN_MODEL_RELEASE,
  CHANGE_OPEN_CREATOR,
  CHANGE_OPEN_IMAGE_TITLE,
  CHANGE_TOGGLE_FILTER_SEARCH,
  CHANGE_OPEN_RELEASES,
  CHANGE_OPEN_LICENSE,
  CLOSE_ALL_FILTER,
  OPEN_ALL_FILTER,
  CHANGE_OPEN_MORE,
} from './constants';
import { SEARCH_RESULT_ORDER } from '~/utils/constants';
import Config from '~/config';

export const initialState = {
  keyword: '',
  excludeKeyword: '',
  category: [],
  excludeCategory: [],
  creatorName: '',
  excludeCreatorName: '',
  imageType: '',
  orderBy: SEARCH_RESULT_ORDER.ALL_IMAGES,
  shape: 'all',
  imageId: '',
  imageTitle: '',
  color: 'all',
  modelCount: '-2',
  modelAge: [],
  orientation: '',
  modelRelease: '',
  license: '',

  // Search filter Illust: handle for search filter IllustAC
  openOrderBy: Config.isIllustAC() ? false : true,
  openImageType: Config.isIllustAC() ? false : true,
  openOrientation: Config.isIllustAC() ? false : true,
  openKeywords: Config.isIllustAC() ? false : true,
  openCategory: Config.isIllustAC() ? false : true,
  openColor: Config.isIllustAC() ? false : true,
  openNumberOfPeople: Config.isIllustAC() ? false : true,
  openAge: Config.isIllustAC() ? false : true,
  openModelRelease: Config.isIllustAC() ? false : true,
  openCreator: Config.isIllustAC() ? false : true,
  openImageTitle: Config.isIllustAC() ? false : true,
  openReleases: Config.isIllustAC() ? false : true,
  openLicense: Config.isIllustAC() ? false : true,
  openMore: false,

  toggleFilterSearch: false,

  dataNow: [{ type: 'keyword', label: '' }],
  isLoaded: false,
};
function searchReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_IMAGE_ID: {
      console.log('hihi vo xoa rui ne', action.value, state.dataNow);
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'imageId';
      });
      return {
        ...state,
        imageId: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'imageId',
                label: action.value.key + ': ' + action.value.value,
              })
            : dataTmp,
      };
    }

    case CHANGE_IMAGE_TITLE: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'imageTitle';
      });
      return {
        ...state,
        imageTitle: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'imageTitle',
                label: action.value.key + ': ' + action.value.value,
              })
            : dataTmp,
      };
    }

    case CHANGE_KEYWORD: {
      const dataTmp = state.dataNow.map((ele) => {
        if (ele.type === 'keyword') {
          ele.label = action.value;
          return ele;
        }
        return ele;
      });
      return {
        ...state,
        keyword: action.value,
        dataNow: dataTmp,
      };
    }
    case CHANGE_EXCLUDE_KEYWORD: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'excludeKeyword';
      });
      return {
        ...state,
        excludeKeyword: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'excludeKeyword',
                label: action.value.key + ': ' + action.value.value,
              })
            : dataTmp,
      };
    }

    case CHANGE_IMAGE_TYPE: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'imageType';
      });
      return {
        ...state,
        imageType: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'imageType',
                label: action.value.key + ': ' + action.value.label,
              })
            : dataTmp,
      };
      // return { ...state, imageType: action.value };
    }
    case CHANGE_ORIENTATION:
      return { ...state, orientation: action.value };

    case CHANGE_LICENSE: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'license';
      });
      return {
        ...state,
        license: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'license',
                label: action.value.label,
              })
            : dataTmp,
      };
    }

    case CHANGE_IMAGE_ORDERBY: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'orderBy';
      });
      return {
        ...state,
        orderBy: action.value.value,
        dataNow:
          action.value.value !== SEARCH_RESULT_ORDER.ALL_IMAGES
            ? dataTmp.concat({ type: 'orderBy', label: action.value.label })
            : dataTmp,
      };
    }

    case CHANGE_IMAGE_SHAPE: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'shape';
      });

      return {
        ...state,
        shape: action.value.value,
        dataNow:
          action.value.value !== 'all'
            ? dataTmp.concat({
                type: 'shape',
                label: action.value.key + ': ' + action.value.label,
              })
            : dataTmp,
      };
    }
    case CHANGE_CATEGORY: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'category';
      });
      const dataCategory = action.options;
      const result = dataCategory.map((data) => {
        return {
          value: data.value,
          label: data.key + ': ' + data.label,
          type: data.type,
        };
      });
      return {
        ...state,
        category: action.options,
        dataNow: dataTmp.concat(result),
      };
    }

    case CHANGE_EXCLUDE_CATEGORY: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'excludeCategory';
      });
      const dataExcludeCategory = action.options;
      const result = dataExcludeCategory.map((data) => {
        return {
          value: data.value,
          label: data.key + ': ' + data.label,
          type: data.type,
        };
      });
      return {
        ...state,
        excludeCategory: action.options,
        dataNow: dataTmp.concat(result),
      };
    }

    case CHANGE_MODEL_RELEASE: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'modelRelease';
      });
      return {
        ...state,
        modelRelease: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'modelRelease',
                label: action.value.label,
              })
            : dataTmp,
      };
    }

    case CHANGE_CREATOR_NAME: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'creatorName';
      });
      return {
        ...state,
        creatorName: action.value.value,
        dataNow:
          action.value.value !== ''
            ? dataTmp.concat({
                type: 'creatorName',
                label: action.value.key + ': ' + action.value.value,
              })
            : dataTmp,
      };
    }

    case CHANGE_COLOR: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'color';
      });
      return {
        ...state,
        color: action.value,
        dataNow:
          action.value !== 'all'
            ? dataTmp.concat({ type: 'color', label: action.value })
            : dataTmp,
      };
    }

    case CHANGE_EXCLUDE_CREATOR_NAME:
      return { ...state, excludeCreatorName: action.value };

    case LOAD_DATA_SEARCH: {
      return {
        ...state,
        ...action.query,
        dataNow: action.query.dataNow,
        isLoaded: true,
      };
    }
    case CLEAR_SEARCH:
      return {
        ...state,
        // keyword: '',
        excludeKeyword: '',
        category: [],
        excludeCategory: [],
        creatorName: '',
        excludeCreatorName: '',
        imageType: '',
        orderBy: SEARCH_RESULT_ORDER.ALL_IMAGES,
        shape: 'all',
        imageId: '',
        imageTitle: '',
        color: 'all',
        modelCount: '-2',
        modelAge: [],
        orientation: '',
        modelRelease: '',
        license: '',
        dataNow: [{ type: 'keyword', label: state.keyword }],
      };

    case CHANGE_MODEL_COUNT: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'modelCount';
      });
      return {
        ...state,
        modelCount: action.value.value,
        dataNow:
          action.value.value !== '-2'
            ? dataTmp.concat({ type: 'modelCount', label: action.value.label })
            : dataTmp,
      };
    }

    case CHANGE_MODEL_AGE: {
      const dataTmp = state.dataNow.filter((ele) => {
        return ele.type !== 'modelAge';
      });
      return {
        ...state,
        modelAge: action.options,
        dataNow: dataTmp.concat(action.options),
      };
    }
    case CHANGE_OPEN_ORDER_BY: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openMore: false,
          openReleases: false,
          openLicense: false,
          openOrderBy: action.value,
        };
      }
      return {
        ...state,
        openOrderBy: action.value,
      };
    }

    case CHANGE_OPEN_IMAGE_TYPE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openImageType: action.value,
        };
      }
      return {
        ...state,
        openImageType: action.value,
      };
    }

    case CHANGE_OPEN_ORIENTATION: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openOrientation: action.value,
        };
      }
      return {
        ...state,
        openOrientation: action.value,
      };
    }

    case CHANGE_OPEN_KEYWORDS: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openKeywords: action.value,
        };
      }
      return {
        ...state,
        openKeywords: action.value,
      };
    }

    case CHANGE_OPEN_CATEGORY: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openMore: false,
          openReleases: false,
          openLicense: false,
          openCategory: action.value,
        };
      }
      return {
        ...state,
        openCategory: action.value,
      };
    }

    case CHANGE_OPEN_COLOR: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openColor: action.value,
        };
      }
      return {
        ...state,
        openColor: action.value,
      };
    }

    case CHANGE_OPEN_NUMBER_OF_PEOPLE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openNumberOfPeople: action.value,
        };
      }
      return {
        ...state,
        openNumberOfPeople: action.value,
      };
    }

    case CHANGE_OPEN_AGE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openAge: action.value,
        };
      }
      return {
        ...state,
        openAge: action.value,
      };
    }

    case CHANGE_OPEN_MODEL_RELEASE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openModelRelease: action.value,
        };
      }
      return {
        ...state,
        openModelRelease: action.value,
      };
    }

    case CHANGE_OPEN_CREATOR: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openImageTitle: false,
          openMore: false,
          openReleases: false,
          openLicense: false,
          openCreator: action.value,
        };
      }
      return {
        ...state,
        openCreator: action.value,
      };
    }

    case CHANGE_OPEN_IMAGE_TITLE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openReleases: false,
          openMore: false,
          openLicense: false,
          openImageTitle: action.value,
        };
      }
      return {
        ...state,
        openImageTitle: action.value,
      };
    }

    case CHANGE_OPEN_RELEASES: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openMore: false,
          openLicense: false,
          openReleases: action.value,
        };
      }
      return {
        ...state,
        openReleases: action.value,
      };
    }

    case CHANGE_OPEN_LICENSE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openMore: false,
          openLicense: action.value,
        };
      }
      return {
        ...state,
        openLicense: action.value,
      };
    }

    case CHANGE_TOGGLE_FILTER_SEARCH:
      return { ...state, toggleFilterSearch: action.value };
    // return { ...state, toggleFilterSearch: false };
    case CLOSE_ALL_FILTER: {
      return {
        ...state,
        openOrderBy: false,
        openImageType: false,
        openOrientation: false,
        openKeywords: false,
        openCategory: false,
        openColor: false,
        openNumberOfPeople: false,
        openAge: false,
        openModelRelease: false,
        openCreator: false,
        openImageTitle: false,
        openReleases: false,
        openLicense: false,
        openMore: false,
      };
    }
    case OPEN_ALL_FILTER: {
      return {
        ...state,
        openOrderBy: true,
        openImageType: true,
        openOrientation: true,
        openKeywords: true,
        openCategory: true,
        openColor: true,
        openNumberOfPeople: true,
        openAge: true,
        openModelRelease: true,
        openCreator: true,
        openImageTitle: true,
        openReleases: true,
        openLicense: true,
        openMore: true,
      };
    }
    case CHANGE_OPEN_MORE: {
      if (action.closeOtherFilter) {
        return {
          ...state,
          openOrderBy: false,
          openImageType: false,
          openOrientation: false,
          openKeywords: false,
          openCategory: false,
          openColor: false,
          openNumberOfPeople: false,
          openAge: false,
          openModelRelease: false,
          openCreator: false,
          openImageTitle: false,
          openReleases: false,
          openLicense: false,
          openMore: action.value,
        };
      }

      return {
        ...state,
        openMore: action.value,
      };
    }
    default:
      return state;
  }
}

export default searchReducer;
