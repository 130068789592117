import {
  ADD_PHOTO_IN_CART,
  CLEAN_PHOTOS_IN_CART,
  LOAD_PHOTOS_IN_CART,
  REMOVE_PHOTO_IN_CART,
  SET_IS_MOBILE_LEFT_MENU_OPEN,
  SET_IS_MOBILE_RIGHT_MENU_OPEN,
  SET_IS_PHOTO_CART_OPEN,
  IS_SURVEY,
  DATA_COUNT_SURVEY,
  ACTIVE_EDITOR_DOWNLOAD,
  CHECK_IS_ACTIVE_EDITOR_DOWNLOAD,
  SET_SHOW_SEARCH_FORM_HOMEPAGE_SCROLL,
  SET_SHOW_FILTER_ON_HEADER,
} from './constants';

export function setIsPhotoCartOpen(isOpen) {
  return {
    type: SET_IS_PHOTO_CART_OPEN,
    payload: isOpen,
  };
}

export function setIsMobileLeftMenuOpen(isOpen) {
  return {
    type: SET_IS_MOBILE_LEFT_MENU_OPEN,
    payload: isOpen,
  };
}

export function setIsMobileRightMenuOpen(isOpen) {
  return {
    type: SET_IS_MOBILE_RIGHT_MENU_OPEN,
    payload: isOpen,
  };
}

export function addPhotoInCart(photo) {
  return {
    type: ADD_PHOTO_IN_CART,
    payload: photo,
  };
}

export function removePhotoInCart(photo) {
  return {
    type: REMOVE_PHOTO_IN_CART,
    payload: photo,
  };
}

export function loadPhotosInCart(isOpen) {
  return {
    type: LOAD_PHOTOS_IN_CART,
  };
}

export function cleanPhotosInCart(isOpen) {
  return {
    type: CLEAN_PHOTOS_IN_CART,
  };
}

export function updateIsSurvey() {
  return {
    type: IS_SURVEY,
  };
}
export function updateDataCountSurvey(data) {
  return {
    type: DATA_COUNT_SURVEY,
    payload: data,
  };
}

export function checkIsActiveEditorDownload(data) {
  return {
    type: CHECK_IS_ACTIVE_EDITOR_DOWNLOAD,
    payload: data,
  };
}
export function updateActiveEditorDownload(data) {
  return {
    type: ACTIVE_EDITOR_DOWNLOAD,
    payload: data,
  };
}
export function updateShowSearchFormScrollHomepage(data) {
  return {
    type: SET_SHOW_SEARCH_FORM_HOMEPAGE_SCROLL,
    payload: data,
  };
}
export function updateShowFilterOnHeader(data) {
  return {
    type: SET_SHOW_FILTER_ON_HEADER,
    payload: data,
  };
}
