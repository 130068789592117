import { createSelector } from 'reselect';

const selectLoading = state => state.loading;

const makeSelectLoading = () =>
  createSelector(
    selectLoading,
    loadingState => loadingState.loading
  );

const makeSelectMessage = () =>
  createSelector(
    selectLoading,
    loadingState => loadingState.message
  );

const makeSelectIsSearchingByPhoto = () =>
  createSelector(
    selectLoading,
    loadingState => loadingState.isSearchingByPhoto
  );

export { makeSelectLoading, makeSelectMessage, makeSelectIsSearchingByPhoto };
