import {
  faCheck,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { checkIEBrowser, hideError, hideIEWarning, hideInfor } from './actions';
import {
  makeSelectDescription,
  makeSelectError,
  makeSelectShowError,
  makeSelectShowIEWarning,
  makeSelectShowInfor,
  makeSelectTitle,
  makeSelectTypeInfor,
} from './selectors';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from '../AuthProvider/selectors';
import withI18next from '~/../hocs/_withI18next';
import ButtonCertificate from '~/components/ButtonCertificate';
import FontIcomoonIcon from '~/components/FontIcomoonIcon';
import Config from '~/config';
import { downloadExtraLicenseCertificate } from '~/containers/MyPage/DownloadHistory/actions';
import { makeSelectPhoto } from '~/containers/PhotoPage/selectors';
import Cookies from '~/services/Cookies';
import { ERROR_CODE, ERROR_TYPE } from '~/utils/errorHandler';

const ERROR_TITLE = {
  [ERROR_CODE.AC_ERROR]: 'system_error',
  [ERROR_CODE.AC_AUTHENTICATE_ERROR]: 'access_denied',
  [ERROR_CODE.AC_ERROR_INPUT]: 'invalid_input_error',
  [ERROR_CODE.AC_ERROR_INVALID_REQUEST]: 'invalid_request_error',
  [ERROR_CODE.AC_MAIL_SERVICE_ERROR]: 'mail_service_error',
  [ERROR_CODE.AC_NOT_ACCEPTABLE_ERROR]: 'not_acceptable_error',
  [ERROR_CODE.AC_ERROR_NOTFOUND]: 'not_found',
  [ERROR_CODE.AC_ERROR_PAYMENT_PREMIUM]: 'register_premium_paypal_title_error',
  [ERROR_CODE.AC_ERROR_PAYMENT_REQUIRED]: 'payment_required',
  [ERROR_CODE.AC_ERROR_PERMISSION]: 'permission_error',
  [ERROR_CODE.UNEXPECTED_ERROR]: 'unexpected_error',
};

const ERROR_MESSAGE = {
  is_exist_premimum_user: 'already_premium',
  could_not_find_plan_id: 'invalid_plan',
  subscription_is_not_active: 'subscription_not_active',
  'Image not found in S3': 'not_found_in_s3',
  system_payment_failed: 'system_payment_failed',
};

/*
Error response format:
{
  code,
  message: string | {
    type,
    message: string | {
      code
    }
  }
}
*/
@withI18next()
class CustomErrorMessage extends React.Component {
  componentDidMount() {
    const { checkIEBrowser } = this.props;
    // checkIEBrowser();
  }

  handleHideModal = () => {
    const { hideError, hideIEWarning, hideInfor } = this.props;
    hideError();
    hideIEWarning();
    hideInfor();
  };

  renderErrorTitle = () => {
    const { t, error } = this.props;
    return t(ERROR_TITLE[error.code]);
  };

  renderStripeErrorDescription = () => {
    const { t, error } = this.props;
    const {
      message: { message: stripeMessage },
    } = error;
    if (ERROR_MESSAGE[stripeMessage]) {
      return t(ERROR_MESSAGE[stripeMessage]);
    }
    if (stripeMessage.code === 'expired_card') {
      return t('expired_card_description');
    }
    if (
      stripeMessage.code === 'balance_insufficient' ||
      (stripeMessage.code === 'card_declined' &&
        stripeMessage.decline_code === 'insufficient_funds')
    ) {
      return t('insufficient_funds_description');
    }
    if (stripeMessage.code === 'card_declined') {
      return t('card_declined_description');
    }
    return t('default_register_premium_error_description');
  };

  renderErrorDescription = () => {
    const { t, error, isAuthenticated, profile } = this.props;
    const { code, message } = error;
    switch (code) {
      case ERROR_CODE.AC_AUTHENTICATE_ERROR: {
        if (typeof message === 'object') {
          if (message.type === ERROR_TYPE.TOKEN_IS_EXPIRED) {
            return t('expired_token_error_description');
          }
          if (message.type === ERROR_TYPE.LOGIN_FAILED) {
            return t('login_failed_error_description');
          }
        }
        if (isAuthenticated) {
          return t('expired_token_error_description');
        }
        return t('not_authenticated_error_description');
      }
      case ERROR_CODE.AC_ERROR_PAYMENT_PREMIUM: {
        if (typeof message === 'object') {
          switch (message.type) {
            case ERROR_TYPE.STRIPE:
              return this.renderStripeErrorDescription();
            case ERROR_TYPE.SYSTEM: {
              const { message: systemMessage } = message;
              if (Object.keys(ERROR_MESSAGE).includes(systemMessage)) {
                return t(ERROR_MESSAGE[systemMessage]);
              }
              return t('register_premium_paypal_content_error');
            }
            default:
              return t('register_premium_paypal_content_error');
          }
        }
        return t('register_premium_paypal_content_error');
      }
      case ERROR_CODE.AC_ERROR_PERMISSION:
        if (typeof message === 'object') {
          switch (message.type) {
            case ERROR_TYPE.REACHED_LIMITED_DOWNLOAD:
              if (profile && profile.role === Config.getRoles().PREMIUM) {
                return t('default_error_description');
              }
              return t(Config.getPrefixContent('modal_limit_download'));
            case ERROR_TYPE.BLOCKED_DOWNLOAD_TEMPORARY:
              return t('block_download_description');
            default:
              return t('default_error_description');
          }
        }
        break;
      case ERROR_CODE.AC_ERROR_NOTFOUND:
        if (ERROR_MESSAGE[message]) {
          return t(Config.getPrefixContent(ERROR_MESSAGE[message]));
        }
        break;
      default:
        return t('default_error_description');
    }
  };

  renderErrorActions = () => {
    const { t, lng, error } = this.props;
    const { code, message } = error;
    if (
      code === ERROR_CODE.AC_AUTHENTICATE_ERROR &&
      message.type !== ERROR_TYPE.LOGIN_FAILED
    ) {
      const handleLogin = () => {
        Cookies.remove('token');
        Cookies.remove('profile');
        window.location.href = `${
          Config.ssoServerUrl
        }/login?lang=${lng}&serviceURL=${encodeURIComponent(
          process.browser ? window.location.href : '',
        )}`;
      };
      return (
        <button
          onClick={handleLogin}
          className={`btn btn-ac-primary btn-${Config.serviceType} btn-block`}
          style={{ padding: '8px 0', marginTop: 10 }}
        >
          <FontIcomoonIcon
            icon="icon-lock"
            style={{ fontSize: 17 }}
            fixedWidth
          />
          {t('login')}
        </button>
      );
    }
    if (
      code === ERROR_CODE.AC_ERROR_PERMISSION &&
      message.type === ERROR_TYPE.REACHED_LIMITED_DOWNLOAD
    ) {
      const joinPremiumButtonClassName = classNames(
        `${Config.serviceType}_color-ac-primary`,
        'btn-join-premium',
      );
      return (
        <div className="premium-actions">
          {/* <PremiumPromotion /> */}
          <a
            className={joinPremiumButtonClassName}
            target="_blank"
            href={`${Config.getSiteURL()}/premium`}
            rel="noreferrer"
          >
            {t('join_premium_enjoy_unlimited_downloads')}
          </a>
        </div>
      );
    }
    return null;
  };

  handleDownloadCertificate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { downloadExtraLicenseCertificate, photo } = this.props;
    downloadExtraLicenseCertificate({
      data: {
        id: photo.id,
        log_id: photo.id,
      },
    });
  };

  renderDownloadELCertificateActions = () => {
    const { t, photo } = this.props;
    return (
      <div
        style={{
          marginTop: '20px',
        }}
      >
        <p>{t('download_your_certificate_el')}</p>
        <ButtonCertificate
          dataId={photo.id}
          logId={
            photo.current_user_extra_license &&
            photo.current_user_extra_license.id
          }
        />
      </div>
    );
  };

  renderIcon = () => {
    const { showInfor } = this.props;
    if (showInfor) {
      return (
        <FontAwesomeIcon
          style={{
            width: 50,
            color: 'green',
          }}
          icon={faCheck}
        />
      );
    }
    return (
      <FontAwesomeIcon
        style={{
          width: 50,
          color: 'rgb(252, 108, 110)',
        }}
        icon={faExclamationCircle}
      />
    );
  };

  render() {
    const { t, error, showError, showIEWarning, showInfor } = this.props;
    let { title, description, actions, typeInfor } = this.props;
    if (showInfor) {
      if (!title) title = 'Success Information';
      if (!description) description = 'Sucessfull';
      if (typeInfor == 'add_extra_license') {
        actions = this.renderDownloadELCertificateActions();
      }
      // if (typeInfor == 'update_invoice_infor') actions = this.renderDownloadELCertificateActions();
    } else if (showIEWarning) {
      title = '';
      description = t('ie_browser_warning');
      actions = null;
    } else {
      if (!error || isEmpty(error)) return null;
      if (error instanceof Error) {
        console.log('CustomErrorMessage', error.message);
        return null;
      }

      if (!title) title = this.renderErrorTitle();
      if (!description) description = this.renderErrorDescription();
      if (!actions) actions = this.renderErrorActions();
    }

    return (
      <Modal
        show={showError || showIEWarning || showInfor}
        onHide={this.handleHideModal}
      >
        <Modal.Body>
          <div className="error-message-modal">
            {this.renderIcon()}
            <div
              className="error-message-title"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <div
              className="error-message-description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            {/* {!showInfor && (
              <div
                className="error-message-description"
                dangerouslySetInnerHTML={{
                  __html: t('contact_us_description', {
                    serviceType: Config.serviceType,
                  }),
                }}
              />
            )} */}
            {actions}
          </div>
        </Modal.Body>
        <style jsx>
          {`
            .error-message-modal {
              width: 100%;
              min-width: 400px;
              background-color: #ffffff;
              border: 1px solid #eff0f1;
              border-radius: 2px;
              padding: 24px;
              text-align: center;
            }
            .error-message-modal .error-message-title {
              color: red;
              font-size: 20px;
              font-weight: 500;
              padding: 10px 0;
            }
            .error-message-modal .error-message-description {
              color: #6e6e70;
            }

            .modal-backdrop {
              height: 100%;
              width: 100%;
              background: ${Config.isIllustAC() ? '#170507' : '#0A1F32'};
              position: fixed;
              top: 0;
              left: 0;
              opacity: 0.75 !important;
            }

            .modal-content {
              border-radius: 2px;
            }
            .modal-content .premium-actions {
              margin-top: 28px;
            }
            .modal-content .premium-actions .time-number {
              font-size: 1em;
            }
          `}
        </style>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
  showError: makeSelectShowError(),
  showInfor: makeSelectShowInfor(),
  showIEWarning: makeSelectShowIEWarning(),
  title: makeSelectTitle(),
  description: makeSelectDescription(),
  typeInfor: makeSelectTypeInfor(),
  isAuthenticated: makeSelectIsAuthenticated(),
  profile: makeSelectProfile(),
  photo: makeSelectPhoto(),
});

const mapDispatchToProps = (dispatch) => ({
  hideError: () => dispatch(hideError()),
  hideIEWarning: () => dispatch(hideIEWarning()),
  hideInfor: () => dispatch(hideInfor()),
  checkIEBrowser: () => dispatch(checkIEBrowser()),
  downloadExtraLicenseCertificate: (payload) =>
    dispatch(downloadExtraLicenseCertificate(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomErrorMessage);
