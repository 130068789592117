/**
 * Gets the repositories of the user from Github
 */

import { takeLatest } from 'redux-saga/effects';

import { LOADING_HIDE, LOADING_SHOW } from './constants';

function loadingShow() {
  document.body.style.overflow = 'hidden';
}

function loadingHide() {
  document.body.style.overflow = '';
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* metaDataSaga() {
  yield takeLatest(LOADING_SHOW, loadingShow);
  yield takeLatest(LOADING_HIDE, loadingHide);
}
