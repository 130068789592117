import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faChevronRight,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import css from 'styled-jsx/css';
import Image from 'next/image';
import constant from '~/../hocs/constant';
import DropdownBottom from '~/components/DropdownBottom';
import DropdownBottomByClick from '~/components/DropdownBottomByClick';
import I18nLink from '~/components/I18nLink';
import UserHeaderInfo from '~/components/Layout/UserHeaderInfo';
import Config, { keyConfig } from '~/config';
import themeVariables from '~/shared/themeVariables';
import { AI_SERVICES, USER_ROLES } from '~/utils/constants';
import { SERVICE_TYPES } from '~/utils/constants';
import IconX from '~/components/svg/icon-x.svg';
import IconCountBulkDownload from '~/components/svg/count-bulk-download.svg';

class MobileView extends React.Component {
  handleToggleLeftMenu = () => {
    const { setIsMobileLeftMenuOpen, layout } = this.props;
    setIsMobileLeftMenuOpen(!layout.isMobileLeftMenuOpen);
  };

  handleToggleRightMenu = () => {
    const { setIsMobileRightMenuOpen, layout } = this.props;
    setIsMobileRightMenuOpen(!layout.isMobileRightMenuOpen);
  };

  handleTogglePhotoCart = () => {
    const { setIsPhotoCartOpen, layout } = this.props;
    setIsPhotoCartOpen(!layout.isPhotoCartOpen);
  };

  renderOtherStockSites = () => {
    const { lng, t } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    if (Config.isPhotoAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('illustrations')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('silhouettes')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
    if (Config.isIllustAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('photos')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('silhouettes')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
    if (Config.isSilhouetteAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('photos')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('illustrations')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
    if (Config.isDesignAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('photos')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('illustrations')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
  };

  renderVideoAC = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <a
        className={linkClassName}
        href="https://video-ac.com/en"
        target="_blank"
        rel="noreferrer"
      >
        {t('header_video')}
      </a>
    );
  };

  renderDesignTool = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    let siteURL = `${Config.domainService.editorAC}/en`;
    if (lng === 'tw' || lng === 'ko' || lng === 'vi') {
      siteURL = `${Config.domainService.editorAC}/${lng}`;
    }

    return (
      <a
        className={linkClassName}
        href={siteURL}
        target="_blank"
        rel="noreferrer"
      >
        {t('header_design_tool')}
      </a>
    );
  };

  renderFileTransferService = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <a
        className={linkClassName}
        href="https://ac-data.info/en"
        target="_blank"
        rel="noreferrer"
      >
        {t('file_transfer')}
      </a>
    );
  };

  renderBlog = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <a
        className={linkClassName}
        target="_blank"
        href={Config.renderLinkBlog(lng)}
        rel="noreferrer"
      >
        {t('blog')}
      </a>
    );
  };

  renderCategories = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/categories">
        <a className={linkClassName}>{t('categories')}</a>
      </I18nLink>
    );
  };
  renderCollections = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/collections">
        <a className={linkClassName}>{t('collections')}</a>
      </I18nLink>
    );
  };
  renderPricing = () => {
    const { t, profile, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    if (
      profile.role === USER_ROLES.PREMIUM ||
      profile.role === USER_ROLES.PREMIUM_NO_ADS ||
      (profile.role === USER_ROLES.CREATOR && Config.isCreatorAvailable(lng))
    ) {
      return null;
    }

    return (
      <I18nLink href="/premium">
        <a className={linkClassName}>{t('pricing')}</a>
      </I18nLink>
    );
  };

  renderSellYourContent = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/sell-your-content">
        <a className={linkClassName}>{t('sell_your_content')}</a>
      </I18nLink>
    );
  };

  renderAIProjectsDropdown = () => {
    const { t, lng } = this.props;
    const allServices = AI_SERVICES;

    return (
      <DropdownBottomByClick
        style={{
          height: '100%',
          fontWeight: lng === 'tw' || lng === 'cn' ? 500 : 600,
          fontSize: 14,
          textTransform: 'uppercase',
          marginLeft: 4,
        }}
        colorIcon="black"
        header={t('artificial_intelligence_projects')}
      >
        <div className="menu-ai-projects">
          {allServices.map((item, index) => (
            <a
              key={index}
              className="menu-item"
              href={item.href}
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-title">
                <img
                  className="lazyload img-logo"
                  data-src={item.logoUrl}
                  alt="site logo"
                />
                {item.serviceName}
              </div>
              <span className="project-description">{t(item.explain)}</span>
            </a>
          ))}
        </div>
        <style jsx>
          {`
            .menu-ai-projects {
              display: flex;
              flex-direction: column;
              color: ${themeVariables.color.dark};
              width: 100%;
              padding: 0 ${themeVariables.space.base}px;
            }
            .menu-item {
              padding: ${themeVariables.space.small}px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: column;
            }
            .menu-item:hover {
              background: ${themeVariables.color.gray};
            }
            .img-logo {
              height: 20px;
              margin-right: ${themeVariables.space.extraSmall}px;
            }
            .project-title {
              font-weight: 500;
            }
            .project-description {
              font-size: 12px;
              margin-top: ${themeVariables.space.extraSmall}px;
            }
          `}
        </style>
      </DropdownBottomByClick>
    );
  };

  // renderSNS = () => {
  //   const { lng } = this.props;

  //   const allServices = [
  //     {
  //       serviceName: 'Facebook',
  //       logoUrl: `${Config.staticPrefix}/static/assets/image/facebook-logo.jpg`,
  //       href: Config.renderLink(lng).link_fb,
  //     },
  //     {
  //       serviceName: 'Blog',
  //       logoUrl:
  //         'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAACXElEQVRYw8WXLaizcBTGfYPBsmKxmEwrgsVgWVhaMaxZhMGwyWAMjIuDlbGwsmAayJJlsLAgS6sLK4MVi2WwCfsAETk3XBzv0OPX3X3fwROGx+f5Hf17/EsAAFFWvu+DqqpAURRQFAWapsHj8YAqXqVPAABClmUgCOJNnU7n3wA4jpMIj7Xf70tDlAaQJAkFkGX5dwHW6zUaHmu325WCKAUgCEIuQLPZ/B0A27Zzw2M5jlMYolBRFEVQr9cLA0iS9FmAxWJRODzWarUqBJFbEIYhcByXGkJRFAogCMJnAObzORrS6/VAVVX0+HK5zIXIPBgEAbAsi3bveR6cTicgSTK1pl6vQxiGUBlgMpmg3RmG8TLWNA2tM02zGsDj8QCGYVJNa7UaXC6Xl7Hneeh64DgOgiCA0gCj0QjtajgcJgz7/T5aP5vNygFcr1egaTrVjKZpuN1uCcPz+YxeBYZh4Pl8QmGA4XCIdjMej9FuqpxHpHVSq9VKd1L1yiVMDMOodC+rrp23P1mrmWXZzNUc636/Zz495/MZUABd1ys/z39rOp0Wmh9vAK7rohON47jciVZ2giYAOp0OSm1ZVuHwWKZpon66rr8DZM1znuchiqLSAFlvUZIkwXVdeAEoioLS2rZdOjyWZVmob7yNJw6HA1okimLlcIDvnRTP8+hVOB6PQLTbbRRgvV7/CAAgey+pKAoQ2L1vNBo/Do8liiK+o8IGz3a7/RjAZrPBAdKGT5UvnDy1Wq1EjqZp30NjMBgATdNA0zR0u13wff/jAJfLBRRFSXxR/wEA4n/+vgBMWAFqNeFiVAAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=',
  //       href:
  //         lng === 'tw'
  //           ? 'http://tw.blog.acworks.co.jp/'
  //           : 'http://en.blog.acworks.co.jp/',
  //     },
  //   ];

  //   return (
  //     <DropdownBottomByClick
  //       style={{ width: '100%', color: themeVariables.color.white }}
  //       header="SNS"
  //     >
  //       <div className="menu-sns">
  //         {allServices.map((item, index) => (
  //           <a
  //             key={index}
  //             className="menu-item"
  //             href={item.href}
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             <img className="lazyload img-logo" data-src={item.logoUrl} />
  //             {item.serviceName}
  //           </a>
  //         ))}
  //       </div>
  //       <style jsx>
  //         {`
  //           .menu-sns {
  //             display: flex;
  //             flex-direction: column;
  //             width: 100%;
  //             padding: 0 ${themeVariables.space.base}px;
  //           }
  //           .menu-item {
  //             padding: ${themeVariables.space.small}px;
  //             color: ${themeVariables.color.dark};
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             white-space: nowrap;
  //           }
  //           .menu-item:hover {
  //             background: ${themeVariables.color.gray};
  //           }
  //           .img-logo {
  //             height: 20px;
  //             margin-right: ${themeVariables.space.extraSmall}px;
  //           }
  //         `}
  //       </style>
  //     </DropdownBottomByClick>
  //   );
  // };

  // renderCreatorsLink = () => {
  //   const { t } = this.props;

  //   return (
  //     <>
  //       <I18nLink href={{ pathname: '/creators' }}>
  //         <a className="link-creators">{t('creators')}</a>
  //       </I18nLink>
  //       <style jsx>
  //         {`
  //           .link-creators {
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             color: ${themeVariables.color.white};
  //             padding: ${themeVariables.space.half}px;
  //           }
  //         `}
  //       </style>
  //     </>
  //   );
  // };

  // renderPremiumButton = () => {
  //   const { t, profile, lng } = this.props;
  //   if (
  //     profile.role === USER_ROLES.PREMIUM ||
  //     profile.role === USER_ROLES.PREMIUM_NO_ADS ||
  //     (profile.role === USER_ROLES.CREATOR && Config.isCreatorAvailable(lng))
  //   ) {
  //     return null;
  //   }

  //   return (
  //     <>
  //       <I18nLink href={{ pathname: '/premium' }}>
  //         <a className="link-othersite">
  //           <img
  //             src={`${Config.staticPrefix}/static/assets/image/premium/crown.png`}
  //             className="icon-premium"
  //             alt="crown"
  //           />
  //           {t('upgrade')}
  //         </a>
  //       </I18nLink>
  //       <style jsx>
  //         {`
  //           .link-othersite {
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             color: ${themeVariables.color.white};
  //             padding: ${themeVariables.space.half}px;
  //           }

  //           .icon-premium {
  //             height: 20px;
  //             margin-right: ${themeVariables.space.extraSmall}px;
  //           }
  //         `}
  //       </style>
  //     </>
  //   );
  // };

  renderPhotoCart = () => {
    const { profile, toggleSlider, photosSlider, layout } = this.props;
    if (profile.role !== USER_ROLES.PREMIUM) return null;

    return (
      <div className="photo-cart" onClick={this.handleTogglePhotoCart}>
        {/* <FontAwesomeIcon
          icon={faFolderOpen}
          style={{
            color: themeVariables.color.dark,
            marginRight: themeVariables.space.extraSmall,
            fontSize: themeVariables.fontSize.large,
          }}
        />
        <div className="count-photo">{layout.photosInCart.length}</div> */}
        <IconCountBulkDownload />
        <div className="container-count-photo">
          <div className="container-arrow-left">
            <div className="arrow-left"></div>
          </div>
          <div className="count-photo">{layout.photosInCart.length}</div>
        </div>
        <style jsx>
          {`
            .photo-cart {
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              margin-right: ${themeVariables.space.small}px;
            }
            .count-photo {
              min-width: 12px;
              border-radius: 4px;
              padding: 2px ${themeVariables.space.small}px;
              font-size: ${themeVariables.fontSize.small}px;
              font-weight: bold;
              display: flex;
              flex-direction: row;
              align-items: center;
              background: #1d7ab3;
              color: #ffffff;
            }
            .container-count-photo {
              display: flex;
              margin-left: 4px;
            }
            .container-arrow-left {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .arrow-left {
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-right: 4px solid #1d7ab3;
            }
          `}
        </style>
      </div>
    );
  };

  getSSORedirectURL = () => {
    const { lng } = this.props;
    let redirectURL = window.location.href;
    if (
      redirectURL.includes('creator-registration') ||
      redirectURL.includes('login')
    ) {
      redirectURL = Config.getSiteURL();
    }
    return redirectURL;
  };

  handleLoginSSO = () => {
    const { lng } = this.props;
    window.open(
      `${Config.ssoServerUrl}/login?lang=${lng}&serviceURL=${encodeURIComponent(
        this.getSSORedirectURL(),
      )}`,
      '_self',
    );
  };

  handleSignUpSSO = () => {
    const { lng } = this.props;
    window.open(
      `${Config.ssoServerUrl}/login?lang=${lng}&serviceURL=${encodeURIComponent(
        this.getSSORedirectURL(),
      )}`,
      '_blank',
    );
  };

  renderLoginAndSignUpForDownloader = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <div className="login-signup-wrapper">
        <a
          className={
            Config.isIllustAC()
              ? `${linkClassName} button-illust-signup`
              : linkClassName
          }
          onClick={this.handleLoginSSO}
        >
          {t('Login')}
        </a>
        {this.renderSignUpDownloader()}

        <style jsx>
          {`
            .login-signup-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .btn-login {
              color: ${themeVariables.color.dark};
              font-weight: 500;
              font-size: 14px;
              text-transform: uppercase;
            }
            .button-illust-signup {
              width: 84px;
              height: 40px !important;
              min-height: 40px !important;
              border: 1px solid #7a7a7a;
              border-radius: 40px;
              font-weight: 700;
            }
            .btn-login:hover {
              color: ${themeVariables.colors.primary};
              text-decoration: underline !important;
            }
          `}
        </style>
      </div>
    );
  };

  renderAILink = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/ai-projects">
        <a className={linkClassName} target="_blank">
          {t('artificial_intelligence_projects')}
        </a>
      </I18nLink>
    );
  };

  renderLogin = () => {
    const { t, lng } = this.props;

    return (
      <DropdownBottom
        style={{
          height: '100%',
          marginLeft: 16,
          display: 'flex',
        }}
        align="right"
        noArrow
        header={<span className="btn-login">{t('Login')}</span>}
      >
        <div className="menu-login">
          <a className="menu-item" onClick={this.handleLoginSSO}>
            {t('downloader_login')}
          </a>
          <I18nLink href={{ pathname: '/login' }}>
            <a className="menu-item">
              {t(Config.getPrefixContent('creator_login'))}
            </a>
          </I18nLink>
        </div>
        <style jsx>
          {`
            .btn-login {
              color: ${themeVariables.color.dark};
              font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
              font-size: 14px;
              text-transform: uppercase;
            }

            .menu-login {
              display: flex;
              flex-direction: column;
              min-width: 150px;
            }

            .menu-item {
              padding: ${themeVariables.space.small}px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              align-items: center;
              white-space: nowrap;
            }
            .menu-item:hover {
              background: ${themeVariables.color.gray};
            }
          `}
        </style>
      </DropdownBottom>
    );
  };

  renderSignUpDownloader = () => {
    const { t, lng } = this.props;

    return (
      <a className="btn-signup-downloader" onClick={this.handleSignUpSSO}>
        {t('downloader_signup')}
        <style jsx>
          {`
            .btn-signup-downloader {
              color: ${themeVariables.color.white};
              align-self: center;
              background: #1b2833;
              width: 84px;
              height: 40px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin-left: 16px;
              text-transform: uppercase;
              font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
              font-size: 14px;
              ${Config.isIllustAC()
                ? 'border-radius: 40px;'
                : `text-transform: uppercase;
                border-radius: 4px ;`}
            }

            .btn-signup-downloader:hover {
              background: ${themeVariables.colors.primary};
            }
          `}
        </style>
      </a>
    );
  };

  renderSignUpCreator = () => {
    const { t, lng } = this.props;

    return (
      <I18nLink href={{ pathname: '/creator-registration' }}>
        <a className="btn-signup-creator">
          {t(Config.getPrefixContent('creator_signup'))}
          <style jsx>
            {`
              .btn-signup-creator {
                color: ${themeVariables.color.dark};
                margin-left: ${themeVariables.space.half}px;
                font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
                font-size: 14px;
                text-transform: uppercase;
                width: -webkit-fill-available;
              }
              .btn-signup-creator:hover {
                color: ${themeVariables.colors.primary};
                text-decoration: underline !important;
              }
            `}
          </style>
        </a>
      </I18nLink>
    );
  };

  renderLoginAndSignup = () => {
    const { lng } = this.props;

    if (Config.isCreatorAvailable(lng)) {
      return (
        <>
          {/* {this.renderSignUpCreator()} */}
          {this.renderSignUpDownloader()}
          {this.renderLogin()}
        </>
      );
    }

    return this.renderLoginAndSignUpForDownloader();
  };

  // renderMoreMenu = () => {
  //   const { t, lng } = this.props;

  //   return (
  //     <DropdownBottomByClick
  //       style={{ width: '100%' }}
  //       align="right"
  //       noArrow
  //       header={
  //         <FontAwesomeIcon
  //           style={{
  //             color: themeVariables.color.white,
  //             fontSize: themeVariables.fontSize.large,
  //           }}
  //           icon={faEllipsisH}
  //         />
  //       }
  //     >
  //       <div className="menu-more">
  //         <I18nLink href={{ pathname: '/tags' }}>
  //           <a className="menu-item">{t('popular_tags')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/terms' }}>
  //           <a className="menu-item">{t('Terms of service')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/privacy-policy' }}>
  //           <a className="menu-item">{t('Privacy Policy')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/about-us' }}>
  //           <a className="menu-item">{t('About us')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/help-page' }}>
  //           <a className="menu-item">{t('FAQ')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/inquiry' }}>
  //           <a className="menu-item">{t('inquiry')}</a>
  //         </I18nLink>
  //       </div>
  //       <style jsx>
  //         {`
  //           .menu-more {
  //             display: flex;
  //             flex-direction: column;
  //             min-width: 150px;
  //           }

  //           .menu-item {
  //             padding: ${themeVariables.space.small}px;
  //             color: ${themeVariables.color.dark};
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             white-space: nowrap;
  //           }
  //           .menu-item:hover {
  //             background: ${themeVariables.color.gray};
  //           }
  //         `}
  //       </style>
  //     </DropdownBottomByClick>
  //   );
  // };

  renderLeftMenu = () => {
    const { layout, profile, lng } = this.props;
    if (!layout.isMobileLeftMenuOpen) return null;

    return (
      <div className="left-menu">
        {this.renderOtherStockSites()}
        {this.renderVideoAC()}
        {this.renderDesignTool()}
        {this.renderFileTransferService()}
        {this.renderBlog()}
        {this.renderCategories()}
        {!Config.isDesignAC() && this.renderCollections()}

        {/* {Config.isCreatorAvailable(lng) && this.renderSellYourContent()} */}
        {this.renderPricing()}
        {/* {this.renderAIProjectsDropdown()} */}
        {this.renderAILink()}
        {/* {this.renderCategoryDropdown()}
        {this.renderStockSitesDropdown()}
        {this.renderSNS()}
        {this.renderCreatorsLink()}
        {this.renderPremiumButton()}
        {this.renderMoreMenu()} */}
        <style jsx>
          {`
            .left-menu {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              width: 100%;
              max-height: calc(100vh - 100%);
              background: ${themeVariables.color.white};
              overflow-y: auto;
            }
          `}
        </style>
      </div>
    );
  };

  render() {
    const logoInfos = {
      [SERVICE_TYPES.PHOTO_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_photo_ac_new.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/logo.png`,
        descriptionKey: 'free_stock_photos_ac',
      },
      [SERVICE_TYPES.ILLUST_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_illustAc_header_mobile.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/illust.png`,
        descriptionKey: 'free_vector_clip_art',
      },
      [SERVICE_TYPES.SILHOUETTE_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_silhouetteAc_header_mobile.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/silhouetteAc_logo.png`,
        descriptionKey: 'free_vector_silhouettes',
      },
      [SERVICE_TYPES.DESIGN_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_designAc_header_mobile.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/design.png`,
        descriptionKey: 'free_design_templates',
      },
    };
    const logoInfo = logoInfos[Config.serviceType];

    const { profile, isAuthenticated, layout, lng } = this.props;
    let showUserInfo = false;
    if (isAuthenticated) {
      if (profile.role !== USER_ROLES.CREATOR) {
        showUserInfo = true;
      } else if (
        profile.role === USER_ROLES.CREATOR &&
        Config.isCreatorAvailable(lng)
      ) {
        showUserInfo = true;
      }
    }

    const { styles: linkStyles } = getLinkStyles({ lng });

    return (
      <div className="mobile-view">
        {layout.isMobileLeftMenuOpen ? (
          <IconX onClick={this.handleToggleLeftMenu} />
        ) : (
          <FontAwesomeIcon
            style={{
              color: themeVariables.color.dark,
              width: 24,
            }}
            icon={faBars}
            onClick={this.handleToggleLeftMenu}
          />
        )}
        {/* <div className="logo-mobile">
          <I18nLink href={{ pathname: '/' }}>
            <Image
              className="lazyload img-logo-desktop"
              src={logoInfo.mobile}
              alt="site logo"
              layout="fixed"
              objectFit="contain"
              width={'40px'}
              height={'28px'}
            />
          </I18nLink>
        </div> */}

        <div className="right-section">
          {!Config.isDesignAC() && this.renderPhotoCart()}
          {showUserInfo ? (
            <UserHeaderInfo
              onToggleMenu={this.handleToggleRightMenu}
              isMenuOpen={layout.isMobileRightMenuOpen}
            />
          ) : (
            this.renderLoginAndSignup()
          )}
        </div>
        {this.renderLeftMenu()}
        {linkStyles}
        <style jsx>
          {`
            .mobile-view {
              position: relative;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px;
            }

            .right-section {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 100%;
            }
            .logo-mobile {
              position: absolute;
              left: 56px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            @media (min-width: 769px) {
              .logo-mobile {
                display: none;
              }
            }

            @media (min-width: ${themeVariables.breakpoints.tabletL
                .minWidth}px) {
              .mobile-view {
                display: none;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

function getLinkStyles({ lng }) {
  return css.resolve`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${themeVariables.colors.bodyText};
    font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
    font-size: 14px;
    height: 48px;
    min-height: 48px;
    text-transform: uppercase;
    padding-left: 16px;

    :hover {
      color: ${themeVariables.colors.primary};
      text-decoration: underline !important;
    }
  `;
}

export default MobileView;
