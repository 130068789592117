import axios from '../interceptor-axios';
import config from '~/config';

function getNotice(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiNotice}/news`,
    params: {
      lang: params.lang,
      page: params.page,
      max_results: params.max_results,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

export { getNotice };
