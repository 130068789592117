import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import withI18next from './_withI18next';
import withSocket from './withSocket';
import createAPI from '~/services/api';
import Cookies from '~/services/Cookies';
import config from '~/config';
import SSO from '~/services/SSO';
import verifyJwtToken from '~/utils/jwtVerify';
import Layout from '~/containers/Layout';

import { supportedLanguages } from '~/../next-i18next.config';
import { updateCurrentLanguage } from '~/containers/Language/action';
import {
  loadProfile,
  loadToken,
  loadSessionId,
  logout,
  setIsAuthenticated,
} from '~/containers/AuthProvider/actions';
import {
  loadCountData,
  loadTotalUser,
  loadCountDownload,
} from '~/containers/HomePage/actions';
import { loadMetadata } from '~/containers/MetadataProvider/actions';
import { changeAccountType } from '~/containers/AccountTypeProvider/actions';
import { DOWNLOADER_ACCOUNT } from '~/containers/AccountTypeProvider/constants';
import { device } from '~/utils';
import {
  loadGoogleAnalyticsForChinaSites,
  loadGoogleAnalyticsForGlobalSites,
  loadSyndicationAds,
} from '~/utils/file-helper';
import { USER_ROLES } from '~/utils/constants';
import { loadingHide, loadingShow } from '~/containers/Loading/actions';

/*
Usage: Only use this for pages
*/
const pageHoc = (Page) => {
  class DefaultPage extends React.Component {
    state = {
      isLoading: false,
    };

    async componentDidMount() {
      const {
        currentLanguage,
        changeAccountType,
        lng,
        loadToken,
        loadProfile,
        loadSessionId,
        setIsAuthenticated,
      } = this.props;

      // Although if condition is useless in getStaticProps,
      // but it's maybe helpful to keep it in case rollback to getServerSideProps
      if (typeof window !== 'undefined') {
        const searchParams = new URLSearchParams(window.location.search);

        let token = searchParams.get('token') || Cookies.get('token');
        let sessionId = Cookies.get('sessionId');
        let profile = Cookies.get('profile');

        let isFirstLogin = false;
        let ssoToken = searchParams.get('ssoToken');
        let isAuthenticated = !!token;

        const currentURL = window.location.href;
        // if (currentURL.includes('?ssoToken')) {
        //   ssoToken = currentURL.split('?ssoToken=')[1];
        // }

        Cookies.set('i18next', currentLanguage);

        const accountType = config.creatorAvailableLanguages.includes(
          currentLanguage,
        )
          ? Cookies.get('accountType') || DOWNLOADER_ACCOUNT
          : DOWNLOADER_ACCOUNT;
        changeAccountType(accountType);

        if (ssoToken) {
          this.setState({
            isLoading: true,
          });
          try {
            const response = await SSO.verifyToken(ssoToken);
            const { token } = response.data;
            const decoded = await verifyJwtToken(token);
            if (decoded) {
              sessionId = decoded.global_session_id;
              isFirstLogin = true;
              Cookies.set('sessionId', sessionId);
            }
            if (isFirstLogin) {
              try {
                const response = await SSO.getUserData(sessionId);
                const userData = response.data;

                if (userData && userData.user) {
                  let { email, nick_name, avatar } = userData.user;

                  let type = userData.user.type || 'VIA_SSO';
                  let language = lng;

                  if (
                    avatar &&
                    avatar.path &&
                    avatar.path.startsWith('/profile-images')
                  ) {
                    avatar.path = `/static/assets/image${avatar.path}`;
                  }

                  const verifyUserResponse = await SSO.verifyUser({
                    email,
                    nickname: nick_name,
                    avatar: avatar && avatar.path,
                    type,
                    language,
                  });
                  const verifyUser = verifyUserResponse.data;
                  loadToken(verifyUser.token);
                  loadProfile(verifyUser.profile);
                  loadSessionId(sessionId);
                  setIsAuthenticated(true);
                  this.changeURLAfterSSO(currentURL);
                }
              } catch (err) {
                this.changeURLAfterSSO(currentURL);
              }
            } else {
              let isValidSessionId;
              try {
                isValidSessionId = await SSO.getSessionUser(sessionId);
              } catch (error) {
                isValidSessionId = null;
              }

              if (isValidSessionId && !isValidSessionId.error) {
                Cookies.set('sessionId', sessionId);
              }
              this.changeURLAfterSSO(currentURL);
            }
          } catch (err) {
            this.changeURLAfterSSO(currentURL);
          }

          this.setState({
            isLoading: false,
          });
        } else {
          if (profile) {
            profile = JSON.parse(profile);
            Cookies.set('accountType', profile.role || USER_ROLES.CREATOR);
          }
          try {
            const api = createAPI();
            if (token && !profile) {
              const response = await api.user.getProfile(
                currentLanguage,
                token,
              );
              loadProfile(response.profile);
              profile = response.profile;
              isAuthenticated = true;
              Cookies.set('accountType', profile.role || USER_ROLES.CREATOR);
            }

            if (token && profile) {
              loadToken(token);
              loadProfile(profile);
              loadSessionId(sessionId);
            }
            if (!token || !profile || profile?.role !== USER_ROLES.PREMIUM) {
              if (device.isTabletOrDesktop()) {
                setTimeout(() => this.loadAds(), 3000);
              } else {
                window.addEventListener('scroll', this.loadAds);
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 403) {
              logout();
              isAuthenticated = false;
            }
          }
          setIsAuthenticated(isAuthenticated);
        }
      }

      // if (config.isChina()) {
      //   setTimeout(() => {
      //     loadGoogleAnalyticsForChinaSites(lng);
      //   }, 3000);
      // } else {
      //   setTimeout(() => {
      //     loadGoogleAnalyticsForGlobalSites(lng);
      //   }, 3000);
      // }
    }

    async componentDidUpdate() {
      // While still loading user from SSO, don't do anything
      if (this.state.isLoading) return;

      //fix bug reload lan 2 trong detail page, click vao related
      const {
        loadToken,
        loadProfile,
        loadSessionId,
        setIsAuthenticated,
        currentLanguage,
      } = this.props;
      let token = Cookies.get('token');
      let sessionId = Cookies.get('sessionId');
      let profile = Cookies.get('profile');
      let isAuthenticated = !!token;

      if (profile) {
        profile = JSON.parse(profile);
        Cookies.set('accountType', profile.role || USER_ROLES.CREATOR);
      }
      try {
        const api = createAPI();
        if (token && !profile) {
          const response = await api.user.getProfile(currentLanguage, token);
          loadProfile(response.profile);
          profile = response.profile;
          isAuthenticated = true;
          Cookies.set('accountType', profile.role || USER_ROLES.CREATOR);
        }

        if (token && profile) {
          loadToken(token);
          loadProfile(profile);
          loadSessionId(sessionId);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          logout();
          isAuthenticated = false;
        }
      }
      setIsAuthenticated(isAuthenticated);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.loadAds);
    }

    loadAds = () => {
      window.removeEventListener('scroll', this.loadAds);
      const { isAuthenticated, profile } = this.props;
      if (
        !isAuthenticated ||
        (profile && profile.role !== USER_ROLES.PREMIUM)
      ) {
        setTimeout(loadSyndicationAds, 0);
      }
    };

    changeURLAfterSSO(currentURL) {
      const { pathname, search } = window.location;
      const searchParams = new URLSearchParams(search);
      searchParams.forEach((value, key) => {
        console.log('searchParams', value, key);
      });
      searchParams.delete('ssoToken');
      const params = searchParams.toString();
      window.history.pushState('', '', `${pathname}${params && '?' + params}`);
    }

    render() {
      const { t } = this.props;
      const { isLoading } = this.state;
      if (isLoading)
        return (
          <Layout>
            <div className="layout-static">
              {t('common:sign_in_and_redirect')}
              <FontAwesomeIcon
                icon={faSpinner}
                pulse
                style={{ width: 48, margin: 16 }}
              />
            </div>
            <style jsx>
              {`
                .layout-static {
                  width: 100%;
                  height: 500px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  font-size: 24px;
                }
              `}
            </style>
          </Layout>
        );

      return <Page {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    loadToken: (token) => dispatch(loadToken(token)),
    loadProfile: (profile) => dispatch(loadProfile(profile)),
    changeAccountType: (accountType) =>
      dispatch(changeAccountType(accountType)),
    loadSessionId: (sessionId) => dispatch(loadSessionId(sessionId)),
    setIsAuthenticated: (isAuthenticated) =>
      dispatch(setIsAuthenticated(isAuthenticated)),
    loadingShow: () => dispatch(loadingShow()),
    loadingHide: () => dispatch(loadingHide()),
    logout: () => dispatch(logout()),
  });

  return connect(null, mapDispatchToProps)(DefaultPage);
};

const DefaultPageNew = (namespaces = ['common']) => (Page) =>
  withSocket(withI18next(namespaces)(pageHoc(Page)));

export default DefaultPageNew;

export async function defaultPageStaticContext(ctx) {
  let requestLang = process.env.LANGUAGE;

  // Init Metadata
  requestLang = ctx.locale || process.env.LANGUAGE;
  if (supportedLanguages.includes(requestLang)) {
    ctx.store.dispatch(
      loadMetadata({
        isServer: typeof window === 'undefined',
        language: requestLang,
        link: '/',
      }),
    );

    ctx.store.dispatch(updateCurrentLanguage(requestLang));
  }

  ctx.store.dispatch(loadCountData());
  ctx.store.dispatch(loadTotalUser());
  ctx.store.dispatch(loadCountDownload());

  const api = createAPI();

  return {
    ...ctx,
    currentLanguage: requestLang,
    api,
  };
}
