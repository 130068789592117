import React from 'react';
import Config from '~/config';
import locales from '~/utils/locales';

function getLanguageAlternates(pagePath) {
  const languageAlternates = Config.supportedHrefLangs
    .map((lang, index) => {
      const hrefLang = lang;
      if (hrefLang === 'zh-Hans') {
        return {
          hrefLang,
          href: `${Config.getSiteURL(null, 'cn')}${pagePath}`,
        };
      } else if (hrefLang === 'ja') {
        if (Config.isDesignAC()) return null;
        if (pagePath !== '') return null;
        return {
          hrefLang,
          href: `${Config.getJapanDomainByService()}${pagePath}`,
        };
      } else {
        return {
          hrefLang,
          href: `${Config.getSiteURL(
            Config.serviceType,
            Config.supportedLanguages[index],
          )}${pagePath}`,
        };
      }
    })
    .filter((item) => item);
  return languageAlternates;
}

function getCanonical(pagePath, lng) {
  lng = locales.convertToShortType(lng);
  const domainURL = Config.getSiteURL();
  let canonical = `${domainURL}${pagePath || '/'}`;
  // if (lng === 'en') {
  //   canonical = `${domainURL}${pagePath}`;
  // }
  return canonical;
}

// Manually regenerate hreflangs and canonical
function generateCanonicalAndLanguageAlternates(pagePath, lng) {
  // Add canonical link
  const canonical = getCanonical(pagePath, lng);
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute('href', canonical);

  // Add hreflang links
  const languageAlternates = getLanguageAlternates(pagePath);
  languageAlternates.forEach(({ hrefLang, href }) => {
    let alternateLink = document.querySelector(
      `link[rel="alternate"][hreflang=${hrefLang}]`,
    );
    if (!alternateLink) {
      alternateLink = document.createElement('link');
      alternateLink.setAttribute('rel', 'alternate');
      alternateLink.setAttribute('hreflang', hrefLang);
      document.head.appendChild(alternateLink);
    }
    alternateLink.setAttribute('href', href);
  });
}

function HrefLangs({ pagePath, lng }) {
  if (Config.isChina()) return null;

  const languageAlternates = getLanguageAlternates(pagePath);
  const canonical = getCanonical(pagePath, lng);

  return (
    <>
      <link rel="canonical" href={canonical} />
      {languageAlternates.map(({ hrefLang, href }) => (
        <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={href} />
      ))}
    </>
  );
}

export default HrefLangs;
export {
  getLanguageAlternates,
  getCanonical,
  generateCanonicalAndLanguageAlternates,
};
