import axios from '../interceptor-axios';
import config from '~/config';

function uploadProperty(property) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/property/release`,
    data: {
      name: property.name,
      s3_path: property.path,
      basename: property.basename,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function getProperties(page = 1, max_results = 10, filter = {}) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/property/release`,
    params: {
      page,
      keyword: filter.keyword,
      createdAt: filter.createdAt,
      max_results,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function getDataByPropertyId(params) {
  const sortByPage = params.sortByPage.split('.');
  const paramsRequest = {
    id: params.id,
    page: params.page,
    status: params.status,
    createdAt: params.createdAt,
    max_results: params.itemPerPage,
    service_type: config.serviceType,
  };
  paramsRequest[sortByPage[0]] = sortByPage[1];
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/property/release/data`,
    params: paramsRequest,
  };
  return axios(request);
}

function update(property) {
  const request = {
    method: 'PUT',
    url: `${config.apiUrl.apiUser}/property/release`,
    data: {
      id: property.id,
      name: property.name,
      s3_path: property.path,
      basename: property.basename,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

export { uploadProperty, getProperties, getDataByPropertyId, update };
