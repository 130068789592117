import React, { useState, useEffect } from 'react';
import css from 'styled-jsx/css';
import Tag from './Tag';
import DataTag from './data';
import DataTagPeopleCategoryIllust from './PeopleTagIllust';
import DataTagPeopleCategoryPhoto from './PeopleTagPhoto';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import Container from '~/components/Container';
import themeVariables from '~/shared/themeVariables';

const TagTrending = ({ t, lng, nameCategory, isCategoryPeople }) => {
  // const { t, lng, nameCategory, isCategoryPeople } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      if (window.innerWidth < themeVariables.breakpoints.tabletS.minWidth) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  let data = DataTag.getData(lng);
  if (!data || data.length <= 0) return null;
  if (
    isCategoryPeople &&
    lng === 'en' &&
    (Config.isPhotoAC() || Config.isIllustAC())
  ) {
    if (Config.isPhotoAC()) {
      data = DataTagPeopleCategoryPhoto.getData(lng);
    }
    if (Config.isIllustAC()) {
      data = DataTagPeopleCategoryIllust.getData(lng);
    }
  }
  let titleTag = t(Config.getPrefixContent('title_trending_tag'));
  if (nameCategory) {
    titleTag = t(Config.getPrefixContent('title_trending_tag_category'), {
      nameCategory,
    });
  }

  return (
    <div className="tag-trending">
      <Container className={containerHeaderClassName}>
        <h2 className="title-trending-text">{titleTag}</h2>
      </Container>
      <Container
        className={containerClassName}
        contentStyle={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 36,
        }}
      >
        {data.slice(0, isMobile ? 40 : data.length).map((tag, index) => {
          return <Tag data={tag} key={index} />;
        })}
      </Container>
      <style jsx>{styles}</style>
      {containerStyles}
      {containerHeaderStyles}
    </div>
  );
};
const { className: containerClassName, styles: containerStyles } = css.resolve`
  position: relative;
  background: #f8f9fa;
  // @media (max-width: 500px) {
  //   background: none;
  // }
`;
const {
  className: containerHeaderClassName,
  styles: containerHeaderStyles,
} = css.resolve`
  margin-top: 32px;
  background: #f8f9fa;
  // @media (max-width: 500px) {
  //   background: #f8f9fa;
  // }
`;
const styles = css`
  .tag-trending {
    margin-top: 12px;
  }
  .title-trending-text {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
  }
  .title-trending a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    text-decoration-line: underline;
    color: ${themeVariables.colors.primary};
  }
  .title-trending a:hover {
    text-decoration-line: underline !important;
  }
  .content-trending-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 36px;
    justify-content: center;
  }

  @media screen and (max-width: ${themeVariables.breakpoints.tabletS
      .minWidth}px) {
    .title-trending-text {
      text-align: center;
      padding-bottom: 24px;
      font-size: 24px;
    }
  }
`;

export default withI18next()(TagTrending);
