import { createSelector } from 'reselect';
import { AUTHENTICATED } from './constants';

const selectAuth = (state) => state.auth;

const makeSelectToken = () =>
  createSelector(selectAuth, (authState) => authState.token);

const makeSelectProfile = () =>
  createSelector(selectAuth, (authState) => authState.profile);

const makeSelectIsAuthenticated = () =>
  createSelector(selectAuth, (authState) => authState.isAuthenticated);

const makeSelectIsVerified = () =>
  createSelector(
    selectAuth,
    (authState) => authState.profile.status === AUTHENTICATED,
  );

const makeSelectStatus = () =>
  createSelector(selectAuth, (authState) => authState.profile.status);

const makeSelectPremiumUser = () =>
  createSelector(selectAuth, (authState) => authState.checkPremium);

export {
  selectAuth,
  makeSelectToken,
  makeSelectProfile,
  makeSelectIsAuthenticated,
  makeSelectIsVerified,
  makeSelectStatus,
  makeSelectPremiumUser,
};
