import axios from './interceptor-axios/axios-no-param';
import config from '~/config';

function getSimilarData({
  keyword,
  serviceTypeData,
  page = 1,
  max_results = 100,
}) {
  let serviceType = serviceTypeData.replace('_ac', '');
  if (serviceType === 'design') {
    serviceType = 'template';
  }

  const request = {
    method: 'GET',
    // url: `${config.apiUrl.apiSimilar}/similarData`,
    // params: {
    //   lang: params.lang,
    //   page: params.page || 1,
    //   per_page: params.per_page || 100,
    //   max_results: params.per_page || 100,
    //   disp_similar: params.disp_similar || false,
    //   order_by: params.order_by || 'popular',
    //   service_type_for_similar:
    //     params.service_type_for_similar || config.serviceType,
    //   data_id: params.data_id,
    //   similar_max_results: params.similar_max_results || 10,
    //   same_creator: params.same_creator,
    // },
    url: `${config.apiUrl.apiSimilar}/similar/${serviceType}`,
    params: { keyword, page, max_results },
  };

  const axiosInstance = axios.create();

  return axiosInstance(request).then((response) => response.data);
}

export { getSimilarData };
