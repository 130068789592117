import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import { withI18next } from '~/../hocs/_withI18next';
import { Annotate } from '~/lib';

@withI18next()
class ControlNumberFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        status: '',
        fileType: '',
        sortByPage: 'createdAt.desc',
        itemPerPage: 10,
      },
    };
  }

  handleChangeInput(name, e) {
    const { filter } = this.state;
    let newFilter = Object.assign({}, filter, { [name]: e.target.value });
    this.setState(
      { filter: Object.assign({}, filter, { [name]: e.target.value }) },
      () => {
        let sortByPage = newFilter['sortByPage'].split('.');
        delete newFilter['sortByPage'];
        newFilter[sortByPage[0]] = sortByPage[1];
        this.props.triggerEventChangeFilter(newFilter);
      },
    );
  }

  render() {
    const { t } = this.props;
    const { filter } = this.state;
    return (
      <div className="text-right">
        <Dropdown className="config" pullRight id="control-number-filter">
          <Dropdown.Toggle bsStyle="link" bsSize="lg">
            <FontAwesomeIcon icon={faCog} style={{ width: '1em' }} />
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{ padding: '15px', minWidth: this.props.minWidth }}
            className="menu-list"
          >
            <ul className="list-unstyled">
              <li className="row">
                <div className="col-xs-4">{t('Sort by page')}</div>
                <div className="col-xs-8">
                  <label role="button">
                    <input
                      name="sort_by_page"
                      type="radio"
                      value={'createdAt.desc'}
                      checked={filter['sortByPage'] == 'createdAt.desc'}
                      onChange={this.handleChangeInput.bind(this, 'sortByPage')}
                    />
                    &nbsp;
                    {t('Sort by newest registration')}
                  </label>
                  <br />
                  <label role="button">
                    <input
                      name="sort_by_page"
                      type="radio"
                      value={'createdAt.asc'}
                      checked={filter['sortByPage'] == 'createdAt.asc'}
                      onChange={this.handleChangeInput.bind(this, 'sortByPage')}
                    />
                    &nbsp;
                    {t('Old order of registration')}
                  </label>
                  <br />
                  <label role="button">
                    <input
                      name="sort_by_page"
                      type="radio"
                      value={'download.desc'}
                      checked={filter['sortByPage'] == 'download.desc'}
                      onChange={this.handleChangeInput.bind(this, 'sortByPage')}
                    />
                    &nbsp;
                    {t('In_descending_order_of_download')}
                  </label>
                  <br />
                  <label role="button">
                    <input
                      name="sort_by_page"
                      type="radio"
                      value={'download.asc'}
                      checked={filter['sortByPage'] == 'download.asc'}
                      onChange={this.handleChangeInput.bind(this, 'sortByPage')}
                    />
                    &nbsp;
                    {t('In_ascending_order_of_download')}
                  </label>
                </div>
              </li>
              <li className="row">
                <div className="col-xs-4">{t('per page')}</div>
                <div className="col-xs-8">
                  <div className="row">
                    <div className="col-xs-4">
                      <label role="button">
                        <input
                          name="itemPerPage"
                          type="radio"
                          value={10}
                          checked={filter['itemPerPage'] == 10}
                          onChange={this.handleChangeInput.bind(
                            this,
                            'itemPerPage',
                          )}
                        />
                        &nbsp; 10
                      </label>
                    </div>
                    <div className="col-xs-4">
                      <label role="button">
                        <input
                          name="itemPerPage"
                          type="radio"
                          value={50}
                          checked={filter['itemPerPage'] == 50}
                          onChange={this.handleChangeInput.bind(
                            this,
                            'itemPerPage',
                          )}
                        />
                        &nbsp; 50
                      </label>
                    </div>
                    <div className="col-xs-4">
                      <label role="button">
                        <input
                          name="itemPerPage"
                          type="radio"
                          value={100}
                          checked={filter['itemPerPage'] == 100}
                          onChange={this.handleChangeInput.bind(
                            this,
                            'itemPerPage',
                          )}
                        />
                        &nbsp; 100
                      </label>
                    </div>
                  </div>
                </div>
              </li>
              {/*<li className="row">*/}
              {/*<div className="col-xs-4">*/}
              {/*{t('File type')}*/}
              {/*</div>*/}
              {/*<div className="col-xs-8">*/}
              {/*<div className="row">*/}
              {/*<div className="col-xs-4">*/}
              {/*<label role="button">*/}
              {/*<input name="fileType" type="radio" value={''}*/}
              {/*checked={filter['fileType'] == ''}*/}
              {/*onChange={this.handleChangeInput.bind(this, 'fileType')}/>&nbsp;*/}
              {/*{t('All')}*/}
              {/*</label>*/}
              {/*</div>*/}
              {/*<div className="col-xs-4">*/}
              {/*<label role="button">*/}
              {/*<input name="fileType" type="radio" value={'psd'}*/}
              {/*checked={filter['fileType'] == 'psd'}*/}
              {/*onChange={this.handleChangeInput.bind(this, 'fileType')}/>&nbsp;*/}
              {/*psd*/}
              {/*</label>*/}
              {/*</div>*/}
              {/*<div className="col-xs-4">*/}
              {/*<label role="button">*/}
              {/*<input name="fileType" type="radio" value={'jpeg'}*/}
              {/*checked={filter['fileType'] == 'jpeg'}*/}
              {/*onChange={this.handleChangeInput.bind(this, 'fileType')}/>&nbsp;*/}
              {/*jpg*/}
              {/*</label>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</li>*/}
              <li className="row">
                <div className="col-xs-4">{t('Status')}</div>
                <div className="col-xs-8">
                  <label role="button">
                    <input
                      name="status"
                      type="radio"
                      value={''}
                      checked={filter['status'] == ''}
                      onChange={this.handleChangeInput.bind(this, 'status')}
                    />
                    &nbsp;
                    {t('All')}
                  </label>
                  <br />
                  <label role="button">
                    <input
                      name="status"
                      type="radio"
                      value={'open'}
                      checked={filter['status'] == 'open'}
                      onChange={this.handleChangeInput.bind(this, 'status')}
                    />
                    &nbsp;
                    {t('Now_open')}
                  </label>
                  <br />
                  <label role="button">
                    <input
                      name="status"
                      type="radio"
                      value={'release'}
                      checked={filter['status'] == 'release'}
                      onChange={this.handleChangeInput.bind(this, 'status')}
                    />
                    &nbsp;
                    {t('Editorial_release_now')}&nbsp;
                  </label>
                  <Annotate text={t('lifestyle_culture_problems_des')} />
                  <br />
                  <label role="button">
                    <input
                      name="status"
                      type="radio"
                      value={'review'}
                      checked={filter['status'] == 'review'}
                      onChange={this.handleChangeInput.bind(this, 'status')}
                    />
                    &nbsp;
                    {t('Under_review')}
                  </label>
                  <br />
                  <label role="button">
                    <input
                      name="status"
                      type="radio"
                      value={'non_approval'}
                      checked={filter['status'] == 'non_approval'}
                      onChange={this.handleChangeInput.bind(this, 'status')}
                    />
                    &nbsp;
                    {t('non_approval')}
                  </label>
                </div>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

ControlNumberFilter.defaultProps = {
  minWidth: '380px',
  triggerEventChangeFilter: () => ({}),
};

ControlNumberFilter.propTypes = {
  minWidth: PropTypes.string,
  triggerEventChangeFilter: PropTypes.func,
};

export default ControlNumberFilter;
