import React from 'react';
import Config from '~/config';

const ServerError = () => (
  <div style={{ paddingTop: 150, paddingBottom: 200 }}>
    <img
      style={{ maxWidth: '50%' }}
      src={`${Config.staticPrefix}/static/images/errors/500.png`}
      alt="logo error"
    />
  </div>
);

export default ServerError;
