import React from 'react';
import css from 'styled-jsx/css';
import I18nLink from '~/components/I18nLink';
import ACImage from '~/components/ACImage';

const CategoryItem = ({ category }) => {
  return (
    <div className="category-item-container">
      <I18nLink href={`/category/${category.category_slug}`}>
        <a className="category-image" target="_blank">
          <div className="thumbnail-wrapper">
            <ACImage
              src={category.categoryImage}
              className="image-category"
              alt={category.category_name}
            />
          </div>
        </a>
      </I18nLink>
      <I18nLink href={`/category/${category.category_slug}`}>
        <a target="_blank">
          <div className="category-name">{category.category_name}</div>
        </a>
      </I18nLink>

      <style jsx>{styles}</style>
      <style jsx global>
        {`
          .image-category {
            position: relative;
            height: 180px;
          }
          .image-category:hover {
            opacity: 0.8;
          }
        `}
      </style>
    </div>
  );
};
const styles = css`
  .category-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
  }
  .category-item {
    position: relative;
    text-align: center;
    margin-right: 8px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    min-width: 300px;
    background: #c4c4c4;
    height: 180px;
    border-radius: 4px;
  }
  .category-name {
    color: #000000;
    font-weight: 500;
    margin-top: 8px;
  }
  .category-image {
    position: relative;
    padding: 0 24px;

    text-align: center;
    display: flex;
    flex-direction: column;

    background: #c4c4c4;
    width: 180px;
    height: 120px;
    border-radius: 4px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
  .category-item-container:hover .category-name {
    text-decoration: underline !important;
    opacity: 0.7;
  }

  .thumbnail-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export default CategoryItem;
