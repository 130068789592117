import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  LOAD_RECOMMEND_DATA,
  LOAD_RECOMMEND_DATA_ERROR,
  LOAD_RECOMMEND_DATA_SUCCESS,
  NICE_PHOTO,
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
} from './constants';
import { makeSelectPhotos } from './selectors';
import * as photoPageService from '~/services/photo-page';
import * as recommendPageService from '~/services/recommend-page';
import * as searchPageService from '~/services/search-page';

import Config from '~/config';

// function* loadRecommend(action) {
//   try {
//     const { payload, api } = action;
//     const query = payload || {};
//     const apiFunction = api
//       ? api.data.getRecommendedData
//       : recommendPageService.getRecommendData;
//     const {
//       collection,
//       has_next,
//       page,
//       total_page,
//       total_item,
//       dataType,
//     } = yield call(apiFunction, {
//       pattern_id: query.pattern_id,
//       lang: query.lang,
//       service_type: query.service_type,
//       per_page: query.per_page || 100,
//       page: query.page || 1,
//       download: query.order_by === 'popular' ? 'desc' : '',
//       createdAt: query.order_by === 'newest' ? 'desc' : '',
//       free_only: query.free_only,
//       clientIp: query.clientIp,
//     });

//     const pageInt = parseInt(page);
//     yield put({
//       type: LOAD_RECOMMEND_DATA_SUCCESS,
//       collection,
//       has_next,
//       page: pageInt,
//       total_page,
//       total_item,
//       dataType,
//     });
//   } catch (error) {
//     yield put({
//       type: LOAD_RECOMMEND_DATA_ERROR,
//       error: (error.response && error.response.data) || {},
//     });
//   }
// }
function* loadRecommendBySearchUrl(action) {
  try {
    if (Config.isPhotoAC() || Config.isIllustAC()) {
      const { payload, api } = action;
      const query = payload || {};
      const apiFunction = api
        ? api.data.getPatternDetailById
        : recommendPageService.getPatternDetailById;
      let patternData = yield call(apiFunction, {
        id: query.pattern_id,
        lang: query.lang,
        service_type: query.service_type,
        clientIp: query.clientIp,
      });
      const apiFunctionSearch = searchPageService.searchData;
      const searchURL = patternData.patterns[0]
        ? new URL(patternData.patterns[0].search_url).pathname
        : null;
      let queryTmp = {};
      patternData.patterns[0]?.search_url
        .split('?')[1]
        .split('&')
        .forEach((value) => {
          const key = value.split('=')[0];
          const data = value.split('=')[1];
          key && (queryTmp[key] = decodeURIComponent(data));
        });

      queryTmp.page = parseInt(payload.page);
      queryTmp.keyword =
        queryTmp.keyword || searchURL.substring(searchURL.lastIndexOf('/') + 1);
      queryTmp.keyword = queryTmp.keyword.replace(/%20/g, ' ');
      console.log(queryTmp);

      //convert keyword of taiwan site
      if (queryTmp.keyword.includes('%'))
        queryTmp.keyword = decodeURIComponent(queryTmp.keyword);
      console.log(queryTmp);

      let { collection, has_next, total_page, total_item, page } = yield call(
        apiFunctionSearch,
        queryTmp,
      );
      collection = collection.map((item) => {
        if (item?.filename) {
          item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
        }
        return item;
      });
      const pageInt = parseInt(page);
      yield put({
        type: LOAD_RECOMMEND_DATA_SUCCESS,
        collection,
        query,
        has_next,
        page: pageInt,
        total_page,
        total_item,
        pattern_origin: patternData.patterns[0],
        // dataType,
      });
    } else {
      const { payload, api } = action;
      const query = payload || {};
      const apiFunction = api
        ? api.data.getRecommendedData
        : recommendPageService.getRecommendData;
      const {
        collection,
        has_next,
        page,
        total_page,
        total_item,
        dataType,
      } = yield call(apiFunction, {
        pattern_id: query.pattern_id,
        lang: query.lang,
        service_type: query.service_type,
        per_page: query.per_page || 100,
        page: query.page || 1,
        download: query.order_by === 'popular' ? 'desc' : '',
        createdAt: query.order_by === 'newest' ? 'desc' : '',
        free_only: query.free_only,
        clientIp: query.clientIp,
      });
      const pageInt = parseInt(page);
      yield put({
        type: LOAD_RECOMMEND_DATA_SUCCESS,
        collection,
        has_next,
        page: pageInt,
        total_page,
        total_item,
        dataType,
      });
    }
  } catch (error) {
    yield put({
      type: LOAD_RECOMMEND_DATA_ERROR,
      error: (error.response && error.response.data) || {},
    });
  }
}

function* nicePhoto(action) {
  try {
    const photos = yield select(makeSelectPhotos());
    const searchedPhoto = photos.find((item) => item.id === action.value);
    if (searchedPhoto.is_nice) {
      yield put({
        type: REMOVE_NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.removeNicePhoto, action.value);
    } else {
      yield put({
        type: NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.nicePhoto, action.value);
    }
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

export default function* loadRecommendSaga() {
  yield takeLatest(LOAD_RECOMMEND_DATA, loadRecommendBySearchUrl);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
