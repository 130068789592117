import axios from './interceptor-axios';
import config from '~/config';

function getTopTagsData(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/top-tag`,
    params: {
      lang: params.lang,
      page: params.page,
      filter: params.filter,
      service_type: config.serviceType,
    },
  };

  return axios(request);
}

export { getTopTagsData };
