import forIn from 'lodash/forIn';
import PropTypes from 'prop-types';
import React from 'react';
import FontIcomoonIcon from '~/components/FontIcomoonIcon';
import I18nLink from '~/components/I18nLink';
import withI18next from '~/../hocs/_withI18next';

@withI18next()
class Pagination extends React.Component {
  onPrev = () => {
    const { currentPage, query } = this.props;
    if (currentPage <= 1) {
      return;
    }
    const prevPage = currentPage - 1;
    const queryString = `page=${prevPage}`;
    let path = '';
    forIn(this.props.query, (value, key) => {
      if (value && key !== 'page') {
        path += `&${key}=${value}`;
      }
    });
    if (this.props.preNextEvent) {
      // this.props.onChange(currentPage);
      this.props.onBlur(currentPage);
    }
  };

  onNextLink = () => {
    let { currentPage } = this.props;
    if (currentPage >= this.props.totalPage) {
      return;
    }
    currentPage++;
    let path = `page=${currentPage}`;
    forIn(this.props.query, (value, key) => {
      if (value && key !== 'page') {
        path += `&${key}=${value}`;
      }
    });

    return `${this.props.path}?${path}`;
  };

  onPreviousLink = () => {
    let { currentPage } = this.props;
    if (currentPage <= 1) {
      return;
    }
    currentPage--;
    let path = `page=${currentPage}`;
    forIn(this.props.query, (value, key) => {
      if (value && key !== 'page') {
        path += `&${key}=${value}`;
      }
    });

    return `${this.props.path}?${path}`;
  };

  onNext = () => {
    let { currentPage } = this.props;
    if (currentPage >= this.props.totalPage) {
      return;
    }
    currentPage++;
    let path = `page=${currentPage}`;
    forIn(this.props.query, (value, key) => {
      if (value && key !== 'page') {
        path += `&${key}=${value}`;
      }
    });

    // browserHistory.push(`${this.props.path}?${path}`);
    if (this.props.preNextEvent) {
      // this.props.onChange(currentPage);
      this.props.onBlur(currentPage);
    }
  };

  onBlur(event) {
    const value = parseInt(event.target.value);
    this.props.onBlur(value);
  }

  onChange(event) {
    const value = parseInt(event.target.value);
    if (value > this.props.totalPage) {
      return;
    }
    this.props.onChange(value);
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      const value = parseInt(event.target.value);
      this.props.onBlur(value);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="pagination-lib">
        <span className="title-page text-center">{t('Page')}</span>
        <span className="number-page">
          <input
            type="number"
            min={1}
            max={this.props.totalPage}
            onKeyPress={this.onKeyPress.bind(this)}
            onChange={this.onChange.bind(this)}
            value={this.props.currentPage}
            onBlur={this.onBlur.bind(this)}
            className="current-page form-control"
          />
        </span>
        <span className="total-page">
          &nbsp;/&nbsp;
          {this.props.totalPage}
        </span>
        {this.props.useButtonLink ? (
          <I18nLink
            to={this.onPreviousLink()}
            disabled={+this.props.currentPage === 1}
          >
            <a className="btn btn-prev">
              <FontIcomoonIcon icon="illust-angle-left" />
            </a>
          </I18nLink>
        ) : (
          <button
            onClick={this.onPrev}
            className="btn btn-prev"
            disabled={+this.props.currentPage === 1}
          >
            <FontIcomoonIcon icon="illust-angle-left" />
          </button>
        )}

        {this.props.useButtonLink ? (
          <I18nLink
            to={this.onNextLink()}
            disabled={this.props.currentPage >= this.props.totalPage}
          >
            <a className="btn btn-next">
              <FontIcomoonIcon icon="illust-angle-right" />
            </a>
          </I18nLink>
        ) : (
          <button
            onClick={this.onNext}
            className="btn btn-next"
            disabled={this.props.currentPage >= this.props.totalPage}
          >
            <FontIcomoonIcon icon="illust-angle-right" />
          </button>
        )}
        <style jsx>
          {`
            .pagination-lib .title-page {
              display: inline-block;
              min-width: 40px;
              color: #697077;
              margin-left: 7px;
              margin-right: 7px;
            }

            .pagination-lib .total-page {
              margin-left: 4px;
              color: #697077;
            }

            .pagination-lib .number-page {
              width: 50px;
              display: inline-flex;
            }

            .pagination-lib .btn-prev {
              border: 1px solid #ececec;
              background: #fff;
              height: 34px;
              margin-bottom: 2px;
              margin-left: 7px;
              padding-top: 1px;
              padding-left: 5px;
              padding-right: 5px;
              color: #4c9ade;
            }
            .pagination-lib .btn-next {
              border: 1px solid #ececec;
              background: #fff;
              height: 34px;
              margin-bottom: 2px;
              margin-left: 7px;
              padding-top: 1px;
              padding-left: 5px;
              padding-right: 5px;
              color: #4c9ade;
            }

            .btn-prev:hover,
            .btn-prev:active,
            .btn-prev:focus,
            .btn-next:hover,
            .btn-next:active,
            .btn-next:focus {
              color: #4c9ade;
            }

            .btn-prev:disabled,
            .btn-next:disabled {
              color: #333;
            }

            .pagination-lib .current-page {
              text-align: center;
              padding: 6px 2px;
              border: 1px solid #ececec;
            }

            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              /*-moz-appearance: none;*/
              appearance: none;
              margin: 0;
            }

            input[type='number'] {
              -moz-appearance: textfield;
            }
          `}
        </style>
      </div>
    );
  }
}

Pagination.defaultProps = {
  currentPage: 1,
  totalPage: 1,
  itemsCountPerPage: 10,
  query: {},
  path: '',
  preNextEvent: false,
  onChange: () => ({}),
  onBlur: () => ({}),
  onNext: () => ({}),
  onPrev: () => ({}),
  useButtonLink: false,
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsCountPerPage: PropTypes.number,
  totalPage: PropTypes.number,
  path: PropTypes.string,
  query: PropTypes.object,
  preNextEvent: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  useButtonLink: PropTypes.bool,
};

export default Pagination;
