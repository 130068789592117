import {
  SHOW_ERROR,
  HIDE_ERROR,
  SHOW_IE_WARNING,
  HIDE_IE_WARNING,
  SHOW_INFOR,
  HIDE_INFOR,
} from './constants';

const initState = {
  showError: false,
  showInfor: false,
  infor: null,
  error: null,
  title: null,
  description: null,
  actions: null,
  typeInfor: null,
  showIEWarning: false,
};

/*
parameter error can be one of following:
- raw error from axios
- error.response.data from axios
- Error object in JS
All are converted to this format
{
  code,
  message: string | {
    type,
    message: string | {
      code
    }
  }
}
*/
const extractErrorContent = (error) => {
  if (!error) return null;
  let errorData = error;
  if (error.response) {
    // raw error returned from axios
    errorData = error.response.data;
  }
  if (errorData && errorData.errorMessage) {
    console.log('errorMessage', errorData.errorMessage);
    if (typeof errorData.errorMessage === 'string') {
      return JSON.parse(errorData.errorMessage);
    }
    return errorData.errorMessage;
  }
  return errorData;
};

function customErrorMessageReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        ...action.payload,
        error: extractErrorContent(action.payload.error),
        showError: true,
      };
    case HIDE_ERROR:
      return {
        ...state,
        showError: false,
      };
    case SHOW_INFOR:
      return {
        ...state,
        ...action.payload,
        typeInfor: action.payload.typeInfor,
        showInfor: true,
      };
    case HIDE_INFOR:
      return {
        ...state,
        showInfor: false,
      };
    case SHOW_IE_WARNING:
      return {
        ...state,
        showIEWarning: true,
      };
    case HIDE_IE_WARNING:
      return {
        ...state,
        showIEWarning: false,
      };
    default:
      return state;
  }
}

export default customErrorMessageReducer;
