import React from 'react';
import PropTypes from 'prop-types';

class Banner extends React.Component {
  render() {
    return (
      <div
        className={`${this.props.className} background-img`}
        style={{
          backgroundImage: `url(${this.props.src})`,
          width: this.props.width,
          height: this.props.height,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

Banner.defaultProps = {
  className: '',
  width: 'auto',
  height: '50px',
};

Banner.propTypes = {
  className: PropTypes.string,
};

export default Banner;
