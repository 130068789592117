const listIdExclude = {
  people:
    '23515409,585000,23594382,23619009,23465573,23619373,23364520,23290627,23649783,23619015,23303422,23447944',
  animals:
    '23649826,23649825,23378890,342391,23649828,23222400,945849,23639825,23294238,23649827,131588,23643728,23452082,23728400,23649823',
  'flowers-plants':
    '23377437,23669282,23385105,23670119,23445952,23556279,192982,23377438,23500604,23549450,23586067,519350,23540372,646838,23549452,23625192',
  business:
    '337592,347323,281420,23435095,296727,23389616,23682410,323351,23613419,23414011,23682407,23542613,650705,1673772,1608530,23338963,23600958,23575172,23294961,23694706,23594382,23597268,23619009,23728444,23613416,23435099,23507695,23465573,23750834,23673523,23384548,23321360,23349660,23491139,23775081,23867193,23599685,23324877,23619373,23312708,23488375,23452489,23443881,23533088,23534804,305806,23290627,23615891,23358144,23227460,364009,23641661,23532316,23567891,23199954,23294719,23438872,23230477,23649783,23808144,23619015,23303375,23488387,23484011,23615795,23779589,23595290,23682411',
  'medical-welfare':
    '23452489,23711911,23530116,23711912,23541038,23607450,23477787,23542213,23711907,1675107,23542214,23759075,23636601,23872734,23872728,23433737,23456270,23478894,23085398,23210955,23354043,23561851,1446135,23523337,23422734,23130978,23786309,23502621,23506983,23436534,23542216,23221999,23451266,23456272,23423085,23649825,23304573,23317251,23436536,23378890,23711910,23221996,23741257,23422735,296725,23436593,23221998,794214,23491891,806733,650698,23221981,23221971,23778477,1479,352765,620422,23483945,23436506,23772783,23477070,23211098,23620549,23093825,23422732,23536645,23886095,23730085,307726,23210766,23478893,23360109,318382,23711909,23456273,23456277,23262556,23436613,23436589,23411326,23649827,23374601,23268132,23724645,23560463,753601,1110017,23816121,23141505,23436572,617017,23390843,23597247,23411325,23411323,23309248,23436626,23452487,23266725',
  ecology:
    '168034,23616370,23616369,23616368,23616371,220453,23649641,504914,1094432,23575217,23824037,23742381,23649611,23575216,1232775,23897433,970111,283526,472204,352765,352765,23319106,1882940,23653772,23233268,23412171,23897431,573460,23701887,23564751,23564750,239195,237450,23689898,23319113,23319104,203093,23522557,23273495,23829267,23804346,23676096,23292693',
  'beauty-health':
    '23574355,269108,23582251 23711907,1675107,23226995,23278795,23839878,1446135,23221967,23502621,23221999',
  miscellaneous:
    '337592,23515409,23414011,23575172,23465573,23453543,23775081,23599685,23592167,23324877,23452489,23312708,23615891,23941762,23290627,23358144,23445952,23530116,23227460,23431039,23230530,23438872,23412157,23412161,23485815,288004,23431059,23404575,23303375,23303422,23484011,23593323,23387686,23711912,23806396,23541038,23328310,23607450,23698080,23614357,23438261,23516173,23400913,23542213,23438864,23438864,23738853,23383650,23570887,23711907,23404627,23575217,23718526,23422988,304107,224479,23735486,23752270,23509078,23775136,23541106,23756091,23438867,23362192,23647505,23303376,23700174',
  'textures-backgrounds':
    '1097327,115826,276500,23194702,873668,23750834,420996,23194704,23556279,421077,23442246,585000',
  'housing-interior':
    '23599685,23441849,23400913,23509078,23776334,23777258,23747984,23323915,23599692,23599686,23690608,23485419,23713591,23686544,337612,23625238,23442244',
  'seasons-events':
    '341160,319930,292676,23775531,689598,23516317,23306262,23144615,23291826,23757465,427272,512983,23884367,1226747,23668380,23807539,23472897,213713,23555390,23241512,23723353,23465503,1020331,1674269,23242963,23377437,23601613,23741612,23482505,23442237,23493726,23723466,23419772,645299,23723465,23614502,23338032,23455787,23555387,23723487,23485263,23377438,23723488,23723468,23723423,23723423,23723469,23555572,23545404,23859547,23713345,23957248,23549452,688454,23330194',
  world:
    '23624237,23537019,23532062,23500295,23548589,23552069,23497482,23474753,23511912,23343812,23530446,23500298,23500296,23858504,23808704,23644618,23500299,23297283,444874,23500462,23645228,23421705,23543166,23680907,23828724,23544950,23774229,23593061,23500294,23562989,23192100,23774228,23930566,1446122,23503662,23630930,23552024,90273,23475968,23645225,23500500,23354273,23714356,1092310,2363544,23507548,23406870,123338,23345514,786307,23500457,55906,67595,23645222,23644619,23858506,23681424,23551993,23436151,23500458,23789094,384026,23239259,23644803,23643442,23348694,90271,741785,23481990,93161,23328243,23197637,23143083,172946,23500499,23809590,23278778,23602790,23645202,251592,23643477,23639990,23311485,23588242,23500459,23185671,23930567,23913054,23544363,54977,23512299,23865632,23718087,23389006,146096,23298056,23533864,23447009,23645216,23645206,23645137,23505170,23593410,23808706,23808703,23808702,604504,23689107,23552206,23789598,23357347,23543576,622308,279888,23256062,67508,23406565,50714,23507543,23507547,23339897,74961,23503664,23438878,23256225,23256232,50845,/93159,89516,2359236,23645224,23645213,23645135,23644620,23644616,1396407,23913170,23913180,23356053,23873131,202144,23865652,108538,23579531,23630515,23297284,23536650,739403,22894874,23819815,23527301,23527296,88109,23007652,23462570,23649261,546865,23517430,211303,23596212,546960,164945,134199,23552070,20747,33007,22991705,322863,23645217,23645208,23402285,23644821,23644623,517194,23643473,211295,23500677,23500463,23500461,43989,43989,1056383,23576497,23574264,23714361,201759,80305,23352545,23298058,53714,23695702,496521,202124',
  'food-drink':
    '23435073,23408466,23871046,23308844,23632165,23532131,23137133,23273368,23632167,23632162,23632166,23679900,23252008,23368511,548604,23315380,23648696,23902537,151785,23679707,23679705,23529699,23589532,23165394,2376638',
  sports:
    '23216299,23395135,23585624,23684582,23684581,1233922,23278779,23684718,23917619,23476755,23041305,23512861,23230417,23733627,23734319,23684559,23402885,23394827,23684564,23684583,23394813,23707948,23508760,23247156,2368458,23406733,3455787,23402886,23780328,23402886,23733635,23733633,23831010,1946394,23822745,18192,23421355,23470485,23310181,23230418,3563678,23684572,706234,23310996,23528574,2373432,23270411,24003614,24003629,23298056,23298059,23684578,23244105,465382,23398683,99593,606248,23361657,23570795,23345990,244886,23777395,23199915,23401096,23345991,23709782,23694759,23166109,561955,23674448,594062,213313,23773506,23429823,561955,23402888,23773506',
  'nature-landscape':
    '207405,23643189,23723467,23923602,23723326,292676,23783105,2373959,22368717,23845737,23680532,23680151,23723454,23343763,23885283,23562313,23535728,1694369,23633296,23739593,23624671,23270692,23783105,23670043,23709328,23687170,23624671,23638533,23757901,23474837,23343581,23542613,1226747,23680991,23723422,23671523,2343509,374706,23307238,23638535,23878086,23705946,23723491,23693210,209721,23657443,23231681,23438941,473891,1609673,23603331,1020331,23649641,1674269,23624670,23723353,23915658,23653883,23201691,23765949,23723489,23826537,23723421,788870,1330648,23444858,23312960,23836313,23723321,683655,23343764,23461091,23633299,23231677,23643136,315259,23723465,23950283,1228305,124114,23518150,23652032,23643197,23723487,24011627,23649610,23723466,23723468,23356012,23591768,23723469,23149108,519350,2337773,23145396,23729976,23955567,23723423,23719733,23504726,23577437,23443144,184968,23236471,23556328,257770,23430252,23739594,1226744,23483568,23723488,23929316,23756091,23231684',
  'buildings-architecture':
    '684549,23643189,23676840,23673282,23568089,1721611,23393985,23564492,23465257,23326153,23804796,23564488,23723851,23399836,23436501,23483865,23465255,23259359,1108886,23311873,23393983,153664,23636406,23776366,23693212,23647142,23394158,23321964,23317849,23393991,23518261,23832697,23364521,23364520,23283555,23679575,23691393,23371145,23834609,23543393,23571513,23120962,23567891,23199954,23447367,23465258,23505860,23283553,23712719,23770770,23483863,23283549,23673283,23683390,23283550,23643197,23497848,23775792,23745699,23148141,23622686,23545681,23642796,23731938,23405601,23509875,23755144,23465253,23643151,23405801,23440454,1674065,23475595,23484821,23393990,23528289,23280306,23710844,23195829,942755,23469343,23378848,23578656,23348900,23621557,23429081,714891,89550,23697543,23792060,23776364,23195887,23372819,23046624,340375,128049,23820181,23280296,706094,23304587,23382156,23184034,23601591,23195824,23686641,23612933,23427911,816,23663185,23788826,2038681,23492998,23433180,23640360,23283552,23326874,23697544,23473079,23523337,23356714,23821486,23148171,23397746,23322279,23238745,23737677,23464346,23697535,23440453,23195304,416755,23776365,24011625,23773822,23867370,23856457,23756597,23755138,1004927,23531776,23735617,23341996,23402397,23778842,23724140,23571510,23616750,23317055,23195830,23821484,23219852,23591450,23590532,156398,23737678,23624599,1826517,156563,23046623,23371427,23685726,23770771,23577292,23905171,23900956,23322055,944074,23359695,176514,280029,23837311,23820588,23282132,23525158,23643152,23643153,23216436,23283556,23575827,23636417,38727,23392682,23686643,61014,324914,23321677,23849642',
  'traffic-transportation':
    '23573512,23691366,23533781,367007,23696103,23479461,23654666,23404493,1610298,23560011,23872748,23576801,23783421,212439,23502626,806776,23811039,23673504,23523067,23342268,23477070,23512912,23734912,23518259,23551536,23733205,23880482,23728995,23360109,23625323,23215665,23710197,23519561,23776577,1429522,23233251,23160039,23469507,23498959,23378771,23143314,23500615,23790092,806727,23550345,23395415,23495735,23995020,23650547,23232902,23701091,23828139,23535086,23824231,23433359,23811043,23635445,23557048,23784641,23773864,23519567,23678177,23677824,23768639,23657735,23601647,23873632,23723100,23599857,23503054,307802,23524455,23642506,23321414,23395431,23955945,23884014,23866245,23863482,23117666,23828817,23794688,23571544,23506383,23480724,23543050,23503082,23542972,23122140,23902253,23201682,23321801,23515560,1856383,23826397,23399257',
  'popular-photos':
    '297171,23706388,23923602,23584976,23513298,23350519,23566438,23515409,337592,793841',
};

export default listIdExclude;
