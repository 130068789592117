import {
  LOAD_COUNT_DATA,
  LOAD_COUNT_DOWNLOAD_RUN,
  LOAD_COUNT_USER,
  LOAD_DATA_TYPE,
  LOAD_HOMEPAGE_DATA,
  LOAD_RANDOM_SLIDER_IMAGES,
  SELECTED_PATTERN,
  SET_IS_TOP_BANNER_VISIBLE,
  LOAD_COLLECTIONS_OF_HOMEPAGE,
  LOAD_DATA_BLOGS,
} from './constants';

export function loadTotalUser() {
  return {
    type: LOAD_COUNT_USER,
  };
}

export function selectedPattern(params) {
  return {
    type: SELECTED_PATTERN,
    ...params,
  };
}

export function loadHomePageData(payload, api) {
  return {
    type: LOAD_HOMEPAGE_DATA,
    payload,
    api,
  };
}

export function loadRandomSliderImages(payload, api) {
  return {
    type: LOAD_RANDOM_SLIDER_IMAGES,
    payload,
    api,
  };
}

export function loadCountData() {
  return {
    type: LOAD_COUNT_DATA,
  };
}

export function loadDataType(params) {
  return {
    type: LOAD_DATA_TYPE,
    ...params,
  };
}

export function loadCountDownload() {
  return {
    type: LOAD_COUNT_DOWNLOAD_RUN,
  };
}

export function setIsTopBannerVisible(value) {
  return {
    type: SET_IS_TOP_BANNER_VISIBLE,
    payload: value,
  };
}

export function loadDataCollectionHomepage(payload, api) {
  return {
    type: LOAD_COLLECTIONS_OF_HOMEPAGE,
    payload,
    api,
  };
}

export function loadDataBlogsHomepage(payload, api) {
  return {
    type: LOAD_DATA_BLOGS,
    payload,
    api,
  };
}
