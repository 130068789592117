import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import axios from './interceptor-axios';
import config from '~/config';
import { SERVICE_TYPES } from '~/utils/constants';

function searchData(params) {
  const serviceType = params.serviceType || config.serviceType;
  let request;
  let modelReleaseParam = {};
  if (params.modelRelease) {
    switch (params.modelRelease.toString()) {
      case '0':
        modelReleaseParam = { model: '2', property: '0' };
        break;
      case '1':
        modelReleaseParam = { model: '0', property: '2' };
        break;
      case '2':
        modelReleaseParam = { model: '0', property: '0' };
        break;
    }
  }
  if (!params.image_token) {
    if (
      serviceType === config.serviceType &&
      params.category_id &&
      isArray(params.category_id)
    ) {
      params.category_id = params.category_id.join(',');
    } else {
      params.category_id = params.category || '';
    }

    if (
      serviceType === config.serviceType &&
      params.excludeCategory_id &&
      isArray(params.excludeCategory_id)
    ) {
      params.excludeCategory_id = params.excludeCategory_id.join(',');
    } else {
      params.excludeCategory_id = params.excludeCategory || '';
    }

    if (params.modelAge && isArray(params.modelAge)) {
      params.modelAge = params.modelAge.join(',');
    }

    let controlParams = {};

    if (serviceType === SERVICE_TYPES.PHOTO_AC) {
      controlParams = {
        option: 2,
        offset: '25d',
        scale: '90d',
        decay: 0.75,
        download: 2,
        download_all: 0.54,
        download_weight: 0,
        weight: 1.42,
        factor_tag: 1.8,
        factor_localize: 0,
        rating: 0.2,
        rating_user: 0,
        log: 1,
        minscore: 0,
        ai_point_weight: 1,
        similar_rank_weight: 12,
        // nonested: true,
        boost_full_tag: 1,
        exclude_ai: false,
        disp_flg_global: true,
        site: 1,
      };
    } else if (serviceType == SERVICE_TYPES.ILLUST_AC) {
      controlParams = {
        rate: params.rate || 0.56,
        rand: isUndefined(params.rand) ? true : params.rand === 'true',
        pow: params.pow || 1.678,
        param: params.param || 14000,
        p5: params.p5 || 0.9,

        option: 2,
        offset: '7d',
        scale: '75d',
        decay: 0.7,
        download: 2,
        download_all: 0.28,
        weight: 3.4,
        rating: 1,
        factor_tag: 1.3,
        factor_localize: 0,
        log: 1,
        minscore: 0.04,
        // nonested: true,
        boost_full_tag: 1,
        exclude_ai: false,
        disp_flg_global: true,
        site: 1,
      };
    } else {
      controlParams = {
        rate: params.rate || 0.56,
        rand: isUndefined(params.rand) ? true : params.rand === 'true',
        pow: params.pow || 1.678,
        param: params.param || 14000,
        p5: params.p5 || 0.9,
      };
    }

    if (
      !params.isCategoryPage &&
      serviceType === SERVICE_TYPES.PHOTO_AC &&
      !params.category_id &&
      !params.excludeCategory_id &&
      !params.imageId &&
      !params.creatorName &&
      !params.excludeCreatorName
    ) {
      params.excludeCategory_id = '22,23';
    }
    request = {
      method: 'GET',
      url: `${config.apiUrl.getAPISearchByService(serviceType)}`,
      params: {
        ...params,
        page: params.page || 1,
        // size: params.size,
        // shape: params.shape,
        // keyword: params.keyword,
        // excludeKeyword: params.excludeKeyword,
        categoriesId: params.category_id,
        excludeCategoriesId: params.excludeCategory_id,
        // creatorName: params.creatorName,
        // excludeCreatorName: params.excludeCreatorName,
        color: params.color === 'all' ? undefined : params.color,
        people_count: params.modelCount,
        ages: params.modelAge,
        // fileType: params.fileType,
        extraLicense: params.license,
        format: params.imageType,
        imageSize: params.imageType,
        max_results: params.per_page || 100,
        language: params.language,
        isCategoryPage: params.isCategoryPage,
        is_tag: params.is_tag,
        service_type: serviceType,
        ...controlParams,
        ...modelReleaseParam,
        translate: true,
        keyword: params?.keyword?.toLowerCase() || '',
        db: 'on',
        user_rank_avg: 50,
        country: 'oversea',
        lang: 'en',
        disp_language: 'en',
        overseas: true,
        negative_percent: 1,
        user_rank_weight: 0,
      },
    };

    if (!params.isFromSearchHeader) {
      request.params.is_tag = true;
    } else if (params.token) {
      request.headers = {
        Authorization: params.token,
      };
    }
  } else {
    console.log('--- isSearchPhoto ---');
    request = {
      method: 'GET',
      url: `${config.apiUrl.apiSearchImageService}`,
      params: {
        service_type: params.serviceType || config.serviceType,
        image_token: params.image_token,
        token: params.token,
        language: params.language,
      },
    };
  }

  console.log('request check v1', JSON.parse(JSON.stringify(request)));
  const paramToDelete = config.unusedParamsSearch[request.params.service_type];
  // remove unused params
  for (const element of paramToDelete) {
    console.log('element', element);
    delete request.params[element];
  }

  // remove null, undefined, blank params
  request.params = Object.fromEntries(
    Object.entries(request.params).filter(([_, v]) => !!v),
  );

  //sort properties in object
  const sortedParams = Object.keys(request.params)
    .sort()
    .reduce((acc, currValue) => {
      acc[currValue] = request.params[currValue];
      return acc;
    }, {});
  delete sortedParams['keyword'];
  delete sortedParams['page'];
  request.params = {
    keyword: params?.keyword?.toLowerCase() || '',
    page: params.page || 1,
    ...sortedParams,
  };
  console.log('request check v2', JSON.parse(JSON.stringify(request)));
  return axios(request);
}

function getSuggestKeyword({ keyword, language = 'en' }) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiSearch()}/get-related-word`,
    params: {
      keyword,
      language,
    },
  };

  return axios(request);
}

export { searchData, getSuggestKeyword };
