import { createSelector } from 'reselect';

const selectPricesPremium = state => state.pricePremium.result;

const selectLoading = state => state.pricePremium.load;

const makeSelectLoading = () => createSelector(
  selectLoading,
  loading => loading,
);

const makeSelectPrices = () => createSelector(
  selectPricesPremium,
  pricesPremium => pricesPremium.prices,
);

const makeSelectIsSale = () => createSelector(
  selectPricesPremium,
  pricesPremium => pricesPremium.isSale,
);
const makeSelectIsTrial = () => createSelector(
  selectPricesPremium,
  pricesPremium => pricesPremium.isTrial,
);
const makeSelectPriceSelected = () => createSelector(
  selectPricesPremium,
  pricesPremium => pricesPremium.priceSelected,
);
const makeSelectTrial = () => createSelector(
  selectPricesPremium,
  pricePremium => pricePremium.trial,
);
const makeSelectEndDate = () => createSelector(
  selectPricesPremium,
  pricePremium => pricePremium.end_date,
);
const makeSelectStartDate = () => createSelector(
  selectPricesPremium,
  pricePremium => pricePremium.start_date,
);
const makeSelectTrialEndDate = () => createSelector(
  selectPricesPremium,
  pricePremium => pricePremium.trialEndDate,
);
const makeSelectCoupon = () => createSelector(
  selectPricesPremium,
  pricePremium => pricePremium.coupon,
);
const makeSelectCountdownDiscount = () => createSelector(
  selectPricesPremium,
  pricePremium => pricePremium.countdownDiscount,
);

export {
  selectPricesPremium,
  makeSelectPrices,
  makeSelectIsSale,
  makeSelectPriceSelected,
  makeSelectLoading,
  makeSelectTrial,
  makeSelectEndDate,
  makeSelectStartDate,
  makeSelectIsTrial,
  makeSelectTrialEndDate,
  makeSelectCoupon,
  makeSelectCountdownDiscount,
};
