import { createSelector } from 'reselect';

const selectSimilar = state => state.similarPage;

const selectFilter = state => state.similarPage.filter;

const makeSelectCollection = () => createSelector(selectSimilar, similarDetailState => similarDetailState.collection);

const makeSelectData = () => createSelector(selectSimilar, similarDetailState => similarDetailState.data);

const makeSelectError = () => createSelector(selectSimilar, similarDetailState => similarDetailState.error);

const makeSelectPage = () => createSelector(selectSimilar, similarDetailState => similarDetailState.page);

const makeSelectTotalPage = () => createSelector(selectSimilar, similarDetailState => similarDetailState.totalPage);

const makeSelectTotalItem = () => createSelector(selectSimilar, similarDetailState => similarDetailState.totalItem);

const makeSelectOrderBy = () => createSelector(selectFilter, filterState => filterState.orderBy);

const makeSelectPerPage = () => createSelector(selectFilter, filterState => filterState.perPage);

export {
  selectSimilar,
  makeSelectCollection,
  makeSelectPage,
  makeSelectTotalPage,
  makeSelectTotalItem,
  makeSelectOrderBy,
  makeSelectPerPage,
  makeSelectError,
  makeSelectData,
};
