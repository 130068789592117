import { combineReducers } from 'redux';
// import Immutable from 'seamless-immutable';
import {
  CLOSE_MODAL,
  HIDE_LOADING_FULL_SCREEN,
  LOAD_PRICE_EL_PLANS,
  LOAD_PRICE_EL_PLANS_ERROR,
  LOAD_PRICE_EL_PLANS_SUCCESS,
  MODAL_SUBMIT,
  OPEN_MODAL,
  SAVE_LICENSE,
  // SELECTED_PRICE_EL_PLAN,
  SHOW_LOADING_FULL_SCREEN,
} from './constants';

const initialState = {
  showModal: false,
};

function extraLicensePaymentModalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        showModal: true,
      };

    case MODAL_SUBMIT:
    case CLOSE_MODAL:
      return {
        showModal: false,
      };

    default:
      return state;
  }
}

const initialStateExtraPlans = {
  el_plans: [],
  planOfSingleLicense: null,
  selectedELPlan: '',
  error: false,
  loading: false,
};

function extralicenseReducer(state = initialStateExtraPlans, action) {
  switch (action.type) {
    case SAVE_LICENSE:
      return {
        ...state,
      };
    case LOAD_PRICE_EL_PLANS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LOAD_PRICE_EL_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        el_plans: action.el_plans.filter((item) => item.number_license > 1),
        planOfSingleLicense: action.el_plans.find(
          (item) => item.number_license === 1,
        ),
      };

    case LOAD_PRICE_EL_PLANS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}

export const initialStateLoad = {
  fullScreen: false,
  message: '',
};

function loadDisplay(state = initialStateLoad, action) {
  switch (action.type) {
    case SHOW_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: true,
      };
    case HIDE_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  result: extralicenseReducer,
  load: loadDisplay,
  modal: extraLicensePaymentModalReducer,
});
