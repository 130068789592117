import React from 'react';
// import $ from 'jquery';

class GridImages extends React.Component {
  onCreate() {
    // $(this.refs.girdImages).justifiedGallery({
    //   rowHeight: 250,
    //   lastRow: this.props.lastRow,
    //   maxRowHeight: 400,
    //   margins: 10,
    //   cssAnimation: true,
    //   captions: this.props.captions,
    // }).on('jg.complete', (e) => {
    //   this.props.onCompleteGirdImage();
    // });
  }

  // onLoadMore() {
  //   $(this.refs.girdImages).justifiedGallery('norewind');
  // }

  // onDestroy() {
  //   $(this.refs.girdImages).justifiedGallery('destroy');
  // }

  onReload() {
    this.onDestroy();

    setTimeout(() => {
      this.onCreate();
    }, 400);
  }

  render() {
    return (
      <div>
        <input id={`${this.props.name}Create`} onClick={this.onCreate.bind(this)} type="hidden" />
        <input id={`${this.props.name}LoadMore`} onClick={this.onLoadMore.bind(this)} type="hidden" />
        <input id={`${this.props.name}Reload`} onClick={this.onReload.bind(this)} type="hidden" />
        <input id={`${this.props.name}Destroy`} onClick={this.onDestroy.bind(this)} type="hidden" />
        <div ref="girdImages">
          {this.props.children}
        </div>
      </div>
    );
  }
}

GridImages.defaultProps = {
  captions: false,
  lastRow: 'nojustify',
  name: '',
  onCompleteGirdImage: () => {
  },
};

export default GridImages;
