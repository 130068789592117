import { createSelector } from 'reselect';

const selectLogsRegisteredUsers = state => state.logsRegisteredUsers;

const makeSelectLoading = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.loading,
);

const makeSelectLatestRegisteredUsers = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.latestRegisteredUsers,
);

const makeSelectRegisteredUsers = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.registeredUsers,
);

const makeSelectShowModal = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.showModal,
);

const makeSelectLoadingLatestRegisteredUsers = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.loadingLatestRegisteredUsers,
);

const makeSelectPage = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.page,
);

const makeSelectTotalPage = () => createSelector(
  selectLogsRegisteredUsers,
  logsRegisteredUsersState => logsRegisteredUsersState.totalPage,
);

export {
  selectLogsRegisteredUsers,
  makeSelectLoading,
  makeSelectLatestRegisteredUsers,
  makeSelectRegisteredUsers,
  makeSelectShowModal,
  makeSelectLoadingLatestRegisteredUsers,
  makeSelectPage,
  makeSelectTotalPage,
};
