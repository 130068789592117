import { combineReducers } from 'redux';
import {
  LOAD_COLLECTIONS,
  LOAD_COLLECTIONS_SUCCESS,
  LOAD_COLLECTIONS_ERROR,
  CHANGE_PAGE,
  LOAD_COLLECTIONS_OF_MONTHS_SUCCESS,
  LOAD_COLLECTIONS_OF_MONTHS_ERROR,
} from './constants';

export const initialState = {
  collections: [],
  error: false,
  loading: false,
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  collections_months: [],
};

function collectionsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_COLLECTIONS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LOAD_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        collections: action.collections,
        totalPage: action.collections.totalpage,
        page: action.page,
      };
    }

    case LOAD_COLLECTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case LOAD_COLLECTIONS_OF_MONTHS_SUCCESS: {
      return {
        ...state,
        collections_months: action.collections,
      };
    }

    case LOAD_COLLECTIONS_OF_MONTHS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CHANGE_PAGE: {
      const pageNumber =
        action.page < 1 || action.page > state.totalPage ? 1 : action.page;
      return {
        ...state,
        page: pageNumber,
      };
    }
    default:
      return state;
  }
}

export default collectionsReducer;
