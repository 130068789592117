import React from 'react';
import Image from 'next/image';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCountDownload } from '~/containers/HomePage/selectors';
import UserTotal from '~/containers/UserTotal';
import Config from '~/config';
import withI18next from '~/../hocs/_withI18next';
import { formatNumberWithCommas } from '~/utils/formatter';
import themeVariables from '~/shared/themeVariables';

const mapStateToProps = createStructuredSelector({
  countDownload: makeSelectCountDownload(),
});
@connect(mapStateToProps)
@withI18next()
class SectionTotalDownload extends React.Component {
  render() {
    const { t, lng } = this.props;
    const getSSORedirectURL = (lng) => {
      let redirectURL = window.location.href;
      if (
        redirectURL.includes('creator-registration') ||
        redirectURL.includes('login')
      ) {
        redirectURL = Config.getSiteURL();
      }
      return redirectURL;
    };
    const handleLoginSSO = () => {
      const { lng } = this.props;
      window.open(
        `${
          Config.ssoServerUrl
        }/login?lang=${lng}&serviceURL=${encodeURIComponent(
          getSSORedirectURL(),
        )}`,
        '_self',
      );
    };
    const handleSignUpSSO = (lng) => {
      window.open(
        `${
          Config.ssoServerUrl
        }/signup?lang=${lng}&serviceURL=${encodeURIComponent(
          getSSORedirectURL(lng),
        )}`,
        '_blank',
      );
    };
    return (
      <section className="section-total-download">
        <img
          src={`${Config.staticPrefix}/static/images/ai-landing-page/footer_banner.png`}
          className="img-background"
          // layout="fill"
          alt="image background"
        />
        <div className="title-container">
          {t('ai_landing_page_footer_title')}
        </div>
        <div className="button-create-container">
          <div className="button-create" onClick={() => handleSignUpSSO(lng)}>
            {t('ai_landing_page_footer_button_create_account')}
          </div>
        </div>
        <div className="title-02-container">
          <span>{t('ai_landing_page_footer_already_account')} </span>
          <a className="link-log-in" onClick={handleLoginSSO}>
            {t('ai_landing_page_footer_login')}
          </a>
        </div>
        <style jsx>
          {`
            .section-total-download {
              margin-top: 64px;
              position: relative;
              color: #fff;
              height: 350px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              overflow: hidden;
              z-index: 10;
            }
            .title-container {
              z-index: 1;
              font-style: normal;
              font-weight: bold;
              font-size: 48px;
            }
            .button-create-container,
            .title-02-container {
              margin-top: 24px;
              z-index: 1;
            }
            .button-create {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 486px;
              height: 60px;
              background: #c80000;
              border: 3px solid #c80000;
              box-sizing: border-box;
              border-radius: 4px;
              cursor: pointer;
            }
            .link-log-in {
              color: #ffffff;
              text-decoration: underline;
            }
            .link-log-in:hover {
              color: #ffffff;
              opacity: 0.9;
              text-decoration: underline !important;
            }
            .img-background {
              position: absolute;
              width: 100%;
            }
            @media (max-width: ${themeVariables.breakpoints.tabletL
                .maxWidth}px) {
              .title-container {
                font-size: 28px;
              }
              .button-create {
                width: 200px;
                height: 60px;
              }
            }
            @media (max-width: ${themeVariables.breakpoints.mobile
                .maxWidth}px) {
              .title-container {
                font-size: 22px;
              }
              .button-create {
                width: 200px;
                height: 60px;
              }
            }
          `}
        </style>
      </section>
    );
  }
}

export default SectionTotalDownload;
