import React from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeVariables from '~/shared/themeVariables';

class DropdownBottom extends React.Component {
  static defaultProps = {
    align: 'left',
  };

  render() {
    const { header, position, children, style, align, noArrow } = this.props;

    return (
      <div className="dropdown-bottom" style={style}>
        <div className="dropdown-bottom-header">
          {header}{' '}
          {!noArrow && (
            <FontAwesomeIcon
              icon={faCaretDown}
              style={{ width: '1em', marginLeft: 4, color: 'black' }}
            />
          )}
        </div>
        <div className="dropdown-bottom-menu">{children}</div>
        <style jsx>
          {`
            .dropdown-bottom {
              position: relative;
              width: fit-content;
              height: fit-content;
              color: ${themeVariables.color.dark};
            }

            .dropdown-bottom-header {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: nowrap;
              cursor: pointer;
              width: 100%;
              height: 100%;
              color: #1b2833;
            }

            .dropdown-bottom-menu {
              position: absolute;
              top: 100%;
              ${align === 'left' ? 'left' : 'right'}: 0;
              background: #fff;
              display: none;
              z-index: 9999;
            }

            .dropdown-bottom-header:hover + .dropdown-bottom-menu {
              display: initial;
            }

            .dropdown-bottom-menu:hover {
              display: initial;
            }

            @media (max-width: 1280px) {
              .dropdown-bottom {
                color: #1b2833;
                flex: none;
                order: 0;
                flex-grow: 0;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

export default DropdownBottom;
