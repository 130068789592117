import { combineReducers } from 'redux';
import {
  LOAD_NEW_DATA,
  LOAD_NEW_DATA_SUCCESS,
  LOAD_NEW_DATA_ERROR,
  FILTER_PER_PAGE,
  CHANGE_PAGE,
} from './constants';
import {
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
} from '~/components/Gallery/constants';

export const initialState = {
  photos: [],
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
};

function newReducers(state = initialState, action) {
  switch (action.type) {
    case LOAD_NEW_DATA:
      return {
        ...state,
        query: {
          pattern_id: action.payload.pattern_id || null,
          per_page: action.payload.per_page || 100,
          page: action.payload.page || 1,
        },
        loading: true,
        error: false,
      };

    case LOAD_NEW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        photos: action.collection,
        has_next: action.has_next,
        page: action.page,
        totalPage: action.total_page,
        totalItem: action.total_item,
        dataType: action.dataType || '',
      };
    case LOAD_NEW_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case NICE_PHOTO_SUCCESS:
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
      };
    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}

const filterInitState = {
  perPage: '100',
};

function filterReducer(state = filterInitState, action) {
  switch (action.type) {
    case FILTER_PER_PAGE:
      return {
        ...state,
        perPage: action.value,
      };
    default:
      return state;
  }
}

export default combineReducers({
  result: newReducers,
  filter: filterReducer,
});
