// import classNames from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import unescape from 'unescape';
import uniq from 'lodash/uniq';
import { withRouter } from 'next/router';
// import { nicePhoto } from './actions';

import ImageActions from './ImageActions/Loadable';
import {
  IS_CATEGORY_PAGE,
  IS_SEARCH_RESULT_PAGE,
  IS_COLLECTION_PAGE,
  IS_RECOMMEND_PAGE,
} from './constants';
import NoScript from '../NoScript';
import { nicePhoto } from '~/containers/SearchResultPage/actions';
import withI18next from '~/../hocs/_withI18next';
import I18nLink from '~/components/I18nLink';
import Config, { keyConfig } from '~/config';
import { makeSelectIsEdgeOrIE } from '~/containers/MetadataProvider/selectors';
import { slugs, urls } from '~/utils';
import { SERVICE_TYPES, USER_ROLES } from '~/utils/constants';
import ACImage from '~/components/ACImage';
import { nicePhoto as nicePhotoSearchResultPage } from '~/containers/SearchResultPage/actions';
import { nicePhoto as nicePhotoCategoryPage } from '~/containers/CategoryPage/actions';
import { nicePhoto as nicePhotoCollectionPage } from '~/containers/CollectionPageDetail/actions';
import { nicePhoto as nicePhotoRecommendPage } from '~/containers/RecommendPage/actions';
const { supportedLanguages } = require('~/../next-i18next.config');

// import ButtonEdit from './ButtonEdit';
// import ButtonSelectAsset from './ButtonSelectAsset';
// import ButtonSocialShare from './ButtonSocialShare';

@withI18next()
@withRouter
class Image extends Component {
  state = {
    isHovering: false,
  };

  handleMouseEnter = () => {
    this.setState({
      isHovering: true,
    });
  };

  handleMouseOut = () => {
    this.setState({
      isHovering: false,
    });
  };
  getNamePage = () => {
    const { router } = this.props;
    const routeData = router.pathname;
    if (
      routeData.startsWith('/category') ||
      supportedLanguages.some((lang) =>
        routeData.startsWith(`/${lang}/category`),
      )
    ) {
      return IS_CATEGORY_PAGE;
    } else if (
      routeData.startsWith('/collection') ||
      supportedLanguages.some((lang) =>
        routeData.startsWith(`/${lang}/collection`),
      )
    ) {
      return IS_COLLECTION_PAGE;
    } else if (
      routeData.startsWith('/recommend') ||
      supportedLanguages.some((lang) =>
        routeData.startsWith(`/${lang}/recommend`),
      )
    ) {
      return IS_RECOMMEND_PAGE;
    } else {
      return IS_SEARCH_RESULT_PAGE;
    }
  };
  actionNice = (data) => {
    const {
      nicePhotoSearchResultPage,
      nicePhotoCategoryPage,
      nicePhotoCollectionPage,
      nicePhotoRecommendPage,
      router,
    } = this.props;

    const checkPage = this.getNamePage();
    if (checkPage === IS_CATEGORY_PAGE) {
      nicePhotoCategoryPage(data);
    } else if (checkPage === IS_COLLECTION_PAGE) {
      nicePhotoCollectionPage(data);
    } else if (checkPage === IS_RECOMMEND_PAGE) {
      nicePhotoRecommendPage(data);
    } else {
      nicePhotoSearchResultPage(data);
    }
    // this.setState({
    //   // isHovering: true,
    //   isHovering: false,
    // });
  };

  renderImageActions = () => {
    const { item, role, api, t } = this.props;
    return (
      <ImageActions
        item={item}
        role={role}
        api={api}
        nicePhoto={this.actionNice}
        t={t}
      />
    );
  };

  renderCreatorInfo = () => {
    const { item } = this.props;
    let creatorId = item.user_id;
    let creatorName = item.user_name;
    let creatorAvatar = null;
    if (item.creatorProfile) {
      creatorId = item.creatorProfile.id;
      creatorName =
        item.creatorProfile.nickname_for_overseas ||
        item.creatorProfile.nickname ||
        creatorName;
      creatorAvatar = item.creatorProfile.avatar;
    }

    // TODO: REMOVE THIS COMMENT
    const hiddenLinkCreator = true;

    if (hiddenLinkCreator) {
      return (
        <div className="creator-info">
          <img
            className="creator-avatar lazyload"
            alt={creatorName || 'creator'}
            data-src={urls.renderAvatarUrl(creatorAvatar)}
          />
          <span className="creator-name">{creatorName}</span>
        </div>
      );
    }
    return (
      <I18nLink
        as={`/creator-profile/${creatorId}`}
        href={{
          pathname: '/creator-profile',
          query: { creator_id: creatorId },
        }}
        prefetch={false}
      >
        <a className="creator-info">
          <img
            src={urls.renderAvatarUrl(creatorAvatar)}
            className="creator-avatar lazyload"
            alt={creatorName || 'creator'}
          />
          <span className="link-creator-name">{creatorName}</span>
        </a>
      </I18nLink>
    );
  };

  renderTags = (tags) => {
    const { serviceType, item } = this.props;
    let maxTags = 5;
    if (+item.scaleWidth <= 220) {
      maxTags = 3;
    }

    return tags
      .filter((tag) => tag)
      .slice(0, maxTags)
      .map((tag, idx) => {
        return (
          <I18nLink
            key={`${tag}-${idx}`}
            host={
              serviceType !== Config.serviceType &&
              Config.getSiteURL(serviceType)
            }
            href={`/search/${tag}?is_tag=true`}
          >
            <a>
              <div
                className="ac-tag-item"
                dangerouslySetInnerHTML={{ __html: tag }}
              />
            </a>
          </I18nLink>
        );
      });
  };

  render() {
    const {
      t,
      lng,
      item,
      forcePremium,
      height,
      width,
      index,
      premiumOnly,
      role,
      margin,
      // isEdgeOrIE,
      showActions,
      vwidth,
      // hideSimilar,
      serviceType,
      page,
      // keyIndex,
    } = this.props;
    const { isHovering } = this.state;
    const photoTitle = unescape(Config.getTitleByLanguage(item.title, lng));
    const slugTitle = slugs.makeSlug(photoTitle, {
      lang: lng,
    });
    const tags = item.tags || [];
    const assetFormats = uniq(item.assets?.map((item) => item.format));
    let assetFormatsText = '';
    if (assetFormats.length > 1) {
      assetFormatsText = t('text_and_text', {
        text1: assetFormats.slice(0, assetFormats.length - 1).join(', '),
        text2: assetFormats[assetFormats.length - 1],
      });
    } else {
      assetFormatsText = assetFormats[0];
    }

    const imageLink = `/${keyConfig.get('route_detail_page', serviceType)}/${
      item.id
    }/${slugTitle}`;

    // Build alt text
    const imageAltText = [
      photoTitle || t(Config.getPrefixContent('default_title')),
    ]
      .concat(tags.slice(0, 3))
      .concat(assetFormatsText)
      .join(', ');

    // Build title text
    const imageTitleText = [
      photoTitle || t(Config.getPrefixContent('default_title')),
    ]
      .concat(assetFormatsText)
      .join(', ');

    const xWidth = width || +item.scaleWidth || 'auto';
    const xHeight = height || 'auto';
    // TODO: Improve addImageResult
    const addImageResult = Config.isDesignAC() &&
      !forcePremium &&
      role !== USER_ROLES.PREMIUM &&
      !premiumOnly && (
        <div
          className="img-free"
          style={{
            position: 'absolute',
            top: '-1%',
            left: '-1%',
            zIndex: '20',
          }}
        >
          <img
            className="lazyload"
            alt="free"
            data-src={`${Config.staticPrefix}/static/assets/image/free.png`}
          />
        </div>
      );

    const imageWrapperStyle = {
      width: width ? xWidth : vwidth,
      height: xHeight,
      overflow: 'hidden',
    };
    let imageNode;
    if (addImageResult) {
      imageNode = (
        <>
          {index < 15 ? (
            <ACImage
              key={`img-${index}`}
              src={urls.fixImageURL(item.thumbnail)}
              alt={imageAltText}
              title={imageTitleText}
              priority
              objectFit="cover"
              unoptimized
            />
          ) : (
            <img
              className="lazyload"
              key={`img-${index}`}
              data-src={urls.fixImageURL(item.thumbnail)}
              alt={imageAltText}
              title={imageTitleText}
              style={{
                cursor: 'pointer',
                width: xWidth,
                height: xHeight,
                marginLeft: +item.marginLeft || 0,
                marginTop: 0,
              }}
            />
          )}
          {addImageResult}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'ImageObject',
                contentUrl: urls.fixImageURL(item.thumbnail),
                license: `${Config.getSiteURL()}/terms`,
                acquireLicensePage: `${Config.renderDownloadPageByServiceAndLanguage(
                  lng,
                  `${item.id}/${slugTitle}`,
                )}`,
                name: t(Config.getPrefixContent('title_download_page_seo'), {
                  title: photoTitle,
                  id: item.id,
                }),
                caption: t(Config.getPrefixContent('title_download_page_seo'), {
                  title: photoTitle,
                  id: item.id,
                }),
                isAccessibleForFree: true,
                isFamilyFriendly: true,
                representativeOfPage: true,
              }),
            }}
          />
          {/* <meta
            itemProp="contentUrl"
            content={urls.fixImageURL(item.thumbnail)}
          />
          <meta itemProp="license" content={`${Config.getSiteURL()}/terms`} />
          <meta
            itemProp="acquireLicensePage"
            content={`${Config.renderDownloadPageByServiceAndLanguage(
              lng,
              `${item.id}/${slugTitle}`,
            )}`}
          />
          <meta
            itemProp="name"
            content={t(Config.getPrefixContent('title_download_page_seo'), {
              title: photoTitle,
              id: item.id,
            })}
          />
          <meta
            itemProp="caption"
            content={t(Config.getPrefixContent('title_download_page_seo'), {
              title: photoTitle,
              id: item.id,
            })}
          /> */}
        </>
      );
    } else {
      const imgStyle = {
        cursor: 'pointer',
        width: xWidth,
        height: xHeight,
        marginLeft: +item.marginLeft || 0,
        marginTop: 0,
      };
      if (serviceType === SERVICE_TYPES.SILHOUETTE_AC) {
        imageWrapperStyle.background = '#fff';
        imageWrapperStyle.padding = 6;
        imageWrapperStyle.borderRadius = 2;
        imageWrapperStyle.boxShadow = '0 2px 4px 0 rgb(0 0 0 / 20%)';
        const newImgHeight = imgStyle.height - imageWrapperStyle.padding * 2;
        const newImgWidth = (imgStyle.width * newImgHeight) / imgStyle.height;
        imgStyle.width = newImgWidth;
        imgStyle.height = newImgHeight;
      }
      imageNode = (
        <>
          {index < 15 ? (
            <ACImage
              key={`img-${index}`}
              src={urls.fixImageURL(item.thumbnail)}
              alt={imageAltText}
              title={imageTitleText}
              priority
              objectFit="cover"
              unoptimized
            />
          ) : (
            <img
              className="lazyload"
              key={`img-${index}`}
              data-src={urls.fixImageURL(item.thumbnail)}
              alt={imageAltText}
              title={imageTitleText}
              style={imgStyle}
            />
          )}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'ImageObject',
                contentUrl: urls.fixImageURL(item.thumbnail),
                license: `${Config.getSiteURL()}/terms`,
                acquireLicensePage: `${Config.renderDownloadPageByServiceAndLanguage(
                  lng,
                  `${item.id}/${slugTitle}`,
                )}`,
                name: t(Config.getPrefixContent('title_download_page_seo'), {
                  title: photoTitle,
                  id: item.id,
                }),
                caption: t(Config.getPrefixContent('title_download_page_seo'), {
                  title: photoTitle,
                  id: item.id,
                }),
                isAccessibleForFree: true,
                isFamilyFriendly: true,
                representativeOfPage: true,
              }),
            }}
          />
          {/* <meta
            itemProp="contentUrl"
            content={urls.fixImageURL(item.thumbnail)}
          />
          <meta itemProp="license" content={`${Config.getSiteURL()}/terms`} />
          <meta
            itemProp="acquireLicensePage"
            content={`${Config.renderDownloadPageByServiceAndLanguage(
              lng,
              `${item.id}/${slugTitle}`,
            )}`}
          />
          <meta
            itemProp="name"
            content={t(Config.getPrefixContent('title_download_page_seo'), {
              title: photoTitle,
              id: item.id,
            })}
          />
          <meta
            itemProp="caption"
            content={t(Config.getPrefixContent('title_download_page_seo'), {
              title: photoTitle,
              id: item.id,
            })}
          /> */}
        </>
      );
    }

    return (
      <figure className="gallery-image-container" style={{ margin }}>
        <I18nLink
          host={
            serviceType !== Config.serviceType && Config.getSiteURL(serviceType)
          }
          as={imageLink}
          shallow
          prefetch={false}
          href={{
            pathname: '/photo',
            query: { id: item.id, title: slugTitle },
          }}
        >
          <a
            target={'_blank'}
            className={Config.isIllustAC() ? 'custom-container-wrapper' : ''}
          >
            <div
              className="gallery-image"
              style={imageWrapperStyle}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseOut}
              // itemType="http://schema.org/ImageObject"
              // itemScope
            >
              {/* <div className="gallery-image-item"> */}
              {!item.prepareLoad && imageNode}
              {/* </div> */}
              <div className="gallery-image-hover">
                {showActions && isHovering && this.renderImageActions(t)}
                {/* {Config.serviceType === 'illust_ac' &&
                  !hideSimilar &&
                  item.has_theme && (
                    <I18nLink
                      href={{
                        pathname: '/similar',
                        query: { data_id: item.id, same_creator: true },
                      }}
                      as={`/search-result/similar/${item.id}?same_creator=1`}
                    >
                      <a
                        className={`btn btn-ac-primary btn-${Config.serviceType} btn-similar`}
                      >
                        {t('ill_similar')}
                      </a>
                    </I18nLink>
                  )} */}
                <figcaption className="gallery-title">
                  <div className="image-title">{photoTitle}</div>
                  {/* {tags && (
                    <div className="image-tag">{this.renderTags(tags)}</div>
                  )} */}
                  {isHovering && (
                    <div className="image-info">
                      {this.renderCreatorInfo()}
                      {/* <span className="number-container">
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ marginRight: 5 }}
                        />
                        {item.download || 0}
                      </span>
                      <span className="number-container">
                        <FontAwesomeIcon
                          className="number-icon"
                          icon={faHeart}
                        />
                        {item.nice || 0}
                      </span> */}
                    </div>
                  )}
                </figcaption>
              </div>
              <NoScript>
                <img
                  src={urls.fixImageURL(item.thumbnail)}
                  alt={imageAltText}
                  title={imageTitleText}
                />
              </NoScript>
            </div>
          </a>
        </I18nLink>
        <style jsx>
          {`
            .btn-similar {
              position: absolute;
              bottom: 80px;
              padding-left: 5%;
              padding-right: 5%;
              max-width: 90%;
            }

            .image-title {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .image-tag {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-left: 8px;
            }
            .gallery-image:hover .gallery-image-hover {
              transition: 0.3s;
              opacity: ${this.state.isHovering ? 1 : 0};
              background-color: rgba(0, 0, 0, 0.4);
            }
            .gallery-image-container {
            }
            .custom-container-wrapper {
              display: block;
              width: 100%;
              text-align: center;
              align-items: center;
              margin: auto;
            }
          `}
        </style>
      </figure>
    );
  }
}

Image.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  margin: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.string || PropTypes.number,
  onSelectImage: PropTypes.func,
  thumbnailStyle: PropTypes.func,
  customOverlay: PropTypes.element,
  premiumOnly: PropTypes.bool,
  forcePremium: PropTypes.bool,
  showActions: PropTypes.bool,
  role: PropTypes.string,
};

Image.defaultProps = {
  isSelectable: true,
  hover: false,
  forcePremium: false,
  premiumOnly: false,
  showActions: false,
  role: '',
  serviceType: Config.serviceType,
};

const mapStateToProps = createStructuredSelector({
  isEdgeOrIE: makeSelectIsEdgeOrIE(),
});
const mapDispatchToProps = (dispatch) => ({
  nicePhotoSearchResultPage: (id) => dispatch(nicePhotoSearchResultPage(id)),
  nicePhotoCategoryPage: (id) => dispatch(nicePhotoCategoryPage(id)),
  nicePhotoCollectionPage: (id) => dispatch(nicePhotoCollectionPage(id)),
  nicePhotoRecommendPage: (id) => dispatch(nicePhotoRecommendPage(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Image);
