import { combineReducers } from 'redux';
import {
  LOAD_PHOTO_SUCCESS,
  LOAD_PHOTO_ERROR,
  ASSET_SELECT,
  ASSET_SELECT_INDEX,
  DOWNLOAD_PHOTO,
  DOWNLOAD_PHOTO_SUCCESS,
  DOWNLOAD_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
  HIDE_GOI_IT,
  SHOW_GOI_IT,
  LOAD_STATISTICS_SUCCESS,
  LOAD_STATISTICS_ERROR,
  LOAD_SIMILAR_LIST,
  LOAD_SIMILAR_LIST_SUCCESS,
  LOAD_SIMILAR_LIST_ERROR,
  LOAD_SIMILAR_DESIGN,
  LOAD_SIMILAR_DESIGN_SUCCESS,
  LOAD_SIMILAR_DESIGN_ERROR,
  DOWNLOAD_PHOTO_IN_PROGRESS,
  SHOW_ATTRIBUTE_MODAL,
  UPDATE_PHOTO,
  GET_CURRENT_STATUS_DOWNLOAD_PHOTO,
  LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR,
  LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS,
  OPEN_EDITOR,
  OPEN_EDITOR_DONE,
  SHOW_PHOTO_VOTING,
  HIDE_PHOTO_VOTING,
  LOAD_RELATED_DATA,
  LOAD_RELATED_DATA_SUCCESS,
  LOAD_RELATED_DATA_ERROR,
  LOAD_CARD_INFO_SUCCESS,
  UPDATE_BOOKMARK_DATA,
} from './constants';

export const initialState = {
  id: 0,
  tags: [],
  categories: [],
  title: '',
  nice: 0,
  assets: [],
  photoPreview: [],
  creator: {},
  assetSelected: 0,
  error: false,
  dataType: null,
  powerPoint: null,
  neighbor: [],
  modal: false,
  openingEditor: false,
  isPhotoVotingVisible: false,
  base64: null,
  relatedData: [],
  loadingRelatedData: false,
  cardInfo: null,
  isBookmark: null,
};

function photoReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ATTRIBUTE_MODAL:
      return {
        ...state,
        modal: action.modal,
      };
    case LOAD_PHOTO_SUCCESS:
      return {
        ...state,
        id: action.id,
        loading: false,
        tags: action.tags,
        categories: action.categories,
        title: action.title,
        nice: action.nice,
        assets: action.assets,
        photoPreview: action.photoPreview || [],
        creator: action.creator,
        path: action.path,
        thumbnail: action.thumbnail,
        dataType: action.data_type || null,
        powerPoint: action.ppt || null,
        photo: action.photo,
        neighbor: action.neighbor,
        // base64: action.base64
      };
    case LOAD_CARD_INFO_SUCCESS:
      return {
        ...state,
        cardInfo: action.card?.attributes,
      };
    case LOAD_PHOTO_ERROR:
      return { ...state, error: action.error, loading: false };

    case UPDATE_PHOTO:
      return { ...state, photo: { ...state.photo, ...action.payload } };

    case ASSET_SELECT_INDEX:
      return { ...state, assetSelected: action.index };

    case ASSET_SELECT:
      return {
        ...state,
        assets: state.assets.map((item, index) => {
          item.active = index === action.index;
          return { ...item };
        }),
      };

    case NICE_PHOTO_SUCCESS:
      return {
        ...state,
        nice: state.nice + 1,
        photo: {
          ...state.photo,
          is_nice: true,
        },
      };
    case REMOVE_NICE_PHOTO_SUCCESS:
      console.log('VO REDUCER ROI');
      return {
        ...state,
        nice: state.nice - 1,
        photo: {
          ...state.photo,
          is_nice: false,
        },
      };
    case OPEN_EDITOR:
      if (action.newWindow === 'download')
        return {
          ...state,
          openingEditor: false,
        };

      return {
        ...state,
        openingEditor: true,
      };

    case OPEN_EDITOR_DONE:
      return {
        ...state,
        openingEditor: false,
      };

    case SHOW_PHOTO_VOTING:
      return {
        ...state,
        isPhotoVotingVisible: true,
      };
    case HIDE_PHOTO_VOTING:
      return {
        ...state,
        isPhotoVotingVisible: false,
      };

    case LOAD_RELATED_DATA:
      return {
        ...state,
        loadingRelatedData: true,
      };
    case LOAD_RELATED_DATA_SUCCESS:
      return {
        ...state,
        loadingRelatedData: false,
        relatedData: action.payload,
      };
    case LOAD_RELATED_DATA_ERROR:
      return {
        ...state,
        loadingRelatedData: false,
      };

    case UPDATE_BOOKMARK_DATA:
      return {
        ...state,
        isBookmark: action.payload,
      };

    default:
      return state;
  }
}

export const initStateSimilar = {
  collection: [],
  data: {},
  has_next: false,
  photo_similar: [],
  page: 1,
  silhouette_similar: [],
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: true,
  illust_similar: [],
  design_similar: [],
  sameCreator: [],
};

function similarReducers(state = initStateSimilar, action) {
  switch (action.type) {
    case LOAD_SIMILAR_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_SIMILAR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: action.data.collection,
        data: action.data.data,
        has_next: action.data.has_next,
        photoSimilar: action.data.photo_similar,
        page: action.data.page,
        silhouetteSimilar: action.data.silhouette_similar,
        totalPage: action.data.total_page,
        totalItem: action.data.total_item,
        illustSimilar: action.data.illust_similar,
        designSimilar: action.data.design_similar,
        sameCreator: action.data.same_creator,
      };
    case LOAD_SIMILAR_LIST_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export const initStateDownloadPhoto = {
  downloading: false,
  loading: false,
  error: false,
  currentDownloadStatus: {},
};

function photoDownloadReducer(state = initStateDownloadPhoto, action) {
  switch (action.type) {
    case DOWNLOAD_PHOTO:
      return { ...state, loading: true };

    case DOWNLOAD_PHOTO_SUCCESS:
      return { ...state, loading: false, downloading: false };

    case DOWNLOAD_PHOTO_IN_PROGRESS:
      return { ...state, downloading: true };

    case DOWNLOAD_PHOTO_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        downloading: false,
      };

    case LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        currentDownloadStatus: action.currentDownloadStatus,
      };

    case LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

function gotItReducer(state = false, action) {
  switch (action.type) {
    case SHOW_GOI_IT:
      return true;

    case HIDE_GOI_IT:
      return false;

    default:
      return state;
  }
}

const initStateStatitics = {
  latsDownload: '',
  peopleViewing: 0,
};

function statiticsReducer(state = initStateStatitics, action) {
  switch (action.type) {
    case LOAD_STATISTICS_SUCCESS:
      return {
        ...state,
        latsDownload: action.latsDownload,
        peopleViewing: action.peopleViewing,
      };

    case LOAD_STATISTICS_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}

const initialStateSimilarDesign = {
  designSimilar: [],
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
  category: null,
};

function loadDataSimilarReducer(state = initialStateSimilarDesign, action) {
  switch (action.type) {
    case LOAD_SIMILAR_DESIGN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_SIMILAR_DESIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        designSimilar: action.collection,
        category: action.category,
      };
    case LOAD_SIMILAR_DESIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  detail: photoReducer,
  download: photoDownloadReducer,
  gotIt: gotItReducer,
  statitics: statiticsReducer,
  similar: similarReducers,
  similarDesign: loadDataSimilarReducer,
});
