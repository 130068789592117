import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import I18nLink from '~/components/I18nLink';
import { formatIntegerNumber, formatNumberWithCommas } from '~/utils/formatter';

@withI18next()
class UserTotal extends React.Component {
  render() {
    const { t, lng, isHomePage = false } = this.props;
    const { totalUser } = this.props;
    if (!totalUser) return null;
    const currentUserCountry = totalUser[lng];
    if (!currentUserCountry) return null;

    const isAvailbleCurrentCountryData = !(
      currentUserCountry.num_of_free_user == 0 ||
      currentUserCountry.num_of_premium_user == 0
    );
    const isAvailbleJpData = !(
      totalUser.jp.num_of_free_user == 0 ||
      totalUser.jp.num_of_premium_user == 0
    );

    return (
      <>
        <div className="user-total">
          {isAvailbleJpData && (
            <>
              <div className="user-content">
                <span className="total-user-number">
                  <b>
                    {formatNumberWithCommas(currentUserCountry.totalOfFreeUser)}
                  </b>{' '}
                  {t('num_of_total_free_user')}
                </span>
                {isAvailbleCurrentCountryData && lng === 'tw' && (
                  <span>
                    (
                    <img
                      className="current-flag"
                      src={`${Config.staticPrefix}/static/assets/image/flags/${lng}.png`}
                      alt="icon flags"
                      width={37}
                      height={20}
                    />
                    <b>
                      {formatNumberWithCommas(
                        currentUserCountry.num_of_free_user,
                      )}
                    </b>
                    )
                  </span>
                )}
              </div>
              <div className="user-content premium-user">
                <span className="total-user-number">
                  <b>
                    {formatNumberWithCommas(
                      currentUserCountry.totalOfPremiumUser,
                    )}
                  </b>{' '}
                  {t('num_of_total_premium_user')}
                </span>
                {isAvailbleCurrentCountryData && lng === 'tw' && (
                  <span>
                    (
                    <img
                      className="current-flag"
                      src={`${Config.staticPrefix}/static/assets/image/flags/${lng}.png`}
                      alt="icon flag"
                      width={37}
                      height={20}
                    />
                    <b>
                      {formatNumberWithCommas(
                        currentUserCountry.num_of_premium_user,
                      )}
                    </b>
                    )
                  </span>
                )}
              </div>
            </>
          )}
        </div>
        <div className="user-today">
          <FontAwesomeIcon
            style={{ width: 24, marginRight: 4 }}
            icon={faUserClock}
          />
          <I18nLink href="/registered-users">
            <a
              target="_blank"
              className={`user-today-title ${
                Config.serviceType === 'illust_ac' && !isHomePage
                  ? Config.getPrefixContent('color-ac-primary')
                  : ''
              }`}
              rel="noreferrer"
            >
              {t('today_users')}
            </a>
          </I18nLink>
        </div>
        <style jsx>
          {`
            .user-total {
              display: flex;
              flex-direction: row;
              justify-content: center;
            }
            .user-total .user-content {
              margin-left: 20px;
            }
            .user-total .user-content .total-user-number {
              margin-right: 10px;
            }
            .user-total .user-content .current-flag {
              height: 20px;
              width: auto;
              margin-left: 5px;
              margin-right: 5px;
            }

            .user-today {
              text-align: center;
            }
            .user-today .user-today-title {
              color: white;
              text-decoration: underline;
              font-weight: 500;
            }
            @media (max-width: 767px) {
              .user-total {
                flex-direction: column;
              }
              .user-total .user-content {
                text-align: center;
                margin: 0;
              }
            }
          `}
        </style>
      </>
    );
  }
}

export default UserTotal;
