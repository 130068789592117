import {
  LOAD_PRICE_PREMIUM,
  SELECTED_PRICE_PREMIUM,
  SHOW_LOADING_FULL_SCREEN,
  HIDE_LOADING_FULL_SCREEN,
  CREATE_BILLING_AGREEMENT,
  UPGRADE_PREMIUM_WITH_CREDIT,
  INIT_COUNTDOWN,
  UPDATE_COUNTDOWN,
} from './constants';

export function loadPricePremium(request) {
  return {
    type: LOAD_PRICE_PREMIUM,
    request,
  };
}

export function selectedPremium(plan) {
  return {
    type: SELECTED_PRICE_PREMIUM,
    plan,
  };
}

export function showLoadingFullScreen() {
  return {
    type: SHOW_LOADING_FULL_SCREEN,
  };
}

export function hideLoadingFullScreen() {
  return {
    type: HIDE_LOADING_FULL_SCREEN,
  };
}

export function createBillingAgreement(params) {
  return {
    type: CREATE_BILLING_AGREEMENT,
    ...params,
  };
}

export function upgradePremiumWithCredit(params) {
  return {
    type: UPGRADE_PREMIUM_WITH_CREDIT,
    ...params,
  };
}

export function initCountdown() {
  return {
    type: INIT_COUNTDOWN,
  };
}

export function updateCountdown(remainingDurationInMilliseconds) {
  return {
    type: UPDATE_COUNTDOWN,
    remainingDurationInMilliseconds,
  };
}
