// import Config from '~/config';

const data = {
  getData(lng) {
    const data = {
      en: [
        'business people',
        'business man',
        'business woman',
        'office people',
        'group of people',
        'happy people',
        'young people',
        'old people ',
        'working people',
        'disable people',
        'people eating',
        'people running',
        'people learning',
        'woman line drawing ',
        'pregnant woman ',
        'people helping',
        'people icons ',
        'party people ',
        'isometric people ',
        '3D people ',
        'children',
        'students',
        'couples',
        'family',
        'friends',
        'people cleaning',
        'people cooking ',
        'people reading',
        'people talking ',
        'people dancing',
        'family tree',
        'crowd silhouette',
      ],
    };

    return data[lng] || data['en'];
  },
};
export default data;
