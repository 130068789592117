import React from 'react';
import css from 'styled-jsx/css';
import ACImage from '~/components/ACImage';
import I18nLink from '~/components/I18nLink';
import Config from '~/config';
import themeVariables from '~/shared/themeVariables';

const Collection = (props) => {
  const { data, t, serviceType } = props;

  return (
    <div className="collection-container-item">
      <div className="container-image-collection">
        <I18nLink
          href={`/collection/${data?.slug}-${data?.id}`}
          host={serviceType && Config.getSiteURL(serviceType)}
        >
          <a className="left-collection" target="_blank" rel="noreferrer">
            <ACImage
              src={data?.thumbnails[0] || ''}
              className="image-collection"
              alt={data?.name || ''}
              priority
            />
          </a>
        </I18nLink>
        <div className="right-collection">
          <I18nLink
            href={`/collection/${data?.slug}-${data?.id}`}
            host={serviceType && Config.getSiteURL(serviceType)}
          >
            <a className="top" target="_blank" rel="noreferrer">
              <ACImage
                src={data?.thumbnails[1] || ''}
                className="image-collection"
                alt={data?.name || ''}
              />
            </a>
          </I18nLink>
          <I18nLink
            href={`/collection/${data?.slug}-${data?.id}`}
            host={serviceType && Config.getSiteURL(serviceType)}
          >
            <a className="bottom" target="_blank" rel="noreferrer">
              <ACImage
                src={data?.thumbnails[2] || ''}
                className="image-collection"
                alt={data?.name || ''}
              />
            </a>
          </I18nLink>
        </div>
      </div>
      <div className="content-collection">
        <div className="left-content">
          <I18nLink
            href={`/collection/${data?.slug}-${data?.id}`}
            host={serviceType && Config.getSiteURL(serviceType)}
          >
            <a className="title-collection" target="_blank" rel="noreferrer">
              {data?.name}
            </a>
          </I18nLink>

          {/* {data?.total_images && (
            <p className="count-image-collection">
              {t('collection_number_results', { count: data?.total_images })}
            </p>
          )} */}
        </div>
      </div>
      {/* <I18nLink
        href={`/collection/${data?.slug}-${data?.id}`}
        host={serviceType && Config.getSiteURL(serviceType)}
      >
        <a className="background-hover" target="_blank" rel="noreferrer">
          {' '}
        </a>
      </I18nLink> */}
      <style jsx>{styles}</style>
      <style jsx global>
        {`
          .image-collection {
            position: relative;
            width: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
          .left-collection .image-collection {
            height: 100%;
          }
          .right-collection .image-collection {
            height: 139px;
          }
        `}
      </style>
    </div>
  );
};
const styles = css`
  .collection-container-item {
    position: relative;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
    border-radius: 4px;
  }
  .container-image-collection {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;
    grid-gap: 8px;
    min-width: 306px;
  }
  .left-collection {
    position: relative;
    background: #c4c4c4;
    border-radius: 4px;
    height: 286px;
  }
  .right-collection {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 8px;
  }
  .top,
  .bottom {
    background: #c4c4c4;
    position: relative;
    border-radius: 4px;
  }
  .right-content {
    margin-top: 10px;
  }
  .like {
    cursor: pointer;
  }
  .content-collection {
    display: flex;

    justify-content: space-between;
    padding: 0 16px;
  }
  .title-collection {
    width: 100%;
    height: 44px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    color: #1b2833;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
  }
  .count-image-collection {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
  }
  .background-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
    top: 0;
    cursor: pointer;
  }
  .background-hover:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(66121_s.jpg);
  }
  @media screen and (min-device-width: 0px) and (max-device-width: 500px) {
    .container-image-collection {
      min-width: 216px;
    }
  }
  @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
    .container-image-collection {
      min-width: 216px;
    }
    .left-collection {
      height: 180px;
    }
  }
`;

export default Collection;
