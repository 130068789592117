export const LOAD_PHOTO = 'containers/PhotoPage/LOAD_PHOTO';
export const LOAD_PHOTO_SUCCESS = 'containers/PhotoPage/LOAD_PHOTO_SUCCESS';
export const LOAD_PHOTO_ERROR = 'containers/PhotoPage/LOAD_PHOTO_ERROR';

export const UPDATE_PHOTO = 'containers/PhotoPage/UPDATE_PHOTO';

export const ASSET_SELECT = 'containers/PhotoPage/ASSET_SELECT';
export const ASSET_SELECT_INDEX = 'containers/PhotoPage/ASSET_SELECT_INDEX';

export const DOWNLOAD_PHOTO = 'containers/PhotoPage/DOWNLOAD_PHOTO';
export const DOWNLOAD_PHOTO_SUCCESS =
  'containers/PhotoPage/DOWNLOAD_PHOTO_SUCCESS';
export const DOWNLOAD_PHOTO_ERROR = 'containers/PhotoPage/DOWNLOAD_PHOTO_ERROR';
export const DOWNLOAD_PHOTO_IN_PROGRESS =
  'containers/PhotoPage/DOWNLOAD_PHOTO_IN_PROGRESS';

export const NICE_PHOTO = 'containers/PhotoPage/NICE_PHOTO';

export const NICE_PHOTO_SUCCESS = 'containers/PhotoPage/NICE_PHOTO_SUCCESS';
export const REMOVE_NICE_PHOTO_SUCCESS =
  'containers/PhotoPage/REMOVE_NICE_PHOTO_SUCCESS';

export const NICE_PHOTO_ERROR = 'containers/PhotoPage/NICE_PHOTO_ERROR';

export const SHOW_GOI_IT = 'containers/PhotoPage/SHOW_GOI_IT';
export const HIDE_GOI_IT = 'containers/PhotoPage/HIDE_GOI_IT';

export const LOAD_STATISTICS = 'containers/PhotoPage/LOAD_STATISTICS';
export const LOAD_STATISTICS_SUCCESS =
  'containers/PhotoPage/LOAD_STATISTICS_SUCCESS';
export const LOAD_STATISTICS_ERROR =
  'containers/PhotoPage/LOAD_STATISTICS_ERROR';

export const LOAD_SIMILAR_LIST = 'containers/PhotoPage/LOAD_SIMILAR_LIST';
export const LOAD_SIMILAR_LIST_SUCCESS =
  'containers/PhotoPage/LOAD_SIMILAR_LIST_SUCCESS';
export const LOAD_SIMILAR_LIST_ERROR =
  'containers/PhotoPage/LOAD_SIMILAR_LIST_ERROR';

export const LOAD_SIMILAR_DESIGN = 'containers/PhotoPage/LOAD_SIMILAR_DESIGN';
export const LOAD_SIMILAR_DESIGN_SUCCESS =
  'containers/PhotoPage/LOAD_SIMILAR_DESIGN_SUCCESS';
export const LOAD_SIMILAR_DESIGN_ERROR =
  'containers/PhotoPage/LOAD_SIMILAR_DESIGN_ERROR';

export const SHOW_ATTRIBUTE_MODAL = 'containers/PhotoPage/SHOW_ATTRIBUTE_MODAL';

export const GET_CURRENT_STATUS_DOWNLOAD_PHOTO =
  'acWorks/app/containers/PhotoPage/GET_CURRENT_STATUS_DOWNLOAD_PHOTO';
export const LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS =
  'acWorks/app/containers/PhotoPage/LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_SUCCESS';
export const LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR =
  'acWorks/app/containers/PhotoPage/LOAD_STATUS_CURRENT_DOWNLOAD_PHOTO_ERROR';
export const OPEN_EDITOR = 'containers/PhotoPage/OPEN_EDITOR';
export const OPEN_EDITOR_DONE = 'containers/PhotoPage/OPEN_EDITOR_DONE';
export const LOG_CLICK_PHOTO = 'containers/PhotoPage/LOG_CLICK_PHOTO';

export const SHOW_PHOTO_VOTING = 'containers/PhotoPage/SHOW_PHOTO_VOTING';
export const HIDE_PHOTO_VOTING = 'containers/PhotoPage/HIDE_PHOTO_VOTING';

export const INIT_OTHER_SERVICES_SIMILAR_DATA =
  'containers/PhotoPage/INIT_OTHER_SERVICES_SIMILAR_DATA';
export const LOAD_OTHER_SERVICES_SIMILAR_DATA =
  'containers/PhotoPage/LOAD_OTHER_SERVICES_SIMILAR_DATA';
export const LOAD_OTHER_SERVICES_SIMILAR_DATA_SUCCESS =
  'containers/PhotoPage/LOAD_OTHER_SERVICES_SIMILAR_DATA_SUCCESS';
export const LOAD_OTHER_SERVICES_SIMILAR_DATA_ERROR =
  'containers/PhotoPage/LOAD_OTHER_SERVICES_SIMILAR_DATA_ERROR';
export const LOAD_RELATED_DATA = 'containers/PhotoPage/LOAD_RELATED_DATA';
export const LOAD_RELATED_DATA_SUCCESS =
  'containers/PhotoPage/LOAD_RELATED_DATA_SUCCESS';
export const LOAD_RELATED_DATA_ERROR =
  'containers/PhotoPage/LOAD_RELATED_DATA_ERROR';

export const LOAD_CURRENT_USER_EXTRA_LICENSE =
  'containers/PhotoPage/LOAD_CURRENT_USER_EXTRA_LICENSE';

export const LOAD_CARD_INFO = 'containers/PhotoPage/LOAD_CARD_INFO';
export const LOAD_CARD_INFO_SUCCESS =
  'containers/PhotoPage/LOAD_CARD_INFO_SUCCESS';

export const CHECK_BOOKMARK_DATA = 'containers/PhotoPage/CHECK_BOOKMARK_DAT';
export const UPDATE_BOOKMARK_DATA = 'containers/PhotoPage/UPDATE_BOOKMARK_DATA';
export const SAVE_BOOKMARK_DATA = 'containers/PhotoPage/SAVE_BOOKMARK_DATA';
