import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '~/components/loading-spinner/LoadingSpinner';

// TODO: Refactor all loading components.
class Loading extends React.PureComponent {
  render() {
    const { message, loading, isSearchingByPhoto } = this.props;
    return (
      <div className={`ac-loading ${!loading ? 'ac-hidden-loading' : ''}`}>
        {!isSearchingByPhoto && (
          <div className="sk-fading-circle">
            <div className="sk-circle1 sk-circle" />
            <div className="sk-circle2 sk-circle" />
            <div className="sk-circle3 sk-circle" />
            <div className="sk-circle4 sk-circle" />
            <div className="sk-circle5 sk-circle" />
            <div className="sk-circle6 sk-circle" />
            <div className="sk-circle7 sk-circle" />
            <div className="sk-circle8 sk-circle" />
            <div className="sk-circle9 sk-circle" />
            <div className="sk-circle10 sk-circle" />
            <div className="sk-circle11 sk-circle" />
            <div className="sk-circle12 sk-circle" />
          </div>
        )}

        {isSearchingByPhoto && <LoadingSpinner isSearchingByPhoto />}
        <div
          className="message-loading"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <style jsx global>
          {`
            .ac-loading {
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              background-color: rgba(0, 0, 0, 0.7);
              color: #fff;
              z-index: 2000;
            }
            .ac-loading .message-loading {
              z-index: 999999;
              padding-top: 10px;
              padding-left: 20px;
              padding-right: 20px;
              font-size: 18px;
              text-align: center;
            }
            .ac-loading .sk-fading-circle {
              width: 40px;
              height: 40px;
              position: relative;
              display: inline-block;
            }
            .ac-loading .sk-fading-circle .sk-circle {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
            .ac-loading .sk-fading-circle .sk-circle:before {
              content: '';
              display: block;
              margin: 0 auto;
              width: 15%;
              height: 15%;
              background-color: #fff;
              border-radius: 100%;
              -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out
                both;
              animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            }
            .ac-loading .sk-fading-circle .sk-circle2 {
              -webkit-transform: rotate(30deg);
              -ms-transform: rotate(30deg);
              transform: rotate(30deg);
            }
            .ac-loading .sk-fading-circle .sk-circle3 {
              -webkit-transform: rotate(60deg);
              -ms-transform: rotate(60deg);
              transform: rotate(60deg);
            }
            .ac-loading .sk-fading-circle .sk-circle4 {
              -webkit-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
            }
            .ac-loading .sk-fading-circle .sk-circle5 {
              -webkit-transform: rotate(120deg);
              -ms-transform: rotate(120deg);
              transform: rotate(120deg);
            }
            .ac-loading .sk-fading-circle .sk-circle6 {
              -webkit-transform: rotate(150deg);
              -ms-transform: rotate(150deg);
              transform: rotate(150deg);
            }
            .ac-loading .sk-fading-circle .sk-circle7 {
              -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
            .ac-loading .sk-fading-circle .sk-circle8 {
              -webkit-transform: rotate(210deg);
              -ms-transform: rotate(210deg);
              transform: rotate(210deg);
            }
            .ac-loading .sk-fading-circle .sk-circle9 {
              -webkit-transform: rotate(240deg);
              -ms-transform: rotate(240deg);
              transform: rotate(240deg);
            }
            .ac-loading .sk-fading-circle .sk-circle10 {
              -webkit-transform: rotate(270deg);
              -ms-transform: rotate(270deg);
              transform: rotate(270deg);
            }
            .ac-loading .sk-fading-circle .sk-circle11 {
              -webkit-transform: rotate(300deg);
              -ms-transform: rotate(300deg);
              transform: rotate(300deg);
            }
            .ac-loading .sk-fading-circle .sk-circle12 {
              -webkit-transform: rotate(330deg);
              -ms-transform: rotate(330deg);
              transform: rotate(330deg);
            }
            .ac-loading .sk-fading-circle .sk-circle2:before {
              -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s;
            }
            .ac-loading .sk-fading-circle .sk-circle3:before {
              -webkit-animation-delay: -1s;
              animation-delay: -1s;
            }
            .ac-loading .sk-fading-circle .sk-circle4:before {
              -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
            }
            .ac-loading .sk-fading-circle .sk-circle5:before {
              -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s;
            }
            .ac-loading .sk-fading-circle .sk-circle6:before {
              -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
            }
            .ac-loading .sk-fading-circle .sk-circle7:before {
              -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s;
            }
            .ac-loading .sk-fading-circle .sk-circle8:before {
              -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s;
            }
            .ac-loading .sk-fading-circle .sk-circle9:before {
              -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s;
            }
            .ac-loading .sk-fading-circle .sk-circle10:before {
              -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s;
            }
            .ac-loading .sk-fading-circle .sk-circle11:before {
              -webkit-animation-delay: -0.2s;
              animation-delay: -0.2s;
            }
            .ac-loading .sk-fading-circle .sk-circle12:before {
              -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s;
            }

            @-webkit-keyframes sk-circleFadeDelay {
              0%,
              39%,
              100% {
                opacity: 0;
              }
              40% {
                opacity: 1;
              }
            }

            @keyframes sk-circleFadeDelay {
              0%,
              39%,
              100% {
                opacity: 0;
              }
              40% {
                opacity: 1;
              }
            }

            .ac-hidden-loading {
              display: none;
            }
          `}
        </style>
      </div>
    );
  }
}

Loading.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
};

Loading.defaultProps = {
  loading: false,
  message: '',
};

export default Loading;
