export const OPEN_MODAL = 'containers/PaymentModal/OPEN_MODAL';
export const CLOSE_MODAL = 'containers/PaymentModal/CLOSE_MODAL';
export const MODAL_SUBMIT = 'containers/PaymentModal/MODAL_SUBMIT';

export const LOAD_SINGLE_PRICE_PREMIUM = 'containers/PaymentModal/LOAD_SINGLE_PRICE_PREMIUM';
export const LOAD_SINGLE_PRICE_PREMIUM_SUCCESS = 'containers/PaymentModal/LOAD_SINGLE_PRICE_PREMIUM_SUCCESS';
export const LOAD_SINGLE_PRICE_PREMIUM_ERROR = 'containers/PaymentModal/LOAD_SINGLE_PRICE_PREMIUM_ERROR';

export const LOAD_PRICE_PREMIUM = 'containers/PaymentModal/LOAD_PRICE_PREMIUM';
export const LOAD_PRICE_PREMIUM_SUCCESS = 'containers/PaymentModal/LOAD_PRICE_PREMIUM_SUCCESS';
export const LOAD_PRICE_PREMIUM_ERROR = 'containers/PaymentModal/LOAD_PRICE_PREMIUM_ERROR';

export const COMPLETED_SALE = 'containers/PaymentModal/COMPLETED_SALE';
export const SELECTED_PRICE_PREMIUM = 'containers/PaymentModal/SELECTED_PRICE_PREMIUM';
export const PRE_CANCEL_FORM_PREMIUM = 'containers/PaymentModal/PRE_CANCEL_FORM_PREMIUM';
export const CANCEL_FORM_PREMIUM = 'containers/PaymentModal/CANCEL_FORM_PREMIUM';
export const CHANGE_ITEM_PAGE_CANCEL_FORM = 'containers/PaymentModal/CHANGE_ITEM_PAGE_CANCEL_FORM';
export const CREATE_BILLING_AGREEMENT = 'containers/PaymentModal/CREATE_BILLING_AGREEMENT';
export const CREATE_BILLING_AGREEMENT_COUPON = 'containers/PaymentModal/CREATE_BILLING_AGREEMENT_COUPON';
export const EXECUTE_TOKEN_COUPON_PREMIUM = 'containers/PaymentModal/EXECUTE_TOKEN_COUPON_PREMIUM';
export const SHOW_NAV_SALE_PREMIUM = 'containers/PaymentModal/SHOW_NAV_SALE_PREMIUM';
export const HIDE_NAV_SALE_PREMIUM = 'containers/PaymentModal/HIDE_NAV_SALE_PREMIUM';
export const UPGRADE_PREMIUM_WITH_CREDIT = 'containers/PaymentModal/UPGRADE_PREMIUM_WITH_CREDIT';

export const SHOW_LOADING_FULL_SCREEN = 'containers/PaymentModal/SHOW_LOADING_FULL_SCREEN';
export const SHOW_LOADING_FULL_SCREEN_WECHAT = 'containers/PaymentModal/SHOW_LOADING_FULL_SCREEN_WECHAT';
export const SHOW_LOADING_FULL_SCREEN_CARD = 'containers/PaymentModal/SHOW_LOADING_FULL_SCREEN_CARD';

export const HIDE_LOADING_FULL_SCREEN = 'containers/PaymentModal/HIDE_LOADING_FULL_SCREEN';
export const REDIRECT_LOGIN = 'containers/PaymentModal/REDIRECT_LOGIN';
export const INIT_COUNTDOWN = 'containers/PaymentModal/INIT_COUNTDOWN';
export const INIT_COUNTDOWN_SUCCESS = 'containers/PaymentModal/INIT_COUNTDOWN_SUCCESS';
export const UPDATE_COUNTDOWN = 'containers/PaymentModal/UPDATE_COUNTDOWN';
export const UPDATE_COUNTDOWN_SUCCESS = 'containers/PaymentModal/UPDATE_COUNTDOWN_SUCCESS';

export const CREATE_PAYMENT_INTENT_SINGLE_PREMIUM = 'containers/PaymentModal/CREATE_PAYMENT_INTENT_SINGLE_PREMIUM';
export const CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_SUCCESS = 'containers/PaymentModal/CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_SUCCESS';
export const CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_FAILED = 'containers/PaymentModal/CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_FAILED';
export const SELECTED_SINGLE_PRICE_PREMIUM = 'containers/PaymentModal/SELECTED_SINGLE_PRICE_PREMIUM';
export const INIT_POLLING_PAYMENT_INTENT = 'containers/PaymentModal/INIT_POLLING_PAYMENT_INTENT';
export const STOP_POLLING_PAYMENT_INTENT = 'containers/PaymentModal/STOP_POLLING_PAYMENT_INTENT';
export const UPGRADE_SINGLE_PREMIUM_WITH_CARD = 'containers/PaymentModal/UPGRADE_SINGLE_PREMIUM_WITH_CARD';

