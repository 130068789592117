import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_COLLECTIONS,
  LOAD_COLLECTIONS_SUCCESS,
  LOAD_COLLECTIONS_ERROR,
  LOAD_COLLECTIONS_OF_MONTHS,
  LOAD_COLLECTIONS_OF_MONTHS_SUCCESS,
  LOAD_COLLECTIONS_OF_MONTHS_ERROR,
  NICE_PHOTO,
} from './constants';

import * as collectionPageService from '~/services/collection';

function* loadCollections(action) {
  const { payload, api } = action;
  const apiFunction = api
    ? api.photo.getCollectionsFullData
    : collectionPageService.getCollections;
  try {
    let collections = yield call(apiFunction, payload);
    yield put({
      type: LOAD_COLLECTIONS_SUCCESS,
      collections,
      page: payload.page,
    });
  } catch (error) {
    yield put({ type: LOAD_COLLECTIONS_ERROR, error: true });
  }
}

function* loadCollectionsOfMonths(action) {
  const { payload, api } = action;
  const apiFunction = api
    ? api.photo.getCollectionOfMonths
    : collectionPageService.getCollectionOfMonths;
  try {
    let collections = yield call(apiFunction, payload);
    yield put({
      type: LOAD_COLLECTIONS_OF_MONTHS_SUCCESS,
      collections,
    });
  } catch (error) {
    yield put({ type: LOAD_COLLECTIONS_OF_MONTHS_ERROR, error: true });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* collectionsSaga() {
  yield takeLatest(LOAD_COLLECTIONS, loadCollections);
  yield takeLatest(LOAD_COLLECTIONS_OF_MONTHS, loadCollectionsOfMonths);
}
