import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { urls, locales } from '~/utils';
import Avatar from '~/lib/avatar/Avatar';
import Config from '~/config';
import withI18next from '~/../hocs/_withI18next';

const ROLES = {
  FREE: 1,
  PREMIUM: 2,
};

let iconPremium = (
  <img
    src={`${Config.staticPrefix}/static/assets/image/premium/crown.png`}
    className="icon-premium"
    alt="crown"
  />
);
iconPremium = ReactDOMServer.renderToString(iconPremium);

const ListRegisteredUsers = withI18next()(({ users, t, className }) => {
  if (users.length === 0) {
    return (
      <div className={`list-registered-users ${className}`}>
        <h3 className="no-logs">{t('no_logs_registered_users')}</h3>
      </div>
    );
  }
  return (
    <div className={`list-registered-users ${className}`}>
      {users.map((user) => {
        const descriptionKey =
          user.registered_role_id === ROLES.PREMIUM
            ? 'premium_user_added_at_time'
            : 'free_user_added_at_time';
        const language = locales.convertToShortType(user.lang);
        return (
          <div className="user-card" key={user.id}>
            <Avatar
              src={urls.renderAvatarUrl(user.avatar)}
              width={64}
              height={64}
            />
            <div
              className="user-info"
              dangerouslySetInnerHTML={{
                __html: t(descriptionKey, {
                  time: moment
                    .unix(user.registered_at)
                    .format('DD MMM, YYYY hh:mm A'),
                  icon: iconPremium,
                }),
              }}
            />
            <img
              className="user-flag"
              src={`${Config.staticPrefix}/static/assets/image/flags/${language}.png`}
            />
          </div>
        );
      })}
      <style jsx>
        {`
          .list-registered-users {
            background: #fff;
          }
          .list-registered-users .no-logs {
            text-align: center;
          }
          .user-card {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 15px 20px;
            box-shadow: 0 1px 5px rgba(134, 159, 178, 0.6);
            margin-top: 12px;
            background: white;
            border-bottom: none;
          }
          .user-card:first-child {
            margin-top: 0;
          }
          .list-registered-users .user-avatar {
            width: 20px;
            height: 20px;
          }
          .list-registered-users .user-info {
            flex: 1;
            margin: 0 14px;
          }
          .list-registered-users .user-info .icon-premium {
            margin-bottom: 4px;
          }
          .list-registered-users .registered-description {
            text-align: left;
          }
          .list-registered-users .registered-time {
            font-size: 12px;
            text-align: right;
          }
          .list-registered-users .user-flag {
            width: 30px;
            height: auto;
          }
        `}
      </style>
    </div>
  );
});

export default ListRegisteredUsers;
