import cn from 'clsx';
import { withRouter } from 'next/router';
import React from 'react';
import { Menu, MenuItem, Token, Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import {
  changeCategory,
  changeColor,
  changeCreatorName,
  changeExcludeCategory,
  changeExcludeCreatorName,
  changeExcludeKeyword,
  changeImageId,
  changeImageTitle,
  changeImageType,
  changeModelAge,
  changeModelCount,
  changeModelRelease,
  changeLicense,
  changeOrderBy,
  changeOrientation,
  changeShape,
  changeToggleFilterSearch,
  clearSearch,
} from '../actions';
import { selectSearch } from '../selectors';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import { SEARCH_RESULT_ORDER, SERVICE_TYPES } from '~/utils/constants';
import themeVariables from '~/shared/themeVariables';
import typeaheadStyles from '~/shared/typeaheadStyles';
import IconFilter from '~/components/svg/icon-filter.svg';
import { makeSelectToggleFilterSearch } from '~/containers/Search/selectors';
import IconReset from '~/components/svg/icon-reset-download-history.svg';
import IconSearch from '~/components/svg/search.svg';
import I18nLink from '~/components/I18nLink';
// import IconCamera from '~/components/svg/icon-camera.svg';

// const image_type_photo_ac = [
//   { value: 'all', label: 'All' },
//   { value: 'm_size', label: 'M size' },
//   { value: 'l_size', label: 'L size' },
//   { value: 'psd_type', label: 'PSD' },
// ];
// const image_type_illust_ac = [
//   { value: 'all', label: 'All' },
//   { value: 'png', label: 'PNG' },
//   { value: 'ai-eps', label: 'AI / EPS' },
// ];
// const image_type_silhouette_ac = [
//   { value: 'all', label: 'All' },
//   { value: 'png', label: 'PNG' },
//   { value: 'ai-eps', label: 'AI / EPS' },
// ];

@withI18next()
@withRouter
class Search extends React.PureComponent {
  typeaheadRef = React.createRef();
  state = {
    showT: true,
    imgAddCameraMobile: `${Config.staticPrefix}/static/assets/svg/add-camera-default.svg`,
  };

  componentDidMount() {
    const { t } = this.props;
    if (!this.typeaheadRef.current) return;
    if (window.innerWidth >= 600) {
      this.typeaheadRef.current.getInput().placeholder = t(
        'enter_keyword_search_images',
      );
    } else {
      this.typeaheadRef.current.getInput().placeholder = t('search_for_images');
    }
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    if (router !== prevProps.router && router.query.image_token) {
      this.typeaheadRef.current.clear();
    }
    if (router !== prevProps.router && router?.query?.keyword) {
      // const keywordRouter = router?.query?.keyword || keyword;
      // if (this.typeaheadRef.current.getInput().value !== keywordRouter) {
      // this.typeaheadRef.current.getInput().value = keywordRouter;
      this.setState({ showT: false }, () => {
        this.setState({ showT: true });
      });
      // }
    }
  }

  handleChangeCategory = (selectedOption) => {
    this.props.onChangeCategory(selectedOption);
  };

  handleChangeImageTitle = (event) => {
    this.props.onChangeImageTitle(event);
  };

  handleChangeExcludeCategory = (selectedOption) => {
    this.props.onChangeExcludeCategory(selectedOption);
  };

  handleChangeImageType = (selectedOption) => {
    this.props.onChangeImageType(selectedOption);
  };

  onChangeExcludeKeyword = (e) => {
    this.props.onChangeExcludeKeyword(e.target.value);
  };

  onChangeKeyword = (value) => {
    this.props.onChangeKeyword(value);
  };

  onChangeOrder = (type) => {
    this.props.onChangeOrderBy(type);
  };

  onChangeShape = (e) => {
    this.props.onChangeShape(e);
  };

  onChangeImageId = (e) => {
    this.props.onChangeImageId(e.target.value);
  };
  onChangeImageType = (e) => {
    this.props.onChangeImageType(e.target.value);
  };
  onChangeOrientation = (e) => {
    this.props.onChangeOrientation(e.target.value);
  };
  // onClearSearch = () => {
  //   this.props.onClearSearch();
  // };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit();
    // this.props.onCloseRefineSearch();
    // this.handleChangeToggleFilterSearch(false);
  };

  handleChangeImageId = (e) => {
    this.props.onChangeImageId(e);
  };

  handleChangeOrder = (e) => {
    const dataTmp = {
      value: e,
      label:
        e === SEARCH_RESULT_ORDER.POPULAR
          ? this.props.t('popular')
          : e === SEARCH_RESULT_ORDER.NEWEST
          ? this.props.t('recent')
          : '',
    };
    this.props.onChangeOrderBy(dataTmp);
  };

  handleChangeExcludeKeyword = (e) => {
    this.props.onChangeExcludeKeyword(e);
  };

  handleChangeCategory = (selectedOption) => {
    const select = selectedOption.map((ele) => {
      return { ...ele, type: 'category' };
    });
    this.props.onChangeCategory(select);
  };

  handleChangeExcludeCategory = (selectedOption) => {
    const select = selectedOption.map((ele) => {
      return { ...ele, type: 'excludeCategory' };
    });
    this.props.onChangeExcludeCategory(select);
  };

  handleChangeCreatorName = (e) => {
    this.props.onChangeCreatorName(e);
  };

  handleChangeExcludeCreatorName = (e) => {
    this.props.onChangeExcludeCreatorName(e);
  };

  handleChangeShape = (e) => {
    const dataTmp = {
      value: e,
      label:
        e === 'vertical'
          ? this.props.t('Vertical')
          : e === 'horizontal'
          ? this.props.t('Horizontal')
          : this.props.t('all'),
    };
    this.props.onChangeShape(dataTmp);
  };

  handleChangeColor = (value) => {
    this.props.onChangeColor(value);
  };

  handleChangeModelCount = (e) => {
    const dataTmp = {
      value: e,
      label: e === '-2' ? this.props.t('all') : e,
    };
    this.props.onChangeModelCount(dataTmp);
  };

  handleChangeModelAge = (selectedOption) => {
    const select = selectedOption.map((ele) => {
      return { ...ele, type: 'modelAge' };
    });
    this.props.onChangeModelAge(select);
  };
  hanleChangeImageType = (e) => {
    this.props.onChangeImageType(e);
  };

  handleChangeOrientation = (value) => {
    this.props.onChangeOrientation(value);
  };
  handleChangeModelRelease = (value) => {
    this.props.onChangeModelRelease(value);
  };

  handleChangeLicense = (value) => {
    const { t } = this.props;
    const dataTmp = {
      value,
      label:
        value === '0'
          ? t('without_extra_licenses')
          : value === '1'
          ? t('extra_licenses')
          : '',
    };
    this.props.onChangeLicense(dataTmp);
  };

  onChangeImgAddCameraMobile = (data) => {
    if (data === -1) {
      this.setState({
        imgAddCameraMobile: `${Config.staticPrefix}/static/assets/svg/add-camera-default.svg`,
      });
    }
    if (data === Config.serviceType) {
      this.setState({
        imgAddCameraMobile: `${Config.staticPrefix}/static/assets/svg/add-camera-${Config.serviceType}.svg`,
      });
    }
  };

  clearDataFilter = (dataNow, dataRemove) => {
    const { t } = this.props;

    // const dataDifferent = differenceWith(dataNow, dataNew, isEqual);
    // const typeChange = dataDifferent[0].type;
    const typeChange = dataRemove.type;
    // const dataNew = dataNow.filter((ele) => {
    //   return ele.value !== dataRemove.value && ele.type === dataRemove.type;
    // });
    const dataNew = dataNow.filter((ele) => {
      if (ele.type !== dataRemove.type) return true;
      if (ele.type === dataRemove.type && ele.value !== dataRemove.value)
        return true;
      return false;
    });
    const dataFilterNewFromType = dataNew
      .filter((val) => val.type === typeChange)
      .map((data) => {
        return {
          value: data.value,
          label: data.label.split(':')[1],
          key:
            typeChange === 'category'
              ? t('include_keyword')
              : typeChange === 'excludeCategory'
              ? t('exclude_keyword')
              : '',
        };
      });

    switch (typeChange) {
      case 'category':
        this.handleChangeCategory(dataFilterNewFromType);
        break;
      case 'color':
        this.handleChangeColor('all');
        break;
      case 'excludeKeyword':
        this.handleChangeExcludeKeyword({
          value: '',
        });
        break;
      case 'excludeCategory':
        this.handleChangeExcludeCategory(dataFilterNewFromType);
        break;
      case 'creatorName':
        this.handleChangeCreatorName({
          value: '',
        });
        break;
      case 'excludeCreatorName':
        this.handleChangeExcludeCreatorName({
          value: '',
        });
        break;
      case 'imageType':
        this.hanleChangeImageType({
          value: '',
        });
        break;
      case 'orderBy':
        this.handleChangeOrder(SEARCH_RESULT_ORDER.ALL_IMAGES);
        break;
      case 'shape':
        this.handleChangeShape('all');
        break;
      case 'imageId':
        this.handleChangeImageId({
          value: '',
          key: 'ID',
        });
        break;
      case 'imageTitle':
        this.handleChangeImageTitle({
          value: '',
        });
        break;
      case 'modelCount':
        this.handleChangeModelCount('-2');
        break;
      case 'modelAge':
        this.handleChangeModelAge(dataFilterNewFromType);
        break;
      case 'orientation':
        this.handleChangeOrientation('');
        break;
      case 'modelRelease':
        this.handleChangeModelRelease({
          value: '',
        });
        break;
      case 'license':
        this.handleChangeLicense('');
        break;
    }
  };
  handleChangeToggleFilterSearch = (value) => {
    this.props.onToggleFilterSearch(value);
  };

  render() {
    const {
      // keyword,
      // toggle,
      // categories,
      t,
      // onCloseRefineSearch,
      inHeader,
      forwardedRef,
      // onToggleRefineSearch,
      onOpenSearchByPhotoModal,
      // onClearSearch,
      searchState,
      toggleFilterSearch,
    } = this.props;

    // const refineSearchClassName = cn('nav-menu-item refine-search', {
    //   'nav-menu-item-active': searchState.toggleFilterSearch,
    // });
    const searchClassName = cn('search-container', {
      'in-header': inHeader,
    });

    const logoInfos = {
      [SERVICE_TYPES.PHOTO_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_photo_ac_new.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/logo.png`,
        descriptionKey: 'free_stock_photos_ac',
      },
      [SERVICE_TYPES.ILLUST_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_illustAc_header_mobile.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/illust.png`,
        descriptionKey: 'free_vector_clip_art',
      },
      [SERVICE_TYPES.SILHOUETTE_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_silhouetteAc_header_mobile.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/silhouetteAc_logo.png`,
        descriptionKey: 'free_vector_silhouettes',
      },
      [SERVICE_TYPES.DESIGN_AC]: {
        mobile: `${Config.staticPrefix}/static/assets/logo/logo_designAc_header_mobile.svg`,
        desktop: `${Config.staticPrefix}/static/assets/image/logo/design.png`,
        descriptionKey: 'free_design_templates',
      },
    };
    const logoInfo = logoInfos[Config.serviceType];

    // let imageTypeList = [];
    const router = this.props.router;
    // if (Config.serviceType === 'photo_ac') {
    //   imageTypeList = image_type_photo_ac;
    // } else if (Config.serviceType === 'illust_ac') {
    //   imageTypeList = image_type_illust_ac;
    // } else if (Config.serviceType === 'silhouette_ac') {
    //   imageTypeList = image_type_silhouette_ac;
    // }
    // const dataNow = [];
    // if (searchState.category.length > 0) {
    //   const data = searchState.category.map((element) => ({
    //     ...element,
    //     type: 'category',
    //   }));
    //   dataNow.push(...data);
    // }
    // if (searchState.excludeCategory.length > 0) {
    //   const data = searchState.excludeCategory.map((element) => ({
    //     ...element,
    //     type: 'excludeCategory',
    //   }));
    //   dataNow.push(...data);
    // }
    // if (searchState.creatorName !== '') {
    //   dataNow.push({ label: searchState.creatorName, type: 'creatorName' });
    // }
    // if (searchState.excludeCreatorName !== '') {
    //   dataNow.push({
    //     label: searchState.excludeCreatorName,
    //     type: 'excludeCreatorName',
    //   });
    // }
    // if (searchState.imageType !== '') {
    //   dataNow.push({ label: searchState.imageType, type: 'imageType' });
    // }
    // if (searchState.orderBy !== 'popular') {
    //   dataNow.push({ label: searchState.orderBy, type: 'orderBy' });
    // }
    // if (searchState.shape !== 'all') {
    //   dataNow.push({ label: searchState.shape, type: 'shape' });
    // }
    // if (searchState.imageId !== '') {
    //   dataNow.push({ label: searchState.imageId, type: 'imageId' });
    // }
    // if (searchState.imageTitle !== '') {
    //   dataNow.push({ label: searchState.imageTitle, type: 'imageTitle' });
    // }
    // if (searchState.color !== 'all') {
    //   dataNow.push({ label: searchState.color, type: 'color' });
    // }
    // if (searchState.modelCount !== '-2') {
    //   dataNow.push({ label: searchState.modelCount, type: 'modelCount' });
    // }
    // if (searchState.modelAge.length > 0) {
    //   const data = searchState.modelAge.map((element) => ({
    //     ...element,
    //     type: 'modelAge',
    //   }));
    //   dataNow.push(...data);
    // }
    // if (searchState.excludeKeyword !== '') {
    //   dataNow.push({
    //     label: searchState.excludeKeyword,
    //     type: 'excludeKeyword',
    //   });
    // }
    // if (searchState.orientation !== '') {
    //   dataNow.push({ label: searchState.orientation, type: 'orientation' });
    // }
    // if (searchState.modelRelease !== '') {
    //   dataNow.push({ label: searchState.modelRelease, type: 'modelRelease' });
    // }
    const keywordRouter = router?.query?.keyword || '';
    // const keywordRouter = searchState.keyword;
    // console.log('hihi', keywordRouter);
    const refineSearchClassName2 = cn('refine-search-2', Config.serviceType, {
      'nav-menu-item-active': toggleFilterSearch,
      toggle: toggleFilterSearch,
    });
    const isFilterSearch =
      searchState.excludeKeyword !== '' ||
      searchState.excludeCategory.length > 0 ||
      searchState.excludeCreatorName !== '' ||
      searchState.imageType !== '' ||
      searchState.orderBy !== SEARCH_RESULT_ORDER.ALL_IMAGES ||
      searchState.shape !== 'all' ||
      searchState.imageId !== '' ||
      searchState.imageTitle !== '' ||
      searchState.color !== 'all' ||
      searchState.modelCount !== '-2' ||
      searchState.modelAge.length > 0 ||
      searchState.orientation !== '' ||
      searchState.modelRelease !== '' ||
      searchState.license !== ''
        ? true
        : false;
    const isHomePage = router.pathname === '/index' || router.pathname === '/';
    return (
      <form
        className={searchClassName}
        onSubmit={this.onSubmit}
        ref={forwardedRef}
      >
        {!isHomePage && (
          <div className="logo-mobile">
            <I18nLink href={{ pathname: '/' }}>
              <Image
                className="lazyload img-logo-desktop"
                src={logoInfo.mobile}
                alt="site logo"
                layout="fixed"
                objectFit="contain"
                width={'40px'}
                height={'28px'}
              />
            </I18nLink>
          </div>
        )}
        <div className="searchForm">
          <span className="icon-search-mobile">
            <FontAwesomeIcon icon={faMagnifyingGlass} width={18} />
          </span>
          {this.state.showT && (
            <Typeahead
              style={{ fontSize: 16 }}
              ref={this.typeaheadRef}
              id="basic-typeahead-multiple"
              // type="text"
              // value={keyword}
              onChange={(dataNew) => {
                console.log(dataNew);
              }}
              onInputChange={(text) => this.onChangeKeyword(text)}
              defaultInputValue={(keywordRouter || searchState.keyword).replace(
                /\+/g,
                ' ',
              )}
              className="form-control text-search"
              placeholder={t('enter_keyword_search_images')}
              options={[]}
              selected={searchState.dataNow}
              // autoFocus
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  this.props.onSubmit();
                  window.scrollTo(0, 0);
                  // this.handleChangeToggleFilterSearch(false);
                }
              }}
              multiple
              renderMenu={(results, menuProps) => {
                if (!results.length) {
                  return null;
                }
                return (
                  <Menu {...menuProps}>
                    {results.map((result, index) => (
                      <MenuItem key={index} option={result} position={index}>
                        {result.label}
                      </MenuItem>
                    ))}
                  </Menu>
                );
              }}
              renderToken={(option, props) => {
                if (option.type === 'keyword') {
                  return null;
                }
                if (option.type === 'color') {
                  return (
                    <Token
                      disabled={props.disabled}
                      onRemove={() => {
                        this.clearDataFilter(searchState.dataNow, option);
                      }}
                    >
                      <div
                        style={{
                          background: `#${option.label}`,
                          width: 15,
                          height: 15,
                          position: 'relative',
                        }}
                      ></div>
                    </Token>
                  );
                }
                return (
                  <Token
                    disabled={props.disabled}
                    onRemove={() => {
                      this.clearDataFilter(searchState.dataNow, option);
                    }}
                  >
                    {option.label}
                  </Token>
                );
              }}
            />
          )}
          {isFilterSearch && (
            <div
              className="icon-clear-search"
              onClick={() => this.props.onClearSearch()}
            >
              <IconReset />
            </div>
          )}
          {searchState.keyword && (
            <button
              className="icon-clear-keyword"
              onClick={() => {
                this.onChangeKeyword('');
                this.typeaheadRef.current.clear();
              }}
            >
              <IconReset />
            </button>
          )}
          <button className={`btn button-search ${Config.serviceType}`}>
            <span className="icon-search">
              <IconSearch />
            </span>
            {/* <img
              src={`${Config.staticPrefix}/static/assets/svg/search-icon.svg`}
              width={`${inHeader ? 30 : 35}`}
              height={`${inHeader ? 30 : 35}`}
              alt="icon filter search"
            /> */}
          </button>
        </div>
        {!Config.isDesignAC() && !isHomePage && (
          <div
            className={refineSearchClassName2}
            onClick={() => {
              this.handleChangeToggleFilterSearch(true);
            }}
          >
            {Config.isIllustAC() && (
              <div className="icon-open-filter">
                <FontAwesomeIcon
                  style={{ width: 14 }}
                  icon={faChevronLeft}
                ></FontAwesomeIcon>
              </div>
            )}
            <div className="custom-button-filter">
              <div className="icon-filter">
                <IconFilter />
              </div>
              <span className="text-filter m-l-10">{t('filter')}</span>
            </div>
          </div>
        )}
        {(Config.isPhotoAC() ||
          Config.isIllustAC() ||
          Config.isSilhouetteAC()) && (
          <div
            onClick={onOpenSearchByPhotoModal}
            className={`photo-search ${Config.serviceType}`}
            onMouseOver={(e) =>
              this.onChangeImgAddCameraMobile(Config.serviceType)
            }
            onMouseOut={(e) => this.onChangeImgAddCameraMobile(-1)}
          >
            <div
              className={`photo-search-border ${Config.serviceType} ${
                inHeader && 'inHeader'
              }`}
            >
              <img
                src={`${Config.staticPrefix}/static/assets/svg/add-camera.svg`}
                width={`${inHeader ? 30 : 35}`}
                height={`${inHeader ? 30 : 35}`}
                alt="icon camera"
                className="img-search-img-desktop"
              />
              <img
                src={this.state.imgAddCameraMobile}
                width={`${inHeader ? 30 : 35}`}
                height={`${inHeader ? 30 : 35}`}
                alt="icon camera"
                className="img-search-img-mobile"
              />
              <span
                className="search-by-image-text"
                style={{ marginLeft: '8px' }}
              >
                {t('search_by_image')}
              </span>
            </div>
          </div>
        )}
        {/* <RefineSearch
          t={t}
          categories={categories}
          onSubmit={this.props.onSubmit}
          onCloseRefineSearch={this.props.onCloseRefineSearch}
          toggle={searchState.toggleFilterSearch}
        /> */}
        <style jsx>
          {`
            .icon-search {
              width: ${inHeader ? '30px' : '35px'} !important;
              height: ${inHeader ? '30px' : '35px'} !important;
            }
          `}
        </style>
        <style jsx global>
          {`
            .searchForm .rbt-input-multi .rbt-input-wrapper {
              width: calc(100% - 90px) !important;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              overflow-x: scroll;
            }
            .icon-clear-keyword,
            .logo-mobile,
            .icon-search-mobile{
              display: none;
            }
            .searchForm .rbt-input-multi .rbt-input-wrapper > div:last-child {
              width: fit-content;
              min-width: 200px;
            }
            .rbt-input-wrapper::-webkit-scrollbar {
              display: none;
            }
            .searchForm .rbt-token {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              white-space: nowrap;
            }
            .icon-clear-search {
              position: absolute;
              right: 52px;
              cursor: pointer;
            }
            .custom-button-filter {
              display: flex;
            }
         
            .ele-select{
              width:auto:
              height:10px;
              background:red;
            }

            .clear-filter:hover {
              border: 1px solid white;
            }

            .clear-filter.photo_ac{
              background: #509bdc;
            }
            .clear-filter.illust_ac{
              background: #d73a4e;
            }
            .clear-filter.silhouette_ac{
              background: #41b9b8;
            }
            .onclick-refine {
              width: 284px;
              height: 44px;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 4px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 10px;
            }

            .onclick-refine.photo_ac{
              background: #1B2833;
            }

            .onclick-refine.illust_ac{
              background: #d73a4e;
            }
            .onclick-refine.silhouette_ac{
              background: #41b9b8;
            }

            .search-container {
              max-width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              color: #1B2833;
              align-items: center;
              border-radius: ${Config.isIllustAC() ? '40px' : '2px'};
              border-color: #fff;
              box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
            }
            .search-container.in-header {
              box-shadow: none;
              background: #f2f3f4;
              justify-content: space-between;
            }
            .search-container.in-header .text-search,
            .search-container.in-header .middle-search,
            .search-container.in-header .refine-search {
              background: none;
            }
            .search-container.in-header .photo-search {
              height: 44px;
              border-radius: ${Config.isIllustAC() ? '40px' : '4px'};
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              color: #ffffff;
              width: 280px;
            }
            .search-container.in-header .photo-search.photo_ac{
              background: #1B2833;
            }
            .search-container.in-header .photo-search.illust_ac{
              background: ${themeVariables.color.primary};
            }
            .search-container.in-header .photo-search.silhouette_ac{
              background: #1B2833;;

            }
            .search-container.in-header .photo-search.photo_ac:hover{
              background: #1D7AB3;
            }
            .search-container.in-header .photo-search.illust_ac:hover{
              background: #D73A4E;
              opacity: 0.9;
            }
            .search-container.in-header .photo-search.silhouette_ac:hover{
              background: #41B9B8;
            }
            .search-container.in-header .button-search {
              width: 40px;
              position: absolute;
              right: -2px;
              top: 0;
              bottom: 0;
              height: 44px;
              margin: auto;
              border-radius:${
                Config.isIllustAC() ? '0 40px 40px 0' : '0 4px 4px 0'
              } ;
            }
            .searchForm {
              display: flex;
              flex-grow: 1;
              border: ${Config.isIllustAC() ? '1px' : '2px'} solid #1b2833;
              box-sizing: border-box;
              border-radius: ${Config.isIllustAC() ? '40px' : '4px'};
              justify-content: space-between;
              align-items: center;
              height: 44px;
              position:relative;
              width: -webkit-fill-available;
            }
            .searchForm.toggle {
              border: none;
            }
            .search-container .text-search {
              box-shadow: none;
              flex: 1;
              height: 100%;
              border: none;
              box-sizing: border-box;
              border-radius: ${Config.isIllustAC() ? '40px' : '4px'};
              font-size: 18px;
              flex-grow: 1;
              width: 100%;
              padding:0;
              z-index:0
            }
            .rbt-token{
              background-color: #f3f4f5 !important;
              color:black  !important;
              line-height:20px !important;
            }
            .rbt-input-multi.form-control {
              overflow: hidden;
              height: 100% !important;
              overflow-x: scroll;
              display: flex;
              flex-direction: column;
              justify-content: center;
              ${Config.isIllustAC() ? 'border-radius: 40px;' : ''}
              scrollbar-width: none !important;
            }
            .rbt-input-multi.form-control::-webkit-scrollbar {
              display: none;
            }
            .rbt-input-multi.form-control::-webkit-scrollbar-thumb {
              display: none;
            }
            .rbt-input-multi.form-control::-webkit-scrollbar-track {
              display: none;
            }
            .rbt-input-multi .rbt-input-wrapper {
              height: 100% !important;
              margin:0 !important;
              width: max-content !important;
              position: absolute !important;
              align-items: center !important;
              scrollbar-width: none !important;
            }
            .rbt-input-multi .rbt-input-wrapper input{
              font-size:16px !important;
            }
            .rbt-input-multi .form-control .rbt-input{
              height:100% !important;
              border:none !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
            }
            .rbt-input-multi.form-control:focus {
              border-color: none !important;
              box-shadow: none !important;
              color: none !important;
              outline: 0;
            }

            .rbt-input-multi{
              height:100%;
              border:none !important;
              border-radius:none;
            }
            .rbt-input-multi:focus{
              border:none !important;
            }
            .rbt-token-removeable{
              padding: 0 5px!important;
              height:70% !important;

            }
            .rbt-token-remove-button{
              position:relative !important;
              top: 0 !important;
              left: 3px;
              border: none !important;
              background: none !important;
            }
             .text-search.toggle {
              border: 2px solid #1b2833;
              border-right: none;
            }
             .text-search:focus {
              box-shadow: none;
            }
            .input-text-filter {
              border: 1px solid #f2f3f4;
              box-shadow: none;
              height: 40px;
              ${
                Config.isIllustAC()
                  ? 'border-radius: 40px; '
                  : 'border-radius: 4px; background: #f2f3f4;'
              }
            }
            .search-dropdown-illust_ac__control {
              -webkit-box-align: center;
              align-items: center;
              background-color: #fafafa;
              cursor: default;
              display: flex;
              flex-wrap: wrap;
              -webkit-box-pack: justify;
              justify-content: space-between;
              min-height: 38px;
              position: relative;
              box-sizing: border-box;
              border-color: #cccccc;
              border-radius: 2px;
              border-style: solid;
              border-width: 1px;
              transition: all 100ms ease 0s;
              outline: 0px !important;
            }
            .search-dropdown-design_ac__control {
              -webkit-box-align: center;
              align-items: center;
              background-color: #fafafa;
              cursor: default;
              display: flex;
              flex-wrap: wrap;
              -webkit-box-pack: justify;
              justify-content: space-between;
              min-height: 38px;
              position: relative;
              box-sizing: border-box;
              border-color: #cccccc;
              border-radius: 2px;
              border-style: solid;
              border-width: 1px;
              transition: all 100ms ease 0s;
              outline: 0px !important;
            }
            .search-dropdown-silhouette_ac__control {
              -webkit-box-align: center;
              align-items: center;
              background-color: #fafafa;
              cursor: default;
              display: flex;
              flex-wrap: wrap;
              -webkit-box-pack: justify;
              justify-content: space-between;
              min-height: 38px;
              position: relative;
              box-sizing: border-box;
              border-color: #cccccc;
              border-radius: 2px;
              border-style: solid;
              border-width: 1px;
              transition: all 100ms ease 0s;
              outline: 0px !important;
            }
             .search-dropdown-photo_ac__control {
              -webkit-box-align: center;
              align-items: center;
              background-color: #fafafa;
              cursor: default;
              display: flex;
              flex-wrap: wrap;
              -webkit-box-pack: justify;
              justify-content: space-between;
              min-height: 38px;
              position: relative;
              box-sizing: border-box;
              border-color: #cccccc;
              border-radius: 2px;
              border-style: solid;
              border-width: 1px;
              transition: all 100ms ease 0s;
              outline: 0px !important;
            }

             .refine-search {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background: #fff;
              font-size: 18px;
              cursor: pointer;
            }
            .text-search {
              background: #f8f9fa;
            }
            .photo-search {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background: #000000;
              height: 100%;
              border-left: 1px solid rgba(143, 155, 165, 0.5);
              padding: 0 2px;
              font-size: 25px;
              cursor: pointer;
              margin-left: 10px;
            }
             .photo-search .photo-search-border {
              padding: 4px;
              display: flex;
              flex-direction: row;
              align-items: center;
              white-space: nowrap;
            }
            .refine-search-mobile {
              display: none;
            }

             .button-search {
              min-width: 50px;
              width: 65px;
              height: 65px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: #fff;
              border-radius: 0 4px 4px 0;
              font-size: 20px;
            }
             .button-search:hover,
             .button-search:focus {
              color: #fff;
              opacity: 0.9;
            }

             .button-search.design_ac {
              background: #1B2833;
            }
             .button-search.illust_ac {
              background: #000000;
              border-left: none;
            }
             .button-search.photo_ac {
              background: #1B2833;
            }
             .button-search.silhouette_ac {
              background: #1B2833;
            }

             .button-search.design_ac:hover {
              background: #FFA823;
            }
             .button-search.illust_ac:hover {
              background: #D73A4E;
            }
             .button-search.photo_ac:hover {
              background: #1D7AB3;
            }
             .button-search.silhouette_ac:hover {
              background: #41B9B8;
            }

             .nav-menu-item {
              display: inline-block;
              position: relative;
              margin-left: 10px;
            }

             .nav-menu-item-active .nav-submenu_arrow_up {
              display: block;
            }

             .nav-submenu_arrow_up {
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #ffffff;
              padding-top: 14px;
              position: absolute;
              display: none;
              margin-left: 40px;
              z-index: 101;
            }

             .nav-submenu .submenu-item-radio {
              display: block;
              width: 100%;
              font-size: 14px;
              line-height: 25px;
            }

             .nav-submenu .submenu-item-radio .ac-radio {
              display: block;
            }

             .nav-submenu .image-type {
              display: flex;
              flex-flow: wrap;
            }
             .nav-submenu .image-type .ac-radio {
              width: 50%;
            }
             .nav-submenu .category {
              display: flex;
            }
             .nav-submenu .category .selectCategory {
              flex-grow: 1;
            }
            .search-container .middle-search {
              display: flex;
              flex-direction: row;
              align-items: center;
              background: #ffffff;
              width: auto;
              height: 100%;
              border: 0;
              border-left: none;
              border-right: none;
              padding-right: 14px;
            }
            .search-container .wrapper-chip {
              padding: 5px;
              display: inline-flex;
              margin: 2px;
              border-radius: 2px;
              height: 30px;
            }
            .search-container .wrapper-chip .btn-close-chip {
              border: none;
              color: #fff;
              border-right: 1px solid #fff;
              padding-left: 0;
            }
            .search-container .wrapper-chip.illust_ac {
              background-color: #d73a4e;
            }
            .search-container .wrapper-chip.illust_ac .btn-close-chip {
              background-color: #d73a4e;
            }
            .search-container .wrapper-chip.photo_ac {
              background-color: #509bdc;
            }
            .search-container .wrapper-chip.photo_ac .btn-close-chip {
              background-color: #509bdc;
            }
            .search-container .wrapper-chip.design_ac {
              background-color: #ffa823;
            }
            .search-container .wrapper-chip.design_ac .btn-close-chip {
              background-color: #ffa823;
            }
            .search-container .wrapper-chip.silhouette_ac {
              background-color: #41b9b8;
            }
            .search-container .wrapper-chip.silhouette_ac .btn-close-chip {
              background-color: #41b9b8;
            }
            .search-container .text-chip {
              line-height: 21px;
              color: #ffffff;
              margin-left: 3px;
            }

            #photo-search-tooltip .tooltip-inner {
              background-color: #1B2833;
              color: #ffcc00;
            }
            .img-search-img-mobile{
              display:none;
            }
            @media (max-width: 768px) {
              .search-container.in-header {
                background: none;

              }
              .search-container.in-header .photo-search{
                width:50px;
              }
              .img-search-img-desktop{
                display:none;
              }
              .img-search-img-mobile{
                display:block;
              }
              .search-container.in-header .photo-search.photo_ac{
                background: #F8F9FA;
                border: 2px solid #1B2833;
                box-sizing: border-box;
                border-radius: 4px;
              }
              .search-by-image-text {
                display: none;
              }
              .search-container .refine-search {
                display: none;
              }
              .onclick-refine {
                display: none;
              }
              .search-container .refine-search-mobile {
                display: flex;
                width: 40px;
                margin-left: 10px;
              }
              .search-container .nav-menu-item {
                margin-left: 0;
              }
              .searchForm.toggle {
                border: 2px solid #1b2833;
              }
              .search-container .text-search.toggle {
                border none;
              }
              .search-container .button-search.photo_ac.toggle {
                background: #509bdc;
                width: 40px;
                height: 40px;
                border-radius: 0px
              }
              .search-container.in-header .photo-search.photo_ac{
                background: #fff;
                border:2px solid #1B2833;
              }
              .search-container.in-header .photo-search.illust_ac{
                background: #fff;
                border:2px solid #1B2833;
              }
              .search-container.in-header .photo-search.silhouette_ac{
                background: #fff;
                border:2px solid #1B2833;;

              }
              .search-container.in-header .photo-search.photo_ac:hover{
                background: #fff;
                border:2px solid #1D7AB3;
              }
              .search-container.in-header .photo-search.illust_ac:hover{
                background: #fff;
                border:2px solid #D73A4E;
              }
              .search-container.in-header .photo-search.silhouette_ac:hover{
                background: #fff;
                border:2px solid #41B9B8;
              }
            }
            .refine-search-2{
              display: none;
            }
            @media only screen and (max-width: ${
              themeVariables.breakpoints.mobile.maxWidth
            }px) {
                .refine-search-2{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  font-size: 18px;
                  cursor: pointer;
                  width: 60px;
                  height: 44px;
                  display: flex;
                  padding: 0px;
                  border: 2px solid #1b2833;
                  box-sizing: border-box;
                  border-radius: 40px;
                  color: #1b2833;
                  left: -10px;
                  margin-left: 10px;
                }
                .refine-search-2.photo_ac:hover {
                  border-color: #1d7ab3;
                  color: #1d7ab3;
                }
                .refine-search-2.illust_ac {
                  width: 60px;
                  height: 40px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  background: #d73a4e;
                  border-radius: 50%;
                  color: #ffffff;
                  border: none;
                  margin-left: 10px;
                }
                .refine-search-2.illust_ac:hover {
                  opacity: 0.9;
                }
                .refine-search-2.silhouette_ac:hover {
                  border-color: #41b9b8;
                  color: #41b9b8;
                }
                .refine-search-2.silhouette_ac:hover {
                  border-color: #ffa823;
                  color: #ffa823;
                }
                .refine-search-2.toggle {
                  display: none;
                  transition: display 0.4;
                }
                .photo-search,
                .text-filter,
                .icon-open-filter,
                .search-container.in-header .button-search {
                  display: none;
                }
                .icon-search-mobile{
                  display: block;
                  color: #6c757d;
                  width: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                }
                .searchForm{
                  background: #ffffff;
                }
                .searchForm .form-control{
                  box-shadow: unset;
                  padding-left: 0;
                  border-bottom-left-radius: 0;
                  border-top-left-radius: 0;
                }
                .searchForm .rbt-input-multi .rbt-input-wrapper{
                  width: calc(100% - 10px) !important;
                }
                input:focus{
                  outline: none;
                }
              .logo-mobile{
                display: block;
                margin-right: 10px;
              }
              .icon-clear-keyword{
                display: block;
                border: none;
                background: unset;
                margin-right: 6px;
              }
              .rbt-input-multi.form-control,
              .rbt-input-multi.form-control.focus,
              .rbt-input-multi.form-control:focus{
                transition: none;
                box-shadow: none;
              }
            }
          `}
        </style>
        <style jsx global>
          {typeaheadStyles}
        </style>
      </form>
    );
  }
}

Search.defaultProps = {
  categories: [],
  imageType: [],
  keyword: '',
  excludeKeyword: '',
  category: [],
  excludeCategory: [],
  toggle: false,
  inHeader: false,
  orderBy: '',
  shape: '',
  imageId: '',
  imageTitle: '',
  toggleFilterSearch: true,
};
const mapStateToProps = createStructuredSelector({
  searchState: selectSearch,
  toggleFilterSearch: makeSelectToggleFilterSearch(),
});
const mapDispatchToProps = (dispatch) => ({
  onChangeExcludeKeyword: (value) => {
    dispatch(changeExcludeKeyword(value));
  },
  onChangeCategory: (options) => {
    dispatch(changeCategory(options));
  },
  onChangeExcludeCategory: (options) => {
    dispatch(changeExcludeCategory(options));
  },
  onChangeCreatorName: (value) => {
    dispatch(changeCreatorName(value));
  },
  onChangeExcludeCreatorName: (value) => {
    dispatch(changeExcludeCreatorName(value));
  },
  onChangeImageType: (value) => {
    dispatch(changeImageType(value));
  },
  onChangeOrderBy: (value) => {
    dispatch(changeOrderBy(value));
  },
  onChangeShape: (value) => {
    dispatch(changeShape(value));
  },
  onChangeImageId: (value) => {
    dispatch(changeImageId(value));
  },
  onChangeImageTitle: (value) => {
    dispatch(changeImageTitle(value));
  },
  onChangeColor: (value) => {
    dispatch(changeColor(value));
  },
  onChangeModelCount: (value) => {
    dispatch(changeModelCount(value));
  },
  onChangeModelAge: (value) => {
    dispatch(changeModelAge(value));
  },
  onChangeOrientation: (value) => {
    dispatch(changeOrientation(value));
  },
  onChangeModelRelease: (value) => {
    dispatch(changeModelRelease(value));
  },
  onChangeLicense: (value) => {
    dispatch(changeLicense(value));
  },
  onClearSearch: () => {
    dispatch(clearSearch());
  },
  onToggleFilterSearch: (value) => {
    dispatch(changeToggleFilterSearch(value));
  },
});
// export default connect(mapStateToProps, mapDispatchToProps)(Search);
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Search);
