import { createSelector } from 'reselect';

const selectHome = (state) => state.home;

const makeSelectTags = () =>
  createSelector(selectHome, (homeState) => homeState.tags);

const makeSelectSliderImage = () =>
  createSelector(selectHome, (homeState) => homeState.sliderImages);

const makeSelectDataType = () =>
  createSelector(selectHome, (homeState) => homeState.dataType);

const makeSelectRecommendList = () =>
  createSelector(selectHome, (homeState) => homeState.recommendList);

const makeSelectEventType = () =>
  createSelector(selectHome, (homeState) => homeState.eventType);

const makeSelectDataTypePattern = () =>
  createSelector(selectHome, (homeState) => homeState.dataTypePattern);

const makeSelectPatternSelected = () =>
  createSelector(selectHome, (homeState) => homeState.patternSelected);

const makeSelectPatterns = () =>
  createSelector(selectHome, (homeState) => homeState.patterns);

const makeSelectCountDownload = () =>
  createSelector(selectHome, (homeState) => homeState.countDownload);

const makeSelectTotalData = () =>
  createSelector(selectHome, (homeState) => homeState.totalData);

const makeSelectTotalUser = () =>
  createSelector(selectHome, (homeState) => homeState.totalUser);

const makeSelectIsTopBannerVisible = () =>
  createSelector(selectHome, (homeState) => homeState.isTopBannerVisible);

const makeSelectCollections = () =>
  createSelector(selectHome, (homeState) => homeState.collections);

const makeSelectBlogs = () =>
  createSelector(selectHome, (homeState) => homeState.blogs);

export {
  selectHome,
  makeSelectTags,
  makeSelectRecommendList,
  makeSelectPatternSelected,
  makeSelectPatterns,
  makeSelectSliderImage,
  makeSelectCountDownload,
  makeSelectDataTypePattern,
  makeSelectTotalData,
  makeSelectTotalUser,
  makeSelectDataType,
  makeSelectEventType,
  makeSelectIsTopBannerVisible,
  makeSelectCollections,
  makeSelectBlogs,
};
