import React from 'react';
import ACImage from '~/components/ACImage';
import I18nLink from '~/components/I18nLink';
import themeVariables from '~/shared/themeVariables';

const CuratedTopic = (props) => {
  const { data, lng } = props;

  if (data?.length < 6) return null;

  const dataMain = data[5];
  const dataChild = data.slice(0, data.length - 1);

  const slugDataMain = JSON.parse(dataMain.slug);
  const slugMain = slugDataMain[`slug_${lng}`] || slugDataMain['slug_en'];

  return (
    <div className="curated-topic">
      {dataChild.map((ele, idx) => {
        const slugData = JSON.parse(ele.slug);
        const slug = slugData
          ? slugData[`slug_${lng}`] || slugData['slug_en']
          : '';

        return (
          <I18nLink key={ele.id} href={`/curated/${slug}-${ele.id}`}>
            <a id={`item-${idx}`} target="_blank">
              <div className="item-container">
                <ACImage
                  src={ele.thumbnail_url || ''}
                  alt={ele.name || ''}
                  className="topic-thumbnail"
                  layout="fill"
                  objectFit="cover"
                />
                <div className="background-curated">{ele.name || ''}</div>
              </div>
            </a>
          </I18nLink>
        );
      })}
      {dataMain && (
        <I18nLink href={`/curated/${slugMain}-${dataMain.id}`}>
          <a id="item-5" target="_blank">
            <div className="item-container-main">
              <ACImage
                src={dataMain.thumbnail_url || ''}
                alt={dataMain.name || ''}
                className="topic-thumbnail"
                layout="fill"
                objectFit="fill"
                priority
              />
              <div className="background-curated main">
                <span>{dataMain.name || ''}</span>
              </div>
            </div>
          </a>
        </I18nLink>
      )}
      <style jsx global>
        {`
          .topic-thumbnail {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        `}
      </style>
      <style jsx>
        {`
          .item-container {
            height: 100%;
            position: relative;
          }
          .item-container-main {
            height: 100%;
            position: relative;
          }
          .curated-topic {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 8px;
          }
          #item-0 {
            border-radius: 4px;
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-column-end: 2;
            cursor: pointer;
          }
          #item-1 {
            border-radius: 4px;
            grid-row-start: 1;
            grid-column-start: 2;
            grid-row-end: 2;
            grid-column-end: 3;
          }
          #item-2 {
            border-radius: 4px;
            grid-row-start: 1;
            grid-column-start: 3;
            grid-row-end: 2;
            grid-column-end: 4;
          }
          #item-3 {
            border-radius: 4px;
            grid-row-start: 2;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 2;
          }
          #item-4 {
            border-radius: 4px;
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 2;
          }
          #item-5 {
            height: 500px;
            border-radius: 4px;
            grid-row-start: 2;
            grid-column-start: 2;
            grid-row-end: 4;
            grid-column-end: 4;
          }
          .background-curated {
            border-radius: 4px;
            width: 100%;
            height: 48px;
            position: absolute;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.05)
            );
            font-weight: 500;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #ffffff;
            bottom: 0;
            padding-left: 32px;
          }
          @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
            .background-curated {
              font-size: 14px;
              padding-left: 8px;
            }

            #item-5 {
              height: 300px;
            }
          }

          @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
            .background-curated {
              font-size: 14px;
              padding-left: 8px;
            }

            #item-5 {
              height: 200px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default CuratedTopic;
