import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Pagination from '~/components/Pagination';
import withI18next from '~/../hocs/_withI18next';
import config from '~/config';
import FontIcomoonIcon from '~/components/FontIcomoonIcon';
import DropdownMenu from '~/components/DropdownMenu';
import CircleCloseIcon from '~/components/svg/circle-close.svg';
@withI18next()
class FilterData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        status: '',
        fileType: '',
        sortByPage: 'createdAt.desc',
        itemPerPage: 10,
        keyword: '',
        hasExtraLicense: false,
      },
    };
  }

  handleChangeInput(name, e) {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const filter = Object.assign({}, this.state.filter, { [name]: value });
    this.setState({ filter }, () => this.handleFilter(filter));
  }

  handleChangeKeyword(e) {
    this.setState({
      filter: Object.assign({}, this.state.filter, { keyword: e.target.value }),
    });
  }

  handleSubmit(e, value) {
    e.preventDefault();
    if (value === 'reset') {
      this.handleFilter(Object.assign({}, this.state.filter, { keyword: '' }));
    } else {
      this.handleFilter(Object.assign({}, this.state.filter));
    }
  }

  handleFilter(filter) {
    const { sortByPage, ...filterParams } = filter;
    const sortParams = sortByPage.split('.');
    return this.props.triggerEventChangeFilter({
      ...filterParams,
      [sortParams[0]]: sortParams[1],
    });
  }

  renderFileType() {
    const { t } = this.props;
    if (config.serviceType === 'photo_ac') {
      return (
        <div className="row">
          <div className="col-xs-4">
            <label role="button">
              <input
                name="fileType"
                type="radio"
                value=""
                checked={this.state.filter.fileType === ''}
                onChange={this.handleChangeInput.bind(this, 'fileType')}
              />
              &nbsp;
              {t('All')}
            </label>
          </div>
          <div className="col-xs-4">
            <label role="button">
              <input
                name="fileType"
                type="radio"
                value="psd"
                checked={this.state.filter.fileType === 'psd'}
                onChange={this.handleChangeInput.bind(this, 'fileType')}
              />
              &nbsp; psd
            </label>
          </div>
          <div className="col-xs-4">
            <label role="button">
              <input
                name="fileType"
                type="radio"
                value="jpeg"
                checked={this.state.filter.fileType === 'jpeg'}
                onChange={this.handleChangeInput.bind(this, 'fileType')}
              />
              &nbsp; jpeg
            </label>
          </div>
        </div>
      );
    }
    if (config.serviceType === 'design_ac') {
      return (
        <div>
          <label role="button">
            <input
              name="fileType"
              type="radio"
              value=""
              checked={this.state.filter.fileType === ''}
              onChange={this.handleChangeInput.bind(this, 'fileType')}
            />
            &nbsp;
            {t('All')}
          </label>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-xs-4">
          <label role="button">
            <input
              name="fileType"
              type="radio"
              value=""
              checked={this.state.filter.fileType === ''}
              onChange={this.handleChangeInput.bind(this, 'fileType')}
            />
            &nbsp;
            {t('All')}
          </label>
        </div>
        <div className="col-xs-4">
          <label role="button">
            <input
              name="fileType"
              type="radio"
              value="jpg"
              checked={this.state.filter.fileType === 'jpg'}
              onChange={this.handleChangeInput.bind(this, 'fileType')}
            />
            &nbsp; JPG
          </label>
        </div>
        <div className="col-xs-4">
          <label role="button">
            <input
              name="fileType"
              type="radio"
              value="png"
              checked={this.state.filter.fileType === 'png'}
              onChange={this.handleChangeInput.bind(this, 'fileType')}
            />
            &nbsp; PNG
          </label>
        </div>
        <div className="col-xs-4" style={{ paddingRight: '0' }}>
          <label role="button">
            <input
              name="fileType"
              type="radio"
              value="ai"
              checked={this.state.filter.fileType === 'ai'}
              onChange={this.handleChangeInput.bind(this, 'fileType')}
            />
            &nbsp; AI/EPS
          </label>
        </div>

        <div className="col-xs-4">
          <label role="button">
            <input
              name="fileType"
              type="radio"
              value="svg"
              checked={this.state.filter.fileType === 'svg'}
              onChange={this.handleChangeInput.bind(this, 'fileType')}
            />
            &nbsp; SVG
          </label>
        </div>
      </div>
    );
  }
  renderIconSetting = () => {
    return (
      <Dropdown.Toggle bsStyle="link" bsSize="lg">
        <FontAwesomeIcon icon={faCog} style={{ width: '1em' }} />
      </Dropdown.Toggle>
    );
  };
  render() {
    const { t, lng } = this.props;
    return (
      <div>
        <div className="col-sm-6 form-group" style={{ padding: 0 }}>
          <form
            onSubmit={this.handleSubmit.bind(this)}
            className="input-group-keyword"
          >
            <input
              type="text"
              style={{ height: 'auto' }}
              className="input-keyword"
              value={this.state.filter.keyword}
              placeholder={this.props.placeholder || t('Property name')}
              onChange={this.handleChangeKeyword.bind(this)}
            />
            <div
              className="icon-clear-keyword-container"
              onClick={(e) => {
                this.setState({
                  filter: Object.assign({}, this.state.filter, { keyword: '' }),
                });
                this.handleSubmit(e, 'reset');
              }}
            >
              <CircleCloseIcon />
            </div>
            <span className="input-group-btn">
              <button
                className={`btn btn-ac-primary btn-${config.serviceType}`}
                type="submit"
                style={{ padding: 6 }}
              >
                <FontIcomoonIcon icon="icon-search" />
                &nbsp;
              </button>
            </span>
          </form>
        </div>
        <div className="col-sm-6 text-right form-group">
          <DropdownMenu
            className="config"
            pullRight
            id="control-number-filter"
            header={this.renderIconSetting()}
            position="bottom"
          >
            <div
              style={{ padding: '10px', minWidth: this.props.minWidth }}
              className="menu-list"
            >
              <ul className="list-unstyled">
                <li className="row">
                  <div className="col-xs-4">{t('sort_by_page')}</div>
                  <div className="col-xs-8">
                    <label role="button">
                      <input
                        name="sort_by_page"
                        type="radio"
                        value="createdAt.desc"
                        checked={
                          this.state.filter.sortByPage === 'createdAt.desc'
                        }
                        onChange={this.handleChangeInput.bind(
                          this,
                          'sortByPage',
                        )}
                      />
                      &nbsp;
                      {t('Sort by newest registration')}
                    </label>
                    <br />
                    <label role="button">
                      <input
                        name="sort_by_page"
                        type="radio"
                        value="createdAt.asc"
                        checked={
                          this.state.filter.sortByPage === 'createdAt.asc'
                        }
                        onChange={this.handleChangeInput.bind(
                          this,
                          'sortByPage',
                        )}
                      />
                      &nbsp;
                      {t('Old order of registration')}
                    </label>
                  </div>
                </li>
                <li className="row">
                  <div className="col-xs-4">{t('filter_per_page')}</div>
                  <div className="col-xs-8">
                    <div className="row">
                      <div className="col-xs-4">
                        <label role="button">
                          <input
                            name="itemPerPage"
                            type="radio"
                            value={10}
                            checked={this.state.filter.itemPerPage == 10}
                            onChange={this.handleChangeInput.bind(
                              this,
                              'itemPerPage',
                            )}
                          />
                          &nbsp; 10
                        </label>
                      </div>
                      <div className="col-xs-4">
                        <label role="button">
                          <input
                            name="itemPerPage"
                            type="radio"
                            value={50}
                            checked={this.state.filter.itemPerPage == 50}
                            onChange={this.handleChangeInput.bind(
                              this,
                              'itemPerPage',
                            )}
                          />
                          &nbsp; 50
                        </label>
                      </div>
                      <div className="col-xs-4">
                        <label role="button">
                          <input
                            name="itemPerPage"
                            type="radio"
                            value={100}
                            checked={this.state.filter.itemPerPage == 100}
                            onChange={this.handleChangeInput.bind(
                              this,
                              'itemPerPage',
                            )}
                          />
                          &nbsp; 100
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="row">
                  <div className="col-xs-4">{t('File type')}</div>
                  <div className="col-xs-8">{this.renderFileType()}</div>
                </li>
                <li className="row">
                  <div className="col-xs-12">
                    <label
                      className="filter-normal-label"
                      htmlFor="hasExtraLicense"
                    >
                      <input
                        className="input-extra-license"
                        id="hasExtraLicense"
                        name="hasExtraLicense"
                        type="checkbox"
                        checked={this.state.filter.hasExtraLicense}
                        onChange={this.handleChangeInput.bind(
                          this,
                          'hasExtraLicense',
                        )}
                      />
                      {t('only_extra_license')}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </DropdownMenu>
          <div style={{ float: 'right' }}>
            <Pagination
              currentPage={this.props.currentPage}
              totalPage={this.props.totalPage}
              query={{ page: this.props.query.page }}
              path="/my-page/download-history"
              asPath="/my-page/download-history"
            />
          </div>
        </div>
        <div className="clear-fix" />
        <style jsx>
          {`
            .input-group {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: nowrap;
            }
            .input-group-keyword {
              display: flex;
              border: 1px solid black;
            }
            .input-keyword {
              flex-grow: 1;
              border: none;
            }
            .input-group-btn {
              width: 30px;
              height: 100%;
            }
            .icon-clear-keyword-container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              cursor: pointer;
            }
          `}
        </style>
      </div>
    );
  }
}

FilterData.defaultProps = {
  minWidth: '380px',
  placeholder: '',
  triggerEventChangeFilter: () => ({}),
  preNextEvent: false,
};
FilterData.propTypes = {
  minWidth: PropTypes.string,
  triggerEventChangeFilter: PropTypes.func,
};
export default FilterData;
