import axios, { addInterceptors } from '../interceptor-axios';
import config from '~/config';

export default class ApiUser {
  constructor(token) {
    const headers = {};
    if (token) {
      headers.Authorization = token;
    }
    this.axios = axios.create({
      baseURL: config.apiUrl.apiUser,
      headers,
    });
    addInterceptors(this.axios);
  }

  getProfile(lang, token) {
    const request = {
      method: 'GET',
      url: '/profile',
      params: {
        lang,
        token,
      },
    };
    return this.axios(request);
  }

  getSubscriptionHistory = (params) => {
    const request = {
      method: 'GET',
      url: '/profile/subscription',
      params: {
        limit: params.limit || 5,
        lastInvoiceId: params.lastInvoiceId,
      },
    };
    return this.axios(request);
  };

  getCardInformation = (params) => {
    const request = {
      method: 'GET',
      url: '/profile/card',
      params: {},
    };
    return this.axios(request);
  };

  getExtraLicensePaymentHistory = (params) => {
    const request = {
      method: 'GET',
      url: '/profile/extra_license_subscription',
      params: {
        lastReceiptId: params.lastReceiptId,
      },
    };
    return this.axios(request);
  };

  getCreatorPublicProfile = (params) => {
    const request = {
      method: 'GET',
      url: '/creator/public-profile',
      params: {
        creator_id: params.creator_id,
      },
    };

    return this.axios(request);
  };

  getCreators = (params) => {
    const request = {
      method: 'GET',
      url: '/creators',
      params: {
        page: params.page || 1,
        max_results: params.max_results || 100,
        clientIp: params.clientIp,
        order_by: 'downloaded',
      },
    };

    return this.axios(request);
  };
}
