import axios from 'axios';
import config from '~/config';

class SSO {
  constructor() {
    this.axios = axios.create({
      baseURL: config.ssoServerUrl,
    });
  }

  verifyToken(ssoToken) {
    const request = {
      method: 'GET',
      url: '/verifytoken',
      params: {
        ssoToken,
      },
      headers: {
        Authorization: `Bearer ${config.ssoAppToken}`,
      },
    };
    return this.axios(request);
  }

  verifyUser(data) {
    const request = {
      method: 'POST',
      url: `${config.apiUrl.apiUser}/sso/downloader/user/verify`,
      data: {
        ...data,
        service_type: config.serviceType,
      },
    };
    return this.axios(request);
  }

  getUserData(sessionId) {
    const request = {
      method: 'GET',
      url: `/api/user-info/${sessionId}`,
    };
    return this.axios(request);
  }

  getSessionUser(sessionId) {
    const request = {
      method: 'GET',
      url: '/get-session-user',
      params: {
        globalSessionToken: sessionId,
      },
    };
    return this.axios(request);
  }
}

export default new SSO();
