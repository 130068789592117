import {
  LOAD_DATA_COLLECTION_BY_ID,
  LOAD_COLLECTION_DETAIL_SUCCESS,
  LOAD_COLLECTION_DETAIL_ERROR,
  LOAD_COLLECTIONS_OF_MONTHS_SUCCESS,
  LOAD_COLLECTIONS_OF_MONTHS_ERROR,
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
} from './constants';
export const initialState = {
  photos: [],
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
  collectionDetail: {},
  max_results: 0,
  slug: {},
  query: [],
  similarData: {},
  collections_months: [],
};

function loadDataCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DATA_COLLECTION_BY_ID:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_COLLECTION_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        collectionDetail: action.collectionDetail[0],
        photos: action.collectionData.collection,
        total_page: action.collectionData.total_page,
        totalItem: action.collectionData.total_item,
        has_next: action.collectionData.has_next,
        max_results: action.collectionData.max_results,
        page: action.collectionData.page,
        slug: action.collectionDetail[0]?.slugFull,
        query: action.query,
        similarData: action.collectionDetail[1],
      };
    }
    case LOAD_COLLECTION_DETAIL_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case LOAD_COLLECTIONS_OF_MONTHS_SUCCESS: {
      return {
        ...state,
        collections_months: action.collections,
      };
    }

    case LOAD_COLLECTIONS_OF_MONTHS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case NICE_PHOTO_SUCCESS: {
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
      };
    }

    case REMOVE_NICE_PHOTO_SUCCESS: {
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id)
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          return item;
        }),
      };
    }
    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}

export default loadDataCategoryReducer;
