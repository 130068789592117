import { faCamera, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'clsx';
import React from 'react';
import DropdownBottom from '~/components/DropdownBottom';
import I18nLink from '~/components/I18nLink';
import Config from '~/config';
import { Avatar } from '~/lib';
import themeVariables from '~/shared/themeVariables';
import { urls } from '~/utils';
import { USER_ROLES } from '~/utils/constants';
import IconCrown from '~/components/svg/icon-crown.svg';
import FontIcomoonIcon from '~/components/FontIcomoonIcon';
import Constant from '~/../hocs/constant';

class TabletView extends React.Component {
  state = {
    statusDropdownMenu: false,
  };

  renderUserIcon = () => {
    const { profile } = this.props;
    if (profile.role === USER_ROLES.PREMIUM) {
      return (
        <IconCrown
          style={{ height: 16, marginRight: themeVariables.space.small }}
        />
      );
    }
    if (profile.role === USER_ROLES.CREATOR) {
      return (
        <FontAwesomeIcon
          size="lg"
          style={{ width: '1em', marginRight: themeVariables.space.small }}
          icon={faCamera}
        />
      );
    }
    return null;
  };

  renderNickname = () => {
    const { profile } = this.props;
    return (
      <div className="nickname-container">
        {/* {this.renderUserIcon()} */}
        {/* {profile.email || profile.nickname || ''} */}
        <Avatar
          style={{ marginLeft: themeVariables.space.extraSmall }}
          src={urls.renderAvatarUrl(profile.avatar)}
          width={32}
          height={32}
        />
        <span className="icon-dropdown-container">
          <img
            className={`icon-dropdown ${this.state.statusDropdownMenu}`}
            src={`${Config.staticPrefix}/static/assets/svg/chevron-up.svg`}
            alt="icon chevron up"
            width={16}
            height={12}
          />
        </span>
        {/* <style jsx>
          {`
            .nickname-container {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              color: ${themeVariables.color.dark};
            }
            .icon-dropdown-container {
              margin-left: 12px;
            }
            .icon-dropdown {
              transform: rotate(180deg);
            }
            .icon-dropdown.true {
              transform: rotate(0deg);
            }
          `}
        </style> */}
      </div>
    );
  };

  renderMenuItem = (menuItem) => {
    const { t, lng, profile } = this.props;
    if (menuItem.routeName === 'profile') {
      return (
        <a
          key={menuItem.routeName}
          className="menu-item"
          href={`${menuItem.path}?lang=${lng}&serviceURL=${encodeURIComponent(
            process.browser ? window.location.href : '',
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          {menuItem.icon && (
            <FontIcomoonIcon
              style={{
                fontSize: 22,
                color: themeVariables.color.primary,
                marginRight: themeVariables.space.small,
              }}
              icon={menuItem.icon}
              fixedWidth
            />
          )}
          {t(menuItem.label)}
          <style jsx>
            {`
              .menu-item {
                padding: 0 16px;
                color: ${themeVariables.color.dark};
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                height: 44px;
                text-transform: uppercase;
              }
              .menu-item:hover {
                background: ${themeVariables.color.gray};
              }
            `}
          </style>
        </a>
      );
    } else {
      return (
        <div key={menuItem.routeName}>
          <I18nLink href={menuItem.path}>
            <a className={cn('menu-item', { disabled: menuItem.lock })}>
              {menuItem.icon && (
                <FontIcomoonIcon
                  style={{
                    fontSize: 22,
                    color: themeVariables.color.primary,
                    marginRight: themeVariables.space.small,
                  }}
                  icon={menuItem.icon}
                  fixedWidth
                />
              )}
              {/* <div className="item-title">{t(menuItem.label)}</div> */}
              {t(menuItem.label)}
              {menuItem.lock && (
                <FontAwesomeIcon
                  icon={faLock}
                  style={{ width: '1em', color: themeVariables.color.primary }}
                />
              )}
            </a>
          </I18nLink>
          <style jsx>
            {`
              .menu-item {
                padding: 0 16px;
                color: ${themeVariables.color.dark};
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                height: 44px;
                text-transform: uppercase;
              }
              .menu-item:hover {
                background: ${themeVariables.color.gray};
              }
              .menu-item.disabled {
                pointer-events: none;
                cursor: default;
                color: #ccc;
              }

              .item-title {
                flex: 1;
              }
            `}
          </style>
        </div>
      );
    }
  };

  renderMembership = () => {
    const { t, profile } = this.props;

    let membership = null;
    if (profile.role === USER_ROLES.CREATOR) {
      membership = t('creator_user');
    } else if (profile.role === USER_ROLES.FREE_MEMBER) {
      membership = t('free_user');
    } else if (profile.role === USER_ROLES.PREMIUM) {
      if (profile.plan === 'MONTHLY') {
        membership = t('premium_user_monthly');
      } else if (profile.plan === 'ONE_YEAR') {
        membership = t('premium_user_yearly');
      } else {
        membership = t('premium_user');
      }
    }

    return (
      <div className="membership">
        {this.renderUserIcon()}
        {membership}
        <style jsx>
          {`
            .membership {
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              height: 32px;
            }
          `}
        </style>
      </div>
    );
  };

  renderMenu = () => {
    if (!process.browser) return null;

    const { t, menu, profile, logout, lng } = this.props;
    let dashboardLink = Constant.routersPathName.NOTIFICATION.path;
    if (profile.role === USER_ROLES.CREATOR) {
      dashboardLink = Constant.routersPathName.PHOTO_LIST.path;
    }

    return (
      <div className="menu-user">
        <div className="header-menu-user-container">
          <div className="left-menu-user">
            <Avatar
              style={{ marginLeft: themeVariables.space.extraSmall }}
              src={urls.renderAvatarUrl(profile.avatar)}
              width={56}
              height={56}
            />
          </div>
          <div className="right-menu-user">
            <div className="username">
              {profile.email || profile.nickname || ''}
            </div>
            <div className="membership-container">
              {this.renderMembership()}
            </div>
          </div>
        </div>

        <div className="menu-item dashboard">
          <I18nLink href={dashboardLink}>
            <a className="dashboard-link">
              {t('go_to_dashboard')}
              <img
                src={`${Config.staticPrefix}/static/assets/svg/next-page.svg`}
                className="icon-premium"
                alt="icon dashboard"
              />
            </a>
          </I18nLink>
        </div>
        {profile.role === USER_ROLES.FREE_MEMBER && (
          <I18nLink href="/premium">
            <a className="menu-item">
              <IconCrown
                style={{ height: 16, marginRight: themeVariables.space.small }}
              />
              {t('upgrade_now')}
            </a>
          </I18nLink>
        )}
        {menu.map((item) => this.renderMenuItem(item))}

        <a className="menu-item" onClick={logout}>
          {t('Logout')}
        </a>
        <style jsx>
          {`
            .header-menu-user-container {
              display: flex;
              padding: 0 16px;
              gap: 32px;
              height: 80px;
              border-bottom: 1px solid #051019;
              margin-bottom: 12px;
            }
            .left-menu-user {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .right-menu-user {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              padding: 4px 0;
            }
            .menu-user {
              display: flex;
              flex-direction: column;
              min-width: 300px;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            .username {
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              height: 32px;
            }

            .menu-item {
              padding: 0 16px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              height: 44px;
              text-transform: uppercase;
            }
            .menu-item:hover {
              background: ${themeVariables.color.gray};
            }
            .menu-item.dashboard:hover {
              background: none;
            }

            .icon-premium {
              height: 16px;
            }
            .dashboard-link {
              background: #1b2833;
              border-radius: 40px;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px;
              color: #f8f9fa;
            }
            .dashboard-link:hover {
              background: ${themeVariables.color.primary};
            }
          `}
        </style>
      </div>
    );
  };

  render() {
    return (
      <div
        className="tablet-view"
        onMouseEnter={() => this.setState({ statusDropdownMenu: true })}
        onMouseLeave={() => this.setState({ statusDropdownMenu: false })}
      >
        <DropdownBottom
          style={{ height: '100%', width: '100px' }}
          noArrow
          align="right"
          header={this.renderNickname()}
        >
          {this.renderMenu()}
        </DropdownBottom>
        <style jsx>
          {`
            .tablet-view {
              display: none;
            }

            @media (min-width: 768px) {
              .tablet-view {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .tablet-view .dropdown-bottom .dropdown-bottom-menu {
              left: -275px !important;
              right: -16px;
              top: unset;
            }
          `}
        </style>
        <style jsx global>
          {`
            .nickname-container {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              color: ${themeVariables.color.dark};
              display: flex;
              justify-content: flex-end;
            }
            .icon-dropdown-container {
              margin-left: 12px;
            }
            .icon-dropdown {
              transform: rotate(180deg);
            }
            .icon-dropdown.true {
              transform: rotate(0deg);
            }
          `}
        </style>
      </div>
    );
  }
}

export default TabletView;
