import { combineReducers } from 'redux';
import {
  LOAD_DATA_EXTRA_LICENSE,
  LOAD_DATA_EXTRA_LICENSE_SUCCESS,
  LOAD_DATA_EXTRA_LICENSE_ERROR,
} from './constants';

export const initialState = {
  extraLicenseData: {},
  error: false,
  loading: false,
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
};

function loadDataExtraLicense(state = initialState, action) {
  switch (action.type) {
    case LOAD_DATA_EXTRA_LICENSE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_DATA_EXTRA_LICENSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        extraLicenseData: action.dataExtraLicense,
      };
    }
    case LOAD_DATA_EXTRA_LICENSE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export default loadDataExtraLicense;
