import { connect } from 'react-redux';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import constant from './constant';
import config from '~/config';
import { makeSelectCurrentLanguage } from '~/containers/Language/selectors';
import { SERVICE_TYPES } from '~/utils/constants';

const mapStateToProps = createStructuredSelector({
  currentLanguage: makeSelectCurrentLanguage(),
});

const creatorLanguage = (screens = config.creatorAvailableServices) => (
  WrappedComponent,
) => {
  @connect(mapStateToProps)
  class Component extends React.PureComponent {
    checkIsShow = (language) => {
      const { currentLanguage } = this.props;
      return (
        screens.includes(config.serviceType) &&
        config.creatorAvailableLanguages.includes(currentLanguage || language)
      );
    };

    informShow = (language) =>
      config.creatorAvailableLanguages.includes(language);

    render() {
      return (
        <WrappedComponent
          {...this.props}
          checkIsShow={this.checkIsShow}
          informShow={this.informShow}
        />
      );
    }
  }
  return Component;
};

export { creatorLanguage };
