export const LOAD_RECOMMEND_DATA =
  'containers/RecommendPage/LOAD_RECOMMEND_DATA';
export const LOAD_RECOMMEND_DATA_SUCCESS =
  'containers/RecommendPage/LOAD_RECOMMEND_DATA_SUCCESS';
export const LOAD_RECOMMEND_DATA_ERROR =
  'containers/RecommendPage/LOAD_RECOMMEND_DATA_ERROR';

export const FILTER_PER_PAGE = 'containers/RecommendPage/FILTER_PER_PAGE';
export const CHANGE_PAGE = 'containers/RecommendPage/CHANGE_PAGE';

export const LOAD_RECOMMEND_DATA_BY_SEARCH_URL =
  'containers/RecommendPage/LOAD_RECOMMEND_DATA_BY_SEARCH_URL';
export const NICE_PHOTO = 'containers/RecommendPage/NICE_PHOTO';
export const NICE_PHOTO_SUCCESS = 'containers/RecommendPage/NICE_PHOTO_SUCCESS';
export const REMOVE_NICE_PHOTO_SUCCESS =
  'containers/RecommendPage/REMOVE_NICE_PHOTO_SUCCESS';
export const NICE_PHOTO_ERROR = 'containers/RecommendPage/NICE_PHOTO_ERROR';
