import FileSaver from 'file-saver';
import axios from '../interceptor-axios';
import config from '~/config';
import Cookies from '~/services/Cookies';

function getListPresentGift(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiDashboard}/present-gift`,
    params: {
      // lang: params.language || config.language,
      service_type: config.serviceType,
      token: params,
    },
  };
  return axios(request);
}
function downloadPresentGift(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiDashboard}/present-gift/download-zip`,
    params: {
      // lang: params.language || config.language,
      service_type: config.serviceType,
      key_file: params.file_key,
    },
  };
  return axios(request);
}
export { getListPresentGift, downloadPresentGift };
