function getUserAgent(context) {
  if (context && context.isServer) {
    return context.req.headers['user-agent'];
  }
  return navigator.userAgent || navigator.vendor || window.opera;
}

// Copy from https://makandracards.com/makandra/53475-minimal-javascript-function-to-detect-version-of-internet-explorer-or-edge
function isEdgeOrIE(context) {
  const userAgent = getUserAgent(context);
  const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(userAgent);
  return !!match;
}

function isTabletOrDesktop() {
  const result = process.browser && window && window.innerWidth > 480;
  return result;
}

function isMacOS(context) {
  const userAgent = getUserAgent(context);
  if (userAgent && userAgent.indexOf('Mac') !== -1) return true;
  return false;
}

function copyToClipboard(value) {
  const inp = document.createElement('input');
  inp.style = 'position:absolute;left:-9999px;top:-9999px';
  inp.value = value;
  document.body.appendChild(inp);
  inp.select();
  document.execCommand('copy');
  document.body.removeChild(inp);
}

export default {
  isEdgeOrIE,
  isMacOS,
  copyToClipboard,
  isTabletOrDesktop,
};
