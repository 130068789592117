import ApiData from './api-data';
import ApiUser from './api-user';
import ApiSearch from './api-search';
import ApiPhoto from './api-photo';

export default function createAPI(token) {
  return {
    data: new ApiData(token),
    user: new ApiUser(token),
    search: new ApiSearch(token),
    photo: new ApiPhoto(token),
  };
}
