import { createSelector } from 'reselect';

const selectSearch = (state) => state.search;

const selectMetadata = (state) => state.metadata;

const makeSelectKeyword = () =>
  createSelector(selectSearch, (searchState) => searchState.keyword);

const makeSelectExcludeKeyword = () =>
  createSelector(selectSearch, (searchState) => searchState.excludeKeyword);

const makeSelectCategory = () =>
  createSelector(selectSearch, (searchState) => searchState.category);

const makeSelectExcludeCategory = () =>
  createSelector(selectSearch, (searchState) => searchState.excludeCategory);

const makeSelectCategories = () =>
  createSelector(selectMetadata, (metadataState) =>
    metadataState.categories.map((item) => ({
      value: item.id,
      label: item.category_name,
    })),
  );

const makeSelectImageType = () =>
  createSelector(selectSearch, (searchState) => searchState.imageType);
const makeSelectOrientation = () =>
  createSelector(selectSearch, (searchState) => searchState.orientation);

const makeSelectOrderBy = () =>
  createSelector(selectSearch, (searchState) => searchState.orderBy);

const makeSelectShape = () =>
  createSelector(selectSearch, (searchState) => searchState.shape);

const makeSelectImageId = () =>
  createSelector(selectSearch, (searchState) => searchState.imageId);

const makeSelectImageTitle = () =>
  createSelector(selectSearch, (searchState) => searchState.imageTitle);

const makeSelectModelRelease = () =>
  createSelector(selectSearch, (searchState) => searchState.modelRelease);

const makeSelectToggleFilterSearch = () =>
  createSelector(selectSearch, (searchState) => searchState.toggleFilterSearch);

export {
  selectSearch,
  makeSelectKeyword,
  makeSelectExcludeKeyword,
  makeSelectCategory,
  makeSelectExcludeCategory,
  makeSelectCategories,
  makeSelectImageType,
  makeSelectOrderBy,
  makeSelectShape,
  makeSelectImageId,
  makeSelectImageTitle,
  makeSelectOrientation,
  makeSelectModelRelease,
  makeSelectToggleFilterSearch,
};
