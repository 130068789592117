import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Router from 'next/router';
import {
  makeSelectModal,
  makeSelectDescription,
  makeSelectTitle,
  makeSelectSwitchAccount,
} from './selectors';
import { closeModal } from './actions';
import { logout } from '../AuthProvider/actions';
import ErrorMessageModal from '~/components/ErrorMessageModal';
import I18nRouter from '~/components/I18nRouter';

class ErrorMessageModalContainer extends React.Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    const {
      modal,
      title,
      description,
      switchAccount,
      onLogout,
      language,
    } = this.props;

    return (
      <ErrorMessageModal
        handleClose={this.handleClose}
        modal={modal}
        title={title}
        switchAccount={switchAccount}
        description={description}
        onLogout={() => onLogout(language)}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modal: makeSelectModal(),
  title: makeSelectTitle(),
  description: makeSelectDescription(),
  switchAccount: makeSelectSwitchAccount(),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  onLogout: () => {
    dispatch(logout());
    dispatch(closeModal());
    I18nRouter.push({
      pathname: '/login',
    });
  },
});

const Connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorMessageModalContainer);

export default Connector;
