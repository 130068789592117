import React, { useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Router from 'next/router';
import Radio from '~/components/Radio';
import { updateContinueRenew, updateShowPopup } from '~/services/auth';

function StopPremiumModal({ premiumCheck, updateCheckPremiumUser }) {
  const [onShow, setOnShow] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  console.log('premiumCheckpremiumCheck', premiumCheck);

  const onSave = async () => {
    if (premiumCheck.isExpired) {
      updateCheckPremiumUser({
        ...premiumCheck,
        isShowPopup: false,
      });
      await updateShowPopup(0);
      Router.push('/premium');
    } else {
      await updateContinueRenew(1);
      await updateShowPopup(0);
      updateCheckPremiumUser({
        ...premiumCheck,
        isShowPopup: false,
        isContinueRenew: true,
      });
      console.log('vo day ne!!');
    }

    setOnShow(false);
  };

  const reject = async () => {
    if (showPopup) {
      await updateShowPopup(0);
      updateCheckPremiumUser({
        ...premiumCheck,
        isShowPopup: false,
      });
    }

    setOnShow(false);
  };

  return (
    <Modal show={onShow}>
      <Modal.Body>
        <div className="text-survey">
          {premiumCheck.isExpired
            ? 'Your subscription has been canceled. Would you like to continue with the Premium plan?'
            : 'Your subscription’s auto-renewal is currently paused. Would you like to continue auto-renewal ?'}
        </div>
        <div className="text-warn">
          <Radio
            checked={showPopup}
            onChange={() => setShowPopup(!showPopup)}
          />{' '}
          I understand and do not want to show this modal again!
        </div>
        <div className="action-container">
          <div className="button-cancel" onClick={reject}>
            No, I don't
          </div>
          <div className="button-survey" onClick={onSave}>
            Yes, I do
          </div>
        </div>
        <style jsx>
          {`
            .text-survey {
              margin-top: 24px;
              color: #1b2833;
              text-align: center;
            }
            .text-warn {
              margin-top: 12px;
              color: red;
              text-align: center;
            }
            .action-container {
              display: flex;
              justify-content: center;
              margin: 24px 0;
            }
            .button-cancel {
              padding: 8px 16px;
              background: #c4c4c4;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              cursor: pointer;
              color: #ffffff;
              margin-right: 16px;
            }
            .button-survey {
              padding: 8px 16px;
              background: #31d0aa;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              cursor: pointer;
              color: #ffffff;
              gap: 4px;
            }

            .button-cancel:hover,
            .button-survey:hover {
              opacity: 0.8;
            }
          `}
        </style>
      </Modal.Body>
    </Modal>
  );
}

export default StopPremiumModal;
