import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SEARCH_PHOTO,
  SEARCH_PHOTO_ERROR,
  SEARCH_PHOTO_SUCCESS,
} from './constants';
import * as searchPageService from '~/services/search-page';
import Config from '~/config';

function* searchPhoto(action) {
  const { payload, api } = action;

  try {
    const apiFunction = api
      ? api.search.searchData
      : searchPageService.searchData;
    let {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      suggestList,
    } = yield call(apiFunction, payload);
    const pageInt = parseInt(page);
    collection = collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      return item;
    });
    yield put({
      type: SEARCH_PHOTO_SUCCESS,
      collection,
      suggest: suggestList,
      has_next,
      page: pageInt,
      total_page,
      total_item,
    });
  } catch (error) {
    let status;
    let { message } = error;
    if (error.response) {
      status = error.response.status;
      message = error.response.data.message;
    }
    yield put({ type: SEARCH_PHOTO_ERROR, error: { status, message } });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* tagDetailSaga() {
  yield takeLatest(SEARCH_PHOTO, searchPhoto);
}
