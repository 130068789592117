import axios from './interceptor-axios';
import config from '~/config';

export const unsubscribeNewsletter = ({ email }) => {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiNotice}/unsubscribe/newsletter`,
    data: { email },
  };
  return axios(request);
};
