import React from 'react';
import Config from '~/config';

const NotFound = () => (
  <div style={{ paddingTop: 150, paddingBottom: 200 }}>
    <img
      className="responsive-image"
      src={`${Config.staticPrefix}/static/images/errors/404.png`}
      alt="logo error"
    />
    <h2>The page you are looking for can not be found.</h2>
    <div>
      The page you are looking for might be temporarily unavailable, or it may
      have been changed or deleted.
    </div>
  </div>
);

export default NotFound;
