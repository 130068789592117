import { UPDATE_CURRENT_LANGUAGE } from './action';

const languageReducer = (state = { currentLanguage: 'en' }, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_LANGUAGE:
      return { ...state, currentLanguage: action.language };
    default:
      return state;
  }
};

export default languageReducer;
