/* eslint-disable */
import React from 'react';
import Config from '~/config';

class GoogleAds extends React.Component {
  componentDidMount() {
    if (Config.isChina()) {
      return;
    }
    if (process.browser && googletag) {
      const { id } = this.props;
      googletag.cmd.push(() => {
        let leaderboard_mapping = googletag
          .sizeMapping()
          .addSize([320, 50], [[320, 50], [320, 100]])
          .addSize([468, 60], [[320, 50], [320, 100], [468, 60]])
          .addSize([728, 90], [[468, 60], [728, 90]])
          .addSize([980, 250], [[728, 90], [970, 90], [970, 250]])
          .addSize([1280, 250], [[728, 90], [970, 90], [970, 250]])
          .build();
        googletag
          .defineSlot(
            '/17939840/globalGrid728',
            [[320, 100], [970, 250], [320, 50], [468, 60], [970, 90], [728, 90]],
            id,
          )
          .defineSizeMapping(leaderboard_mapping)
          .addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });

      setTimeout(() => {
        googletag.cmd.push(() => {
          googletag.display(id);
        });
      }, 2000);
    }
  }

  render() {
    if (Config.isChina()) {
      return <div />;
    }
    const { id } = this.props;
    if (!process.browser) {
      return null;
    }
    return <div id={id} style={{ height: 'auto', margin: '7px 0px' }} />;
  }
}

export default GoogleAds;
