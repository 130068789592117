import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import withI18next from '~/../hocs/_withI18next';
import themeVariables from '~/shared/themeVariables';

@withI18next()
@withCookies
class NoticeCookie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNoticeVisible: false,
    };
  }

  componentDidMount() {
    this.setState({ isNoticeVisible: this.shouldShowHeader() });
  }

  handleClose = (e) => {
    e.preventDefault();
    this.props.cookies.set('hideNotice', true, {
      maxAge: 31536000, // for a year
    });
    this.setState({ isNoticeVisible: false });
  };

  shouldShowHeader() {
    if (process.browser) {
      return !this.props.cookies.get('hideNotice');
    }
    return false;
  }

  render() {
    const { t } = this.props;
    const { isNoticeVisible } = this.state;

    return (
      <div
        style={this.props.style}
        className={`text-center ${
          isNoticeVisible ? 'show' : 'hide'
        }-notice-cookie`}
      >
        <span
          className="text-left"
          dangerouslySetInnerHTML={{
            __html: t('notice_cookie_des', { link: '/privacy-policy' }),
          }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a className="cb-enable" onClick={this.handleClose}>
          OK
        </a>
        <style jsx global>
          {`
            .show-notice-cookie {
              min-height: 20px;
              z-index: 1001;
              background: ${themeVariables.colors.bodyText};
              opacity: 0.92;
              color: #fff;
              font-size: 14px;
              border-bottom: 1px solid #ddd;
              padding: 8px 16px;
              position: fixed;
              bottom: -1px;
              width: 100%;
            }

            .show-notice-cookie span a {
              display: inline-block;
              border-radius: 3px;
              text-decoration: none;
              padding: 0 6px;
              margin-left: 8px;
              color: #4c9ade;
            }

            .show-notice-cookie .cb-enable {
              text-align: center;
              background: #ddd;
              padding: 4px 16px;
              line-height: 1;
              color: #666;
              display: inline-block;
              border-radius: 3px;
              text-decoration: none;
              margin-left: 8px;
            }

            .hide-notice-cookie {
              display: none;
            }

            @media (max-width: 767px) {
              .show-notice-cookie {
                min-height: 48px;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cookie: state.noticeCookie,
});

const mapDispatchToProps = (dispatch) => ({
  // changeStatusCookie: (bool) => dispatch(changeStatusCookie(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeCookie);
