import debounce from 'lodash/debounce';
import forIn from 'lodash/forIn';
import Router from 'next/router';
import React from 'react';
import handleViewport from 'react-in-viewport';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import {
  loadDataType,
  loadRandomSliderImages,
  selectedPattern,
  setIsTopBannerVisible,
} from './actions';
import CategoriesList from './CategoriesList';
import ContentDaily from './ContentDaily';
import CuratedTopic from './CuratedTopic';
import CuratedTopicIllust from './CuratedTopicIllust';

import {
  makeSelectBlogs,
  makeSelectCollections,
  makeSelectDataType,
  makeSelectDataTypePattern,
  makeSelectPatterns,
  makeSelectPatternSelected,
  makeSelectRecommendList,
  makeSelectSliderImage,
  makeSelectTags,
  makeSelectTotalData,
} from './selectors';
import { logout } from '../AuthProvider/actions';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
  makeSelectToken,
} from '../AuthProvider/selectors';
import HeroSlider from '../HeroSlideShow/HeroSlider';
import { makeSelectCurrentLanguage } from '../Language/selectors';
import {
  makeSelectCategories,
  makeSelectCurrentLang,
  makeSelectIsLocaleInPath,
  makeSelectIsMacOS,
  makeSelectLink,
} from '../MetadataProvider/selectors';
import BannerDiscount from '~/components/BannerDiscount';
// import SEOTextACworks from '~/components/SEOTextACworks';
import BlogPost from '~/components/BlogPost';
import CollectionItem from '~/components/CollectionItem';
import Container from '~/components/Container';
import Gallery from '~/components/Gallery';
import GridGallery from '~/components/Gallery/GridGallery';
import I18nLink from '~/components/I18nLink';
import I18nRouter from '~/components/I18nRouter';
import LinkTo from '~/components/LinkTo';
import LoadingSpinner from '~/components/loading-spinner/LoadingSpinner';
import NextPageButtonCustom from '~/components/NextPageButtonCustom';
import Pattern from '~/components/Pattern';
import PopularSearch from '~/components/PopularSearch';
import SEOTextSub from '~/components/SEOTextSub';
import TagTrending from '~/components/TagTrending';
import Config, { keyConfig } from '~/config';
import { updateDataCountSurvey } from '~/containers/Layout/actions';
import { selectLayout } from '~/containers/Layout/selectors';
import NoticeCookie from '~/containers/NoticeCookie';
import { makeSelectIsCountdownActive } from '~/containers/PaymentModal/selectors';
import Search from '~/containers/Search';
import { searchPhoto } from '~/containers/SearchResultPage/actions';
import LocalStorage from '~/services/LocalStorage';
import {
  default as theme,
  default as themeVariables,
} from '~/shared/themeVariables';
import { formatNumberWithCommas } from '~/utils/formatter';

const ViewportHeroSlider = handleViewport(HeroSlider);
const roles = Config.getRoles();

class Home extends React.Component {
  static isUsingGridGallery(dataType) {
    return (
      dataType === Config.roles.POWERPOINT ||
      dataType === Config.roles.WEB_TEMPLATE ||
      dataType === Config.roles.ADSENSE
    );
  }

  state = { type: 'recommend', isTabletS: false };
  componentDidMount() {
    if (window.innerWidth <= themeVariables.breakpoints.tabletS.maxWidth) {
      this.setState({ isTabletS: true });
    } else {
      this.setState({ isTabletS: false });
    }
    window.addEventListener('resize', this.checkIsTabletS);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIsTabletS);
  }

  checkIsTabletS = debounce(() => {
    if (window.innerWidth <= themeVariables.breakpoints.tabletS.maxWidth) {
      this.setState({ isTabletS: true });
    } else {
      this.setState({ isTabletS: false });
    }
  }, 200);

  onSearchSubmit = (values) => {
    if (
      !values.keyword &&
      !values.category &&
      !values.imageTitle &&
      !values.imageId &&
      values.color === 'all' &&
      !values.creatorName
    ) {
      return toast.warn(this.props.t('search_message_not_input'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }

    values = { ...this.props.query, ...values };
    let asPath = 'page=1';
    forIn(values, (value, key) => {
      if (
        value &&
        key !== 'page' &&
        key !== 'is_tag' &&
        key !== 'clientIp' &&
        key !== 'keyword'
      ) {
        asPath += `&${key}=${value}`;
      }
    });

    LocalStorage.otherServices = null;

    let pageURL = `/search/${encodeURIComponent(values.keyword)}?${asPath}`;
    if (this.props.currentLang !== 'en' || this.props.isLocaleInPath) {
      pageURL = `/${this.props.currentLang}${pageURL}`;
    }
    // let ModelRelease = {};
    // if (values.modelRelease) {
    //   switch (values.modelRelease) {
    //     case '0':
    //       ModelRelease = { model: 2, property: 0 };
    //       break;
    //     case '1':
    //       ModelRelease = { model: 0, property: 2 };
    //       break;
    //     case '2':
    //       ModelRelease = { model: 0, property: 0 };
    //       break;
    //   }
    // }
    const { countSearch } = this.props.layout;
    this.props.updateDataCountSurvey({
      countSearch: countSearch + 1,
    });

    const { searchPhoto } = this.props;
    searchPhoto({
      ...values,
      page: 1,
      keyword: values.keyword,
      excludeKeyword: values.excludeKeyword,
      category: values.category,
      excludeCategory: values.excludeCategory,
      creatorName: values.creatorName,
      excludeCreatorName: values.excludeCreatorName,
      color: values.color || 'all',
      modelCount: values.modelCount,
      modelAge: values.modelAge,
      isFromSearchHeader: true,
      // fileType: values.imageType,
      imageType: values.imageType,
      imageId: values.imageId,
      orderBy: values.orderBy,
      shape: values.shape,
      title: values.imageTitle,
      modelRelease: values.modelRelease,
      image_token: null,
    });
    I18nRouter.push(
      {
        pathname: `/search/${encodeURIComponent(values.keyword)}`,
        locale: this.props.currentLang,
        query: {
          ...values,
          page: 1,
          keyword: values.keyword,
          excludeKeyword: values.excludeKeyword,
          category: values.category,
          excludeCategory: values.excludeCategory,
          creatorName: values.creatorName,
          excludeCreatorName: values.excludeCreatorName,
          color: values.color || 'all',
          modelCount: values.modelCount,
          modelAge: values.modelAge,
          isFromSearchHeader: true,
          // fileType: values.imageType,
          imageType: values.imageType,
          imageId: values.imageId,
          orderBy: values.orderBy,
          shape: values.shape,
          title: values.imageTitle,
          modelRelease: values.modelRelease,
          image_token: null,
        },
      },
      pageURL,
      { shallow: true },
    );
  };
  renderHeroContent = () => {
    const {
      t,
      lng,
      tags,
      // onHideSearchHeader,
      // onShowSearchHeader,
      totalData,
      isAuthenticated,
      profile,
    } = this.props;
    if (Config.isDesignAC()) {
      return (
        <div className="container home-slider-header">
          <div className="slider-section">
            <h1 className="title-slider">
              {t('design_title_form_search_top_page_seo')}
            </h1>
            <h2 className="description-title">
              {t('design_title2_form_search_top_page')}
            </h2>
          </div>
          <div className="container text-center">
            {isAuthenticated && profile.role !== Config.getRoles().PREMIUM && (
              <I18nLink href="/premium">
                <a
                  className={`btn btn-ac-primary btn-${Config.serviceType} btn-go-premium`}
                >
                  {t('go_premium')}
                </a>
              </I18nLink>
            )}
          </div>
          <style jsx>{`
            @media (max-width: ${themeVariables.breakpoints.mobile
                .maxWidth}px) {
              .title-slider {
                font-size: 24px;
              }
            }
          `}</style>
        </div>
      );
    }

    const numberData = totalData
      ? Math.floor(totalData[Config.serviceType] / 100) * 100
      : Config.totalData[Config.serviceType];

    return (
      <div className="container home-slider-header">
        <div className="slider-section">
          <h1 className="title-slider">
            {t(Config.getPrefixContent('title_form_search_top_page_seo'))}
          </h1>
          <h2
            className="description-title"
            dangerouslySetInnerHTML={{
              __html: t(
                Config.getPrefixContent('title2_form_search_top_page'),
                {
                  count: formatNumberWithCommas(numberData),
                },
              ),
            }}
          />
        </div>
        <div className="search-box">
          <Search
            inHeader
            onSubmit={this.onSearchSubmit}
            // hidden={!show}
            handleCheckToggleSearch={this.handleCheckToggleSearch}
          />
        </div>
        <PopularSearch tags={tags} lang={lng} />
        <div className="container text-center">
          {isAuthenticated && profile.role !== Config.getRoles().PREMIUM && (
            <I18nLink href="/premium">
              <a
                className={`btn btn-ac-primary btn-${Config.serviceType} btn-go-premium`}
              >
                {t('go_premium')}
              </a>
            </I18nLink>
          )}
        </div>
        <style jsx>{`
          @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
            .title-slider {
              font-size: 24px;
            }
          }
        `}</style>
      </div>
    );
  };

  handleSelectNav = (selectedKey) => {
    if (this.state.type === selectedKey) {
      return;
    }
    this.setState({ type: selectedKey });
    if (selectedKey === 'recommend') {
      return;
    }
    this.props.loadDataType({ eventType: selectedKey });
  };

  handleChangePattern = (id, lang) => {
    const { onChangePattern } = this.props;
    onChangePattern(id, lang);
    this.handleSelectNav('recommend');
  };

  renderCollection = (data) => {
    const { t } = this.props;
    const result = [...data].reverse();

    return (
      <Container
        style={{
          background: themeVariables.color.white,
          paddingTop: 32,
          paddingBottom: 32,
        }}
      >
        <div className="similar-collection-header">
          <p className="collection-similar-title">
            {t('header_collection_of_homepage')}
          </p>

          <LinkTo label={t('view_all')} href="/collections" />
        </div>
        <div className="container-collections">
          {result.map((ele) => {
            return <CollectionItem key={ele.id} data={ele} t={t} />;
          })}
        </div>
        <style>
          {`
              .container-collections {
                  display: grid;
                  grid-gap: 24px;
                  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                  margin-top:12px;
                }
              .collection-similar-title{
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 28px;
                  color: #1b2833;
              }
              .similar-collection-header{
                  display:flex;
                  flex-direction:row;
                  justify-content:space-between;
              }
              .sub-header-extra-license{
                display: flex;
                justify-content: space-between;
                margin-top: 12px;
              }
              .sub-header-extra-license-title{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                text-transform: uppercase;
              }
              .link-extra-license{
                color: #509bdc !important;
                text-decoration: none;
              }
              .link-extra-license:hover{
                color: #509bdc !important;
                opacity:0.9;
                text-decoration:underline !important;
              }
              @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
                .container-collections {
                  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                  justify-content: center;
                  grid-gap: 10px;
                }
              }
          `}
        </style>
      </Container>
    );
  };

  render() {
    const {
      categories,
      sliderImages,
      patterns,
      recommendList,
      patternSelected,
      profile,
      t,
      lng,
      dataTypePattern,
      currentLanguage,
      loading,
      isMobileOrTablet,
      isMacOS,
      api,
      dataType,
      setIsTopBannerVisible,
      isAuthenticated,
      collections,
      isLocaleInPath,
      isCountdownActive,
      blogs,
    } = this.props;
    const imageSize = Config.getFixedSizeByDataType(dataTypePattern);
    const showData = this.state.type === 'recommend' ? recommendList : dataType;
    const selectedPattern = patterns.find(
      (item) => item.pattern_id === patternSelected,
    );
    const isDiscountDisplayed =
      profile.role !== roles.PREMIUM &&
      profile.role !== roles.CREATOR &&
      isCountdownActive;
    return (
      <div className="home-containers">
        {/* <div className="count-down">
          <NoticeCookie /> */}
        {/* <PresentCountDown campaign /> */}
        {/* <HeaderCountdownDiscount /> */}
        {/* </div> */}
        <div className="hero-container">
          <ViewportHeroSlider
            sliderImages={sliderImages}
            onChangeVisibility={setIsTopBannerVisible}
            isDiscountDisplayed={isDiscountDisplayed}
            lng={lng}
            t={t}
          />
          {this.renderHeroContent()}
        </div>
        {isDiscountDisplayed && <BannerDiscount />}
        <CategoriesList
          t={t}
          categories={categories}
          isTabletS={this.state.isTabletS}
        />
        <ContentDaily t={t} isAuthenticated={isAuthenticated} lng={lng} />

        <div className="clearfix" />
        {Config.isDesignAC() && (
          <div className="title-pattern">
            <h2 style={{ fontSize: '20px' }}>
              {profile.role === Config.getRoles().PREMIUM
                ? t('design_title_pattern')
                : t('for_free_and_guest_title')}
            </h2>
          </div>
        )}

        <Container
          style={{ background: theme.color.gray }}
          contentStyle={{ paddingTop: 16, paddingBottom: 24 }}
        >
          <div className="recommend-header-container">
            <div className="title-recommend">
              {t('curated_topics')}
              {/* <div className="topic-name">{selectedPattern.name}</div> */}
            </div>
            {!Config.isPhotoAC() && !Config.isIllustAC() && (
              <div className="pattern-recommend">
                <Pattern
                  patterns={patterns}
                  patternSelected={patternSelected}
                  onChangePattern={this.handleChangePattern}
                  language={currentLanguage}
                />
              </div>
            )}
          </div>
          <div className="curated-topic-mobile-container">
            {!Config.isPhotoAC() &&
              !Config.isIllustAC() &&
              !loading &&
              showData &&
              !!showData.length && (
                <>
                  {Home.isUsingGridGallery(dataTypePattern) ? (
                    <GridGallery
                      images={showData}
                      currentLang={currentLanguage}
                      lastRowHide={false}
                      colWidth={imageSize.width}
                      rowHeight={imageSize.height}
                      showImageActions
                      api={api}
                    />
                  ) : (
                    <Gallery
                      images={showData}
                      currentLang={currentLanguage}
                      lastRowHide={false}
                      colWidth={imageSize.width}
                      rowHeight={imageSize.height}
                      showImageActions
                      api={api}
                    />
                  )}
                </>
              )}

            {!Config.isPhotoAC() && !Config.isIllustAC() && (
              <div className="section-loading">
                {loading && <LoadingSpinner />}
                {!loading &&
                  !Config.isDesignAC() &&
                  this.state.type === 'recommend' && (
                    <NextPageButtonCustom
                      pathname="/recommend-design"
                      query={{ pattern_id: patternSelected }}
                      as={`/${keyConfig.get(
                        'route_recommend_page',
                      )}?pattern_id=${patternSelected}`}
                      t={t}
                      type="see_more"
                    />
                  )}
                {!loading &&
                  !Config.isDesignAC() &&
                  this.state.type === 'popular' && (
                    <NextPageButtonCustom
                      pathname="/popular-design"
                      query={{ pattern_id: patternSelected }}
                      as={`/${keyConfig.get('route_popular_page')}`}
                      t={t}
                      type="see_more"
                    />
                  )}

                {!loading &&
                  !Config.isDesignAC() &&
                  this.state.type === 'newest' && (
                    <NextPageButtonCustom
                      pathname="/new-design"
                      query={{ pattern_id: patternSelected }}
                      as={`/${keyConfig.get('route_new_page')}`}
                      t={t}
                      type="see_more"
                    />
                  )}
              </div>
            )}
            {Config.isPhotoAC() && (
              <div className="curated-topic-container">
                <CuratedTopic data={patterns} lng={lng} />
              </div>
            )}
            {Config.isIllustAC() && (
              <div className="curated-topic-container">
                <CuratedTopicIllust
                  data={patterns}
                  lng={lng}
                  language={currentLanguage}
                />
              </div>
            )}
          </div>
        </Container>

        {!Config.isDesignAC() &&
          collections.length === 3 &&
          this.renderCollection(collections)}

        <TagTrending />
        {!isAuthenticated &&
          (Config.isIllustAC() || Config.isPhotoAC()) &&
          lng !== 'tw' &&
          lng !== 'ko' && <SEOTextSub t={t} lng={lng} />}
        {!Config.isDesignAC() && (
          <BlogPost lng={lng} t={t} blogs={this.props.blogs} />
        )}
        {/* <PremiumCriteriaAndNews t={t} lng={lng} /> */}
        {/* {!isAuthenticated && <SEOTextACworks />} */}
        <NoticeCookie />
        <style jsx>
          {`
            .home-containers {
              display: flex;
              flex-direction: column;
              margin-bottom: 64px;
            }

            .section-loading {
              display: flex;
              flex-direction: row;
              justify-content: center;
              margin-top: 24px;
            }
            .recommend-header-container {
              display: flex;
              justify-content: space-between;
              margin-bottom: 24px;
            }
            .title-recommend {
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              color: #1b2833;
            }
            .topic-name {
              font-size: 16px;
              margin-top: 16px;
            }
            .title-topic-container {
              display: flex;
              justify-content: space-between;
              margin-bottom: 24px;
            }
            .title-topic {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              color: #000000;
              text-transform: uppercase;
            }
            @media (max-width: ${themeVariables.breakpoints.mobile
                .maxWidth}px) {
              .title-recommend {
                font-size: 24px;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .home-containers .nav-and-pattern-container {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            .home-containers .hero-container {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              height: 468px;
            }

            .home-containers .home-slider-header {
              z-index: 100;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: #fff;
            }

            .home-containers .overlay-logo {
              position: absolute;
              top: ${themeVariables.space.half}px;
              left: ${themeVariables.space.base}px;
              background: transparent;
            }

            .home-containers .title-slider {
              font-size: 50px;
              text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
              font-weight: 600;
              padding: 0;
              margin: 0;
            }

            .home-containers .description-title {
              font-size: 24px;
              margin-top: 20px;
              text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
            }

            .home-containers .description-sub-title {
              font-size: 24px;
              margin-top: 10px;
              text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
            }

            .home-containers .home-search-form {
              max-width: 9200px;
              height: 65px;
              margin: 38px 0;
            }

            .home-containers .country-user-infor {
              font-size: 24px;
              text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
            }

            .home-containers .more-description {
              font-size: 16px;
            }

            .home-containers .title-thumbnail-categories {
              display: flex;
              justify-content: center;
              padding-bottom: 20px;
            }

            .home-containers .title-pattern {
              display: flex;
              justify-content: center;
              padding-top: 10px;
              padding-bottom: 20px;
            }

            .home-containers .slider-section {
              margin: 0 auto;
              // margin-top: 50px;
              text-align: center;
            }

            .home-containers .site-description {
              background: rgba(0, 0, 0, 0.7);
              border-radius: 5px;
              width: 70%;
              padding-top: 10px;
              padding-bottom: 10px;
            }
            .home-containers .site-description h4 {
              font-size: 1em;
            }

            .home-containers .bookmark-description {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 30px;
              font-weight: 500;
              color: #3e3e3f;
              padding: 28px;
            }
            .home-containers .bookmark-description .bookmark-key {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
              width: 100px;
              height: 50px;
              margin: 0 7px;
              background: #f2f3f4;
            }

            .home-containers .section-memberships {
              padding-top: 20px;
              padding-bottom: 15px;
            }
            .home-containers .section-memberships .table-free-premium {
              padding-top: 20px;
            }
            .home-containers .section-memberships .table-free-premium-header {
              display: flex;
              align-items: center;
              background-color: #3e3e40;
              color: #ffa823;
              font-size: 15px;
            }
            .home-containers
              .section-memberships
              .table-free-premium-header
              .header---row {
              padding: 12px;
              border-right: 1px solid #dcdcdd4f;
              font-weight: 500;
            }
            .home-containers
              .section-memberships
              .table-free-premium-header
              .header---row:last-child {
              border: none;
            }
            .home-containers .section-memberships .table-free-premium-item {
              display: flex;
              align-items: center;
              background-color: #eeeef0;
            }
            .home-containers
              .section-memberships
              .table-free-premium-item
              .body---row {
              padding: 6px 15px;
            }
            .home-containers .section-memberships .checkbox-premium {
              width: 25px;
              height: 25px;
              display: flex;
              border-radius: 50%;
              justify-content: center;
              align-items: center;
              color: #dcdcdc8a;
              background-color: #fff;
            }
            .home-containers .section-memberships .wrapper-home-price-free {
              display: flex;
              justify-content: center;
              width: 100%;
            }
            @media (max-width: 767px) {
              .home-containers .section-memberships .wrapper-home-price-free {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
              }
            }

            .home-containers .btn-go-premium {
              margin-top: 28px;
              padding-left: 28px;
              padding-right: 28px;
              font-size: 24px;
              font-weight: bold;
              ${
                Config.isIllustAC() && lng !== 'tw'
                  ? `
              background: ${themeVariables.color.dark} !important;
              color: ${themeVariables.color.white} !important; 
              border-radius: 40px;
              `
                  : ''
              }
            }

            @media (max-width: ${
              themeVariables.breakpoints.mobile.maxWidth
            }px) {
              .home-containers .nav-and-pattern-container {
                flex-direction: column-reverse;
              }

              .tabs-home {
                margin-top: 16px;
              }
              .home-containers .title-slider {
                font-size: 24px;
              }
              .home-containers .description-title {
                font-size: 16px;
              }
              .home-containers .title-trending-text{
                font-size: 24px;}
              }
              .home-containers .btn-go-premium {
                margin-top: 12px;
              }
            }

            @media (min-width: 1200px) {
              .home-containers .home-slider-header {
                width: 1200px;
              }
            }

            .home-form {
              text-align: center;
              margin-top: 50px;
            }

            .popular-tag {
              color: white;
            }

            .color-home-photo_ac {
              color: #4c9ade !important;
            }

            .color-home-illust_ac {
              color: #db394d !important;
            }

            .color-home-silhouette_ac {
              color: #3abdbd !important;
            }

            .color-home-design_ac {
              color: #ffa823 !important;
            }

            .tabs-home {
              min-width: 300px;
            }
            .tabs-home ul.tab-home-item li a {
              color: #1b2833;
              font-weight: 600;
              font-size: 14px;
            }
            .tabs-home ul.tab-home-item li a,
            .tabs-home ul.tab-home-item li a:hover,
            .tabs-home ul.tab-home-item li a:active,
            .tabs-home ul.tab-home-item li a:focus {
              border: none;
              background-color: transparent;
              border-bottom: 2px solid transparent;
            }
            .tabs-home ul.tab-home-item li {
              padding-bottom: 3px;
            }
            .tabs-home ul.tab-home-item li.active a,
            .tabs-home ul.tab-home-item li.active a:hover,
            .tabs-home ul.tab-home-item li.active a:active,
            .tabs-home ul.tab-home-item li.active a:focus {
              border: none;
              padding-bottom: 4px !important;
            }
            .tabs-home ul.tab-home-item.photo_ac li.active a,
            .tabs-home ul.tab-home-item.photo_ac li.active a:hover,
            .tabs-home ul.tab-home-item.photo_ac li.active a:active,
            .tabs-home ul.tab-home-item.photo_ac li.active a:focus {
              border-bottom: 2px solid #509bdc;
            }
            .tabs-home ul.tab-home-item.illust_ac li.active a,
            .tabs-home ul.tab-home-item.illust_ac li.active a:hover,
            .tabs-home ul.tab-home-item.illust_ac li.active a:active,
            .tabs-home ul.tab-home-item.illust_ac li.active a:focus {
              border-bottom: 2px solid #d73a4e;
            }
            .tabs-home ul.tab-home-item.silhouette_ac li.active a,
            .tabs-home ul.tab-home-item.silhouette_ac li.active a:hover,
            .tabs-home ul.tab-home-item.silhouette_ac li.active a:active,
            .tabs-home ul.tab-home-item.silhouette_ac li.active a:focus {
              border-bottom: 2px solid #41b9b8;
            }
            .tabs-home ul.tab-home-item.design_ac li.active a,
            .tabs-home ul.tab-home-item.design_ac li.active a:hover,
            .tabs-home ul.tab-home-item.design_ac li.active a:active,
            .tabs-home ul.tab-home-item.design_ac li.active a:focus {
              border-bottom: 2px solid #ffa823;
            }
            .hero-container .home-slider-header .search-box .search-container {
              padding: 8px;
              background: none;
              border-radius: 40px;
            }
            @media (max-width: 992px) {
              .tabs-home {
                width: 50%;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

// TODO: Convert all to selectors. Optimize selectors.
const mapStateToProps = createStructuredSelector({
  tags: makeSelectTags(),
  sliderImages: makeSelectSliderImage(),
  patternSelected: makeSelectPatternSelected(),
  patterns: makeSelectPatterns(),
  recommendList: makeSelectRecommendList(),
  loading: (state) => state.home.loading,
  cookie: (state) => state.noticeCookie,
  dataTypePattern: makeSelectDataTypePattern(),
  totalData: makeSelectTotalData(),
  categories: makeSelectCategories(),
  token: makeSelectToken(),
  link: makeSelectLink(),
  isAuthenticated: makeSelectIsAuthenticated(),
  profile: makeSelectProfile(),
  // assets: makeSelectPhotoAssets(),
  // list: makeSelectPhotos(),
  currentLanguage: makeSelectCurrentLanguage(),
  isMacOS: makeSelectIsMacOS(),
  dataType: makeSelectDataType(),
  collections: makeSelectCollections(),
  isLocaleInPath: makeSelectIsLocaleInPath(),
  isCountdownActive: makeSelectIsCountdownActive(),
  blogs: makeSelectBlogs(),
  layout: selectLayout,
  currentLang: makeSelectCurrentLang(),
});
const mapDispatchToProps = (dispatch) => ({
  loadDataType: (params) => dispatch(loadDataType(params)),
  onChangePattern: (id, lang) => dispatch(selectedPattern({ id, lang })),
  onLogout: () => {
    dispatch(logout());
    Router.push({ pathname: '/' });
  },
  setIsTopBannerVisible: (value) => dispatch(setIsTopBannerVisible(value)),
  loadRandomSliderImages: (params) => dispatch(loadRandomSliderImages(params)),
  updateDataCountSurvey: (data) => dispatch(updateDataCountSurvey(data)),
  searchPhoto: (data) => dispatch(searchPhoto(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
