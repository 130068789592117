import {
  PRE_LOAD_DOWNLOADED,
  LOAD_DOWNLOADED,
  SELECTED_PHOTO_DOWNLOADED,
  CHANGE_CURRENT_PAGE_DOWNLOADED,
  DELETED_PHOTO_DOWNLOADED,
  DOWNLOAD_EXTRA_LICENSE_CERTIFICATE,
  DOWNLOAD_EXTRA_LICENSE_CERTIFICATE_COMPLETED,
  SELECTED_ALL_PHOTO_DOWNLOADED,
} from './actions';

export const initState = {
  collection: [],
  has_next: false,
  max_results: 10,
  page: 1,
  total_item: 0,
  total_page: 0,
  loading: false,
  filter: {
    createdAt: 'desc',
  },
  downloadingCertificate: {},
};

const downloadHistoryReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case PRE_LOAD_DOWNLOADED:
      return {
        ...state,
        loading: true,
        page: action.page,
        max_results: action.max_results,
        filter: action.filter,
      };
    case LOAD_DOWNLOADED:
      return { ...state, ...action.downloaded, loading: false };

    case DELETED_PHOTO_DOWNLOADED:
      return {
        ...state,
        collection: state.collection.filter((item) => !item.checked),
      };

    case CHANGE_CURRENT_PAGE_DOWNLOADED:
      return { ...state, page: action.page };

    case SELECTED_PHOTO_DOWNLOADED:
      return {
        ...state,
        collection: state.collection.map((item, index) => {
          if (index === action.index) {
            return { ...item, checked: !item.checked };
          }
          return item;
        }),
      };
    case SELECTED_ALL_PHOTO_DOWNLOADED: {
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (action.value) {
            return { ...item, checked: true };
          } else {
            return { ...item, checked: false };
          }
        }),
      };
    }
    case DOWNLOAD_EXTRA_LICENSE_CERTIFICATE:
      return {
        ...state,
        downloadingCertificate: {
          ...state.downloadingCertificate,
          [action.payload.dataId]: true,
        },
      };
    case DOWNLOAD_EXTRA_LICENSE_CERTIFICATE_COMPLETED:
      return {
        ...state,
        downloadingCertificate: {
          ...state.downloadingCertificate,
          [action.payload.dataId]: false,
        },
      };
    default:
      return state;
  }
};

export default downloadHistoryReducer;
