import { createSelector } from 'reselect';

const selectStateCustomErrorMessage = (state) => state.customErrorMessage;

const makeSelectError = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.error,
  );

const makeSelectShowInfor = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.showInfor,
  );

const makeSelectShowError = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.showError,
  );

const makeSelectTitle = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.title,
  );

const makeSelectDescription = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.description,
  );

const makeSelectTypeInfor = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.typeInfor,
  );

const makeSelectShowIEWarning = () =>
  createSelector(
    selectStateCustomErrorMessage,
    (customErrorMessageState) => customErrorMessageState.showIEWarning,
  );

export {
  makeSelectError,
  makeSelectShowError,
  makeSelectTitle,
  makeSelectDescription,
  makeSelectTypeInfor,
  makeSelectShowIEWarning,
  makeSelectShowInfor,
};
