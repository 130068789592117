import {
  LOAD_LATEST_REGISTERED_USERS,
  LOAD_LATEST_REGISTERED_USERS_ERROR,
  LOAD_LATEST_REGISTERED_USERS_SUCCESS,
  LOAD_REGISTERED_USERS,
  LOAD_REGISTERED_USERS_ERROR,
  LOAD_REGISTERED_USERS_SUCCESS,
  SHOW_REGISTERED_USERS_MODAL,
  HIDE_REGISTERED_USERS_MODAL,
  LOAD_PAGE,
} from './constants';

export const initialState = {
  latestRegisteredUsers: [],
  registeredUsers: [],
  page: 1,
  totalPage: 0,
  loading: false,
  loadingLatestRegisteredUsers: false,
  error: false,
  showModal: false,
};

function logsRegisteredUsersReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LATEST_REGISTERED_USERS:
      return { ...state, loadingLatestRegisteredUsers: true };
    case LOAD_LATEST_REGISTERED_USERS_SUCCESS:
      return {
        ...state,
        loadingLatestRegisteredUsers: false,
        error: false,
        latestRegisteredUsers: action.payload,
      };
    case LOAD_LATEST_REGISTERED_USERS_ERROR:
      return { ...state, loadingLatestRegisteredUsers: false, error: true };
    case LOAD_REGISTERED_USERS:
      return {
        ...state,
        loading: true,
        page: action.page || state.page,
      };
    case LOAD_REGISTERED_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        registeredUsers: action.collection,
        page: action.page,
        totalPage: action.total_page,
      };
    case LOAD_REGISTERED_USERS_ERROR:
      return { ...state, loading: false, error: true };
    case SHOW_REGISTERED_USERS_MODAL:
      return { ...state, showModal: true };
    case HIDE_REGISTERED_USERS_MODAL:
      return { ...state, showModal: false };
    case LOAD_PAGE:
      return { ...state, loading: true, ...action.payload };
    default:
      return state;
  }
}

export default logsRegisteredUsersReducer;
