import forIn from 'lodash/forIn';
import { SERVICE_TYPES } from './constants';
import config from '~/config';

const convertToQueryStrings = (query) => {
  let path = '';
  forIn(query, (value, key) => {
    path += value ? `&${key}=${value}` : '';
  });
  if (path.startsWith('&')) {
    path = path.substring(1);
  }

  return path;
};

const isBlockedInChina = (url) => {
  const blockedDomains = [
    'googleusercontent.com',
    'scontent.xx.fbcdn.net',
    'facebook.com',
    'platform-lookaside.fbsbx.com',
  ];
  return blockedDomains.some((domain) => url.includes(domain));
};

const renderAvatarUrl = (avatar, random = false) => {
  if (avatar && avatar != 'undefined') {
    if (
      avatar.startsWith('/static/assets/image/default-avatar') ||
      avatar.startsWith('/static/assets/image/profile-images')
    ) {
      return `${config.staticPrefix}${avatar}`;
    }
    if (
      avatar.startsWith('/assets/image/default-avatar') ||
      avatar.startsWith('/assets/image/profile-images')
    ) {
      return `${config.staticPrefix}/static${avatar}`;
    }

    if (config.isChina()) {
      if (!isBlockedInChina(avatar)) return avatar;
    } else {
      return avatar.replace('http://', 'https://');
    }
  }

  let defaultAvatar = `${config.staticPrefix}/static/assets/image/avatar_default.png`;
  if (random) {
    defaultAvatar = `${
      config.staticPrefix
    }/static/assets/image/default-avatar/${
      parseInt(Math.random() * 30, 10) + 1
    }.png`;
  }

  return defaultAvatar;
};

const trackPageView = (url) => {
  setTimeout(() => {
    try {
      console.log('trackPageView', url, document.title);
      if (!window.gtag) return;
      console.log('Googleeeeee');
      window.gtag('config', config.getGoogleAnalytics('cn'), {
        page_path: url,
        page_title: document.title,
      });
      if (!window._hmt) return;
      console.log('Baiduuuuuu');
      window._hmt.push(['_trackPageview', url]);
    } catch (error) {
      console.log('trackPageViewAAAA ERROR', error);
    }
  }, 1000);
};

const fixImageURL = (imageURL) => {
  if (!imageURL) return imageURL;

  if (
    config.serviceType === SERVICE_TYPES.PHOTO_AC ||
    config.serviceType === SERVICE_TYPES.ILLUST_AC
  ) {
    if (imageURL.endsWith('.jpg')) {
      return `${imageURL.substring(0, imageURL.length - 3)}jpeg`;
    }
  }
  return imageURL;
};

const getCanonicalURL = (lng, path) => {
  return `${config.getSiteURL(config.serviceType, lng)}${path}`;
};

export default {
  convertToQueryStrings,
  renderAvatarUrl,
  trackPageView,
  fixImageURL,
  getCanonicalURL,
};
