import {
  SHOW_ERROR,
  HIDE_ERROR,
  SHOW_INFOR,
  HIDE_INFOR,
  SIMULATE_ERROR,
  CHECK_IE_BROWSER,
  SHOW_IE_WARNING,
  HIDE_IE_WARNING,
} from './constants';

export function showError(payload) {
  console.log('🚀 ~ file: actions.js ~ line 13 ~ showError ~ payload', payload);
  return {
    type: SHOW_ERROR,
    payload,
  };
}

export function showInfor(payload) {
  return {
    type: SHOW_INFOR,
    payload,
  };
}

export function hideInfor() {
  return {
    type: HIDE_INFOR,
  };
}

export function hideError() {
  return {
    type: HIDE_ERROR,
  };
}

export function simulateError(code) {
  return {
    type: SIMULATE_ERROR,
    code,
  };
}

export function checkIEBrowser() {
  return {
    type: CHECK_IE_BROWSER,
  };
}

export function showIEWarning() {
  return {
    type: SHOW_IE_WARNING,
  };
}

export function hideIEWarning() {
  return {
    type: HIDE_IE_WARNING,
  };
}
