import axios from './interceptor-axios';
import config from '~/config';
import Cookies from '~/services/Cookies';

function loginWithFacebook(token, lang, accountType) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/login`,
    data: {
      type: 'facebook',
      service_type: config.serviceType,
      lang: lang || config.language,
      account_type: accountType,
      access_token: token,
    },
  };
  return axios(request);
}

function loginWithGoogle(token, lang, accountType) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/login`,
    data: {
      type: 'google',
      service_type: config.serviceType,
      lang: lang || config.language,
      account_type: accountType,
      access_token: token,
    },
  };
  return axios(request);
}

function loginWithEmail(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/login`,
    data: {
      type: 'email',
      email: params.email,
      password: params.password,
      account_type: params.accountType,
      lang: params.currentLanguage,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function loginWithTwitter(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/twitter/login`,
    data: {
      lang: params.lang || config.language,
      token: params.token,
      token_secret: params.tokenSecret,
      service_type: config.serviceType,
      oauth_verifier: params.oauthVerifier,
      isNewPlatform: true,
      account_type: params.accountType,
    },
  };
  return axios(request);
}

function getCredentialTwitter(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/twitter/credential`,
    params: {
      service_type: config.serviceType,
      lang: params.lang || config.language,
      isNewPlatform: true,
    },
  };
  return axios(request);
}

function registerMember(email, password, lang, accountType, captcha) {
  let request;
  if (config.isChina()) {
    request = {
      method: 'POST',
      url: `${config.apiUrl.apiUser}/signup`,
      data: {
        email,
        password,
        language: lang || config.language,
        service_type: config.serviceType,
        account_type: accountType,
        captcha,
      },
    };
  } else {
    request = {
      method: 'POST',
      url: `${config.apiUrl.apiUser}/signup`,
      data: {
        email,
        language: lang || config.language,
        service_type: config.serviceType,
        account_type: accountType,
        captcha,
      },
    };
  }
  return axios(request);
}

function checkTokenRegister(token) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/signup/token`,
    data: {
      token,
      language: config.language,
      service_type: config.serviceType,
    },
  };

  return axios(request);
}

function changePassword(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/signup/confirmation`,
    data: {
      password: params.password,
      token: params.token,
      lang: params.currentLanguage,
      service_type: config.serviceType,
    },
  };

  return axios(request);
}

function changePasswordUser(params) {
  const request = {
    method: 'PUT',
    url: `${config.apiUrl.apiUser}/password`,
    data: {
      old_password: params.old_password,
      new_password: params.new_password,
      service_type: config.serviceType,
    },
  };

  return axios(request);
}

function forgotPassword(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/forgotPassword`,
    data: {
      email: params.email,
      language: params.currentLanguage,
      account_type: params.accountType,
      service_type: config.serviceType,
    },
  };

  return axios(request);
}

function resetPassword(password, token) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/forgotPassword/confirmation`,
    data: {
      password,
      token,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function getProfile(lang, token = Cookies.get('token')) {
  console.log('getProfileeeeeeeee', lang, token);
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/profile`,
    params: {
      lang: lang || config.language,
      service_type: config.serviceType,
    },
    headers: {
      Authorization: token,
    },
  };
  return axios(request);
}

function checkPremiumUser(token = Cookies.get('token')) {
  console.log('checkPremiumUser', token);
  if (!token) {
    return {};
  }
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/user/check-premium`,
    params: {
      service_type: config.serviceType,
      lang: config.language,
    },
    headers: {
      Authorization: token,
    },
  };
  return axios(request);
}

async function updateShowPopup(isShowPopup, token = Cookies.get('token')) {
  if (!token) {
    return {};
  }
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/user/show-popup`,
    data: {
      service_type: config.serviceType,
      lang: config.language,
      isShowPopup,
    },
    headers: {
      Authorization: token,
    },
  };
  return await axios(request);
}

async function updateContinueRenew(
  isContinueRenew,
  token = Cookies.get('token'),
) {
  if (!token) {
    return {};
  }
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/user/continue-renew`,
    data: {
      service_type: config.serviceType,
      lang: config.language,
      isContinueRenew,
    },
    headers: {
      Authorization: token,
    },
  };
  return await axios(request);
}

function simulateError(code) {
  const request = {
    method: 'GET',
    url: `${process.env.API_PUBLIC_LOCAL}/simulate-error`,
    params: {
      service_type: 'photo',
      code,
    },
  };
  return axios(request);
}

export { loginWithFacebook };
export { loginWithGoogle };
export { loginWithEmail };
export { loginWithTwitter };
export { getCredentialTwitter };
export { registerMember };
export { checkTokenRegister };
export { changePassword };
export { forgotPassword };
export { resetPassword };
export { getProfile };
export { changePasswordUser };
export { simulateError };
export { checkPremiumUser, updateShowPopup, updateContinueRenew };
