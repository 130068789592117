import {
  GET_CREATORS,
  GET_CREATORS_SUCCESS,
  GET_CREATORS_ERROR,
  CHANGE_PAGE,
  FILTER_PER_PAGE,
} from './constants';

export const initState = {
  collection: [],
  loading: true,
  pagination: {
    has_next: false,
    page: 1,
    max_results: 100,
    total_page: 0,
    total_item: 0,
  },
};

function creatorsPage(state = initState, action = {}) {
  switch (action.type) {
  case GET_CREATORS:
    return { ...state, loading: true };
  case GET_CREATORS_SUCCESS:
    return { ...state, ...action, loading: false };
  case GET_CREATORS_ERROR:
    return { ...state, loading: false };
  case CHANGE_PAGE:
    return {
      ...state,
      pagination: { ...state.pagination, page: action.page },
    };
  case FILTER_PER_PAGE:
    return {
      ...state,
      pagination: { ...state.pagination, max_results: action.value },
    };
  default:
    return state;
  }
}

export default creatorsPage;
