import React from 'react';
import ACImage from '~/components/ACImage';
import themeVariables from '~/shared/themeVariables';

const CuratedTopic = (props) => {
  const { data, lng } = props;
  if (data?.length < 6) return null;

  const dataMain = data[5];
  // const nameMainData = JSON.parse(dataMain.names);
  // const nameMain = nameMainData
  //   ? nameMainData[`name_${lng}`] || nameMainData['name_en']
  //   : '';
  const thumbnailDataMain = JSON.parse(dataMain.thumbnail_url);
  const thumbnailMain =
    dataMain.priority === 1
      ? thumbnailDataMain
        ? thumbnailDataMain[`url_${lng}`] || thumbnailDataMain['url_all']
        : ''
      : thumbnailDataMain['url_all'];
  const linkDataMain = JSON.parse(dataMain.search_url);
  // const linkChildMain = linkDataMain
  //   ? linkDataMain[`url_${lng}`] || linkDataMain['url_en']
  //   : '';
  const linkChildMain = linkDataMain ? linkDataMain['url_en'] : '';
  const linkMain = linkChildMain.split('ac-illust.com/');

  const dataChild = data.slice(0, data.length - 1);
  return (
    <div className="curated-topic">
      {dataChild.map((ele, idx) => {
        // const nameData = JSON.parse(ele.names);
        // const name = nameData
        //   ? nameData[`name_${lng}`] || nameData['name_en']
        //   : '';
        const thumbnailData = JSON.parse(ele.thumbnail_url);
        const thumbnail =
          ele.priority === 1
            ? thumbnailData
              ? thumbnailData[`url_${lng}`] || thumbnailData['url_all']
              : ''
            : thumbnailData['url_all'];
        const linkData = JSON.parse(ele.search_url);
        // const linkChild = linkData
        //   ? linkData[`url_${lng}`] || linkData['url_en']
        //   : '';
        const linkChild = linkData ? linkData['url_en'] : '';
        const link = linkChild.split('ac-illust.com/');
        return (
          <a
            key={ele.id}
            id={`item-${idx}`}
            target="_blank"
            href={`/${link[1]}`}
            rel="noreferrer"
          >
            <div className="item-container">
              <ACImage
                src={thumbnail || ''}
                alt={ele?.name || ''}
                className="topic-thumbnail"
                layout="fill"
                objectFit="cover"
              />
              <div className="background-curated">{ele?.name || ''}</div>
            </div>
          </a>
          // </I18nLink>
        );
      })}
      {dataMain && (
        // <I18nLink href={dataMain.search_url}>
        <a
          id="item-5"
          target="_blank"
          href={`/${linkMain[1]}`}
          rel="noreferrer"
        >
          <div className="item-container-main">
            <ACImage
              src={thumbnailMain || ''}
              alt={dataMain?.name || ''}
              className="topic-thumbnail"
              layout="fill"
              objectFit="fill"
              priority
            />
            <div className="background-curated main">
              <span>{dataMain?.name || ''}</span>
            </div>
          </div>
        </a>
        // </I18nLink>href={dataMain.search_url}
      )}
      <style jsx global>
        {`
          .topic-thumbnail {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        `}
      </style>
      <style jsx>
        {`
          .item-container {
            height: 100%;
            position: relative;
          }
          .item-container-main {
            height: 100%;
            position: relative;
          }
          .curated-topic {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 8px;
          }
          #item-0 {
            border-radius: 4px;
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-column-end: 2;
            cursor: pointer;
          }
          #item-1 {
            border-radius: 4px;
            grid-row-start: 1;
            grid-column-start: 2;
            grid-row-end: 2;
            grid-column-end: 3;
          }
          #item-2 {
            border-radius: 4px;
            grid-row-start: 1;
            grid-column-start: 3;
            grid-row-end: 2;
            grid-column-end: 4;
          }
          #item-3 {
            border-radius: 4px;
            grid-row-start: 2;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 2;
          }
          #item-4 {
            border-radius: 4px;
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 2;
          }
          #item-5 {
            height: 500px;
            border-radius: 4px;
            grid-row-start: 2;
            grid-column-start: 2;
            grid-row-end: 4;
            grid-column-end: 4;
          }
          .background-curated {
            border-radius: 4px;
            width: 100%;
            height: 48px;
            position: absolute;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.05)
            );
            font-weight: 500;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #ffffff;
            bottom: 0;
            padding-left: 32px;
          }
          @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
            .background-curated {
              font-size: 14px;
              padding-left: 8px;
            }

            #item-5 {
              height: 300px;
            }
          }

          @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
            .background-curated {
              font-size: 14px;
              padding-left: 8px;
            }

            #item-5 {
              height: 200px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default CuratedTopic;
