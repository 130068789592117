import axios from '../interceptor-axios';
import config from '~/config';

function updateProfile(profile) {
  const request = {
    method: 'PUT',
    url: `${config.apiUrl.apiUser}/downloader`,
    data: {
      avatar: profile.avatar,
      google: profile.google,
      website: profile.website,
      twitter: profile.twitter,
      facebook: profile.facebook,
      nickname: profile.nickname,
      instagram: profile.instagram,
      header_url: profile.header_url,
      mail_notice: profile.mail_notice,
      mail_notice_ko: profile.mail_notice_ko,
      comment_notice: profile.comment_notice,
      is_professional: profile.is_professional,
      self_introduction: profile.self_introduction,
      camera_description: profile.camera_description,
      nickname_for_overseas: profile.nickname_for_overseas,
      contact_from_this_site: profile.contact_from_this_site,
      email: profile.email,
      lang: profile.lang,
      birthday: new Date(profile.birthday).getTime() / 1000,
      service_type: config.serviceType,
    },
  };

  return axios(request);
}

function loadMyProfile(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/profile`,
    params: {
      user_id: params.user_id,
      lang: params.lang || 'en',
      service_type: params.service_type || config.serviceType,
      fields: params.fields,
    },
  };

  return axios(request);
}

function getMyProfile(params) {
  console.log('getMyProfileeeeeeee', params);
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/profile`,
    params: {
      lang: params.lang,
      service_type: params.service_type || config.serviceType,
      token: params.token,
      fields: params.fields,
    },
  };

  return axios(request);
}

function putMyProfile(params) {
  const request = {
    method: 'PUT',
    url: `${config.apiUrl.apiUser}/creator/profile`,
    data: {
      lang: params.lang,
      service_type: params.service_type || config.serviceType,
      avatar: params.avatar,
      header_url: params.header_url,
      nickname: params.nickname,
      nickname_for_overseas: params.nickname_for_overseas,
      self_introduction: params.self_introduction,
      camera_description: params.camera_description,
      website: params.website,
      facebook: params.facebook,
      twitter: params.twitter,
      google: params.google,
      instagram: params.instagram,
      contact_from_this_site: !!params.contact_from_this_site,
      comment_notice: !!params.comment_notice,
      mail_notice: !!params.mail_notice,
      is_safety: !!params.is_safety,
    },
  };
  return axios(request);
}

function getCreatorProfile(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/creator/profile`,
    params: {
      lang: params.lang,
      service_type: params.service_type || config.serviceType,
      token: params.token,
      fields: params.fields,
    },
  };

  return axios(request);
}

function putCreatorInfo(params) {
  const request = {
    method: 'PUT',
    url: `${config.apiUrl.apiUser}/creator/info`,
    data: {
      lang: params.lang,
      service_type: params.service_type || config.serviceType,
      name: params.name,
      birthday: params.birthday,
      zipcode: params.zipcode,
      prefecture: params.prefecture,
      city: params.city,
      address: params.address,
      building: params.building,
      phone: params.phone,
      company: params.company,
      representative: params.representative,
      country: params.country,
      is_train_data: params.is_train_data,
    },
  };
  return axios(request);
}

function getCreatorInfo(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/creator/info`,
    params: {
      lang: params.lang,
      token: params.token,
      service_type: params.service_type || config.serviceType,
    },
  };
  return axios(request);
}

function getProfileInfo(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/creator/info`,
    params: {
      creator_id: params.creator_id,
      lang: params.lang,
      fields: params.fields,
      service_type: params.service_type || config.serviceType,
    },
  };

  return axios(request);
}

function getCreators(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/creators`,
    params: {
      page: params.page || 1,
      max_results: params.max_results || 100,
      clientIp: params.clientIp,
    },
  };

  return axios(request);
}

function getCreatorPublicProfile(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/creator/public-profile`,
    params: {
      creator_id: params.creator_id,
    },
  };

  return axios(request);
}

function getCreatorData(params = {}) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/creator/data/public`,
    params: {
      creator_id: params.creator_id,
      lang: params.lang,
      service_type: params.service_type || config.serviceType,
      page: params.page || 1,
      max_results: params.itemPerPage || 100,
      createdAt: params.data_type === 'new' ? 'desc' : undefined,
      download: params.data_type === 'popular' ? 'desc' : undefined,
      type: params.type,
      clientIp: params.clientIp,
    },
  };
  return axios(request);
}

export {
  updateProfile,
  loadMyProfile,
  getProfileInfo,
  getCreatorData,
  getMyProfile,
  getCreatorProfile,
  putMyProfile,
  putCreatorInfo,
  getCreatorInfo,
  getCreatorPublicProfile,
  getCreators,
};
