import axios from './interceptor-axios';
import Config from '../config';

function getDonationData() {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiDonationFreebie}?callback=(function(a) { return a; })`,
    timeout: 1000,
  };
  return axios(request);
}

export { getDonationData };
