import loadjs from 'loadjs';
import Config from '~/config';

function getExtensionWithFileType(fileType) {
  if (fileType === 'image/jpeg') {
    return 'jpg';
  }
  if (fileType === 'image/png') {
    return 'png';
  }
  if (fileType === 'application/vnd.ms-powerpoint') {
    return 'zip';
  }
  return '';
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

function blobToImageElement(blobObj) {
  return new Promise((resolve, reject) => {
    const imgObj = new Image();
    const reader = new FileReader();
    reader.onload = (e) => {
      imgObj.onload = () => {
        resolve(imgObj);
      };
      imgObj.onerror = (error) => {
        reject(error);
      };
      imgObj.src = e.target.result;
    };
    reader.readAsDataURL(blobObj);
  });
}

function loadStripeJS() {
  return new Promise((resolve) => {
    if (!loadjs.isDefined('stripejs')) {
      // loadjs(`${Config.staticPrefix}/static/stripe-v3.js`, 'stripejs');
      if (Config.isChina()) {
        loadjs(`${Config.staticPrefix}/static/stripe-v3.js`, 'stripejs');
      } else {
        loadjs('https://js.stripe.com/v3/', 'stripejs');
      }
    }
    loadjs.ready('stripejs', () => {
      if (window.Stripe) {
        console.log('Init Stripe.......');
        resolve(window.Stripe(process.env.STRIPE_PUBLIC_KEY));
      }
    });
  });
}

function loadAdsense() {
  return new Promise((resolve) => {
    if (!loadjs.isDefined('adsense')) {
      console.log('loadjsssss adsense');
      loadjs('https://www.googletagservices.com/tag/js/gpt.js', 'adsense');
    }
    loadjs.ready('adsense', () => {
      console.log('Init Google Adsense.........');
      if (window.googletag) {
        var googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(function () {
          googletag
            .defineSlot(
              '/17939840/Global_Illust_Side_336x280',
              ['fluid', [300, 250], [336, 280], [300, 160]],
              'div-gpt-ad-1555572300636-0',
            )
            .addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();
        });
        resolve(googletag);
      }
      // resolve(window.googletag);
    });
  });
}

function loadNewAdsense() {
  return new Promise((resolve) => {
    if (!loadjs.isDefined('new-adsense')) {
      loadjs(
        'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        'new-adsense',
      );
    }
    loadjs.ready('new-adsense', () => {
      if (window.googletag) {
        var googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(function () {
          googletag
            .defineSlot(
              '/17939840/illustGl_DL_Timer',
              [
                [336, 280],
                [300, 250],
              ],
              'div-gpt-ad-1665020264318-0',
            )
            .addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
        resolve(googletag);
      }
    });
  });
}

function loadSyndicationAds() {
  /*
  <script
    data-ad-client="ca-pub-6219550375267396"
    defer
    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
  />
  */
  return new Promise((resolve) => {
    if (!loadjs.isDefined('syndicationAds')) {
      console.log('loadjsssss syndicationAds');
      loadjs(
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
        'syndicationAds',
        {
          success: function () {},
          error: function () {},
          before: function (path, scriptEl) {
            scriptEl.setAttribute('data-ad-client', 'ca-pub-6219550375267396');
          },
        },
      );
    }
    loadjs.ready('syndicationAds', () => {
      resolve();
    });
  });
}

function loadGoogleAnalyticsForGlobalSites(lng) {
  /*
  <script
    dangerouslySetInnerHTML={{
      __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GA_TRACKING_ID}');
    }}
  />
  */
  const GA_TRACKING_ID = Config.getGoogleAnalytics(lng);

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GA_TRACKING_ID);
}

function loadGoogleAnalyticsForChinaSites(lng) {
  /*
  <script
    defer
    src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
  />
  <script
    dangerouslySetInnerHTML={{
      __html: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA_TRACKING_ID}', { 'allow_ad_personalization_signals': false });
      `,
    }}
  />
  */
  console.log('lng trong nay ne hihihihihih', lng);
  const GA_TRACKING_ID = Config.getGoogleAnalytics(lng);

  return new Promise((resolve) => {
    if (!loadjs.isDefined('googleAnalytics')) {
      console.log('loadjsssss googleAnalytics');
      loadjs(
        `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`,
        'googleAnalytics',
        {
          success: function () {},
          error: function () {},
          before: function () {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', GA_TRACKING_ID, {
              allow_ad_personalization_signals: false,
            });
          },
        },
      );
    }
    loadjs.ready('googleAnalytics', () => {
      resolve();
    });
  });
}

function loadPinterest() {
  return new Promise((resolve) => {
    if (!loadjs.isDefined('pinterest')) {
      console.log('loadjsssss pinterest');
      setTimeout(() => {
        loadjs('https://assets.pinterest.com/js/pinit.js', 'pinterest');
      }, 0);
    }
    loadjs.ready('pinterest', () => {
      resolve();
    });
  });
}

function capitalizeString(str) {
  if (str) {
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  }
  return '';
}

// Gets the display name of a JSX component for dev tools
function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export {
  getExtensionWithFileType,
  dataURItoBlob,
  loadStripeJS,
  loadAdsense,
  loadSyndicationAds,
  loadGoogleAnalyticsForGlobalSites,
  loadGoogleAnalyticsForChinaSites,
  loadPinterest,
  blobToImageElement,
  capitalizeString,
  getDisplayName,
  loadNewAdsense,
};
