export const LOAD_DATA_COLLECTION_BY_ID =
  'containers/CollectionPageDetail/LOAD_DATA_COLLECTION_BY_ID';
export const LOAD_COLLECTION_DETAIL_SUCCESS =
  'containers/CollectionPageDetail/LOAD_COLLECTION_DETAIL_SUCCESS';
export const LOAD_COLLECTION_DETAIL_ERROR =
  'containers/CollectionPageDetail/LOAD_COLLECTION_DETAIL_ERROR';
export const LOAD_DATA_COLLECTION_PHOTO =
  'containers/CollectionPageDetail/LOAD_DATA_COLLECTION_PHOTO';
export const LOAD_DATA_COLLECTION_PHOTO_SUCCESS =
  'containers/CollectionPageDetail/LOAD_DATA_COLLECTION_PHOTO_SUCCESS';
export const LOAD_DATA_COLLECTION_PHOTO_ERROR =
  'containers/CollectionPageDetail/LOAD_DATA_COLLECTION_PHOTO_ERROR';
export const LOAD_COLLECTIONS_OF_MONTHS =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_MONTHS';
export const LOAD_COLLECTIONS_OF_MONTHS_SUCCESS =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_MONTHS_SUCCESS';
export const LOAD_COLLECTIONS_OF_MONTHS_ERROR =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_MONTHS_ERROR';
export const NICE_PHOTO = 'containers/CollectionPage/NICE_PHOTO';
export const NICE_PHOTO_SUCCESS =
  'containers/CollectionPage/NICE_PHOTO_SUCCESS';
export const REMOVE_NICE_PHOTO_SUCCESS =
  'containers/CollectionPage/REMOVE_NICE_PHOTO_SUCCESS';
export const NICE_PHOTO_ERROR = 'containers/CollectionPage/NICE_PHOTO_ERROR';
