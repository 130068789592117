import { createSelector } from 'reselect';

const selectLanguage = (state) => state.language;

const makeSelectCurrentLanguage = () =>
  createSelector(
    selectLanguage,
    (languageState) => languageState.currentLanguage,
  );

export { selectLanguage, makeSelectCurrentLanguage };
