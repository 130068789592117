import axios from './interceptor-axios';
import config from '~/config';

function getSliderImages(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/banner`,
    params: {
      service_type: config.serviceType,
      disp_language: params.lang || config.language,
      pattern_id: params.id,
    },
  };
  return axios(request);
}

function getRecommendList(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/data/recommended`,
    params: {
      lang: params.lang || config.language,
      page: params.page,
      per_page: params.per_page || 100,
      download: params.download || 'desc',
      rand: params.filter.rand,
      pattern_id: params.pattern_id,
      fileType: params.filter.fileType,
      max_results: params.max_results || 100,
      free_only: params.free_only || false,
      clientIp: params.clientIp,
    },
  };
  return axios(request);
}

function countDownload() {
  // TODO: remove mocks
  // const request = {
  //   method: 'GET',
  //   url: `${config.apiUrl.apiS3Service}/download/count`,
  //   params: {},
  // };
  // return axios(request);
  return require('./__mocks__/downloadCount.json');
}

function countData() {
  // TODO: remove mocks
  // const request = {
  //   method: 'GET',
  //   url: `${config.apiUrl.apiS3Service}/data/count`,
  //   params: {
  //     service_type: config.serviceType,
  //   },
  // };
  // return axios(request);
  return require('./__mocks__/dataCount.json');
}

function countUser() {
  // TODO: remove mocks
  // const request = {
  //   method: 'GET',
  //   url: `${config.apiUrl.apiS3Service}/data/user/total`,
  //   params: {},
  // };
  // return axios(request);
  return require('./__mocks__/totalUser.json');
}

function getPopularSearch(lang) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiPhoto}/popular-tag`,
    params: {
      service_type: config.serviceType,
      lang: lang || config.language,
    },
  };
  return axios(request);
}

function getPattern(lang) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/pattern/lang`,
    params: {
      service_type: config.serviceType,
      lang: lang || config.language,
    },
  };
  return axios(request);
}

function getPatternNew(lang) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/pattern/homepage`,
    params: {
      service_type: config.serviceType,
      lang: lang || config.language,
    },
  };
  return axios(request);
}

function getCuratedTopic(lang) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/curated/homepage`,
    params: {
      service_type: config.serviceType,
      lang: lang || config.language,
    },
  };
  return axios(request);
}

function getDataType(params) {
  console.log(params);
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/data`,
    params: {
      service_type: config.serviceType,
      lang: config.language,
      type: params.eventType,
      count_all: params.count_all,
      page: params.page || 1,
      max_results: params.max_results || 100,
      clientIp: params.clientIp,
    },
  };
  return axios(request);
}

export {
  getSliderImages,
  getDataType,
  getRecommendList,
  countDownload,
  countData,
  countUser,
  getPopularSearch,
  getPattern,
  getPatternNew,
  getCuratedTopic,
};
