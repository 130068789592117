import { createSelector } from 'reselect';

const selectCreatorProfile = state => state.creatorProfile;

const makeSelectProfile = () => createSelector(
  selectCreatorProfile,
  state => state.profile,
);

const makeSelectCollection = () => createSelector(
  selectCreatorProfile,
  state => state.collection,
);

const makeSelectLoading = () => createSelector(
  selectCreatorProfile,
  state => state.loading,
);

const makeSelectPagination = () => createSelector(
  selectCreatorProfile,
  state => state.pagination,
);

export {
  makeSelectProfile, makeSelectCollection, makeSelectLoading, makeSelectPagination,
};
