import React from 'react';
import clsx from 'clsx';
import DataSlideShow from './slideShowData';
import ACImage from '~/components/ACImage';
import Config from '~/config';
import NoScript from '~/components/NoScript';
import withI18next from '~/../hocs/_withI18next';

@withI18next()
class HeroSlider extends React.Component {
  timeoutCarousel = null;

  componentDidMount() {
    // Check visibility of slideshow to hide banner
    const { onChangeVisibility } = this.props;
    onChangeVisibility(window.scrollY <= 700);

    // Start running carousel
    let myIndex = 0;
    const that = this;
    const carousel = () => {
      let i;
      let x = document.getElementsByClassName('hero-slider-item');
      for (i = 0; i < x.length; i++) {
        x[i].style.display = 'none';
      }
      myIndex++;
      if (myIndex > x.length) {
        myIndex = 1;
      }
      if (x[myIndex - 1] && x[myIndex - 1].style) {
        x[myIndex - 1].style.display = 'block';
      }
      if (that) {
        that.timeoutCarousel = setTimeout(carousel, 9000);
      }
    };
    carousel();
  }

  componentWillUnmount() {
    if (this && this.timeoutCarousel) {
      clearTimeout(this.timeoutCarousel);
      this.timeoutCarousel = null;
    }
  }

  componentDidUpdate(prevProps) {
    const { onChangeVisibility, inViewport } = this.props;
    if (inViewport !== prevProps.inViewport) {
      onChangeVisibility(inViewport);
    }
  }

  renderChristmasCampaign = () => {
    return (
      <div className="christmas-campaign">
        <a href="/">
          <img
            className="hero-slider-item lazyload"
            alt="Christmas Campaign"
            data-src={`${Config.staticPrefix}/static/images/campaign/campaign-christmas.jpg`}
          />
        </a>
      </div>
    );
  };

  renderStaticSlideshow = (lng, t) => {
    const data = DataSlideShow.getImage(lng);
    let creatorURL = '/';
    // TODO: REMOVE THIS COMMENT

    // if (Config.isPhotoAC() || Config.isSilhouetteAC()) {
    //   creatorURL = '/creator-profile/43626';
    // } else if (Config.isIllustAC()) {
    //   creatorURL = '/creator-profile/6746';
    // } else if (Config.isDesignAC()) {
    //   creatorURL = '/creator-profile/6744';
    // }

    return (
      <div>
        {data.map((ele, idx) => {
          return (
            <div className="christmas-campaign" key={idx}>
              <ACImage
                className="hero-slider-item"
                alt="slideshow image"
                src={ele}
                layout="fill"
                objectFit="cover"
                priority={idx === 0}
              />
              <div
                className={clsx('photo-from-creator', {
                  'illust-creator-link': Config.isIllustAC() && lng !== 'tw',
                })}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `${Config.getPrefixContent('from_creator_homepage')}`,
                    {
                      creatorName: 'ACworks',
                      creatorURL,
                    },
                  ),
                }}
              />
            </div>
          );
        })}
        <style jsx>
          {`
            .photo-from-creator {
              position: absolute;
              color: #fff;
              font-weight: normal;
              right: 16px;
              bottom: 16px;
              font-size: 16px;
              z-index: 100;
            }
          `}
        </style>
        <style jsx global>
          {`
            .illust-creator-link a {
              color: #ffffff !important;
              text-decoration: underline !important;
            }
            .photo-from-creator a:hover {
              text-decoration: underline !important;
              opacity: 0.8;
            }
          `}
        </style>
      </div>
    );
  };
  render() {
    const { sliderImages, isDiscountDisplayed, lng, t } = this.props;

    // if (!isDiscountDisplayed && (!sliderImages || sliderImages.length <= 0)) {
    //   return null;
    // }
    return (
      <div className="slider-images">
        {(Config.isPhotoAC() ||
          Config.isIllustAC() ||
          Config.isSilhouetteAC()) &&
          this.renderStaticSlideshow(lng, t)}
        {Config.isDesignAC() &&
          sliderImages.map((image, idx) => {
            return (
              <ACImage
                key={idx}
                className="hero-slider-item"
                alt="slideshow image"
                src={image.filename}
                priority={idx === 0}
              />
            );
          })}
        {/* {(Config.isSilhouetteAC() || Config.isDesignAC()) &&
          this.renderChristmasCampaign()} */}

        {/* {isDiscountDisplayed && this.renderChristmasCampaign()} */}
        <div className="slider-background-blur" />
        <NoScript>
          {sliderImages.map((item) => (
            <a key={item.id} href={item.url_redirect}>
              <img src={item.filename} alt="slideshow image" />
            </a>
          ))}
        </NoScript>
        <style jsx>
          {`
            .slider-images {
              position: absolute;
              top: 0;
              background: rgba(233, 228, 228, 0.3);
              height: 468px;
              width: 100%;
              overflow: hidden;
            }

            .slider-background-blur {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              background: ${Config.isIllustAC() && lng !== 'tw'
                ? 'none'
                : 'rgba(0, 0, 0, 0.35)'};
            }
          `}
        </style>
        <style jsx global>
          {`
            .hero-slider-item {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              overflow: hidden;
              min-height: 100%;
              object-fit: cover;
              animation: fading 10s infinite;
            }
            .hero-slider-item-h800 {
              height: 800px;
            }
          `}
        </style>
      </div>
    );
  }
}

export default HeroSlider;
