import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_TOP_TAGS,
  LOAD_TOP_TAGS_SUCCESS,
  LOAD_TOP_TAGS_ERROR,
} from './constants';
import * as topTagsService from '~/services/topTagsService';

function* loadTopTagsData(action) {
  try {
    const { collection, page, total_page, has_next } = yield call(
      topTagsService.getTopTagsData,
      action.param,
    );
    yield put({
      type: LOAD_TOP_TAGS_SUCCESS,
      collection,
      has_next,
      page,
      total_page,
      filter: action.param.filter,
    });
  } catch (error) {
    yield put({
      type: LOAD_TOP_TAGS_ERROR,
      error: true,
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* loadTopTagsDataSaga() {
  yield takeLatest(LOAD_TOP_TAGS, loadTopTagsData);
}
