import axios from './interceptor-axios';
import photoACPremiumPrice from './defaultPremiumPrice/photo_ac.json';
import illustACPremiumPrice from './defaultPremiumPrice/illust_ac.json';
import silhouetteACPremiumPrice from './defaultPremiumPrice/silhouette_ac.json';
import templateACPremiumPrice from './defaultPremiumPrice/design_ac.json';
import config from '~/config';

const premiumPriceByServiceType = {
  photo_ac: photoACPremiumPrice,
  illust_ac: illustACPremiumPrice,
  silhouette_ac: silhouetteACPremiumPrice,
  design_ac: templateACPremiumPrice,
};

function getPremiumPrice(params) {
  return premiumPriceByServiceType[config.serviceType];

  // const request = {
  //   method: 'GET',
  //   url: `${config.apiUrl.apiUser}/premium/price`,
  //   params: {
  //     service_type: config.serviceType,
  //     type: params.type || 'premium',
  //   },
  // };
  // return axios(request);

  // console.log('defaultPremiumPriceeeeeeeeeeeeeeeee', defaultPremiumPrice);
  // return defaultPremiumPrice;
}

function getSinglePremiumPrice() {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiTemps}/premium/single_price`,
    params: {
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function upgradePremiumWithCredit(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiTemps}/premium/register/stripe`,
    data: {
      service_type: config.serviceType,
      lang: params.lang,
      stripeToken: params.stripeToken,
      idempotencyToken: params.idempotencyToken,
      plan: params.plan,
      isDiscounted: params.isDiscounted,
      currency: params.currency,
      plan_id: params.planId,
    },
  };

  return axios(request);
}

function cancel(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiTemps}/premium/cancel`,
    data: {
      job: params.job,
      reason_select: params.valueReason,
      name_other_site: params.nameOtherSite,
      suggestion: params.suggestion,
      reason: params.reason,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function createPaymentIntentOfSinglePremium(params) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiTemps}/premium/payment_intent`,
    data: {
      service_type: config.serviceType,
      country: 'cn',
      plan: params.plan,
      id: params.id,
    },
  };
  return axios(request);
}

function getCurrentPaymentIntent(paymentIntentId) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiTemps}/premium/payment_intent/status`,
    params: {
      service_type: config.serviceType,
      id: paymentIntentId,
    },
  };
  return axios(request);
}

function checkRegisterPremium() {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiTemps}/premium/check-register`,
    params: {
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

export {
  getPremiumPrice,
  getSinglePremiumPrice,
  cancel,
  upgradePremiumWithCredit,
  createPaymentIntentOfSinglePremium,
  getCurrentPaymentIntent,
  checkRegisterPremium,
};
