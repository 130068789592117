import { createSelector } from 'reselect';

const selectState = state => state;

const makeSelectSearchPhotoModal = () =>
  createSelector(
    selectState,
    state => state.searchPhotoModal
  );

export { makeSelectSearchPhotoModal };
