import { createSelector } from 'reselect';

const selectStateErrorMessageModal = state => state.errorMessageModal;

const makeSelectModal = () => createSelector(selectStateErrorMessageModal, errorMessageModalState => errorMessageModalState.modal);

const makeSelectTitle = () => createSelector(selectStateErrorMessageModal, errorMessageModalState => errorMessageModalState.title);

const makeSelectDescription = () => createSelector(selectStateErrorMessageModal, errorMessageModalState => errorMessageModalState.description);
const makeSelectSwitchAccount = () => createSelector(selectStateErrorMessageModal, errorMessageModalState => errorMessageModalState.switchAccount);

export {
  makeSelectModal,
  makeSelectTitle,
  makeSelectDescription,
  makeSelectSwitchAccount,
};
