import { put, call, takeEvery } from 'redux-saga/effects';
import {
  PRE_LOAD_NOTICE,
  LOAD_NOTICE,
  PRE_LOAD_MORE_NOTICE,
  LOAD_MORE_NOTICE,
} from './actions';
import * as NoticeService from '~/services/my-page/NoticeService';

function* getNotices(action) {
  try {
    const notices = yield call(NoticeService.getNotice, action.payload);
    yield put({ type: LOAD_NOTICE, notices });
  } catch (e) {
    console.log(e);
  }
}

function* loadMoreNotices(action) {
  try {
    const notices = yield call(NoticeService.getNotice, action.payload);
    yield put({ type: LOAD_MORE_NOTICE, notices });
  } catch (e) {
    console.log(e);
  }
}

export default function* noticeSaga() {
  yield takeEvery(PRE_LOAD_NOTICE, getNotices);
  yield takeEvery(PRE_LOAD_MORE_NOTICE, loadMoreNotices);
}
