import axios from './interceptor-axios';
import config from '~/config';

function getDataExtraLicense(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiPhoto}/extralicense/data`,
    params: {
      lang: params.lang || config.language,
    },
  };
  return axios(request);
}

/**
 *
 * @param params
 * @returns {Promise}
 */

export { getDataExtraLicense };
