import axios from './interceptor-axios';
import config from '~/config';

function getCategories(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/categories/name`,
    params: {
      service_type: config.serviceType,
      lang: params.language || config.language,
    },
  };
  return axios(request);
}

/**
 *
 * @param params
 * @returns {Promise}
 */
function getDataOfCategory(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/category/data`,
    params: {
      page: params.page || 1,
      slug: params.slug,
      lang: params.lang,
      ficonstype: params.ficonstype,
      download: params.download || 'desc',
      createdAt: params.createdAt,
      max_results: params.per_page || 100,
      per_page: params.per_page || 100,
      service_type: config.serviceType,
      clientIp: params.clientIp,
    },
  };
  return axios(request).then(
    data => data,
    error => Promise.reject(error),
  );
}

export { getCategories, getDataOfCategory };
