import PropTypes from 'prop-types';
import React from 'react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withI18next from '~/../hocs/_withI18next';
import Link from '~/components/I18nLink';
import config from '~/config';

const NUMBER_DISPLAYED_TAGS = 10;

@withI18next()
class PopularSearch extends React.PureComponent {
  render() {
    let { tags, t } = this.props;
    if (tags.length === 0) {
      return null;
    }
    const FIXED_TAGS_ILLUST = [
      {
        id: 1,
        tagName: 'Happy new year',
        link: '/free-illustrations-vectors/happy-new-year',
      },
      {
        id: 2,
        tagName: 'Christmas wreath',
        link: '/free-illustrations-vectors/christmas-wreath',
      },
      {
        id: 3,
        tagName: 'Christmas tree',
        link: '/free-illustrations-vectors/christmas-tree',
      },
    ];

    tags = tags.slice(0, NUMBER_DISPLAYED_TAGS);

    return (
      <div className="popular-search">
        {config.isIllustAC() ? (
          FIXED_TAGS_ILLUST.map((item) => (
            <Link href={item.link} as={item.link} key={item.id}>
              <a
                className="illust-popular-search-item"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    color: '#ffffff',
                    width: '16px',
                    height: '16px',
                    margin: '5px 0px 5px 14px',
                  }}
                />
                <span>{item.tagName}</span>
              </a>
            </Link>
          ))
        ) : (
          <>
            <span className="trending">{t('trending')}:</span>
            {tags.map((item, index) => (
              <Link key={index} href={`/search/${item}`} as={`/search/${item}`}>
                <a className="popular-search-item">
                  {item.toLowerCase()}
                  <span style={{ paddingRight: 4 }}>,</span>
                </a>
              </Link>
            ))}
            <Link href="/tags">
              <a className="popular-see-more">{t('See more').toLowerCase()}</a>
            </Link>
          </>
        )}

        <style jsx>
          {`
            .popular-search {
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              background-color: ${config.isIllustAC()
                ? 'none'
                : 'rgba(10, 10, 10, 0.48)'};
              padding: 14px;
              border-radius: 2px;
              margin: auto;
            }
            .illust-popular-search-item {
              background-color: rgba(0, 0, 0, 0.35);
              border-radius: 100px;
              display: flex;
              margin: 5px 7px;
              font-size: 14px;
              align-items: center;
              justify-content: center;
            }
            a.illust-popular-search-item:focus {
              text-decoration: none;
            }
            .illust-popular-search-item span {
              padding: 6px;
              padding-right: 18px;
              padding-left: 8px;
              color: #ffffff;
            }
            .trending {
              color: #fff;
              margin-right: 5px;
              font-weight: 200;
            }
            .popular-see-more {
              font-weight: 200;
              font-style: italic;
            }
            .popular-search a {
              color: #fff;
            }
            .popular-search a.popular-search-item {
              font-weight: 500;
            }
            .popular-search a.see-more-bg-photo_ac {
              background-color: #509bdc !important;
            }
            .popular-search a.see-more-bg-illust_ac {
              background-color: #d73a4e !important;
            }
            .popular-search a.see-more-bg-silhouette_ac {
              background-color: #41b9b8 !important;
            }
            .popular-search a.see-more-bg-design_ac {
              background-color: #ffa823 !important;
            }
          `}
        </style>
      </div>
    );
  }
}

PopularSearch.propTypes = {
  tags: PropTypes.array,
};

PopularSearch.defaultProps = {
  tags: [],
};

export default PopularSearch;
