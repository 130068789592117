import axios from './interceptor-axios';
import Config from '~/config';

function createBillingAgreement(params) {
  const isoDate = new Date();
  isoDate.setMinutes(isoDate.getMinutes() + 5);
  `${isoDate.toISOString().slice(0, 19)}Z`;

  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiTemps}/premium/billingagreement`,
    data: {
      service_type: Config.serviceType,
      plan: params.plan,
      lang: params.language || Config.language,
      iso_date: isoDate,
    },
  };

  return axios(request);
}

function paymentByStripe(opts) {
  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiDownload}/download/stripe`,
    data: {
      data_id: opts.id,
      data_size: opts.itemSelect.type,
      data_type: opts.itemSelect.format
        ? opts.itemSelect.format.toLowerCase()
        : opts.itemSelect.type,
      service_type: Config.serviceType,
      lang: opts.language || Config.language,
      currency: opts.currency,
      stripeToken: opts.stripeToken,
    },
  };
  return axios(request);
}

export { createBillingAgreement };
export { paymentByStripe };
