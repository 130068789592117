import React from 'react';
import { Table, Modal } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import I18nLink from '~/components/I18nLink';
import { withI18next } from '~/../hocs/_withI18next';
import { FormattedDate, FormattedNumber, FormattedTime } from '~/lib';

@withI18next()
class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, itemModal: {}, item: {} };
  }

  handleOpenModal = (item) => {
    this.props.openModal(item);
  };

  // handleShowImage(item) {
  //   this.setState({ showModal: true, item });
  // }

  close = () => {
    this.setState({ showModal: false });
  };

  renderBtnApproval(item) {
    const { t } = this.props;
    switch (item.status) {
      // case 1:
      //   return (
      //     <a
      //       className="mypage-text-primary"
      //       target="_blank"
      //       onClick={this.handleOpenModal.bind(this, item)}
      //     >
      //       {t('under_review')}
      //     </a>
      //   );
      case 1:
        return t('under_review');
      case 2:
        return <div>{t('approval')}</div>;
      case 3:
        return <div>{t('refusal_content')}</div>;
      case 4:
        return <div>{t('refuse_document_format_error')}</div>;
      default:
        return <div>{t('no_upload')}</div>;
    }
  }

  render() {
    const { t, list, link, downloadRelease } = this.props;
    return (
      <div>
        <Table responsive className="table-striped">
          <thead>
            <tr>
              <th className="text-center">{t('file name')}</th>
              <th className="text-center">{t('Control number')}</th>
              <th className="text-center">{t('Upload date and time')}</th>
              <th className="text-center">{t('Adapted material')}</th>
              <th className="text-center">{t('status')}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item) => (
              <tr key={item.id}>
                <td className="text-center">
                  <span
                    onClick={() => downloadRelease({ path: item.s3_path })}
                    className="mypage-text-primary"
                    role="button"
                    title={t('click_here_to_download')}
                  >
                    {item.name}
                  </span>
                </td>
                <td className="text-center">
                  <FormattedNumber value={item.id} />
                </td>
                <td className="text-center">
                  <FormattedDate
                    value={moment.unix(item.created_at)}
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                  &nbsp;
                  <FormattedTime
                    value={moment.unix(item.created_at)}
                    hour="numeric"
                    minute="numeric"
                    second="numeric"
                  />
                </td>
                <td className="text-center">
                  <I18nLink href={`${link}/${item.id}`}>
                    <a>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="cursor-pointer mypage-text-primary"
                      />
                    </a>
                  </I18nLink>
                </td>
                <td className="text-center">{this.renderBtnApproval(item)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Body>
            <div className="text-center">
              <img src={this.state.item.s3_path} alt={this.state.item.name} />
            </div>
          </Modal.Body>
        </Modal>
        <style jsx global>
          {`
            .text-ellipsis {
              width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .img-release {
              width: 50px;
              height: 50px;
              background-position: center;
              background-size: cover;
              border-radius: 5%;
            }
          `}
        </style>
      </div>
    );
  }
}

TableList.defaultProps = {
  list: [],
  openModal: () => ({}),
  downloadRelease: () => ({}),
  link: '',
};

TableList.propTypes = {
  openModal: PropTypes.func,
  downloadRelease: PropTypes.func,
  list: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
};

export default TableList;
