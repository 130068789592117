import {
  CHANGE_ACCOUNT_TYPE_SUCCESS, DOWNLOADER_ACCOUNT,
} from './constants';

export const initialState = DOWNLOADER_ACCOUNT;

function accountTypeProvider(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ACCOUNT_TYPE_SUCCESS:
      return action.accountType;
    default:
      return state;
  }
}

export default accountTypeProvider;
