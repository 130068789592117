import {
  LOAD_TOP_TAGS_SUCCESS,
  LOAD_TOP_TAGS_ERROR,
  CHANGE_PAGE,
} from './constants';

export const initialState = {
  topTags: [],
  hasNext: false,
  filter: '',
  page: 1,
  totalPage: 0,
  error: false,
  loading: false,
};

function TagsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TOP_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        topTags: action.collection,
        hasNext: action.has_next,
        page: action.page,
        totalPage: action.total_page,
        filter: action.filter,
      };
    case LOAD_TOP_TAGS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
}

export default TagsReducer;
