import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
import {
  LOAD_LATEST_REGISTERED_USERS,
  LOAD_LATEST_REGISTERED_USERS_ERROR,
  LOAD_LATEST_REGISTERED_USERS_SUCCESS,
  LOAD_REGISTERED_USERS,
  LOAD_REGISTERED_USERS_SUCCESS,
  LOAD_REGISTERED_USERS_ERROR,
} from './constants';
import { makeSelectLatestRegisteredUsers } from './selectors';
import * as companyService from '~/services/company';

function* loadLatestRegisteredUsers(action) {
  try {
    const latestRegisteredUsers = yield select(
      makeSelectLatestRegisteredUsers(),
    );
    const lastRegisteredUser =
      latestRegisteredUsers.length > 0 ? latestRegisteredUsers[0] : {};
    console.log('lastRegisteredUserrrrrrrrrrrrr', lastRegisteredUser);
    let response = yield call(companyService.getLatestRegisteredUsersDB, {
      last_registered_at: lastRegisteredUser.registered_at,
      last_log_id: lastRegisteredUser.id,
    });
    console.log('redisResponsessssssssss', response);
    if (response.logs.length === 0) {
      response = yield call(companyService.getLatestRegisteredUsersDB, {
        last_registered_at: lastRegisteredUser.registered_at,
        last_log_id: lastRegisteredUser.id,
      });
      console.log('dbResponsessssssssssssss', response);
    }

    yield put({
      type: LOAD_LATEST_REGISTERED_USERS_SUCCESS,
      payload: response.logs,
    });
  } catch (error) {
    yield put({ type: LOAD_LATEST_REGISTERED_USERS_ERROR });
  }
}

function* loadRegisteredUsers(action) {
  try {
    const response = yield call(companyService.getRegisteredUsers, {
      page: action.page,
      max_results: 10,
    });
    yield put({ type: LOAD_REGISTERED_USERS_SUCCESS, ...response });
  } catch (error) {
    yield put({ type: LOAD_REGISTERED_USERS_ERROR });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* logsRegisteredUsersSaga() {
  yield takeLatest(LOAD_LATEST_REGISTERED_USERS, loadLatestRegisteredUsers);
  yield takeLatest(LOAD_REGISTERED_USERS, loadRegisteredUsers);
}
