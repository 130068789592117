import React from 'react';
import Config from '~/config';

const NotAuthorized = () => (
  <div style={{ paddingTop: 150, paddingBottom: 200 }} className="text-center">
    <img
      style={{ maxWidth: '50%' }}
      src={`${Config.staticPrefix}/static/images/errors/403.png`}
      alt="logo error"
    />
    <h2>We are sorry</h2>
    <p>We are sorry, but you do not have access to this page or resource.</p>
  </div>
);

export default NotAuthorized;
