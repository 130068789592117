export const LOAD_TOKEN = 'containers/AuthProvider/LOAD_TOKEN';
export const CLEAR_TOKEN = 'containers/AuthProvider/CLEAR_TOKEN';

export const SET_AUTHENTICATE = 'containers/AuthProvider/SET_AUTHENTICATE';
export const LOGOUT = 'containers/AuthProvider/LOGOUT';
export const LOGIN = 'containers/AuthProvider/LOGIN';

export const UPDATE_PROFILE = 'containers/AuthProvider/UPDATE_PROFILE';
export const LOAD_PROFILE = 'containers/AuthProvider/LOAD_PROFILE';
export const CLEAR_PROFILE = 'containers/AuthProvider/CLEAR_PROFILE';

export const UPDATE_ROLE = 'containers/AuthProvider/UPDATE_ROLE';
export const UPDATE_STATUS = 'containers/AuthProvider/UPDATE_STATUS';

export const NO_UPLOAD = 0;
export const CHECKING = 1;
export const AUTHENTICATED = 2;
export const REJECTED = 3;
export const REJECTED_ADDRESS_MISMATCH = 4;
export const REJECTED_NAME_MISMATCH = 5;
export const REJECTED_MISSING_INFORMATION = 6;
export const REJECTED_IMAGE_BLUR = 7;
export const REJECTED_EXPIRED = 8;
export const REJECTED_PART_OF_IMAGE_IS_CUT = 9;
export const LOAD_SESSION_ID = 'containers/AuthProvider/LOAD_SESSION_ID';

export const CHECK_USER_PREMIUM = 'containers/AuthProvider/CHECK_USER_PREMIUM';
