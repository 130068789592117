import { createSelector } from 'reselect';

const selectState = (state) => state.extraLicense.modal;

const selectELPlans = (state) => state.extraLicense.result;

const selectLoading = (state) => state.extraLicense.load;

const makeSelectModal = () =>
  createSelector(selectState, (state) => state.showModal);

const makeSelectLoadingExtraLicensePayment = () =>
  createSelector(selectLoading, (loading) => loading);

const makeSelectElPlans = () =>
  createSelector(selectELPlans, (eLPlans) => eLPlans.el_plans);

const makeSelectPlanOfSingleLicense = () =>
  createSelector(selectELPlans, (eLPlans) => eLPlans.planOfSingleLicense);

export {
  makeSelectModal,
  makeSelectLoadingExtraLicensePayment,
  makeSelectElPlans,
  makeSelectPlanOfSingleLicense,
};
