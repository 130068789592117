import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { reducer as form } from 'redux-form';
// import postPhoto from '~/containers/MyPage/PostPhoto/reducer';
// import subscriptionHistory from '~/containers/MyPage/SubscriptionHistory/reducer';
import auth from '~/containers/AuthProvider/reducer';
import accountType from '~/containers/AccountTypeProvider/reducer';
import categoryPage from '~/containers/CategoryPage/reducer';
import collections from '~/containers/CollectionPage/reducer';
import collection from '~/containers/CollectionPageDetail/reducer';
import extraLicenseLandingPage from '~/containers/ExtraLicense/reducer';
import errorMessageModal from '~/containers/ErrorMessageModal/reducer';
import home from '~/containers/HomePage/reducer';
import language from '~/containers/Language/reducers';
import loading from '~/containers/Loading/reducer';
import searchPhotoModal from '~/containers/SearchPhotoModal/reducer';
import payment from '~/containers/PaymentModal/reducer';
import metadata from '~/containers/MetadataProvider/reducer';
import notice from '~/containers/MyPage/Notice/reducer';
import noticeCookie from '~/containers/NoticeCookie/reducer';
import photo from '~/containers/PhotoPage/reducer';
import recommendPage from '~/containers/RecommendPage/reducer';
import popularPage from '~/containers/PopularPage/reducer';
import newPage from '~/containers/NewPage/reducer';
import pricePremium from '~/containers/RegisterPremiumPage/reducer';
import search from '~/containers/Search/reducer';
import searchResult from '~/containers/SearchResultPage/reducer';
import similarPage from '~/containers/SimilarPage/reducer';
import topTags from '~/containers/Tags/reducer';
import tagDetail from '~/containers/TagDetail/reducer';
import customErrorMessage from '~/containers/CustomErrorMessage/reducer';
import premiumPage from '~/containers/PremiumPage/reducer';
import card from '~/containers/CardModal/reducer';
import invoice from '~/containers/InvoiceCustomizeModal/reducer';
import categories from '~/containers/CategoriesPage/reducer';
// import translation from '~/containers/TranslationForm/reducer';
import logsRegisteredUsers from '~/containers/LogsRegisteredUsers/reducer';
import creatorProfile from '~/containers/CreatorProfilePage/reducer';
import creatorsPage from '~/containers/CreatorsPage/reducer';
import layout from '~/containers/Layout/reducer';
import resetPassword from '~/containers/ResetPasswordPage/reducer';
import extraLicense from '~/containers/ExtraLicensePaymentModal/reducer';
import presentGift from '~/containers/MyPage/Present/reducer';
import comment from '~/containers/Comment/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    home,
    auth,
    metadata,
    search,
    loading,
    photo,
    searchPhotoModal,
    payment,
    errorMessageModal,
    searchResult,
    categoryPage,
    collection,
    collections,
    extraLicense,
    recommendPage,
    popularPage,
    newPage,
    // postPhoto,
    notice,
    similarPage,
    pricePremium,
    language,
    noticeCookie,
    topTags,
    tagDetail,
    customErrorMessage,
    premiumPage,
    // subscriptionHistory,
    card,
    invoice,
    categories,
    // translation,
    logsRegisteredUsers,
    creatorProfile,
    creatorsPage,
    layout,
    accountType,
    resetPassword,
    extraLicenseLandingPage,
    presentGift,
    comment,
    ...injectedReducers,
  });

  const rootReducerWithHydrate = (state, action) => {
    switch (action.type) {
      case HYDRATE:
        return { ...state, ...action.payload };

      default: {
        return rootReducer(state, action);
      }
    }
  };

  return rootReducerWithHydrate;
}
