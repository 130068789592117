import React from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeVariables from '~/shared/themeVariables';

class DropdownBottomByClick extends React.Component {
  static defaultProps = {
    align: 'left',
  };
  state = {
    isMenuOpen: false,
  };

  handleToggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  };

  render() {
    const { header, position, children, style, align, noArrow } = this.props;
    const { isMenuOpen } = this.state;
    const { colorIcon } = this.props;
    return (
      <div className="dropdown-bottom" style={style}>
        <div className="dropdown-bottom-header" onClick={this.handleToggleMenu}>
          {header}{' '}
          {!noArrow && (
            <FontAwesomeIcon
              icon={faCaretDown}
              style={{
                width: '1em',
                marginLeft: 4,
                color: colorIcon || '#fff',
              }}
            />
          )}
        </div>
        {isMenuOpen && <div className="dropdown-bottom-menu">{children}</div>}
        <style jsx>
          {`
            .dropdown-bottom {
              width: fit-content;
              height: fit-content;
              color: ${themeVariables.color.dark};
            }

            .dropdown-bottom-header {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: nowrap;
              cursor: pointer;
              width: 100%;
              height: 100%;
              padding: ${themeVariables.space.half}px;
            }

            .dropdown-bottom-menu {
              width: 100%;
              background: #fff;
            }
          `}
        </style>
      </div>
    );
  }
}

export default DropdownBottomByClick;
