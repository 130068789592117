import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import I18nLink from '../I18nLink';
import I18nRouter from '../I18nRouter';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import { urls } from '~/utils';

@withI18next()
class Pagination extends React.PureComponent {
  state = {
    pageValue: this.props.currentPage,
  };

  componentDidUpdate(prevProps) {
    const { currentPage } = this.props;
    if (currentPage !== prevProps.currentPage) {
      this.setState({ pageValue: currentPage });
    }
  }

  handleChangePage = (event) => {
    this.setState({ pageValue: event.target.value });
  };

  handleSubmitPage = (event) => {
    if (event.key && event.key === 'Enter') {
      const { totalPage, path, asPath, query } = this.props;
      let { pageValue } = this.state;
      pageValue = parseInt(pageValue, 10);
      console.log('pageValueeeeeeeeee', pageValue, query);
      if (isNaN(pageValue)) return;
      if (pageValue > totalPage || pageValue < 1) return;
      const newQuery = { ...query, page: pageValue };
      const queryString = urls.convertToQueryStrings(newQuery);
      I18nRouter.push(
        { pathname: path, query: { ...newQuery, is_tag: true } },
        `${asPath}?${queryString}`,
      );
    }
  };

  onNextLink() {
    const { currentPage, totalPage, query, asPath } = this.props;
    if (currentPage >= totalPage) {
      return {};
    }
    const nextPage = currentPage + 1;
    const newQuery = { ...query, page: nextPage };
    const queryString = urls.convertToQueryStrings(newQuery);
    return {
      as: `${asPath}?${queryString}`,
      query: { ...newQuery, is_tag: true },
    };
  }

  onPreviousLink() {
    const { currentPage, asPath, query } = this.props;
    if (currentPage <= 1) {
      return {};
    }
    const previousPage = currentPage - 1;
    const newQuery = { ...query, page: previousPage };
    const queryString = urls.convertToQueryStrings(newQuery);
    return {
      as: `${asPath}?${queryString}`,
      query: { ...newQuery, is_tag: true },
    };
  }

  render() {
    const {
      totalPage,
      currentPage,
      t,
      path,
      useButtonLink,
      onPrev,
      onNext,
    } = this.props;
    const { pageValue } = this.state;

    return (
      <div className="pagination-ac">
        <div className="title-page text-center">{t('Page')}</div>
        <div className="number-page">
          <input
            type="number"
            pattern="[0-9]*"
            min={1}
            max={totalPage}
            onChange={this.handleChangePage}
            onKeyDown={this.handleSubmitPage}
            value={pageValue}
            className="current-page form-control"
          />
        </div>
        <div className="total-page">
          &nbsp;/&nbsp;
          {totalPage || 1}
        </div>
        {useButtonLink ? (
          <I18nLink
            className={`${Config.getPrefixContent('btn-ac-primary')}`}
            href={{ pathname: path, query: this.onPreviousLink().query }}
            as={this.onPreviousLink().as}
          >
            <a
              className={`btn btn-prev-${Config.serviceType} ${
                +currentPage === 1 ? 'disabled' : ''
              }`}
            >
              <FontAwesomeIcon style={{ width: '1em' }} icon={faChevronLeft} />
            </a>
          </I18nLink>
        ) : (
          <button
            className={`btn btn-prev-${Config.serviceType} ${
              +currentPage === 1 ? 'disabled' : ''
            }`}
            disabled={currentPage === 1}
            onClick={() => onPrev(+currentPage - 1)}
          >
            <FontAwesomeIcon style={{ width: '1em' }} icon={faChevronLeft} />
          </button>
        )}

        {useButtonLink ? (
          <I18nLink
            href={{ pathname: path, query: this.onNextLink().query }}
            as={this.onNextLink().as}
          >
            <a
              className={`btn btn-next-${Config.serviceType} ${
                +this.props.currentPage >= this.props.totalPage
                  ? 'disabled'
                  : ''
              }`}
            >
              <FontAwesomeIcon style={{ width: '1em' }} icon={faChevronRight} />
            </a>
          </I18nLink>
        ) : (
          <button
            className={`btn btn-next-${Config.serviceType} ${
              +this.props.currentPage >= this.props.totalPage ? 'disabled' : ''
            }`}
            disabled={this.props.currentPage >= this.props.totalPage}
            onClick={() => onNext(+currentPage + 1)}
          >
            <FontAwesomeIcon style={{ width: '1em' }} icon={faChevronRight} />
          </button>
        )}

        <style jsx>
          {`
            .pagination-ac {
              display: inline-flex;
              align-items: center;
            }
            .pagination-ac .title-page {
              min-width: 40px;
              color: #697077;
              margin-left: 7px;
              margin-right: 7px;
            }
            .pagination-ac .btn-prev-illust_ac,
            .pagination-ac .btn-next-illust_ac {
              border: 1px solid #ececec;
              background: #fff;
              height: 34px;
              margin-bottom: 2px;
              margin-left: 7px;
              padding-top: 5px;
              padding-left: 10px;
              padding-right: 10px;
              color: #d73a4e;
              outline: none;
            }
            .pagination-ac .btn-prev-illust_ac:hover,
            .pagination-ac .btn-prev-illust_ac:active,
            .pagination-ac .btn-prev-illust_ac:focus,
            .pagination-ac .btn-prev-illust_ac:active,
            .pagination-ac .btn-next-illust_ac:hover,
            .pagination-ac .btn-next-illust_ac:active,
            .pagination-ac .btn-next-illust_ac:focus,
            .pagination-ac .btn-next-illust_ac:active {
              color: #d73a4e;
              outline: none;
            }
            .pagination-ac .btn-prev-illust_ac:disabled,
            .pagination-ac .btn-next-illust_ac:disabled {
              color: #1b2833;
            }
            .pagination-ac .btn-prev-design_ac,
            .pagination-ac .btn-next-design_ac {
              border: 1px solid #ececec;
              background: #fff;
              height: 34px;
              margin-bottom: 2px;
              margin-left: 7px;
              padding-top: 5px;
              padding-left: 10px;
              padding-right: 10px;
              color: #ffa823;
              outline: none;
            }
            .pagination-ac .btn-prev-design_ac:hover,
            .pagination-ac .btn-prev-design_ac:active,
            .pagination-ac .btn-prev-design_ac:focus,
            .pagination-ac .btn-prev-design_ac:active,
            .pagination-ac .btn-next-design_ac:hover,
            .pagination-ac .btn-next-design_ac:active,
            .pagination-ac .btn-next-design_ac:focus,
            .pagination-ac .btn-next-design_ac:active {
              color: #ffa823;
              outline: none;
            }
            .pagination-ac .btn-prev-design_ac:disabled,
            .pagination-ac .btn-next-design_ac:disabled {
              color: #1b2833;
            }
            .pagination-ac .btn-prev-photo_ac,
            .pagination-ac .btn-next-photo_ac {
              border: 1px solid #ececec;
              background: #fff;
              height: 34px;
              margin-bottom: 2px;
              margin-left: 7px;
              padding-top: 5px;
              padding-left: 10px;
              padding-right: 10px;
              color: #509bdc;
              outline: none;
            }
            .pagination-ac .btn-prev-photo_ac:hover,
            .pagination-ac .btn-prev-photo_ac:active,
            .pagination-ac .btn-prev-photo_ac:focus,
            .pagination-ac .btn-prev-photo_ac:active,
            .pagination-ac .btn-next-photo_ac:hover,
            .pagination-ac .btn-next-photo_ac:active,
            .pagination-ac .btn-next-photo_ac:focus,
            .pagination-ac .btn-next-photo_ac:active {
              color: #509bdc;
              outline: none;
            }
            .pagination-ac .btn-prev-photo_ac:disabled,
            .pagination-ac .btn-next-photo_ac:disabled {
              color: #1b2833;
            }
            .pagination-ac .btn-prev-silhouette_ac,
            .pagination-ac .btn-next-silhouette_ac {
              border: 1px solid #ececec;
              background: #fff;
              height: 34px;
              margin-bottom: 2px;
              margin-left: 7px;
              padding-top: 5px;
              padding-left: 10px;
              padding-right: 10px;
              color: #41b9b8;
              outline: none;
            }
            .pagination-ac .btn-prev-silhouette_ac:hover,
            .pagination-ac .btn-prev-silhouette_ac:active,
            .pagination-ac .btn-prev-silhouette_ac:focus,
            .pagination-ac .btn-prev-silhouette_ac:active,
            .pagination-ac .btn-next-silhouette_ac:hover,
            .pagination-ac .btn-next-silhouette_ac:active,
            .pagination-ac .btn-next-silhouette_ac:focus,
            .pagination-ac .btn-next-silhouette_ac:active {
              color: #41b9b8;
              outline: none;
            }
            .pagination-ac .btn-prev-silhouette_ac:disabled,
            .pagination-ac .btn-next-silhouette_ac:disabled {
              color: #1b2833;
            }
            .pagination-ac .current-page {
              text-align: center;
              padding: 6px 2px;
              border: 1px solid #ececec;
            }
            .pagination-ac input[type='number'] {
              text-align: center;
            }
            .pagination-ac input[type='number']::-webkit-inner-spin-button,
            .pagination-ac input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              /*-moz-appearance: none;*/
              appearance: none;
              margin: 0;
            }
            .pagination-ac input[type='number'] {
              -moz-appearance: textfield;
            }
            .pagination-ac .total-page {
              margin-left: 4px;
              color: #697077;
              white-space: nowrap;
            }
            .pagination-ac .number-page {
              width: 50px;
              color: #697077;
            }
            .pagination-ac .number-page input {
              border-radius: 2px;
            }
            .pagination-ac .btn-next .disabled,
            .pagination-ac .btn-prev .disabled {
              pointer-events: none;
            }
          `}
        </style>
      </div>
    );
  }
}

Pagination.defaultProps = {
  currentPage: 1,
  totalPage: 1,
  query: {},
  asPath: '',
  path: '',
  onChange: () => ({}),
  onBlur: () => ({}),
  onNext: () => ({}),
  onPrev: () => ({}),
  useButtonLink: true,
};
Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  asPath: PropTypes.string,
  path: PropTypes.string,
  query: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  useButtonLink: PropTypes.bool,
};
export default Pagination;
