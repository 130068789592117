export const LOAD_SLIDER_IMAGES = 'containers/HomePage/LOAD_SLIDER_IMAGES';
export const LOAD_SLIDER_IMAGES_SUCCESS =
  'containers/HomePage/LOAD_SLIDER_IMAGES_SUCCESS';
export const LOAD_SLIDER_IMAGES_ERROR =
  'containers/HomePage/LOAD_SLIDER_IMAGES_ERROR';

export const LOAD_PATTERN = 'containers/HomePage/LOAD_PATTERN';
export const LOAD_PATTERN_SUCCESS = 'containers/HomePage/LOAD_PATTERN_SUCCESS';
export const LOAD_PATTERN_ERROR = 'containers/HomePage/LOAD_PATTERN_ERROR';

export const LOAD_RECOMMEND_LIST = 'containers/HomePage/LOAD_RECOMMEND_LIST';
export const LOAD_RECOMMEND_LIST_SUCCESS =
  'containers/HomePage/LOAD_RECOMMEND_LIST_SUCCESS';
export const LOAD_RECOMMEND_LIST_ERROR =
  'containers/HomePage/LOAD_RECOMMEND_LIST_ERROR';

export const CHANGE_PATTERN = 'containers/HomePage/CHANGE_PATTERN';
export const SELECTED_PATTERN = 'containers/HomePage/SELECTED_PATTERN';

export const LOAD_COUNT_DOWNLOAD_RUN =
  'containers/HomePage/LOAD_COUNT_DOWNLOAD_RUN';
export const LOAD_COUNT_DOWNLOAD_RUN_SUCCESS =
  'containers/HomePage/LOAD_COUNT_DOWNLOAD_RUN_SUCCESS';
export const LOAD_COUNT_DOWNLOAD_RUN_ERROR =
  'containers/HomePage/LOAD_COUNT_DOWNLOAD_RUN_ERROR';

export const LOAD_COUNT_DATA = 'containers/HomePage/LOAD_COUNT_DATA';
export const LOAD_COUNT_DATA_SUCCESS =
  'containers/HomePage/LOAD_COUNT_DATA_SUCCESS';
export const LOAD_COUNT_DATA_ERROR =
  'containers/HomePage/LOAD_COUNT_DATA_ERROR';

export const LOAD_HOMEPAGE_DATA = 'containers/HomePage/LOAD_HOMEPAGE_DATA';

export const LOAD_TAGS = 'containers/HomePage/LOAD_TAGS';
export const LOAD_TAGS_SUCCESS = 'containers/HomePage/LOAD_TAGS_SUCCESS';
export const LOAD_TAGS_ERROR = 'containers/HomePage/LOAD_TAGS_ERROR';

export const LOAD_COUNT_USER = 'containers/HomePage/LOAD_COUNT_USER';
export const LOAD_COUNT_USER_SUCCESS =
  'containers/HomePage/LOAD_COUNT_USER_SUCCESS';
export const LOAD_COUNT_USER_ERROR =
  'containers/HomePage/LOAD_COUNT_USER_ERROR';

export const LOAD_DATA_TYPE = 'containers/HomePage/LOAD_DATA_TYPE';
export const LOAD_DATA_TYPE_SUCCESS =
  'containers/HomePage/LOAD_DATA_TYPE_SUCCESS';
export const LOAD_DATA_TYPE_ERROR = 'containers/HomePage/LOAD_DATA_TYPE_ERROR';

export const SET_IS_TOP_BANNER_VISIBLE =
  'containers/HomePage/SET_IS_TOP_BANNER_VISIBLE';

export const LOAD_RANDOM_SLIDER_IMAGES =
  'containers/HomePage/LOAD_RANDOM_SLIDER_IMAGES';
export const LOAD_RANDOM_SLIDER_IMAGES_SUCCESS =
  'containers/HomePage/LOAD_RANDOM_SLIDER_IMAGES_SUCCESS';
export const LOAD_RANDOM_SLIDER_IMAGES_ERROR =
  'containers/HomePage/LOAD_RANDOM_SLIDER_IMAGES_ERROR';

export const LOAD_COLLECTIONS_OF_HOMEPAGE =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_HOMEPAGE';
export const LOAD_COLLECTIONS_OF_HOMEPAGE_SUCCESS =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_HOMEPAGE_SUCCESS';
export const LOAD_COLLECTIONS_OF_HOMEPAGE_ERROR =
  'containers/CollectionPage/LOAD_COLLECTIONS_OF_HOMEPAGE_ERROR';

export const LOAD_DATA_BLOGS = 'containers/HomePage/LOAD_DATA_BLOGS';
export const LOAD_DATA_BLOGS_SUCCESS =
  'containers/HomePage/LOAD_DATA_BLOGS_SUCCESS';
export const LOAD_DATA_BLOGS_ERROR =
  'containers/HomePage/LOAD_DATA_BLOGS_ERROR';
