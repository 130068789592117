import config from '~/config';
import { SERVICE_TYPES } from '~/utils/constants';

const fontSize = {
  small: 12,
  base: 14,
  medium: 16,
  large: 24,
  extraLarge: 48,

  headerOne: 64,
  headerTwo: 48,
  headerThree: 24,
  subtitle: 24,
};

const space = {
  extraSmall: 4,
  small: 8,
  half: 12,
  base: 24,
  medium: 32,
  large: 48,
};

const colorsByServiceType = {
  [SERVICE_TYPES.PHOTO_AC]: {
    primary: 'rgb(80, 155, 220)',
  },
  [SERVICE_TYPES.ILLUST_AC]: {
    primary: 'rgb(215, 58, 78)',
  },
  [SERVICE_TYPES.SILHOUETTE_AC]: {
    primary: 'rgb(65, 185, 184)',
  },
  [SERVICE_TYPES.DESIGN_AC]: {
    primary: 'rgb(255, 168, 35)',
  },
};

// TODO: Change themeVariables.color.dark to themeVariables.colors.bodyText
const color = {
  white: '#fff',
  gray: '#f2f3f4',
  darkGray: '#c0c0c0',
  semiDark: '#3e3e3f',
  dark: '#1b2833',
  colorIllustPrimary: 'rgb(215, 58, 78)',
  colorPhotoPrimary: 'rgb(80, 155, 220)',
  colorSilhouettePrimary: 'rgb(65, 185, 184)',
  colorDesignPrimary: 'rgb(255, 168, 35)',
  ...colorsByServiceType[config.serviceType],
};
const serviceColors = {
  [SERVICE_TYPES.PHOTO_AC]: {
    primary: '#1d7ab3',
    tertiaryBrand: '#95BEE1',
    popupBackground: '#21405C',
  },
  [SERVICE_TYPES.ILLUST_AC]: {
    primary: '#d73a4e',
    popupBackground: '#571720',
  },
  [SERVICE_TYPES.SILHOUETTE_AC]: {
    primary: '#41b9b8',
  },
  [SERVICE_TYPES.DESIGN_AC]: {
    primary: '#ffa823',
  },
};
const commonColors = {
  success: '#31D0AA',
  error: '#EE1818',
  bodyText: '#1B2833',
  subtleBackground: '#ECF1F4',
  lightBackground: '#F8F9FA',
  white: '#fff',
  link: '#3a4ed7',
};

const colors = {
  ...commonColors,
  ...serviceColors[config.serviceType],
};

const breakpoints = {
  mobile: {
    maxWidth: 599,
    space: 16,
  },
  tabletS: {
    minWidth: 600,
    maxWidth: 904,
    space: 16,
  },
  tabletL: {
    minWidth: 905,
    maxWidth: 1239,
    space: 32,
  },
  desktopMedium: {
    minWidth: 1240,
    maxWidth: 1439,
    space: 96,
  },
  desktopLarge: {
    minWidth: 1440,
  },
};

// TODO: Replace color by colors
// TODO: Add breakpoints
export default { fontSize, space, color, colors, breakpoints };
