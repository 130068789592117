import React from 'react';
import PropTypes from 'prop-types';

class Process extends React.Component {
  render() {
    return (
      <div className={`pt15 ${this.props.show ? '' : 'hide'}`}>
        <div className="process" />
        <style jsx>
          {`
            .process {
              background: linear-gradient(
                to right,
                #ea118c,
                #2ec626,
                #5ac8fa,
                #d21f1f,
                #007aff,
                #34aadc,
                #5856d6,
                #d2a71f,
                #ff2d55
              );
              background-size: 900% 100%;
              -webkit-animation: AnimationName 5s ease infinite;
              -moz-animation: AnimationName 5s ease infinite;
              animation: AnimationName 5s ease infinite;
              height: 5px;
            }

            @-webkit-keyframes AnimationName {
              0% {
                background-position: 0 50%;
              }
              50% {
                background-position: 100% 51%;
              }
              100% {
                background-position: 0 50%;
              }
            }
            @-moz-keyframes AnimationName {
              0% {
                background-position: 0 50%;
              }
              50% {
                background-position: 100% 51%;
              }
              100% {
                background-position: 0 50%;
              }
            }
            @keyframes AnimationName {
              0% {
                background-position: 0 50%;
              }
              50% {
                background-position: 100% 51%;
              }
              100% {
                background-position: 0 50%;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

Process.defaultProps = {
  show: false,
};

Process.propTypes = {
  show: PropTypes.bool,
};

export default Process;
