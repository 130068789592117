import React from 'react';
import moment from 'moment';

class FormattedNumber extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const format = this.props.format || 'LL';
        return (
            <span>{moment(this.props.value).format(format)}</span>
        );
    }
}

export default FormattedNumber;
