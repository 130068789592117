export const LOAD_COMMENTS_DATA = 'containers/Comment/LOAD_COMMENTS_DATA';
export const LOAD_COMMENTS_DATA_SUCCESS =
  'containers/Comment/LOAD_COMMENTS_DATA_SUCCESS';
export const LOAD_COMMENTS_DATA_ERROR =
  'containers/Comment/LOAD_COMMENTS_DATA_ERROR';

export const LOAD_APPEND_COMMENTS_DATA =
  'containers/Comment/LOAD_APPEND_COMMENTS_DATA';
export const LOAD_APPEND_COMMENTS_DATA_SUCCESS =
  'containers/Comment/LOAD_APPEND_COMMENTS_DATA_SUCCESS';
export const LOAD_APPEND_COMMENTS_DATA_ERROR =
  'containers/Comment/LOAD_APPEND_COMMENTS_DATA_ERROR';

export const POST_COMMENT = 'containers/Comment/POST_COMMENT';
export const POST_COMMENT_SUCCESS = 'containers/Comment/POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'containers/Comment/POST_COMMENT_ERROR';

export const LOAD_REPLY_COMMENTS_DATA =
  'containers/Comment/LOAD_REPLY_COMMENTS_DATA';
export const LOAD_REPLY_COMMENTS_DATA_SUCCESS =
  'containers/Comment/LOAD_REPLY_COMMENTS_DATA_SUCCESS';
export const LOAD_REPLY_COMMENTS_DATA_ERROR =
  'containers/Comment/LOAD_REPLY_COMMENTS_DATA_ERROR';

export const LOAD_MORE_REPLY_COMMENTS_DATA =
  'containers/Comment/LOAD_MORE_REPLY_COMMENTS_DATA';
export const LOAD_MORE_REPLY_COMMENTS_DATA_SUCCESS =
  'containers/Comment/LOAD_MORE_REPLY_COMMENTS_DATA_SUCCESS';
export const LOAD_MORE_REPLY_COMMENTS_DATA_ERROR =
  'containers/Comment/LOAD_MORE_REPLY_COMMENTS_DATA_ERROR';

export const POST_REPLY_COMMENT = 'containers/Comment/POST_REPLY_COMMENT';
export const POST_REPLY_COMMENT_SUCCESS =
  'containers/Comment/POST_REPLY_COMMENT_SUCCESS';
export const POST_REPLY_COMMENT_ERROR =
  'containers/Comment/POST_REPLY_COMMENT_ERROR';

export const POST_LIKE_COMMENT = 'containers/Comment/POST_LIKE_COMMENT';
export const POST_LIKE_COMMENT_SUCCESS =
  'containers/Comment/POST_LIKE_COMMENT_SUCCESS';
export const POST_LIKE_COMMENT_ERROR =
  'containers/Comment/POST_LIKE_COMMENT_ERROR';
