import {
  LOAD_MORE_NOTICE,
  LOAD_NOTICE,
  PRE_LOAD_MORE_NOTICE,
  PRE_LOAD_NOTICE,
  TOGGLE_CONTENT_NOTICE,
} from './actions';

export const initialState = {
  collection: [],
  has_next: false,
  max_results: 10,
  page: 1,
  total_item: 0,
  total_page: 0,
  loading: true,
  load_more: false,
};

const noticeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PRE_LOAD_NOTICE:
      return { ...state, loading: true };
    case LOAD_NOTICE:
      return { ...state, ...action.notices, loading: false };
    case PRE_LOAD_MORE_NOTICE:
      return { ...state, load_more: true };
    case LOAD_MORE_NOTICE:
      return {
        ...state,
        ...action.notices,
        collection: state.collection.concat(action.notices.collection),
        load_more: false,
      };
    case TOGGLE_CONTENT_NOTICE:
      return {
        ...state,
        collection: state.collection.map((item, index) => {
          if (index === action.index) {
            return { ...item, open: !item.open };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default noticeReducer;
