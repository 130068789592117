import {
  LOAD_DATA_COLLECTION_BY_ID,
  // LOAD_DATA_COLLECTION_PHOTO,
  LOAD_COLLECTIONS_OF_MONTHS,
  NICE_PHOTO,
} from './constants';

export function loadDataCollectionById(payload, api) {
  return {
    type: LOAD_DATA_COLLECTION_BY_ID,
    payload,
    api,
  };
}

export function loadCollectionOfMonths(payload, api) {
  return {
    type: LOAD_COLLECTIONS_OF_MONTHS,
    payload,
    api,
  };
}
export function nicePhoto(value) {
  return {
    type: NICE_PHOTO,
    value,
  };
}
