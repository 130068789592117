import { LOADING_HIDE, LOADING_SHOW } from './constants';

export const initialState = {
  loading: false,
  message: '',
  isSearchingByPhoto: false,
};

function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_SHOW:
      return {
        ...state,
        loading: true,
        message: action.message,
        isSearchingByPhoto: action.isSearchingByPhoto,
      };

    case LOADING_HIDE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default loadingReducer;
