import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import { makeSelectDownloadingCertificate } from '~/containers/MyPage/DownloadHistory/selectors';
import { downloadExtraLicenseCertificate } from '~/containers/MyPage/DownloadHistory/actions';

@withI18next()
class ButtonCertificate extends React.Component {
  handleDownloadCertificate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const {
      dataId,
      logId,
      downloadExtraLicenseCertificate,
      onClick,
    } = this.props;
    downloadExtraLicenseCertificate({ dataId, logId });
    if (onClick) onClick(e);
  };

  render() {
    const { t, className, downloadingCertificate, dataId } = this.props;
    const loading = downloadingCertificate[dataId];
    return (
      <button
        className={`btn btn-ac-primary btn-${Config.serviceType} ${className}`}
        onClick={this.handleDownloadCertificate}
        disabled={loading}
      >
        {loading ? (
          <FontAwesomeIcon
            className="icon-download-certificate"
            style={{ width: '1em' }}
            icon={faSpinner}
            pulse
          />
        ) : (
          <FontAwesomeIcon
            className="icon-download-certificate"
            style={{ width: '1em' }}
            icon={faDownload}
          />
        )}
        {t('certificate')}
        <style jsx>
          {`
            .icon-download-certificate {
              width: 24px;
              margin-right: 5px;
            }
          `}
        </style>
      </button>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  downloadingCertificate: makeSelectDownloadingCertificate(),
});
const mapDispatchToProps = (dispatch) => ({
  downloadExtraLicenseCertificate: (payload) =>
    dispatch(downloadExtraLicenseCertificate(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ButtonCertificate);
