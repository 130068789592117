export const PRE_LOAD_DOWNLOADED = 'PRE_LOAD_DOWNLOADED';

export const LOAD_DOWNLOADED = 'LOAD_DOWNLOADED';
export const SELECTED_PHOTO_DOWNLOADED = 'SELECTED_PHOTO_DOWNLOADED';
export const SELECTED_ALL_PHOTO_DOWNLOADED = 'SELECTED_ALL_PHOTO_DOWNLOADED';
export const CHANGE_CURRENT_PAGE_DOWNLOADED = 'CHANGE_CURRENT_PAGE_DOWNLOADED';
export const DELETE_PHOTO_DOWNLOADED = 'DELETE_PHOTO_DOWNLOADED';
export const DELETED_PHOTO_DOWNLOADED = 'DELETED_PHOTO_DOWNLOADED';
export const DOWNLOAD_PHOTO_DOWNLOADED = 'DOWNLOAD_PHOTO_DOWNLOADED';
export const DOWNLOAD_EXTRA_LICENSE_CERTIFICATE =
  'DOWNLOAD_EXTRA_LICENSE_CERTIFICATE';
export const DOWNLOAD_EXTRA_LICENSE_CERTIFICATE_COMPLETED =
  'DOWNLOAD_EXTRA_LICENSE_CERTIFICATE_COMPLETED';

export function getDownloadedPhotos(params) {
  return { type: PRE_LOAD_DOWNLOADED, ...params };
}

export function downloadExtraLicenseCertificate(payload) {
  return {
    type: DOWNLOAD_EXTRA_LICENSE_CERTIFICATE,
    payload,
  };
}
