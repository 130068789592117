import { withResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Image from './Image';
import GoogleAds from '../GoogleAds';
import PremiumAds from '../PremiumAds';

const mapStateToProps = createStructuredSelector({
  profile: (state) => state.auth.profile,
});

@connect(mapStateToProps)
class Gallery extends Component {
  rebuildImages(images = [], prepareLoad) {
    return images.map((image) => ({ ...image, prepareLoad }));
  }

  componentDidMount() {
    this.onResize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.width !== prevProps.width) {
      this.onResize();
    }
  }

  onResize() {
    if (!this._gallery) return;
    const { width } = this.props;
    if (!width) return;

    this.setState({
      containerWidth: width,
      // thumbnails: this.renderThumbs(width),
    });
  }

  getClientWidth() {
    if (this._gallery) {
      return Math.floor(this._gallery.clientWidth) - 1;
    }
    return 1000;
  }

  calculateCutOff(len, delta, items) {
    const cutoff = [];
    let cutsum = 0;
    for (const i in items) {
      const item = items[i];
      const fractOfLen = item.scaleWidth / len;
      cutoff[i] = Math.floor(fractOfLen * delta);
      cutsum += cutoff[i];
    }

    let stillToCutOff = delta - cutsum;
    while (stillToCutOff > 0) {
      for (const i in cutoff) {
        cutoff[i]++;
        stillToCutOff--;
        if (stillToCutOff < 0) break;
      }
    }
    return cutoff;
  }

  buildImageRow(items, containerWidth) {
    let row = [];
    let len = 0;
    const imgMargin = 2 * this.props.margin;
    while (items.length > 0 && len < containerWidth) {
      const item = items.shift();
      row.push(item);
      len += item.scaleWidth + imgMargin;
    }

    const delta = len - containerWidth;
    if (row.length > 0 && delta > 0) {
      const cutoff = this.calculateCutOff(len, delta, row);
      for (const i in row) {
        const pixelsToRemove = cutoff[i];
        const item = row[i];
        item.marginLeft = -Math.abs(Math.floor(pixelsToRemove / 2));
        item.vwidth = item.scaleWidth - pixelsToRemove;
      }
    } else if (this.props.lastRowHide) {
      row = [];
    } else {
      for (const j in row) {
        const item = row[j];
        item.marginLeft = 0;
        item.vwidth = item.scaleWidth;
      }
    }
    return row;
  }

  setThumbScale(item) {
    let ratio = 1;
    if (item.meta_data) {
      ratio = item.meta_data.width / item.meta_data.height;
    }
    item.scaleWidth = Math.floor(this.props.rowHeight * ratio);
  }

  renderThumbs = (containerWidth, images) => {
    const { images: originalImages, prepareLoad } = this.props;
    images = this.rebuildImages(originalImages, prepareLoad);

    if (!images) return [];
    if (containerWidth === 0) return [];
    const items = images.slice();
    for (const t in items) {
      this.setThumbScale(items[t]);
    }

    const thumbs = [];
    const rows = [];
    // let count = 0;
    // const idAds = 0;

    while (items.length > 0) {
      // if (count > 0 && count % 2 === 0 && profile.role !== Config.getRoles().PREMIUM) {
      //   rows.push([
      //     {
      //       id: idAds,
      //       type: 'ads',
      //     },
      //   ]);
      //   idAds += 1;
      // }
      // count += 1;
      rows.push(this.buildImageRow(items, containerWidth));
    }

    for (const r in rows) {
      for (const i in rows[r]) {
        const item = rows[r][i];
        if (this.props.maxRows) {
          if (r < this.props.maxRows) {
            thumbs.push(item);
          }
        } else {
          thumbs.push(item);
        }
      }
    }
    return thumbs;
  };

  render() {
    const {
      showImageActions,
      api,
      profile,
      forcePremium,
      rowHeight,
      colWidth,
      margin,
      hideSimilar,
      t,
      prepareLoad,
      images: originalImages,
      page,
    } = this.props;

    const images = this.rebuildImages(originalImages, prepareLoad);
    const thumbnails = this.renderThumbs(this.getClientWidth(), images);

    return (
      <div className="gallery-container">
        <div
          className="gallery-images"
          id={this.props.id}
          ref={(c) => (this._gallery = c)}
        >
          {thumbnails.map((item, keyIndex) => {
            if (item.type === 'ads' && item.id === 0) {
              return <PremiumAds />;
            }

            if (item.type === 'ads') {
              return (
                <div className="gallery-ads-container" key={item.id}>
                  <GoogleAds id={`div-gpt-ad-1561445773754-${item.id}`} />
                </div>
              );
            }
            return (
              <Image
                key={item.id}
                item={item}
                index={item.id}
                vwidth={item.vwidth}
                margin={margin}
                width={colWidth}
                height={rowHeight}
                premiumOnly={!!item.premium_only}
                role={profile.role}
                forcePremium={forcePremium}
                showActions={showImageActions}
                api={api}
                hideSimilar={hideSimilar}
                t={t}
                keyIndex={keyIndex}
                page={page}
              />
            );
          })}
          <div style={{ clear: 'both' }} />
        </div>
        <style jsx>
          {`
            .gallery-container {
              max-width: 100%;
            }

            .gallery-images {
              max-width: 100%;
            }
          `}
        </style>
        <style jsx global>
          {`
            .gallery-images .gallery-title .image-tag .ac-tag-item {
              color: white;
              padding: 4px;
              margin-right: 8px;
              margin-bottom: 4px;
              white-space: nowrap;
              background: hsla(0, 0%, 100%, 0.2);
            }
            .gallery-images .gallery-title .image-tag .ac-tag-item:hover {
              opacity: 0.9;
            }
            .gallery-images {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
            }
            .gallery-images .empty-image {
              height: 0;
              padding-top: 0;
              padding-bottom: 0;
              margin-top: 0;
              margin-bottom: 0;
            }
            .gallery-images .gallery-image-container {
              position: relative;
              float: left;
              cursor: pointer;
              background: #eee;
              padding: 0px;
              user-select: none;
              -webkit-user-select: none;
              border-radius: 2px;
              // filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
            }
            .gallery-images .gallery-ads-container {
              background-color: #f2f3f4;
              width: 100%;
              text-align: center;
            }
            .gallery-images .gallery-image-hover {
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              transition: 0.2s;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-radius: 2px;
              overflow: hidden;
            }
            .gallery-images .gallery-image-hover .image-actions-container {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              padding: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .image-actions {
              // min-width: 40px;
              // max-width: 100px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .action-button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 30px;
              margin-top: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .number-nices {
              padding-left: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .dropdown-menu__ac {
              width: 100%;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-arrow-left {
              border-width: 5px;
              border-right-width: 0;
              left: -5px;
              top: 11px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-wrapper-left {
              padding-right: 5px;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-wrapper {
              right: 100%;
              bottom: 0;
              min-width: max-content;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .submenu-wrapper
              .submenu {
              min-width: max-content;
            }
            .gallery-images
              .gallery-image-hover
              .image-actions-container
              .dropdown-left-options {
              display: flex;
              flex-direction: row;
            }
            .gallery-images
              .gallery-image-hover
              .button-social-share
              .facebook {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              color: #3b5998;
            }
            .gallery-images .gallery-image-hover .button-social-share .twitter {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              color: #1da1f2;
              border-right-width: 0;
            }
            .gallery-images
              .gallery-image-hover
              .button-social-share
              .social-network-share {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
            }
            .gallery-images
              .gallery-image-hover
              .button-social-share
              .social-network-share:hover {
              color: #fff;
            }
            .button-select-asset-premium {
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              flex-direction: column !important;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-premium
              .asset-option {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 30px;
              border-radius: 0px;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-premium
              .asset-option:first-child {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-premium
              .asset-option:last-child {
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              border-right-width: 0;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-free
              .submenu-wrapper-left {
              // top: calc(50% - 30px);
              top: 0px;
            }
            .gallery-images
              .gallery-image-hover
              .button-select-asset-free
              .premium-hint-assets {
              min-width: auto;
              // min-height: 50px;
              min-height: auto;
              font-size: 12px;
              text-align: center;
              padding: 7px;
            }
            .gallery-images .gallery-title {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              overflow: hidden;
            }
            .gallery-images .gallery-title .image-title {
              padding: 5px 10px;
            }
            .gallery-images .gallery-title .image-info {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              background: #1b2833;
              opacity: 0.5;
              color: white;
              padding: 5px 10px;
              overflow: hidden;
            }
            .gallery-images .gallery-title .image-info .creator-info {
              flex: 1;
              flex-wrap: nowrap;
              color: #fff;
              display: flex;
            }
            .gallery-images
              .gallery-title
              .image-info
              .creator-info
              .creator-name,
            .gallery-images
              .gallery-title
              .image-info
              .creator-info
              .link-creator-name {
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .gallery-images
              .gallery-title
              .image-info
              .creator-info
              .link-creator-name:hover {
              text-decoration: underline;
            }
            .gallery-images .gallery-title .image-info .creator-avatar {
              position: relative;
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
            .gallery-images .gallery-title .image-info .number-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 15px;
            }
            .gallery-images .gallery-title .image-info .number-icon {
              margin-right: 5px;
            }
            .gallery-images .gallery-image {
              color: #fff;
              flex-direction: column-reverse;
              align-content: center;
              border-radius: 2px;
              position: relative;
            }
          `}
        </style>
      </div>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
  id: PropTypes.string,
  maxRows: PropTypes.number,
  margin: PropTypes.number,
  lastRowHide: PropTypes.bool,
  colWidth: PropTypes.string || PropTypes.number,
  rowHeight: PropTypes.number,
  forcePremium: PropTypes.bool,
  prepareLoad: PropTypes.bool,
};

Gallery.defaultProps = {
  id: 'ReactGridGallery',
  margin: 5,
  lastRowHide: false,
  forcePremium: false,
  prepareLoad: false,
};

export default withResizeDetector(Gallery);
