import React, { useRef } from 'react';
import css from 'styled-jsx/css';
import CategoryItem from './CategoryItem';
import CategoryItem2 from './CategoryItem2';

import Config from '~/config';
import Container from '~/components/Container';
import themeVariables from '~/shared/themeVariables';
import LinkTo from '~/components/LinkTo';

const CategoriesList = ({ t, categories, isTabletS, categoryNumber }) => {
  const ref = useRef(null);

  const scroll = (data) => {
    const widthScreen = window.innerWidth;
    let scroll = 0;
    if (widthScreen > 768) {
      scroll = 800;
    } else if (widthScreen > 500) {
      scroll = 600;
    } else {
      scroll = 300;
    }
    scroll *= data;
    console.log(scroll);
    ref.current.scrollLeft += scroll;
  };
  let data = categories.slice(0, 12);

  if (isTabletS) {
    data = categories.slice(0, 3);
  }

  return (
    <div className="categories-list">
      <Container contentClassName={titleContentClassName}>
        <h3 className="list-title">{t('categories')}</h3>

        <LinkTo label={t('view_all')} href="/categories" />
      </Container>
      <Container contentClassName={listContentClassName}>
        <div className="content-category">
          <div className="category-container" ref={ref}>
            {data
              .filter((category) => category.categoryImage)
              .map((category) => {
                if (Config.isSilhouetteAC() || Config.isIllustAC()) {
                  return <CategoryItem key={category.id} category={category} />;
                } else {
                  return <CategoryItem key={category.id} category={category} />;
                }
              })}
          </div>
          {/* <img
            src={`${Config.staticPrefix}/static/assets/icon-homepage/button-left-category.svg`}
            className="button-category left"
            alt="go left"
            onClick={() => scroll(-1)}
          />
          <img
            src={`${Config.staticPrefix}/static/assets/icon-homepage/button-right-category.svg`}
            className="button-category right"
            alt="go right"
            onClick={() => scroll(1)}
          /> */}
        </div>
      </Container>
      {titleContentStyles}
      {listContentStyles}
      <style jsx>{styles}</style>
    </div>
  );
};

const {
  className: titleContentClassName,
  styles: titleContentStyles,
} = css.resolve`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
  }
`;

const {
  className: listContentClassName,
  styles: listContentStyles,
} = css.resolve`
  position: relative;
  margin-top: 24px;
  @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
  }
`;

const styles = css`
  .categories-list {
    width: 100%;
    height: fit-content;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
  .content-category {
    height: 100%;
    position: relative;
  }

  .button-category {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
  .button-category.left {
    top: 70px;
    left: 12px;
    z-index: 1;
  }
  .button-category.right {
    top: 70px;
    right: 12px;
    z-index: 1;
  }

  .category-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
    grid-gap: 24px;
    justify-content: space-between;
  }
  .category-container::-webkit-scrollbar {
    display: none;
  }

  .list-title {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
    .category-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }
  }

  @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
    .category-container {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default CategoriesList;
