const convertToShortType = (lang) => {
  if (lang) {
    if (lang === 'zh-tw' || lang === 'zh-cn') {
      return lang.substring(3, 5);
    }
    return lang;
  }

  return '';
};

const convertToLongType = (lang) => {
  if (lang) {
    if (lang === 'tw') return 'zh-tw';
    if (lang === 'cn') return 'zh-cn';

    return lang;
  }

  return '';
};

const roundPriceByCurrency = (currency, price) => {
  const rawCurrencies = ['KRW', 'VND', 'JPY'];
  const inCentsCurrencies = ['USD', 'TWD', 'CNY', 'THB'];
  if (rawCurrencies.includes(currency)) {
    return Math.round(price);
  }
  if (inCentsCurrencies.includes(currency)) {
    return price.toFixed(2);
  }
};

function filterPriceByLanguage(prices, lng) {
  let price = prices.find((item) => item.lang == lng);
  if (!price) {
    price = prices.find((item) => item.lang === 'en');
  }
  return price;
}

function hasCJKCharacters(input) {
  // * https://gist.github.com/ryanmcgrath/982242
  // REFERENCE UNICODE TABLES:
  // http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
  // http://www.tamasoft.co.jp/en/general-info/unicode.html
  //
  // TEST EDITOR:
  // http://www.gethifi.com/tools/regex
  //
  // UNICODE RANGE : DESCRIPTION
  //
  // 3000-303F : punctuation
  // 3040-309F : hiragana
  // 30A0-30FF : katakana
  // FF00-FFEF : Full-width roman + half-width katakana
  // 4E00-9FAF : Common and uncommon kanji
  //
  // Non-Japanese punctuation/formatting characters commonly used in Japanese text
  // 2605-2606 : Stars
  // 2190-2195 : Arrows
  // u203B     : Weird asterisk thing
  const regexJP = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;

  /*
  * https://stackoverflow.com/a/43419070
  The ranges of Unicode characters which are routinely used for Chinese and Japanese text are:

  U+3040 - U+30FF: hiragana and katakana (Japanese only)
  U+3400 - U+4DBF: CJK unified ideographs extension A (Chinese, Japanese, and Korean)
  U+4E00 - U+9FFF: CJK unified ideographs (Chinese, Japanese, and Korean)
  U+F900 - U+FAFF: CJK compatibility ideographs (Chinese, Japanese, and Korean)
  U+FF66 - U+FF9F: half-width katakana (Japanese only)
  */
  const regexCJK = /[\u3040-\u30ff]|[\u3400-\u4dbf]|[\u4e00-\u9fff]|[\uf900-\ufaff]|[\uff66-\uff9f]|[\u3040-\u30ff]|[\u3400-\u4dbf]|[\u4e00-\u9fff]|[\uf900-\ufaff]|[\uff66-\uff9f]|[\u3131-\uD79D]/g;

  return regexJP.test(input) || regexCJK.test(input);
}

export default {
  convertToLongType,
  convertToShortType,
  roundPriceByCurrency,
  filterPriceByLanguage,
  hasCJKCharacters,
};
