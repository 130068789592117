import Config from '~/config';

const data = {
  getData(lng) {
    const data = {
      en: [
        'Summer',
        'Flowers',
        'Women',
        'Easter',
        'Bunny',
        'Diversity',
        'Flower',
        'Light',
        'Gold',
        'Text effect',
        'Frame',
        'Arrow',
        'Blue background',
        'Infographic',
        'Coffee',
        'Paper',
        'Pattern',
        'Abstract background',
        'Logo',
        'Technology',
        'Ribbon',
        'Gift',
        'Snow',
        'Food',
        'Cloud',
        'Icons',
        'Phone',
        'Sky',
        'Business card',
        'Wedding',
        'Money',
        '2022',
        'City',
        'Covid',
        'Leaf',
        'Star',
        'White background',
        'Line',
        'Label',
        'Blue',
        'Wood',
        'Tree',
        'Car',
        'Fire',
        'Green background',
        'Menu',
        'Template',
        'Book',
        'Red background',
        'Business',
        'Green',
        'Font',
        'Cat',
        'Card',
        'Travel',
        'Japan',
        'Black background',
        'Texture',
        'Red',
        'School',
        'People',
        'Paper texture',
        'Cosmetic',
        'Mountain',
        'Dog',
        'Baby',
        'Brochure',
        'Shopping',
        'Hand',
        'Gift box',
        'Button',
        'Wave',
        'Gradient',
        'Abstract',
        'Technology background',
        'Cover',
        'Pink',
        'Music',
        'Building',
      ],
      th: [
        'ฤดูร้อน',
        'ดอกไม้',
        'ผู้หญิง',
        'อีสเตอร์',
        'กระต่าย',
        'ความหลากหลาย',
        'ดอกไม้',
        'แสงสว่าง',
        'ทอง',
        'เอฟเฟกต์ข้อความ',
        'เฟรม',
        'ลูกศร',
        'พื้นหลังสีน้ำเงิน',
        'อินโฟกราฟิก',
        'กาแฟ',
        'กระดาษ',
        'ลวดลาย',
        'พื้นหลังที่เป็นนามธรรม',
        'โลโก้',
        'เทคโนโลยี',
        'ริบบิ้น',
        'ของขวัญ',
        'หิมะ',
        'อาหาร',
        'คลาวด์',
        'ไอคอน',
        'โทรศัพท์',
        'ท้องฟ้า',
        'นามบัตร',
        'งานแต่งงาน',
        'เงิน',
        '2022',
        'เมือง',
        'โควิด',
        'ใบไม้',
        'ดาว',
        'พื้นหลังสีขาว',
        'เส้น',
        'ฉลาก',
        'สีฟ้า',
        'ไม้',
        'ต้นไม้',
        'รถ',
        'ไฟ',
        'พื้นหลังสีเขียว',
        'เมนู',
        'แม่แบบ',
        'หนังสือ',
        'พื้นหลังสีแดง',
        'ธุรกิจ',
        'สีเขียว',
        'แบบอักษร',
        'แมว',
        'การ์ด',
        'การท่องเที่ยว',
        'ญี่ปุ่น',
        'พื้นหลังสีดำ',
        'เนื้อ',
        'สีแดง',
        'โรงเรียน',
        'ประชากร',
        'เนื้อกระดาษ',
        'เครื่องสำอาง',
        'ภูเขา',
        'หมา',
        'ที่รัก',
        'โบรชัวร์',
        'ช้อปปิ้ง',
        'มือ',
        'กล่องของขวัญ',
        'ปุ่ม',
        'คลื่น',
        'การไล่ระดับสี',
        'เชิงนามธรรม',
        'พื้นหลังเทคโนโลยี',
        'ปิดบัง',
        'สีชมพู',
        'ดนตรี',
        'อาคาร',
      ],
      vi: [
        'Mùa hè',
        'Những bông hoa',
        'Phụ nữ',
        'Phục Sinh',
        'Chú thỏ',
        'Đa dạng',
        'Hoa',
        'Ánh sáng',
        'Vàng',
        'Hiệu ứng văn bản',
        'Khung',
        'Mũi tên',
        'Nền xanh',
        'Đồ họa thông tin',
        'Cà phê',
        'Giấy',
        'Mẫu',
        'Hình nền trừu tượng',
        'Logo',
        'Công nghệ',
        'Ruy-băng',
        'Quà',
        'Tuyết',
        'Đồ ăn',
        'Đám mây',
        'Biểu tượng',
        'Điện thoại',
        'Bầu trời',
        'Danh thiếp',
        'Lễ cưới',
        'Tiền bạc',
        '2022',
        'Thành phố',
        'Covid',
        'Lá cây',
        'Ngôi sao',
        'Nền trắng',
        'Đường kẻ',
        'Nhãn mác',
        'Màu xanh da trời',
        'Gỗ',
        'Cây',
        'Xe ô tô',
        'Lửa',
        'Nền xanh',
        'Thực đơn',
        'Mẫu',
        'Sách',
        'Nền đỏ',
        'Việc kinh doanh',
        'Màu xanh lá',
        'Nét chữ',
        'Con mèo',
        'Thẻ',
        'Đi du lịch',
        'Nhật Bản',
        'Phông nền màu đen',
        'Kết cấu',
        'Màu đỏ',
        'Ngôi trường',
        'Mọi người',
        'Kết cấu giấy',
        'Mỹ phẩm',
        'Núi',
        'Chú chó',
        'Em bé',
        'Cuốn sách nhỏ',
        'Mua sắm',
        'Tay',
        'Hộp quà',
        'Khuy ao',
        'Sóng',
        'Dốc',
        'Trừu tượng',
        'Nền tảng công nghệ',
        'Che',
        'Hồng',
        'Âm nhạc',
        'Nhà lầu',
      ],
      tw: [
        '夏天',
        '花卉',
        '女性',
        '復活節',
        '兔子',
        '多樣性',
        '花',
        '光',
        '金子',
        '文字效果',
        '框架',
        '箭',
        '藍色背景',
        '信息圖表',
        '咖啡',
        '紙',
        '圖案',
        '抽象背景',
        '商標',
        '技術',
        '絲帶',
        '禮物',
        '雪',
        '食物',
        '雲',
        '圖標',
        '電話',
        '天空',
        '名片',
        '婚禮',
        '錢',
        '2022',
        '城市',
        '冠狀病毒病',
        '葉子',
        '星星',
        '白色背景',
        '線',
        '標籤',
        '藍色',
        '木頭',
        '樹',
        '車',
        '火',
        '綠色背景',
        '菜單',
        '模板',
        '書',
        '紅色背景',
        '商業',
        '綠色的',
        '字體',
        '貓',
        '卡片',
        '旅行',
        '日本',
        '黑色背景',
        '質地',
        '紅色的',
        '學校',
        '人們',
        '紙張紋理',
        '化妝品',
        '山',
        '狗',
        '嬰兒',
        '小冊子',
        '購物',
        '手',
        '禮物盒',
        '按鈕',
        '海浪',
        '坡度',
        '抽象的',
        '技術背景',
        '覆蓋',
        '粉色的',
        '音樂',
        '建築',
      ],
      cn: [
        '夏天',
        '花卉',
        '女性',
        '复活节',
        '兔子',
        '多样性',
        '花',
        '光',
        '金子',
        '文字效果',
        '框架',
        '箭',
        '蓝色背景',
        '信息图表',
        '咖啡',
        '纸',
        '图案',
        '抽象背景',
        '商标',
        '技术',
        '丝带',
        '礼物',
        '雪',
        '食物',
        '云',
        '图标',
        '电话',
        '天空',
        '名片',
        '婚礼',
        '钱',
        '2022',
        '城市',
        '冠状病毒病',
        '叶子',
        '星星',
        '白色背景',
        '线',
        '标签',
        '蓝色',
        '木头',
        '树',
        '车',
        '火',
        '绿色背景',
        '菜单',
        '模板',
        '书',
        '红色背景',
        '商业',
        '绿色的',
        '字体',
        '猫',
        '卡片',
        '旅行',
        '日本',
        '黑色背景',
        '质地',
        '红色的',
        '学校',
        '人们',
        '纸张纹理',
        '化妆品',
        '山',
        '狗',
        '婴儿',
        '小册子',
        '购物',
        '手',
        '礼物盒',
        '按钮',
        '海浪',
        '坡度',
        '抽象的',
        '技术背景',
        '覆盖',
        '粉色的',
        '音乐',
        '建筑',
      ],
      ko: [
        '여름',
        '꽃들',
        '여성',
        '부활절',
        '토끼',
        '다양성',
        '꽃',
        '빛',
        '금',
        '텍스트 효과',
        '액자',
        '화살',
        '파란색 배경',
        '인포그래픽',
        '커피',
        '종이',
        '무늬',
        '추상 배경',
        '심벌 마크',
        '기술',
        '리본',
        '선물',
        '눈',
        '음식',
        '구름',
        '아이콘',
        '핸드폰',
        '하늘',
        '명함',
        '혼례',
        '돈',
        '2022',
        '도시',
        '코로나',
        '잎',
        '별',
        '흰 배경',
        '선',
        '상표',
        '푸른',
        '목재',
        '나무',
        '자동차',
        '불',
        '녹색 배경',
        '메뉴',
        '주형',
        '책',
        '빨간 배경',
        '사업',
        '녹색',
        '폰트',
        '고양이',
        '카드',
        '여행하다',
        '일본',
        '검은 배경',
        '조직',
        '빨간색',
        '학교',
        '사람들',
        '종이 질감',
        '화장품',
        '산',
        '개',
        '아기',
        '소책자',
        '쇼핑',
        '손',
        '선물 상자',
        '단추',
        '파도',
        '구배',
        '추상적 인',
        '기술 배경',
        '씌우다',
        '분홍색',
        '음악',
        '건물',
      ],
    };

    return data[lng] || data['en'];
  },
};
export default data;
