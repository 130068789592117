import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUserClock } from '@fortawesome/free-solid-svg-icons';
import Router from 'next/router';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  hideRegisteredUsersModal,
  loadLatestRegisteredUsers,
} from '../actions';
import ListRegisteredUsers from '../ListRegisteredUsers';
import {
  makeSelectLatestRegisteredUsers,
  makeSelectLoadingLatestRegisteredUsers,
  makeSelectShowModal,
} from '../selectors';
import Config from '~/config';
import withI18next from '~/../hocs/_withI18next';
import I18nRouter from '~/components/I18nRouter';

const styles = {
  iconLoading: {
    width: 30,
    margin: 28,
  },
};

@withI18next()
class RegisteredUsersModal extends React.Component {
  componentDidMount() {
    const { showModal, loading, loadLatestRegisteredUsers } = this.props;
    if (showModal && !loading) loadLatestRegisteredUsers();
  }

  handleViewAll = () => {
    const { lng, closeModal } = this.props;
    I18nRouter.push('/registered-users', '/registered-users');
    closeModal();
  };

  render() {
    const {
      showModal,
      lng,
      t,
      closeModal,
      loading,
      latestRegisteredUsers,
    } = this.props;

    return (
      <Modal
        show={showModal}
        onHide={closeModal}
        className="member-login-modal registered-users-modal"
      >
        <Modal.Body className="login-modal-body">
          <div className="ac-dialog login-modal">
            <div className={`dialog-header ${Config.serviceType}`}>
              <FontAwesomeIcon
                style={{ width: 20, marginRight: 8, marginBottom: 4 }}
                icon={faUserClock}
              />
              {t('newest_registered_users')}
            </div>
            <div className="dialog-body registered-users-conntent">
              {loading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  style={styles.iconLoading}
                  pulse
                />
              ) : (
                <ListRegisteredUsers users={latestRegisteredUsers} />
              )}
              <button
                className={`btn btn-ac-primary btn-${Config.serviceType} btn-view`}
                onClick={this.handleViewAll}
              >
                {t('view_all')}
              </button>
            </div>
          </div>
        </Modal.Body>
        <style jsx>
          {`
            .registered-users-modal .ac-dialog {
              width: 680px;
            }

            .registered-users-modal .registered-users-conntent {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 28px;
              background: #fff;
            }
            .registered-users-modal .registered-users-conntent .btn-view {
              margin-top: 14px;
            }
          `}
        </style>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  latestRegisteredUsers: makeSelectLatestRegisteredUsers(),
  loading: makeSelectLoadingLatestRegisteredUsers(),
  showModal: makeSelectShowModal(),
});
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(hideRegisteredUsersModal()),
  loadLatestRegisteredUsers: () => dispatch(loadLatestRegisteredUsers()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisteredUsersModal);
