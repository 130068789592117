import React from 'react';
import I18nLink from '~/components/I18nLink';
import Config from '~/config';
import themeVariables from '~/shared/themeVariables';

const Tag = (props) => {
  const { data } = props;
  return (
    <I18nLink href={`/search/${data}?is_tag=true`}>
      <a className="container-tag-full" target="_blank">
        <div className="container-tag">
          <div className="tag-text">{data}</div>
        </div>
        <style jsx>
          {`
            .container-tag-full {
              height: 44px;
              display: flex;
              flex-direction: column;
              margin-left: 8px;
              margin-top: 8px;
              color: #000000;
            }

            .container-tag-full:hover {
              cursor: pointer;
            }
            .container-tag {
              width: fit-content;
              padding: 8px;
              background: #ffffff;
              border-radius: ${Config.isIllustAC() ? '40px' : '4px'};
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .container-tag:hover {
              background: ${Config.isIllustAC() ? '#FBEBED' : '#ecf1f4'};
            }
            .container-tag:active {
              background: #ffffff;
              box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
            .tag-text {
              padding: 0;
            }
            @media screen and (max-width: ${themeVariables.breakpoints.tabletS
                .minWidth}px) {
              .container-tag {
                font-size: 14px;
                padding: 6px;
              }
              .container-tag-full {
                height: 42px;
                flex-direction: column;
                margin-left: 6px;
                margin-top: 6px;
              }
            }
          `}
        </style>
      </a>
    </I18nLink>
  );
};

export default Tag;
