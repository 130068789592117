import {
  LOAD_DONATION_INFOR_SUCCESS,
  LOAD_DONATION_INFOR_ERROR,
} from './constants';

const initialState = {
  donation: {},
  error: false,
};

const premiumPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DONATION_INFOR_SUCCESS:
      return { ...state, donation: action.donation };
    case LOAD_DONATION_INFOR_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default premiumPageReducer;
