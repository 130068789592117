import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  LOAD_COLLECTIONS_OF_MONTHS,
  LOAD_COLLECTIONS_OF_MONTHS_ERROR,
  LOAD_COLLECTIONS_OF_MONTHS_SUCCESS,
  LOAD_COLLECTION_DETAIL_ERROR,
  LOAD_COLLECTION_DETAIL_SUCCESS,
  LOAD_DATA_COLLECTION_BY_ID,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
  REMOVE_NICE_PHOTO_SUCCESS,
  NICE_PHOTO,
} from './constants';
import { makeSelectCollections } from './selectors';
import * as collectionPageService from '~/services/collection';
import * as searchPageService from '~/services/search-page';
import * as photoPageService from '~/services/photo-page';
import Config from '~/config';

function* loadCollectionDetailById(action) {
  const { payload, api } = action;
  const apiFunction = api
    ? api.photo.getCollectionDetailById
    : collectionPageService.getCollectionDetailById;
  const apiFunctionSearch = searchPageService.searchData;
  try {
    let collectionDetail = yield call(apiFunction, payload);

    let query = {};
    collectionDetail[0]?.search_url
      .split('?')[1]
      .split('&')
      .forEach((value) => {
        const key = value.split('=')[0];
        const data = value.split('=')[1];
        key && (query[key] = decodeURIComponent(data));
      });
    if (collectionDetail[0]?.exclude_id) {
      query['excludeImageIds'] = decodeURIComponent(
        collectionDetail[0].exclude_id,
      );
    }
    query.page = parseInt(payload.page);

    if (collectionDetail[0]?.search_url && !query.keyword) {
      const linkSearch = decodeURIComponent(
        collectionDetail[0].search_url,
      ).split('?')[0];
      query.keyword = linkSearch.substring(
        linkSearch.lastIndexOf('/') + 1,
        linkSearch.length,
      );
    }
    console.log('day la query collection', query);

    let collectionData = yield call(apiFunctionSearch, query);
    console.log('data collection', collectionData.collection[0]);
    collectionData.collection = collectionData.collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      return item;
    });
    yield put({
      type: LOAD_COLLECTION_DETAIL_SUCCESS,
      collectionDetail,
      collectionData,
      query,
    });
  } catch (error) {
    yield put({ type: LOAD_COLLECTION_DETAIL_ERROR, error: true });
  }
}
// function* loadCollectionData(action) {
//   const dataCollectionDetail = yield select(makeSelectCollectionDetail());
//   const apiFunctionSearch = api
//   ? api.search.searchData
//   : searchPageService.searchData;

//   const { payload, api } = action;

//   try {
//     let collectionData = yield call(apiFunctionSearch, payload);
//     yield put({
//       type: LOAD_DATA_COLLECTION_PHOTO_SUCCESS,
//       collectionData,
//     });
//   } catch (error) {
//     yield put({ type: LOAD_DATA_COLLECTION_PHOTO_ERROR, error: true });
//   }
// }
/**
 * Root saga manages watcher lifecycle
 */
function* loadCollectionsOfMonths(action) {
  const { payload, api } = action;
  const apiFunction = api
    ? api.photo.getCollectionOfMonths
    : collectionPageService.getCollectionOfMonths;
  try {
    let collections = yield call(apiFunction, payload);
    yield put({
      type: LOAD_COLLECTIONS_OF_MONTHS_SUCCESS,
      collections,
    });
  } catch (error) {
    yield put({ type: LOAD_COLLECTIONS_OF_MONTHS_ERROR, error: true });
  }
}

function* nicePhoto(action) {
  try {
    const photos = yield select(makeSelectCollections());
    console.log('vo nick collection roi', photos);

    const searchedPhoto = photos.find((item) => item.id === action.value);
    console.log('vo nick collection roi', searchedPhoto);

    if (searchedPhoto.is_nice) {
      yield put({
        type: REMOVE_NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.removeNicePhoto, action.value);
    } else {
      yield put({
        type: NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.nicePhoto, action.value);
    }
  } catch (error) {
    console.log('vo nick collection errrrr', error);

    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}
export default function* collectionSaga() {
  yield takeLatest(LOAD_DATA_COLLECTION_BY_ID, loadCollectionDetailById);
  // yield takeLatest(LOAD_DATA_COLLECTION_PHOTO, loadCollectionData);
  yield takeLatest(LOAD_COLLECTIONS_OF_MONTHS, loadCollectionsOfMonths);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
