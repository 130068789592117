import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
if (process.browser && !('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit');
}
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'glider-js/glider.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { config, dom } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/ko';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-cn';
import 'moment/locale/th';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import Router from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { i18n } from 'next-i18next';
import nextI18NextConfig from '~/../next-i18next.config.js';
import { wrapper } from '~/store';
import { locales, urls } from '~/utils';
import Config from '~/config';
import commonStyles from '~/shared/commonStyles';
import toastifyStyles from '~/shared/toastifyStyles';
require('intersection-observer');

import(`~/shared/themes/${process.env.SERVICE_TYPE}.css`); // CANNOT USE Config.serviceType

config.autoAddCss = false;
dom.watch();

NProgress.configure({ trickleSpeed: 300 });
if (Config.serviceType === 'design_ac') {
  NProgress.configure({
    showSpinner: false,
    template: '<div class=\'bar-1\' role=\'bar\'><div class=\'peg></div></div>',
  });
}
if (Config.serviceType === 'illust_ac') {
  NProgress.configure({
    showSpinner: false,
    template: '<div class=\'bar-illust\' role=\'bar\'><div class=\'peg></div></div>',
  });
}
if (Config.serviceType === 'photo_ac') {
  NProgress.configure({
    showSpinner: false,
    template: '<div class=\'bar-photo\' role=\'bar\'><div class=\'peg></div></div>',
  });
}
if (Config.serviceType === 'silhouette_ac') {
  NProgress.configure({
    showSpinner: false,
    template: '<div class=\'bar-sil\' role=\'bar\'><div class=\'peg></div></div>',
  });
}

Router.onRouteChangeStart = (url) => {
  NProgress.start();
  NProgress.set(0.1);
  NProgress.set(0.2);
  NProgress.set(0.3);
  NProgress.set(0.4);
  NProgress.set(0.5);
  NProgress.set(0.6);
  NProgress.set(0.7);
};

Router.onRouteChangeComplete = (url) => {
  NProgress.set(0.8);
  NProgress.set(0.9);
  NProgress.done();
  if (Config.isChina()) {
    urls.trackPageView(url);
  }
};

Router.onRouteChangeError = () => {
  NProgress.set(0.8);
  NProgress.set(0.9);
  NProgress.done();
};

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    moment.locale(locales.convertToLongType(i18n.language));
  }, []);

  return (
    <CookiesProvider>
      <Component {...pageProps} />
      <style jsx global>
        {commonStyles}
      </style>
      <style jsx global>
        {toastifyStyles}
      </style>
    </CookiesProvider>
  );
}

export default wrapper.withRedux(appWithTranslation(MyApp, nextI18NextConfig));
