const path = require('path');

const supportedLanguages = [
  'en',
  'de',
  'ar',
  'bn',
  'es',
  'fr',
  'it',
  'ko',
  'hi',
  'pl',
  'th',
  'tr',
  'ru',
  'vi',
  'tw',
  'cn',
  'jv',
  'pt',
];
const defaultLanguage = process.env.LANGUAGE || 'en';
const chinaDomains = {
  photo_ac: process.env.CHINA_PHOTO_AC,
  illust_ac: process.env.CHINA_ILLUST_AC,
  silhouette_ac: process.env.CHINA_SILHOUETTE_AC,
  design_ac: process.env.CHINA_DESIGN_AC,
};
const domains = supportedLanguages.map((lang) => ({
  domain:
    defaultLanguage === 'cn'
      ? chinaDomains[process.env.SERVICE_TYPE]
      : `${lang === 'tw' ? 'zh-tw' : lang}.${process.env.DOMAIN}`,
  defaultLocale: lang,
}));

module.exports = {
  supportedLanguages,

  i18n: {
    defaultLocale: defaultLanguage,
    locales: defaultLanguage === 'cn' ? [defaultLanguage] : supportedLanguages,
    localeDetection: false,
    domains,
  },

  // next-i18next options
  localePath: path.resolve('./static/locales'),
  serializeConfig: false,
  defaultNS: 'common',
  localeStructure: '{{lng}}/{{ns}}',

  // react-i18next options
  defaultLanguage,
  fallbackLng: 'en',

  // i18next options
  load: 'languageOnly',
  ns: [
    'common',
    'component-SEOTextACworks',
    'component-FreeAndPremiumCompareTable',
    'page-sell-your-content',
    'page-submission-guideline',
    'page-categories',
    'page-category-details',
    'page-collections',
    'page-extra-license',
    'page-terms',
    'page-terms-ai',
    'page-privacy-policy',
    'page-about-us',
    'page-faq',
  ],
  debug: false,
};
