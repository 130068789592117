import css from 'styled-jsx/css';
import Config from '~/config';

// Global styles
const commonStyles = css.global`
  /* latin */
  @font-face {
    font-family: 'Work Sans';
    font-weight: 400;
    font-display: swap;
    src: url(${Config.staticPrefix}/static/fonts/WorkSans/latin-400.woff2)
      format('woff2');
  }
  /* latin */
  @font-face {
    font-family: 'Work Sans';
    font-weight: 500;
    font-display: swap;
    src: url(${Config.staticPrefix}/static/fonts/WorkSans/latin-500.woff2)
      format('woff2');
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Work Sans';
  }

  html {
    -webkit-tap-highlight-color: #1b2833;
    scroll-behavior: smooth;
  }

  *::selection {
    color: currentColor;
  }

  *::-moz-selection {
    color: currentColor;
  }

  .app_photo_ac .ac-color,
  .app_photo_ac .ac-text {
    color: #509bdc;
  }

  .app_photo_ac .ac-bg {
    background-color: #509bdc;
  }

  .app_photo_ac .btn-ac,
  .app_photo_ac .btn-ac:active,
  .app_photo_ac .btn-ac:focus,
  .app_photo_ac .btn-ac:hover {
    background-color: #509bdc;
  }

  .app_illust_ac .ac-color,
  .app_illust_ac .ac-text {
    color: #d73a4e;
  }

  .app_illust_ac .ac-bg {
    background-color: #d73a4e;
  }

  .app_illust_ac .btn-ac,
  .app_illust_ac .btn-ac:active,
  .app_illust_ac .btn-ac:focus,
  .app_illust_ac .btn-ac:hover {
    background-color: #d73a4e;
  }

  .app_silhouette_ac .ac-color,
  .app_silhouette_ac .ac-text {
    color: #41b9b8;
  }

  .app_silhouette_ac .ac-bg {
    background-color: #41b9b8;
  }

  .app_silhouette_ac .btn-ac,
  .app_silhouette_ac .btn-ac:active,
  .app_silhouette_ac .btn-ac:focus,
  .app_silhouette_ac .btn-ac:hover {
    background-color: #41b9b8;
  }

  .app_design_ac .ac-color,
  .app_design_ac .ac-text {
    color: #ffa823;
  }

  .app_design_ac .ac-bg {
    background-color: #ffa823;
  }

  .app_design_ac .btn-ac,
  .app_design_ac .btn-ac:active,
  .app_design_ac .btn-ac:focus,
  .app_design_ac .btn-ac:hover {
    background-color: #ffa823;
  }

  .ac-divider {
    padding-bottom: 0.21428571rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }

  .ac-divider-horizontal {
    padding-left: 0.21428571rem;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
  }

  .ac-divider-vertical {
    height: 30px;
    width: 1px;
    border: 1px solid rgba(105, 112, 119, 0.3);
    margin-top: 1px;
  }

  label {
    font-weight: 500;
  }

  .pt70 {
    padding-top: 70px;
  }

  .pt65 {
    padding-top: 65px;
  }

  @media only screen and (max-width: 1080px) {
    .pt70 {
      padding-top: 70px;
    }
  }

  .pt70 {
    padding-top: 70px;
  }

  .design_color-ac-primary {
    color: #ffa823 !important;
  }

  .design_ac_color-ac-primary {
    color: #ffa823 !important;
  }

  .ill_color-ac-primary {
    color: #d73a4e !important;
  }

  .illust_ac_color-ac-primary {
    color: #d73a4e !important;
  }

  .photo_color-ac-primary {
    color: #509bdc !important;
  }

  .photo_ac_color-ac-primary {
    color: #509bdc !important;
  }

  .sil_color-ac-primary {
    color: #41b9b8 !important;
  }

  .silhouette_ac_color-ac-primary {
    color: #41b9b8 !important;
  }

  .bg-gray {
    background-color: #f2f3f4;
  }

  .ill_bg-yellow {
    background-color: #d73a4e;
  }

  .design_bg-yellow {
    background-color: #ffa823;
  }

  .photo_bg-yellow {
    background-color: #509bdc;
  }

  .sil_bg-yellow {
    background-color: #41b9b8;
  }

  .ill_arrow-up {
    border-bottom: 7px solid #d73a4e;
  }

  .design_arrow-up {
    border-bottom: 7px solid #ffa823;
  }

  .photo_arrow-up {
    border-bottom: 7px solid #509bdc;
  }

  .sil_arrow-up {
    border-bottom: 7px solid #41b9b8;
  }

  .ill_text-color a {
    color: #d73a4e;
    cursor: pointer;
  }
  .ill_text-color a:hover,
  .ill_text-color a:active,
  .ill_text-color a:focus {
    color: currentColor;
    text-decoration: none;
  }

  .design_text-color a {
    color: #ffa823;
    cursor: pointer;
  }
  .design_text-color a:hover,
  .design_text-color a:active,
  .design_text-color a:focus {
    color: currentColor;
    text-decoration: none;
  }

  .photo_text-color a {
    color: #509bdc;
    cursor: pointer;
  }
  .photo_text-color a:hover,
  .photo_text-color a:active,
  .photo_text-color a:focus {
    color: currentColor;
    text-decoration: none;
  }

  .sil_text-color a {
    color: #41b9b8;
    cursor: pointer;
  }
  .sil_text-color a:hover,
  .sil_text-color a:active,
  .sil_text-color a:focus {
    color: currentColor;
    text-decoration: none;
  }

  a {
    color: #3a4ed7;
    cursor: pointer;
  }
  // a:hover,
  // a:active,
  // a:focus {
  //   color: currentColor;
  //   text-decoration: none;
  // }

  a:hover {
    color: #3a4ed7;
    opacity: 0.8;
    text-decoration: underline;
  }

  a:focus {
    color: #3a4ed7;
    opacity: 1;
  }

  .btn-lg {
    padding: 15px 16px;
    font-size: 14px;
  }

  .font-weight-500 {
    font-weight: 500;
  }

  .design_btn-ac-primary {
    color: #fff;
    background: #ffa823;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border: 1px solid #ffa823;
    border-radius: 2px;
  }
  .design_btn-ac-primary:hover,
  .design_btn-ac-primary:active,
  .design_btn-ac-primary:focus {
    color: #fff;
    background: #774d17;
    opacity: 0.9;
    outline: none;
  }

  .ill_btn-ac-primary {
    color: #fff;
    background: #d73a4e;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border: 1px solid #d73a4e;
    border-radius: 2px;
  }
  .ill_btn-ac-primary:hover,
  .ill_btn-ac-primary:active,
  .ill_btn-ac-primary:focus {
    color: #fff;
    background: #5e282e;
    opacity: 0.9;
    outline: none;
  }

  .photo_btn-ac-primary {
    color: #fff;
    background: #509bdc;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border: 1px solid #509bdc;
    border-radius: 2px;
  }
  .photo_btn-ac-primary:hover,
  .photo_btn-ac-primary:active,
  .photo_btn-ac-primary:focus {
    color: #fff;
    background: #1c5362;
    opacity: 0.9;
    outline: none;
  }

  .sil_btn-ac-primary {
    color: #fff;
    background: #41b9b8;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border: 1px solid #41b9b8;
    border-radius: 2px;
  }
  .sil_btn-ac-primary:hover,
  .sil_btn-ac-primary:active,
  .sil_btn-ac-primary:focus {
    color: #fff;
    background: #2b5e5a;
    opacity: 0.9;
    outline: none;
  }

  .btn-ac-primary {
    color: #fff;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border-radius: 2px;
    font-size: 14px;
  }
  .btn-ac-primary:hover,
  .btn-ac-primary:active,
  .btn-ac-primary:focus {
    color: #fff;
    opacity: 0.9;
    outline: none;
  }
  .btn-ac-primary[disabled]:hover,
  .btn-ac-primary[disabled]:active,
  .btn-ac-primary[disabled]:focus {
    opacity: 0.65 !important;
  }
  .btn-ac-primary.btn-illust_ac {
    background: #d73a4e;
    border: 1px solid #d73a4e;
  }
  .btn-ac-primary.btn-illust_ac:hover,
  .btn-ac-primary.btn-illust_ac:active,
  .btn-ac-primary.btn-illust_ac:focus {
    background: #5e282e;
    border: 1px solid #5e282e;
  }
  .btn-ac-primary.btn-photo_ac {
    background: #509bdc;
    border: 1px solid #509bdc;
  }
  .btn-ac-primary.btn-photo_ac:hover,
  .btn-ac-primary.btn-photo_ac:active,
  .btn-ac-primary.btn-photo_ac:focus {
    background: #1c5362;
    border: 1px solid #1c5362;
  }
  .btn-ac-primary.btn-silhouette_ac {
    background: #41b9b8;
    border: 1px solid #41b9b8;
  }
  .btn-ac-primary.btn-silhouette_ac:hover,
  .btn-ac-primary.btn-silhouette_ac:active,
  .btn-ac-primary.btn-silhouette_ac:focus {
    background: #2b5e5a;
    border: 1px solid #2b5e5a;
  }
  .btn-ac-primary.btn-design_ac {
    background: #ffa823;
    border: 1px solid #ffa823;
  }
  .btn-ac-primary.btn-design_ac:hover,
  .btn-ac-primary.btn-design_ac:active,
  .btn-ac-primary.btn-design_ac:focus {
    background: #774d17;
    border: 1px solid #774d17;
  }

  .btn-ac-primary-outline {
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border-radius: 2px;
    background: #fff;
  }
  .btn-ac-primary-outline:hover,
  .btn-ac-primary-outline:active,
  .btn-ac-primary-outline:focus {
    opacity: 0.9;
    outline: none;
  }
  .btn-ac-primary-outline.btn-illust_ac {
    color: #d73a4e;
    border: 1px solid #d73a4e;
  }
  .btn-ac-primary-outline.btn-illust_ac:hover,
  .btn-ac-primary-outline.btn-illust_ac:active,
  .btn-ac-primary-outline.btn-illust_ac:focus {
    color: #fff;
    opacity: 0.9;
    outline: none;
    background: #5e282e;
    border: 1px solid #5e282e;
  }
  .btn-ac-primary-outline.btn-photo_ac {
    color: #509bdc;
    border: 1px solid #509bdc;
  }
  .btn-ac-primary-outline.btn-photo_ac:hover,
  .btn-ac-primary-outline.btn-photo_ac:active,
  .btn-ac-primary-outline.btn-photo_ac:focus {
    color: #fff;
    opacity: 0.9;
    outline: none;
    background: #1c5362;
    border: 1px solid #1c5362;
  }
  .btn-ac-primary-outline.btn-silhouette_ac {
    color: #41b9b8;
    border: 1px solid #41b9b8;
  }
  .btn-ac-primary-outline.btn-silhouette_ac:hover,
  .btn-ac-primary-outline.btn-silhouette_ac:active,
  .btn-ac-primary-outline.btn-silhouette_ac:focus {
    color: #fff;
    opacity: 0.9;
    outline: none;
    background: #2b5e5a;
    border: 1px solid #2b5e5a;
  }
  .btn-ac-primary-outline.btn-design_ac {
    color: #ffa823;
    border: 1px solid #ffa823;
  }
  .btn-ac-primary-outline.btn-design_ac:hover,
  .btn-ac-primary-outline.btn-design_ac:active,
  .btn-ac-primary-outline.btn-design_ac:focus {
    color: #fff;
    opacity: 0.9;
    outline: none;
    background: #774d17;
    border: 1px solid #774d17;
  }

  .bg-color-illust_ac {
    background-color: #d73a4e;
  }

  .bg-color-photo_ac {
    background-color: #509bdc;
  }

  .bg-color-silhouette_ac {
    background-color: #41b9b8;
  }

  .bg-color-design_ac {
    background-color: #ffa823;
  }

  .color-ac-facebook {
    color: #3b5998;
  }

  .color-ac-twitter {
    color: #1da1f2;
  }

  .color-ac-google-plus {
    color: #dc4e41;
  }

  .btn-shadow {
    border-radius: 60px;
    height: 35px;
    padding-right: 20px;
    padding-left: 20px;
    color: #fff;
    border: none;
    font-weight: 400;
  }

  .btn-shadow-gray {
    background: #8a8d8c;
    border: 1px solid #8a8d8c;
    box-shadow: 0 5px 1px -1px #5d5f5e !important;
  }
  .btn-shadow-gray:hover,
  .btn-shadow-gray:active,
  .btn-shadow-gray:focus {
    color: #ffffff;
    background: #8a8d8c;
    opacity: 0.95;
  }

  .btn-shadow-primary {
    background: #218cad;
    box-shadow: 0 5px 1px -1px #114e5f;
  }
  .btn-shadow-primary:hover,
  .btn-shadow-primary:active,
  .btn-shadow-primary:focus {
    color: #ffffff;
    background: #218cad;
    opacity: 0.95;
  }

  .design_btn-ac-primary-outline {
    color: #ffa823;
    border: 1px solid #ffa823;
    background: #fff;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border-radius: 2px;
  }
  .design_btn-ac-primary-outline:hover,
  .design_btn-ac-primary-outline:active,
  .design_btn-ac-primary-outline:focus {
    background: #ffa823;
    color: #fff;
    opacity: 0.95;
    outline: none;
  }

  .ill_btn-ac-primary-outline {
    color: #d73a4e;
    border: 1px solid #d73a4e;
    background: #fff;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border-radius: 2px;
  }
  .ill_btn-ac-primary-outline:hover,
  .ill_btn-ac-primary-outline:active,
  .ill_btn-ac-primary-outline:focus {
    background: #d73a4e;
    color: #fff;
    opacity: 0.95;
    outline: none;
  }

  .photo_btn-ac-primary-outline {
    color: #509bdc;
    border: 1px solid #509bdc;
    background: #fff;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border-radius: 2px;
  }
  .photo_btn-ac-primary-outline:hover,
  .photo_btn-ac-primary-outline:active,
  .photo_btn-ac-primary-outline:focus {
    background: #509bdc;
    color: #fff;
    opacity: 0.95;
    outline: none;
  }

  .sil_btn-ac-primary-outline {
    color: #41b9b8;
    border: 1px solid #41b9b8;
    background: #fff;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    border-radius: 2px;
  }
  .sil_btn-ac-primary-outline:hover,
  .sil_btn-ac-primary-outline:active,
  .sil_btn-ac-primary-outline:focus {
    background: #41b9b8;
    color: #fff;
    opacity: 0.95;
    outline: none;
  }

  .ac-dialog {
    border-radius: 2px;
    background-color: #f2f3f4;
    position: relative;
    min-width: 300px;
    display: inline-block;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
  }
  @media (min-width: 769px) {
    .ac-dialog {
      min-width: 500px;
    }
  }
  .ac-dialog .dialog-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    padding: 14px 0;
    font-size: 18px;
  }
  .ac-dialog .dialog-header .dialog-header-icon {
    width: 22px;
    margin-right: 7px;
  }
  .ac-dialog .dialog-header::selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header::-moz-selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header.photo_ac {
    background: #509bdc;
  }
  .ac-dialog .dialog-header.illust_ac {
    background: #d73a4e;
  }
  .ac-dialog .dialog-header.silhouette_ac {
    background: #41b9b8;
  }
  .ac-dialog .dialog-header.design_ac {
    background: #ffa823;
  }
  .ac-dialog .dialog-header-design_ac {
    text-align: center;
    color: #fff;
    background-color: #ffa823;
    padding-top: 15px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    padding-bottom: 15px;
  }
  .ac-dialog .dialog-header-design_ac::selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-design_ac::-moz-selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-illust_ac {
    text-align: center;
    color: #fff;
    background-color: #d73a4e;
    padding-top: 15px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    padding-bottom: 15px;
  }
  .ac-dialog .dialog-header-illust_ac::selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-illust_ac::-moz-selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-photo_ac {
    text-align: center;
    color: #fff;
    background-color: #509bdc;
    padding-top: 15px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    padding-bottom: 15px;
  }
  .ac-dialog .dialog-header-photo_ac::selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-photo_ac::-moz-selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-silhouette_ac {
    text-align: center;
    color: #fff;
    background-color: #41b9b8;
    padding-top: 15px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    padding-bottom: 15px;
  }
  .ac-dialog .dialog-header-silhouette_ac::selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .dialog-header-silhouette_ac::-moz-selection {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
  .ac-dialog .btn-dialog-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    .ac-dialog .dialog {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .ac-auth-bg-design_ac {
    background-image: url(https://static.photo-ac.com/static/images/banner/photo-world.png);
    background-size: cover;
    background-position: 50%;
    min-height: calc(100% - 320px);
  }

  .ac-auth-bg-illust_ac {
    background-image: url(https://static.photo-ac.com/static/images/banner/ill_world.png);
    background-size: cover;
    background-position: 50%;
    min-height: calc(100% - 320px);
  }

  .ac-auth-bg-silhouette_ac {
    background-image: url(https://static.photo-ac.com/static/assets/image/sil_world.png);
    background-size: cover;
    background-position: 50%;
    min-height: calc(100% - 320px);
  }

  .ac-auth-bg-photo_ac {
    background-image: url(https://static.photo-ac.com/static/images/banner/photo-login-background.jpg);
    background-size: cover;
    background-position: 50%;
    min-height: calc(100% - 320px);
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .member-login-modal {
    text-align: center;
    background-color: rgba(10, 10, 10, 0.59);
  }
  .member-login-modal::before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 100%;
  }
  .member-login-modal .dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .member-login-modal .modal-content {
    border-radius: 2px;
  }
  .member-login-modal .modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  body {
    font-family: 'Work Sans', sans-serif;
    /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    color: #1b2833;
  }

  @font-face {
    font-family: FuturaMedium;
    src: url(https://static.photo-ac.com/static/fonts/FuturaMedium.otf);
  }

  a.color-yellow,
  a.text-acphoto {
    opacity: 0.85;
  }

  .modal {
    z-index: 2003;
  }

  .modal-backdrop {
    z-index: 2002;
  }

  .member-registration-modal .dialog {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  .member-registration-modal .modal-content {
    border-radius: 2px;
  }

  .member-registration-modal .modal-dialog {
    max-width: 500px;
  }

  .member-registration-modal .modal-body {
    padding: 0;
  }

  a.color-yellow:active,
  a.color-yellow:hover,
  a.color-yellow:focus,
  a.text-acphoto:active,
  a.text-acphoto:hover,
  a.text-acphoto:focus {
    opacity: 1;
  }

  .mt-83 {
    margin-top: 83px;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .mt-23 {
    margin-top: 23px;
  }

  .pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .width-60 {
    width: 60px;
    padding-top: 5px;
  }

  .pace-inactive {
    display: none;
  }

  .font-size-12 {
    font-size: 12px;
  }

  .font-size-11 {
    font-size: 11px;
  }

  .pace .pace-progress {
    background: #29d;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
  }

  .layout-background-image {
    min-height: calc(100% - 320px);
  }

  .btn-black {
    background-color: #4c9ade;
    color: #ffffff;
    border-radius: 2px;
    padding-right: 35px;
    padding-left: 35px;
  }

  .btn-green {
    background-color: #4c9ade;
    color: #ffffff;
    border-radius: 2px;
    padding: 10px 40px;
    font-size: 18px;
  }

  .btn-green-no-hover {
    background-color: #4c9ade;
    color: #ffffff;
    border-radius: 2px;
    padding-right: 35px;
    padding-left: 35px;
  }

  .btn-green:hover,
  .btn-green:focus,
  .btn-green:active {
    color: #ffffff;
    outline: none;
    opacity: 0.8;
  }

  .btn-orange {
    background-color: #ffa823;
    color: #ffffff;
    border-radius: 2px;
    padding: 10px 40px;
    font-size: 18px;
  }

  .btn-orange-no-hover {
    background-color: #ffa823;
    color: #ffffff;
    border-radius: 2px;
    padding-right: 35px;
    padding-left: 35px;
  }

  .btn-orange:hover,
  .btn-orange:focus,
  .btn-orange:active {
    color: #ffffff;
    outline: none;
    opacity: 0.8;
  }

  .btn-error {
    background-color: #f65656;
    color: #ffffff;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    border-radius: 2px;
    padding-right: 35px;
    padding-left: 35px;
  }

  .btn-error:hover,
  .btn-error:focus,
  .btn-error:active {
    background-color: #f65656;
    color: #ffffff;
    outline: none;
  }

  .relative {
    position: relative;
  }

  .btn-black:hover,
  .btn-black:focus,
  .btn-black:active {
    color: #ffffff;
    outline: none;
    opacity: 0.8;
  }

  .color-white {
    color: #ffffff;
  }

  .chat-bubble {
    position: relative;
    display: inline-block;
    background: #fff;
    color: #1b2833;
    border: 1px solid #bfbbbb;
    border-radius: 2px;
    outline: 0;
    width: 100%;
  }

  .chat-bubble:after {
    content: ' ';
    position: absolute;
    left: 25px;
    right: 0;
    top: -8px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-left: 1px solid #bfbbbb;
    border-top: 1px solid #bfbbbb;
    transform: rotate(45deg);
  }

  .chat-bubble-right {
    position: relative;
    display: inline-block;
    background: #fff;
    color: #1b2833;
    border: 1px solid #bfbbbb;
    border-radius: 2px;
    outline: 0;
    width: 100%;
  }

  .chat-bubble-right:after {
    content: ' ';
    position: absolute;
    right: 40%;
    top: -8px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-left: 1px solid #bfbbbb;
    border-top: 1px solid #bfbbbb;
    transform: rotate(45deg);
  }

  .dialog {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .full-width {
    width: 100%;
  }

  .text-title2_form_search_top_page {
    width: 90%;
    margin: auto;
  }

  .bg-gray {
    background-color: #f2f3f4;
  }

  .bg-dark {
    background-color: #3e3e3f;
  }

  [dir='rtl'] .btn-social {
    border: 1px solid #39b9b9 !important;
    border-radius: 50% !important;
  }

  .btn-social {
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    outline: none;
    border-radius: 50%;
    border: 1px solid #4c9ade;
    background-color: #ffffff;
    color: #4c9ade;
    width: 50px;
    height: 50px;
  }

  .btn-social:hover,
  .btn-social:focus,
  .btn-social:active {
    outline: none;
    border-radius: 50%;
    border: 1px solid #4c9ade;
    background-color: #4c9ade;
    color: #ffffff;
  }

  .input-acphoto {
    height: 40px;
    border: solid 1px #d0d0d0;
    outline-style: none;
    border-radius: 2px;
    font-size: 14px;
  }

  .input-acphoto:hover,
  .input-acphoto:active,
  .input-acphoto:focus {
    border: solid 1px #b0b0b0;
    box-shadow: #b0b0b0;
    outline-style: none;
  }

  .input-acphoto-filter {
    height: 40px;
    border: none;
    border-radius: 5px !important;
    box-shadow: none;
    background-color: #eff0f1 !important;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }

  .input-acphoto-filter:hover,
  .input-acphoto-filter:active,
  .input-acphoto-filter:focus {
    border: none;
    box-shadow: none;
  }

  .checkbox-acphoto {
    border: 1px solid #4c9ade;
  }

  .checkbox-acphoto:hover,
  .checkbox-acphoto:active,
  .checkbox-acphoto:focus {
    border: 1px solid #4c9ade;
  }

  .btn-acphoto {
    background-color: #4c9ade;
    border-radius: 4px;
    height: 40px;
    color: #1b2833;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }

  .btn-acphoto:hover,
  .btn-acphoto:focus,
  .btn-acphoto:active {
    background-color: #4c9ade;
    color: #1b2833;
    outline: none;
  }

  .btn-dropdown-menu:hover,
  .btn-dropdown-menu:focus,
  .btn-dropdown-menu:active {
    background-color: #ffffff;
    color: #1b2833;
    outline: none;
  }

  .btn-acphoto-o {
    background-color: #2b2b2c;
    border-radius: 4px;
    height: 40px;
    color: #4c9ade;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }

  .btn-acphoto-o:hover,
  .btn-acphoto-o:focus,
  .btn-acphoto-o:active {
    background-color: #1b2833;
    color: #4c9ade;
    outline: none;
  }

  .btn-acill-o {
    background-color: #d9384c;
    border-radius: 4px;
    height: 40px;
    border: solid 1px #d9384c;
    color: #fff;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }

  .btn-acill-o:hover,
  .btn-acill-o:focus,
  .btn-acill-o:active {
    background-color: #d9384c;
    color: #fff;
    outline: none;
  }

  .btn-acsil-o {
    background-color: #39b9b9;
    border-radius: 4px;
    height: 40px;
    border: solid 1px #39b9b9;
    color: #fff;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }

  .btn-acsil-o:hover,
  .btn-acsil-o:focus,
  .btn-acsil-o:active {
    background-color: #39b9b9;
    color: #fff;
    outline: none;
  }

  .btn-acphoto-black-gray {
    background-color: #414143;
    border-radius: 4px;
    color: #fff !important;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    box-shadow: 1px 1px 1px 1px #242424;
  }

  .btn-acphoto-black-gray:hover,
  .btn-acphoto-black-gray:focus,
  .btn-acphoto-black-gray:active {
    background-color: #3b3b3d;
    outline: none;
  }

  .btn-acill-black-gray {
    background-color: #d9384c;
    border-radius: 4px;
    color: #fff !important;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    box-shadow: 1px 1px 1px 1px #242424;
  }

  .btn-acill-black-gray:hover,
  .btn-acill-black-gray:focus,
  .btn-acill-black-gray:active {
    background-color: #d9384c;
    outline: none;
    color: #fff !important;
  }

  .btn-acsil-black-gray {
    background-color: #39b9b9;
    border-radius: 4px;
    color: #fff !important;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    box-shadow: 1px 1px 1px 1px #242424;
  }

  .btn-acsil-black-gray:hover,
  .btn-acsil-black-gray:focus,
  .btn-acsil-black-gray:active {
    background-color: #39b9b9;
    outline: none;
    color: #fff !important;
  }

  .padding-15 {
    padding: 15px;
  }

  .padding-10 {
    padding: 10px;
  }

  .pt11 {
    padding-top: 11px;
  }

  .pt4 {
    padding-top: 4px;
  }

  .ph10 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .pt22 {
    padding-top: 22px;
  }

  .pt30 {
    padding-top: 30px;
  }

  .pt40 {
    padding-top: 40px;
  }

  .pt25 {
    padding-top: 25px;
  }

  .pt20 {
    padding-top: 20px;
  }

  .pt15 {
    padding-top: 15px;
  }

  .pl20 {
    padding-left: 20px;
  }

  .pl15 {
    padding-left: 15px;
  }

  .pr15 {
    padding-right: 15px;
  }

  .pr20 {
    padding-right: 20px;
  }

  .pl30 {
    padding-left: 30px;
  }

  .pr30 {
    padding-right: 30px;
  }

  .pt {
    padding-top: 15px;
  }

  .pb {
    padding-bottom: 15px;
  }

  .pv {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pl20 {
    padding-left: 20px;
  }

  .pb30 {
    padding-bottom: 30px;
  }

  .pt10 {
    padding-top: 10px;
  }

  .pt5 {
    padding-top: 5px;
  }

  .pt50 {
    padding-top: 50px;
  }

  .pb5 {
    padding-bottom: 5px;
  }

  .pb50 {
    padding-bottom: 50px;
  }

  .pb10 {
    padding-bottom: 10px;
  }

  .pb20 {
    padding-bottom: 20px;
  }

  .pb7 {
    padding-bottom: 7px;
  }

  .p0 {
    padding: 0;
  }

  .m0 {
    margin: 0;
  }

  .mr {
    margin-right: 15px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .mb0 {
    margin-bottom: 0px;
  }

  .mb {
    margin-bottom: 15px;
  }

  .mt70 {
    margin-top: 70px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .pv20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pv10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pv30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .h70 {
    height: 50px;
  }

  .h50 {
    height: 50px;
  }

  .h20 {
    height: 20px;
  }

  .pv40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pv50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .ph {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mh15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .mt-2 {
    margin-top: 2px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-20-important {
    margin-top: 20px !important;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .display-inline {
    display: inline;
  }

  .display-block {
    display: block;
  }

  .display-flex {
    display: flex !important;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .align-center {
    align-items: center;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .display-inline-block {
    display: inline-block;
  }

  .position-relative {
    position: relative;
  }

  .ph30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tab-custom {
    text-align: center;
  }

  .tab-custom .tab-content {
    text-align: left;
  }

  .tab-custom ul:first-child {
    margin: 15px 0;
    border-bottom: 0;
    display: inline-block;
  }

  .tab-custom ul li a {
    color: #555;
    border-bottom: 3px solid transparent;
  }

  .tab-custom.tab-actived .nav-tabs > li.active > a,
  .tab-custom.tab-actived .nav-tabs > li.active > a:hover,
  .tab-custom.tab-actived .nav-tabs > li.active > a:focus {
    color: #4c9ade;
  }

  .tab-custom .nav-tabs > li.active > a,
  .tab-custom .nav-tabs > li.active > a:focus,
  .tab-custom .nav-tabs > li.active > a:hover {
    color: #555;
    cursor: default;
    background-color: transparent;
    border-bottom: 3px solid #4c9ade;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .tab-custom .nav-tabs > li > a:hover {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .tab-custom .nav-tabs > li {
    margin-bottom: -3px;
  }

  .tab-custom .nav-tabs > li > a {
    border: 0;
    font-weight: 900;
  }

  .tab-custom .nav > li > a:focus,
  .tab-custom .nav > li > a:hover {
    background-color: transparent;
    border-bottom: none;
    font-weight: 900;
    color: #4c9ade;
  }

  .color-photo_ac {
    color: #509bdc;
  }

  .color-illust_ac {
    color: #d73a4e;
  }

  .color-silhouette_ac {
    color: #41b9b8;
  }

  .color-design_ac {
    color: #ffa823;
  }

  .nav-custom {
    text-align: center;
  }

  .nav-custom .tab-content {
    text-align: left;
  }

  .nav-custom ul:first-child {
    margin: 15px 0;
    border-bottom: 0;
    display: inline-block;
  }

  .nav-custom ul li a {
    color: #555;
    border-bottom: 3px solid transparent;
  }

  .nav-custom.tab-actived .nav-tabs > li.active > a,
  .nav-custom.tab-actived .nav-tabs > li.active > a:hover,
  .nav-custom.tab-actived .nav-tabs > li.active > a:focus {
    color: #4c9ade;
  }

  .nav-custom .nav-tabs > li.active > a,
  .nav-custom .nav-tabs > li.active > a:focus,
  .nav-custom .nav-tabs > li.active > a:hover {
    color: #555;
    cursor: default;
    background-color: transparent;
    border-bottom: 3px solid #4c9ade;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .nav-custom .nav-tabs > li > a:hover {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .nav-custom .nav-tabs > li {
    margin-bottom: -3px;
  }

  .nav-custom .nav-tabs > li > a {
    border: 0;
    font-weight: 900;
  }

  .nav-custom .nav > li > a:focus,
  .nav-custom .nav > li > a:hover {
    background-color: transparent;
    border-bottom: none;
    font-weight: 900;
    color: #4c9ade;
  }

  .bold {
    font-weight: bold;
  }

  .bg-white {
    background-color: #fff;
  }

  .bg-yellow,
  .bg-yellow.title-head {
    background-color: #ffa823;
  }

  .bg-gray-head {
    background-color: #3e3e3f;
  }

  .border-left {
    border-left: 1px solid #d1d3d4;
  }

  .border-right {
    border-right: 1px solid #d1d3d4;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none !important;
  }

  a.color-yellow:hover,
  a.color-yellow:focus,
  a.color-yellow:active {
    color: #4c9ade;
  }

  textarea {
    resize: vertical;
  }

  .border-yellow,
  .border-yellow:hover,
  .border-yellow:focus {
    border-color: #4c9ade !important;
    box-shadow: none !important;
    outline-style: none;
  }

  .btn-yellow,
  .btn-yellow:hover,
  .btn-yellow:focus {
    background-color: #4c9ade !important;
    box-shadow: none;
    outline-style: none;
    color: #ffffff;
  }

  .btn-yellow-o,
  .btn-yellow-o:hover,
  .btn-yellow-o:active,
  .btn-yellow-o:focus {
    height: 40px;
    background-color: #ffffff;
    outline-style: none;
    color: #4c9ade;
    border: 1px solid #4c9ade;
  }

  .text-acphoto {
    color: #4c9ade;
  }

  .text-acphoto:hover,
  .text-acphoto:active,
  .text-acphoto:focus {
    /*color: #4c9ade;*/
  }

  .text-white {
    color: #ffffff;
  }

  .text-green {
    color: #8dc63f;
  }

  a.text-black,
  .text-black {
    color: #1b2833;
  }

  .cursor-pointer.text-black,
  .cursor-pointer.text-black:hover,
  .cursor-pointer.text-black:active,
  .cursor-pointer.text-black:focus {
    color: #1b2833;
  }

  .text-black:hover,
  .text-black:active,
  .text-black:focus,
  a.text-black:hover,
  a.text-black:active,
  a.text-black:focus {
    color: #1b2833;
  }

  .text-red {
    color: #e80316;
  }

  .font-size-10 {
    font-size: 10px;
  }

  .font-size-18 {
    font-size: 18px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-size-22 {
    font-size: 22px;
  }

  .font-size-25 {
    font-size: 25px;
  }

  .font-size-35 {
    font-size: 35px;
  }

  .font-size-50 {
    font-size: 50px;
  }

  .btn-social-black {
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    outline: none;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background-color: transparent;
    width: 45px;
    color: #fff !important;
    height: 45px;
  }

  .btn-social-black:hover,
  .btn-social-black:focus,
  .btn-social-black:active {
    outline: none;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: #ffffff;
  }

  .label-black {
    color: #4c9ade;
    background-color: #3e3e3f;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .btn-social-yellow {
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    outline: none;
    border-radius: 50%;
    border: 1px solid #4c9ade;
    background-color: transparent;
    width: 50px;
    height: 50px;
    color: #4c9ade;
  }

  .btn-small.btn-social-yellow {
    width: 40px;
    height: 40px;
  }

  .btn-social-yellow:hover,
  .btn-social-yellow:focus,
  .btn-social-yellow:active {
    outline: none;
    background-color: #ffffff;
    border-radius: 50%;
    color: #4c9ade;
  }

  .form-control:focus {
    border-color: #b0b0b0;
    box-shadow: #b0b0b0;
  }

  a,
  button,
  input {
    outline: 0 !important;
  }

  .row5 {
    margin-right: -5px;
    margin-left: -5px;
  }

  .title-head {
    background-color: #3e3e3f;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    font-size: 22px;
  }

  .drop-down-yellow .dropdown-menu {
    padding: 0;
    border-radius: 4px;
    margin-top: 5px;
  }

  .btn-bg-acwork {
    background-color: #4c9ade;
    color: white;
  }

  .drop-down-yellow .dropdown-menu > .active > a,
  .drop-down-yellow .dropdown-menu li > a:hover,
  .drop-down-yellow .dropdown-menu > .active > a:focus,
  .drop-down-yellow .dropdown-menu > .active > a:hover {
    background-color: #4c9ade;
    color: #ffffff;
    text-decoration: none;
  }

  .drop-down-yellow .dropdown-menu li a {
    line-height: 40px;
    height: 40px;
    padding-top: 0;
  }

  .btn-follow,
  .btn-follow:hover,
  .btn-follow:focus,
  .btn-follow:active {
    background-color: #3e3a39;
    color: #4c9ade;
    border: 0;
    border-radius: 2px;
  }

  .btn-gray {
    background-color: #f1f2f1;
    border: 0;
    color: #1b2833;
  }

  .btn-gray:hover,
  .btn-gray:focus,
  .btn-gray:active {
    background-color: #edeeed;
    border-radius: 2px;
  }

  .btn-link-ac {
    background-color: transparent;
    color: #ffa823;
  }

  .btn-link-ac:hover,
  .btn-link-ac:active,
  .btn-link-ac:focus {
    background-color: transparent;
    color: #ffa823;
  }

  .mypage-title-icon {
    color: #e2bf20;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-appear {
    opacity: 0.01;
  }

  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(1%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slideDown {
    0% {
      -webkit-transform: translateY(1%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }

  @keyframes slideDownTop {
    0% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slideDownTop {
    0% {
      -webkit-transform: translateY(50%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }

  .bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }
    60% {
      opacity: 1;
      -webkit-transform: translateX(-30px);
    }
    80% {
      -webkit-transform: translateX(10px);
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    60% {
      opacity: 1;
      transform: translateX(-10px);
    }
    80% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }
    60% {
      opacity: 1;
      -webkit-transform: translateX(30px);
    }
    80% {
      -webkit-transform: translateX(-10px);
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    60% {
      opacity: 1;
      transform: translateX(10px);
    }
    80% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @-webkit-keyframes bounceOutRight {
    0% {
      -webkit-transform: translateX(0);
    }
    20% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(100px);
    }
  }

  @keyframes bounceOutRight {
    0% {
      transform: translateX(0);
    }
    20% {
      opacity: 1;
      transform: translateX(-20px);
    }
    100% {
      opacity: 0;
      transform: translateX(100px);
    }
  }

  .bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
    animation-duration: 3s;
  }

  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
  }

  .example-enter {
    opacity: 0.01;
  }

  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .example-leave {
    opacity: 1;
  }

  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  .border-bottom {
    border-bottom: 1px solid #dbdcdc;
  }

  .text-wrap {
    white-space: normal;
  }

  .list-custom {
    margin: 0;
    padding: 0 0 0 25px;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid #4c9ade;
    border-radius: 50%;
    outline: none;
    background-color: #fff;
    box-shadow: 0 0 5px 5px #fff inset;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    margin-left: 3px;
    margin-top: 3px;
    border-radius: 50%;
  }

  input[type='radio']:checked:before {
    box-shadow: 0 0 5px 10px #4c9ade inset;
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  .form-field-height {
    min-height: 90px;
  }

  .field-message-error {
    z-index: 100;
    border-radius: 2px;
    position: absolute;
    width: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 3px 5px 0 rgba(176, 176, 176, 0.8);
  }

  .field-message-error .animated {
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .font-loader {
    position: absolute;
    top: 13px;
    right: 10px;
    font-size: 16px;
    color: #4c9ade;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .action-active {
    color: #4c9ade !important;
    border-color: #4c9ade;
  }

  .action-button-active {
    color: #4c9ade !important;
  }

  .btn-group-social > a > i {
    margin-top: 5px;
  }

  .slick-prev,
  .slick-next {
    z-index: 10;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }

  .text-underline {
    border-bottom: 1px solid #4c9ade;
    padding-bottom: 2px;
  }

  .lh40 {
    line-height: 40px;
  }

  a.link {
    color: #1b2833;
  }

  a.text-white {
    color: #fff;
  }

  .pb200 {
    padding-bottom: 200px;
  }

  .dotted-gradient {
    background-image: linear-gradient(
      to right,
      #1b2833 40%,
      rgba(255, 255, 255, 0) 20%
    );
    background-position: top;
    background-size: 3px 1px;
    background-repeat: repeat-x;
  }

  .font-weight-500 {
    font-weight: 500;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  input[type='checkbox'] {
    display: none;
  }

  label input[type='checkbox'] ~ b {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    background: #fff;
    border: 1px solid #cccdce;
    padding: 1px;
    height: 17px;
    width: 17px;
    margin-top: -3px;
  }

  .photo_style label input[type='checkbox']:checked ~ b {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpath fill='%234c9ade' d='M14 18L26 6l4 4-16 16L4 16l4-4z'/%3E%3C/svg%3E");
    background-size: 100%;
  }

  .sil_style label input[type='checkbox']:checked ~ b {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpath fill='%2339b9b9' d='M14 18L26 6l4 4-16 16L4 16l4-4z'/%3E%3C/svg%3E");
    background-size: 100%;
  }

  .ill_style label input[type='checkbox']:checked ~ b {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpath fill='%23d9384b' d='M14 18L26 6l4 4-16 16L4 16l4-4z'/%3E%3C/svg%3E");
    background-size: 100%;
  }

  .table.table-border-none > thead > tr > th,
  .table.table-border-none > tbody > tr > td,
  .table.table-border-none > tbody > tr > th,
  .table.table-border-none > tfoot > tr > td,
  .table.table-border-none > tfoot > tr > th,
  .table.table-border-none > thead > tr > td,
  .table.table-border-none > thead > tr > th {
    border: none;
  }

  .ill_style .title-head.bg-yellow,
  .ill_style .title-head.bg-yellow,
  .sil_style .title-head,
  .sil_style .title-head {
    color: #fff;
  }

  .border-dashed-bottom.border-last-none:last-of-type {
    border-bottom: 0;
  }

  .mypage-input {
    height: 40px;
    border-radius: 2px;
  }

  .modal-confirm .modal-content {
    background-color: #3e3a39;
    color: #fff;
  }

  .middle {
    vertical-align: middle;
  }

  @media (min-width: 768px) {
    .col-md-5 {
      float: left;
      width: calc(100% / 5);
    }
    .navbar-head .col-md-5 {
      float: left;
      width: 41.66666667%;
    }
  }

  .btn-avatar-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 5px;
    border: none;
    margin-bottom: 15px;
    background: transparent;
  }

  .btn-avatar-icon:hover,
  .btn-avatar-icon:focus,
  .btn-avatar-icon:active,
  .btn-avatar-icon.active {
    padding: 0;
    border: 5px solid #e7c700;
  }

  .btn-header-icon {
    background: transparent;
    margin: 1px 10px;
    border: 5px solid transparent;
    padding: 0;
    border-radius: 0;
  }

  .nav > li.disabled > a {
    color: rgba(119, 119, 119, 0.780392);
  }

  .btn-header-icon:hover,
  .btn-header-icon:focus,
  .btn-header-icon:active,
  .btn-header-icon.active {
    border: 5px solid #e7c700;
  }

  .input-group-social > .input-group-addon {
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-color: #b0b0b0;
    padding-right: 2px;
    min-width: 30px;
  }

  .input-group-social > .form-control.input-acphoto {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .color-facebook {
    color: #6081c4;
  }

  .color-twitter {
    color: #43bdef;
  }

  .color-google-plus {
    color: #e62b33;
  }

  .color-instagram {
    color: #864c2d;
  }

  .avatar-circle {
    border-radius: 50%;
  }

  .btn-upload {
    text-decoration: none !important;
  }

  #control-number-filter,
  #control-number-filter:hover,
  #control-number-filter:focus,
  #filter,
  #filter:hover,
  #filter:focus {
    padding: 5px 15px;
    color: #1b2833;
    text-decoration: none;
  }

  .lh-23 {
    line-height: 23px;
  }

  .h50 {
    height: 50px;
  }

  .display-none {
    display: none !important;
  }

  .background-image-component {
    width: 100%;
    height: 300px;
  }

  .avatar-img {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .avatar-center {
    margin: 0 auto;
  }

  .person.dropdown:hover > .dropdown-menu,
  .person.dropdown:focus > .dropdown-menu,
  .person.dropdown:active > .dropdown-menu {
    display: inline-block !important;
  }

  .list-style-type-none {
    padding-left: 20px;
    list-style-type: none;
  }

  .dotted-bottom {
    border-bottom: 1px #c2c4cc dotted;
  }

  .btn-blue {
    background-color: #39c;
    color: #fff !important;
    transition: all 200ms ease;
  }

  .btn-blue:hover,
  btn-blue:focus,
  btn-blue:active {
    background-color: #3192c3;
    color: #fff !important;
  }

  .btn-premium {
    background-color: #69c616;
    border-radius: 50px;
    color: #fff;
    transition: all 200ms ease;
  }

  .btn-premium:hover {
    background-color: #64bc15;
    border-radius: 50px;
    color: #fff;
  }

  .premium-footer {
    background-color: #39c;
    height: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .text-blue {
    color: #39c;
  }

  .text-psd {
    color: #39c;
  }

  .text-ai {
    color: #ed8b00;
  }

  .text-eps {
    color: #d85142;
  }

  .premium input[type='radio'] {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid #797979;
    border-radius: 50%;
    outline: none;
    background-color: #fff;
    box-shadow: 0 0 5px 5px #fff inset;
  }

  .premium input[type='radio']:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    margin-left: 3px;
    margin-top: 3px;
    border-radius: 50%;
  }

  .premium input[type='radio']:checked:before {
    box-shadow: 0 0 5px 10px #797979 inset;
  }

  .router-navigation .pull-right > div.ads {
    height: auto;
    min-width: 600px;
  }

  .bg-yellow-premium {
    background-color: #fff59d;
  }

  @media (min-width: 768px) {
    .photo-detail-search-results .col-sm-7 {
      width: calc(100% / 7);
    }
    .photo-detail-search-results .col-sm-7 {
      float: left;
    }
  }

  .btn.btn-avatar-icon > img {
    border-radius: 50%;
    overflow: hidden;
  }

  .pr0 {
    padding-right: 0;
  }

  .config.dropdown .list-unstyled {
    width: 100%;
  }

  .config.dropdown .item-filter .col-xs-8,
  .config.dropdown .item-filter .col-xs-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .text-jp {
    -ms-writing-mode: tb-rl;
    /* old syntax. IE */
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    /* new syntax */
    -webkit-text-orientation: upright;
    -moz-text-orientation: upright;
    -ms-text-orientation: upright;
    text-orientation: upright;
  }

  .modal {
    text-align: center;
  }

  .min-height-280 {
    min-height: 280px;
  }

  .min-height-300 {
    min-height: 300px;
  }

  @media screen and (min-width: 768px) {
    .modal:before {
      display: inline-block;
      vertical-align: middle;
      content: ' ';
      height: 100%;
    }
  }

  .fs08em {
    font-size: 0.8em;
  }

  .fs09em {
    font-size: 0.9em;
  }

  @media screen and (max-width: 767px) {
    .modal:before {
      /*display: inline-block;*/
      /*vertical-align: middle;*/
      /*content: " ";*/
      /*height: 100%;*/
    }
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .width-100 {
    width: 100px;
  }

  .width-20 {
    width: 20px;
  }

  .padding-left-right-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .modal-content {
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }

  .sampleContainer {
    margin-top: 250px;
  }

  .loader {
    position: relative;
    width: 44px;
    height: 8px;
    margin: 12px auto;
  }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #ccc;
    position: absolute;
  }

  .dot_1 {
    animation: animateDot1 1.5s linear infinite;
    left: 12px;
    background: #e579b8;
  }

  .dot_2 {
    animation: animateDot2 1.5s linear infinite;
    animation-delay: 0.5s;
    left: 24px;
  }

  .dot_3 {
    animation: animateDot3 1.5s linear infinite;
    left: 12px;
  }

  .dot_4 {
    animation: animateDot4 1.5s linear infinite;
    animation-delay: 0.5s;
    left: 24px;
  }

  @media (max-width: 767px) {
    .download-photo-item {
      min-width: 0 !important;
    }
  }

  @keyframes animateDot1 {
    0% {
      transform: rotate(0deg) translateX(-12px);
    }
    25% {
      transform: rotate(180deg) translateX(-12px);
    }
    75% {
      transform: rotate(180deg) translateX(-12px);
    }
    100% {
      transform: rotate(360deg) translateX(-12px);
    }
  }

  @keyframes animateDot2 {
    0% {
      transform: rotate(0deg) translateX(-12px);
    }
    25% {
      transform: rotate(-180deg) translateX(-12px);
    }
    75% {
      transform: rotate(-180deg) translateX(-12px);
    }
    100% {
      transform: rotate(-360deg) translateX(-12px);
    }
  }

  @keyframes animateDot3 {
    0% {
      transform: rotate(0deg) translateX(12px);
    }
    25% {
      transform: rotate(180deg) translateX(12px);
    }
    75% {
      transform: rotate(180deg) translateX(12px);
    }
    100% {
      transform: rotate(360deg) translateX(12px);
    }
  }

  @keyframes animateDot4 {
    0% {
      transform: rotate(0deg) translateX(12px);
    }
    25% {
      transform: rotate(-180deg) translateX(12px);
    }
    75% {
      transform: rotate(-180deg) translateX(12px);
    }
    100% {
      transform: rotate(-360deg) translateX(12px);
    }
  }

  .mb5 {
    margin-bottom: 5px;
  }

  .loader-payment,
  .loader-payment:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader-payment {
    margin: 0 auto;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(222, 222, 222, 0.7);
    border-right: 1.1em solid rgba(222, 222, 222, 0.7);
    border-bottom: 1.1em solid rgba(222, 222, 222, 0.7);
    border-left: 1.1em solid #29d;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  [data-google-query-id] {
    display: block;
    width: auto;
    height: auto;
  }

  .pl0 {
    padding-left: 0px;
  }

  .pl10 {
    padding-left: 10px;
  }

  .pr10 {
    padding-right: 10px;
  }

  .pr5 {
    padding-right: 5px;
  }

  .pl5 {
    padding-left: 5px;
  }

  .ml5 {
    margin-left: 5px;
  }

  .hidden-gird {
    opacity: 0;
    height: 1px;
  }

  .host-gird {
    width: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: auto;
  }

  .loading-gird {
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 100%;
    float: left;
    margin-right: 10px;
  }

  .loading-0 {
    -webkit-animation: bounce 1s infinite;
    -webkit-animation-delay: 0.1s;
    background: #1b2833;
  }

  .loading-1 {
    -webkit-animation: bounce 1s infinite;
    -webkit-animation-delay: 0.3s;
    background: #1b2833;
  }

  .loading-2 {
    -webkit-animation: bounce 1s infinite ease;
    -webkit-animation-delay: 0.5s;
    background: #1b2833;
  }

  @-webkit-keyframes bounce {
    0%,
    100% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
  }

  h2.photo-title {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1b2833;
    margin: 0;
    font-weight: 400;
  }

  h1.photo-title {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1b2833;
    margin: 0;
    font-weight: 400;
  }

  h2.similar-photo {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1b2833;
    margin: 0;
    font-weight: 500;
  }

  .pagination-wrapper {
    float: right;
    margin-right: 10px;
  }

  .border-right-filter {
    height: 30px;
    width: 1px;
    border: 1px solid rgba(105, 112, 119, 0.3);
    margin-top: 1px;
  }

  @media (max-width: 767px) {
    .modal-content .photo-detail .slick-initialized.slick-slider .slick-track {
      max-width: 300px !important;
    }
    .pagination-wrapper {
      float: none;
      text-align: center;
      margin-top: 10px;
      margin-right: 0;
    }
  }

  .navigator-footer .text-center + .pagination-wrapper {
    margin-top: -42px;
  }

  .btn-more-home {
    /*padding: 11px 30px;*/
    padding: 10px 40px;
    font-size: 18px;
  }

  .ill_style .header-app-des-logo {
    margin-top: 8px;
    margin-left: 55px;
  }

  .ill_style .home-page .header-app-des-logo {
    top: 10px;
    margin-left: 55px;
  }

  .photo_style .home-page .header-app-des-logo {
    top: 10px;
  }

  .sil_style .home-page .header-app-des-logo {
    top: 10px;
  }

  .ill_style .text-description {
    color: #3e3e3f;
  }

  .ill_style .collection-title,
  .ill_style .other-title {
    color: #3e3e3f;
    font-weight: 900;
  }

  .ill_style .collection-title span,
  .ill_style .other-title span {
    font-size: 17px;
    font-weight: normal;
    margin-left: 8px;
  }

  h3.subscribe {
    padding-top: 100px;
    padding-bottom: 20px;
  }

  @media (max-width: 767px) {
    h3.subscribe {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  /* css for arabic */
  [dir='rtl'] .gallery .image-detail,
  [dir='rtl'] .gallery-image {
    float: right;
  }

  [lang='ar-AR'] input[type='radio']:before {
    margin-right: 3px;
  }

  [dir='rtl'] .menu-fix-top-slideshow {
    left: auto;
    right: 15px;
  }

  [dir='rtl'] .header-app-des-logo {
    margin-left: 0;
  }

  [dir='rtl'] .home-form .search-bar .input-group-btn .btn-search,
  [dir='rtl'] .header-form .search-bar .input-group-btn .btn-search {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
  }

  [dir='rtl'] .btn-search {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 20px;
    padding-right: 15px;
  }

  [dir='rtl'] .search-bar .txt-search {
    height: 48px;
    border-left: 0;
    border-right: 1px solid #ccc;
    border-top-right-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
  }

  [dir='rtl'] .home-form .search-bar .dropdown__content,
  [dir='rtl'] .header-form .search-bar .dropdown__content {
    right: auto;
    left: 0;
  }

  [dir='rtl'] .fleft {
    float: right;
  }

  [dir='rtl'] .form-subscribe .btn {
    border-radius: 3px 0 0 3px;
  }

  [dir='rtl'] .form-subscribe input {
    border-radius: 0 3px 3px 0;
  }

  [dir='rtl'] .scroll-top-top.show-scroll {
    right: auto;
    left: 5px;
    -webkit-transition: left 0.4s ease-in-out;
    -moz-transition: left 0.4s ease-in-out;
    -ms-transition: left 0.4s ease-in-out;
    -o-transition: left 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;
  }

  [dir='rtl'] .scroll-top-top.hide-scroll {
    -webkit-transition: left 0.4s ease-in-out;
    -moz-transition: left 0.4s ease-in-out;
    -ms-transition: left 0.4s ease-in-out;
    -o-transition: left 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;
    /* right: -5px; */
  }

  [dir='rtl'] .scroll-top-top {
    right: auto;
    left: -29px;
  }

  [dir='rtl'] .notification {
    right: 20px;
    left: auto;
  }

  [dir='rtl'] .pull-left {
    float: right !important;
  }

  [dir='rtl'] .pull-right {
    float: left !important;
  }

  [dir='rtl'] .popover.top > .arrow {
    right: 40px;
  }

  [dir='rtl'] .language-select .row .col-xs-6:first-child {
    padding-left: 0;
    padding-right: 15px;
  }

  [dir='rtl'] .language-select .row .col-xs-6:last-child {
    padding-right: 0;
    padding-left: 15px;
  }

  [dir='rtl'] .photo-ac-terms .container,
  [dir='rtl'] .privacy-policy .container {
    direction: ltr;
  }

  [dir='rtl'] .col-sm-4.text-right.pt4 > .display-inline-block {
    float: left;
  }

  [dir='rtl'] .pagination-lib .fa-chevron-left:before {
    content: '\f054';
  }

  [dir='rtl'] .pagination-lib .fa-chevron-right:before {
    content: '\f053';
  }

  [dir='rtl'] .btn-group-photo-detail-mobile .btn + .btn {
    margin-right: 14px;
  }

  [dir='rtl'] .router-navigation .text-right > .display-inline-block {
    float: left;
  }

  [dir='rtl'] .top-download img {
    margin-right: 15px;
    margin-left: 0;
  }

  [dir='rtl'] .Select-value,
  .Select-multi-value-wrapper {
    float: right !important;
  }

  .Select--multi .Select-value {
    margin-right: 5px !important;
    margin-left: 0 !important;
  }

  [dir='rtl'] .Select-menu-outer {
    direction: rtl;
  }

  [dir='rtl'] .wrapper-top-download-2 {
    border-left: 0;
    border-right: 1px solid #ccc;
    float: left;
    padding-left: 15px;
    padding-right: 0;
  }

  [dir='rtl'] .top-download span.text-acphoto {
    padding-left: 0;
    padding-right: 10px;
  }

  [dir='rtl'] a.tag {
    margin-right: 0;
    margin-left: 15px;
  }

  [dir='rtl'] .wap-people-container,
  [dir='rtl'] .wap-download-container {
    right: auto;
    left: 0;
  }

  [dir='rtl'] .icon-left-popup {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  [dir='rtl'] .right-0 {
    left: 0;
    right: auto;
  }

  [dir='rtl'] .wap-text-peopleReview {
    float: left;
  }

  [dir='rtl'] .bounceInRight {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
  }

  [dir='rtl'] .ill_style .header-app-des-logo {
    margin-right: 12px;
  }

  .count-number-download .odometer.odometer-theme-default .odometer-inside {
    direction: ltr;
  }

  [dir='rtl'] .user-request-not-display {
    left: 0;
    right: auto;
  }

  [dir='rtl'] .user-request {
    left: 0;
    right: auto;
  }

  [dir='rtl'] .avatar-user-request {
    float: right;
    border-radius: 0 4px 4px 0;
  }

  [dir='rtl'] .user-request .wrapper-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }

  [dir='rtl']
    .search-bar
    .form-group
    .input-group
    .txt-search.form-control.visible-xs {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  [dir='rtl'] .search-bar .form-group .input-group .btn.btn-search-responsive {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  [dir='rtl'] .navbar-head .navbar-inverse .navbar-toggle {
    float: right;
  }

  [dir='rtl'] .nav.navbar-nav.navbar-right .wrapper-ul-lang li {
    padding-right: 10px;
  }

  [dir='rtl'] .navbar-head .col-xs-6.wrapper-ul-lang.pr0 {
    padding-left: 0;
    padding-right: 15px;
  }

  [dir='rtl'] .navbar-head .col-xs-6.wrapper-ul-lang.pl0 {
    padding-left: 15px;
    padding-right: 0;
  }

  [dir='rtl'] .navbar-head .nav.navbar-nav.navbar-right .wrapper-ul-lang li {
    padding: 4px 20px;
    white-space: nowrap;
  }

  [dir='rtl'] .home-page .col-xs-6.wrapper-ul-lang.pr0 {
    padding-left: 0;
    padding-right: 15px;
  }

  [dir='rtl'] .home-page .col-xs-6.wrapper-ul-lang.pl0 {
    padding-left: 15px;
    padding-right: 0;
  }

  [dir='rtl'] .home-page .nav.navbar-nav.navbar-right .wrapper-ul-lang li {
    padding: 4px 20px;
    white-space: nowrap;
  }

  [dir='rtl'] .home-page .wrapper-popular-search {
    word-wrap: break-word;
    color: white;
    background-color: rgba(10, 10, 10, 0.48);
    position: absolute;
    width: 40%;
    margin-left: 0;
    margin-right: 30%;
    border-radius: 5px;
  }

  [dir='rtl'] .wrapper-api-docs .menu-left .menu-item-submenu i.ti-plus {
    font-size: 12px;
    float: right;
    margin-top: 3px;
  }

  [dir='rtl'] .wrapper-api-docs .menu-left .menu-item-submenu {
    padding-left: 0;
  }

  [dir='rtl'] .wrapper-api-docs pre code:nth-child(1) {
    float: right !important;
    border-left: 1px solid #ccc;
    border-right: none;
    margin-right: 0;
    margin-left: 15px;
    padding-left: 7px;
    padding-right: 0 !important;
    text-align: center;
  }

  @media (min-width: 768px) {
    [dir='rtl']
      .home-page
      nav.navbar.navbar-default
      .navbar-collapse.collapse
      .navbar-right {
      float: left !important;
      margin-left: 10px;
    }
    [dir='rtl'] .navbar-head .navbar-photo-ac .col-md-4.pl0 {
      padding-right: 0;
      padding-left: 10px;
    }
    [dir='rtl'] .navbar-head .navbar-photo-ac .col-md-2 {
      padding-right: 30px;
    }
    [dir='rtl'] .download-right .pv.row > .col-sm-6.dl {
      padding-left: 7px;
      padding-right: 15px;
    }
    [dir='rtl'] .download-right .pv.row > .col-sm-6.fd {
      padding-right: 7px;
      padding-left: 15px;
    }
    [dir='rtl'] input[type='radio']:before {
      margin-left: 0;
      margin-right: 3px;
    }
    [dir='rtl'] .wrapper-arrow-cate:after,
    [dir='rtl'] .wrapper-arrow-cate:before {
      left: 0;
      right: 90%;
    }
    [dir='rtl'] .wrapper-arrow-lang:after,
    [dir='rtl'] .wrapper-arrow-lang:before {
      left: 0;
      right: 85%;
    }
    [dir='rtl'] .wrapper-menu-more:after,
    [dir='rtl'] .wrapper-menu-more:before {
      left: 0;
      right: 83%;
    }
  }

  .title-breadcrumbs {
    display: inline-block;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-16 {
    font-size: 16px;
  }

  /* css for arabic */
  @media (max-width: 991px) {
    .dark-footer.sil-footer
      > .hidden-xs.pb30
      > .text-center:nth-child(2n)
      > .category-footer
      > div {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 767px) {
    .container-fluid > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container > .navbar-header {
      margin-right: 0;
    }
    .hide-ads-mobile {
      overflow: hidden;
    }
    .router-navigation .col-sm-4.text-right {
      display: inline-block;
      float: right;
    }
    .router-navigation .url-form.col-sm-8 {
      display: inline-block;
    }
    .navigator-footer.pv50 {
      position: relative;
      padding-top: 86px;
    }
    .navigator-footer .pagination-wrapper {
      position: absolute;
      top: 0;
      right: 10px;
      margin-top: 10px !important;
    }
  }

  .form-group .error-messages {
    -webkit-transition: height 0.35s ease-in-out;
    -moz-transition: height 0.35s ease-in-out;
    -ms-transition: height 0.35s ease-in-out;
    -o-transition: height 0.35s ease-in-out;
    transition: height 0.35s ease-in-out;
    height: 0;
  }

  .form-group .error-messages.show-message {
    -webkit-transition: height 0.35s ease-in-out;
    -moz-transition: height 0.35s ease-in-out;
    -ms-transition: height 0.35s ease-in-out;
    -o-transition: height 0.35s ease-in-out;
    transition: height 0.35s ease-in-out;
    height: 37px;
  }

  .form-group .error-messages .field-message-error {
    padding: 5px;
    position: static;
  }

  .input-form {
    position: relative;
  }

  .ill_style .home-page .ads-line,
  .sil_style .home-page .ads-line,
  .photo_style .home-page .ads-line {
    background-color: white !important;
  }

  .photo_style .search-page .ads-line,
  .ill_style .search-page .ads-line,
  .sil_style .search-page .ads-line {
    background-color: #f2f3f4 !important;
  }

  .photo_style .category-page .ads-line,
  .ill_style .category-page .ads-line,
  .sil_style .category-page .ads-line {
    background-color: #f2f3f4 !important;
  }

  .sil_style .recommend-page .ads-line,
  .ill_style .recommend-page .ads-line,
  .photo_style .recommend-page .ads-line {
    background-color: #f2f3f4 !important;
  }

  .count-down-particles {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    top: 0;
    position: absolute;
    color: #fff;
  }

  .count-down-premium {
    display: flex;
    padding-top: 15px;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .count-down-format {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 2px;
  }

  .price-sale {
    background-image: url(/assets/image/premium/register-premium/sale-off.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 49px;
    height: 49px;
    font-size: 18px;
    display: flex;
    margin-left: 7px;
    margin-right: 7px;
    justify-content: center;
    align-items: center;
  }

  .price-sale-mypage {
    background-image: url(/assets/image/premium/register-premium/sale-off.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 49px;
    height: 49px;
    display: flex;
    margin-left: 7px;
    margin-right: 7px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
    flex-direction: column;
  }

  .count-down-format-item {
    padding: 4px 9px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 4px;
    border-radius: 5px;
    font-size: 13px;
  }

  .count-down-format-item-premium {
    padding: 13px 20px;
    font-size: 16px;
    margin: 0 4px;
    font-weight: 500;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .day---acronym {
    display: none;
  }

  .StripeElement {
    display: block;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    outline: 0;
    border-radius: 4px;
    background: white;
    width: 100%;
    max-width: 100%;
    border: 1px solid rgba(221, 221, 221, 0.5);
    padding: 12px;
    box-shadow: none;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .credit-card .error-messages.show-message {
    -webkit-transition: height 0.35s ease-in-out;
    -moz-transition: height 0.35s ease-in-out;
    -ms-transition: height 0.35s ease-in-out;
    -o-transition: height 0.35s ease-in-out;
    transition: height 0.35s ease-in-out;
    height: 37px;
  }

  .credit-card .error-messages {
    -webkit-transition: height 0.35s ease-in-out;
    -moz-transition: height 0.35s ease-in-out;
    -ms-transition: height 0.35s ease-in-out;
    -o-transition: height 0.35s ease-in-out;
    transition: height 0.35s ease-in-out;
    height: 0;
  }

  .credit-card .error-messages .field-message-error {
    padding: 5px;
  }

  @media (max-width: 768px) {
    .count-down-format-item {
      padding: 8px;
      font-size: 14px;
    }
    .day---hidden {
      display: none;
    }
    .day---acronym {
      display: inline-block;
    }
  }

  .tag-list {
    padding-bottom: 15px;
  }

  .bg-white {
    background-color: #fff;
  }

  .color-design_ac {
    color: #ffa823;
  }

  .bg-illust_ac {
    background-color: #d73a4e;
  }

  .bg-photo_ac {
    background-color: #509bdc;
  }

  .bg-silhouette_ac {
    background-color: #41b9b8;
  }

  .bg-design_ac {
    background-color: #ffa823;
  }

  .bg-premium-yellow {
    background-color: #d73a4e;
  }

  .bg-op2-illust_ac {
    background-color: #efd2d7;
  }

  .bg-op2-photo_ac {
    background-color: #cbdfe5;
  }

  .bg-op2-silhouette_ac {
    background-color: #d1e3e1;
  }

  .bg-op2-design_ac {
    background-color: #ffead4;
  }

  .bg-premium-design_ac {
    background-color: #ffa823;
  }

  label input[type='checkbox']:checked ~ b {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpath fill='%234c9ade' d='M14 18L26 6l4 4-16 16L4 16l4-4z'/%3E%3C/svg%3E");
    background-size: 100%;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mb50 {
    margin-bottom: 50px;
  }

  .button-illust_ac {
    background: #d73a4e;
  }

  .button-illust_ac:hover,
  .button-illust_ac:active,
  .button-illust_ac:focus {
    background: #5e282e !important;
  }

  .button-silhouette_ac {
    background: #41b9b8;
  }

  .button-silhouette_ac:hover,
  .button-silhouette_ac:active,
  .button-silhouette_ac:focus {
    background: #2b5e5a !important;
  }

  .button-design_ac {
    background: #ffa823;
  }

  .button-design_ac:hover,
  .button-design_ac:active,
  .button-design_ac:focus {
    background: #774d17 !important;
  }

  .button-photo_ac {
    background: #509bdc;
  }

  .button-photo_ac:hover,
  .button-photo_ac:active,
  .button-photo_ac:focus {
    background: #1c5362 !important;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-detail-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
  }

  .alert {
    border-radius: 2px !important;
  }

  .subscribe-newsletter.sub-color-photo_ac a {
    color: #509bdc;
  }

  .subscribe-newsletter.sub-color-illust_ac a {
    color: #d73a4e;
  }

  .subscribe-newsletter.sub-color-silhouette_ac a {
    color: #41b9b8;
  }

  .subscribe-newsletter.sub-color-design_ac a {
    color: #ffa823;
  }

  .btn-ac-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    font-size: 14px;
    border-radius: 2px;
    padding: 9px 30px;
    max-height: 40px;
  }

  .btn-ac-default {
    height: 40px;
    padding: 9px 12px !important;
  }

  .btn.btn-ac-primary:focus,
  .btn.btn-ac-primary:hover {
    color: #fff;
  }

  .layout-toast-progress.photo_ac {
    background: #509bdc;
  }

  .layout-toast-progress.illust_ac {
    background: #d73a4e;
  }

  .layout-toast-progress.silhouette_ac {
    background: #41b9b8;
  }

  .layout-toast-progress.design_ac {
    background: #ffa823;
  }

  .layout-toast-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export default commonStyles;
