export const PRE_LOAD_NOTICE = 'containers/MyPage/Notice/PRE_LOAD_NOTICE';
export const LOAD_NOTICE = 'containers/MyPage/Notice/LOAD_NOTICE';
export const PRE_LOAD_MORE_NOTICE =
  'containers/MyPage/Notice/PRE_LOAD_MORE_NOTICE';
export const LOAD_MORE_NOTICE = 'containers/MyPage/Notice/LOAD_MORE_NOTICE';
export const TOGGLE_CONTENT_NOTICE =
  'containers/MyPage/Notice/TOGGLE_CONTENT_NOTICE';

export function getNotices(payload) {
  return { type: PRE_LOAD_NOTICE, payload };
}

export function loadMoreNotices(payload) {
  return { type: PRE_LOAD_MORE_NOTICE, payload };
}

export function toggleContentNotice(params) {
  return { type: TOGGLE_CONTENT_NOTICE, ...params };
}
