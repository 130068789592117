import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_SUCCESS,
} from './constants';
import config from '~/config';

function* loadCategories(action) {
  const { payload, api } = action;

  try {
    let categories = yield call(api.data.getCategoriesFullData, payload);
    yield put({
      type: LOAD_CATEGORIES_SUCCESS,
      categories,
    });
  } catch (error) {
    yield put({ type: LOAD_CATEGORIES_ERROR, error: true });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* categoriesSaga() {
  yield takeLatest(LOAD_CATEGORIES, loadCategories);
}
