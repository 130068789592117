import { call, takeLatest } from 'redux-saga/effects';
import { UNSUBSCRIBE_NEWSLETTER } from './constants';
import * as NewsletterService from '~/services/newsletter';

function* unsub({ resolve, reject, email }) {
  try {
    yield call(NewsletterService.unsubscribeNewsletter, { email });
    resolve();
  } catch (error) {
    reject();
  }
}

export default function* loadRecommendSaga() {
  yield takeLatest(UNSUBSCRIBE_NEWSLETTER, unsub);
}
