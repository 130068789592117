import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  CHANGE_PATTERN,
  LOAD_COUNT_DATA,
  LOAD_COUNT_DATA_ERROR,
  LOAD_COUNT_DATA_SUCCESS,
  LOAD_COUNT_DOWNLOAD_RUN,
  LOAD_COUNT_DOWNLOAD_RUN_ERROR,
  LOAD_COUNT_DOWNLOAD_RUN_SUCCESS,
  LOAD_COUNT_USER,
  LOAD_COUNT_USER_ERROR,
  LOAD_COUNT_USER_SUCCESS,
  LOAD_DATA_TYPE,
  LOAD_DATA_TYPE_ERROR,
  LOAD_DATA_TYPE_SUCCESS,
  LOAD_HOMEPAGE_DATA,
  LOAD_PATTERN_ERROR,
  LOAD_PATTERN_SUCCESS,
  LOAD_RANDOM_SLIDER_IMAGES,
  LOAD_RECOMMEND_LIST_ERROR,
  LOAD_RECOMMEND_LIST_SUCCESS,
  LOAD_SLIDER_IMAGES_ERROR,
  LOAD_SLIDER_IMAGES_SUCCESS,
  LOAD_TAGS_ERROR,
  LOAD_TAGS_SUCCESS,
  SELECTED_PATTERN,
  LOAD_COLLECTIONS_OF_HOMEPAGE,
  LOAD_COLLECTIONS_OF_HOMEPAGE_SUCCESS,
  LOAD_COLLECTIONS_OF_HOMEPAGE_ERROR,
  LOAD_DATA_BLOGS,
  LOAD_DATA_BLOGS_SUCCESS,
  LOAD_DATA_BLOGS_ERROR,
} from './constants';
import {
  makeSelectDataType,
  makeSelectEventType,
  makeSelectRecommendList,
} from './selectors';
import {
  NICE_PHOTO,
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
} from '~/components/Gallery/constants';
import Config from '~/config';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';
import * as homePageService from '~/services/home-page';
import * as photoPageService from '~/services/photo-page';
import * as searchPageService from '~/services/search-page';
import * as collectionPageService from '~/services/collection';
import * as blogPageService from '~/services/blogs';

import LocalStorage from '~/services/LocalStorage';

function* getPattern(params) {
  const colors = [
    '#efe1a2',
    '#95bcc0',
    '#93e4ef',
    '#c095b0',
    '#ed92c7',
    '#d6cbb7',
    '#549766',
    '#fdd599',
    '#003300',
    '#d5ff80',
    '#ff99ff',
    '#80bfff',
    '#00bfff',
    '#ff471a',
    '#996633',
    '#80ff80',
    '#ffcc66',
    '#669900',
    '#ff8080',
    '#0080ff',
    '#80ffaa',
    '#ff9900',
    '#660033',
    '#ccffff',
    '#cc6666',
    '#b3cccc',
    '#33cc33',
    '#ff3333',
    '#ff0066',
    '#ccffcc',
    '#ff531a',
    '#cc99ff',
    '#8cb3d9',
  ];
  try {
    const lang = params ? params.lang : 'en';
    if (Config.isPhotoAC()) {
      const { patterns } = yield call(homePageService.getPatternNew, lang);
      patterns.map((item) => {
        const num = Math.floor(Math.random() * colors.length - 5);
        item.color = colors.splice(num, 1)[0];
        return item;
      });
      yield put({ type: LOAD_PATTERN_SUCCESS, patterns });
      return patterns;
    } else if (Config.isIllustAC()) {
      const { curateds } = yield call(homePageService.getCuratedTopic, lang);
      // curateds.map((item) => {
      //   const num = Math.floor(Math.random() * colors.length - 5);
      //   item.color = colors.splice(num, 1)[0];
      //   return item;
      // });
      yield put({ type: LOAD_PATTERN_SUCCESS, patterns: curateds });
      return curateds;
    } else {
      const { patterns } = yield call(homePageService.getPattern, lang);
      patterns.map((item) => {
        const num = Math.floor(Math.random() * colors.length - 5);
        item.color = colors.splice(num, 1)[0];
        return item;
      });
      yield put({ type: LOAD_PATTERN_SUCCESS, patterns });
      return patterns;
    }
  } catch (error) {
    yield put({ type: LOAD_PATTERN_ERROR, error });
    return [];
  }
}

function* getRecommendList(params) {
  const { api, ...values } = params;
  const query = {
    page: 1,
    max_results: 32,
    filter: { rand: true },
    pattern_id: values.id,
    free_only: values.free_only,
    lang: values.lang,
    clientIp: values.clientIp,
  };

  try {
    // TODO: Migrate to api.data
    const apiFunction = api
      ? api.data.getRecommendedData
      : homePageService.getRecommendList;
    const { collection, dataType } = yield call(apiFunction, query);
    console.log('DAY LA COLLECTIONS PATTERN,', collection);
    yield put({ type: LOAD_RECOMMEND_LIST_SUCCESS, collection, dataType });
  } catch (error) {
    yield put({ type: LOAD_RECOMMEND_LIST_ERROR, error });
  }
}

function* getSlideShow(values) {
  try {
    const { collection } = yield call(homePageService.getSliderImages, values);
    yield put({ type: LOAD_SLIDER_IMAGES_SUCCESS, collection });
  } catch (error) {
    yield put({ type: LOAD_SLIDER_IMAGES_ERROR, error });
  }
}

function* getPopularSearches(params) {
  try {
    const lang = params ? params.lang : 'en';
    const { tags } = yield call(homePageService.getPopularSearch, lang);
    yield put({ type: LOAD_TAGS_SUCCESS, tags });
  } catch (error) {
    yield put({ type: LOAD_TAGS_ERROR, error });
  }
}

function* countDownload() {
  try {
    const { downloads } = yield call(homePageService.countDownload);

    yield put({ type: LOAD_COUNT_DOWNLOAD_RUN_SUCCESS, downloads });
  } catch (error) {
    yield put({ type: LOAD_COUNT_DOWNLOAD_RUN_ERROR, error });
  }
}

function* countData() {
  try {
    const { totalData } = yield call(homePageService.countData);
    yield put({ type: LOAD_COUNT_DATA_SUCCESS, totalData });
  } catch (error) {
    yield put({ type: LOAD_COUNT_DATA_ERROR, error });
  }
}

function* countUser() {
  try {
    const { totalUser } = yield call(homePageService.countUser);
    yield put({ type: LOAD_COUNT_USER_SUCCESS, totalUser });
  } catch (error) {
    yield put({ type: LOAD_COUNT_USER_ERROR, error });
  }
}

function* selectedPattern(data) {
  const values = { id: data.id, lang: data.lang };
  LocalStorage.selectedPatternId = data.id;
  yield put({ type: CHANGE_PATTERN, values });
  yield fork(getSlideShow, values);
  yield fork(getRecommendList, values);
}

function* loadData(action) {
  const { payload: params, api } = action;
  yield fork(getPopularSearches, params);
  yield fork(countDownload);

  const patterns = yield call(getPattern, params);

  let id;
  if (Config.isPhotoAC() || Config.isIllustAC()) {
    const ids = patterns.map((item) => item.id);
    id = ids[Math.floor(Math.random() * ids.length)];
  } else {
    const ids = patterns.map((item) => item.pattern_id);
    id = ids[Math.floor(Math.random() * ids.length)];
  }
  const values = { id };
  yield put({ type: CHANGE_PATTERN, values });
  yield call(getSlideShow, {
    id,
    lang: params.lang,
  });
}

function* loadRandomSliderImages(action) {
  const { payload: params, api } = action;
  const patterns = yield call(getPattern, params);

  let id;
  if (Config.isPhotoAC() || Config.isIllustAC()) {
    const ids = patterns.map((item) => item.id);
    id = ids[Math.floor(Math.random() * ids.length)];
  } else {
    const ids = patterns.map((item) => item.pattern_id);
    id = ids[Math.floor(Math.random() * ids.length)];
  }
  const values = { id };
  yield put({ type: CHANGE_PATTERN, values });
  yield call(getSlideShow, {
    id,
    lang: params.lang,
  });
}

function* nicePhoto(action) {
  try {
    const eventType = yield select(makeSelectEventType());
    let photos = [];
    if (eventType === 'recommend') {
      photos = yield select(makeSelectRecommendList());
    } else {
      photos = yield select(makeSelectDataType());
    }
    const searchedPhoto = photos.find((item) => item.id === action.id);
    if (!searchedPhoto) return;
    if (searchedPhoto.is_nice) {
      return yield put({
        type: OPEN_MODAL,
        title: i18n.t(Config.getPrefixContent('already_nice')),
      });
    }
    yield put({
      type: NICE_PHOTO_SUCCESS,
      id: action.id,
    });
    yield call(photoPageService.nicePhoto, action.id);
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

function* getDataType({ type, ...params }) {
  try {
    console.log('getDataTypeeeeeeeeeee', params);
    if (params.eventType === 'popular') {
      let { collection, has_next, page, total_page, max_results } = yield call(
        searchPageService.searchData,
        {
          language: params.lang,
        },
      );
      collection = collection.map((item) => {
        if (item?.filename) {
          item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
        }
        return item;
      });
      yield put({
        type: LOAD_DATA_TYPE_SUCCESS,
        has_next,
        page,
        max_results,
        total_page,
        collection,
        eventType: params.eventType,
      });
    } else {
      const {
        has_next,
        page,
        max_results,
        total_page,
        collection,
      } = yield call(homePageService.getDataType, params);
      yield put({
        type: LOAD_DATA_TYPE_SUCCESS,
        has_next,
        page,
        max_results,
        total_page,
        collection,
        eventType: params.eventType,
      });
    }
  } catch (error) {
    console.log('error', error);
    yield put({ type: LOAD_DATA_TYPE_ERROR, error });
  }
}
function* loadCollectionsOfHomepage(action) {
  const { payload, api } = action;
  const apiFunction = api
    ? api.photo.getCollectionOfMonths
    : collectionPageService.getCollectionOfMonths;
  try {
    let collections = yield call(apiFunction, payload);
    yield put({
      type: LOAD_COLLECTIONS_OF_HOMEPAGE_SUCCESS,
      collections,
    });
  } catch (error) {
    yield put({ type: LOAD_COLLECTIONS_OF_HOMEPAGE_ERROR, error: true });
  }
}

function* loadBlogsOfHomepage(action) {
  const { payload, api } = action;
  console.log('lang truyen vao action ne', action.payload);
  const apiFunction = api
    ? api.data.getBlogsHomepage
    : blogPageService.getBlogsHomepage;

  try {
    let blogs = yield call(apiFunction, payload);
    yield put({
      type: LOAD_DATA_BLOGS_SUCCESS,
      blogs: blogs.data,
    });
  } catch (error) {
    yield put({ type: LOAD_DATA_BLOGS_ERROR, error: true });
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* homePageData() {
  yield takeLatest(LOAD_HOMEPAGE_DATA, loadData);
  yield takeLatest(LOAD_RANDOM_SLIDER_IMAGES, loadRandomSliderImages);
  yield takeLatest(LOAD_COUNT_DOWNLOAD_RUN, countDownload);
  yield takeLatest(LOAD_COUNT_DATA, countData);
  yield takeLatest(LOAD_COUNT_USER, countUser);
  yield takeLatest(SELECTED_PATTERN, selectedPattern);
  yield takeLatest(NICE_PHOTO, nicePhoto);
  yield takeLatest(LOAD_DATA_TYPE, getDataType);
  yield takeLatest(LOAD_COLLECTIONS_OF_HOMEPAGE, loadCollectionsOfHomepage);
  yield takeLatest(LOAD_DATA_BLOGS, loadBlogsOfHomepage);
}
