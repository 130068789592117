import React from 'react';
import { withTranslation } from 'next-i18next';

export const withI18next = (namespaces = ['common']) => (WrappedComponent) => {
  const WithI18next = ({ t, i18n, ...otherProps }) => {
    return (
      <WrappedComponent {...otherProps} t={t} lng={i18n.language} i18n={i18n} />
    );
  };

  return withTranslation(namespaces)(WithI18next);
};

export default withI18next;
