import { LOAD_METADATA_SUCCESS } from './constants';

export const initialState = {
  currentLang: '',
  serviceType: '',
  categories: [],
  link: '',
  isServer: false,
  isEdgeOrIE: false,
  isMobileOrTablet: false,
  isMacOS: false,
  isLocaleInPath: false,
};

function metadataProvider(state = initialState, action) {
  switch (action.type) {
    case LOAD_METADATA_SUCCESS:
      return { ...state, ...action.metadata };
    default:
      return state;
  }
}

export default metadataProvider;
