import { createSelector } from 'reselect';
import { UPGRADE_PREMIUM_WITH_CREDIT } from './constants';

const selectState = (state) => state.payment.modal;

const selectPricesPremium = (state) => state.payment.result;

const selectSinglePricePremiums = (state) => state.payment.singlePremium;

const selectLoading = (state) => state.payment.load;

const makeSelectModal = () =>
  createSelector(selectState, (state) => state.showModal);

const makeSelectLoading = () =>
  createSelector(selectLoading, (loading) => loading);

const makeSelectPrices = () =>
  createSelector(selectPricesPremium, (pricesPremium) => pricesPremium.prices);

const makeSelectIsSale = () =>
  createSelector(selectPricesPremium, (pricesPremium) => pricesPremium.isSale);
const makeSelectIsTrial = () =>
  createSelector(selectPricesPremium, (pricesPremium) => pricesPremium.isTrial);

const makeSelectPriceSelected = () =>
  createSelector(
    selectPricesPremium,
    (pricesPremium) => pricesPremium.priceSelected,
  );

const makeSelectTrial = () =>
  createSelector(selectPricesPremium, (pricePremium) => pricePremium.trial);
const makeSelectEndDate = () =>
  createSelector(selectPricesPremium, (pricePremium) => pricePremium.end_date);
const makeSelectStartDate = () =>
  createSelector(
    selectPricesPremium,
    (pricePremium) => pricePremium.start_date,
  );
const makeSelectTrialEndDate = () =>
  createSelector(
    selectPricesPremium,
    (pricePremium) => pricePremium.trialEndDate,
  );
const makeSelectCoupon = () =>
  createSelector(selectPricesPremium, (pricePremium) => pricePremium.coupon);

const makeSelectCountdownDiscount = () =>
  createSelector(
    selectPricesPremium,
    (pricePremium) => pricePremium.countdownDiscount,
  );

const makeSelectDiscountRemainingDuration = () =>
  createSelector(
    selectPricesPremium,
    (pricePremium) =>
      (pricePremium.countdownDiscount &&
        pricePremium.countdownDiscount.remainingDurationInMilliseconds) ||
      0,
  );

const makeSelectDiscountCoupon = () =>
  createSelector(
    selectPricesPremium,
    (pricePremium) =>
      pricePremium.countdownDiscount && pricePremium.countdownDiscount.coupon,
  );

const makeSelectIsCountdownActive = () =>
  createSelector(
    selectPricesPremium,
    (pricePremium) =>
      pricePremium.countdownDiscount && pricePremium.countdownDiscount.isActive,
  );

export function upgradePremiumWithCredit(params) {
  return {
    type: UPGRADE_PREMIUM_WITH_CREDIT,
    ...params,
  };
}

const makeSelectSinglePricePremiumSelected = () =>
  createSelector(
    selectSinglePricePremiums,
    (singlePricePremiums) => singlePricePremiums.priceSelected,
  );

const makeSelectSinglePricePremiums = () =>
  createSelector(
    selectSinglePricePremiums,
    (singlePricePremiums) => singlePricePremiums.prices,
  );

const makeSelectPaymentIntentOfSinglePremium = () =>
  createSelector(
    selectSinglePricePremiums,
    (singlePricePremiums) => singlePricePremiums.paymentIntent,
  );

export {
  makeSelectModal,
  selectPricesPremium,
  makeSelectPrices,
  makeSelectIsSale,
  makeSelectPriceSelected,
  makeSelectLoading,
  makeSelectTrial,
  makeSelectEndDate,
  makeSelectStartDate,
  makeSelectIsTrial,
  makeSelectTrialEndDate,
  makeSelectCoupon,
  makeSelectCountdownDiscount,
  makeSelectSinglePricePremiums,
  makeSelectSinglePricePremiumSelected,
  makeSelectPaymentIntentOfSinglePremium,
  makeSelectIsCountdownActive,
  makeSelectDiscountRemainingDuration,
  makeSelectDiscountCoupon,
};
