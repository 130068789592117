export const LOAD_PRICE_PREMIUM = 'containers/RegisterPremiumPage/LOAD_PRICE_PREMIUM';
export const LOAD_PRICE_PREMIUM_SUCCESS = 'containers/RegisterPremiumPage/LOAD_PRICE_PREMIUM_SUCCESS';
export const LOAD_PRICE_PREMIUM_ERROR = 'containers/RegisterPremiumPage/LOAD_PRICE_PREMIUM_ERROR';

export const COMPLETED_SALE = 'containers/RegisterPremiumPage/COMPLETED_SALE';
export const SELECTED_PRICE_PREMIUM = 'containers/RegisterPremiumPage/SELECTED_PRICE_PREMIUM';
export const PRE_CANCEL_FORM_PREMIUM = 'containers/RegisterPremiumPage/PRE_CANCEL_FORM_PREMIUM';
export const CANCEL_FORM_PREMIUM = 'containers/RegisterPremiumPage/CANCEL_FORM_PREMIUM';
export const CHANGE_ITEM_PAGE_CANCEL_FORM = 'containers/RegisterPremiumPage/CHANGE_ITEM_PAGE_CANCEL_FORM';
export const CREATE_BILLING_AGREEMENT = 'containers/RegisterPremiumPage/CREATE_BILLING_AGREEMENT';
export const CREATE_BILLING_AGREEMENT_COUPON = 'containers/RegisterPremiumPage/CREATE_BILLING_AGREEMENT_COUPON';
export const EXECUTE_TOKEN_COUPON_PREMIUM = 'containers/RegisterPremiumPage/EXECUTE_TOKEN_COUPON_PREMIUM';
export const SHOW_NAV_SALE_PREMIUM = 'containers/RegisterPremiumPage/SHOW_NAV_SALE_PREMIUM';
export const HIDE_NAV_SALE_PREMIUM = 'containers/RegisterPremiumPage/HIDE_NAV_SALE_PREMIUM';
export const UPGRADE_PREMIUM_WITH_CREDIT = 'containers/RegisterPremiumPage/UPGRADE_PREMIUM_WITH_CREDIT';

export const SHOW_LOADING_FULL_SCREEN = 'containers/RegisterPremiumPage/SHOW_LOADING_FULL_SCREEN';
export const HIDE_LOADING_FULL_SCREEN = 'containers/RegisterPremiumPage/HIDE_LOADING_FULL_SCREEN';
export const REDIRECT_LOGIN = 'containers/RegisterPremiumPage/REDIRECT_LOGIN';
export const INIT_COUNTDOWN = 'containers/RegisterPremiumPage/INIT_COUNTDOWN';
export const UPDATE_COUNTDOWN = 'containers/RegisterPremiumPage/UPDATE_COUNTDOWN';
export const UPDATE_COUNTDOWN_SUCCESS = 'containers/RegisterPremiumPage/UPDATE_COUNTDOWN_SUCCESS';
