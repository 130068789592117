import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Select, { components } from 'react-select';
import withI18next from '~/../hocs/_withI18next';
import IconGlobe from '~/components/svg/icon-globe.svg';
import config from '~/config';
import Config from '~/config';
import themeVariables from '~/shared/themeVariables';
import { locales } from '~/utils';

@withI18next()
class DropdownMenuLanguage extends React.Component {
  render() {
    const { lng, t } = this.props;
    const data = config.getLanguages();

    const customStyles = {
      container: (styles) => ({
        ...styles,
        width: 248,
        borderRadius: 4,
        zIndex: 100,
      }),
      control: (styles) => ({
        ...styles,
        backgroundColor: themeVariables.colors.subtleBackground,
        border: '2px solid #1B2833',
      }),
      valueContainer: (base) => ({
        ...base,
        color: themeVariables.colors.bodyText,
        maxWidth: 190,
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: themeVariables.colors.bodyText,
        backgroundColor: isFocused ? '#ECF1F4' : '#FFFFFF',
      }),
      menuList: (styles) => ({
        ...styles,
        backgroundColor: '#FFFFFF',
      }),
      menuPortal: (styles) => ({
        ...styles,
        backgroundColor: '#FFFFFF',
      }),
    };

    const formatOptionLabel = ({ key, value, label, customAbbreviation }) => {
      const languageKey = locales.convertToShortType(key);
      // let languageLink = `/${languageKey}/`;
      // if (languageKey === 'en') {
      //   languageLink = '/';
      // }
      // if (languageKey === 'cn') {
      //   languageLink = Config.chinaDomainLinkByServiceType[Config.serviceType];
      // }
      const languageLink = Config.getSiteURL(Config.serviceType, languageKey);
      return (
        <a href={languageLink}>
          <div className="label">{label}</div>
          <style jsx>
            {`
              .label {
                color: ${themeVariables.colors.bodyText};
                padding-left: 32px;
              }
            `}
          </style>
        </a>
      );
    };

    return (
      <div className="language-container">
        <Select
          label="Language menu"
          noOptionsMessage={() => t('no_options')}
          options={data}
          formatOptionLabel={formatOptionLabel}
          components={{
            DropdownIndicator() {
              return (
                <div className="button-dropdown-language">
                  <FontAwesomeIcon
                    style={{ width: '1em', marginRight: 4 }}
                    icon={faCaretDown}
                  />
                  <style jsx>
                    {`
                      .button-dropdown-language {
                        padding-right: 8px;
                      }
                    `}
                  </style>
                </div>
              );
            },
            IndicatorSeparator: () => null,
            ValueContainer: (props) => {
              return (
                <div className="language-button">
                  <div className="icon-language">
                    <IconGlobe />
                  </div>
                  <components.ValueContainer {...props} />
                  <style jsx>
                    {`
                      .language-button {
                        display: flex;
                        flex-direction: row;
                        flex: 1;
                      }
                      .icon-language {
                        width: 32px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                      }
                    `}
                  </style>
                </div>
              );
            },
            SingleValue: () => {
              return (
                <div className="language-button">
                  {`${
                    data.find((item, index) => {
                      if (lng === locales.convertToShortType(item.key)) {
                        return true;
                      }
                      return null;
                    })?.label
                  } (${lng})`}
                  <style jsx>
                    {`
                      .language-button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-transform: uppercase;
                        white-space: nowrap;
                      }
                    `}
                  </style>
                </div>
              );
            },
          }}
          styles={customStyles}
          defaultValue={data.find((item, index) => {
            if (lng === locales.convertToShortType(item.key)) {
              return (
                <>
                  {item}
                  <span style={{ textTransform: 'uppercase' }}>({lng})</span>
                </>
              );
            }
            return null;
          })}
          menuPlacement="top"
          instanceId="ac-global-language-menu"
        />
      </div>
    );
  }
}

export default DropdownMenuLanguage;
