import { createSelector } from 'reselect';
import { initState } from './reducer';

const selectDownloadHistory = state => (state.myPage ? state.myPage.downloadHistory : initState);

const makeSelectDownloadingCertificate = () => createSelector(
  selectDownloadHistory,
  state => state.downloadingCertificate,
);

export { selectDownloadHistory, makeSelectDownloadingCertificate };
