import jwt from 'jwt-simple';

const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA4V2JjOOVFBCyuIEp9x3z
t4YDxv4lenvPWrLfubrj+4tDpIgfYYv/KqRzPb059WlnTPNO9B67HN1Zp1B/B4RK
eMHK0qKGgul275TYg/DYpBswsIh4Sd9SajzoytnJffQRMooMsaaHqJ1WBRvNBScP
4h0RpPobn03teNoypY+L7pK/v+Y1Vo+it1HMBmkMIiaDnaXR5jc1bu9j0IDMpwpy
WhKvKScsU3bnK/WL6XONOxkXM/8Ey4ftmZCbdF/8pAM5uml66/gvSfofcIlJV/Ge
UkchdsZHjjPAaqTQyKa9SOUmY9nyMiiz1GnNbZBi1ps5zU9cKf1rs1j/eIWfHhsP
11CWHq3aBqG0xbT4EJRQRwf4YdgzcjP9CHWuRmXlE+hRP74dcLjY3Srb0MHcY46+
qk5TqXzHrTWxKMRXONjQR9Z1MNYKKXp9KneVxGlElcmXGod3tXLNRD5px2zihC47
XaUgSH7qvIcwkCcMkoi68SUxSCqHyQlHcPX/M2ZL0pMRhSm3boIbkEYVAEgWIguh
sWjZeWvZYVfeqdSqT8JZ+fgS4tN0PKXPdrFTOijgU5KMrozHsGOuLEhtitFi0NYl
EOfTUZBXJBlJAoe+Ypu49mxbQ4rq1aezSX84tsq3M64ikMSQzbj4X7xmBjWdRMJP
yMb/jYmfjFqMrwaVpUJQq7ECAwEAAQ==
-----END PUBLIC KEY-----`;

// const ISSUER = 'ac-sso';
// const verifyJwtToken = (token) =>
//   new Promise((resolve, reject) => {
//     console.log('verifyJwtTokennnnnnnnnn', token);
//     jwt.verify(
//       token,
//       publicKey,
//       { issuer: ISSUER, algorithms: ['RS256'] },
//       (err, decoded) => {
//         if (err) return reject(err);
//         return resolve(decoded);
//       },
//     );
//   });

const verifyJwtToken = (token) => {
  const decoded = jwt.decode(token, publicKey, false, 'RS256');
  return decoded;
};

export default verifyJwtToken;
