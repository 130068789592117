import Router from 'next/router';
import moment from 'moment';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  LOAD_PRICE_PREMIUM,
  LOAD_PRICE_PREMIUM_SUCCESS,
  LOAD_PRICE_PREMIUM_ERROR,
  SHOW_LOADING_FULL_SCREEN,
  HIDE_LOADING_FULL_SCREEN,
  REDIRECT_LOGIN,
  CREATE_BILLING_AGREEMENT,
  UPGRADE_PREMIUM_WITH_CREDIT,
  INIT_COUNTDOWN,
  UPDATE_COUNTDOWN,
  UPDATE_COUNTDOWN_SUCCESS,
} from './constants';
import { makeSelectCoupon } from './selectors';
import { showLoadingFullScreen, hideLoadingFullScreen } from './actions';
import { updateProfile } from '../AuthProvider/saga';
import * as premiumService from '~/services/Premium';
import * as paymentService from '~/services/payment';
import Cookies from '~/services/Cookies';
import Config from '~/config';
import { showError } from '~/containers/CustomErrorMessage/actions';
import { isError } from '~/utils/errorHandler';
import { formatFloatNumber, formatNumberWithCommas } from '~/utils/formatter';
import I18nRouter from '~/components/I18nRouter';

function getDateTrial(prices) {
  const [trial] = prices.trial || [];
  return !!trial && trial.end_date;
}

function buildPrice(data) {
  const prices = {} || [];
  const [trial] = data.trial;

  if (trial && trial.end_date) {
    data.premium_prices.map((premium) => {
      const monthlyConversion = 0;
      const title = 'register_premium_price_title1';
      const giftPoints = 1;
      const year = '';
      let yearEn = '';

      if (premium.plan === 'MONTHLY') {
        yearEn = 'mo';
        prices[premium.plan] = {
          id: premium.id,
          title,
          giftPoints,
          withTax: formatFloatNumber(premium.with_tax),
          withoutTax: formatFloatNumber(premium.without_tax),
          plan: premium.plan,
          monthlyConversion,
          consumptionTax: premium.consumption_tax,
          year,
          yearEn,
          discount: premium.discount,
          end_date: premium.end_date,
          start_date: premium.start_date,
        };
      }
    });
  } else {
    data.premium_prices.map((premium) => {
      // let monthlyConversion = 0;
      // let title = 'register_premium_price_title1';
      // let giftPoints = 1;
      // let year = '';
      // let yearEn = '';

      const pricesByCurrency = premium['by_country'].map((price) => ({
        price: price.price,
        monthlyConversion: premium.plan === 'ONE_YEAR' ? price.price / 12 : 0,
        currency: price.unit,
        lang: price.lang,
        currencyIcon: Config.getCurrencyIcon(price.unit),
      }));

      if (premium.plan === 'ONE_YEAR') {
        const monthlyConversion = formatFloatNumber(premium.with_tax / 12);
        const title = 'register_premium_price_title2';
        const giftPoints = 5;
        const year = 'register_premium_price_year1';
        const yearEn = 'year';

        prices[premium.plan] = {
          id: premium.id,
          title,
          giftPoints,
          withTax: formatFloatNumber(premium.with_tax),
          withoutTax: formatFloatNumber(premium.without_tax),
          pricesByCurrency,
          plan: premium.plan,
          monthlyConversion,
          consumptionTax: premium.consumption_tax,
          year,
          yearEn,
          discount: premium.discount,
          end_date: premium.end_date,
          start_date: premium.start_date,
        };
      }

      if (premium.plan === 'MONTHLY') {
        const monthlyConversion = 0;
        const title = 'register_premium_price_title1';
        const giftPoints = 1;
        const year = '';
        let yearEn = 'month';

        prices[premium.plan] = {
          id: premium.id,
          title,
          giftPoints,
          withTax: formatFloatNumber(premium.with_tax),
          withoutTax: formatFloatNumber(premium.without_tax),
          pricesByCurrency,
          plan: premium.plan,
          monthlyConversion,
          consumptionTax: premium.consumption_tax,
          year,
          yearEn,
          discount: premium.discount,
          end_date: premium.end_date,
          start_date: premium.start_date,
        };
      }
    });
  }
  // else {
  //   data.premium_prices.map((premium) => {
  //     const monthlyConversion = 0;
  //     const title = 'register_premium_price_title1';
  //     const giftPoints = 1;
  //     const year = '';
  //     let yearEn = '';

  //     if (premium.plan === 'MONTHLY') {
  //       yearEn = 'month';
  //       prices[premium.plan] = {
  //         id: premium.id,
  //         title,
  //         giftPoints,
  //         withTax: formatFloatNumber(premium.with_tax),
  //         withoutTax: formatFloatNumber(premium.without_tax),
  //         plan: premium.plan,
  //         monthlyConversion,
  //         consumptionTax: premium.consumption_tax,
  //         year,
  //         yearEn,
  //         discount: premium.discount,
  //         end_date: premium.end_date,
  //         start_date: premium.start_date,
  //       };
  //     }
  //   });
  //   data.premium_prices.map((premium) => {
  //     let monthlyConversion = 0;
  //     let title = 'register_premium_price_title1';
  //     let giftPoints = 1;
  //     let year = '';
  //     let yearEn = '';

  //     if (premium.plan === 'ONE_YEAR') {
  //       monthlyConversion = formatFloatNumber(premium.with_tax / 12);
  //       title = 'register_premium_price_title2';
  //       giftPoints = 5;
  //       year = 'register_premium_price_year1';
  //       yearEn = 'month';
  //       prices[premium.plan] = {
  //         id: premium.id,
  //         title,
  //         giftPoints,
  //         withTax: formatFloatNumber(premium.with_tax),
  //         withoutTax: formatFloatNumber(premium.without_tax),
  //         plan: premium.plan,
  //         monthlyConversion,
  //         consumptionTax: premium.consumption_tax,
  //         year,
  //         yearEn,
  //         discount: premium.discount,
  //         end_date: premium.end_date,
  //         start_date: premium.start_date,
  //       };
  //     }
  //   });
  // }

  return prices;
}

function findTrial(prices) {
  const [trial] = prices.trial;
  return !!(trial && trial.end_date);
}

function findSaleOff(prices) {
  return !!prices.end_date;
}

function getDateSale(prices) {
  return {
    endDate: prices.end_date,
    startDate: prices.start_date,
  };
}

function countdownForTodayExisted(coupon, request) {
  // countdown not existed in cookie
  console.log('--- request ---', request);
  if (!Cookies.get('countdown.date', request)) return false;

  // countdown is existed in cookie, but in different day
  // countdown.date is string in format YYYY/MM/DD
  const countdownDate = Cookies.get('countdown.date', request);
  const today = moment().format('YYYY/MM/DD');
  if (countdownDate !== today) return false;
  return true;
}

function buildCountdownDiscount(coupon, request) {
  const totalDurationInMilliseconds = coupon.count_duration * 1000;
  const percentOff = coupon.percent_off;
  if (!countdownForTodayExisted(coupon, request)) {
    const today = moment();
    Cookies.set('countdown.startTime', today.format());
    Cookies.set('countdown.date', today.format('YYYY/MM/DD'));
    Cookies.set('countdown.percentOff', percentOff);
    Cookies.set(
      'countdown.remainingDurationInMilliseconds',
      totalDurationInMilliseconds,
    );
    return {
      percentOff,
      remainingDurationInMilliseconds: totalDurationInMilliseconds,
    };
  }
  const startTime = moment(Cookies.get('countdown.startTime', request));
  const elapsedDurationInMilliseconds = moment().diff(startTime);
  const remainingDurationInMilliseconds = Math.max(
    0,
    totalDurationInMilliseconds - elapsedDurationInMilliseconds,
  );
  Cookies.set(
    'countdown.remainingDurationInMilliseconds',
    remainingDurationInMilliseconds,
  );
  return {
    percentOff,
    remainingDurationInMilliseconds,
  };
}

function* loadPricePremium(action) {
  try {
    const prices = yield call(premiumService.getPremiumPrice, {
      type: 'premium',
    });
    if (isError(prices)) throw prices;
    yield put({
      type: LOAD_PRICE_PREMIUM_SUCCESS,
      prices: buildPrice(prices),
      isSale: findSaleOff(prices),
      saleDate: getDateSale(prices),
      trial: prices.trial,
      start_date: prices.start_date,
      end_date: prices.end_date,
      priceSelected: findTrial(prices) ? 'ONE_YEAR' : 'ONE_YEAR',
      isTrial: findTrial(prices),
      trialEndDate: getDateTrial(prices),
      coupon: prices.coupon,
      countdownDiscount: buildCountdownDiscount(prices.coupon, action.request),
    });
  } catch (error) {
    yield put({
      type: LOAD_PRICE_PREMIUM_ERROR,
      error: (error && error.response && error.response.data) || {},
    });
    yield put(showError({ error }));
  }
}

function* createBillingAgreement(params) {
  yield put({ type: SHOW_LOADING_FULL_SCREEN, message: params.message });
  const { resolve, reject } = params;
  try {
    const { approval_url } = yield call(paymentService.createBillingAgreement, {
      plan: params.plan,
    });
    if (approval_url) {
      yield put({
        type: REDIRECT_LOGIN,
        link: approval_url,
        domainNew: true,
      });
    }
    resolve();
  } catch (e) {
    yield put({
      type: HIDE_LOADING_FULL_SCREEN,
    });
    reject(e);
  }
}

function* getIsDiscounted() {
  const countdownStartTime = Cookies.get('countdown.startTime');
  const differenceInMilliseconds = moment().diff(countdownStartTime);
  console.log('differenceInMilliseconds', differenceInMilliseconds);
  const coupon = yield select(makeSelectCoupon());
  const totalDurationInMilliseconds = coupon.count_duration * 1000;
  console.log('totalDurationInMilliseconds', totalDurationInMilliseconds);
  console.log(
    'Config.discount.extraSeconds',
    Config.discount.extraSeconds * 1000,
  );
  return (
    differenceInMilliseconds <=
    totalDurationInMilliseconds + Config.discount.extraSeconds * 1000
  );
}

function* upgradePremiumWithCredit(params) {
  try {
    yield put(showLoadingFullScreen());
    const isDiscounted = yield call(getIsDiscounted);
    console.log('isDiscounted', isDiscounted);
    const response = yield call(premiumService.upgradePremiumWithCredit, {
      stripeToken: params.stripeToken,
      plan: params.plan,
      lang: params.lang,
      isDiscounted,
      currency: params.currency,
    });
    if (isError(response)) throw response;
    yield call(updateProfile, params.lang);
    yield put(hideLoadingFullScreen());
    I18nRouter.push('/register-premium-success', '/register-premium/success');
  } catch (error) {
    yield put(hideLoadingFullScreen());
    yield put(
      showError({
        error,
        title: i18n.t('registering_premium_failed'),
      }),
    );
  }
}

function* updateCountdown(action) {
  const { remainingDurationInMilliseconds } = action;
  Cookies.set(
    'countdown.remainingDurationInMilliseconds',
    remainingDurationInMilliseconds,
  );
  yield put({
    type: UPDATE_COUNTDOWN_SUCCESS,
    remainingDurationInMilliseconds,
  });
}

function* initCountdown() {
  const coupon = yield select(makeSelectCoupon());
  const { remainingDurationInMilliseconds } = buildCountdownDiscount(coupon);
  yield put({
    type: UPDATE_COUNTDOWN,
    remainingDurationInMilliseconds,
  });
}

/**
 *
 * Root saga manages watcher lifecycle
 */
export default function* pricePremiumSaga() {
  yield takeLatest(LOAD_PRICE_PREMIUM, loadPricePremium);
  yield takeLatest(CREATE_BILLING_AGREEMENT, createBillingAgreement);
  yield takeLatest(UPGRADE_PREMIUM_WITH_CREDIT, upgradePremiumWithCredit);
  yield takeLatest(INIT_COUNTDOWN, initCountdown);
  yield takeLatest(UPDATE_COUNTDOWN, updateCountdown);
}
