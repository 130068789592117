import slug from 'slug';
import config from '~/config';

class UrlUtil {
  static makeSlug(name = '', options = {}) {
    switch (options.lang || config.language) {
      case 'en':
        return UrlUtil.makeSlugEn(name);
      case 'zh-cn' || 'cn':
        return UrlUtil.makeSlugZhCn(name);
      case 'zh-tw' || 'tw':
        return UrlUtil.makeSlugZhTw(name);
      case 'vi':
        return UrlUtil.makeSlugVi(name);
      case 'es':
        return UrlUtil.makeSlugEs(name);
      case 'ko':
        return UrlUtil.makeSlugKo(name);
      case 'hi':
        return UrlUtil.makeSlugHi(name);
      case 'bn':
        return UrlUtil.makeSlugBn(name);
      case 'th':
        return UrlUtil.makeSlugTh(name);
      case 'pt':
        return UrlUtil.makeSlugPt(name);
      case 'ru':
        return UrlUtil.makeSlugRu(name);
      case 'jv':
        return UrlUtil.makeSlugJv(name);
      case 'tr':
        return UrlUtil.makeSlugTr(name);
      case 'de':
        return UrlUtil.makeSlugDe(name);
      case 'ar':
        return UrlUtil.makeSlugAr(name);
      case 'fr':
        return UrlUtil.makeSlugFr(name);
      case 'it':
        return UrlUtil.makeSlugIt(name);
      case 'pl':
        return UrlUtil.makeSlugPl(name);
      case 'jp':
        return UrlUtil.makeSlugJp(name);
      default:
        return UrlUtil.makeSlugEn(name);
    }
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugEn(str = '') {
    /* eslint-disable no-useless-escape */
    return (str || '')
      .toLowerCase()
      .replace(/[^0-9a-zа-яї_\u3400-\u9FBF\i-]/gi, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugZhTw(str = '') {
    return (str || '').toLowerCase().replace(/\s+/g, '-').replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugZhCn(str = '') {
    return (str || '').toLowerCase().replace(/\s+/g, '-').replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugVi(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
      .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
      .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
      .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
      .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/đ/g, 'd')
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9_-]/g, '')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugEs(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ä|â/g, 'a')
      .replace(/è|é|ë|ê/g, 'e')
      .replace(/ì|í|ï|î/g, 'i')
      .replace(/ò|ó|ö|ô/g, 'o')
      .replace(/ù|ú|ü|û/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9_-]/g, '')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugKo(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugHi(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugBn(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugJp(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugTh(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugPt(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
      .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
      .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
      .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
      .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugRu(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ä|â/g, 'a')
      .replace(/è|é|ë|ê/g, 'e')
      .replace(/ì|í|ï|î/g, 'i')
      .replace(/ò|ó|ö|ô/g, 'o')
      .replace(/ù|ú|ü|û/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ|Й|й/g, 'y')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugJv(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugTr(str = '') {
    return (slug(str) || '').toLowerCase().replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugDe(str = '') {
    return (slug(str) || '').toLowerCase().replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugAr(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugFr(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ä|â/g, 'a')
      .replace(/è|é|ë|ê/g, 'e')
      .replace(/ì|í|ï|î/g, 'i')
      .replace(/ò|ó|ö|ô/g, 'o')
      .replace(/ù|ú|ü|û/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9_-]/g, '')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugIt(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ä|â/g, 'a')
      .replace(/è|é|ë|ê/g, 'e')
      .replace(/ì|í|ï|î/g, 'i')
      .replace(/ò|ó|ö|ô/g, 'o')
      .replace(/ù|ú|ü|û/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9_-]/g, '')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }

  /**
   * @private
   * @param str
   * @returns {string}
   */
  static makeSlugPl(str = '') {
    return (str || '')
      .toLowerCase()
      .replace(/à|á|ä|â/g, 'a')
      .replace(/è|é|ë|ê/g, 'e')
      .replace(/ì|í|ï|î/g, 'i')
      .replace(/ò|ó|ö|ô/g, 'o')
      .replace(/ù|ú|ü|û/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/[^A-Za-z0-9_-]/g, '')
      .replace(/-+/g, '-')
      .replace(/\//gi, '-');
  }
}

export default UrlUtil;
