import React from 'react';
import PropTypes from 'prop-types';
import Config from '~/config';

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.defaultStyle = {
      margin: 'auto',
      width: 100,
      textAlign: 'center',
      padding: '100px 0',
    };
  }

  render() {
    return (
      <div
        className={`loading-spinner ${Config.getPrefixContent(
          'loading-spinner',
        )}`}
        style={Object.assign({}, this.defaultStyle, this.props.style)}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <style jsx>
          {`
            .loading-spinner {
            }

            .loading-spinner > div {
              width: 18px;
              height: 18px;
              border-radius: 100%;
              display: inline-block;
              -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
              animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            }

            .ill_loading-spinner > div {
              background-color: #d73a4e;
            }

            .photo_loading-spinner > div {
              background-color: #509bdc;
            }

            .design_loading-spinner > div {
              background-color: #ffa823;
            }

            .sil_loading-spinner > div {
              background-color: #41b9b8;
            }

            .loading-spinner .bounce1 {
              -webkit-animation-delay: -0.32s;
              animation-delay: -0.32s;
            }

            .loading-spinner .bounce2 {
              -webkit-animation-delay: -0.16s;
              animation-delay: -0.16s;
            }

            .loading-pagination {
              position: fixed;
              width: 100px;
              padding: 5px;
              margin: auto;
              top: 0;
              z-index: 1000;
              left: 0;
              right: 0;
              border: 1px solid transparent;
              border-bottom-left-radius: 2px;
              border-bottom-right-radius: 2px;
              -webkit-border-radius: 2px;
              -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              text-align: center;
            }

            @-webkit-keyframes sk-bouncedelay {
              0%,
              80%,
              100% {
                -webkit-transform: scale(0);
              }
              40% {
                -webkit-transform: scale(1);
              }
            }

            @keyframes sk-bouncedelay {
              0%,
              80%,
              100% {
                -webkit-transform: scale(0);
                transform: scale(0);
              }
              40% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }
          `}
        </style>
      </div>
    );
  }
}

LoadingSpinner.defaultProps = {
  style: {},
};

LoadingSpinner.propTypes = {
  style: PropTypes.object,
};

export default LoadingSpinner;
