import React from 'react';

const Index = (props) => {
  const { value, setSelected, selected, text } = props;

  return (
    <div className="custom-control custom-checkbox">
      <div className="checkbox-container" onClick={() => setSelected(value)}>
        <input
          disabled
          type="checkbox"
          name="confirm"
          className="input-confirm-radio custom-input-checkbox custom-control-input"
          id="input-check"
          checked={selected}
        />
        <label className="custom-control-label" htmlFor="input-check">
          {text}
        </label>
      </div>
      <style jsx>
        {`
          .checkbox-container {
            display: flex;
            width: fit-content;
            cursor: pointer;
          }
          .confirm-container {
            margin-top: 24px;
            display: flex;
            align-items: center;
            height: 20px;
          }

          .custom-control {
            position: relative;
            display: block;
            min-height: 1.5rem;
            padding-left: 1.5rem;
          }

          .custom-input-checkbox {
            opacity: 0;
            z-index: -1;
          }
          .custom-control-label {
            position: relative;
            margin-bottom: 0;
            vertical-align: top;
            padding-left: 16px;
            padding-top: 0px;
            cursor: pointer;
          }
          .custom-control-label::before {
            position: absolute;
            top: 0;
            left: -1.5rem;
            display: block;
            width: 24px;
            height: 24px;
            pointer-events: none;
            content: '';
            background-color: #fff;
            border: 2px solid #051019;
            box-sizing: border-box;
            border-radius: 4px;
          }
          .custom-control-label::after {
            position: absolute;
            top: 0;
            left: -1.5rem;
            display: block;
            width: 24px;
            height: 24px;
            content: '';
            border: 2px solid #051019;
            box-sizing: border-box;
            border-radius: 4px;
          }
          .custom-checkbox
            .custom-control-input:checked
            ~ .custom-control-label::after {
            width: 24px !important;
            height: 24px !important;
            background: url('/static/assets/svg/icon-tick-checkbox.svg') center
              no-repeat;
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            border-color: #ffffff;
            background-color: #ffffff;
          }
        `}
      </style>
    </div>
  );
};
export default Index;
