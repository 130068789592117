import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import { connect } from 'react-redux';
import { END } from 'redux-saga';
import nextI18nextConfig from '~/../next-i18next.config';
import Config from '~/config';
import HomeContainer from '~/containers/HomePage';
import {
  loadHomePageData,
  loadDataCollectionHomepage,
  loadDataBlogsHomepage,
} from '~/containers/HomePage/actions';
import Layout from '~/containers/Layout';
import defaultStaticPage, {
  defaultPageStaticContext,
} from '~/../hocs/defaultStaticPage';
import HrefLangs, {
  generateCanonicalAndLanguageAlternates,
} from '~/components/HrefLangs';
import { wrapper } from '~/store';

const mapStateToProps = (state) => ({
  photo: state.home.recommendList,
});

@defaultStaticPage()
@connect(mapStateToProps)
class Index extends React.Component {
  state = {
    showSearchHeader: false,
  };

  onShowSearchHeader = () => {
    this.setState({ showSearchHeader: true });
  };

  onHideSearchHeader = () => {
    this.setState({ showSearchHeader: false });
  };

  componentDidMount() {
    const { lng } = this.props;
    generateCanonicalAndLanguageAlternates('', lng);
  }

  componentDidUpdate() {
    const { lng } = this.props;
    generateCanonicalAndLanguageAlternates('', lng);
  }

  componentWillUnmount() {
    document.querySelector('#meta-keywords').remove();
    document.querySelector('#meta-description').remove();
  }

  generateJSONLD() {
    const { lng } = this.props;
    const logo = Config.getLogo();
    const snsLinks = Config.renderLink(lng);

    return (
      <>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: Config.renderTitleOpenSearch(),
              url: `${Config.getSiteURL()}`,
              logo: logo.desktop,
              sameAs: [snsLinks.link_fb, snsLinks.link_twitter],
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: `${Config.getSiteURL()}`,
              potentialAction: {
                '@type': 'SearchAction',
                target: `${Config.getSiteURL()}/search/{search_term_string}`,
                'query-input': 'required name=search_term_string',
              },
            }),
          }}
        />
      </>
    );
  }

  render() {
    const { t, lng, photo } = this.props;
    const data = photo.length ? photo[0] : {};

    return (
      <Layout header t={t} lng={lng}>
        <Head>
          <HrefLangs pagePath="" lng={lng} />
          {process.browser ? (
            <title>{t(Config.getPrefixContent('title_top_page_seo'))}</title>
          ) : (
            <title
              dangerouslySetInnerHTML={{
                __html: t(Config.getPrefixContent('title_top_page_seo')),
              }}
            />
          )}

          <meta
            id="meta-keywords"
            name="keywords"
            content={t(Config.getPrefixContent('meta_keywords_top_page_seo'))}
          />
          <meta
            id="meta-description"
            name="description"
            content={t(
              Config.getPrefixContent('meta_description_top_page_seo'),
              {
                total: 1242251,
              },
            )}
          />
          {this.generateJSONLD()}

          <meta
            property="og:description"
            content={t(
              Config.getPrefixContent('meta_description_top_page_seo'),
              {
                total: 1242251,
              },
            )}
          />
          {lng === 'en' && <meta property="og:locale" content={'en_US'} />}
          {lng !== 'en' && (
            <meta
              property="og:locale"
              content={lng + '_' + lng?.toUpperCase()}
            />
          )}
          <meta
            property="og:title"
            content={t(Config.getPrefixContent('title_top_page_seo'), {
              total: 1242251,
            })}
          />
          <meta property="og:type" content="article" />
          {/* <meta property="og:image" content={data.path} /> */}
          <meta
            property="og:image"
            content={`${
              Config.staticPrefix
            }/static/assets/image/logo/${Config.renderImagePage()}`}
          />

          <meta
            property="og:description"
            content={t(
              Config.getPrefixContent('meta_description_top_page_seo', {
                total: 1242251,
              }),
            )}
          />
          <meta property="og:url" content={`${Config.getSiteURL()}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content={Config.getSiteURL()} />
          <meta
            name="twitter:title"
            content={t(Config.getPrefixContent('title_top_page_seo'))}
          />
          <meta
            name="twitter:description"
            content={t(
              Config.getPrefixContent('meta_description_top_page_seo'),
              {
                total: 1242251,
              },
            )}
          />
          <meta name="twitter:image" content={data.path} />
          <meta name="twitter:site" content={`${Config.getSiteURL()}`} />
          <meta name="twitter:creator" content={`@${Config.getName()}`} />
        </Head>
        <HomeContainer
          t={t}
          lng={lng}
          onShowSearchHeader={this.onShowSearchHeader}
          onHideSearchHeader={this.onHideSearchHeader}
        />
        {/* <ScrollTop /> */}
      </Layout>
    );
  }
}

export const getStaticProps = wrapper.getStaticProps((store) => async (ctx) => {
  const i18nProps = await serverSideTranslations(
    ctx.locale || process.env.LANGUAGE,
    ['common'],
    nextI18nextConfig,
  );

  const { currentLanguage, clientIp, api } = await defaultPageStaticContext({
    ...ctx,
    store,
  });

  if (Config.isDesignAC()) {
    store.dispatch(
      loadHomePageData(
        { free_only: true, lang: currentLanguage, clientIp },
        api,
      ),
    );
  } else {
    // * TODO: Enable blog posts setting from database
    store.dispatch(loadDataBlogsHomepage({ lang: currentLanguage }, api));
    store.dispatch(loadHomePageData({ lang: currentLanguage, clientIp }, api));
    store.dispatch(loadDataCollectionHomepage({ lang: currentLanguage }, api));
  }

  // end the saga
  store.dispatch(END);
  await store.sagaTask.toPromise();

  return {
    props: {
      ...i18nProps,
      currentLanguage,
    }, // will be passed to the page component as props
    revalidate: 60 * 60 * 24, // revalidate every 1 day
  };
});

export default Index;
