import { createSelector } from 'reselect';

const selectMetadata = (state) => state.metadata;

const makeSelectI18nInstance = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.t);

const makeSelectCategories = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.categories);

const makeSelectCurrentLang = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.currentLang);

const makeSelectServiceType = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.serviceType);

const makeSelectLink = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.link);

const makeSelectIsEdgeOrIE = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.isEdgeOrIE);

const makeSelectIsMobileOrTablet = () =>
  createSelector(
    selectMetadata,
    (metadataState) => metadataState.isMobileOrTablet,
  );

const makeSelectIsMacOS = () =>
  createSelector(selectMetadata, (metadataState) => metadataState.isMacOS);

const makeSelectIsLocaleInPath = () =>
  createSelector(
    selectMetadata,
    (metadataState) => metadataState.isLocaleInPath,
  );

export {
  selectMetadata,
  makeSelectCategories,
  makeSelectCurrentLang,
  makeSelectServiceType,
  makeSelectLink,
  makeSelectI18nInstance,
  makeSelectIsEdgeOrIE,
  makeSelectIsMobileOrTablet,
  makeSelectIsMacOS,
  makeSelectIsLocaleInPath,
};
