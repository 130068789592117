import {
  LOAD_RECOMMEND_DATA,
  FILTER_PER_PAGE,
  CHANGE_PAGE,
  NICE_PHOTO,
} from './constants';

export function loadRecommendData(payload, api) {
  return {
    type: LOAD_RECOMMEND_DATA,
    payload,
    api,
  };
}

export function changePerPage(value) {
  return {
    type: FILTER_PER_PAGE,
    value,
  };
}

export function changePage(page) {
  return {
    type: CHANGE_PAGE,
    page,
  };
}
export function nicePhoto(value) {
  return {
    type: NICE_PHOTO,
    value,
  };
}
