import React from 'react';
import PropTypes from 'prop-types';
import themeVariables from '~/shared/themeVariables';

class Radio extends React.Component {
  render() {
    const { checked, label, onChange, groupName, style } = this.props;
    return (
      <div style={style} className="ac-radio" onClick={onChange}>
        <input
          className={checked ? 'checked' : 'unchecked'}
          type="radio"
          name={groupName}
          checked={checked}
          readOnly
        />
        <label>{label}</label>
        <style jsx>
          {`
            .ac-radio {
              display: inline-block;
              user-select: none;
            }
            label {
              height: 20px;
              margin-bottom: -5px;
              white-space: nowrap;
            }
            [type='radio'].checked,
            [type='radio'].unchecked {
              position: absolute;
              left: -9999px;
            }
            [type='radio'].checked + label,
            [type='radio'].unchecked + label {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              line-height: 20px;
              display: inline-block;
              color: #666;
            }
            [type='radio'].checked + label:before,
            [type='radio'].unchecked + label:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 18px;
              height: 18px;
              border: 2px solid #000000;
              border-radius: 100%;
              background: #fff;
            }
            [type='radio'].checked + label:after,
            [type='radio'].unchecked + label:after {
              content: '';
              width: 8px;
              height: 8px;
              position: absolute;
              top: 5px;
              left: 5px;
              border-radius: 100%;
              -webkit-transition: all 0.2s ease;
              transition: all 0.2s ease;
            }
            [type='radio'].checked + label:after {
              background-color: ${themeVariables.colors.primary};
            }
            .ac-radio [type='radio'].unchecked + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
            }
            .ac-radio [type='radio'].checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          `}
        </style>
      </div>
    );
  }
}

Radio.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  groupName: PropTypes.string,
  style: PropTypes.object,
};

Radio.defaultProps = {
  style: {},
};

export default Radio;
