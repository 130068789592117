import { call, put, takeLatest, select } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  LOAD_DATA_CATEGORY,
  LOAD_DATA_CATEGORY_OLD,
  LOAD_DATA_CATEGORY_ERROR,
  LOAD_DATA_CATEGORY_SUCCESS,
  LOAD_DATA_CATEGORY_SIMILAR,
  NICE_PHOTO,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
  REMOVE_NICE_PHOTO_SUCCESS,
} from './constants';
import { makeSelectPhotos } from './selectors';
import * as categoryPageService from '~/services/category';
import * as photoPageService from '~/services/photo-page';
import * as searchPageService from '~/services/search-page';
import Config from '~/config';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';
import { listIdCategoryExclude } from '~/utils';

function* loadDataOfCategory(action) {
  try {
    const { payload, api } = action;
    const query = payload || {};

    const apiFunction = api
      ? api.search.searchData
      : searchPageService.searchData;
    let {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      category,
      dataType,
    } = yield call(apiFunction, {
      category_slug: query.slug,
      language: query.lang,
      service_type: query.service_type,
      per_page: query.per_page || 100,
      page: query.page || 1,
      order_by: query.order_by,
      is_tag: true,
      isCategoryPage: true,
      clientIp: query.clientIp,
      keyword: query.slug === 'brushes' ? 'brush' : '',
    });
    collection = collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      return item;
    });

    let listDataFilter = listIdCategoryExclude[`${category.slug.cat_slug_en}`]
      ? listIdCategoryExclude[`${category.slug.cat_slug_en}`].split(',')
      : null;

    let newCollection = collection;

    if (listDataFilter && Config.isPhotoAC()) {
      newCollection = collection.filter(
        (item) => !listDataFilter.includes(item.id.toString()),
      );
    }
    console.log(
      collection.length,
      'length collection',
      newCollection.length,
      'length newListCollection',
    );

    const pageInt = parseInt(page);

    yield put({
      type: LOAD_DATA_CATEGORY_SUCCESS,
      category,
      collection: newCollection,
      has_next,
      page: pageInt,
      total_page,
      total_item,
      dataType,
    });
  } catch (error) {
    let status;
    let { message } = error;
    if (error.response) {
      status = error.response.status;
      message = error.response.data.message;
    }
    yield put({ type: LOAD_DATA_CATEGORY_ERROR, error: { status, message } });
  }
}

function* loadDataOfCategoryOld(action) {
  try {
    const { payload, api } = action;
    const query = payload || {};

    const apiFunction = api
      ? [api.data, api.data.getDataOfCategory]
      : categoryPageService.getDataOfCategory;

    let {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      category,
      dataType,
    } = yield call(apiFunction, {
      slug: query.slug,
      lang: query.lang,
      service_type: query.service_type,
      per_page: query.per_page || 100,
      page: query.page || 1,
      download: query.order_by === 'popular' ? 'desc' : '',
      createdAt: query.order_by === 'newest' ? 'desc' : '',
      clientIp: query.clientIp,
    });
    collection = collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      console.log('item thumbnail', item.thumbnail, item);
      return item;
    });
    const pageInt = parseInt(page);

    yield put({
      type: LOAD_DATA_CATEGORY_SUCCESS,
      category,
      collection,
      has_next,
      page: pageInt,
      total_page,
      total_item,
      dataType,
    });
  } catch (error) {
    console.log('errorrr', error);
    yield put({ type: LOAD_DATA_CATEGORY_ERROR, error: true });
  }
}

function* loadDataCategorySimilar(action) {
  try {
    const { payload, api } = action;
    const query = payload || {};

    const apiFunction = api
      ? api.search.searchData
      : searchPageService.searchData;
    console.log('loadDataCategorySimilarr', query);

    let {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      category,
    } = yield call(apiFunction, {
      category_slug: query.slug,
      keyword: query.tag_name,
      language: query.lang,
      service_type: query.service_type,
      per_page: query.per_page || 100,
      page: query.page || 1,
      order_by: query.order_by,
      is_tag: true,
      isCategoryPage: true,
      clientIp: query.clientIp,
    });
    collection = collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      return item;
    });
    const pageInt = parseInt(page);

    yield put({
      type: LOAD_DATA_CATEGORY_SUCCESS,
      category,
      collection,
      has_next,
      page: pageInt,
      total_page,
      total_item,
    });
  } catch (error) {
    let status;
    let { message } = error;
    if (error.response) {
      status = error.response.status;
      message = error.response.data.message;
    }
    yield put({ type: LOAD_DATA_CATEGORY_ERROR, error: { status, message } });
  }
}

function* nicePhoto(action) {
  try {
    const photos = yield select(makeSelectPhotos());
    const searchedPhoto = photos.find((item) => item.id === action.value);
    if (searchedPhoto.is_nice) {
      yield put({
        type: REMOVE_NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.removeNicePhoto, action.value);
    } else {
      yield put({
        type: NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.nicePhoto, action.value);
    }
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* loadDataCategorySaga() {
  yield takeLatest(LOAD_DATA_CATEGORY, loadDataOfCategory);
  yield takeLatest(LOAD_DATA_CATEGORY_OLD, loadDataOfCategoryOld);
  yield takeLatest(LOAD_DATA_CATEGORY_SIMILAR, loadDataCategorySimilar);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
