import { call, put, takeLatest, select } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  GET_CREATOR_DATA_ERROR,
  GET_CREATOR_DATA_SUCCESS,
  GET_CREATOR_PROFILE_SUCCESS,
  GET_CREATOR_PROFILE_ERROR,
  PRE_GET_CREATOR_DATA,
  PRE_GET_CREATOR_PROFILE,
} from './constants';
import { makeSelectCollection } from './selectors';
import * as creatorService from '~/services/my-page/creator';
import * as photoPageService from '~/services/photo-page';
import * as searchPageService from '~/services/search-page';
import {
  NICE_PHOTO,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
} from '~/components/Gallery/constants';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';
import Config from '~/config';

function* getCreatorProfile(action) {
  const { api, payload: params } = action;
  let profile;
  try {
    const apiPublicProfileFunction = api
      ? api.user.getCreatorPublicProfile
      : creatorService.getCreatorPublicProfile;
    const response = yield call(apiPublicProfileFunction, params);
    profile = response.profile;
    console.log('profile ne', profile);
  } catch (e) {
    console.log(e);
    yield put({ type: GET_CREATOR_PROFILE_ERROR });
  }

  try {
    if (Config.isIllustAC() && !profile?.id_mapping) {
      // illustAC does not have correct creator ID in Elasticsearch
      // So we query from DB
      console.log('dbbbbbbbbbbbbbbbb');
      const apiPublicDataFunction = api
        ? api.data.getCreatorData
        : creatorService.getCreatorData;
      const { collection, code, ...pagination } = yield call(
        apiPublicDataFunction,
        params,
      );
      yield put({
        type: GET_CREATOR_PROFILE_SUCCESS,
        profile,
        pagination,
        collection,
        code,
      });
    } else {
      // photoAC, silhouetteAC, templateAC data is fetched from Elasticsearch
      console.log('esssssssssssssssss');
      const apiFunction = api
        ? api.search.searchData
        : searchPageService.searchData;
      let { collection, code, ...pagination } = yield call(apiFunction, {
        creator_id: Config.isIllustAC() ? profile?.id_mapping : profile.id,
        page: params.page,
        per_page: params.max_results,
        orderBy: params.data_type === 'popular' ? 'recent_popular' : 'newest',
      });
      collection = collection.map((item) => {
        if (item?.filename) {
          item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
        }
        return item;
      });
      yield put({
        type: GET_CREATOR_PROFILE_SUCCESS,
        profile,
        pagination,
        collection,
        code,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({ type: GET_CREATOR_DATA_ERROR });
  }
}

function* getCreatorData(action) {
  try {
    const { api, ...params } = action;
    const apiPublicDataFunction = api
      ? api.data.getCreatorData
      : creatorService.getCreatorData;
    const { collection, code, ...pagination } = yield call(
      apiPublicDataFunction,
      params,
    );
    yield put({
      type: GET_CREATOR_DATA_SUCCESS,
      pagination,
      collection,
      code,
    });
  } catch (e) {
    yield put({ type: GET_CREATOR_DATA_ERROR });
  }
}

function* nicePhoto(action) {
  try {
    const photos = yield select(makeSelectCollection());
    const searchedPhoto = photos.find((item) => item.id === action.id);
    if (!searchedPhoto) return;
    if (searchedPhoto.is_nice) {
      return yield put({
        type: OPEN_MODAL,
        title: i18n.t(Config.getPrefixContent('already_nice')),
      });
    }
    yield put({
      type: NICE_PHOTO_SUCCESS,
      id: action.id,
    });
    yield call(photoPageService.nicePhoto, action.id);
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

export default function* pricePremiumSaga() {
  yield takeLatest(PRE_GET_CREATOR_PROFILE, getCreatorProfile);
  yield takeLatest(PRE_GET_CREATOR_DATA, getCreatorData);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
