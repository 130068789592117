import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  makeSelectMessage,
  makeSelectLoading,
  makeSelectIsSearchingByPhoto,
} from './selectors';
import Loading from '~/components/Loading';

class LoadingContainer extends React.Component {
  render() {
    const { message, loading, isSearchingByPhoto } = this.props;

    return (
      <Loading
        message={message}
        loading={loading}
        isSearchingByPhoto={isSearchingByPhoto}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  isSearchingByPhoto: makeSelectIsSearchingByPhoto(),
  message: makeSelectMessage(),
});
export default connect(mapStateToProps)(LoadingContainer);
