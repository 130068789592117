import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import css from 'styled-jsx/css';
import constant from '~/../hocs/constant';
import DropdownBottom from '~/components/DropdownBottom';
import I18nLink from '~/components/I18nLink';
import UserHeaderInfo from '~/components/Layout/UserHeaderInfo';
import Config, { keyConfig } from '~/config';
import themeVariables from '~/shared/themeVariables';
import { AI_SERVICES, SERVICE_TYPES, USER_ROLES } from '~/utils/constants';
import IconCrown from '~/components/svg/icon-crown.svg';
import IconCountBulkDownload from '~/components/svg/count-bulk-download.svg';

class DesktopView extends React.Component {
  handleTogglePhotoCart = () => {
    const { setIsPhotoCartOpen, layout } = this.props;
    setIsPhotoCartOpen(!layout.isPhotoCartOpen);
  };

  renderCategoryDropdown = () => {
    const { categories, t } = this.props;

    return (
      <DropdownBottom
        style={{ height: '100%', marginRight: themeVariables.space.base }}
        header={
          <I18nLink href={{ pathname: '/categories' }}>
            <a className="dropdown-category">{t('category')}</a>
          </I18nLink>
        }
      >
        <div className="menu-category">
          <I18nLink href={`/${keyConfig.get('route_popular_page')}`}>
            <a className="menu-item all-categories">
              {t(Config.getPrefixContent('all_categories'))}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ width: '1em', marginLeft: themeVariables.space.small }}
              />
            </a>
          </I18nLink>
          <div className="grid-categories">
            {categories &&
              categories.map((item) => (
                <I18nLink
                  key={item.category_slug}
                  as={`/category/${item.category_slug}`}
                  href={{
                    pathname: '/category',
                    query: { slug: item.category_slug },
                  }}
                >
                  <a className="menu-item">{item.category_name}</a>
                </I18nLink>
              ))}
            <I18nLink href={{ pathname: '/categories' }}>
              <a className="menu-item view-all">{t('view_all')}</a>
            </I18nLink>
          </div>
        </div>
        <style jsx>
          {`
            .dropdown-category {
              color: ${themeVariables.color.dark};
            }
            .dropdown-category:hover {
              opacity: 0.8;
            }

            .menu-category {
              width: 350px;
              display: flex;
              flex-direction: column;
              background: #fff;
              color: ${themeVariables.color.dark};
            }
            .menu-item {
              padding: ${themeVariables.space.small}px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .menu-item:hover {
              background: ${themeVariables.color.gray};
            }
            .grid-categories {
              display: grid;
              grid-template-columns: 1fr 1fr;
              color: ${themeVariables.color.dark};
            }
            .all-categories,
            .view-all {
              color: ${themeVariables.color.primary};
            }
          `}
        </style>
      </DropdownBottom>
    );
  };

  renderOtherStockSites = () => {
    const { lng, t } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    if (Config.isPhotoAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('illustrations')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('silhouettes')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
    if (Config.isIllustAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('photos')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('silhouettes')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
    if (Config.isSilhouetteAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('photos')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('illustrations')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('templates')}
          </a>
        </>
      );
    }
    if (Config.isDesignAC()) {
      return (
        <>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('photos')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('illustrations')}
          </a>
          <a
            className={linkClassName}
            href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
            target="_blank"
            rel="noreferrer"
          >
            {t('silhouettes')}
          </a>
        </>
      );
    }
  };

  renderVideoAC = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <a
        className={linkClassName}
        href="https://video-ac.com/en"
        target="_blank"
        rel="noreferrer"
      >
        {t('header_video')}
      </a>
    );
  };

  renderFileTransferService = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <a
        className={linkClassName}
        href="https://ac-data.info/en"
        target="_blank"
        rel="noreferrer"
      >
        {t('file_transfer')}
      </a>
    );
  };

  renderDesignTool = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    let siteURL = `${Config.domainService.editorAC}/en`;
    if (lng === 'tw' || lng === 'ko' || lng === 'vi') {
      siteURL = `${Config.domainService.editorAC}/${lng}`;
    }

    return (
      <a
        className={linkClassName}
        href={siteURL}
        target="_blank"
        rel="noreferrer"
      >
        {t('header_design_tool')}
      </a>
    );
  };

  renderAIProjectsDropdown = () => {
    const { t, lng } = this.props;
    const allServices = AI_SERVICES;

    return (
      <DropdownBottom
        style={{
          height: '100%',
          color: themeVariables.color.dark,
          marginRight: 16,
          fontWeight: lng === 'tw' || lng === 'cn' ? 500 : 600,
          fontSize: 14,
          textTransform: 'uppercase',
        }}
        header={t('artificial_intelligence_projects')}
      >
        <div className="menu-ai-projects">
          {allServices.map((item, index) => (
            <a
              key={index}
              className="menu-item"
              href={item.href}
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-title">
                <img
                  className="lazyload img-logo"
                  data-src={item.logoUrl}
                  alt="site logo"
                />
                {item.serviceName}
              </div>
              <span className="project-description">{t(item.explain)}</span>
            </a>
          ))}
        </div>
        <style jsx>
          {`
            .menu-ai-projects {
              min-width: 350px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              color: ${themeVariables.color.dark};
              font-weight: normal;
              text-transform: none;
            }
            .menu-item {
              padding: ${themeVariables.space.small}px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: column;
            }
            .menu-item:hover {
              background: ${themeVariables.color.gray};
            }
            .img-logo {
              height: 20px;
              margin-right: ${themeVariables.space.extraSmall}px;
            }
            .project-title {
              font-weight: 500;
            }
            .project-description {
              font-size: 12px;
              margin-top: ${themeVariables.space.extraSmall}px;
            }
          `}
        </style>
      </DropdownBottom>
    );
  };

  renderAILink = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/ai-projects">
        <a className={linkClassName} target="_blank">
          {t('artificial_intelligence_projects')}
        </a>
      </I18nLink>
    );
  };

  renderBlog = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <a
        className={linkClassName}
        href={Config.renderLinkBlog(lng)}
        target="_blank"
        rel="noreferrer"
      >
        {t('blog')}
      </a>
    );
  };

  renderCategories = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/categories">
        <a className={linkClassName}>{t('categories')}</a>
      </I18nLink>
    );
  };
  renderCollections = () => {
    const { t, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    return (
      <I18nLink href="/collections">
        <a className={linkClassName}>{t('collections')}</a>
      </I18nLink>
    );
  };
  renderSellYourContent = () => {
    const { t, lng } = this.props;

    return (
      <I18nLink href="/sell-your-content">
        <a className="link-sell-your-content">
          <span className="text-sell-your-content">
            {t('sell_your_content')}
          </span>
          <span className="new-label">{t('new')}</span>
          <style jsx>
            {`
              .link-sell-your-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-transform: uppercase;
                font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
                font-size: 14px;
                margin-right: 16px;
              }
              .text-sell-your-content {
                color: ${themeVariables.color.dark};
              }
              .text-sell-your-content:hover {
                color: ${themeVariables.colors.primary};
                text-decoration: underline;
              }
              .new-label {
                color: white;
                width: 32px;
                height: 14px;
                background: #ee1818;
                font-size: 12px;
                margin-left: 6px;
                display: inline-table;
                text-align: center;
                text-transform: uppercase;
              }
            `}
          </style>
        </a>
      </I18nLink>
    );
  };

  // renderCreatorsLink = () => {
  //   const { t } = this.props;

  //   return (
  //     <>
  //       <I18nLink href={{ pathname: '/creators' }}>
  //         <a className="link-creators">{t('creators')}</a>
  //       </I18nLink>
  //       <style jsx>
  //         {`
  //           .link-creators {
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             height: 100%;
  //             color: ${themeVariables.color.dark};
  //             margin-right: ${themeVariables.space.base}px;
  //           }
  //         `}
  //       </style>
  //     </>
  //   );
  // };

  renderPremiumButton = () => {
    const { t, profile, lng } = this.props;
    const { className: linkClassName } = getLinkStyles({ lng });

    if (
      profile.role === USER_ROLES.PREMIUM ||
      profile.role === USER_ROLES.PREMIUM_NO_ADS ||
      (profile.role === USER_ROLES.CREATOR && Config.isCreatorAvailable(lng))
    ) {
      return null;
    }

    return (
      <I18nLink href="/premium">
        <a className={linkClassName}>
          <IconCrown style={{ height: 16, marginRight: 8 }} />
          {t('upgrade')}
        </a>
      </I18nLink>
    );
  };

  renderPhotoCart = () => {
    const { profile, layout } = this.props;
    if (profile.role !== USER_ROLES.PREMIUM) return null;

    return (
      <div className="photo-cart" onClick={this.handleTogglePhotoCart}>
        <IconCountBulkDownload />
        <div className="container-count-photo">
          <div className="container-arrow-left">
            <div className="arrow-left"></div>
          </div>
          <div className="count-photo">{layout.photosInCart.length}</div>
        </div>

        <style jsx>
          {`
            .container-count-photo {
              display: flex;
              margin-left: 4px;
            }
            .container-arrow-left {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .arrow-left {
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-right: 4px solid #1d7ab3;
            }
            .photo-cart {
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            }
            .count-photo {
              min-width: 12px;
              border-radius: 4px;
              padding: 2px ${themeVariables.space.small}px;
              font-weight: 500;
              font-size: 12px;
              color: #ffffff;
              display: flex;
              flex-direction: row;
              align-items: center;
              background: #1d7ab3;
            }
          `}
        </style>
      </div>
    );
  };

  renderSignUpDownloader = () => {
    const { t, lng } = this.props;

    return (
      <a className="btn-signup-downloader" onClick={this.handleSignUpSSO}>
        {t('downloader_signup')}
        <style jsx>
          {`
            .btn-signup-downloader {
              color: ${themeVariables.color.white};
              align-self: center;
              background: ${themeVariables.color.dark};
              padding: ${themeVariables.space.extraSmall}px
                ${themeVariables.space.small}px;
              border-radius: 4px;
              width: 96px;
              height: 44px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin-left: 16px;
              text-transform: uppercase;
              font-size: 14px;
              font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
            }

            .btn-signup-downloader:hover {
              background: ${themeVariables.colors.primary};
            }
          `}
        </style>
      </a>
    );
  };

  renderSignUpCreator = () => {
    const { t, lng } = this.props;

    return (
      <I18nLink href={{ pathname: '/creator-registration' }}>
        <a className="btn-signup-creator">
          {t(Config.getPrefixContent('creator_signup'))}
          <style jsx>
            {`
              .btn-signup-creator {
                align-self: center;
                color: ${themeVariables.color.dark};
                font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
                font-size: 14px;
                text-transform: uppercase;
              }
              .btn-signup-creator:hover {
                color: ${themeVariables.colors.primary};
                text-decoration: underline !important;
              }
            `}
          </style>
        </a>
      </I18nLink>
    );
  };

  renderLoginAndSignUpForDownloader = () => {
    const { t, lng } = this.props;

    return (
      <div className="login-signup-wrapper">
        <a
          className={`btn-login ${Config.serviceType}`}
          onClick={this.handleLoginSSO}
        >
          {t('Login')}
        </a>
        {this.renderSignUpDownloader()}

        <style jsx>
          {`
            .login-signup-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .btn-login {
              color: ${themeVariables.color.dark};
              font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
              font-size: 14px;
              text-transform: uppercase;
            }
            .btn-login:hover {
              color: ${themeVariables.colors.primary};
              text-decoration: underline !important;
            }
          `}
        </style>
      </div>
    );
  };

  getSSORedirectURL = () => {
    const { lng } = this.props;
    let redirectURL = window.location.href;
    if (
      redirectURL.includes('creator-registration') ||
      redirectURL.includes('login')
    ) {
      redirectURL = Config.getSiteURL();
    }
    return redirectURL;
  };

  handleLoginSSO = () => {
    const { lng } = this.props;
    window.open(
      `${Config.ssoServerUrl}/login?lang=${lng}&serviceURL=${encodeURIComponent(
        this.getSSORedirectURL(),
      )}`,
      '_self',
    );
  };

  handleSignUpSSO = () => {
    const { lng } = this.props;
    window.open(
      `${
        Config.ssoServerUrl
      }/signup?lang=${lng}&serviceURL=${encodeURIComponent(
        this.getSSORedirectURL(),
      )}`,
      '_blank',
    );
  };

  renderLogin = () => {
    const { t, lng } = this.props;

    return (
      <DropdownBottom
        key="login"
        style={{
          height: '100%',
          marginLeft: themeVariables.space.half,
          display: 'flex',
        }}
        align="right"
        noArrow
        header={<span className="btn-login">{t('Login')}</span>}
      >
        <div className="menu-login">
          <a className="menu-item" onClick={this.handleLoginSSO}>
            {t('downloader_login')}
          </a>
          <I18nLink href={{ pathname: '/login' }}>
            <a className="menu-item">
              {t(Config.getPrefixContent('creator_login'))}
            </a>
          </I18nLink>
          <style jsx>
            {`
              .btn-login {
                color: ${themeVariables.color.dark};
                margin-left: 16px;
                font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
                font-size: 14px;
                text-transform: uppercase;
              }

              .menu-login {
                display: flex;
                flex-direction: column;
                min-width: 150px;
              }

              .menu-item {
                padding: ${themeVariables.space.small}px;
                color: ${themeVariables.color.dark};
                display: flex;
                flex-direction: row;
                align-items: center;
                white-space: nowrap;
              }
              .menu-item:hover {
                background: ${themeVariables.color.gray};
              }
            `}
          </style>
        </div>
      </DropdownBottom>
    );
  };

  renderLoginAndSignup = () => {
    const { lng } = this.props;

    if (Config.isCreatorAvailable(lng)) {
      return (
        <>
          {/* {this.renderSignUpCreator()} */}
          {this.renderSignUpDownloader()}
          {this.renderLogin()}
        </>
      );
    }

    return this.renderLoginAndSignUpForDownloader();
  };

  // renderMoreMenu = () => {
  //   const { t, lng } = this.props;

  //   return (
  //     <DropdownBottom
  //       key="more-menu"
  //       style={{
  //         height: '100%',
  //         marginLeft: themeVariables.space.half,
  //         display: 'flex',
  //       }}
  //       align="right"
  //       noArrow
  //       header={
  //         <FontAwesomeIcon
  //           style={{
  //             color: themeVariables.color.dark,
  //             fontSize: themeVariables.fontSize.large,
  //           }}
  //           icon={faEllipsisH}
  //         />
  //       }
  //     >
  //       <div className="menu-more">
  //         <I18nLink href={{ pathname: '/tags' }}>
  //           <a className="menu-item">{t('popular_tags')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/terms' }}>
  //           <a className="menu-item">{t('Terms of service')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/privacy-policy' }}>
  //           <a className="menu-item">{t('Privacy Policy')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/about-us' }}>
  //           <a className="menu-item">{t('About us')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/help-page' }}>
  //           <a className="menu-item">{t('FAQ')}</a>
  //         </I18nLink>
  //         <I18nLink href={{ pathname: '/inquiry' }}>
  //           <a className="menu-item">{t('inquiry')}</a>
  //         </I18nLink>
  //       </div>
  //       <style jsx>
  //         {`
  //           .menu-more {
  //             display: flex;
  //             flex-direction: column;
  //             min-width: 150px;
  //           }

  //           .menu-item {
  //             padding: ${themeVariables.space.small}px;
  //             color: ${themeVariables.color.dark};
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             white-space: nowrap;
  //           }
  //           .menu-item:hover {
  //             background: ${themeVariables.color.gray};
  //           }
  //         `}
  //       </style>
  //     </DropdownBottom>
  //   );
  // };

  render() {
    const { profile, isAuthenticated, lng } = this.props;
    let showUserInfo = false;
    if (isAuthenticated) {
      if (profile.role !== USER_ROLES.CREATOR) {
        showUserInfo = true;
      } else if (
        profile.role === USER_ROLES.CREATOR &&
        Config.isCreatorAvailable(lng)
      ) {
        showUserInfo = true;
      }
    }
    const { styles: linkStyles } = getLinkStyles({ lng });

    return (
      <div className="desktop-view">
        <div className="left-section">
          {/* {this.renderCategoryDropdown()} */}
          {/* {this.renderOtherStockSites()} */}
          {/* {this.renderVideoAC()} */}
          {/* {this.renderDesignTool()} */}
          {/* {this.renderFileTransferService()} */}
          {/* {this.renderAIProjectsDropdown()} */}
          {/* {this.renderAILink()} */}
          {/* {this.renderBlog()}
          {this.renderCategories()}
          {!Config.isDesignAC() && this.renderCollections()} */}
          {/* {this.renderSNS()} */}
          {/* {this.renderBlog()}
          {this.renderHelpage()} */}
          {/* {this.renderCreatorsLink()} */}
        </div>
        <div className="right-section">
          {/* {Config.isCreatorAvailable(lng) && this.renderSellYourContent()} */}
          {/* {this.renderPremiumButton()} */}
          {this.renderPhotoCart()}
          {showUserInfo ? <UserHeaderInfo /> : this.renderLoginAndSignup()}
          {/* {this.renderMoreMenu()} */}
        </div>
        {linkStyles}
        <style jsx>
          {`
            .desktop-view {
              width: 100%;
              height: 100%;
              display: none;
              padding: 0 16px;
            }

            .left-section,
            .right-section {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: nowrap;
            }
            .left-section {
              justify-content: flex-start;
            }
            .right-section {
              flex: 1;
              justify-content: flex-end;
            }
            .right-section > * {
              margin-left: ${themeVariables.space.half}px;
            }

            @media (min-width: ${themeVariables.breakpoints.tabletL
                .minWidth}px) {
              .desktop-view {
                display: flex;
                flex-direction: row;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

function getLinkStyles({ lng }) {
  return css.resolve`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${themeVariables.colors.bodyText};
    font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 16px;
    height: 100%;

    :hover {
      color: ${themeVariables.colors.primary};
      text-decoration: underline !important;
    }
  `;
}

export default DesktopView;
