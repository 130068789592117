import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_DONATION_INFOR,
  LOAD_DONATION_INFOR_SUCCESS,
  LOAD_DONATION_INFOR_ERROR,
} from './constants';
import * as donationFreebieService from '~/services/donate-service';

function* loadDonation() {
  try {
    const { data } = eval(
      yield call(donationFreebieService.getDonationData, {}),
    );
    yield put({
      type: LOAD_DONATION_INFOR_SUCCESS,
      donation: {
        last_month: data.lm,
        last_day: data.ld,
        total: data.total,
      },
    });
  } catch (error) {
    console.log('loadDonationnnnnnnnnn ERROR', error.response, error.message);
    yield put({
      type: LOAD_DONATION_INFOR_ERROR,
      error: true,
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* premiumSaga() {
  yield takeLatest(LOAD_DONATION_INFOR, loadDonation);
}
