// import Immutable from 'seamless-immutable';
import {
  GET_CREATOR_PROFILE_SUCCESS,
  GET_CREATOR_PROFILE_ERROR,
  PRE_GET_CREATOR_PROFILE,
  PRE_GET_CREATOR_DATA,
  GET_CREATOR_DATA_SUCCESS,
  GET_CREATOR_DATA_ERROR,
  CHANGE_PAGE,
  FILTER_PER_PAGE,
} from './constants';
import {
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
} from '~/components/Gallery/constants';

export const initState = {
  profile: {},
  collection: [],
  loading: true,
  pagination: {
    has_next: false,
    page: 1,
    max_results: 100,
    total_page: 0,
    total_item: 0,
  },
};

function creatorProfile(state = initState, action = {}) {
  switch (action.type) {
    case GET_CREATOR_PROFILE_SUCCESS:
      return {
        ...state,
        ...action,
        profile: action.profile || null,
        loading: false,
      };
    case GET_CREATOR_PROFILE_ERROR:
      return { ...state, profile: null, loading: false };
    // return { ...state, loading: false };
    case PRE_GET_CREATOR_PROFILE:
      return { ...state, ...action.payload, loading: true };
    case PRE_GET_CREATOR_DATA:
      return { ...state, loading: true };
    case GET_CREATOR_DATA_SUCCESS:
      return { ...state, ...action, loading: false };
    case GET_CREATOR_DATA_ERROR:
      return { ...state, loading: false };
    case CHANGE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: action.page || 1 },
      };
    case FILTER_PER_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, max_results: action.value },
      };
    case NICE_PHOTO_SUCCESS:
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
      };
    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}

export default creatorProfile;
