import React from 'react';
import { formatFloatNumber } from '~/utils/formatter';

const FormattedNumber = ({ className, style, value }) => {
  return (
    <span className={className} style={{ whiteSpace: 'nowrap', ...style }}>
      {formatFloatNumber(value)}
    </span>
  );
};

export default FormattedNumber;
