import { LOAD_TOKEN, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from './constants';

export const initialState = {
  token: '',
  resetSuccess: false,
  error: false,
};

function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TOKEN:
      return { ...state, token: action.token };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetSuccess: true };

    case RESET_PASSWORD_ERROR:
      return { ...state, error: action.error, resetSuccess: false };

    default:
      return state;
  }
}

export default resetPasswordReducer;
