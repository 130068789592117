import { takeLatest, call, put, select } from 'redux-saga/effects';
import { createStructuredSelector } from 'reselect';
import {
  LOAD_DATA_PRESENT,
  LOAD_DATA_SUCCESS,
  LOAD_DATA_ERROR,
} from './constants';
import { makeSelectToken } from '~/containers/AuthProvider/selectors';

import * as DashboardService from '~/services/my-page/DashboardService';

function* loadListPresentGift() {
  const tokenSelector = yield select(
    createStructuredSelector({ token: makeSelectToken() }),
  );
  try {
    const listPresent = yield call(
      DashboardService.getListPresentGift,
      tokenSelector.token,
    );
    yield put({
      type: LOAD_DATA_SUCCESS,
      ...listPresent,
    });
  } catch (error) {
    yield put({
      type: LOAD_DATA_ERROR,
      ...error,
    });
  }
}

export default function* load() {
  yield takeLatest(LOAD_DATA_PRESENT, loadListPresentGift);
}
