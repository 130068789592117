import axios from './interceptor-axios';
import config from '~/config';

function getBlogsHomepage(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/blog/homepage`,
    params: {
      service_type: config.serviceType,
      lang: params.lang || config.language,
    },
  };
  return axios(request);
}

/**
 *
 * @param params
 * @returns {Promise}
 */

export { getBlogsHomepage };
