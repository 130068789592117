import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_METADATA,
  LOAD_METADATA_ERROR,
  LOAD_METADATA_SUCCESS,
} from './constants';
import { supportedLanguages } from '~/../next-i18next.config';
import config from '~/config';
import * as categoryService from '~/services/category';

function* loadMetadata(params) {
  try {
    let categories = yield call(categoryService.getCategories, {
      language: params.language,
    });

    if (config.serviceType === 'design_ac') {
      categories = categories.filter((category) => category.id != 8);
    }
    yield put({
      type: LOAD_METADATA_SUCCESS,
      metadata: {
        categories,
        currentLang: params.language || config.language,
        serviceType: config.serviceType,
        isServer: params.isServer,
        // isEdgeOrIE: params.isEdgeOrIE,
        // isMobileOrTablet: params.isMobileOrTablet,
        // isMacOS: params.isMacOS,
        link: params.link,
        isLocaleInPath:
          process.env.NODE_ENV === 'development' || // When running local app
          !process.env.DOMAIN || // Or when NOT using subdomain
          (params.link &&
            supportedLanguages.some((lng) =>
              params.link.startsWith(`/${lng}`),
            )), // Or when link has locale in subpath
      },
    });
  } catch (error) {
    yield put({ type: LOAD_METADATA_ERROR, error });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* metaDataSaga() {
  yield takeLatest(LOAD_METADATA, loadMetadata);
}
