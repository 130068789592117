import React from 'react';
import PropTypes from 'prop-types';
import { urls } from '~/utils';

class Avatar extends React.Component {
  render() {
    return (
      <div
        className={`avatar-img ${this.props.className}`}
        style={{
          backgroundImage: `url(${urls.renderAvatarUrl(this.props.src)})`,
          width: this.props.width,
          height: this.props.height,
          ...this.props.style,
        }}
      />
    );
  }
}

Avatar.defaultProps = {
  src: '',
  className: '',
  width: 150,
  height: 150,
};

Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Avatar;
