import {
  LOAD_TOKEN,
  LOAD_PROFILE,
  SET_AUTHENTICATE,
  LOGOUT,
  UPDATE_PROFILE,
  UPDATE_ROLE,
  UPDATE_STATUS,
  LOAD_SESSION_ID,
  CHECK_USER_PREMIUM,
} from './constants';

export function loadToken(token) {
  return {
    type: LOAD_TOKEN,
    token,
  };
}

export function updateProfile(params) {
  return {
    type: UPDATE_PROFILE,
    params,
  };
}

export function updateRole(role) {
  return {
    type: UPDATE_ROLE,
    role,
  };
}

export function updateStatus(status) {
  return {
    type: UPDATE_STATUS,
    status,
  };
}

export function loadProfile(profile) {
  return {
    type: LOAD_PROFILE,
    profile,
  };
}

export function loadSessionId(sessionId) {
  return {
    type: LOAD_SESSION_ID,
    sessionId,
  };
}

export function setIsAuthenticated(isAuthenticate) {
  return {
    type: SET_AUTHENTICATE,
    isAuthenticate,
  };
}

export function logout(path) {
  return {
    type: LOGOUT,
    path,
  };
}

export function updateCheckPremiumUser(checkPremium) {
  return {
    type: CHECK_USER_PREMIUM,
    checkPremium,
  };
}
