import Config from '~/config';

const CHANGE_STATUS_COOKIE = 'CHANGE_STATUS_COOKIE';

const initialState = {
  show: false,
};

const noticeCookieReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_STATUS_COOKIE:
      Config.updateStatusNoticeCookie(action.bool);
      return Object.assign({}, state, { show: !action.bool });
    default:
      return state;
  }
};

export default noticeCookieReducer;
