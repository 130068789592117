import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCollection = (state) => state.collection || initialState;
const makeSelectCollections = () =>
  createSelector(
    selectCollection,
    (collectionsState) => collectionsState.photos,
  );
const makeSelectCollectionDetail = () =>
  createSelector(
    selectCollection,
    (collectionsState) => collectionsState.collectionDetail,
  );

const makeSelectCollectionData = () =>
  createSelector(selectCollection, (collectionsState) => collectionsState);

const makeSelectLoading = () =>
  createSelector(
    selectCollection,
    (collectionsState) => collectionsState.loading,
  );
const makeSelectSimilarCollection = () =>
  createSelector(
    selectCollection,
    (collectionsState) => collectionsState.similarData,
  );
const makeSelectPage = () =>
  createSelector(selectCollection, (collectionsState) => collectionsState.page);

const makeSelectCollectionsOfMonths = () =>
  createSelector(
    selectCollection,
    (collectionsState) => collectionsState.collections_months,
  );
export {
  makeSelectCollectionDetail,
  makeSelectCollectionData,
  makeSelectPage,
  makeSelectLoading,
  makeSelectSimilarCollection,
  makeSelectCollectionsOfMonths,
  makeSelectCollections,
};
