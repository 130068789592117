import PropTypes from 'prop-types';
import React from 'react';
// import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dynamic from 'next/dynamic';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import Search from '~/containers/Search';
import I18nLink from '~/components/I18nLink';
import Logo from '~/components/Layout/Logo';
import themeVariables from '~/shared/themeVariables';
import UserHeaderInfo from '~/components/Layout/UserHeaderInfo';
import { USER_ROLES } from '~/utils/constants';
import IconCountBulkDownload from '~/components/svg/count-bulk-download.svg';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from '~/containers/AuthProvider/selectors';
import { selectLayout } from '~/containers/Layout/selectors';
// import { makeSelectCategories } from '~/containers/MetadataProvider/selectors';
import { makeSelectCategories } from '~/containers/Search/selectors';
import {
  loadPhotosInCart,
  setIsMobileLeftMenuOpen,
  setIsMobileRightMenuOpen,
  setIsPhotoCartOpen,
} from '~/containers/Layout/actions';
import DropdownBottom from '~/components/DropdownBottom';
import {
  updateShowSearchFormScrollHomepage,
  updateShowFilterOnHeader,
} from '~/containers/Layout/actions';
// import RefineSearchNew from '~/containers/Search/RefineSearchNew/Loadable';
import { makeSelectToggleFilterSearch } from '~/containers/Search/selectors';
import { makeSelectQuery } from '~/containers/SearchResultPage/selectors';
const RefineSearchNew = dynamic(() =>
  import('~/containers/Search/RefineSearchNew'),
);

const mapStateToProps = createStructuredSelector({
  categories: makeSelectCategories(),
  profile: makeSelectProfile(),
  isAuthenticated: makeSelectIsAuthenticated(),
  layout: selectLayout,
  toggleFilterSearch: makeSelectToggleFilterSearch(),
  query: makeSelectQuery(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIsPhotoCartOpen,
      setIsMobileLeftMenuOpen,
      setIsMobileRightMenuOpen,
      loadPhotosInCart,
      updateShowSearchFormScrollHomepage,
      updateShowFilterOnHeader,
    },
    dispatch,
  );

@connect(mapStateToProps, mapDispatchToProps)
@withI18next()
@withRouter
class SearchHeader extends React.PureComponent {
  state = {
    showSearch: false,
    checkToggleSearch: false,
    // isTabletS: false,
  };

  handleToggleSearchBar = () => {
    this.setState((state) => ({
      showSearch: !state.showSearch,
    }));
  };
  handleCheckToggleSearch = (x) => {
    this.setState(() => ({
      checkToggleSearch: x,
    }));
  };
  componentDidMount() {
    // if (window.innerWidth <= themeVariables.breakpoints.tabletS.maxWidth) {
    //   this.setState({ isTabletS: true });
    // } else {
    //   this.setState({ isTabletS: false });
    // }
    // window.addEventListener('resize', debounce(this.checkIsTabletS, 200));
    window.addEventListener('scroll', this.listenToScroll);
  }
  // checkIsTabletS = () => {
  //   if (window.innerWidth <= themeVariables.breakpoints.tabletS.maxWidth) {
  //     this.setState({ isTabletS: true });
  //   } else {
  //     this.setState({ isTabletS: false });
  //   }
  // };

  componentWillUnmount() {
    // window.removeEventListener('resize', this.checkIsTabletS);
    window.removeEventListener('scroll', this.listenToScroll);
  }

  renderSignUpDownloader = () => {
    const { t, lng } = this.props;

    return (
      <a className="btn-signup-downloader" onClick={this.handleSignUpSSO}>
        {t('downloader_signup')}
        <style jsx>
          {`
            .btn-signup-downloader {
              color: ${themeVariables.color.white};
              align-self: center;
              background: ${themeVariables.color.dark};
              padding: ${themeVariables.space.extraSmall}px
                ${themeVariables.space.small}px;
              width: 96px;
              height: 44px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin-left: 16px;
              ${Config.isIllustAC()
                ? 'border-radius: 40px;'
                : `text-transform: uppercase;
                border-radius: 4px ;`}
              font-size: 14px;
              font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
            }

            .btn-signup-downloader:hover {
              background: ${themeVariables.colors.primary};
            }
          `}
        </style>
      </a>
    );
  };
  handleTogglePhotoCart = () => {
    const { setIsPhotoCartOpen, layout } = this.props;
    setIsPhotoCartOpen(!layout.isPhotoCartOpen);
  };
  renderPhotoCart = () => {
    const { profile, layout } = this.props;
    if (profile.role !== USER_ROLES.PREMIUM) return null;
    return (
      <div className="photo-cart" onClick={this.handleTogglePhotoCart}>
        {Config.isIllustAC() ? (
          <FontAwesomeIcon
            icon={faFolderOpen}
            style={{
              width: 28,
              color: '#000000',
            }}
          />
        ) : (
          <IconCountBulkDownload />
        )}
        <div className="container-count-photo">
          <div className="container-arrow-left">
            <div className="arrow-left"></div>
          </div>
          <div className="count-photo">{layout.photosInCart.length}</div>
        </div>

        <style jsx>
          {`
            .container-count-photo {
              display: flex;
              margin-left: 4px;
            }
            .container-arrow-left {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .arrow-left {
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-right: 4px solid
                ${Config.isIllustAC() ? '#000000' : '#1d7ab3'};
            }
            .photo-cart {
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            }
            .count-photo {
              min-width: 30px;
              justify-content: center;
              border-radius: 4px;
              padding: 2px 6px;
              font-weight: 500;
              font-size: 12px;
              color: #ffffff;
              display: flex;
              align-items: center;
              background: ${Config.isIllustAC() ? '#000000' : '#1d7ab3'};
            }
          `}
        </style>
      </div>
    );
  };

  renderLogin = () => {
    const { t, lng } = this.props;

    return (
      <DropdownBottom
        key="login"
        style={{
          height: '100%',
          marginLeft: themeVariables.space.half,
          display: 'flex',
          minWidth: '32px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        align="right"
        noArrow
        header={<span className="btn-login">{t('Login')}</span>}
      >
        <div className="menu-login">
          <a className="menu-item" onClick={this.handleLoginSSO}>
            {t('downloader_login')}
          </a>
          <I18nLink href={{ pathname: '/login' }}>
            <a className="menu-item">
              {t(Config.getPrefixContent('creator_login'))}
            </a>
          </I18nLink>
          <style jsx>
            {`
              .btn-login {
                color: ${themeVariables.color.dark};
                margin-left: 16px;
                font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
                font-size: 14px;
                text-transform: uppercase;
              }

              .menu-login {
                display: flex;
                flex-direction: column;
                min-width: 150px;
              }

              .menu-item {
                padding: ${themeVariables.space.small}px;
                color: ${themeVariables.color.dark};
                display: flex;
                flex-direction: row;
                align-items: center;
                white-space: nowrap;
              }
              .menu-item:hover {
                background: ${themeVariables.color.gray};
              }
            `}
          </style>
        </div>
      </DropdownBottom>
    );
  };
  renderSignUpCreator = () => {
    const { t, lng } = this.props;

    return (
      <I18nLink href={{ pathname: '/creator-registration' }}>
        <a className="btn-signup-creator">
          {t(Config.getPrefixContent('creator_signup'))}
          <style jsx>
            {`
              .btn-signup-creator {
                align-self: center;
                color: ${themeVariables.color.dark};
                font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
                font-size: 14px;
                text-transform: uppercase;
                min-width: 100px;
              }
              .btn-signup-creator:hover {
                color: ${themeVariables.colors.primary};
                text-decoration: underline !important;
              }
            `}
          </style>
        </a>
      </I18nLink>
    );
  };

  getSSORedirectURL = () => {
    const { lng } = this.props;
    let redirectURL = window.location.href;
    if (
      redirectURL.includes('creator-registration') ||
      redirectURL.includes('login')
    ) {
      redirectURL = Config.getSiteURL();
    }
    return redirectURL;
  };
  handleLoginSSO = () => {
    const { lng } = this.props;
    window.open(
      `${Config.ssoServerUrl}/login?lang=${lng}&serviceURL=${encodeURIComponent(
        this.getSSORedirectURL(),
      )}`,
      '_self',
    );
  };

  handleSignUpSSO = () => {
    const { lng } = this.props;
    window.open(
      `${
        Config.ssoServerUrl
      }/signup?lang=${lng}&serviceURL=${encodeURIComponent(
        this.getSSORedirectURL(),
      )}`,
      '_blank',
    );
  };

  renderLoginAndSignUpForDownloader = () => {
    const { t, lng } = this.props;

    return (
      <div className="login-signup-wrapper">
        <a
          className={`btn-login ${Config.serviceType}`}
          onClick={this.handleLoginSSO}
        >
          {t('Login')}
        </a>
        {this.renderSignUpDownloader()}

        <style jsx>
          {`
            .login-signup-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .btn-login {
              color: ${themeVariables.color.dark};
              font-weight: ${lng === 'tw' || lng === 'cn' ? 500 : 600};
              font-size: 14px;
              ${Config.isIllustAC()
                ? `width: 90px;
              height: 40px;
              padding: 12px 20px;
              border: 1px solid #7A7A7A;
              border-radius: 40px;
              font-weight: 700;
              `
                : `text-transform: uppercase;  
                padding: 4px 8px;
                border: 1px solid #1b2833;
                height: 44px;
                border-radius: 4px;`}
              width: 96px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .btn-login:hover {
              ${Config.isIllustAC()
                ? `color:#FFFFFF;
                background-color:${themeVariables.colors.primary};
                border: none;`
                : `background-color: ${themeVariables.colors.primary};
                  color: #FFFFFF;
                  border: none;
                `}
            }
          `}
        </style>
      </div>
    );
  };
  renderLoginAndSignup = () => {
    const { lng } = this.props;

    if (Config.isCreatorAvailable(lng)) {
      return (
        <>
          {/* {this.renderSignUpCreator()} */}
          {this.renderSignUpDownloader()}
          {this.renderLogin()}
        </>
      );
    }

    return this.renderLoginAndSignUpForDownloader();
  };

  listenToScroll = () => {
    const { router } = this.props;
    // const { isShowSearchForm, isShowFilterOnHeader } = layout;
    const isHomePage = router.pathname === '/index' || router.pathname === '/';
    const isSearchResult = router.pathname.indexOf('/search/') !== -1;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (isHomePage) {
      if (winScroll > 350) {
        this.props.updateShowSearchFormScrollHomepage(false);
      } else {
        this.props.updateShowSearchFormScrollHomepage(true);
      }
    }
    if (isSearchResult) {
      if (winScroll > 200 && window.innerWidth > 599) {
        this.props.updateShowFilterOnHeader(true);
      } else {
        this.props.updateShowFilterOnHeader(false);
      }
    }
  };
  render() {
    const {
      profile,
      show,
      onSearchSubmit,
      // isPremiumPage,
      // countdown,
      // isDiscountDisplayed,
      lng,
      // isBannerDisplayed,
      // isShowHeaderExternal,
      isAuthenticated,
      t,
      layout,
      router,
      toggleFilterSearch,
      query,
    } = this.props;
    const { isShowSearchForm, isShowFilterOnHeader, isPhotoCartOpen } = layout;
    const isHomePage = router.pathname === '/index' || router.pathname === '/';
    const isSearchResult = router.pathname.indexOf('/search/') !== -1;
    const isSearchResultImage =
      router.pathname.indexOf('/search-result') !== -1;
    const opacity = show ? 1 : 0;

    // let topSpace = 0; // Height of external links header
    // if (this.state.isTabletS) {
    //   topSpace += 58; // Height of menu header
    // } else {
    //   topSpace = 0;
    // }
    // if (isBannerDisplayed) {
    //   topSpace += 91; // Height of creator banner
    // }
    // if (isDiscountDisplayed) {
    //   topSpace += 60; // Height of discount banner
    // }
    // if (!isShowHeaderExternal) {
    //   topSpace = 58;
    // }
    let showUserInfo = false;
    if (isAuthenticated) {
      if (profile.role !== USER_ROLES.CREATOR) {
        showUserInfo = true;
      } else if (
        profile.role === USER_ROLES.CREATOR &&
        Config.isCreatorAvailable(lng)
      ) {
        showUserInfo = true;
      }
    }
    // if (Config.isDesignAC()) {
    //   topSpace = 0;
    // }

    return (
      <>
        <header
          className="search-header"
          style={{
            opacity,
            // top:
            //   isHomePage && isShowSearchForm && this.state.isTabletS
            //     ? topSpace - 58
            //     : topSpace,
            // display: Config.isDesignAC() ? 'none' : 'block',
          }}
        >
          <div className="search-header-wrapper">
            <div className="top-section">
              <div className="left-section-header">
                <div className="wrapper-logo">
                  <I18nLink href={{ pathname: '/' }}>
                    <a>
                      <div className="wrapper-logo-container">
                        {Config.isPhotoAC() ? (
                          <img
                            alt="photo ac logo header"
                            src={`${Config.staticPrefix}/static/assets/logo/photoAC_header_logo.svg`}
                            className="lazyload photo-header-logo"
                          />
                        ) : (
                          <>
                            <div className="container-logo">
                              <Logo byDevice logoNoText />
                            </div>
                            <div className="container-text-site">
                              {t(Config.getPrefixContent('title_site'))}
                            </div>
                          </>
                        )}
                      </div>
                    </a>
                  </I18nLink>
                </div>
                {isHomePage && !isShowSearchForm && !Config.isDesignAC() && (
                  <div className="search-box">
                    <Search
                      inHeader
                      onSubmit={onSearchSubmit}
                      hidden={!show}
                      handleCheckToggleSearch={this.handleCheckToggleSearch}
                    />
                  </div>
                )}
                {!isHomePage && !Config.isDesignAC() && (
                  <div className="search-box">
                    <Search
                      inHeader
                      onSubmit={onSearchSubmit}
                      hidden={!show}
                      handleCheckToggleSearch={this.handleCheckToggleSearch}
                    />
                  </div>
                )}
                {/* {!Config.isDesignAC() && (
            <div className="search-box">
              <Search
                inHeader
                onSubmit={onSearchSubmit}
                hidden={!show}
                handleCheckToggleSearch={this.handleCheckToggleSearch}
              />
            </div>
          )} */}
              </div>
              <div className="right-section-header">
                <div className="icon-action-bulk-download">
                  {!Config.isDesignAC() && this.renderPhotoCart()}
                </div>
                {showUserInfo ? (
                  <UserHeaderInfo />
                ) : (
                  this.renderLoginAndSignup()
                )}
              </div>
            </div>
            {isSearchResult &&
              isShowFilterOnHeader &&
              Config.isIllustAC() &&
              !isSearchResultImage &&
              !isPhotoCartOpen && (
                <div className="container-search-filter">
                  <RefineSearchNew
                    t={t}
                    categories={this.props.categories}
                    toggle={toggleFilterSearch}
                    query={query}
                  />
                </div>
              )}
          </div>
          <style jsx>
            {`
              .search-header {
                height: ${isSearchResult &&
                isShowFilterOnHeader &&
                Config.isIllustAC()
                  ? 'auto'
                  : '70px'};
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 16px;
                padding-bottom: ${isSearchResult &&
                isShowFilterOnHeader &&
                Config.isIllustAC() &&
                !isSearchResultImage &&
                isPhotoCartOpen
                  ? '8px'
                  : 0};
                background-color: #f8f9fa;
                position: fixed;
                width: 100%;
                z-index: 900;
                color: #fff;
                -webkit-transition: opacity 0.25s ease-in-out;
                -moz-transition: opacity 0.25s ease-in-out;
                -ms-transition: opacity 0.25s ease-in-out;
                -o-transition: opacity 0.25s ease-in-out;
                transition: opacity 0.25s ease-in-out;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
              }
              .search-header-wrapper {
                display: flex;
                width: 100%;
                flex-direction: column;
                padding-top: ${isSearchResult &&
                isShowFilterOnHeader &&
                Config.isIllustAC()
                  ? '16px'
                  : '0'};
              }
              .top-section {
                display: flex;
              }
              .left-section-header {
                display: flex;
                width: 100%;
              }
              .search-box {
                flex: 1;
                height: 50px;
                margin-left: 16px;
                padding: 0;
              }
              .wrapper-logo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .wrapper-logo-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              }
              .container-logo {
                width: 100%;
                height: 28px;
              }
              .icon-action-bulk-download {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .right-section-header {
                display: flex;
                margin: auto;
                margin-left: 16px;
                align-items: center;
                height: 100%;
              }
              .container-text-site {
                font-weight: 500;
                font-size: 12px;
                color: #7a7a7a;
              }

              @media (max-width: ${themeVariables.breakpoints.tabletS
                  .maxWidth}px) {
                .right-section-header {
                  display: none;
                }
                .wrapper-logo-container {
                  display: none;
                }
              }

              @media only screen and (max-width: ${themeVariables.breakpoints
                  .tabletS.minWidth}px) {
                .search-box {
                  flex: 1;
                  height: 50px;
                  margin-left: 0px;
                  padding: 0;
                }
                .search-header {
                  display: ${isHomePage ? 'none' : 'flex'};
                  position: sticky;
                  top: 0;
                }
              }
            `}
          </style>
        </header>
      </>
    );
  }
}

SearchHeader.propTypes = {
  categories: PropTypes.array,
  children: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
  onLogout: PropTypes.func,
  subCategory: PropTypes.bool,
};
SearchHeader.defaultProps = {
  categories: [],
  isAuthenticated: false,
  profile: {},
  subCategory: false,
  onLogout: () => {},
};
export default SearchHeader;
