import { func } from 'prop-types';
import {
  LOAD_PHOTO,
  NICE_PHOTO,
  HIDE_GOI_IT,
  ASSET_SELECT,
  ASSET_SELECT_INDEX,
  DOWNLOAD_PHOTO,
  LOAD_STATISTICS,
  LOAD_SIMILAR_LIST,
  LOAD_SIMILAR_DESIGN,
  SHOW_ATTRIBUTE_MODAL,
  UPDATE_PHOTO,
  GET_CURRENT_STATUS_DOWNLOAD_PHOTO,
  OPEN_EDITOR,
  LOG_CLICK_PHOTO,
  SHOW_PHOTO_VOTING,
  HIDE_PHOTO_VOTING,
  INIT_OTHER_SERVICES_SIMILAR_DATA,
  LOAD_RELATED_DATA,
  LOAD_OTHER_SERVICES_SIMILAR_DATA,
  LOAD_CURRENT_USER_EXTRA_LICENSE,
  LOAD_CARD_INFO,
  CHECK_BOOKMARK_DATA,
  SAVE_BOOKMARK_DATA,
} from './constants';

export function loadPhoto(payload, api) {
  return {
    type: LOAD_PHOTO,
    payload,
    api,
  };
}

export function loadCurrentUserExtraLicense(payload) {
  return {
    type: LOAD_CURRENT_USER_EXTRA_LICENSE,
    payload,
  };
}

export function loadCardInfo() {
  return {
    type: LOAD_CARD_INFO,
  };
}

export function updatePhoto(payload) {
  return {
    type: UPDATE_PHOTO,
    payload,
  };
}

export function assetSelect(index) {
  return {
    type: ASSET_SELECT,
    index,
  };
}

export function assetSelectIndex(index) {
  return {
    type: ASSET_SELECT_INDEX,
    index,
  };
}

export function downloadPhoto(params) {
  return {
    type: DOWNLOAD_PHOTO,
    params,
  };
}

export function nicePhoto(id) {
  return {
    type: NICE_PHOTO,
    id,
  };
}

export function hideGotIt() {
  return {
    type: HIDE_GOI_IT,
  };
}

export function loadStatitics(id) {
  return {
    type: LOAD_STATISTICS,
    id,
  };
}

export function loadSimilar(query, lng) {
  return {
    type: LOAD_SIMILAR_LIST,
    query,
    lng,
  };
}

export function loadSimilarDesign(id, gotIt, lng) {
  return {
    type: LOAD_SIMILAR_DESIGN,
    id,
    gotIt,
    lng,
  };
}

export function showAttributeModal(modal) {
  return {
    type: SHOW_ATTRIBUTE_MODAL,
    modal,
  };
}

export function getCurrentStatusDownloadFromRedis(userId) {
  return {
    type: GET_CURRENT_STATUS_DOWNLOAD_PHOTO,
    userId,
  };
}

export function openEditor(params, newWindow) {
  return {
    type: OPEN_EDITOR,
    params,
    newWindow,
  };
}

export function logClick(params) {
  return {
    type: LOG_CLICK_PHOTO,
    params,
  };
}

export function showPhotoVoting() {
  return {
    type: SHOW_PHOTO_VOTING,
  };
}

export function hidePhotoVoting() {
  return {
    type: HIDE_PHOTO_VOTING,
  };
}

export function initOtherServiceImages() {
  return {
    type: INIT_OTHER_SERVICES_SIMILAR_DATA,
  };
}

export function loadOtherServiceImages() {
  return {
    type: LOAD_OTHER_SERVICES_SIMILAR_DATA,
  };
}

export function loadRelatedData(dataID) {
  return {
    type: LOAD_RELATED_DATA,
    payload: { dataID },
  };
}

export function checkBookmarkData(params) {
  return {
    type: CHECK_BOOKMARK_DATA,
    params,
  };
}

export function saveBookmark(params) {
  return {
    type: SAVE_BOOKMARK_DATA,
    payload: params,
  };
}
