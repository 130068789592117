import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import Config from '~/config';

class Annotate extends React.Component {
  render() {
    const { text, html, placement } = this.props;
    const id = uuid();
    if (!text && !html) {
      return (
        <span role="button">
          [
          <span className={`mypage-text-primary ${Config.serviceType}`}>?</span>
          ]
        </span>
      );
    }
    let popover;
    if (this.props.html) {
      popover = (
        <Popover id="popover-1">
          <span dangerouslySetInnerHTML={{ __html: this.props.html }} />
        </Popover>
      );
    } else {
      popover = <Popover id="popover-2">{this.props.text}</Popover>;
    }
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement={this.props.placement}
        overlay={popover}
      >
        <span role="button">
          ［
          <span className={`mypage-text-primary ${Config.serviceType}`}>?</span>
          ］
          <style jsx global>
            {`
              .mypage-text-primary.photo_ac {
                color: #509bdc;
              }

              .mypage-text-primary.illust_ac {
                color: #d73a4e;
              }

              .mypage-text-primary.silhouette_ac {
                color: #41b9b8;
              }

              .mypage-text-primary.design_ac {
                color: #ffa823;
              }
            `}
          </style>
        </span>
      </OverlayTrigger>
    );
  }
}

Annotate.defaultProps = {
  placement: 'top',
  text: '',
  html: '',
};

Annotate.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string,
  html: PropTypes.string,
};

export default Annotate;
