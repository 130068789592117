import React from 'react';
import PropTypes from 'prop-types';
import Layout from '~/containers/Layout';
import ServerError from '~/components/Errors/ServerError';
import NotFound from '~/components/Errors/NotFound';
import NotAuthorized from '~/components/Errors/NotAuthorized';

class ErrorPage extends React.Component {
  render() {
    const { statusCode } = this.props;
    let errorContent = null;
    if (statusCode === 404) {
      errorContent = <NotFound />;
    } else if (statusCode === 403) {
      errorContent = <NotAuthorized />;
    } else if (statusCode === 500) {
      errorContent = <ServerError />;
    }
    return (
      <Layout>
        <div className="container text-center">{errorContent}</div>
      </Layout>
    );
  }
}

ErrorPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default ErrorPage;
