import { OPEN_MODAL, CLOSE_MODAL, MODAL_SUBMIT } from './constants';

function photoSearchModalReducer(state = false, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return true;

    case MODAL_SUBMIT:
    case CLOSE_MODAL:
      return false;

    default:
      return state;
  }
}

export default photoSearchModalReducer;
