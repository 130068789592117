import {
  SEARCH_PHOTO,
  FILTER_ORDER_BY,
  FILTER_PER_PAGE,
  CHANGE_PAGE,
  SEARCH_PHOTO_ERROR,
  CHANGE_PERSONALIZE,
  SEARCH_OTHER_SERVICES,
  INIT_OTHER_SERVICE_SEARCH_RESULTS,
  CHANGE_TOGGLE_FILTER_SEARCH,
  NICE_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
  NICE_PHOTO,
} from './constants';

export function searchPhoto(payload, api) {
  return {
    type: SEARCH_PHOTO,
    payload,
    api,
  };
}

export function initOtherServiceSearchResults(payload, api) {
  return {
    type: INIT_OTHER_SERVICE_SEARCH_RESULTS,
    payload,
    api,
  };
}

export function searchOtherServices(payload, api) {
  return {
    type: SEARCH_OTHER_SERVICES,
    payload,
    api,
  };
}

export function changeOrder(value) {
  return {
    type: FILTER_ORDER_BY,
    value,
  };
}

export function changePerPage(value) {
  return {
    type: FILTER_PER_PAGE,
    value,
  };
}

export function changePage(page) {
  return {
    type: CHANGE_PAGE,
    page,
  };
}

export function changePersonalize(hasPersonalize) {
  return {
    type: CHANGE_PERSONALIZE,
    hasPersonalize,
  };
}

export function updatePhotoSearchError(error) {
  return {
    type: SEARCH_PHOTO_ERROR,
    error,
  };
}
export function changeToggleFilterSearch(value) {
  return {
    type: CHANGE_TOGGLE_FILTER_SEARCH,
    value,
  };
}
export function nicePhoto(value, pageFrom) {
  console.log('page from', pageFrom);
  return {
    type: NICE_PHOTO,
    value,
    pageFrom,
  };
}
