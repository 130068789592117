import React from 'react';
import { ErrorMessage } from 'formik';

class ErrorMessageCustom extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { name } = this.props;
    const render = msg => (
      <div className="error-messages show-message text-center">
        <div className="ac-bg field-message-error animated">{msg}</div>
      </div>
    );
    return (
      <ErrorMessage name={name} render={render} />
    );
  }
}

export default ErrorMessageCustom;
