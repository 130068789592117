import axios from '../interceptor-axios';
import Config from '~/config';

function getSubscriptionHistory(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiUser}/profile/subscription`,
    params: {
      limit: params.limit || 5,
      lastInvoiceId: params.lastInvoiceId,
    },
  };
  return axios(request);
}

function getExtraLicensePaymentHistory(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiUser}/profile/extra_license_subscription`,
    params: {
      lastReceiptId: params.lastReceiptId,
    },
  };
  return axios(request);
}

function getCardInformation(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiUser}/profile/card`,
    params: {},
  };
  return axios(request);
}

function updateCardInformation(params) {
  const request = {
    method: 'PUT',
    url: `${Config.apiUrl.apiUser}/profile/card`,
    data: {
      stripeToken: params.stripeToken,
    },
  };
  return axios(request);
}

function updateInvoiceInformation(params) {
  const request = {
    method: 'PUT',
    url: `${Config.apiUrl.apiUser}/profile/invoice`,
    data: {
      name: params.name,
    },
  };
  return axios(request);
}

function cancelExtraLicenseMembership(params) {
  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiData}/extra_license/cancel`,
    data: {
      reason: params.reason,
    },
  };
  return axios(request);
}

export {
  getSubscriptionHistory,
  getCardInformation,
  updateCardInformation,
  getExtraLicensePaymentHistory,
  cancelExtraLicenseMembership,
  updateInvoiceInformation,
};
