import React from 'react';
import Constant from './constant';
import { creatorLanguage } from './creatorLanguage';
import Config from '~/config';
import * as AuthConstants from '~/containers/AuthProvider/constants';

const roles = Config.getRoles();

export const NORMAL_MENU = [
  {
    path: `${Config.ssoServerUrl}${Constant.routersPathName.PROFILE.path}`,
    routeName: Constant.routersPathName.PROFILE.routeName,
    // label: 'My profile',
    label: 'edit_profile',
    // icon: 'icon-user-info2',
  },
  {
    path: Constant.routersPathName.HELP_PAGE.path,
    routeName: Constant.routersPathName.HELP_PAGE.routeName,
    label: 'footer_faq_help_page',
    // icon: 'icon-envelop',
  },
];

const profileMenuProvider = () => (WrappedComponent) => {
  class ComposedComponent extends React.Component {
    render() {
      const { profile = {} } = this.props;
      let menu = NORMAL_MENU;
      if (profile.role === roles.CREATOR) {
        menu = menu.filter(
          (item) =>
            item.routeName !== Constant.routersPathName.PROFILE.routeName,
        );
      }
      return <WrappedComponent menu={menu} {...this.props} />;
    }
  }

  return ComposedComponent;
};

export default profileMenuProvider;
