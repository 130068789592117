import React from 'react';
import { toast } from 'react-toastify';
import SocketService from '~/services/SocketService';
import Config from '~/config';
import LatestRegisteredUser from '~/containers/LogsRegisteredUsers/LatestRegisteredUser';

let wsService = null;
const isConnected = false;

const ROLES = {
  FREE: 1,
  PREMIUM: 2,
};

// const withSocket = (WrappedComponent) =>
//   class ExtendedComponent extends React.Component {
//     static async getInitialProps(ctx) {
//       let initialProps = WrappedComponent.getInitialProps
//         ? await WrappedComponent.getInitialProps(ctx)
//         : null;
//       if (!initialProps) initialProps = {};
//       return initialProps;
//     }

//     handleNotify = ({ data }) => {
//       const { isAuthenticated } = this.props;
//       const newLog = JSON.parse(data);
//       console.log('--- Checking authenticated or not ---');
//       if (!isAuthenticated) {
//         console.log('=> Not Authenticated');
//         toast.info(<LatestRegisteredUser log={newLog} />, {
//           autoClose: 13000,
//           position: toast.POSITION.BOTTOM_RIGHT,
//           toastId: 'toast.latest-registered-user',
//           className: 'layout-toast-info',
//         });
//       }
//       // else {
//       //   console.log('=> Authenticated');
//       //   const { registered_role_id } = newLog;
//       //   if (registered_role_id == ROLES.PREMIUM) {
//       //     toast.info(<LatestRegisteredUser log={newLog} />, {
//       //       autoClose: 13000,
//       //       position: toast.POSITION.BOTTOM_RIGHT,
//       //       toastId: 'toast.latest-registered-user',
//       //       className: 'layout-toast-info',
//       //     });
//       //   }
//       // }
//     };

//     handleSaveSession = () => {
//       if (!wsService.isConnected) return;
//       const { profile } = this.props;
//       wsService.saveSession({
//         userId: profile && profile.id,
//         serviceType: Config.serviceType,
//         pageUrl: window.location.href,
//       });
//     };

//     componentDidMount() {
//       if (!wsService) {
//         console.log('initSocket');
//         wsService = new SocketService({
//           onopen: this.handleSaveSession,
//           onmessage: this.handleNotify,
//         });
//       } else {
//         // this.handleSaveSession();
//       }
//     }

//     render() {
//       return <WrappedComponent {...this.props} />;
//     }
//   };

const withSocket = (WrappedComponent) =>
  class ExtendedComponent extends React.Component {
    handleNotify = ({ data }) => {
      const { isAuthenticated } = this.props;
      const newLog = JSON.parse(data);
      console.log('--- Checking authenticated or not ---');
      if (!isAuthenticated) {
        console.log('=> Not Authenticated');
        if (Config.isIllustAC()) {
          toast.error(<LatestRegisteredUser log={newLog} />, {
            autoClose: 13000,
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: 'toast.latest-registered-user',
            className: 'layout-toast-info',
          });
        } else {
          toast.info(<LatestRegisteredUser log={newLog} />, {
            autoClose: 13000,
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: 'toast.latest-registered-user',
            className: 'layout-toast-info',
          });
        }
      }
      // else {
      //   console.log('=> Authenticated');
      //   const { registered_role_id } = newLog;
      //   if (registered_role_id == ROLES.PREMIUM) {
      //     toast.info(<LatestRegisteredUser log={newLog} />, {
      //       autoClose: 13000,
      //       position: toast.POSITION.BOTTOM_RIGHT,
      //       toastId: 'toast.latest-registered-user',
      //       className: 'layout-toast-info',
      //     });
      //   }
      // }
    };

    handleSaveSession = () => {
      if (!wsService.isConnected) return;
      const { profile } = this.props;
      wsService.saveSession({
        userId: profile && profile.id,
        serviceType: Config.serviceType,
        pageUrl: window.location.href,
      });
    };

    componentDidMount() {
      if (!wsService) {
        console.log('initSocket');
        wsService = new SocketService({
          onopen: this.handleSaveSession,
          onmessage: this.handleNotify,
        });
      } else {
        // this.handleSaveSession();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withSocket;
