import { combineReducers } from 'redux';
// import Immutable from 'seamless-immutable';
import {
  LOAD_PRICE_PREMIUM,
  LOAD_PRICE_PREMIUM_SUCCESS,
  LOAD_PRICE_PREMIUM_ERROR,
  SELECTED_PRICE_PREMIUM,
  SHOW_LOADING_FULL_SCREEN,
  HIDE_LOADING_FULL_SCREEN,
  UPDATE_COUNTDOWN_SUCCESS,
} from './constants';

export const initialState = {
  prices: {},
  priceSelected: '',
  isSale: false,
  trial: [],
  saleDate: {},
  error: false,
  loading: false,
  end_date: '',
  start_date: '',
  isTrial: false,
  trialEndDate: '',
  coupon: {},
  countdownDiscount: {},
};

function premiumReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PRICE_PREMIUM:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LOAD_PRICE_PREMIUM_SUCCESS:
      Object.keys(action.prices).map(key => {
        const item = action.prices[key];
        item.active =
          item.plan === action.priceSelected ||
          Object.keys(action.prices).length === 1;
      });
      return {
        ...state,
        loading: false,
        prices: action.prices,
        isSale: action.isSale,
        saleDate: action.saleDate,
        trial: action.trial,
        end_date: action.end_date,
        start_date: action.start_date,
        priceSelected: action.priceSelected,
        isTrial: action.isTrial,
        trialEndDate: action.trialEndDate,
        coupon: action.coupon,
        countdownDiscount: action.countdownDiscount,
      };
    case LOAD_PRICE_PREMIUM_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SELECTED_PRICE_PREMIUM:
      const prices = { ...state.prices };
      let priceSelected = '';
      Object.keys(prices).map(key => {
        const item = prices[key];

        if (item.plan === action.plan) {
          item.active = true;
        } else {
          item.active = false;
        }
        if (item.active) {
          priceSelected = item.plan;
        }
      });
      return {
        ...state,
        priceSelected,
        prices,
      };
    case UPDATE_COUNTDOWN_SUCCESS:
      const { remainingDurationInMilliseconds } = action;
      return {
        ...state,
        countdownDiscount: {
          ...state.countdownDiscount,
          remainingDurationInMilliseconds,
        },
      };
    default:
      return state;
  }
}

export const initialStateLoad = {
  fullScreen: false,
  message: '',
};

function loadDisplay(state = initialStateLoad, action) {
  switch (action.type) {
    case SHOW_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: true,
      };
    case HIDE_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  result: premiumReducer,
  load: loadDisplay,
});
