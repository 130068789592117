import React from 'react';
import css from 'styled-jsx/css';
import ACImage from '~/components/ACImage';
import themeVariables from '~/shared/themeVariables';

const Blog = (data) => {
  return (
    <div className="blog-container">
      <a
        className="link-blog"
        style={{ height: 200 }}
        href={data.link}
        target="_blank"
        rel="noreferrer"
      >
        <ACImage
          src={data.urlImg}
          className="blog-thumbnail"
          alt={data.title}
          layout="fill"
          objectFit="cover"
        />
      </a>
      <div className="content-container">
        <a
          className="link-blog"
          href={data.link}
          target="_blank"
          rel="noreferrer"
        >
          <p className="title-blog">{data.title}</p>
        </a>
        <p className="content-image-blog">{data.summary}</p>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .blog-container {
    display: flex;
    flex-direction: column;
  }
  .title-blog {
    font-weight: bold;
    font-size: 16px;
  }
  .content-image-blog {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
  }
  .content-container {
    margin-top: 12px;
  }
  .link-blog {
    position: relative;
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }
  .link-blog:hover {
    opacity: 0.9 !important;
    color: #000000;
  }

  @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
    .blog-container {
      margin-right: 0;
    }
  }
`;

export default Blog;
