import {
  LOAD_COMMENTS_DATA,
  LOAD_COMMENTS_DATA_SUCCESS,
  POST_COMMENT,
  POST_COMMENT_SUCCESS,
  LOAD_REPLY_COMMENTS_DATA_SUCCESS,
  LOAD_REPLY_COMMENTS_DATA,
  LOAD_APPEND_COMMENTS_DATA,
  LOAD_APPEND_COMMENTS_DATA_SUCCESS,
  LOAD_MORE_REPLY_COMMENTS_DATA,
  LOAD_MORE_REPLY_COMMENTS_DATA_SUCCESS,
  LOAD_MORE_REPLY_COMMENTS_DATA_ERROR,
  POST_REPLY_COMMENT,
  POST_REPLY_COMMENT_SUCCESS,
  POST_LIKE_COMMENT_SUCCESS,
  POST_LIKE_COMMENT_ERROR,
  POST_LIKE_COMMENT,
} from './constants';

export const initialState = {
  comments: [],
  has_next: false,
  page: 1,
  error: false,
  loading: true,
  loadingAppendComment: false,
  total_page: 0,
  total_item: 0,
  loadingReply: false,
  loadingAppendReplyComment: false,
  object_id: null,
  max_results: 5,
  replyCommentActive: {},
  loadingSubmit: false,
  countComment: null,
};

function commentReducers(state = initialState, action) {
  switch (action.type) {
    case LOAD_COMMENTS_DATA:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LOAD_COMMENTS_DATA_SUCCESS: {
      console.log('load comment ra', action.collection);
      return {
        ...state,
        loading: false,
        error: false,
        comments: action.collection,
        has_next: action.has_next,
        object_id: action.collection[0]?.object_id || null,
        page: action.page,
        total_item: action.total_item,
        total_page: action.total_page,
        countComment: action.countComment,
      };
    }

    // case POST_COMMENT_SUCCESS: {
    //   const comments = (state.comments || []).slice(0);
    //   comments.unshift(action.comment);
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     comments: comments,
    //   };
    // }
    case LOAD_REPLY_COMMENTS_DATA:
      return {
        ...state,
        loadingReply: true,
        error: false,
      };
    case LOAD_REPLY_COMMENTS_DATA_SUCCESS: {
      const dataReply = action?.data || {};
      const newData = state.comments.map((data) => {
        if (data.id === dataReply.collection[0].comment_id)
          data.dataReply = dataReply;

        return data;
      });
      return {
        ...state,
        loadingReply: false,
        comments: newData,
      };
    }
    case LOAD_APPEND_COMMENTS_DATA: {
      return {
        ...state,
        loadingAppendComment: true,
        error: false,
      };
    }
    case LOAD_APPEND_COMMENTS_DATA_SUCCESS: {
      const newList = state.comments.concat(action.collection);
      return {
        ...state,
        loadingAppendComment: false,
        error: false,
        has_next: action.has_next,
        object_id: action.collection[0]?.object_id,
        page: action.page,
        total_item: action.total_item,
        total_page: action.total_page,
        comments: newList,
      };
    }

    case LOAD_MORE_REPLY_COMMENTS_DATA: {
      return {
        ...state,
        loadingAppendReplyComment: true,
      };
    }
    case LOAD_MORE_REPLY_COMMENTS_DATA_SUCCESS: {
      const dataReply = action?.data || {};
      let newData = state.comments.map((data) => {
        if (data.id === dataReply.collection[0].comment_id) {
          data.dataReply.collection = data.dataReply.collection.concat(
            dataReply.collection,
          );
          data.dataReply.has_next = dataReply?.has_next;
          data.dataReply.page = dataReply?.page;
        }
        return data;
      });
      return {
        ...state,
        loadingAppendReplyComment: false,
        comments: newData,
        replyCommentActive: action.data,
      };
    }
    case LOAD_MORE_REPLY_COMMENTS_DATA_ERROR: {
      return { ...state, loadingAppendReplyComment: false };
    }
    case POST_REPLY_COMMENT: {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
    case POST_REPLY_COMMENT_SUCCESS: {
      const dataReply = action?.data?.data || {};

      let dataReplyNew = [dataReply];
      let newData = state.comments.map((data) => {
        if (data.id === dataReply?.comment_id) {
          if (data?.dataReply?.collection.length > 0) {
            data.dataReply.collection = dataReplyNew.concat(
              data.dataReply.collection,
            );
            data.countReply = data.countReply + 1 || 1;
          } else {
            // data.dataReply = {
            //   page: 1,
            //   has_next: false,
            // };
            data.countReply = data.countReply + 1 || 1;
            data.dataReply = {
              collection: dataReplyNew,
              page: 1,
              has_next: false,
            };
          }
          // data.dataReply.has_next = dataReply?.has_next;
          // data.dataReply.page = dataReply?.page;
        }
        return data;
      });
      return {
        ...state,
        comments: newData,
        loadingSubmit: false,
      };
    }
    case POST_COMMENT: {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
    case POST_COMMENT_SUCCESS: {
      const newData = [action.data.data];
      const newList = newData.concat(state.comments);
      console.log('them comment moi xong', newList);
      return {
        ...state,
        comments: newList,
        loadingSubmit: false,
        countComment: action.data.data.countComment || null,
      };
    }

    case POST_LIKE_COMMENT: {
      const newData = state.comments.map((data) => {
        if (data.id === action.payload.comment_id) {
          if (!data.likeActive) {
            data.likeActive = !data.likeActive;
            data.count_like = data.count_like + 1 || 1;
          } else {
            data.likeActive = !data.likeActive;
            data.count_like = data.count_like - 1;
          }
        }
        return data;
      });
      console.log('newData', newData);
      return {
        ...state,
        comments: newData,
      };
    }
    case POST_LIKE_COMMENT_SUCCESS: {
      // const newData = state.comments.map((data) => {
      //   if (data.id === action.data.comment_id) {
      //     if (action.data.success) {
      //       data.likeActive = true;
      //     } else {
      //       data.likeActive = false;
      //     }
      //   }
      //   return data;
      // });
      return {
        ...state,
        // comments: newData,
      };
    }
    default:
      return state;
  }
}

export default commentReducers;
