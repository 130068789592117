import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

class QCodeComponent extends React.Component {
  render() {
    return <QRCode value={this.props.value} />;
  }
}

QCodeComponent.defaultProps = {
  value: '',
};

QCodeComponent.propTypes = {
  value: PropTypes.string,
};

export default QCodeComponent;
