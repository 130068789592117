import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { makeSelectCategories, selectSearch } from './selectors';
import { changeKeyword } from './actions';
import { makeSelectSearchPhotoModal } from '../SearchPhotoModal/selectors';
import { openModal, closeModal } from '../SearchPhotoModal/actions';
import Search from '~/containers/Search/Search';
import withI18next from '~/../hocs/_withI18next';

@withI18next()
class SearchContainer extends React.Component {
  state = {
    showRefineSearch: false,
    suggestions: [],
  };

  static getDerivedStateFromProps(props) {
    const heroSearchBarHiddenInViewport =
      props.inViewport !== undefined && props.inViewport === false;
    const hiddenInHeader = props.inHeader && props.hidden;
    if (heroSearchBarHiddenInViewport || hiddenInHeader) {
      return {
        showRefineSearch: false,
      };
    }
    return null;
  }

  componentDidMount() {
    setTimeout(() => {
      const { onShowSearchHeader, inViewport } = this.props;
      const heroSearchBarHiddenInViewport =
        inViewport !== undefined && inViewport === false;
      if (heroSearchBarHiddenInViewport && onShowSearchHeader) {
        onShowSearchHeader();
      }
    }, 1000);
  }

  onSubmit = () => {
    const { searchState, t } = this.props;
    let {
      keyword,
      excludeKeyword,
      category,
      excludeCategory,
      creatorName,
      excludeCreatorName,
      color,
      imageType,
      orderBy,
      shape,
      imageId,
      imageTitle,
      modelCount,
      modelAge,
      modelRelease,
    } = searchState;
    console.log('vo case nay ne xxxx', imageId);

    // const { showRefineSearch } = this.state;
    category = category.map((item) => item.value).toString();
    excludeCategory = excludeCategory.map((item) => item.value).toString();
    modelAge = modelAge.map((item) => item.value).toString();
    // let ModelRelease = {};
    // if (modelRelease) {
    //   switch (modelRelease) {
    //     case '0':
    //       ModelRelease = { model: '2', property: '0' };
    //       break;
    //     case '1':
    //       ModelRelease = { model: '0', property: '2' };
    //       break;
    //     case '2':
    //       ModelRelease = { model: '0', property: '0' };
    //       break;
    //   }
    // }
    if (!keyword && !category && !imageTitle && !imageId && !creatorName) {
      return toast.warn(t('search_message_not_input'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
    if (imageId && imageId !== '') {
      console.log('vo case nay ne', imageId);
      this.props.onSubmit({
        imageId,
      });
    } else {
      this.props.onSubmit({
        keyword,
        excludeKeyword,
        category,
        excludeCategory,
        creatorName,
        excludeCreatorName,
        color: color || 'all',
        modelCount,
        modelAge,
        imageType,
        imageTitle,
        orderBy,
        // modelRelease,
        shape: shape || 'all',
        modelRelease,
      });
      window.scrollTo(0, 0);
    }
    console.log(
      'vo day roi ne',
      keyword,
      category,
      imageTitle,
      imageId,
      creatorName,
    );
  };

  onToggleRefineSearch = (data) => {
    this.setState((state) => ({
      showRefineSearch: !state.showRefineSearch,
    }));
    this.props.handleCheckToggleSearch(!this.state.showRefineSearch);
  };

  onCloseRefineSearch = () => {
    this.setState({ showRefineSearch: false });
    this.props.handleCheckToggleSearch(false);
  };

  render() {
    const {
      categories,
      searchState,
      inHeader,
      forwardedRef,
      onChangeKeyword,
      searchPhotoModal,
    } = this.props;
    const {
      keyword,
      excludeKeyword,
      category,
      excludeCategory,
      creatorName,
      excludeCreatorName,
      imageType,
      orderBy,
      shape,
      imageId,
      imageTitle,
    } = searchState;
    const { showRefineSearch, suggestions } = this.state;

    return (
      <Search
        searchPhotoModal={searchPhotoModal}
        categories={categories}
        suggestions={suggestions}
        keyword={keyword}
        excludeKeyword={excludeKeyword}
        category={category}
        excludeCategory={excludeCategory}
        creatorName={creatorName}
        excludeCreatorName={excludeCreatorName}
        toggle={showRefineSearch}
        onChangeKeyword={onChangeKeyword}
        onCloseRefineSearch={this.onCloseRefineSearch}
        onToggleRefineSearch={this.onToggleRefineSearch}
        onOpenSearchByPhotoModal={this.props.onOpenSearchByPhotoModal}
        onCloseSearchByPhotoModal={this.props.onCloseSearchByPhotoModal}
        onSubmit={this.onSubmit}
        inHeader={inHeader}
        forwardedRef={forwardedRef}
        orderBy={orderBy}
        shape={shape}
        imageType={imageType}
        imageId={imageId}
        imageTitle={imageTitle}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  searchState: selectSearch,
  categories: makeSelectCategories(),
  searchPhotoModal: makeSelectSearchPhotoModal(),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeKeyword: (value) => {
    dispatch(changeKeyword(value));
  },
  onOpenSearchByPhotoModal: (index) => {
    dispatch(openModal(index));
  },
  onCloseSearchByPhotoModal: () => {
    dispatch(closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
