import { combineReducers } from 'redux';
import {
  LOAD_DATA_SUCCESS,
  LOAD_DATA_PRESENT,
  LOAD_DATA_ERROR,
} from './constants';

export const initialState = {
  present: [],
  loading: false,
  error: false,
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
};

function patternPresent(state = initialState, action) {
  switch (action.type) {
    case LOAD_DATA_PRESENT:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DATA_SUCCESS: {
      return {
        ...state,
        present: action.collection,
        loading: false,
        has_next: action.has_next,
        page: action.page,
        totalPage: action.total_page,
        totalItem: action.total_item,
      };
    }

    case LOAD_DATA_ERROR:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
}

export default patternPresent;
