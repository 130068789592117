import axios from './interceptor-axios';
import Config from '~/config';

function postContactUs(data) {
  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiNotice}/contact`,
    data,
  };
  return axios(request);
}

function addTranslation(data) {
  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiNotice}/translation`,
    data: {
      page_url: data.pageURL,
      incorrect_text: data.incorrectText,
      correct_text: data.correctText,
    },
  };
  return axios(request);
}

function getLatestRegisteredUsersRedis(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiS3Service}/registered-users/redis`,
    params,
  };
  return axios(request);
}

function getLatestRegisteredUsersDB(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiS3Service}/registered-users/db`,
    params,
  };
  return axios(request);
}

function getRegisteredUsers(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiS3Service}/registered-users`,
    params,
  };
  return axios(request);
}

export {
  postContactUs,
  addTranslation,
  getLatestRegisteredUsersRedis,
  getLatestRegisteredUsersDB,
  getRegisteredUsers,
};
