import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from './constants';

const initState = {
  title: '',
  description: '',
  modal: false,
  switchAccount: false,
};

function errorMessageErrorReducer(state = initState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      const newState = { ...state };

      if (action.title) {
        newState.title = action.title;
      }

      if (action.description) {
        newState.description = action.description;
      }

      if (action.switchAccount) {
        newState.switchAccount = action.switchAccount;
      }

      newState.modal = true;

      return { ...state, ...newState };

    case CLOSE_MODAL:
      return { ...state, ...initState };

    default:
      return state;
  }
}

export default errorMessageErrorReducer;
