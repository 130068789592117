import {
  LOAD_LATEST_REGISTERED_USERS,
  LOAD_REGISTERED_USERS,
  SHOW_REGISTERED_USERS_MODAL,
  HIDE_REGISTERED_USERS_MODAL,
  LOAD_PAGE,
} from './constants';

export function loadLatestRegisteredUsers() {
  return {
    type: LOAD_LATEST_REGISTERED_USERS,
  };
}

export function loadRegisteredUsers(payload) {
  return {
    type: LOAD_REGISTERED_USERS,
    ...payload,
  };
}

export function showRegisteredUsersModal() {
  return {
    type: SHOW_REGISTERED_USERS_MODAL,
  };
}

export function hideRegisteredUsersModal() {
  return {
    type: HIDE_REGISTERED_USERS_MODAL,
  };
}

export function loadPage(page) {
  return {
    type: LOAD_PAGE,
    payload: { page },
  };
}
