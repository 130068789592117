import React from 'react';
import cn from 'clsx';
import css from 'styled-jsx/css';
import Container from '~/components/Container';
import themeVariables from '~/shared/themeVariables';
import config from '~/config';

const SEOTextSub = ({ t, lng, catSlugEn }) => {
  let noScroll = false;
  if (config.isPhotoAC()) {
    noScroll = true;
  } else if (config.isIllustAC() && lng === 'en' && catSlugEn !== 'others') {
    noScroll = true;
  }
  let dataSEO = t(config.getPrefixContent('general_seo_text_sub'));

  return (
    <Container
      style={{ marginTop: 32 }}
      contentStyle={{
        padding: 32,
        background: themeVariables.colors.lightBackground,
        borderRadius: 4,
      }}
    >
      <article
        className={cn('seo-text-category')}
        dangerouslySetInnerHTML={{
          __html: dataSEO,
        }}
      />
      <style jsx global>
        {`
          .seo-text-category {
            width: 100%;
            height: 468px;
            overflow-x: hidden;
            overflow-y: scroll;
          }
          .seo-text-category.noScroll {
            width: 100%;
            height: auto;
            overflow-x: none;
            overflow-y: none;
          }
          .seo-text-category h4 {
            font-size: 18px;
            font-weight: bold;
          }

          .seo-text-category p {
            font-weight: 400;
          }

          .seo-text-category h5 {
            font-size: 16px;
            font-weight: bold;
          }
          .link-to {
            color: #1b2833;
            text-decoration: underline !important;
          }
          .link-to:hover {
            color: #1b2833;
            opacity: 0.9;
            text-decoration: underline !important;
          }
        `}
      </style>
    </Container>
  );
};

export default SEOTextSub;
