import {
  CHANGE_KEYWORD,
  CHANGE_EXCLUDE_KEYWORD,
  CHANGE_CATEGORY,
  CHANGE_EXCLUDE_CATEGORY,
  CHANGE_IMAGE_TYPE,
  CHANGE_IMAGE_SHAPE,
  CHANGE_IMAGE_ORDERBY,
  ON_SUBMIT,
  LOAD_DATA_SEARCH,
  CHANGE_IMAGE_ID,
  CHANGE_IMAGE_TITLE,
  CLEAR_SEARCH,
  CHANGE_CREATOR_NAME,
  CHANGE_EXCLUDE_CREATOR_NAME,
  CHANGE_COLOR,
  CHANGE_MODEL_COUNT,
  CHANGE_MODEL_AGE,
  CHANGE_ORIENTATION,
  CHANGE_MODEL_RELEASE,
  CHANGE_OPEN_ORDER_BY,
  CHANGE_OPEN_IMAGE_TYPE,
  CHANGE_OPEN_ORIENTATION,
  CHANGE_OPEN_KEYWORDS,
  CHANGE_OPEN_CATEGORY,
  CHANGE_OPEN_COLOR,
  CHANGE_OPEN_NUMBER_OF_PEOPLE,
  CHANGE_OPEN_AGE,
  CHANGE_OPEN_MODEL_RELEASE,
  CHANGE_OPEN_CREATOR,
  CHANGE_OPEN_IMAGE_TITLE,
  CHANGE_TOGGLE_FILTER_SEARCH,
  CHANGE_OPEN_RELEASES,
  CHANGE_OPEN_LICENSE,
  CHANGE_LICENSE,
  CLOSE_ALL_FILTER,
  OPEN_ALL_FILTER,
  CHANGE_OPEN_MORE,
} from './constants';

export function changeKeyword(value) {
  return {
    type: CHANGE_KEYWORD,
    value,
  };
}

export function changeExcludeKeyword(value) {
  return {
    type: CHANGE_EXCLUDE_KEYWORD,
    value,
  };
}

export function changeCategory(options) {
  return {
    type: CHANGE_CATEGORY,
    options,
  };
}

export function changeExcludeCategory(options) {
  return {
    type: CHANGE_EXCLUDE_CATEGORY,
    options,
  };
}

export function changeCreatorName(value) {
  return {
    type: CHANGE_CREATOR_NAME,
    value,
  };
}

export function changeExcludeCreatorName(value) {
  return {
    type: CHANGE_EXCLUDE_CREATOR_NAME,
    value,
  };
}

export function onSubmit(options) {
  return {
    type: ON_SUBMIT,
    options,
  };
}

export function loadDataSearch(query) {
  return {
    type: LOAD_DATA_SEARCH,
    query,
  };
}

export function changeImageType(value) {
  return {
    type: CHANGE_IMAGE_TYPE,
    value,
  };
}

export function changeOrderBy(value) {
  return {
    type: CHANGE_IMAGE_ORDERBY,
    value,
  };
}

export function changeShape(value) {
  return {
    type: CHANGE_IMAGE_SHAPE,
    value,
  };
}

export function changeImageId(value) {
  return {
    type: CHANGE_IMAGE_ID,
    value,
  };
}

export function changeImageTitle(value) {
  return {
    type: CHANGE_IMAGE_TITLE,
    value,
  };
}

export function changeColor(value) {
  return {
    type: CHANGE_COLOR,
    value,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function changeModelCount(value) {
  return {
    type: CHANGE_MODEL_COUNT,
    value,
  };
}

export function changeModelAge(options) {
  return {
    type: CHANGE_MODEL_AGE,
    options,
  };
}

export function changeOrientation(value) {
  return {
    type: CHANGE_ORIENTATION,
    value,
  };
}

export function changeModelRelease(value) {
  return {
    type: CHANGE_MODEL_RELEASE,
    value,
  };
}

export function changeLicense(value) {
  return {
    type: CHANGE_LICENSE,
    value,
  };
}

///xxx
export function changeOpenOrderBy(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_ORDER_BY,
    value,
    closeOtherFilter,
  };
}

export function changeOpenImageType(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_IMAGE_TYPE,
    value,
    closeOtherFilter,
  };
}
export function changeOpenOrientation(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_ORIENTATION,
    value,
    closeOtherFilter,
  };
}
export function changeOpenKeywords(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_KEYWORDS,
    value,
    closeOtherFilter,
  };
}
export function changeOpenCategory(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_CATEGORY,
    value,
    closeOtherFilter,
  };
}
export function changeOpenColor(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_COLOR,
    value,
    closeOtherFilter,
  };
}
export function changeOpenNumberOfPeople(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_NUMBER_OF_PEOPLE,
    value,
    closeOtherFilter,
  };
}
export function changeOpenAge(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_AGE,
    value,
    closeOtherFilter,
  };
}
export function changeOpenModelRelease(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_MODEL_RELEASE,
    value,
    closeOtherFilter,
  };
}
export function changeOpenCreator(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_CREATOR,
    value,
    closeOtherFilter,
  };
}
export function changeOpenImageTitle(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_IMAGE_TITLE,
    value,
    closeOtherFilter,
  };
}
export function changeOpenReleases(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_RELEASES,
    value,
    closeOtherFilter,
  };
}
export function changeOpenLicense(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_LICENSE,
    value,
    closeOtherFilter,
  };
}

export function changeToggleFilterSearch(value) {
  return {
    type: CHANGE_TOGGLE_FILTER_SEARCH,
    value,
  };
}

export function closeAllFilter(value) {
  return {
    type: CLOSE_ALL_FILTER,
    value,
  };
}

export function openAllFilter(value) {
  return {
    type: OPEN_ALL_FILTER,
    value,
  };
}
export function changeOpenMore(value, closeOtherFilter) {
  return {
    type: CHANGE_OPEN_MORE,
    value,
    closeOtherFilter,
  };
}
