import { call, put, takeLatest, select } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  LOAD_POPULAR_DATA,
  LOAD_POPULAR_DATA_SUCCESS,
  LOAD_POPULAR_DATA_ERROR,
} from './constants';
import { makeSelectPhotos } from './selectors';
import * as photoPageService from '~/services/photo-page';
import * as searchPageService from '~/services/search-page';
import {
  NICE_PHOTO,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO_ERROR,
} from '~/components/Gallery/constants';
import Config from '~/config';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';
import { listIdCategoryExclude } from '~/utils';

function* loadPopular(action) {
  try {
    const { payload, api } = action;
    const query = payload || {};
    const apiFunction = api
      ? api.search.searchData
      : searchPageService.searchData;
    let {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      dataType,
    } = yield call(apiFunction, {
      language: query.lang,
      per_page: query.per_page || 100,
      page: query.page || 1,
      clientIp: query.clientIp,
    });
    collection = collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      return item;
    });
    const listDataFilter = listIdCategoryExclude['popular-photos'].split(',');
    let newCollection = collection;

    if (Config.isPhotoAC()) {
      newCollection = collection.filter(
        (item) => !listDataFilter.includes(item.id.toString()),
      );
    }
    console.log(
      collection.length,
      'length collection',
      newCollection.length,
      'length newListCollection',
    );

    yield put({
      type: LOAD_POPULAR_DATA_SUCCESS,
      collection: newCollection,
      has_next,
      page: parseInt(page),
      total_page,
      total_item,
      dataType,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: LOAD_POPULAR_DATA_ERROR,
      error: (error.response && error.response.data) || {},
    });
  }
}

function* nicePhoto(action) {
  try {
    const photos = yield select(makeSelectPhotos());
    const searchedPhoto = photos.find((item) => item.id === action.id);
    if (!searchedPhoto) return;
    if (searchedPhoto.is_nice) {
      return yield put({
        type: OPEN_MODAL,
        title: i18n.t(Config.getPrefixContent('already_nice')),
      });
    }
    yield put({
      type: NICE_PHOTO_SUCCESS,
      id: action.id,
    });
    yield call(photoPageService.nicePhoto, action.id);
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

export default function* loadRecommendSaga() {
  yield takeLatest(LOAD_POPULAR_DATA, loadPopular);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
