import React from 'react';
import PropTypes from 'prop-types';

class FontIcomoonIcon extends React.PureComponent {
  render() {
    const styles = this.props.color
      ? Object.assign(this.props.style, { fill: this.props.color })
      : this.props.style;

    return (
      <>
        <svg
          style={styles}
          className={`icon ${this.props.icon} ${
            this.props.fixedWidth ? 'iconmoon-fw' : ''
          }`}
        >
          <use
            xlinkHref={`/static/icomoon/symbol-defs.svg#${this.props.icon}`}
          />
        </svg>
        <style jsx>
          {`
            .iconmoon-fw {
              width: 1.25em;
            }
            .icon {
              display: inline-block;
              height: 1em;
              stroke-width: 0;
              stroke: currentColor;
              fill: currentColor;
              width: 0.9091796875em;
            }
          `}
        </style>
      </>
    );
  }
}

FontIcomoonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  fixedWidth: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
};

FontIcomoonIcon.defaultProps = {
  icon: '',
  fixedWidth: false,
  color: '',
  style: {},
};

export default FontIcomoonIcon;
