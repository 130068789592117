import React from 'react';
import Router from 'next/router';
// import Error from 'next/error';
import config from '~/config';
import ErrorPage from '~/containers/ErrorPage';

const redirect = (context, target) => {
  console.log('redirecttttt', target);
  if (context.res) {
    // server
    context.res.writeHead(302, { Location: target });
    context.res.end();
  } else {
    // In the browser, we just pretend like this never even happened ;)
    Router.replace(target);
  }
};

export const redirectLogin = (lng) => {
  lng = lng || 'en';
  window.location.href = `${
    config.ssoServerUrl
  }/login?lang=${lng}&serviceURL=${encodeURIComponent(
    process.browser ? window.location.href : '',
  )}`;
};

export const throw404 = () => {
  return <ErrorPage statusCode={404} />;

  // if (process.browser) {
  //   return <ErrorPage statusCode={404} />;
  // }
  // const e = new Error();
  // e.code = 'PAGE_NOT_FOUND';
  // throw e;
};

export const redirectWithoutSSOToken = () => {
  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.forEach((value, key) => {
    console.log('searchParams', value, key);
  });
  searchParams.delete('ssoToken');
  const params = searchParams.toString();
  Router.replace(`${pathname}${params && '?' + params}`);
};

export default redirect;
