import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectLayout } from '~/containers/Layout/selectors';
import {
  updateDataCountSurvey,
  updateIsSurvey,
} from '~/containers/Layout/actions';
import LocalStorage from '~/services/LocalStorage';
import Config from '~/config';

function JapanSiteNotice({ onClose }) {
  const goToJapanSite = () => {
    onClose();
    const japanSite = Config.getJapanDomainByService();
    window.open(japanSite, '_blank');
  };

  const reject = () => {
    LocalStorage.hideJapanNotice = true;
    onClose();
  };

  return (
    <Modal show>
      <Modal.Body>
        <div className="text-survey">
          日本からアクセスしています。日本向けのコンテンツを表示しますか？
        </div>
        <div className="action-container">
          <div className="button-cancel" onClick={reject}>
            いいえ
          </div>
          <div className="button-survey" onClick={goToJapanSite}>
            はい
          </div>
        </div>
        <style jsx>
          {`
            .text-survey {
              margin-top: 24px;
              color: #1b2833;
              text-align: center;
            }
            .action-container {
              display: flex;
              justify-content: center;
              margin: 24px 0;
            }
            .button-cancel {
              width: 96px;
              height: 44px;
              background: #c4c4c4;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              cursor: pointer;
              color: #ffffff;
              margin-right: 16px;
            }
            .button-survey {
              width: 96px;
              height: 44px;
              background: #31d0aa;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              cursor: pointer;
              color: #ffffff;
              gap: 4px;
            }
          `}
        </style>
      </Modal.Body>
    </Modal>
  );
}

export default JapanSiteNotice;
