import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_CREATORS,
  GET_CREATORS_ERROR,
  GET_CREATORS_SUCCESS,
} from './constants';
import * as creatorService from '~/services/my-page/creator';

function* getCreators(action) {
  try {
    const { api, ...params } = action;
    const apiGetCreatorsFn = api
      ? api.user.getCreators
      : creatorService.getCreators;
    const { collection, code, ...pagination } = yield call(
      apiGetCreatorsFn,
      params,
    );
    yield put({
      type: GET_CREATORS_SUCCESS,
      pagination,
      collection,
      code,
    });
  } catch (e) {
    console.log('eeeee', e);
    yield put({ type: GET_CREATORS_ERROR });
  }
}

export default function* creatorsPageSaga() {
  yield takeLatest(GET_CREATORS, getCreators);
}
