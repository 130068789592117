import React from 'react';
import moment from 'moment';
import Config from './../../config';

switch (Config.language) {
  case 'zh-tw':
    require('moment/locale/zh-tw');
    break;
  case 'en':
    require('moment/locale/en-ie');
    break;
  default:
    require('moment/locale/ja');
}
moment.locale(Config.language);

class FormattedNumber extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const format = this.props.format || 'LTS';
    return (
      <span>{moment(this.props.value).format(format)}</span>
    );
  }
}

export default FormattedNumber;
