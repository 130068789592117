import axios from './interceptor-axios';
import config from '~/config';

function getRecommendData(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/data/recommended`,
    params: {
      pattern_id: params.pattern_id,
      lang: params.lang,
      per_page: params.per_page || 100,
      page: params.page || 1,
      download: params.download || 'desc',
      createdAt: params.createdAt,
      max_results: params.per_page || 100,
      free_only: params.free_only || false,
      clientIp: params.clientIp,
    },
  };
  return axios(request);
}

function getPatternDetailById(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiData}/pattern/detail`,
    params: {
      id: params.id,
      lang: params.lang,
      clientIp: params.clientIp,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}
export { getRecommendData, getPatternDetailById };
