import { createSelector } from 'reselect';

const selectRecommend = state => state.recommendPage.result;

const selectFilter = state => state.recommendPage.filter;

const makeSelectDataType = () => createSelector(selectRecommend, recommendState => recommendState.dataType);

const makeSelectPhotos = () => createSelector(selectRecommend, recommendState => recommendState.photos);

const makeSelectLoading = () => createSelector(selectRecommend, recommendState => recommendState.loading);

const makeSelectError = () => createSelector(selectRecommend, recommendState => recommendState.error);

const makeSelectPage = () => createSelector(selectRecommend, recommendState => recommendState.page);

const makeSelectTotalPage = () => createSelector(selectRecommend, recommendState => recommendState.totalPage);

const makeSelectTotalItem = () => createSelector(selectRecommend, recommendState => recommendState.totalItem);

const makeSelectPerPage = () => createSelector(selectFilter, filterState => filterState.perPage);


export {
  selectRecommend,
  makeSelectPhotos,
  makeSelectError,
  makeSelectPage,
  makeSelectTotalPage,
  makeSelectTotalItem,
  makeSelectPerPage,
  makeSelectLoading,
  makeSelectDataType,
};
