import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router, { withRouter } from 'next/router';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { closeModal, modalSubmit } from './actions';
import { makeSelectSearchPhotoModal } from './selectors';
import { makeSelectProfile, makeSelectToken } from '../AuthProvider/selectors';
import { makeSelectCurrentLanguage } from '../Language/selectors';
import { loadingHide, loadingShow } from '../Loading/actions';
import { updatePhotoSearchError } from '../SearchResultPage/actions';
import { clearSearch } from '../Search/actions';
import * as UploaderService from '~/services/my-page/uploadFile';
import withI18next from '~/../hocs/_withI18next';
import { searchPhoto } from '~/containers/SearchResultPage/actions';
import I18nRouter from '~/components/I18nRouter';
import themeVariables from '~/shared/themeVariables';
import Config from '~/config';

@withI18next()
@withRouter
class PhotoSearchModal extends React.Component {
  handleClose = () => {
    this.props.closeModal();
  };

  handleChangeInputFile = (files, rejectedFiles) => {
    const { t, currentLanguage, searchPhoto, router } = this.props;
    if (rejectedFiles.length) {
      const content = t('this_file_type_is_not_accepted', {
        type: ['JPG', 'PNG'].join(', '),
      });
      toast.error(content, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    if (!files.length) return;
    const file = files[0];
    if (file.size >= 5 * 1000000) {
      const content = t('search_photo_condition');
      toast.error(content, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    console.log('--- check file uploaded ---');
    console.log(files);
    this.props.closeModal();
    this.props.loadingShow({
      message: this.props.t('cancel_title_wait'),
      isSearchingByPhoto: true,
    });
    this.props.onClearSearch();

    UploaderService.uploadPhotoSearch(file, this.props.token)
      .then(async (fileUrl) => {
        const imageToken = fileUrl.original_url.split(
          'https://s3-ap-northeast-1.amazonaws.com/',
        )[1];
        let pageURL = `/search-result?image_token=${imageToken}`;
        // if (currentLanguage !== 'en') {
        pageURL = `/${currentLanguage}${pageURL}`;
        // }

        searchPhoto({
          image_token: imageToken,
          is_search_by_photo: true,
        });
        I18nRouter.push(
          {
            pathname: '/search-result',
            query: {
              image_token: imageToken,
              is_search_by_photo: true,
            },
          },
          pageURL,
          { shallow: true },
        );
      })
      .catch((error) => {
        console.log('--- check err ---');
        let status;
        let message;
        let code = 'unkown';
        console.log(error.response);
        if (error.response && error.response.data) {
          status = error.response.status;
          message = error.response.data.message;
          code = error.response.data.code;
        }

        let pageURL = `/search-result?error=${code.toLowerCase()}`;
        if (currentLanguage !== 'en') {
          pageURL = `/${currentLanguage}${pageURL}`;
        }

        searchPhoto({
          error_status: status,
          error_code: code,
          error_message: message,
          is_search_by_photo: true,
        });
        I18nRouter.push(
          {
            pathname: '/search-result',
            query: {
              error_status: status,
              error_code: code,
              error_message: message,
              is_search_by_photo: true,
            },
          },
          pageURL,
          { shallow: true },
        );
      });
  };

  render() {
    const { modal, t } = this.props;
    // const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    //   noDrag: true,
    // })
    return (
      <>
        <Modal
          show={modal}
          onHide={this.handleClose}
          className="photo-search-modal"
        >
          <Modal.Body
            style={{
              padding: '25px 25px',
            }}
          >
            <div className="photo-search-title">
              <span
                style={{
                  fontSize: '25px',
                }}
              >
                {t('search_by_image')}
              </span>
              <p>{t('choose_any_of_the_methods_below_to_start_your_search')}</p>
            </div>
            <Dropzone
              className="photo-search-dropzone"
              multiple={false}
              accept="image/jpeg,image/png"
              onDrop={this.handleChangeInputFile.bind(this)}
            >
              <FontAwesomeIcon
                icon={faUpload}
                className="upload-photo-search-icon"
                style={{ width: '1em' }}
              />
              {/* <p>Drag and drop your photo or click here to select</p> */}
              <p>{t('search_photo_guide')}</p>
            </Dropzone>
            <div className="photo-search-notice">
              <p>{t('search_photo_condition')}</p>
            </div>
          </Modal.Body>
        </Modal>
        <style jsx global>
          {`
            .photo-search-title {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 20px;
            }
            .photo-search-dropzone {
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
            }
            .photo-search-notice {
              text-align: center;
            }
            .photo-search-dropzone {
              height: 200px;
              text-align: center;
              border: 4px dashed #a8b2b1;
              background-color: #e8eded;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0px 20px;
              margin-bottom: 20px;
            }
            .upload-photo-search-icon {
              width: 45px;
              margin-bottom: 10px;
            }
            .photo-search-modal {
              // background: #2d090e;
              // opacity: 0.8 !important;
            }
            .modal-backdrop {
              height: 100%;
              width: 100%;
              background: ${Config.isIllustAC() ? '#170507' : '#0A1F32'};
              position: fixed;
              top: 0;
              left: 0;
              opacity: 0.75 !important;
              // filter: blur(10px);
            }
            @media (max-width: ${themeVariables.breakpoints.mobile
                .maxWidth}px) {
              .photo-search-modal {
                position: fixed;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgba(233, 228, 228, 0.7);
              }
            }
          `}
        </style>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modal: makeSelectSearchPhotoModal(),
  profile: makeSelectProfile(),
  token: makeSelectToken(),
  currentLanguage: makeSelectCurrentLanguage(),
});
const mapDispatchToProps = (dispatch) => ({
  onClearSearch: () => {
    dispatch(clearSearch());
  },
  closeModal: () => dispatch(closeModal()),
  modalSubmit: () => dispatch(modalSubmit()),
  loadingShow: (params) => dispatch(loadingShow(params)),
  loadingHide: () => dispatch(loadingHide()),
  updatePhotoSearchError: (error) => dispatch(updatePhotoSearchError(error)),
  searchPhoto: (error) => dispatch(searchPhoto(error)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhotoSearchModal);
