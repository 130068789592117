export const SET_IS_PHOTO_CART_OPEN =
  'containers/Layout/SET_IS_PHOTO_CART_OPEN';
export const SET_IS_MOBILE_LEFT_MENU_OPEN =
  'containers/Layout/SET_IS_MOBILE_LEFT_MENU_OPEN';
export const SET_IS_MOBILE_RIGHT_MENU_OPEN =
  'containers/Layout/SET_IS_MOBILE_RIGHT_MENU_OPEN';
export const SET_NUMBER_PHOTOS_IN_CART =
  'containers/Layout/SET_NUMBER_PHOTOS_IN_CART';
export const ADD_PHOTO_IN_CART = 'containers/Layout/ADD_PHOTO_IN_CART';
export const REMOVE_PHOTO_IN_CART = 'containers/Layout/REMOVE_PHOTO_IN_CART';
export const LOAD_PHOTOS_IN_CART = 'containers/Layout/LOAD_PHOTOS_IN_CART';
export const CLEAN_PHOTOS_IN_CART = 'containers/Layout/CLEAN_PHOTOS_IN_CART';
export const IS_SURVEY = 'containers/Layout/IS_SURVEY';
export const DATA_COUNT_SURVEY = 'containers/Layout/DATA_COUNT_SURVEY';
export const CHECK_IS_ACTIVE_EDITOR_DOWNLOAD =
  'containers/Layout/CHECK_IS_ACTIVE_EDITOR_DOWNLOAD';
export const ACTIVE_EDITOR_DOWNLOAD =
  'containers/Layout/ACTIVE_EDITOR_DOWNLOAD';
export const SET_SHOW_SEARCH_FORM_HOMEPAGE_SCROLL =
  'containers/Layout/SET_SHOW_SEARCH_FORM_HOMEPAGE_SCROLL';
export const SET_SHOW_FILTER_ON_HEADER =
  'containers/Layout/SET_SHOW_FILTER_ON_HEADER';
