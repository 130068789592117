import axios, { addInterceptors } from '../interceptor-axios';
import config from '~/config';

// TODO: Change to arrow functions and remove [api.data, api.data.someFn] in saga
export default class ApiPhoto {
  constructor(token) {
    const headers = {};
    if (token) {
      headers.Authorization = token;
    }
    this.axios = axios.create({
      baseURL: config.apiUrl.apiPhoto,
      headers,
    });
    addInterceptors(this.axios);
  }

  getRecommendedData = (params) => {
    const request = {
      method: 'GET',
      url: '/data/recommended',
      params: {
        pattern_id: params.pattern_id,
        lang: params.lang,
        per_page: params.per_page || 100,
        page: params.page || 1,
        download: params.download || 'desc',
        rand: params.filter?.rand || true,
        createdAt: params.createdAt,
        max_results: params.max_results || 100,
        free_only: params.free_only || false,
        clientIp: params.clientIp,
      },
    };
    return this.axios(request);
  };

  getPatternDetailById = (params) => {
    const request = {
      method: 'post',
      url: '/pattern/detail',
      params: {
        id: params.pattern_id,
        lang: params.lang,
        clientIp: params.clientIp,
        service_type: config.serviceType,
      },
    };
    return this.axios(request);
  };

  getSimilarData({ keyword, serviceTypeData }) {
    let serviceType = serviceTypeData.replaceAll('_ac', '');
    if (serviceType === 'design') {
      serviceType = 'template';
    }
    const request = {
      method: 'GET',
      // url: `${config.apiUrl.apiSimilar}/similarData`,
      // params: {
      //   lang: params.lang,
      //   page: params.page || 1,
      //   per_page: params.per_page || 100,
      //   max_results: params.per_page || 100,
      //   disp_similar: params.disp_similar || false,
      //   order_by: params.order_by || '',
      //   service_type_for_similar:
      //     params.service_type_for_similar || config.serviceType,
      //   data_id: params.data_id,
      //   similar_max_results: params.similar_max_results || 10,
      //   same_creator: params.same_creator,
      // },
      url: `${config.apiUrl.apiSimilar}/similar/${serviceType}`,
      params: { keyword },
    };
    return this.axios(request);
  }

  getDataOfCategory(params) {
    const request = {
      method: 'GET',
      url: '/category/data',
      params: {
        page: params.page || 1,
        slug: params.slug,
        lang: params.lang,
        ficonstype: params.ficonstype,
        download: params.download || 'desc',
        createdAt: params.createdAt,
        max_results: params.per_page || 100,
        per_page: params.per_page || 100,
        clientIp: params.clientIp,
      },
    };

    return this.axios(request);
  }

  nicePhoto(photoId) {
    const request = {
      method: 'POST',
      url: '/nice',
      data: {
        data_id: photoId,
      },
    };

    return this.axios(request);
  }

  getDetailData = (params) => {
    const request = {
      method: 'GET',
      url: `${config.apiUrl.apiDataDetail}/data/detail`,
      params: {
        data_id: params.id,
        lang: params.language,
        clientIp: params.clientIp,
      },
    };
    return this.axios(request);
  };

  getCategoriesFullData = (params) => {
    const request = {
      method: 'GET',
      url: '/categories/services',
      params: {
        lang: params.lang,
      },
    };
    return this.axios(request);
  };

  getCollectionsFullData = (params) => {
    const request = {
      method: 'GET',
      url: '/collections/name',
      params: {
        lang: params.lang,
        perpage: params.perpage,
        page: params.page,
      },
    };
    return this.axios(request);
  };

  getCollectionDetailById = (params) => {
    const request = {
      method: 'GET',
      url: '/collection/detail',
      params: {
        lang: params.lang,
        id: params.id,
      },
    };
    return this.axios(request);
  };

  getCollectionOfMonths = (params) => {
    const request = {
      method: 'GET',
      url: '/collection/months',
      params: {
        lang: params.lang,
      },
    };
    return this.axios(request);
  };
  getCollectionOfHomepage = (params) => {
    const request = {
      method: 'GET',
      url: '/collection/homepage',
      params: {
        lang: params.lang,
      },
    };
    return this.axios(request);
  };
  getDataExtraLicense = (params) => {
    const request = {
      method: 'GET',
      url: '/extralicense/data',
      params: {
        lang: params.lang,
      },
    };
    return this.axios(request);
  };
  getCreatorData = (params = {}) => {
    const request = {
      method: 'GET',
      url: '/creator/data/public',
      params: {
        creator_id: params.creator_id,
        page: params.page || 1,
        max_results: params.itemPerPage || 100,
        createdAt: params.data_type === 'new' ? 'desc' : undefined,
        download: params.data_type === 'popular' ? 'desc' : undefined,
        type: params.data_type,
        lang: params.lang,
        clientIp: params.clientIp,
      },
    };
    return this.axios(request);
  };
}
