import { createSelector } from 'reselect';

const selectPopular = state => state.popularPage.result;

const selectFilter = state => state.popularPage.filter;

const makeSelectDataType = () => createSelector(
  selectPopular,
  popularState => popularState.dataType,
);

const makeSelectPhotos = () => createSelector(
  selectPopular,
  popularState => popularState.photos,
);

const makeSelectLoading = () => createSelector(
  selectPopular,
  popularState => popularState.loading,
);

const makeSelectError = () => createSelector(
  selectPopular,
  popularState => popularState.error,
);

const makeSelectPage = () => createSelector(
  selectPopular,
  popularState => popularState.page,
);

const makeSelectTotalPage = () => createSelector(
  selectPopular,
  popularState => popularState.totalPage,
);

const makeSelectTotalItem = () => createSelector(
  selectPopular,
  popularState => popularState.totalItem,
);

const makeSelectPerPage = () => createSelector(
  selectFilter,
  filterState => filterState.perPage,
);

export {
  selectPopular,
  makeSelectPhotos,
  makeSelectError,
  makeSelectPage,
  makeSelectTotalPage,
  makeSelectTotalItem,
  makeSelectPerPage,
  makeSelectLoading,
  makeSelectDataType,
};
