export const CHANGE_KEYWORD = 'containers/Search/CHANGE_KEYWORD';
export const CHANGE_EXCLUDE_KEYWORD =
  'containers/Search/CHANGE_EXCLUDE_KEYWORD';
export const CHANGE_CATEGORY = 'containers/Search/CHANGE_CATEGORY';
export const CHANGE_EXCLUDE_CATEGORY =
  'containers/Search/CHANGE_EXCLUDE_CATEGORY';
export const CHANGE_CREATOR_NAME = 'containers/Search/CHANGE_CREATOR_NAME';
export const CHANGE_EXCLUDE_CREATOR_NAME =
  'containers/Search/CHANGE_EXCLUDE_CREATOR_NAME';
export const ON_SUBMIT = 'containers/Search/ON_SUBMIT';
export const LOAD_DATA_SEARCH = 'containers/Search/LOAD_DATA_SEARCH';
export const CHANGE_IMAGE_TYPE = 'containers/Search/CHANGE_IMAGE_TYPE';
export const CHANGE_IMAGE_ORDERBY = 'containers/Search/CHANGE_IMAGE_ORDERBY';
export const CHANGE_IMAGE_SHAPE = 'containers/Search/CHANGE_IMAGE_SHAPE';
export const CHANGE_IMAGE_ID = 'containers/Search/CHANGE_IMAGE_ID';
export const CHANGE_IMAGE_TITLE = 'containers/Search/CHANGE_IMAGE_TITLE';
export const CHANGE_COLOR = 'containers/Search/CHANGE_COLOR';
export const CLEAR_SEARCH = 'containers/Search/CLEAR_SEARCH';
export const SET_SEARCH_QUERY = 'containers/Search/SET_SEARCH_QUERY';
export const CHANGE_MODEL_COUNT = 'containers/Search/CHANGE_MODEL_COUNT';
export const CHANGE_MODEL_AGE = 'containers/Search/CHANGE_MODEL_AGE';
export const CHANGE_ORIENTATION = 'containers/Search/CHANGE_ORIENTATION';
export const CHANGE_MODEL_RELEASE = 'containers/Search/CHANGE_MODEL_RELEASE';
export const CHANGE_LICENSE = 'containers/Search/CHANGE_LICENSE';

export const CHANGE_OPEN_ORDER_BY = 'containers/Search/CHANGE_OPEN_ORDER_BY';
export const CHANGE_OPEN_IMAGE_TYPE =
  'containers/Search/CHANGE_OPEN_IMAGE_TYPE';
export const CHANGE_OPEN_ORIENTATION =
  'containers/Search/CHANGE_OPEN_ORIENTATION';
export const CHANGE_OPEN_KEYWORDS = 'containers/Search/CHANGE_OPEN_KEYWORDS';
export const CHANGE_OPEN_CATEGORY = 'containers/Search/CHANGE_OPEN_CATEGORY';
export const CHANGE_OPEN_COLOR = 'containers/Search/CHANGE_OPEN_COLOR';
export const CHANGE_OPEN_NUMBER_OF_PEOPLE =
  'containers/Search/CHANGE_OPEN_NUMBER_OF_PEOPLE';
export const CHANGE_OPEN_AGE = 'containers/Search/CHANGE_OPEN_AGE';
export const CHANGE_OPEN_MODEL_RELEASE =
  'containers/Search/CHANGE_OPEN_MODEL_RELEASE';
export const CHANGE_OPEN_CREATOR = 'containers/Search/CHANGE_OPEN_CREATOR';
export const CHANGE_OPEN_IMAGE_TITLE =
  'containers/Search/CHANGE_OPEN_IMAGE_TITLE';
export const CHANGE_OPEN_RELEASES = 'containers/Search/CHANGE_OPEN_RELEASES';
export const CHANGE_OPEN_LICENSE = 'containers/Search/CHANGE_OPEN_LICENSE';

export const CHANGE_TOGGLE_FILTER_SEARCH =
  'containers/Search/CHANGE_TOGGLE_FILTER_SEARCH';
export const CLOSE_ALL_FILTER = 'containers/Search/CLOSE_ALL_FILTER';
export const OPEN_ALL_FILTER = 'containers/Search/OPEN_ALL_FILTER';
export const CHANGE_OPEN_MORE = 'containers/Search/CHANGE_OPEN_MORE';
