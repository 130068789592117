import React from 'react';
// import CountdownTimer from '~/containers/CountdownTimer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from '~/containers/AuthProvider/selectors';
// import { openModal } from '~/containers/LoginModal/actions';
import {
  closeModal as closePaymentModal,
  openModal as openPaymentModal,
  selectedPremium,
} from '~/containers/PaymentModal/actions';
import withI18next from '~/../hocs/_withI18next';
import { redirectLogin } from '~/utils/redirect';
import Config from '~/config';

@withI18next()
class PremiumAds extends React.PureComponent {
  onRegisterPremium = (plan = 'ONE_YEAR') => {
    const {
      isAuthenticated,
      // openLoginModal,
      openPaymentModal,
      selectedPremium,
    } = this.props;
    if (!isAuthenticated) {
      // openLoginModal();
      redirectLogin(this.props.lng);
    } else {
      selectedPremium(plan);
      openPaymentModal();
    }
  };

  render() {
    const { t, lng } = this.props;
    return (
      <div className="premium-ads">
        <div className="content-ads">
          <img
            src={`${Config.staticPrefix}/static/assets/image/premium/ads/0-3.png`}
            alt="logo"
          />
          <h3>{t('cancel_page2_coupon_title1')}</h3>
          <p>{t('credit_premium_header_title')}</p>
          <div className="logo-groups">
            <img
              src={`${Config.staticPrefix}/static/assets/image/premium/ads/0-4.png`}
              alt="logo"
              className="ill"
            />
            <img
              src={`${Config.staticPrefix}/static/assets/image/premium/ads/0-5.png`}
              alt="logo"
              className="pho"
            />
            <img
              src={`${Config.staticPrefix}/static/assets/image/premium/ads/0-6.png`}
              alt="logo"
              className="sil"
            />
            <img
              src={`${Config.staticPrefix}/static/assets/image/premium/ads/0-7.png`}
              alt="logo"
              className="des"
            />
          </div>
          <hr />
          <p
            className="colo"
            dangerouslySetInnerHTML={{ __html: t('yearly_sale_off_campaign') }}
          />
          {/* <div className="premium-ads-countdown">
            <CountdownTimer hasText={false} />
          </div> */}
          <a
            className="btn btn-ac-primary btn-subcribe"
            href={`${Config.getSiteURL()}/premium`}
          >
            {t('subcribe_now')}
          </a>
        </div>
        <style jsx>
          {`
            .premium-ads {
              font-size: 20px;
              width: 100%;
              height: 400px;
              background-image: url('/static/assets/image/premium/ads/0-1.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              border-radius: 2px;
              margin: 4px;
              position: relative;
              display: flex;
              align-items: center;
            }
            .premium-ads .content-ads {
              position: absolute;
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              text-align: center;
            }
            .premium-ads .content-ads > img:first-child {
              width: 50px;
            }
            .premium-ads .content-ads h3 {
              color: #fff;
              margin-top: 7px;
              margin-bottom: 7px;
              text-transform: uppercase;
              letter-spacing: 3px;
              font-size: 36px;
            }
            .premium-ads .content-ads > p {
              text-transform: uppercase;
              color: #fff;
              margin: 5px 0;
              font-size: 16px;
            }
            .premium-ads .content-ads .logo-groups img {
              vertical-align: middle;
            }
            .premium-ads .content-ads .logo-groups img.ill {
              height: 25px;
            }
            .premium-ads .content-ads .logo-groups img.pho {
              height: 20px;
            }
            .premium-ads .content-ads .logo-groups img.sil {
              height: 20px;
            }
            .premium-ads .content-ads .logo-groups img.des {
              height: 20px;
              margin-top: 3px;
            }
            .premium-ads .content-ads .logo-groups img + img {
              padding-left: 10px;
            }
            .premium-ads .content-ads hr {
              background-color: #45518a;
              color: #45518a;
              width: 380px;
              margin: 10px auto;
              height: 1px;
              border: none;
            }
            .premium-ads .content-ads p.colo {
              color: #ffcc00;
              margin: 0 auto;
              text-transform: inherit;
            }
            .premium-ads .content-ads .premium-ads-countdown {
              height: 42px;
              margin-bottom: 5px;
            }
            .premium-ads .content-ads .countdown-timer {
              color: #fff;
              font-size: 20px;
              font-weight: 300;
              margin-top: 0;
            }
            .premium-ads .content-ads .countdown-timer .time-number {
              font-weight: 500;
            }
            .premium-ads .content-ads .btn-subcribe {
              background-color: #ffcc00;
              color: #243376;
              font-weight: bold;
              margin-top: 15px;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
  profile: makeSelectProfile(),
});

const mapDispatchToProps = (dispatch) => ({
  // openLoginModal: () => dispatch(openModal()),
  openPaymentModal: () => dispatch(openPaymentModal()),
  closePaymentModal: () => dispatch(closePaymentModal()),
  selectedPremium: (plan) => dispatch(selectedPremium(plan)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PremiumAds);
