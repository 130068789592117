import Sockette from 'sockette';
import Config from '~/config';

export default class SocketService {
  constructor(options) {
    this.isConnected = false;
    this.ws = new Sockette(Config.apiUrl.apiSocket, {
      timeout: 5e3,
      maxAttempts: 1,
      onopen: (e) => {
        this.isConnected = true;
        console.log('connected:', e);
        options.onopen && options.onopen(e);
      },
      onmessage: (e) => {
        console.log('Received:', e);
        options.onmessage && options.onmessage(e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: (e) => {
        this.isConnected = false;
        console.log('Closed!', e);
      },
      onerror: (e) => {
        this.isConnected = false;
        console.log('Error:', e);
      },
    });
  }

  send(body) {
    return this.ws.json(body);
  }

  saveSession(body) {
    return this.send({ ...body, action: 'saveSession' });
  }
}
