import { createSelector } from 'reselect';
import { makeSelectCurrentLang } from '../MetadataProvider/selectors';
import { locales } from '~/utils';
import Config from '~/config';

const selectDetailPhoto = (state) => state.photo.detail;

const selectDownloadPhoto = (state) => state.photo.download;

const selectGotIt = (state) => state.photo.gotIt;

const selectStatitics = (state) => state.photo.statitics;

const selectSimilar = (state) => state.photo.similar;

const selectSimilarDesign = (state) => state.photo.similarDesign;

const makeSelectCategoryDesign = () =>
  createSelector(
    selectSimilarDesign,
    (stateSimilarDesign) => stateSimilarDesign.designSimilar,
  );

const makeSelectModalAttribute = () =>
  createSelector(selectDetailPhoto, (statePhoto) => statePhoto.modal);

const makeSelectCategorySimilar = () =>
  createSelector(
    selectSimilarDesign,
    (stateSimilarDesign) => stateSimilarDesign.category,
  );

const makeSelectStatistics = () =>
  createSelector(selectStatitics, (statisticsState) => statisticsState);

const makeSelectGotIt = () =>
  createSelector(selectGotIt, (gotItState) => gotItState);

const makeSelectSilhouetteSimilar = () =>
  createSelector(
    selectSimilar,
    (similarDetailState) => similarDetailState.silhouetteSimilar,
  );

const makeSelectPhotoSimilar = () =>
  createSelector(
    selectSimilar,
    (similarDetailState) => similarDetailState.photoSimilar,
  );

const makeSelectIllustSimilar = () =>
  createSelector(
    selectSimilar,
    (similarDetailState) => similarDetailState.illustSimilar,
  );

const makeSelectDesignSimilar = () =>
  createSelector(
    selectSimilar,
    (similarDetailState) => similarDetailState.designSimilar,
  );

const makeSelectSameCreator = () =>
  createSelector(
    selectSimilar,
    (similarDetailState) => similarDetailState.sameCreator,
  );

const makeSelectCollection = () =>
  createSelector(
    selectSimilar,
    (similarDetailState) => similarDetailState.collection,
  );

const makeSelectPhotoTags = () =>
  createSelector(
    selectDetailPhoto,
    makeSelectCurrentLang(),
    (photoDetailState, currentLang) => {
      if (!photoDetailState.tags) return [];
      const tags = photoDetailState.tags.slice(0);
      return tags.map((item) => ({
        name: item[`name_${locales.convertToLongType(currentLang)}`],
        id: item.id,
      }));
    },
  );

const makeSelectPhotoCategories = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.categories,
  );

const makeSelectPhotoTitle = () =>
  createSelector(
    selectDetailPhoto,
    makeSelectCurrentLang(),
    (photoDetailState, currentLang) =>
      Config.getTitleByLanguage(photoDetailState.title, currentLang),
  );

const makeSelectPhotoAssets = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.assets,
  );

const makeSelectPhotoPath = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.path,
  );

const makeSelectPhotoBase64 = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.base64,
  );

const makeSelectPhotoThumbnail = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.thumbnail,
  );

const makeSelectPhoto = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.photo,
  );

const makeSelectPhotoNice = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.nice,
  );

const makeSelectPhotoPreview = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.photoPreview,
  );

const makeSelectNeighbor = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.neighbor,
  );

const makeSelectCreator = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.creator,
  );

const makeSelectId = () =>
  createSelector(selectDetailPhoto, (photoDetailState) => photoDetailState.id);

const makeSelectDataType = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.dataType,
  );

const makeSelectPowerPoint = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.powerPoint,
  );

const makeSelectAssetSelected = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.assetSelected,
  );

const makeSelectDownloadPhotoLoading = () =>
  createSelector(
    selectDownloadPhoto,
    (photoDownloadState) => photoDownloadState.loading,
  );

const makeSelectDownloadPhotoStatus = () =>
  createSelector(
    selectDownloadPhoto,
    (photoDownloadState) => photoDownloadState.currentDownloadStatus,
  );

const makeSelectDownloadingPhoto = () =>
  createSelector(
    selectDownloadPhoto,
    (photoDownloadState) => photoDownloadState.downloading,
  );

const makeSelectOpeningEditor = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.openingEditor,
  );

const makeSelectCardInfo = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.cardInfo,
  );

const makeSelectBookmarkData = () =>
  createSelector(
    selectDetailPhoto,
    (photoDetailState) => photoDetailState.isBookmark,
  );

export {
  makeSelectCardInfo,
  selectDetailPhoto,
  selectSimilar,
  makeSelectPhotoTags,
  makeSelectPhotoCategories,
  makeSelectPhotoTitle,
  makeSelectPhotoNice,
  makeSelectPhotoAssets,
  makeSelectPhotoPreview,
  makeSelectCreator,
  makeSelectId,
  makeSelectAssetSelected,
  makeSelectDownloadPhotoLoading,
  makeSelectGotIt,
  makeSelectStatistics,
  makeSelectPhotoPath,
  makeSelectPhotoThumbnail,
  makeSelectSilhouetteSimilar,
  makeSelectPhotoSimilar,
  makeSelectCollection,
  makeSelectIllustSimilar,
  makeSelectDesignSimilar,
  makeSelectSameCreator,
  makeSelectCategoryDesign,
  makeSelectCategorySimilar,
  makeSelectDownloadingPhoto,
  makeSelectDataType,
  makeSelectPowerPoint,
  makeSelectPhoto,
  makeSelectNeighbor,
  makeSelectModalAttribute,
  makeSelectDownloadPhotoStatus,
  makeSelectOpeningEditor,
  makeSelectPhotoBase64,
  makeSelectBookmarkData,
};
