import Config from '~/config';

const data = {
  getData(lng) {
    let dataBlog;
    if (lng === 'tw') {
      if (Config.isPhotoAC()) {
        dataBlog = [
          {
            link: 'https://tw.blog.acworks.co.jp/2021/09/03/how-to-submit/',
            src: `${Config.staticPrefix}/static/assets/blog-post/photo_ac/photo_ac_blog_01_tw.png`,
            title: '如何在圖庫網站photoAC / illustAC中投稿並創收？',
            summary: '大家好久不見！ […]',
          },
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/07/15/photo_creator_function_release/',
            src: `${Config.staticPrefix}/static/assets/blog-post/photo_ac/photo_ac_blog_02_tw.png`,
            title: '關於部分開放“創作者”投稿功能的通知',
            summary:
              '即日起，ACworks將開放照片圖庫網站photoAC中的投稿功能。 […]',
          },
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/07/08/%e5%a4%8f%e6%97%a5%e9%81%bf%e6%9a%91%e5%bf%85%e5%82%99-10%e4%bb%bd%e6%9c%89%e9%97%9c%e5%88%a8%e5%86%b0%e7%9a%84%e7%85%a7%e7%89%87/',
            src: `${Config.staticPrefix}/static/assets/blog-post/photo_ac/photo_ac_blog_03_tw.png`,
            title: '夏日避暑必備––10份有關“刨冰”的照片',
            summary:
              '為了對抗逐漸悶熱起來的夏天，小編特意給大家準備了10份有關日本傳統食物——“刨冰”的照片，希望大家能看得開心！',
          },
        ];
      }
      if (Config.isIllustAC()) {
        dataBlog = [
          {
            link: 'https://tw.blog.acworks.co.jp/2021/09/03/how-to-submit/',
            src: `${Config.staticPrefix}/static/assets/blog-post/illust_ac/illust_ac_blog_01_tw.png`,
            title: '如何在圖庫網站photoAC / illustAC中投稿並創收？',
            summary: '大家好久不見！ […]',
          },
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/07/30/illustac_submission_released/',
            src: `${Config.staticPrefix}/static/assets/blog-post/illust_ac/illust_ac_blog_02_tw.png`,
            title: 'ACworks開放向量圖庫illustAC中的投稿功能。',
            summary:
              'ACworks是一家主在日本提供免費且高質量素材下載服務的圖庫公司。得益於日本使用者的厚愛，當前illustAC每日都有多達14萬名的訪問者來訪。 […]',
          },
          {
            link: 'https://tw.blog.acworks.co.jp/2021/07/30/tokyo_olympic/',
            src: `${Config.staticPrefix}/static/assets/blog-post/illust_ac/illust_ac_blog_03_tw.png`,
            title: '東京奧林匹克運動會！——10份包含奧運元素插圖',
            summary:
              '正值奧林匹克運動會期間，這次就為大家帶來10份相關的插圖素材～ […]',
          },
        ];
      }
      if (Config.isSilhouetteAC()) {
        dataBlog = [
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/03/31/acworks%e5%9c%96%e5%ba%ab%e7%b6%b2%e7%ab%99%e4%bd%bf%e7%94%a8%e6%8c%87%e5%8d%97%ef%bc%88%e4%b8%80%ef%bc%89/',
            src: `${Config.staticPrefix}/static/assets/blog-post/silhouette_ac/silhouette_ac_blog_01_tw.png`,
            title: 'ACworks圖庫網站使用指南',
            summary: '5項技巧幫您提高搜圖效率，豐富創作手段 […]',
          },
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/02/16/%e8%aa%b0%e8%aa%aa%e5%89%aa%e5%bd%b1%e5%b0%b1%e4%b8%8d%e8%83%bd%e6%93%81%e6%9c%89%e7%b4%b0%e7%af%80%ef%bc%9f%e5%85%8d%e8%b2%bb%e7%9a%84%e5%89%aa%e5%bd%b1%e7%b4%a0%e6%9d%90%e6%8e%a8%e8%96%a6/',
            src: `${Config.staticPrefix}/static/assets/blog-post/silhouette_ac/silhouette_ac_blog_02_tw.png`,
            title: '誰說剪影不能擁有細節？——免費剪影素材推薦',
            summary:
              '說到剪影，相信大家最先想到的特徵便是極致的簡潔與抽象。的確，如果我們希望獲取精緻且具體的圖片內容的話，我們會直接去尋找照片或者插畫。 […]',
          },
        ];
      }
      if (Config.isDesignAC()) {
        dataBlog = [
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/05/24/5%e6%ad%a5%e6%91%b8%e6%b8%85%e5%85%8d%e8%b2%bb%e7%b7%a8%e8%bc%af%e5%99%a8editorac%e7%9a%84%e7%94%a8%e6%b3%95/',
            src: `${Config.staticPrefix}/static/assets/blog-post/design_ac/design_ac_blog_01_tw.png`,
            title: '5步摸清免費編輯器designAC的用法',
            summary:
              '本次為您帶來的是關於圖片編輯器designAC中“裁剪”工具的使用方法說明。 […]',
          },
          {
            link:
              'https://tw.blog.acworks.co.jp/2021/01/22/how-to-create-an-infographic-with-editorac-no-design-skills-needed/',
            src: `${Config.staticPrefix}/static/assets/blog-post/design_ac/design_ac_blog_02_tw.png`,
            title: '設計小白也能製作美觀的資訊圖表——designAC的使用方法介紹',
            summary:
              '資訊圖表是能夠將我們的創意以一種能夠吸引讀者的形式呈現的奇妙工具。在圖表的幫助下，不僅讀者能快速地找到他們尋找的內容，內容製作者也能夠推進內容的傳播、協助搜尋引擎最佳化(search […]',
          },
        ];
      }
    } else {
      if (Config.isPhotoAC()) {
        dataBlog = [
          {
            link: 'https://www.photo-ac.com/blog/free-valentine-images/',
            src: `${Config.staticPrefix}/static/assets/blog-post/photo_ac/photo_ac_blog_01_en.jpeg`,
            title:
              'Free Valentine Images to Download: +9 sites to find the best Valentine’s photos',
            summary:
              'Valentine’s day is when lovers express their feelings with wishes and gifts. Because of their similarities, it is thought to have originated with the Roman feast of Lupercalia, which took place in…',
          },
          {
            link:
              'https://www.photo-ac.com/blog/background-design-trends-2022/',
            src: `${Config.staticPrefix}/static/assets/blog-post/photo_ac/photo_ac_blog_02_en.jpeg`,
            title: '2022 Background Design Trends & Styles: 18 Inspiring Ideas',
            summary:
              'You can find inspiring ideas of background design trends 2022, from up-to-date 3D to minimalism one in this article.',
          },
          {
            link: 'https://www.photo-ac.com/blog/holiday-color-palettes/',
            src: `${Config.staticPrefix}/static/assets/blog-post/photo_ac/photo_ac_blog_03_en.jpeg`,
            title: '13 Inspiring Holiday Color Palettes 2022',
            summary:
              'The holiday seasons are one of the best times of the year. Check 13 recommended holiday color palettes from photoAC.',
          },
        ];
      }
      if (Config.isIllustAC()) {
        dataBlog = [
          {
            link: 'https://www.ac-illust.com/blog/cute-valentines-clipart/',
            src: `${Config.staticPrefix}/static/assets/blog-post/illust_ac/illust_ac_blog_01_en.jpeg`,
            title:
              'Royalty Free Cute Valentine’s Clipart for Download: 25 Unique Ideas for Couples',
            summary:
              'We introduce free and cute Valentine’s clipart, vectors, and illustrations, and many ideas couples can do with and for each other on Valentine’s day.',
          },
          {
            link: 'https://www.ac-illust.com/blog/lunar-new-year-clipart/',
            src: `${Config.staticPrefix}/static/assets/blog-post/illust_ac/illust_ac_blog_02_en.jpeg`,
            title:
              'Lunar New Year 2022: Beautiful illustrations for different countries',
            summary:
              'Lunar New Year is around the corner. Do you know it is celebrated in different countries? In this article, we introduce about lunar new year traditions and illustrations and clipart that can be downloaded for free.',
          },
          {
            link: 'https://www.ac-illust.com/blog/year-of-the-tiger-vectors/',
            src: `${Config.staticPrefix}/static/assets/blog-post/illust_ac/illust_ac_blog_03_en.jpeg`,
            title:
              '2022 – Year of the Tiger: Royalty-Free Tiger Vectors for Awesome Design',
            summary:
              'Lunar New Year is just around the corner. In this article, we share fun facts and beliefs about the year of the tiger and cute tiger vectors for download.',
          },
        ];
      }
      if (Config.isSilhouetteAC()) {
        dataBlog = [
          {
            link:
              'https://en.blog.acworks.co.jp/10-free-business-silhouette-vectors-for-your-next-designs/',
            src: `${Config.staticPrefix}/static/assets/blog-post/silhouette_ac/silhouette_ac_blog_01_en.png`,
            title: '10 free business silhouette vectors for your next designs',
            summary:
              'In this article, we would like to introduce 10 business silhouette vector arts from silhouetteAC that might help in your…',
          },
          {
            link:
              'https://en.blog.acworks.co.jp/silhouette-vectors-for-diy-t-shirt-printing-silhouetteac/',
            src: `${Config.staticPrefix}/static/assets/blog-post/silhouette_ac/silhouette_ac_blog_02_en.png`,
            title: 'Silhouette vectors for DIY T-shirt printing – silhouetteAC',
            summary:
              'With the help of a silhouette cutter or special heat transfer paper, you can now print a T-shirt yourself at…',
          },
          {
            link:
              'https://en.blog.acworks.co.jp/icons-for-your-e-learning-and-e-commerce-projects-free-silhouettes/',
            src: `${Config.staticPrefix}/static/assets/blog-post/silhouette_ac/silhouette_ac_blog_03_en.png`,
            title:
              'Icons for your e-learning and e-commerce projects: Free silhouettes',
            summary:
              'Online activities involving e-learning and e-commerce are getting more and more popular these days thanks to the development of Internet…',
          },
        ];
      }
      if (Config.isDesignAC()) {
        dataBlog = [
          {
            link:
              'https://en.blog.acworks.co.jp/9-tools-to-help-you-create-a-stunning-presentation-online/',
            src: `${Config.staticPrefix}/static/assets/blog-post/design_ac/design_ac_blog_01_en.png`,
            title: '9 tools to help you create a stunning presentation online',
            summary:
              'Whether you’re just starting out or are a veteran of many presentations, here are some of the easiest ways to create a stunning presentation online.',
          },
          {
            link:
              'https://en.blog.acworks.co.jp/create-online-calendars-for-free-with-editorac-in-minutes/',
            src: `${Config.staticPrefix}/static/assets/blog-post/design_ac/design_ac_blog_02_en.png`,

            title: 'Create online calendars for free with designAC in minutes',
            summary:
              'No matter what kind of calendars you want to create, various beautiful and customizable templates are just a click away.',
          },
          {
            link:
              'https://en.blog.acworks.co.jp/how-to-change-zoom-backgrounds-when-working-online/',
            src: `${Config.staticPrefix}/static/assets/blog-post/design_ac/design_ac_blog_03_en.png`,
            title: 'How to change Zoom backgrounds when working online',
            summary:
              'We share steps to change Zoom backgrounds and 10 Zoom backgrounds that you can download totally for free on designAC.',
          },
        ];
      }
    }
    return dataBlog;
  },
  getLinkViewAll(lng) {
    let link_view_all;

    if (lng === 'tw') {
      if (Config.isPhotoAC()) {
        link_view_all = 'https://tw.blog.acworks.co.jp/category/photoac/';
      }
      if (Config.isIllustAC()) {
        link_view_all = 'https://tw.blog.acworks.co.jp/category/illustac/';
      }
      if (Config.isSilhouetteAC()) {
        link_view_all = 'https://tw.blog.acworks.co.jp/category/silhouetteac/';
      }
      if (Config.isDesignAC()) {
        link_view_all = 'https://tw.blog.acworks.co.jp/category/editorac/';
      }
    } else {
      if (Config.isPhotoAC()) {
        link_view_all = 'https://en.blog.acworks.co.jp/category/photoac/';
      }

      if (Config.isIllustAC()) {
        link_view_all = 'https://en.blog.acworks.co.jp/category/illustac/';
      }
      if (Config.isSilhouetteAC()) {
        link_view_all = 'https://en.blog.acworks.co.jp/category/silhouetteac/';
      }
      if (Config.isDesignAC()) {
        link_view_all = 'https://en.blog.acworks.co.jp/category/editorac/';
      }
    }
    return link_view_all;
  },
};
export default data;
