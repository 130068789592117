import {
  LOAD_SLIDER_IMAGES_SUCCESS,
  LOAD_TAGS_SUCCESS,
  LOAD_PATTERN_SUCCESS,
  LOAD_RECOMMEND_LIST_SUCCESS,
  CHANGE_PATTERN,
  LOAD_COUNT_DOWNLOAD_RUN_SUCCESS,
  LOAD_COUNT_DATA_SUCCESS,
  LOAD_COUNT_USER_SUCCESS,
  LOAD_DATA_TYPE,
  LOAD_DATA_TYPE_SUCCESS,
  SET_IS_TOP_BANNER_VISIBLE,
  LOAD_COLLECTIONS_OF_HOMEPAGE,
  LOAD_COLLECTIONS_OF_HOMEPAGE_SUCCESS,
  LOAD_COLLECTIONS_OF_HOMEPAGE_ERROR,
  LOAD_DATA_BLOGS_SUCCESS,
  LOAD_DATA_BLOGS_ERROR,
} from './constants';
import {
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
} from '~/components/Gallery/constants';
import LocalStorage from '~/services/LocalStorage';

export const initialState = {
  sliderImages: [],
  patterns: [],
  recommendList: [],
  dataType: [],
  tags: [],
  patternSelected: false,
  countDownload: 0,
  totalData: {},
  totalUser: {},
  loading: false,
  eventType: 'recommend',
  isTopBannerVisible: true,
  collections: [],
  blogs: [],
};

function homeReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SLIDER_IMAGES_SUCCESS:
      return { ...state, sliderImages: action.collection };

    case LOAD_DATA_TYPE:
      return { ...state, loading: true };

    case LOAD_DATA_TYPE_SUCCESS:
      return {
        ...state,
        dataType: action.collection,
        eventType: action.eventType,
        loading: false,
      };

    case LOAD_PATTERN_SUCCESS:
      return { ...state, patterns: action.patterns };

    case LOAD_RECOMMEND_LIST_SUCCESS:
      return {
        ...state,
        recommendList: action.collection,
        dataTypePattern: action.dataType,
        loading: false,
        eventType: 'recommend',
      };

    case LOAD_TAGS_SUCCESS:
      return { ...state, tags: action.tags || [] };

    case CHANGE_PATTERN:
      return {
        ...state,
        patternSelected: action.values.id || false,
        loading: true,
      };

    case LOAD_COUNT_DOWNLOAD_RUN_SUCCESS:
      return { ...state, countDownload: action.downloads };

    case LOAD_COUNT_DATA_SUCCESS:
      return { ...state, totalData: action.totalData };

    case LOAD_COUNT_USER_SUCCESS:
      return { ...state, totalUser: action.totalUser };

    case NICE_PHOTO_SUCCESS:
      return {
        ...state,
        recommendList: state.recommendList.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
        dataType: state.dataType.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
      };
    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        recommendList: state.recommendList.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          }
          return item;
        }),
      };

    case SET_IS_TOP_BANNER_VISIBLE:
      return {
        ...state,
        isTopBannerVisible: action.payload,
      };
    case LOAD_COLLECTIONS_OF_HOMEPAGE_SUCCESS: {
      return {
        ...state,
        collections: action.collections,
      };
    }

    case LOAD_COLLECTIONS_OF_HOMEPAGE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case LOAD_DATA_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.blogs,
      };
    case LOAD_DATA_BLOGS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export default homeReducer;
