import { createSelector } from 'reselect';

const selectSearchResult = (state) => state.searchResult.search;

const selectFilter = (state) => state.searchResult.filter;

const makeSelectPhotos = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.photos,
  );

const makeSelectSuggestion = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.suggest,
  );

const makeSelectError = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.error,
  );

const makeSelectPage = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.page,
  );

const makeSelectTotalPage = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.totalPage,
  );

const makeSelectQuery = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.query,
  );

const makeSelectTotalItem = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.totalItem,
  );

const makeSelectOrderBy = () =>
  createSelector(selectFilter, (filterState) => filterState.orderBy);

const makeSelectPerPage = () =>
  createSelector(selectFilter, (filterState) => filterState.perPage);

const makeSelectHasNext = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.has_next,
  );

const makeSelectHasPersonalize = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.hasPersonalize,
  );

const makeSelectLoading = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.loading,
  );

const makeSelectLoadingOtherServices = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.loadingOtherServices,
  );

const makeSelectOtherServices = () =>
  createSelector(
    selectSearchResult,
    (searchResultState) => searchResultState.otherServices,
  );

export {
  selectSearchResult,
  makeSelectPhotos,
  makeSelectError,
  makeSelectPage,
  makeSelectTotalPage,
  makeSelectTotalItem,
  makeSelectOrderBy,
  makeSelectPerPage,
  makeSelectLoading,
  makeSelectQuery,
  makeSelectSuggestion,
  makeSelectHasNext,
  makeSelectHasPersonalize,
  makeSelectLoadingOtherServices,
  makeSelectOtherServices,
};
