import { LOADING_SHOW, LOADING_HIDE } from './constants';

export function loadingShow(params) {
  return {
    type: LOADING_SHOW,
    ...params,
  };
}

export function loadingHide() {
  return {
    type: LOADING_HIDE,
  };
}
