import { combineReducers } from 'redux';
import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_ERROR,
  FILTER_PER_PAGE,
  FILTER_ORDER_BY,
  CHANGE_PAGE,
} from './constants';

export const initialState = {
  categories: [],
  error: false,
  loading: false,
};

function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CATEGORIES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.categories,
      };

    case LOAD_CATEGORIES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}

export default categoriesReducer;
