import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_DATA_EXTRA_LICENSE,
  LOAD_DATA_EXTRA_LICENSE_SUCCESS,
  LOAD_DATA_EXTRA_LICENSE_ERROR,
} from './constants';
import config from '~/config';
import * as extraLicensePageService from '~/services/extra-license';

function* loadDataExtraLicense(action) {
  const { payload, api } = action;
  const apiFunction = api
    ? api.photo.getDataExtraLicense
    : extraLicensePageService.getDataExtraLicense;
  try {
    let dataExtraLicense = yield call(apiFunction, payload);
    yield put({
      type: LOAD_DATA_EXTRA_LICENSE_SUCCESS,
      dataExtraLicense,
    });
  } catch (error) {
    yield put({ type: LOAD_DATA_EXTRA_LICENSE_ERROR, error: true });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* extraLicenseSaga() {
  yield takeLatest(LOAD_DATA_EXTRA_LICENSE, loadDataExtraLicense);
}
