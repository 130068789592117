const DEFAULT_VALUE = JSON.stringify(null);

let localStorage = {
  getItem: (key) => localStorage[key],
  setItem: (key, value) => (localStorage[key] = value),
};
if (process.browser) {
  localStorage = window.localStorage;
}

class LocalStorage {
  getItem = (key) => {
    const value = localStorage.getItem(key);
    return JSON.parse(value || DEFAULT_VALUE);
  };

  setItem = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
  };

  get photos() {
    return this.getItem('photos') || [];
  }

  set photos(value) {
    this.setItem('photos', value);
  }

  get selectedPatternId() {
    return this.getItem('selectedPatternId') || null;
  }

  set selectedPatternId(value) {
    this.setItem('selectedPatternId', value);
  }

  get otherServices() {
    return this.getItem('otherServices') || null;
  }

  set otherServices(value) {
    this.setItem('otherServices', value);
  }

  get popupBannerDate() {
    return this.getItem('popupBannerDate') || null;
  }

  set popupBannerDate(value) {
    this.setItem('popupBannerDate', value);
  }

  get hideFilter() {
    return this.getItem('hideFilter') || null;
  }

  set hideFilter(value) {
    this.setItem('hideFilter', value);
  }

  set isSurvey(value) {
    this.setItem('isSurvey', value);
  }
  get isSurvey() {
    return this.getItem('isSurvey') || null;
  }
  set countSearch(value) {
    this.setItem('countSearch', value);
  }
  get countSearch() {
    return this.getItem('countSearch') || null;
  }
  set countDownload(value) {
    this.setItem('countDownload', value);
  }
  get countDownload() {
    return this.getItem('countDownload') || null;
  }
  set isActiveEditorDownload(value) {
    this.setItem('isActiveEditorDownload', value);
  }
  get isActiveEditorDownload() {
    return this.getItem('isActiveEditorDownload') || null;
  }
  set hideJapanNotice(value) {
    this.setItem('hideJapanNotice', value);
  }
  get hideJapanNotice() {
    return this.getItem('hideJapanNotice') || false;
  }
  set hideJapanNoticePremium(value) {
    this.setItem('hideJapanNoticePremium', value);
  }
  get hideJapanNoticePremium() {
    return this.getItem('hideJapanNoticePremium') || false;
  }
}
export default new LocalStorage();
