import React from 'react';
import Image from 'next/image';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCountDownload } from '~/containers/HomePage/selectors';
import UserTotal from '~/containers/UserTotal';
import Config from '~/config';
import withI18next from '~/../hocs/_withI18next';
import { formatNumberWithCommas } from '~/utils/formatter';

const mapStateToProps = createStructuredSelector({
  countDownload: makeSelectCountDownload(),
});
@connect(mapStateToProps)
@withI18next()
class SectionTotalDownload extends React.Component {
  render() {
    const { t, countDownload } = this.props;
    let urlImage;
    if (Config.serviceType === 'design_ac') {
      urlImage = 'total-download.png';
    } else if (Config.serviceType === 'photo_ac') {
      urlImage = 'photo_ac_background_half.webp';
    } else if (Config.serviceType === 'illust_ac') {
      urlImage = 'illust_ac_downloader.jpg';
    } else {
      urlImage = Config.getPrefixContent('bg_downloader.jpg');
    }
    const total = countDownload || 202979331;

    return (
      <section className="section-total-download">
        <Image
          src={`${Config.staticPrefix}/static/images/banner/${urlImage}`}
          className="img-background"
          alt="image background"
          layout="fill"
          priority
        />
        <div className="country-user-infor">
          <UserTotal isHomePage />
        </div>
        <div className="total-download-title">
          {t('total_downloaded_graphics')}
        </div>
        <div className="total-download-number">
          {formatNumberWithCommas(total)}
        </div>
        <style jsx>
          {`
            .section-total-download {
              position: relative;
              color: #fff;
              height: 350px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              overflow: hidden;
              z-index: 10;
            }
            .section-total-download .total-download-title {
              padding: 10px;
              font-size: 24px;
              font-weight: 500;
              ${Config.isIllustAC()
                ? `
                position: relative;
                margin-top: 30px;
              `
                : ''}
            }
            .section-total-download .total-download-number {
              padding: 7px 25px;
              font-size: 40px;
              display: inline-block;
              border: 2px solid #fff;
              border-radius: 2px;
            }
            .section-total-download .bg-blur {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                0deg,
                transparent 0,
                rgba(0, 0, 0, 0.49)
              );
            }

            .country-user-infor {
              font-size: 24px;
              text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
              z-index: 1;
              ${Config.isIllustAC()
                ? `margin-bottom: 50px;
                  position: absolute;
                  top: 20px;
                `
                : 'margin-bottom: 50px;'}
            }
            @media (max-width: 767px) {
              .total-download-title {
                text-align: center;
              }

              .country-user-infor {
                margin-bottom: 30px;
              }

              .country-user-infor,
              .section-total-download .total-download-title {
                font-size: 20px;
              }

              .section-total-download .total-download-number {
                font-size: 24px;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .section-total-download .img-background {
              position: absolute;
              min-width: 100%;
              min-height: 100%;
              z-index: -1;
              object-fit: cover;
            }
          `}
        </style>
      </section>
    );
  }
}

export default SectionTotalDownload;
