import Config from '~/config';

const data = {
  getImage(lng) {
    if (Config.isPhotoAC()) {
      if (lng === 'tw') {
        return [
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_1.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_2.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_3.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_4.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_5.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_6.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_7.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_8.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_9.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/tw/july_10.jpg`,
        ];
      }
      if (lng === 'ko') {
        return [
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_1.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_2.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_3.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_4.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_6.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_7.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_8.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_9.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/photoAC/ko/july_10.jpg`,
        ];
      }
      return [
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_1.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_2.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_3.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_4.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_5.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_6.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_7.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_8.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_9.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_10.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_11.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/photoAC/en/july_12.jpg`,
      ];
    }
    if (Config.isIllustAC()) {
      if (lng === 'tw') {
        return [
          `${Config.staticPrefix}/static/images/slideshow/illustAC/tw/july_1.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/illustAC/tw/july_2.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/illustAC/tw/july_3.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/illustAC/tw/july_4.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/illustAC/tw/july_5.jpg`,
          // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_7.jpg`,
        ];
      }
      if (lng === 'ko') {
        return [
          // `${Config.staticPrefix}/static/images/slideshow/illustAC/ko/july_1.jpg`,
          // `${Config.staticPrefix}/static/images/slideshow/illustAC/ko/july_2.jpg`,
          // `${Config.staticPrefix}/static/images/slideshow/illustAC/ko/july_3.jpg`,
          // `${Config.staticPrefix}/static/images/slideshow/illustAC/ko/july_4.jpg`,
          // `${Config.staticPrefix}/static/images/slideshow/illustAC/ko/july_5.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_7.jpg`,
        ];
      }
      return [
        // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_1.jpg`,
        // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_2.jpg`,
        // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_3.jpg`,
        // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_4.jpg`,
        // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_5.jpg`,
        // `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_6.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/illustAC/en/july_7.jpg`,
      ];
    }
    if (Config.isSilhouetteAC()) {
      if (lng === 'tw') {
        return [
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/tw/july_1.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/tw/july_2.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/tw/july_3.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/tw/july_4.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/tw/july_5.jpg`,
        ];
      }
      if (lng === 'ko') {
        return [
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/ko/july_1.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/ko/july_2.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/ko/july_3.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/ko/july_4.jpg`,
          `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/ko/july_5.jpg`,
        ];
      }
      return [
        `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/en/july_1.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/en/july_2.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/en/july_3.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/en/july_4.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/en/july_5.jpg`,
        `${Config.staticPrefix}/static/images/slideshow/silhouetteAC/en/july_6.jpg`,
      ];
    }
  },
};
export default data;
