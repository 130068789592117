import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  all,
} from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  SEARCH_OTHER_SERVICES,
  SEARCH_OTHER_SERVICES_REQUEST,
  SEARCH_OTHER_SERVICES_ERROR,
  SEARCH_OTHER_SERVICES_SUCCESS,
  SEARCH_PHOTO,
  SEARCH_PHOTO_REQUEST,
  SEARCH_PHOTO_ERROR,
  SEARCH_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
  NICE_PHOTO_SUCCESS,
  NICE_PHOTO,
  NICE_PHOTO_ERROR,
  SEARCH_OTHER_SERVICES_CLEAR,
} from './constants';
import {
  makeSelectOtherServices,
  makeSelectPhotos,
  makeSelectLoading,
  makeSelectLoadingOtherServices,
} from './selectors';
import { CLOSE_MODAL } from '../SearchPhotoModal/constants';
import { LOADING_HIDE } from '../Loading/constants';
import { makeSelectPhotos as maseSelectCategoryList } from '~/containers/CategoryPage/selectors';

// import { NICE_PHOTO, NICE_PHOTO_ERROR } from '~/components/Gallery/constants';
import * as searchPageService from '~/services/search-page';
import * as photoPageService from '~/services/photo-page';
import Config from '~/config';
import { OPEN_MODAL } from '~/containers/ErrorMessageModal/constants';
import LocalStorage from '~/services/LocalStorage';

function* searchPhoto(action) {
  const { payload: data, api } = action;
  const { isFromSearchHeader } = data;
  const isLoading = yield select(makeSelectLoading());
  if (isLoading) return;

  // clear old data before search new keyword
  yield put({
    type: SEARCH_OTHER_SERVICES_CLEAR,
    payload: [],
  });

  yield put({
    type: SEARCH_PHOTO_REQUEST,
  });
  const apiFunction = api
    ? api.search.searchData
    : searchPageService.searchData;

  const apiKeyword = api
    ? api.search.getSuggestKeyword
    : searchPageService.getSuggestKeyword;

  console.log('apiKeywordapiKeyword', data);

  try {
    let {
      collection,
      has_next,
      page,
      total_page,
      total_item,
      suggestList,
    } = yield call(apiFunction, {
      ...data,
      category_id: data.category,
      excludeCategory_id: data.excludeCategory,
      isFromSearchHeader,
    });

    console.log('responseDataresponseData', data);
    collection = collection.map((item) => {
      if (item?.filename) {
        item.thumbnail = Config.renderThumbnailImageByFileName(item.filename);
      }
      return item;
    });

    try {
      const responseData = yield call(apiKeyword, {
        keyword: data?.keyword || '',
        language: data?.language || '',
      });

      suggestList = responseData?.result || [];
    } catch (error) {
      suggestList = [];
      console.log(error);
    }

    console.log('suggestListsuggestList', suggestList);

    const pageInt = parseInt(page);
    yield put({
      type: SEARCH_PHOTO_SUCCESS,
      collection,
      suggest: suggestList,
      has_next,
      page: pageInt,
      total_page: total_page || 1,
      total_item: total_item || collection.length,
    });
    if (data.image_token) {
      yield put({
        type: LOADING_HIDE,
      });
      yield put({
        type: CLOSE_MODAL,
      });
    }
  } catch (error) {
    let status;
    let { message } = error;
    if (error.response) {
      status = error.response.status;
      message = error.response.data.message || '';
    }
    yield put({ type: SEARCH_PHOTO_ERROR, error: { status, message } });
  }
}

function* searchOtherServices(action) {
  const { payload: data, api } = action;
  const { isFromSearchHeader } = data;
  const isLoading = yield select(makeSelectLoadingOtherServices());
  if (isLoading) return;

  yield put({
    type: SEARCH_OTHER_SERVICES_REQUEST,
  });

  const isExisted =
    LocalStorage.otherServices &&
    LocalStorage.otherServices.every((item) => item.collection);
  if (isExisted) {
    yield put({
      type: SEARCH_OTHER_SERVICES_SUCCESS,
      payload: LocalStorage.otherServices,
    });
    return;
  }

  let otherServices = yield select(makeSelectOtherServices());

  const apiFunction = api
    ? api.search.searchData
    : searchPageService.searchData;
  try {
    otherServices = yield all(
      otherServices.map(function* ({ serviceType }) {
        let { collection } = yield call(apiFunction, {
          ...data,
          category_id: '',
          excludeCategory_id: '',
          isFromSearchHeader,
          serviceType,
          page: 1,
          per_page: 10,
          is_similar: 1,
        });
        console.log('DATA SIMILAR SEARCH', collection);
        collection = collection.map((item) => {
          if (item?.filename) {
            item.thumbnail = Config.renderThumbnailImageByFileName(
              item.filename,
              serviceType,
            );
            console.log('item sau khi xu li', item.thumbnail);
          }
          return item;
        });
        return {
          serviceType,
          collection,
        };
      }),
    );
    yield put({
      type: SEARCH_OTHER_SERVICES_SUCCESS,
      payload: otherServices,
    });
  } catch (error) {
    let status;
    let { message } = error;
    if (error.response) {
      status = error.response.status;
      message = error.response.data.message;
    }
    yield put({
      type: SEARCH_OTHER_SERVICES_ERROR,
      error: { status, message },
    });
  }
}

function* nicePhoto(action) {
  try {
    console.log('searchedPhoto searchedPhoto photo ne 123', action);
    const pageFrom = action.pageFrom || '';
    const photos =
      pageFrom === 'category'
        ? yield select(maseSelectCategoryList()) //logic đang lỗi ở đây do đang lấy trong list catery, cần tách riêng ra 1 phần khác
        : yield select(makeSelectPhotos());
    const searchedPhoto = photos.find((item) => item.id === action.value);
    console.log('searchedPhoto searchedPhoto photo ne 1', photos);

    console.log('searchedPhoto searchedPhoto photo ne', searchedPhoto);
    // if (!searchedPhoto) return;
    if (searchedPhoto.is_nice) {
      // return yield put({
      //   type: OPEN_MODAL,
      //   title: i18n.t(Config.getPrefixContent('already_nice')),
      // });
      console.log('hinh da like roi nen se xoa', action.value);
      yield put({
        type: REMOVE_NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.removeNicePhoto, action.value);
    } else {
      console.log('hinh chua like roi nen se like', action.value);

      yield put({
        type: NICE_PHOTO_SUCCESS,
        id: action.value,
      });
      yield call(photoPageService.nicePhoto, action.value);
    }
  } catch (error) {
    yield put({
      type: NICE_PHOTO_ERROR,
      error: true,
    });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* searchPhotoSaga() {
  // yield takeLatest(SEARCH_PHOTO, searchPhoto);
  // yield takeLatest(SEARCH_OTHER_SERVICES, searchOtherServices);
  yield takeEvery(SEARCH_PHOTO, searchPhoto);
  yield takeEvery(SEARCH_OTHER_SERVICES, searchOtherServices);
  yield takeLatest(NICE_PHOTO, nicePhoto);
}
