import config from './index';

// key: ['designKey', 'illustKey', 'photoKey', 'silhouetteKey']
const keysConfig = {
  // Route
  route_detail_page: [
    'free-design-template',
    'clip-art',
    'photo',
    'silhouette',
  ],
  route_recommend_page: [
    'recommend-design',
    'recommend-illustration',
    'recommend-photos',
    'recommend-silhouettes',
  ],
  route_popular_page: [
    'popular-design',
    'popular-illustration',
    'popular-photos',
    'popular-silhouettes',
  ],
  route_new_page: [
    'new-design',
    'new-illustration',
    'new-photos',
    'new-silhouettes',
  ],

  // Key i18n
  title_form_search_top_page: [
    'design_title_form_search_top_page',
    'ill_title_form_search_top_page',
    'photo_title_form_search_top_page',
    'sil_title_form_search_top_page',
  ],
  title2_form_search_top_page: [
    'design_title2_form_search_top_page',
    'ill_title2_form_search_top_page',
    'photo_title2_form_search_top_page',
    'sil_title2_form_search_top_page',
  ],
  title_download_page: [
    'design_title_download_page',
    'ill_title_download_page',
    'photo_title_download_page',
    'sil_title_download_page',
  ],
  meta_keywords_download_page: [
    'design_meta_keywords_download_page',
    'ill_meta_keywords_download_page',
    'photo_meta_keywords_download_page',
    'sil_meta_keywords_download_page',
  ],
  meta_description_download_page: [
    'design_meta_description_download_page',
    'ill_meta_description_download_page',
    'photo_meta_description_download_page',
    'sil_meta_description_download_page',
  ],
  title_management_company_page: [
    'design_title_management_company_page',
    'ill_title_management_company_page',
    'photo_title_management_company_page',
    'sil_title_management_company_page',
  ],
  meta_description_management_company_page: [
    'design_meta_description_management_company_page',
    'ill_meta_description_management_company_page',
    'photo_meta_description_management_company_page',
    'sil_meta_description_management_company_page',
  ],
  meta_keywords_management_company_page: [
    'design_meta_keywords_management_company_page',
    'ill_meta_keywords_management_company_page',
    'photo_meta_keywords_management_company_page',
    'sil_meta_keywords_management_company_page',
  ],
  title_breadcrumb_download_page: [
    'design_title_breadcrumb_download_page',
    'ill_title_breadcrumb_download_page',
    'photo_title_breadcrumb_download_page',
    'sil_title_breadcrumb_download_page',
  ],
  download_desc_1: [
    ' ',
    'ill_download_desc_1',
    'photo_download_desc_1',
    'sil_download_desc_1',
  ],
  download_desc_2: [
    ' ',
    'ill_download_desc_2',
    'photo_download_desc_2',
    'sil_download_desc_2',
  ],
  similar_category_des: [
    ' ',
    'ill_category_des',
    'photo_category_des',
    'sil_category_des',
  ],
  title_premium_page: [
    'design_title_premium_page',
    'ill_title_premium_page',
    'photo_title_premium_page',
    'sil_title_premium_page',
  ],
  title_user_info_page: [
    'design_title_user_info_page',
    'ill_title_user_info_page',
    'photo_title_user_info_page',
    'sil_title_user_info_page',
  ],
  title_category_page: [
    'design_title_category_page',
    'ill_title_category_page',
    'photo_title_category_page',
    'sil_title_category_page',
  ],
  meta_keywords_category_page: [
    'design_meta_keywords_category_page',
    'ill_meta_keywords_category_page',
    'photo_meta_keywords_category_page',
    'sil_meta_keywords_category_page',
  ],
  meta_description_category_page: [
    'design_meta_description_category_page',
    'ill_meta_description_category_page',
    'photo_meta_description_category_page',
    'sil_meta_description_category_page',
  ],
  title_top_page: [
    'design_title_top_page',
    'ill_title_top_page',
    'photo_title_top_page',
    'sil_title_top_page',
  ],
  title_downloader_register_page: [
    'design_title_downloader_register_page',
    'ill_title_downloader_register_page',
    'photo_title_downloader_register_page',
    'sil_title_downloader_register_page',
  ],
  meta_keywords_downloader_register_page: [
    'design_meta_keywords_downloader_register_page',
    'ill_meta_keywords_downloader_register_page',
    'photo_meta_keywords_downloader_register_page',
    'design_meta_keywords_downloader_register_page',
  ],
  meta_description_downloader_register_page: [
    'design_meta_description_downloader_register_page',
    'ill_meta_description_downloader_register_page',
    'photo_meta_description_downloader_register_page',
    'sil_meta_description_downloader_register_page',
  ],
  h1_register_page: [
    'design_h1_register_page',
    'ill_h1_register_page',
    'photo_h1_register_page',
    'sil_h1_register_page',
  ],
  h1_login_page: [
    'design_h1_login_page',
    'ill_h1_login_page',
    'photo_h1_login_page',
    'sil_h1_login_page',
  ],
  title_privacy_policy_page: [
    'design_title_privacy_policy_page',
    'ill_title_privacy_policy_page',
    'photo_title_privacy_policy_page',
    'sil_title_privacy_policy_page',
  ],
  meta_keywords_privacy_policy_page: [
    'design_meta_keywords_privacy_policy_page',
    'ill_meta_keywords_privacy_policy_page',
    'photo_meta_keywords_privacy_policy_page',
    'sil_meta_keywords_privacy_policy_page',
  ],
  meta_description_privacy_policy_page: [
    'design_meta_description_privacy_policy_page',
    'ill_meta_description_privacy_policy_page',
    'photo_meta_description_privacy_policy_page',
    'sil_meta_description_privacy_policy_page',
  ],
  h1_privacy_policy_page: [
    'design_h1_privacy_policy_page',
    'ill_h1_privacy_policy_page',
    'photo_h1_privacy_policy_page',
    'sil_h1_privacy_policy_page',
  ],
  title_recommend_page: [
    'design_title_recommend_page',
    'ill_title_recommend_page',
    'photo_title_recommend_page',
    'sil_title_recommend_page',
  ],
  meta_keywords_recommend_page: [
    'design_meta_keywords_recommend_page',
    'ill_meta_keywords_recommend_page',
    'photo_meta_keywords_recommend_page',
    'sil_meta_keywords_recommend_page',
  ],
  meta_description_recommend_page: [
    'design_meta_description_recommend_page',
    'ill_meta_description_recommend_page',
    'photo_meta_description_recommend_page',
    'sil_meta_description_recommend_page',
  ],
  title_popular_page: [
    'design_title_popular_page',
    'ill_title_popular_page',
    'photo_title_popular_page',
    'sil_title_popular_page',
  ],
  meta_keywords_popular_page: [
    'design_meta_keywords_popular_page',
    'ill_meta_keywords_popular_page',
    'photo_meta_keywords_popular_page',
    'sil_meta_keywords_popular_page',
  ],
  meta_description_popular_page: [
    'design_meta_description_popular_page',
    'ill_meta_description_popular_page',
    'photo_meta_description_popular_page',
    'sil_meta_description_popular_page',
  ],
  title_new_page: [
    'design_title_new_page',
    'ill_title_new_page',
    'photo_title_new_page',
    'sil_title_new_page',
  ],
  meta_keywords_new_page: [
    'design_meta_keywords_new_page',
    'ill_meta_keywords_new_page',
    'photo_meta_keywords_new_page',
    'sil_meta_keywords_new_page',
  ],
  meta_description_new_page: [
    'design_meta_description_new_page',
    'ill_meta_description_new_page',
    'photo_meta_description_new_page',
    'sil_meta_description_new_page',
  ],
  title_search_page: [
    '',
    'ill_title_search_page',
    'photo_title_search_page',
    'sil_title_search_page',
  ],
  title_similar_page: [
    'design_title_similar_page',
    'ill_title_similar_page',
    'photo_title_similar_page',
    'sil_title_similar_page',
  ],
  meta_keywords_similar_page: [
    'design_meta_keywords_similar_page',
    'ill_meta_keywords_similar_page',
    'photo_meta_keywords_similar_page',
    'sil_meta_keywords_similar_page',
  ],
  meta_description_similar_page: [
    'design_meta_description_similar_page',
    'ill_meta_description_similar_page',
    'photo_meta_description_similar_page',
    'sil_meta_description_similar_page',
  ],
  title_terms_of_service_page: [
    'design_title_terms_of_service_page',
    'ill_title_terms_of_service_page',
    'photo_title_terms_of_service_page',
    'sil_title_terms_of_service_page',
  ],
  meta_keywords_terms_of_service_page: [
    'design_meta_keywords_terms_of_service_page',
    'ill_meta_keywords_terms_of_service_page',
    'photo_meta_keywords_terms_of_service_page',
    'sil_meta_keywords_terms_of_service_page',
  ],
  meta_description_terms_of_service_page: [
    'design_meta_description_terms_of_service_page',
    'ill_meta_description_terms_of_service_page',
    'photo_meta_description_terms_of_service_page',
    'sil_meta_description_terms_of_service_page',
  ],
  no_result_search_page: [
    'design_no_result_search_page',
    'ill_no_result_search_page',
    'photo_no_result_search_page',
    'sil_no_result_search_page',
  ],
  breadcrumb_recommend: [
    'Most Recommended Design',
    'Most Recommended Illustration',
    'Most Recommended Photos',
    'Most Recommended Silhouettes',
  ],
  breadcrumb_popular: [
    'Most Popular Design',
    'Most Popular Illustration',
    'Most Popular Photos',
    'Most Popular Silhouettes',
  ],
  breadcrumb_new: [
    'New Design',
    'New Illustration',
    'New Photos',
    'New Silhouettes',
  ],
  title_similar_detail: [
    'design_title_similar_detail',
    'ill_title_similar_detail',
    'photo_title_similar_detail',
    'sil_title_similar_detail',
  ],
  meta_keywords_search_page: [
    'design_meta_keywords_search_page',
    'ill_meta_keywords_search_page',
    'photo_meta_keywords_search_page',
    'sil_meta_keywords_search_page',
  ],
  meta_description_search_page: [
    'design_meta_description_search_page',
    'ill_meta_description_search_page',
    'photo_meta_description_search_page',
    'sil_meta_description_search_page',
  ],
  tops_tag_breadcrum: [
    'design_tops_tag_breadcrum',
    'photo_tops_tag_breadcrum',
    'illust_tops_tag_breadcrum',
    'silhouette_tops_tag_breadcrum',
  ],
  title_help_page: [
    'design_title_help_page',
    'ill_title_help_page',
    'photo_title_help_page',
    'sil_title_help_page',
  ],
  meta_keywords_help_page: [
    'design_meta_keywords_help_page',
    'ill_meta_keywords_help_page',
    'photo_meta_keywords_help_page',
    'sil_meta_keywords_help_page',
  ],
  meta_description_help_page: [
    'design_meta_descripton_help_page',
    'ill_meta_descripton_help_page',
    'photo_meta_descripton_help_page',
    'sil_meta_descripton_help_page',
  ],
};

const get = (key, serviceType) => {
  serviceType = serviceType || config.serviceType;
  const keys = keysConfig[key] || [];

  switch (serviceType) {
    case config.getServiceTypes().DESIGN_AC:
      return keys[0];
    case config.getServiceTypes().ILLUST_AC:
      return keys[1];
    case config.getServiceTypes().PHOTO_AC:
      return keys[2];
    case config.getServiceTypes().SILHOUETTE_AC:
      return keys[3];
  }

  return '';
};

export default { keysConfig, get };
