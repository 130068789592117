import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'next/router';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import withI18next from '~/../hocs/_withI18next';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from '~/containers/AuthProvider/selectors';
import {
  loadPhotosInCart,
  setIsMobileLeftMenuOpen,
  setIsMobileRightMenuOpen,
  setIsPhotoCartOpen,
} from '~/containers/Layout/actions';
import { selectLayout } from '~/containers/Layout/selectors';
import { makeSelectCategories } from '~/containers/MetadataProvider/selectors';
// import PhotoCart from '~/containers/PhotoCart/Loadable';
import themeVariables from '~/shared/themeVariables';

const mapStateToProps = createStructuredSelector({
  categories: makeSelectCategories(),
  profile: makeSelectProfile(),
  isAuthenticated: makeSelectIsAuthenticated(),
  layout: selectLayout,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIsPhotoCartOpen,
      setIsMobileLeftMenuOpen,
      setIsMobileRightMenuOpen,
      loadPhotosInCart,
    },
    dispatch,
  );

@connect(mapStateToProps, mapDispatchToProps)
@withI18next()
@withRouter
class MenuHeader extends React.Component {
  componentDidMount() {
    const { loadPhotosInCart, isShowHeaderExternal } = this.props;
    loadPhotosInCart();
  }

  render() {
    const {
      // isBannerDisplayed,
      isDiscountDisplayed,
      router,
      // isShowHeaderExternal,
    } = this.props;
    let topSpace = 0; // Height of external links header
    // if (isBannerDisplayed) {
    //   console.log('co vo day ne');
    //   topSpace += 91; // Height of creator banner
    // }
    if (isDiscountDisplayed) {
      topSpace += 60; // Height of discount banner
    }
    // if (!isShowHeaderExternal) {
    //   topSpace = 0;
    // }
    const isHomePage = router.pathname === '/index' || router.pathname === '/';

    return (
      <header
        className="menu-header"
        style={{
          top: topSpace,
        }}
      >
        <DesktopView {...this.props} />
        <MobileView {...this.props} />
        <style jsx>
          {`
            .menu-header {
              position: fixed;
              width: 100%;
              height: 70px;
              background: #f8f9fa;
              z-index: 999;
              display: none;
            }
            @media (max-width: ${themeVariables.breakpoints.tabletS
                .maxWidth}px) {
              .menu-header {
                z-index: 901;
                display: block;
              }
            }
            @media (max-width: ${themeVariables.breakpoints.tabletS
                .minWidth}px) {
              .menu-header {
                position: relative;
                box-shadow: ${isHomePage
                  ? '0px 2px 4px rgb(0 0 0 / 25%)'
                  : 'none'};
              }
            }
          `}
        </style>
      </header>
    );
  }
}

export default MenuHeader;
