import React from 'react';
import cn from 'clsx';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';

@withI18next()
class Logo extends React.Component {
  render() {
    const { t, byDevice, isFAQPage, logoNoText } = this.props;
    const logoInfo = Config.getLogo();

    return (
      <div className={cn('logo-wrapper', Config.serviceType)}>
        {/* <div className="logo-desc">{t(logoInfo.descriptionKey)}</div> */}
        {!logoNoText &&
          (byDevice ? (
            <>
              {/* <img
                  className="lazyload img-logo-mobile"
                  data-src={logoInfo.mobile}
                  alt="site logo"
                /> */}
              <img
                className="lazyload img-logo-desktop"
                data-src={
                  Config.isDesignAC() ? logoInfo.faqPage : logoInfo.desktop
                }
                alt="site logo"
              />
            </>
          ) : (
            <img
              className="lazyload img-logo"
              data-src={
                Config.isDesignAC() ? logoInfo.faqPage : logoInfo.desktop
              }
              alt="site logo"
            />
          ))}
        {logoNoText &&
          (byDevice ? (
            <>
              {/* <img
                    className="lazyload img-logo-mobile"
                    data-src={logoInfo.mobile}
                    alt="site logo"
                  /> */}
              <img
                className="lazyload  logo-no-text"
                data-src={
                  Config.isDesignAC() ? logoInfo.faqPage : logoInfo.logoNoText
                }
                alt="site logo"
                width={124}
                height={28}
              />
            </>
          ) : (
            <img
              className="lazyload  logo-no-text"
              data-src={
                Config.isDesignAC() ? logoInfo.faqPage : logoInfo.logoNoText
              }
              alt="site logo"
              width={124}
              height={28}
            />
          ))}
        <style jsx>
          {`
            .logo-wrapper {
              position: relative;
              cursor: pointer;
              color: #fff;
            }

            .img-logo-mobile {
              width: 40px;
              height: 40px;
            }
            .img-logo-desktop {
              display: none;
            }

            .img-logo {
              display: inherit;
              height: 42px;
            }
            .logo-wrapper.illust_ac .img-logo {
              height: 38px;
            }
            .logo-wrapper.silhouette_ac .img-logo {
              height: 32px;
            }

            .logo-desc {
              position: absolute;
              top: 0px;
              left: 0px;
              font-weight: 500;
              font-size: 11px;
              display: none;
              white-space: nowrap;
            }

            @media (min-width: 768px) {
              .logo-wrapper {
                display: block;
              }
              .logo-wrapper.design_ac {
                padding-top: 4px;
              }

              .logo-desc {
                display: inherit;
              }

              .img-logo-mobile {
                display: none;
              }
              .img-logo-desktop {
                display: inherit;
                height: 42px;
              }

              .logo-wrapper.illust_ac .img-logo-desktop {
                height: 38px;
              }
              .logo-wrapper.silhouette_ac .img-logo-desktop {
                height: 32px;
              }
            }
            @media (max-width: 500px) {
              .logo-wrapper {
                display: none;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .lazyload:not([src]) {
              visibility: hidden;
            }
          `}
        </style>
      </div>
    );
  }
}

export default Logo;
