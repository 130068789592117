import axios from 'axios';
import Cookies from '~/services/Cookies';

export const addInterceptors = (axiosInstance) => {
  /*
  - Each request will include following properties in query or body:
    + service_type
    + lang
    + language
    + disp_language
  - Each language param will be converted from "tw" to "zh-tw" and "cn" to "zh-cn".
  */
  axiosInstance.interceptors.request.use(
    (config) => {
      // console.log('---> Check config axios ---');
      // console.log(JSON.stringify(config));
      // if (config.timeout === 0) {
      //   config.timeout = 3000;
      // }
      if (!config.headers || !config.headers.Authorization) {
        const token = Cookies.get('token');
        if (token) {
          config.headers.common.Authorization = `${token}`;
          config.headers.Authorization = `${token}`;
        }
      }

      // if (process.browser) {
      // console.log('AXIOS REQUEST', config.method, config.url);
      // console.log('REQUEST PARAMS', config.params);
      // console.log('REQUEST DATA', config.data);
      // }
      return config;
    },
    (error) => Promise.reject(error.response),
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if (process.browser && response && response.config) {
        console.log(
          'AXIOS RESPONSE',
          response.config.method,
          response.config.url,
        );
        console.log(response.status, response.data);
      }
      // Do something with response data
      if (response && response.data) {
        return response.data;
      }

      return response;
    },
    (error) => {
      if (error.config) {
        console.error(
          'AXIOS DEBUG ERROR',
          error.config.method,
          error.config.url,
        );
        if (error.response) {
          console.error(
            'AXIOS ERROR1111111',
            error.response.status,
            error.response.data,
          );
        } else {
          console.error(
            'AXIOS ERROR2222222',
            error.response.status,
            error.response.data,
          );
        }
      } else {
        console.log('AXIOS CUSTOM ERROR', error);
      }
      return Promise.reject(error);
    },
  );
};

addInterceptors(axios);

export default axios;
