import remove from 'lodash/remove';
import {
  ADD_PHOTO_IN_CART,
  CLEAN_PHOTOS_IN_CART,
  LOAD_PHOTOS_IN_CART,
  REMOVE_PHOTO_IN_CART,
  SET_IS_MOBILE_LEFT_MENU_OPEN,
  SET_IS_MOBILE_RIGHT_MENU_OPEN,
  SET_IS_PHOTO_CART_OPEN,
  SET_NUMBER_PHOTOS_IN_CART,
  IS_SURVEY,
  DATA_COUNT_SURVEY,
  ACTIVE_EDITOR_DOWNLOAD,
  CHECK_IS_ACTIVE_EDITOR_DOWNLOAD,
  SET_SHOW_SEARCH_FORM_HOMEPAGE_SCROLL,
  SET_SHOW_FILTER_ON_HEADER,
} from './constants';
import LocalStorage from '~/services/LocalStorage';
import Config from '~/config';

const roles = Config.getRoles();

const initialState = {
  isPhotoCartOpen: false,
  photosInCart: [],
  isMobileLeftMenuOpen: false,
  isMobileRightMenuOpen: false,
  isSurvey: false,
  countSearch: 0,
  countDownload: 0,
  isActiveEditorDownload: true,
  isShowSearchForm: true,
  isShowFilterOnHeader: false,
};

function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_PHOTO_CART_OPEN: {
      return {
        ...state,
        isPhotoCartOpen: state.photosInCart.length > 0 && action.payload,
        isMobileLeftMenuOpen: false,
        isMobileRightMenuOpen: false,
      };
    }

    case SET_IS_MOBILE_LEFT_MENU_OPEN:
      return {
        ...state,
        isPhotoCartOpen: false,
        isMobileLeftMenuOpen: action.payload,
        isMobileRightMenuOpen: false,
      };
    case SET_IS_MOBILE_RIGHT_MENU_OPEN:
      return {
        ...state,
        isPhotoCartOpen: false,
        isMobileLeftMenuOpen: false,
        isMobileRightMenuOpen: action.payload,
      };
    case LOAD_PHOTOS_IN_CART: {
      return {
        ...state,
        photosInCart: LocalStorage.photos,
      };
    }

    case ADD_PHOTO_IN_CART:
      LocalStorage.photos = [action.payload, ...state.photosInCart].slice(
        0,
        process.env.NUMBER_DOWNLOAD,
      );
      return {
        ...state,
        photosInCart: LocalStorage.photos,
        isPhotoCartOpen: true,
      };
    case REMOVE_PHOTO_IN_CART: {
      let newPhotos = state.photosInCart.slice();
      remove(
        newPhotos,
        (photo) =>
          photo.id === action.payload.id &&
          photo.sizeSelect === action.payload.sizeSelect,
      );
      LocalStorage.photos = newPhotos;
      return {
        ...state,
        photosInCart: LocalStorage.photos,
        isPhotoCartOpen: newPhotos.length > 0 ? true : false,
      };
    }
    case CLEAN_PHOTOS_IN_CART:
      LocalStorage.photos = [];
      return {
        ...state,
        photosInCart: [],
        isPhotoCartOpen: false,
      };
    case IS_SURVEY: {
      let isSurvey = LocalStorage.isSurvey;
      let countSearch = LocalStorage.countSearch;
      let countDownload = LocalStorage.countDownload;

      if (!isSurvey) {
        LocalStorage.isSurvey = false;
        isSurvey = false;
      }
      if (!countSearch) {
        LocalStorage.countSearch = 0;
        countSearch = 0;
      }
      if (!countDownload) {
        LocalStorage.countDownload = 0;
        countDownload = 0;
      }
      return {
        ...state,
        isSurvey: isSurvey,
        countSearch,
        countDownload,
      };
    }
    case DATA_COUNT_SURVEY: {
      console.log('action data survey ne', action);
      let countSearch = action.payload?.countSearch;
      if (countSearch === undefined) {
        countSearch = state.countSearch;
      } else {
        LocalStorage.countSearch = countSearch;
      }
      let countDownload = action.payload?.countDownload;
      console.log('countDownload = null ne', countDownload);

      if (countDownload === undefined) {
        console.log('count = null ne');
        countDownload = state.countDownload;
      } else {
        LocalStorage.countDownload = countDownload;
      }
      return {
        ...state,
        countSearch,
        countDownload,
      };
    }
    case CHECK_IS_ACTIVE_EDITOR_DOWNLOAD: {
      if (action.payload === roles.PREMIUM) {
        let dataActiveEditorDownload = LocalStorage.isActiveEditorDownload;
        if (dataActiveEditorDownload) {
          if (new Date().getDate() !== dataActiveEditorDownload.day) {
            return {
              ...state,
              isActiveEditorDownload: true,
            };
          } else {
            return {
              ...state,
              isActiveEditorDownload: false,
            };
          }
        } else {
          const dataActive = {
            isActive: true,
            day: new Date().getDate(),
          };
          LocalStorage.isActiveEditorDownload = dataActive;
          return {
            ...state,
            isActiveEditorDownload: true,
          };
        }
      }
      return {
        ...state,
      };
    }
    case ACTIVE_EDITOR_DOWNLOAD: {
      const dataActive = {
        isActive: false,
        day: new Date().getDate(),
      };
      LocalStorage.isActiveEditorDownload = dataActive;
      return {
        ...state,
        isActiveEditorDownload: false,
      };
    }
    case SET_SHOW_SEARCH_FORM_HOMEPAGE_SCROLL: {
      return {
        ...state,
        isShowSearchForm: action.payload,
      };
    }
    case SET_SHOW_FILTER_ON_HEADER: {
      return {
        ...state,
        isShowFilterOnHeader: action.payload,
      };
    }

    default:
      return state;
  }
}

export default layoutReducer;
