import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Annotate, InputFile } from '~/lib';
import * as modelReleaseService from '~/services/my-page/model-service';
import * as propertyService from '~/services/my-page/property-service';
import { withI18next } from '~/../hocs/_withI18next';

@withI18next()
class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        s3_path: '',
      },
      file: '',
      saving: false,
    };
  }

  handleChange(name, event) {
    let { form } = this.state;
    form[name] = event.target.value;
    this.setState({
      form: form,
    });
  }

  handleChangeFileUpload(file) {
    let { form } = this.state;
    form.s3_path = file.target.value;
    this.setState({
      file: file.target.file,
      form: form,
    });
  }

  handleSubmit(event) {
    const { t, isModel } = this.props;
    const { file, form } = this.state;
    event.preventDefault();
    this.setState({ saving: true }, () => {
      let data = Object.assign({}, form);
      if (file) {
        data['basename'] = file['name'];
      }
      const saveDataPromise = isModel
        ? modelReleaseService.save(data)
        : propertyService.save(data);
      saveDataPromise
        .then(() => {
          toast.success(t('Status'), t('Upload success'));
          this.setState({
            saving: false,
            form: { name: '', s3_path: '' },
            file: '',
          });
        })
        .catch(() => this.setState({ saving: false }));
    });
  }

  render() {
    const { isModel, t } = this.props;
    const { form, saving } = this.state;
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className="row">
          {isModel ? (
            <div className="col-sm-6 form-group">
              <label>
                {t('Model name')}
                <Annotate
                  text={t(
                    'it_will_be_used_for_searching_and_managing_model_releases',
                  )}
                />
              </label>
              <input
                className="form-control form-control mypage-input"
                placeholder={t('Model name')}
                type="text"
                value={form.name}
                onChange={this.handleChange.bind(this, 'name')}
              />
            </div>
          ) : (
            <div className="col-sm-6 form-group">
              <label>
                {t('Property name')}
                <Annotate
                  text={t(
                    'it_will_be_used_for_searching_and_managing_property_releases',
                  )}
                />
              </label>
              <input
                disabled
                className="form-control form-control mypage-input"
                placeholder={t('Property name')}
                type="text"
                value={form.name}
                onChange={this.handleChange.bind(this, 'name')}
              />
            </div>
          )}
          <div className="col-sm-6 form-group">
            <label>
              {isModel
                ? t('Model Release Selection')
                : t('Property Release Selection')}
              <Annotate text={t('jpg_png_pdf_format_files_(100_MB_or_less)')} />
            </label>
            <InputFile
              uploadFor="data"
              accept="image/jpeg,image/png,application/pdf"
              value={form['s3_path']}
              types={['JPG', 'PNG', 'pdf']}
              onChange={this.handleChangeFileUpload.bind(this)}
            />
          </div>
        </div>
        <div className="text-center pb">
          <button
            type="submit"
            className="btn mypage-btn-primary btn-ac-default"
            disabled={saving || !(form.name && form.s3_path)}
          >
            <i className={`fa ${saving ? 'fa-spinner fa-pulse fa-w' : ''}`} />
            &nbsp;
            {t('upload')}
          </button>
        </div>
      </form>
    );
  }
}

export default FormUpload;
