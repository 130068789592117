import { OPEN_MODAL, CLOSE_MODAL, MODAL_SUBMIT } from './constants';

export function openModal(index) {
  return {
    type: OPEN_MODAL,
    index,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function modalSubmit() {
  return {
    type: MODAL_SUBMIT,
  };
}
