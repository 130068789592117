import {
  LOAD_DATA_CATEGORY,
  LOAD_DATA_CATEGORY_SUCCESS,
  LOAD_DATA_CATEGORY_ERROR,
  CHANGE_PAGE,
  LOAD_DATA_CATEGORY_SIMILAR,
  LOAD_DATA_CATEGORY_OLD,
} from './constants';
import {
  NICE_PHOTO_ERROR,
  NICE_PHOTO_SUCCESS,
  REMOVE_NICE_PHOTO_SUCCESS,
} from './constants';

export const initialState = {
  photos: [],
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
};

export default function loadDataCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DATA_CATEGORY_OLD:
    case LOAD_DATA_CATEGORY:
      return {
        ...state,
        query: {
          slug: action.payload.slug,
          per_page: action.payload.per_page || 100,
          page: action.payload.page || 1,
          order_by: action.payload.order_by || 'popular',
        },
        loading: true,
        error: false,
      };
    case LOAD_DATA_CATEGORY_SIMILAR:
      return {
        ...state,
        query: {
          slug: action.payload.slug,
          per_page: action.payload.per_page || 100,
          page: action.payload.page || 1,
          order_by: action.payload.order_by || 'popular',
          tag_name: action.payload.tag_name,
        },
        loading: true,
        error: false,
      };
    case LOAD_DATA_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        photos: action.collection,
        has_next: action.has_next,
        page: action.page,
        totalPage: action.total_page,
        totalItem: action.total_item,
        category: action.category,
        dataType: action.dataType || null,
      };
    case LOAD_DATA_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: !!action.error,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case NICE_PHOTO_SUCCESS: {
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 1,
              nice: (item.nice || 0) + 1,
            };
          }
          return item;
        }),
      };
    }

    case REMOVE_NICE_PHOTO_SUCCESS: {
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id)
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          return item;
        }),
      };
    }
    case NICE_PHOTO_ERROR:
      // If nice photo is failed, undo number of nice
      return {
        ...state,
        photos: state.photos.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              is_nice: 0,
              nice: (item.nice || 0) - 1,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}

// const filterInitState = {
//     orderBy: 'popular',
//     download: 'desc',
//     createdAt: '',
//     perPage: '100'
// };
//
// function filterReducer(state = filterInitState, action) {
//     switch (action.type) {
//         case FILTER_ORDER_BY:
//             return {
//                 ...state,
//                 orderBy: action.value,
//                 download: action.value === 'popular' ? 'desc' : '',
//                 createdAt: action.value === 'newest' ? 'desc' : ''
//             };
//         case FILTER_PER_PAGE:
//             return {
//                 ...state,
//                 perPage: action.value
//             };
//         default:
//             return state;
//     }
//
// }
//
// export default combineReducers({
//     dataCategory: loadDataCategoryReducer,
//     filter: filterReducer
// });
