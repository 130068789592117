import React from 'react';
import I18nLink from '~/components/I18nLink';
import themeVariables from '~/shared/themeVariables';

const LinkTo = (props) => {
  const { label, href, host } = props;

  return (
    <I18nLink href={href} host={host}>
      <a className="link-view-all" target="_blank" rel="noreferrer">
        {label}
        <style jsx>
          {`
            .link-view-all {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
            }
            .link-view-all:hover {
              text-decoration: underline !important;
              color: ${themeVariables.colors.link} !important;
              opacity: 0.8;
            }
            .link-view-all:focus {
              opacity: 1;
              color: ${themeVariables.colors.link} !important;
              text-decoration: none !important;
            }
            // .link-view-all {
            //   cursor: pointer;
            // }
          `}
        </style>
      </a>
    </I18nLink>
  );
};

export default LinkTo;
