import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_COMMENTS_DATA,
  LOAD_COMMENTS_DATA_SUCCESS,
  LOAD_COMMENTS_DATA_ERROR,
  POST_COMMENT,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_ERROR,
  LOAD_REPLY_COMMENTS_DATA,
  LOAD_REPLY_COMMENTS_DATA_SUCCESS,
  LOAD_REPLY_COMMENTS_DATA_ERROR,
  LOAD_APPEND_COMMENTS_DATA,
  LOAD_APPEND_COMMENTS_DATA_SUCCESS,
  LOAD_APPEND_COMMENTS_DATA_ERROR,
  LOAD_MORE_REPLY_COMMENTS_DATA,
  LOAD_MORE_REPLY_COMMENTS_DATA_SUCCESS,
  LOAD_MORE_REPLY_COMMENTS_DATA_ERROR,
  POST_REPLY_COMMENT,
  POST_REPLY_COMMENT_SUCCESS,
  POST_REPLY_COMMENT_ERROR,
  POST_LIKE_COMMENT,
  POST_LIKE_COMMENT_SUCCESS,
  POST_LIKE_COMMENT_ERROR,
} from './constants';
import * as commentService from '~/services/comment';

function* loadComment({ payload }) {
  try {
    const max_results = payload?.max_results || 5;
    const page = payload?.page || 1;
    const data_id = payload?.data_id || null;
    const user_id = payload?.user_id || null;
    console.log('payload load comment ban dau', payload);
    if (!data_id) return;
    const data = yield call(commentService.getComments, {
      max_results,
      page,
      data_id,
      user_id,
    });
    const { collection, has_next, total_page, total_item, countComment } = data;
    yield put({
      type: LOAD_COMMENTS_DATA_SUCCESS,
      collection,
      has_next,
      total_page,
      total_item,
      page: data?.page,
      countComment,
    });
  } catch (error) {
    yield put({ type: LOAD_COMMENTS_DATA_ERROR });
  }
}

function* loadAppendDataComment({ payload }) {
  try {
    const max_results = payload?.max_results || 5;
    const page = payload?.page || 1;
    const data_id = payload?.data_id || null;

    if (!data_id) return;
    const data = yield call(commentService.getComments, {
      max_results,
      page,
      data_id,
    });
    const { collection, has_next, total_page, total_item } = data;
    yield put({
      type: LOAD_APPEND_COMMENTS_DATA_SUCCESS,
      collection,
      has_next,
      total_page,
      total_item,
      page: data?.page,
    });
  } catch (error) {
    yield put({ type: LOAD_APPEND_COMMENTS_DATA_ERROR });
  }
}

function* loadReplyComment({ payload }) {
  try {
    const params = {
      max_results: payload?.max_results || 5,
      page: payload.page || 1,
      comment_id: payload.comment_id || null,
      data_id: payload.data_id || null,
    };
    const data = yield call(commentService.getReplyComments, params);
    yield put({ type: LOAD_REPLY_COMMENTS_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: LOAD_REPLY_COMMENTS_DATA_ERROR });
  }
}

function* loadAppendReplyDataComment({ payload }) {
  try {
    const params = {
      max_results: payload?.max_results || 5,
      page: payload.page || 1,
      comment_id: payload.comment_id || null,
      data_id: payload.data_id || null,
    };
    const data = yield call(commentService.getReplyComments, params);
    yield put({ type: LOAD_MORE_REPLY_COMMENTS_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: LOAD_MORE_REPLY_COMMENTS_DATA_ERROR });
  }
}

function* postReplyComment(params) {
  try {
    const { payload } = params;
    // return;
    const data = yield call(commentService.postReplyComments, payload);

    yield put({ type: POST_REPLY_COMMENT_SUCCESS, data, dataContent: payload });
  } catch (error) {
    yield put({ type: POST_REPLY_COMMENT_ERROR });
  }
}

function* postComment(params) {
  try {
    const { payload } = params;
    const data = yield call(commentService.postComments, payload);
    yield put({ type: POST_COMMENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: POST_COMMENT_ERROR });
  }
}

function* postLikeComment(params) {
  try {
    const { payload } = params;
    console.log('like payload saga ne', payload);
    const data = yield call(commentService.postLikeComment, payload);
    console.log('like xong saga ne', data);
    yield put({
      type: POST_LIKE_COMMENT_SUCCESS,
      data: { success: data.success, comment_id: payload.comment_id },
    });
  } catch (error) {
    yield put({ type: POST_LIKE_COMMENT_ERROR });
  }
}
export default function* loadCommentSaga() {
  yield takeLatest(LOAD_COMMENTS_DATA, loadComment);
  yield takeLatest(POST_COMMENT, postComment);
  yield takeLatest(LOAD_REPLY_COMMENTS_DATA, loadReplyComment);
  yield takeLatest(LOAD_APPEND_COMMENTS_DATA, loadAppendDataComment);
  yield takeLatest(LOAD_MORE_REPLY_COMMENTS_DATA, loadAppendReplyDataComment);
  yield takeLatest(POST_REPLY_COMMENT, postReplyComment);
  yield takeLatest(POST_LIKE_COMMENT, postLikeComment);
}
