export const ERROR_CODE = {
  AC_ERROR: 'AC_ERROR',
  AC_AUTHENTICATE_ERROR: 'AC_AUTHENTICATE_ERROR',
  AC_ERROR_INPUT: 'AC_ERROR_INPUT',
  AC_ERROR_INVALID_REQUEST: 'AC_ERROR_INVALID_REQUEST',
  AC_MAIL_SERVICE_ERROR: 'AC_MAIL_SERVICE_ERROR',
  AC_NOT_ACCEPTABLE_ERROR: 'AC_NOT_ACCEPTABLE_ERROR',
  AC_ERROR_NOTFOUND: 'AC_ERROR_NOTFOUND',
  AC_ERROR_PAYMENT_PREMIUM: 'AC_ERROR_PAYMENT_PREMIUM',
  AC_ERROR_PAYMENT_REQUIRED: 'AC_ERROR_PAYMENT_REQUIRED',
  AC_ERROR_PERMISSION: 'AC_ERROR_PERMISSION',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
};

export const ERROR_TYPE = {
  STRIPE: 'STRIPE',
  SYSTEM: 'SYSTEM',
  REACHED_LIMITED_DOWNLOAD: 'REACHED_LIMITED_DOWNLOAD',
  BLOCKED_DOWNLOAD_TEMPORARY: 'BLOCKED_DOWNLOAD_TEMPORARY',
  TOKEN_IS_NULL: 'TOKEN_IS_NULL',
  TOKEN_FIND_FAILED_SYSTEM: 'TOKEN_FIND_FAILED_SYSTEM',
  TOKEN_IS_NOT_EXIST: 'TOKEN_IS_NOT_EXIST',
  TOKEN_IS_EXPIRED: 'TOKEN_IS_EXPIRED',
  ACC_IS_NOT_ACTIVE: 'ACC_IS_NOT_ACTIVE',
  LOGIN_FAILED: 'LOGIN_FAILED',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
};

export const isError = (response) =>
  !!ERROR_CODE[response.code] || response.errorMessage;
