import 'regenerator-runtime/runtime';
import { all, fork } from 'redux-saga/effects';
import es6promise from 'es6-promise';

import watchHome from '~/containers/HomePage/saga';
import watchMetadata from '~/containers/MetadataProvider/saga';
import watchAuth from '~/containers/AuthProvider/saga';
import watchLoading from '~/containers/Loading/saga';
import watchPhoto from '~/containers/PhotoPage/saga';
import watchSearchResult from '~/containers/SearchResultPage/saga';
import watchCategoryResult from '~/containers/CategoryPage/saga';
import watchRecommendResult from '~/containers/RecommendPage/saga';
import watchPopularResult from '~/containers/PopularPage/saga';
import watchNewResult from '~/containers/NewPage/saga';
import watchSimilarResult from '~/containers/SimilarPage/saga';
import watchNotice from '~/containers/MyPage/Notice/saga'; // !!! Cannot refactor yet
import watchPricePremium from '~/containers/RegisterPremiumPage/saga';
import watchTopTags from '~/containers/Tags/saga';
import watchCustomErrorMessage from '~/containers/CustomErrorMessage/saga';
import watchTagDetail from '~/containers/TagDetail/saga';
import watchPremiumPlan from '~/containers/PaymentModal/saga';
import watchPremium from '~/containers/PremiumPage/saga';
import watchCategories from '~/containers/CategoriesPage/saga';
import watchCollections from '~/containers/CollectionPage/saga';
import watchCollection from '~/containers/CollectionPageDetail/saga';
import watchExtraLicenseLandingPage from '~/containers/ExtraLicense/saga';
import watchUnsubscribeNewsletter from '~/containers/UnsubscribeNewsletterPage/saga';
import watchAccountType from '~/containers/AccountTypeProvider/saga';
import watchLogsRegisteredUsers from '~/containers/LogsRegisteredUsers/saga';
import watchCreatorProfile from '~/containers/CreatorProfilePage/saga';
import watchCreatorsPage from '~/containers/CreatorsPage/saga';
import watchResetPasswordPage from '~/containers/ResetPasswordPage/saga';
import watchExtraLicense from '~/containers/ExtraLicensePaymentModal/saga';
import watchPresentGiftMyPage from '~/containers/MyPage/Present/saga';
import watchComment from '~/containers/Comment/saga';

es6promise.polyfill();

export default function* rootSaga() {
  yield all([
    fork(watchHome),
    fork(watchMetadata),
    fork(watchAuth),
    fork(watchLoading),
    fork(watchPhoto),
    fork(watchSearchResult),
    fork(watchCategoryResult),
    fork(watchRecommendResult),
    fork(watchPopularResult),
    fork(watchNewResult),
    fork(watchNotice),
    fork(watchPresentGiftMyPage),
    fork(watchSimilarResult),
    fork(watchPricePremium),
    fork(watchTopTags),
    fork(watchTagDetail),
    fork(watchCustomErrorMessage),
    fork(watchPremiumPlan),
    fork(watchPremium),
    fork(watchCategories),
    fork(watchCollections),
    fork(watchCollection),
    fork(watchExtraLicense),
    fork(watchUnsubscribeNewsletter),
    fork(watchCreatorProfile),
    fork(watchAccountType),
    fork(watchLogsRegisteredUsers),
    fork(watchCreatorProfile),
    fork(watchCreatorsPage),
    fork(watchResetPasswordPage),
    fork(watchExtraLicenseLandingPage),
    fork(watchComment),
  ]);
}
