import PropTypes from 'prop-types';
import React from 'react';
import withI18next from '~/../hocs/_withI18next';
import LocalStorage from '~/services/LocalStorage';
import themeVariables from '~/shared/themeVariables';

@withI18next()
class Pattern extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setState({ show: true });
    const { patterns, onChangePattern, language, patternSelected } = this.props;
    const ids = patterns.map((item) => item.pattern_id);
    if (
      LocalStorage.selectedPatternId &&
      ids.indexOf(LocalStorage.selectedPatternId) >= 0
    ) {
      onChangePattern(LocalStorage.selectedPatternId, language);
    } else {
      onChangePattern(patternSelected, language);
    }
  }

  render() {
    const {
      patterns,
      onChangePattern,
      patternSelected,
      language,
      t,
    } = this.props;

    return (
      <div className="pattern-container">
        {patterns &&
          patterns.map((item, i) => (
            <div
              key={i}
              onClick={() => onChangePattern(item.pattern_id, language)}
            >
              <div
                className={`home-pattern-ui-item ${
                  patternSelected === item.pattern_id ? 'pattern-ui-active' : ''
                }`}
              />
            </div>
          ))}
        <style jsx>
          {`
            .pattern-container {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-left: 16px;
            }

            @media (max-width: ${themeVariables.breakpoints.mobile
                .maxWidth}px) {
              .pattern-container {
                margin: 0;
                position: relative;
              }
            }
            .pattern-container .pattern-list-title {
              font-size: 14px;
              font-weight: 500;
            }
            .pattern-container .home-pattern-ui-item {
              border-radius: 50%;
              width: 10px;
              height: 10px;
              display: inline-block;
              margin-left: 14px;
              cursor: pointer;
              background: #c4c4c4;
            }
            .pattern-container .home-pattern-ui-item.pattern-ui-active {
              background: ${themeVariables.colors.primary};
            }
            .pattern-container .pattern-ui-none {
              -webkit-animation: none;
              animation: none;
            }
            .pattern-container .design_home-tooltip-pattern {
              color: #fff !important;
              background-color: #ffa823 !important;
            }
            .pattern-container .ill_home-tooltip-pattern {
              color: #fff !important;
              background-color: #d73a4e !important;
            }
            .pattern-container .photo_home-tooltip-pattern {
              color: #fff !important;
              background-color: #509bdc !important;
            }
            .pattern-container .sil_home-tooltip-pattern {
              color: #fff !important;
              background-color: #41b9b8 !important;
            }
            .pattern-container .design_home-tooltip-pattern:after {
              border-top-color: #ffa823 !important;
            }
            .pattern-container .ill_home-tooltip-pattern:after {
              border-top-color: #d73a4e !important;
            }
            .pattern-container .photo_home-tooltip-pattern:after {
              border-top-color: #509bdc !important;
            }
            .pattern-container .sil_home-tooltip-pattern:after {
              border-top-color: #41b9b8 !important;
            }

            @-webkit-keyframes pulse-shadow--collection {
              0% {
                -webkit-box-shadow: 0 0;
                box-shadow: 0 0;
              }
              70% {
                -webkit-box-shadow: 0 0 5px 8px rgba(255, 255, 255, 0);
                box-shadow: 0 0 5px 8px rgba(255, 255, 255, 0);
              }
              100% {
                -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
              }
            }

            @keyframes pulse-shadow--collection {
              0% {
                -webkit-box-shadow: 0 0;
                box-shadow: 0 0;
              }
              70% {
                -webkit-box-shadow: 0 0 5px 8px rgba(255, 255, 255, 0);
                box-shadow: 0 0 5px 8px rgba(255, 255, 255, 0);
              }
              100% {
                -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
              }
            }
          `}
        </style>
      </div>
    );
  }
}

Pattern.propTypes = {
  patterns: PropTypes.array,
  patternSelected: PropTypes.any,
  onChangePattern: PropTypes.func,
};
Pattern.defaultProps = {
  patterns: [],
  patternSelected: 0,
  onChangePattern: () => {},
};
export default Pattern;
