import React from 'react';
import PropTypes from 'prop-types';

class DropdownMenu extends React.Component {
  render() {
    const { header, position, children, className, styleSubmenu } = this.props;

    return (
      <div className={`dropdown-menu__ac ${className || ''}`}>
        {header}
        <div className={`submenu-arrow-${position}`} />
        <div className={`submenu-wrapper submenu-wrapper-${position}`}>
          <div className="submenu" style={styleSubmenu || null}>
            {children}
          </div>
        </div>
        <style jsx global>
          {`
            .dropdown-menu__ac {
              display: inline-block;
              position: relative;
              cursor: pointer;
            }

            .dropdown-menu__ac .submenu-arrow-bottom {
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #ffffff;
              padding-top: 5px;
              position: absolute;
              display: none;
              margin-left: 25%;
              z-index: 101;
            }
            .dropdown-menu__ac .submenu-arrow-top {
              width: 0;
              height: 0;
              display: none;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid #fff;
              top: -10px;
              margin-left: 38%;
              position: absolute;
              z-index: 101;
            }
            .dropdown-menu__ac .submenu-arrow-left {
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #ffffff;
              position: absolute;
              display: none;
              z-index: 101;
              top: 50%;
            }
            .dropdown-menu__ac .submenu-wrapper {
              display: none;
              position: absolute;
              background: transparent;
              right: -50%;
              z-index: 90;
              bottom: 100%;
            }
            .dropdown-menu__ac .submenu-wrapper .submenu {
              min-width: 160px;
              background: #ffffff;
              color: #3e3e3f;
              z-index: 100;
              border-radius: 2px;
            }
            .dropdown-menu__ac .submenu-wrapper-top {
              padding-bottom: 14px;
              bottom: 15px;
            }
            .dropdown-menu__ac .submenu-wrapper-top .submenu {
              box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
              transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            }
            .dropdown-menu__ac .submenu-wrapper-bottom {
              padding-top: 14px;
            }
            .dropdown-menu__ac .submenu-wrapper-bottom .submenu {
              box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.175);
              transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            }
            .dropdown-menu__ac .submenu-wrapper-left {
              padding-right: 14px;
            }
            .dropdown-menu__ac .submenu-wrapper-left .submenu {
              box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.175);
              transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            }
            .dropdown-menu__ac .design_dropdown-item-active {
              background: #ffa823 !important;
              color: #ffffff !important;
            }
            .dropdown-menu__ac .ill_dropdown-item-active {
              background: #d73a4e !important;
              color: #ffffff !important;
            }
            .dropdown-menu__ac .photo_dropdown-item-active {
              background: #509bdc !important;
              color: #ffffff !important;
            }
            .dropdown-menu__ac .sil_dropdown-item-active {
              background: #41b9b8 !important;
              color: #ffffff !important;
            }
            .dropdown-menu__ac .dropdown-item {
              width: 50%;
              height: 34px;
              padding: 0 14px;
              display: flex;
              flex-direction: row;
              align-items: center;
              color: #3e3e3f;
            }

            .dropdown-menu__ac .submenu-wrapper-custom-footer {
              left: -10%;
            }

            .dropdown-menu__ac .dropdown-item:hover {
              background: rgba(204, 205, 206, 0.2);
            }
            .dropdown-menu__ac:hover .submenu-wrapper,
            .dropdown-menu__ac:hover .submenu-arrow-top,
            .dropdown-menu__ac:hover .submenu-arrow-bottom,
            .dropdown-menu__ac:hover .submenu-arrow-left {
              display: block;
            }
            .dropdown-menu__ac .dropdown-item-more:hover {
              background: rgba(204, 205, 206, 0.2);
            }
          `}
        </style>
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  header: PropTypes.any,
  children: PropTypes.any,
  position: PropTypes.string,
};

DropdownMenu.defaultProps = {
  position: 'bottom',
};

export default DropdownMenu;
