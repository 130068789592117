import React from 'react';
import css from 'styled-jsx/css';
import ACImage from '~/components/ACImage';
import I18nLink from '~/components/I18nLink';
import themeVariables from '~/shared/themeVariables';

const CategoryItem = ({ category }) => {
  return (
    <div className="category-item-container">
      <I18nLink href={`/category/${category.category_slug}`}>
        <a className="category-image" target="_blank">
          <ACImage
            src={category.categoryImage}
            className="img-thumbnail"
            alt={category.category_name}
          />
        </a>
      </I18nLink>
      {/* </div> */}
      <I18nLink href={`/category/${category.category_slug}`}>
        <a target="_blank">
          <div className="category-name">{category.category_name}</div>
        </a>
      </I18nLink>
      <style jsx>{styles}</style>

      <style jsx global>
        {`
          .img-thumbnail {
            position: relative;
            width: 306px;
            height: 180px;
            object-fit: cover;
            border-radius: 4px;
          }
          .img-thumbnail:hover {
            opacity: 0.8;
          }
          @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
          }
        `}
      </style>
    </div>
  );
};
const styles = css`
  .category-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .category-item {
    position: relative;
    text-align: center;
    margin-right: 8px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    width: 306px;
  }
  .category-image {
    position: relative;
    text-align: center;
    margin-right: 8px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    width: 180px;
    height: 120px;
  }
  .category-name {
    display: flex;
    justify-content: center;
    color: #000000;
    font-weight: 500;
    margin-top: 8px;
    filter: none;
  }

  @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
    .category-image {
      width: 100%;
      height: 150px;
      margin-right: 0;
    }
  }

  @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
    .category-image {
      width: 100%;
      height: 200px;
      margin-right: 0;
    }
  }
`;

export default CategoryItem;
