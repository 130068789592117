import axios from '../interceptor-axios';
import config from '~/config';

function save(model) {
  const request = {
    method: 'POST',
    url: `${config.apiUrl.apiUser}/model/release`,
    data: {
      name: model.name,
      s3_path: model.path,
      basename: model.basename,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function getModels(page, max_results, filter = {}) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/model/release`,
    params: {
      page,
      keyword: filter.keyword,
      createdAt: filter.createdAt,
      max_results,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

function getDataByModelId(params) {
  const sortByPage = params.sortByPage.split('.');
  const paramsRequest = {
    id: params.id,
    page: params.page,
    status: params.status,
    createdAt: params.createdAt,
    max_results: params.itemPerPage,
    service_type: config.serviceType,
  };

  paramsRequest[sortByPage[0]] = sortByPage[1];

  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiUser}/model/release/data`,
    params: paramsRequest,
  };
  return axios(request);
}

function update(model) {
  const request = {
    method: 'PUT',
    url: `${config.apiUrl.apiUser}/model/release`,
    data: {
      id: model.id,
      name: model.name,
      s3_path: model.path,
      basename: model.basename,
      service_type: config.serviceType,
    },
  };
  return axios(request);
}

export { save, getModels, getDataByModelId, update };
