export default class EventClick {
  static dispatchEvent(name) {
    const event = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': false
    });
    const node = document.getElementById(name);
    if (!node) {
      return;
    }
    node.dispatchEvent(event);
  }
}