import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

class Captcha extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = value => {
    this.props.onChange(value);
  };

  render() {
    return (
      <ReCAPTCHA
        sitekey={process.env.GOOGLE_CAPTCHA_SITE_KEY}
        onChange={this.onChange}
      />
    );
  }
}

Captcha.defaultProps = {
  onChange: ({}) => ({}),
};

Captcha.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Captcha;
