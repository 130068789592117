import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { withI18next } from '~/../hocs/_withI18next';

@withI18next()
class UpdateListFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        status: '',
        fileType: '',
        sortByPage: 'createdAt.desc',
        itemPerPage: 10,
        keyword: '',
      },
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.handleFilter(Object.assign({}, this.state.filter));
  };

  handleChangeKeyword = (event) => {
    this.setState({
      filter: Object.assign({}, this.state.filter, {
        keyword: event.target.value,
      }),
    });
  };

  handleChangeInput = (name, event) => {
    const filter = Object.assign({}, this.state.filter, {
      [name]: event.target.value,
    });
    this.setState(
      {
        filter: Object.assign({}, this.state.filter, {
          [name]: event.target.value,
        }),
      },
      this.handleFilter(filter),
    );
  };

  handleFilter(filter) {
    const sortByPage = filter.sortByPage.split('.');
    delete filter.sortByPage;
    filter[sortByPage[0]] = sortByPage[1];
    return this.props.triggerEventChangeFilter(filter);
  }

  render() {
    const { t, placeholder } = this.props;
    const { filter } = this.state;

    return (
      <div className="row">
        <div className="col-sm-7 form-group">
          <form onSubmit={this.handleSubmit} className="input-group">
            <input
              type="text"
              className="form-control input-acphoto"
              style={{ height: 42 }}
              value={filter.keyword}
              placeholder={placeholder}
              onChange={this.handleChangeKeyword}
            />
            <button
              className="btn mypage-btn-primary mp-btn-search"
              type="submit"
            >
              <FontAwesomeIcon icon={faSearch} style={{ width: '1em' }} />
            </button>
          </form>
        </div>
        <div className="col-sm-5 text-right form-group">
          <Dropdown className="config" pullRight id="control-number-filter">
            <Dropdown.Toggle bsStyle="link" bsSize="lg">
              <FontAwesomeIcon icon={faCog} style={{ width: '1em' }} />
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ padding: '15px', minWidth: this.props.minWidth }}
              className="menu-list"
            >
              <ul className="list-unstyled">
                <li className="row">
                  <div className="col-xs-6">{t('sort_by_page')}</div>
                  <div className="col-xs-6">
                    <label role="button">
                      <input
                        name="sort_by_page"
                        type="radio"
                        value="createdAt.desc"
                        checked={filter.sortByPage == 'createdAt.desc'}
                        onChange={this.handleChangeInput.bind(
                          this,
                          'sortByPage',
                        )}
                      />
                      &nbsp;
                      {t('sort_by_newest_registration')}
                    </label>
                    <br />
                    <label role="button">
                      <input
                        name="sort_by_page"
                        type="radio"
                        value="createdAt.asc"
                        checked={filter.sortByPage == 'createdAt.asc'}
                        onChange={this.handleChangeInput.bind(
                          this,
                          'sortByPage',
                        )}
                      />
                      &nbsp;
                      {t('old_order_of_registration')}
                    </label>
                  </div>
                </li>
                <li className="row">
                  <div className="col-xs-6">{t('item_per_page')}</div>
                  <div className="col-xs-6">
                    <label role="button">
                      <input
                        name="itemPerPage"
                        type="radio"
                        value={10}
                        checked={filter.itemPerPage == 10}
                        onChange={this.handleChangeInput.bind(
                          this,
                          'itemPerPage',
                        )}
                      />
                      &nbsp;
                      <span className="pl5">10</span>
                    </label>
                    <br />
                    <label role="button">
                      <input
                        name="itemPerPage"
                        type="radio"
                        value={50}
                        checked={filter.itemPerPage == 50}
                        onChange={this.handleChangeInput.bind(
                          this,
                          'itemPerPage',
                        )}
                      />
                      &nbsp;
                      <span className="pl5">50</span>
                    </label>
                    <br />
                    <label role="button">
                      <input
                        name="itemPerPage"
                        type="radio"
                        value={100}
                        checked={filter.itemPerPage == 100}
                        onChange={this.handleChangeInput.bind(
                          this,
                          'itemPerPage',
                        )}
                      />
                      <span className="pl5">100</span>
                    </label>
                  </div>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <style jsx>
          {`
            .input-group {
              display: flex;
              flex-direction: row;
            }

            .mp-btn-search {
              height: 42px;
              margin-left: 8px;
            }
          `}
        </style>
        <style jsx global>
          {`
            .update-list-btn-search:hover {
              color: #fff;
            }

            @media (min-width: 768px) {
              .mypage-property-release-modal .modal-body {
                padding-left: 25px;
                padding-right: 25px;
              }

              .mypage-property-release-modal .modal-dialog {
                width: 450px;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

UpdateListFilter.defaultProps = {
  minWidth: '310px',
  triggerEventChangeFilter: () => ({}),
};

export default UpdateListFilter;
