import React from 'react';
import Config from '~/config';
import themeVariables from '~/shared/themeVariables';
import Container from '~/components/Container';
import IconStar from '~/components/svg/icon-star.svg';

const ContentDaily = (props) => {
  const { t, isAuthenticated, lng } = props;

  let linkPreviousGiftPacks = 'https://www.freebie-ac.jp/index.php?lang=en';
  switch (lng) {
    case 'tw':
      linkPreviousGiftPacks = 'https://www.freebie-ac.jp/index.php?lang=tw';
      break;
    case 'ko':
      linkPreviousGiftPacks = 'https://www.freebie-ac.jp/index.php?lang=ko';
      break;
    default:
      linkPreviousGiftPacks = 'https://www.freebie-ac.jp/index.php?lang=en';
  }

  const getSSORedirectURL = (lng) => {
    let redirectURL = Config.getSiteURL();

    // if (
    //   redirectURL.includes('creator-registration') ||
    //   redirectURL.includes('login')
    // ) {
    //   redirectURL = Config.getSiteURL();
    // }
    return redirectURL;
  };

  return (
    <div className="container-content-daily">
      <Container>
        <div className="container-content">
          <div className="container-item">
            <IconStar />
            <div className="title">
              {t(Config.getPrefixContent('text_title_daily_01'))}
            </div>
            <div className="text-content">
              {t(Config.getPrefixContent('text_content_daily_01'))}
            </div>
          </div>
          <div className="container-item">
            <IconStar />
            <div className="title">{t('text_title_daily_02')}</div>
            <div
              className="text-content"
              dangerouslySetInnerHTML={{
                __html: t(Config.getPrefixContent('text_content_daily_02'), {
                  link1: 'terms',
                  link2: 'help-page?tab=usage_guides',
                }),
              }}
            ></div>
          </div>
          {!isAuthenticated && (
            <div className="container-item">
              <IconStar />
              <div className="title">{t('text_title_daily_03')}</div>
              <div
                className="text-content"
                dangerouslySetInnerHTML={{
                  __html: t('text_content_daily_03', {
                    link: `${
                      Config.ssoServerUrl
                    }/signup?lang=${lng}&serviceURL=${encodeURIComponent(
                      getSSORedirectURL(lng),
                    )}`,
                  }),
                }}
              ></div>
            </div>
          )}

          {isAuthenticated && (
            <div className="container-item">
              <IconStar />
              <div className="title">{t('text_title_daily_03')}</div>
              <div
                className="text-content"
                dangerouslySetInnerHTML={{
                  __html: t('text_content_daily_04', {
                    link: linkPreviousGiftPacks,
                  }),
                }}
              ></div>
            </div>
          )}
        </div>
      </Container>
      <style jsx>
        {`
          .container-content-daily {
            margin-top: 48px;
            margin-bottom: 48px;
          }
          .container-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 24px;
          }
          .container-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
          .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            margin-top: 24px;
            text-align: center;
          }
          .text-content {
            font-weight: 500;
            font-size: 16px;
            align-items: center;
            text-align: center;
            margin-top: 24px;
            colort: #1b2833;
          }
          @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
            .container-content {
              flex-direction: column;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          .link-to-daily {
            font-weight: 600;
          }
          .link-to-daily:hover {
            text-decoration: underline !important;
            opacity: 0.8;
            color: ${themeVariables.colors.link};
          }
        `}
      </style>
    </div>
  );
};

export default ContentDaily;
