export const LOAD_DATA_CATEGORY = 'containers/CategoryPage/LOAD_DATA_CATEGORY';
export const LOAD_DATA_CATEGORY_OLD =
  'containers/CategoryPage/LOAD_DATA_CATEGORY_OLD';
export const LOAD_DATA_CATEGORY_SUCCESS =
  'containers/CategoryPage/LOAD_DATA_CATEGORY_SUCCESS';
export const LOAD_DATA_CATEGORY_ERROR =
  'containers/CategoryPage/LOAD_DATA_CATEGORY_ERROR';
export const LOAD_DATA_CATEGORY_SIMILAR =
  'containers/CategoryPage/LOAD_DATA_CATEGORY_SIMILAR';

export const FILTER_ORDER_BY = 'containers/CategoryPage/FILTER_ORDER_BY';
export const FILTER_PER_PAGE = 'containers/CategoryPage/FILTER_PER_PAGE';

export const CHANGE_PAGE = 'containers/CategoryPage/CHANGE_PAGE';
export const NICE_PHOTO = 'containers/CategoryPage/NICE_PHOTO';
export const NICE_PHOTO_SUCCESS = 'containers/CategoryPage/NICE_PHOTO_SUCCESS';
export const REMOVE_NICE_PHOTO_SUCCESS =
  'containers/CategoryPage/REMOVE_NICE_PHOTO_SUCCESS';
export const NICE_PHOTO_ERROR = 'containers/CategoryPage/NICE_PHOTO_ERROR';
