import { put, takeEvery } from 'redux-saga/effects';
import { CHANGE_ACCOUNT_TYPE, CHANGE_ACCOUNT_TYPE_SUCCESS } from './constants';
import Cookies from '~/services/Cookies';


function* accountChange(params) {
  const { accountType } = params;
  Cookies.set('accountType', accountType);
  yield put({ type: CHANGE_ACCOUNT_TYPE_SUCCESS, accountType });
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* accountType() {
  yield takeEvery(CHANGE_ACCOUNT_TYPE, accountChange);
}
