import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentLanguage } from '~/containers/Language/selectors';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from '~/containers/AuthProvider/selectors';
import {
  initCountdown,
  updateCountdown,
} from '~/containers/PaymentModal/actions';
import { makeSelectCountdownDiscount } from '~/containers/PaymentModal/selectors';
import I18nLink from '~/components/I18nLink';
import themeVariables from '~/shared/themeVariables';

@withI18next()
class BannerDiscount extends Component {
  handleTickCountdown = ({ total }) => {
    const { updateCountdown } = this.props;
    updateCountdown(total);
  };

  handleCompleteCountdown = () => {
    const { updateCountdown } = this.props;
    updateCountdown(0);
  };

  handleLoginSSO = () => {
    const { lng } = this.props;
    const domainURL = Config.getSiteURL();
    let premiumPageURL = `${domainURL}/premium`;
    // if (lng !== 'en') {
    //   premiumPageURL = `${domainURL}/${lng}/premium`;
    // }
    const url = `${
      Config.ssoServerUrl
    }/login?lang=${lng}&serviceURL=${encodeURIComponent(premiumPageURL)}`;
    window.open(url);
  };

  renderCountdown = (
    { days, hours, minutes, seconds, completed },
    typeCountdown,
    discount,
  ) => {
    const {
      t,
      // countdown
    } = this.props;
    // const { percentOff } = countdown;
    // console.log("--- typeCountdown ---", typeCountdown);
    let discountText;
    if (typeCountdown == 'FIRST_TIME') {
      discountText = t('discount_annual_plan', {
        discount,
      });
    }
    if (typeCountdown == 'SECOND_TIME') {
      discountText = t('countdown_limit_price');
    }

    return (
      <div className="container">
        <div
          className="count-down-format-campaign"
          dangerouslySetInnerHTML={{
            __html: discountText,
          }}
        ></div>
        <div
          className="text-center"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <div className="count-down-format">
            {typeCountdown == 'SECOND_TIME' && (
              <div className="count-down-format-item">
                {days}{' '}
                <span className="day---hidden">{t('sale_time_1')} &nbsp;</span>
              </div>
            )}
            {days > 0 && (
              <div className="count-down-format-item">
                {days}{' '}
                <span className="day---hidden">{t('sale_time_1')} &nbsp;</span>
              </div>
            )}

            <div className="count-down-format-item">
              {hours}{' '}
              <span className="day---hidden">{t('sale_time_2')} &nbsp;</span>
            </div>
            <div className="count-down-format-item">
              {minutes}{' '}
              <span className="day---hidden">{t('sale_time_3')} &nbsp;</span>
            </div>
            <div className="count-down-format-item">
              {seconds} <span className="day---hidden">{t('sale_time_4')}</span>
            </div>
          </div>
        </div>
        <div className="count-down-format-item link-premium-wrapper">
          <I18nLink href={{ pathname: '/premium' }}>
            <a className="link-premium">{t('go_premium')}</a>
          </I18nLink>
        </div>
        <style jsx>
          {`
            .link-premium {
              padding: 4px 12px;
              text-transform: uppercase;
              font-weight: 600;
            }
            @media (max-width: ${themeVariables.breakpoints.mobile
                .maxWidth}px) {
              .count-down-format {
                display: none;
              }
              .container {
                display: block;
              }
              .count-down-format-item.link-premium-wrapper {
                width: 132px;
                height: 44px;
                margin: 12px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .link-premium {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0;
              }
              .count-down-format-campaign {
                font-size: 32px;
                margin-left: 20px;
                margin-top: 12px;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .text-discount {
              font-size: 32px;
              margin: 0 4px;
              font-weight: 600;
            }
          `}
        </style>
      </div>
    );
  };

  render() {
    const { countdown, profile } = this.props;

    if (!countdown) return null;

    const {
      remainingDurationInMilliseconds,
      typeCountdown,
      coupon,
    } = countdown;

    if (remainingDurationInMilliseconds === 0) {
      return null;
    }

    const futureDate = Date.now() + remainingDurationInMilliseconds;
    // console.log(
    //   'remainingDurationInMillisecondsss',
    //   remainingDurationInMilliseconds,
    //   futureDate,
    // );

    return (
      <div
        className={`${
          !(profile.role !== Config.getRoles().PREMIUM)
            ? 'display-none'
            : 'banner-discount'
        }`}
      >
        <Countdown
          date={futureDate}
          renderer={({ days, hours, minutes, seconds, completed }) =>
            this.renderCountdown(
              {
                days,
                hours,
                minutes,
                seconds,
                completed,
              },
              typeCountdown,
              coupon && coupon.percent_off,
            )
          }
          onTick={this.handleTickCountdown}
          onComplete={this.handleCompleteCountdown}
        />
        <style jsx global>
          {`
            .banner-discount {
              min-height: 60px;
              width: 100%;
              top: 0;
              background: ${themeVariables.colors.bodyText};
              // background: url(${Config.staticPrefix}/static/images/campaign/christmas-background.jpg)
              // repeat center center;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .banner-discount .container {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
            }
            .banner-discount .container .count-down-format-campaign {
              display: flex;
              align-items: center;
            }
            .banner-discount .container .count-down-format-item {
              padding: 4px 8px;
              background: #fff;
              margin: 0 4px;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              text-wrap: nowrap;
              color: ${themeVariables.colors.bodyText};
            }
            .banner-discount .container .count-down-format-item a {
              color: ${themeVariables.colors.bodyText};
            }
            .banner-discount .container .link-premium-wrapper {
              background: #ffa823;
            }
            .banner-discount .container .link-premium-wrapper .link-premium {
              color: #fff;
            }
            .banner-discount .discount-percent {
              background-image: url(/static/assets/image/premium/register-premium/sale-off.svg);
              background-position: center;
              background-repeat: no-repeat;
              width: 50px;
              height: 50px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              font-size: 18px;
              margin-left: 7px;
              margin-right: 7px;
              align-items: center;
              font-weight: 700;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  countdown: makeSelectCountdownDiscount(),
  currentLanguage: makeSelectCurrentLanguage(),
  profile: makeSelectProfile(),
  isAuthenticated: makeSelectIsAuthenticated(),
});
const mapDispatchToProps = (dispatch) => ({
  initCountdown: () => dispatch(initCountdown()),
  updateCountdown: (remainingDuration) =>
    dispatch(updateCountdown(remainingDuration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerDiscount);
