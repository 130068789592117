import { combineReducers } from 'redux';
import {
  SEARCH_PHOTO,
  SEARCH_PHOTO_SUCCESS,
  SEARCH_PHOTO_ERROR,
  FILTER_PER_PAGE,
  FILTER_ORDER_BY,
  CHANGE_PAGE,
} from './constants';

export const initialState = {
  photos: [],
  suggest: [],
  has_next: false,
  page: 1,
  totalPage: 0,
  totalItem: 0,
  error: false,
  loading: false,
};

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_PHOTO:
      return {
        ...state,
        query: {
          category: action.payload.category || '',
          excludeCategory: action.payload.excludeCategory || '',
          excludeKeyword: action.payload.excludeKeyword || '',
          keyword: action.payload.keyword || '',
          per_page: action.payload.per_page || 100,
          page: action.payload.page || 1,
          order_by: action.payload.order_by || 'popular',
          is_tag: action.payload.is_tag || false,
        },
        loading: true,
        error: false,
      };

    case SEARCH_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        photos: action.collection,
        suggest: action.suggest,
        has_next: action.has_next,
        page: action.page,
        totalPage: action.total_page,
        totalItem: action.total_item,
      };

    case SEARCH_PHOTO_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };

    default:
      return state;
  }
}

const filterInitState = {
  orderBy: 'popular',
  perPage: '100',
};

function filterReducer(state = filterInitState, action) {
  switch (action.type) {
    case FILTER_ORDER_BY:
      return {
        ...state,
        orderBy: action.value,
      };
    case FILTER_PER_PAGE:
      return {
        ...state,
        perPage: action.value,
      };

    default:
      return state;
  }
}

export default combineReducers({
  search: searchReducer,
  filter: filterReducer,
});
