import { takeLatest, call, put } from 'redux-saga/effects';
import { SIMULATE_ERROR, CHECK_IE_BROWSER } from './constants';
import { showError, showIEWarning } from './actions';
import * as authService from '~/services/auth';
import { device } from '~/utils';
import Cookies from '~/services/Cookies';

function* simulateError(action) {
  try {
    const { code } = action;
    const result = yield call(authService.simulateError, code);
    console.log('result', result);
  } catch (error) {
    yield put(showError({ error }));
  }
}

function* checkIEBrowser() {
  if (process.browser) {
    if (Cookies.get('warningIE')) return;
    Cookies.set('warningIE', true);
    if (!device.isEdgeOrIE()) return;
    yield put(showIEWarning());
  }
}

export default function* customErrorMessageSaga() {
  yield takeLatest(SIMULATE_ERROR, simulateError);
  yield takeLatest(CHECK_IE_BROWSER, checkIEBrowser);
}
