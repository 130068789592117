import axios from './interceptor-axios';
import config from '~/config';

function getCollections(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiPhoto}/collections/name`,
    params: {
      lang: params.lang || config.language,
      page: params.page,
      perpage: params.perpage,
    },
  };
  return axios(request);
}
function getCollectionDetailById(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiPhoto}/collection/detail`,
    params: {
      lang: params.lang || config.language,
      id: params.id,
    },
  };
  return axios(request);
}

function getCollectionOfMonths(params) {
  const request = {
    method: 'GET',
    url: `${config.apiUrl.apiPhoto}/collection/months`,
    params: {
      lang: params.lang || config.language,
    },
  };
  return axios(request);
}
/**
 *
 * @param params
 * @returns {Promise}
 */

export { getCollections, getCollectionDetailById, getCollectionOfMonths };
