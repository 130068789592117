import React from 'react';
import PropTypes from 'prop-types';
import Config from '~/config';

class BtnToggle extends React.Component {
  turnOn = () => {
    this.props.handleChange(this.props.valueOn);
  };

  turnOff = () => {
    this.props.handleChange(this.props.valueOff);
  };

  render() {
    const { active, valueOn, valueOff, textOn, textOff } = this.props;
    return (
      <div
        className={`btn-group btn-group-justified btn-toggle-${Config.serviceType}`}
        role="group"
      >
        <div className="btn-group" role="group">
          <button
            style={{ border: 'none' }}
            type="button"
            onClick={this.turnOn}
            className={`btn btn-default ${
              !!active === valueOn ? 'btn-ac on' : 'off'
            }`}
          >
            {textOn}
          </button>
        </div>
        <div className="btn-group" role="group">
          <button
            style={{ border: 'none' }}
            type="button"
            onClick={this.turnOff}
            className={`btn btn-default ${
              !!active === valueOff ? 'btn-ac on' : 'off'
            }`}
          >
            {textOff}
          </button>
        </div>
        <style jsx global>
          {`
            .btn-group.btn-toggle-illust_ac .on {
              background-color: rgb(215, 58, 78);
              color: #fff;
            }
            .btn-group.btn-toggle-design_ac .on {
              background-color: rgb(255, 168, 35);
              color: #fff;
            }
            .btn-group.btn-toggle-photo_ac .on {
              background-color: rgb(80, 155, 220);
              color: #fff;
            }
            .btn-group.btn-toggle-silhouette_ac_ac .on {
              background-color: rgb(65, 185, 184);
              color: #fff;
            }
            .btn-group.btn-toggle-illust_ac .off {
              background-color: #dedede;
              color: #fff;
            }
            .btn-group.btn-toggle-photo_ac .off {
              background-color: #dedede;
              color: #fff;
            }
            .btn-group.btn-toggle-silhouette_ac .off {
              background-color: #dedede;
              color: #fff;
            }
            .btn-group.btn-toggle-design_ac .off {
              background-color: #dedede;
              color: #fff;
            }
            .btn-group.btn-toggle .btn:last-child {
              border-left: 0;
            }

            .btn-group.btn-toggle .btn:hover,
            .btn-group.btn-toggle .btn:active,
            .btn-group.btn-toggle .btn:focus,
            .btn-group.btn-toggle .btn {
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
              border-color: #ccc;
            }
          `}
        </style>
      </div>
    );
  }
}

BtnToggle.defaultProps = {
  textOn: 'On',
  textOff: 'Off',
  active: false,
  valueOn: true,
  valueOff: false,
};

BtnToggle.propTypes = {
  textOn: PropTypes.string.isRequired,
  valueOn: PropTypes.any.isRequired,
  textOff: PropTypes.string.isRequired,
  valueOff: PropTypes.any.isRequired,
  active: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default BtnToggle;
