export const NICE_PHOTO = 'components/Gallery/NICE_PHOTO';
export const NICE_PHOTO_SUCCESS = 'components/Gallery/NICE_PHOTO_SUCCESS';
export const REMOVE_NICE_PHOTO_SUCCESS =
  'components/Gallery/REMOVE_NICE_PHOTO_SUCCESS';
export const NICE_PHOTO_ERROR = 'components/Gallery/NICE_PHOTO_ERROR';
export const IS_CATEGORY_PAGE = 'components/Gallery/IMAGE/IS_CATEGORY_PAGE';
export const IS_COLLECTION_PAGE = 'components/Gallery/IMAGE/IS_COLLECTION_PAGE';
export const IS_RECOMMEND_PAGE = 'components/Gallery/IMAGE/IS_RECOMMEND_PAGE';
export const IS_SEARCH_RESULT_PAGE =
  'components/Gallery/IMAGE/IS_SEARCH_RESULT_PAGE';
