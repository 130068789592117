import Link from 'next/link';
import React from 'react';
import { connect } from 'react-redux';
import { locales } from '~/utils';
import Config from '~/config';
import { supportedLanguages } from '~/../next-i18next.config';

@connect((state) => ({
  currentLanguage: state.language.currentLanguage,
  isLocaleInPath: state.metadata.isLocaleInPath,
}))
class I18nLink extends React.Component {
  render() {
    let {
      host,
      currentLanguage,
      href = '',
      useI18n = true,
      as,
      children,
      language,
      isLocaleInPath,
      // dispatch, // "dispatch" is unknown props for next/link component
      // className, // "className" is unknown props for next/link component
      ...props
    } = this.props;
    const lng = language || currentLanguage;

    let asLink;
    const i18nRegex = new RegExp(`^/(${supportedLanguages.join('|')})/`);
    if (Config.isChina()) {
      asLink = as || href;
      if (i18nRegex.test(asLink)) asLink = asLink.substring(3);
    } else {
      asLink = as || href;
      if (useI18n) {
        let rawAsLink = '';
        if (as) {
          rawAsLink = as;
        } else {
          rawAsLink = href.pathname === undefined ? href : href.pathname;
        }
        if (i18nRegex.test(rawAsLink)) rawAsLink = rawAsLink.substring(3);
        // if (lng === 'en' && !isLocaleInPath) {
        //   asLink = rawAsLink.startsWith('/') ? `${rawAsLink}` : `/${rawAsLink}`;
        // } else {
        asLink = `${
          rawAsLink.startsWith('/') ? `${rawAsLink}` : `/${rawAsLink}`
        }`;
        if (isLocaleInPath) {
          asLink = `/${locales.convertToShortType(lng)}${asLink}`;
          if (typeof href === 'string') {
            href = `/${locales.convertToShortType(lng)}${href}`;
          }
        }
        // }
      }
    }

    if (host) {
      return (
        <Link
          passHref
          {...props}
          href={`${host}${href}`}
          as={asLink === href ? undefined : `${host}${asLink}`}
          prefetch={false}
          locale={false}
        >
          {children}
        </Link>
      );
    }

    return (
      <Link
        passHref
        {...props}
        href={href}
        as={asLink === href ? undefined : asLink}
        prefetch={false}
        locale={false}
      >
        {children}
      </Link>
    );
  }
}

export default I18nLink;
