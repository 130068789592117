import { createSelector } from 'reselect';

const selectNew = state => state.newPage.result;

const selectFilter = state => state.newPage.filter;

const makeSelectDataType = () => createSelector(
  selectNew,
  newState => newState.dataType,
);

const makeSelectPhotos = () => createSelector(
  selectNew,
  newState => newState.photos,
);

const makeSelectLoading = () => createSelector(
  selectNew,
  newState => newState.loading,
);

const makeSelectError = () => createSelector(
  selectNew,
  newState => newState.error,
);

const makeSelectPage = () => createSelector(
  selectNew,
  newState => newState.page,
);

const makeSelectTotalPage = () => createSelector(
  selectNew,
  newState => newState.totalPage,
);

const makeSelectTotalItem = () => createSelector(
  selectNew,
  newState => newState.totalItem,
);

const makeSelectPerPage = () => createSelector(
  selectFilter,
  filterState => filterState.perPage,
);

export {
  selectNew,
  makeSelectPhotos,
  makeSelectError,
  makeSelectPage,
  makeSelectTotalPage,
  makeSelectTotalItem,
  makeSelectPerPage,
  makeSelectLoading,
  makeSelectDataType,
};
