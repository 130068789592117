import React, { useRef } from 'react';
import css from 'styled-jsx/css';
import Blog from './Blog';
import DataBlog from './data';
import Config from '~/config';
import Container from '~/components/Container';
import themeVariables from '~/shared/themeVariables';

const Blogs = (props) => {
  const { lng, t, blogs } = props;

  return (
    <>
      <Container className={containerHeader}>
        <div className="title-trending">
          <p>{t('latest_blog')}</p>
          <a href={Config.renderLinkBlog(lng)} target="_blank" rel="noreferrer">
            {t('view_all')}
          </a>
        </div>
      </Container>
      <Container className={containerClassName}>
        <div className="content-blog-container">
          {/* Migrating blog settings from frontend to database, so need to fallback to default blog posts if setting from database is missing */}
          {blogs.length >= 3
            ? blogs.map((data, idx) => {
                return (
                  <Blog
                    key={idx}
                    urlImg={data.thumbnail}
                    title={data.title}
                    summary={data.summary}
                    link={data.url_blog}
                  />
                );
              })
            : DataBlog.getData(lng).map((data, idx) => {
                return (
                  <Blog
                    key={idx}
                    urlImg={data.src}
                    title={data.title}
                    summary={data.summary}
                    link={data.link}
                  />
                );
              })}
        </div>
      </Container>
      <style jsx>{styles}</style>
      {containerStyles}
      {containerStylesHeader}
    </>
  );
};
const { className: containerClassName, styles: containerStyles } = css.resolve`
  position: relative;
  margin-top: 16px;
  @media (max-width: 500px) {
    background: none;
  }
`;
const {
  className: containerHeader,
  styles: containerStylesHeader,
} = css.resolve`
  margin-top: 20px;
  // @media (max-width: 500px) {
  //   background: #f8f9fa;
  // }
`;
const styles = css`
  .content-trending {
    position: relative;
  }
  .title-trending {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .title-trending p {
    font-weight: 600;
    font-size: 24px;
  }
  .title-trending a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
  .title-trending a:hover {
    text-decoration: underline !important;
    color: ${themeVariables.colors.link} !important;
    opacity: 0.8;
  }
  .content-blog-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
  }

  @media (max-width: ${themeVariables.breakpoints.tabletL.maxWidth}px) {
    .content-blog-container {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      justify-content: center;
    }
  }

  @media (max-width: ${themeVariables.breakpoints.tabletS.maxWidth}px) {
    .content-blog-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      justify-content: center;
    }
  }

  @media (max-width: ${themeVariables.breakpoints.mobile.maxWidth}px) {
    .content-blog-container {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      justify-content: center;
    }
  }
`;

export default Blogs;
