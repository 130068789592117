import { combineReducers } from 'redux';
// import Immutable from 'seamless-immutable';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  MODAL_SUBMIT,
  SHOW_LOADING_FULL_SCREEN,
  HIDE_LOADING_FULL_SCREEN,
} from './constants';

const initialState = {
  showModal: false,
};

function invoiceCustomizeModalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        showModal: true,
      };

    case MODAL_SUBMIT:
    case CLOSE_MODAL:
      return {
        showModal: false,
      };

    default:
      return state;
  }
}

export const initialStateLoad = {
  fullScreen: false,
  message: '',
};

function loadDisplay(state = initialStateLoad, action) {
  switch (action.type) {
    case SHOW_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: true,
      };
    case HIDE_LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  load: loadDisplay,
  modal: invoiceCustomizeModalReducer,
});
