import {
  OPEN_MODAL,
  CLOSE_MODAL,
  MODAL_SUBMIT,
  LOAD_PRICE_PREMIUM,
  SELECTED_PRICE_PREMIUM,
  SELECTED_SINGLE_PRICE_PREMIUM,
  SHOW_LOADING_FULL_SCREEN,
  HIDE_LOADING_FULL_SCREEN,
  CREATE_BILLING_AGREEMENT,
  UPGRADE_PREMIUM_WITH_CREDIT,
  UPGRADE_SINGLE_PREMIUM_WITH_CARD,
  INIT_COUNTDOWN,
  UPDATE_COUNTDOWN,
  LOAD_SINGLE_PRICE_PREMIUM,
  CREATE_PAYMENT_INTENT_SINGLE_PREMIUM,
  SHOW_LOADING_FULL_SCREEN_WECHAT,
  SHOW_LOADING_FULL_SCREEN_CARD,
  INIT_POLLING_PAYMENT_INTENT,
  STOP_POLLING_PAYMENT_INTENT,
} from './constants';

// This area is for logging modal

export function openModal(index) {
  return {
    type: OPEN_MODAL,
    index,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function modalSubmit() {
  return {
    type: MODAL_SUBMIT,
  };
}

// This area is for premium payment

export function loadPricePremium(request) {
  return {
    type: LOAD_PRICE_PREMIUM,
    request,
  };
}

export function loadSinglePricePremium(request) {
  return {
    type: LOAD_SINGLE_PRICE_PREMIUM,
    request,
  };
}

export function selectedPremium(plan) {
  return {
    type: SELECTED_PRICE_PREMIUM,
    plan,
  };
}

export function selectedSinglePremium(plan) {
  return {
    type: SELECTED_SINGLE_PRICE_PREMIUM,
    plan,
  };
}

export function showLoadingFullScreen() {
  return {
    type: SHOW_LOADING_FULL_SCREEN,
  };
}

export function showLoadingFullScreenCard() {
  return {
    type: SHOW_LOADING_FULL_SCREEN_CARD,
  };
}

export function showLoadingFullScreenWeChat(wechat) {
  return {
    type: SHOW_LOADING_FULL_SCREEN_WECHAT,
    wechat,
  };
}

export function hideLoadingFullScreen() {
  return {
    type: HIDE_LOADING_FULL_SCREEN,
  };
}

export function createBillingAgreement(params) {
  return {
    type: CREATE_BILLING_AGREEMENT,
    ...params,
  };
}

export function upgradePremiumWithCredit(params) {
  return {
    type: UPGRADE_PREMIUM_WITH_CREDIT,
    ...params,
  };
}

export function upgradeSinglePremiumWithCard(params) {
  return {
    type: UPGRADE_SINGLE_PREMIUM_WITH_CARD,
    ...params,
  };
}

export function createPaymentIntentOfSinglePremium(plan) {
  return {
    type: CREATE_PAYMENT_INTENT_SINGLE_PREMIUM,
    plan,
  };
}

export function initCountdown(request) {
  return {
    type: INIT_COUNTDOWN,
    request
  };
}

export function updateCountdown(remainingDurationInMilliseconds) {
  return {
    type: UPDATE_COUNTDOWN,
    remainingDurationInMilliseconds,
  };
}

export function initPollingPaymentIntentStatus(pollingPaymentIntent) {
  console.log(pollingPaymentIntent);
  return {
    type: INIT_POLLING_PAYMENT_INTENT,
    pollingPaymentIntent,
  };
}

export function stopPollingPaymentIntentStatus() {
  return {
    type: STOP_POLLING_PAYMENT_INTENT,
  };
}
