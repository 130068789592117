import {
  faFacebookF,
  faInstagram,
  faPinterest,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'clsx';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Collapse from 'react-bootstrap/lib/Collapse';
import Image from 'next/image';
import withI18next from '~/../hocs/_withI18next';
import ChinaWrap from '~/components/ChinaWrap';
import Container from '~/components/Container';
import DropdownBottom from '~/components/DropdownBottom';
import DropdownMenu from '~/components/DropdownMenu';
import DropdownMenuLanguage from '~/components/DropdownMenuLanguage';

import I18nLink from '~/components/I18nLink';
import Config, { keyConfig } from '~/config';
import themeVariables from '~/shared/themeVariables';
import { locales } from '~/utils';
import { AI_SERVICES, SERVICE_TYPES, USER_ROLES } from '~/utils/constants';
import IconGlobe from '~/components/svg/icon-globe.svg';
// TODO: Replace font awesome icons by these icons
import IconArrowDown from '~/components/svg/icon-arrow-down.svg';
import IconArrowUp from '~/components/svg/icon-arrow-up.svg';
import IconFacebook from '~/components/svg/icon-facebook.svg';
import IconTwitter from '~/components/svg/icon-twitter.svg';

const LOGO_BY_SERVICE_TYPE = {
  [SERVICE_TYPES.PHOTO_AC]: `${Config.staticPrefix}/static/assets/image/logo/photoAC_logo_grey.svg`,
  [SERVICE_TYPES.ILLUST_AC]: `${Config.staticPrefix}/static/assets/image/logo/illustAC_logo_grey.svg`,
  [SERVICE_TYPES.SILHOUETTE_AC]: `${Config.staticPrefix}/static/assets/image/logo/silhouetteAC_logo_grey.svg`,
  [SERVICE_TYPES.DESIGN_AC]: `${Config.staticPrefix}/static/assets/image/logo/templateAC_logo_grey.svg`,
};

// TODO: Fix action button to be full width
const renderAIProjectsDropdown = (label) => {
  const allServices = AI_SERVICES;

  return (
    <DropdownBottom
      style={{
        color: themeVariables.color.dark,
        marginRight: themeVariables.space.base,
        zIndex: 999,
      }}
      header={label}
      // header={'AI projects'}
    >
      <div className="menu-ai-projects">
        {allServices.map((item, index) => (
          <a
            key={index}
            className="menu-item"
            href={item.href}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="lazyload img-logo"
              data-src={item.logoUrl}
              alt="site logo"
            />
            {item.serviceName}
          </a>
        ))}
      </div>
      <style jsx>
        {`
          .menu-ai-projects {
            min-width: 350px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            color: ${themeVariables.color.dark};
            font-weight: normal;
            text-transform: none;
          }
          .img-logo {
            height: 20px;
            margin-right: ${themeVariables.space.extraSmall}px;
          }
          .menu-item {
            height: 28px;
            font-weight: 500;
            color: ${themeVariables.color.dark};
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: ${themeVariables.space.small}px;
          }
          .menu-item:hover {
            color: ${themeVariables.colors.primary};
          }
        `}
      </style>
      <style jsx global>
        {`
          .dropdown-bottom .dropdown-bottom-menu {
            left: -90px;
          }
        `}
      </style>
    </DropdownBottom>
  );
};

const ColumnMenuContent = withI18next()(({ t, lng }) => (
  <>
    <div className="menu-title">{t('footer_content')}</div>
    <div className="menu-items-container">
      {!Config.isDesignAC() && (
        <I18nLink language={lng} href={'/collections'}>
          <a className="menu-item">{t('collections')}</a>
        </I18nLink>
      )}
      <I18nLink language={lng} href={'/categories'}>
        <a className="menu-item">{t('categories')}</a>
      </I18nLink>
      <I18nLink language={lng} href={`/${keyConfig.get('route_popular_page')}`}>
        <a className="menu-item">{t('footer_popular_content')}</a>
      </I18nLink>
      <I18nLink language={lng} href={`/${keyConfig.get('route_new_page')}`}>
        <a className="menu-item">
          {t(Config.getPrefixContent('footer_new_page'))}
        </a>
      </I18nLink>
      {/* <I18nLink language={lng} href={'/tags'}>
        <a className="menu-item">{t('footer_search_trends')}</a>
      </I18nLink> */}
      <a
        className="menu-item"
        href={Config.renderLinkBlog(lng)}
        target="_blank"
        rel="noreferrer"
      >
        {t('blog')}
      </a>
    </div>
  </>
));

const ColumnMenuSupport = withI18next()(({ t, lng }) => (
  <>
    <div className="menu-title">{t('footer_support')}</div>
    <div className="menu-items-container">
      <I18nLink language={lng} href={'/help-page'}>
        <a className="menu-item">{t('footer_faq_help_page')}</a>
      </I18nLink>
      <I18nLink language={lng} href={'/inquiry'}>
        <a className="menu-item">{t('footer_contact')}</a>
      </I18nLink>
    </div>
  </>
));

const ColumnMenuInformation = withI18next()(({ t, lng, profile }) => (
  <>
    <div className="menu-title">{t('footer_information')}</div>
    <div className="menu-items-container">
      <I18nLink language={lng} href={'/about-us'}>
        <a className="menu-item">{t('footer_about_us')}</a>
      </I18nLink>
      {profile?.role !== USER_ROLES.PREMIUM &&
        profile?.role !== USER_ROLES.CREATOR && (
          <I18nLink language={lng} href={'/premium'}>
            <a className="menu-item">{t('footer_plan_pricing')}</a>
          </I18nLink>
        )}
      <I18nLink language={lng} href={'/extra-license'}>
        <a className="menu-item">{t('footer_extra_licenses')}</a>
      </I18nLink>
      {/* {lng === 'tw' && (
        <I18nLink language={lng} href={'/sell-your-content'}>
          <a className="menu-item">
            {t('sell_your_content')}{' '}
            <span className="new-label">{t('new')}</span>
          </a>
        </I18nLink>
      )} */}
    </div>
    <style jsx>
      {`
        .new-label {
          color: white;
          width: 32px;
          height: 14px;
          background: #ee1818;
          font-size: 12px;
          margin-left: 6px;
          display: inline-table;
          text-align: center;
          text-transform: uppercase;
        }
      `}
    </style>
  </>
));

const ColumnMenuLegal = withI18next()(({ t, lng }) => (
  <>
    <div className="menu-title">{t('footer_legal')}</div>
    <div className="menu-items-container">
      <I18nLink language={lng} href={'/terms'}>
        <a className="menu-item" rel="nofollow">
          {t('footer_terms_conditions')}
        </a>
      </I18nLink>
      {/* <I18nLink language={lng} href={'/'}>
        <a className="menu-item">{t('footer_extra_licenses')}</a>
      </I18nLink> */}
      <I18nLink language={lng} href={'/privacy-policy'}>
        <a className="menu-item" rel="nofollow">
          {t('footer_privacy_policy')}
        </a>
      </I18nLink>
    </div>
  </>
));

const ColumnMenuOurOtherServices = withI18next()(({ t, lng }) => (
  <>
    <div className="menu-title">{t('footer_our_other_services')}</div>
    <div className="menu-items-container">
      <a
        className="menu-item"
        href="https://www.design-ac.net/en"
        target="_blank"
        rel="noreferrer"
      >
        {t('footer_editorac_design_tool')}
      </a>
      <a
        className="menu-item"
        href="https://www.freebie-ac.jp/index.php?lang=en"
        target="_blank"
        rel="noreferrer"
      >
        freebieAC
      </a>
      <a
        className="menu-item"
        href="https://ac-data.info/en"
        target="_blank"
        rel="noreferrer"
      >
        {t('footer_acdata_transfer')}
      </a>
      <I18nLink language={lng} href={'/ai-projects'}>
        <a className="menu-item" rel="nofollow" target="_blank">
          {t('artificial_intelligence_projects')}
        </a>
      </I18nLink>
      {/* {renderAIProjectsDropdown(t('artificial_intelligence_projects'))} */}
    </div>
  </>
));
const ColumnMenuOurFreeStockSites = withI18next()(({ t, lng }) => (
  <>
    <div className="menu-title">{t('footer_our_free_stock_sites')}</div>
    <div className="menu-items-container">
      <a
        className="menu-item"
        href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
        target="_blank"
        rel="noreferrer"
      >
        photoAC
      </a>
      <a
        className="menu-item"
        href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
        target="_blank"
        rel="noreferrer"
      >
        illustAC
      </a>
      <a
        className="menu-item"
        href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
        target="_blank"
        rel="noreferrer"
      >
        silhouetteAC
      </a>
      <a
        className="menu-item"
        href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
        target="_blank"
        rel="noreferrer"
      >
        templateAC
      </a>
      <a
        className="menu-item"
        href="https://video-ac.com/en"
        target="_blank"
        rel="noreferrer"
      >
        videoAC
      </a>
    </div>
  </>
));
const getSSORedirectURL = (lng) => {
  let redirectURL = window.location.href;
  if (
    redirectURL.includes('creator-registration') ||
    redirectURL.includes('login')
  ) {
    redirectURL = Config.getSiteURL();
  }
  return redirectURL;
};
const handleSignUpSSO = (lng) => {
  window.open(
    `${Config.ssoServerUrl}/signup?lang=${lng}&serviceURL=${encodeURIComponent(
      getSSORedirectURL(lng),
    )}`,
    '_blank',
  );
};

const FooterActionsDesktop = ({ t, lng, profile, isAuthenticated }) => {
  if (!isAuthenticated) {
    return (
      <>
        <p className="register">{t('footer_register_once_and_get_access')}</p>
        <a
          className="footer-action-wrapper"
          onClick={() => handleSignUpSSO(lng)}
        >
          {t('downloader_signup')}
        </a>
      </>
    );
  }

  if (profile.role === USER_ROLES.FREE_MEMBER) {
    return (
      <div className="column-menu column-menu-actions">
        <p className="register">{t('download_unlimited_more_benefits')}</p>
        <I18nLink href="/premium">
          <a className="footer-action-wrapper">{t('upgrade_premium')}</a>
        </I18nLink>
      </div>
    );
  }

  // Keep the remaining space
  return <div className="column-menu column-menu-actions"></div>;
};

const FooterMenuDesktop = withRouter(
  withI18next()(({ t, lng, router, profile, isAuthenticated }) => {
    const currentYear = new Date().getFullYear();

    return (
      <div className="footer-desktop">
        <div className="footer-desktop-content">
          <div className="column-menu">
            <div className="footer-first-row">
              <ColumnMenuContent />
            </div>
            <div className="footer-second-row">
              <ColumnMenuSupport />
            </div>
          </div>
          <div className="column-menu">
            <div className="footer-first-row">
              <ColumnMenuInformation profile={profile} />
            </div>
            <div className="footer-second-row">
              <ColumnMenuLegal />
            </div>
          </div>
          <div className="column-menu">
            <div className="footer-first-row">
              <ColumnMenuOurFreeStockSites />
            </div>
            <div className="footer-second-row">
              {!isAuthenticated && (
                <ChinaWrap nonChina={<DropdownMenuLanguage />} />
              )}
            </div>
          </div>
          <div className="column-menu">
            <div className="footer-first-row">
              <ColumnMenuOurOtherServices />
            </div>
            <div className="footer-second-row">
              {isAuthenticated ? (
                <ChinaWrap nonChina={<DropdownMenuLanguage />} />
              ) : (
                <FooterActionsDesktop
                  t={t}
                  lng={lng}
                  profile={profile}
                  isAuthenticated={isAuthenticated}
                />
              )}
            </div>
          </div>
        </div>
        <div className="footer-divider" />
        <div className="bottom-content">
          <div className="copyright">
            <img
              src={LOGO_BY_SERVICE_TYPE[Config.serviceType]}
              className="img-logo"
              alt="logo"
              width={126}
              height={28}
            />
            &copy; 2011 - {currentYear} ACworks Co.,Ltd.{' '}
            {t('all_rights_reserved')}
          </div>
          {/* <Payments /> */}
          <div className="sns-icon-container">
            {/* <a
              className="sns-link icon-twitter"
              href="https://twitter.com/en_ACworks"
              target="_blank"
              rel="noreferrer"
            >
              <IconTwitter />
            </a> */}
            <a
              className="sns-link icon-facebook"
              href="https://www.facebook.com/enACworks/"
              target="_blank"
              rel="noreferrer"
            >
              <IconFacebook />
            </a>
          </div>
        </div>
        <style jsx>
          {`
            .footer-desktop {
              display: none;
            }

            .footer-desktop-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .bottom-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
            .copyright {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .img-logo {
              width: 120px;
              margin-right: 16px;
            }

            .sns-icon-container {
              display: flex;
            }

            .sns-link {
              width: 32px;
              height: 32px;
              border-radius: 9999px;
              overflow: hidden;
              margin: 0 4px;
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            }

            .sns-link.icon-twitter {
              background: #55acee;
            }

            .sns-link.icon-facebook {
              background: #1877f2;
            }

            @media (min-width: 905px) {
              .footer-desktop {
                width: 100%;
                display: block;
                margin: 48px 0;
              }
            }
          `}
        </style>
      </div>
    );
  }),
);

const FooterActionsMobile = ({ t, lng, profile, isAuthenticated }) => {
  if (!isAuthenticated) {
    return (
      <div className="submenu submenu-actions-mobile">
        <p className="register">{t('footer_register_once_and_get_access')}</p>
        <a
          className="footer-action-wrapper"
          onClick={() => handleSignUpSSO(lng)}
        >
          {t('downloader_signup')}
        </a>
      </div>
    );
  }

  if (profile.role === USER_ROLES.FREE_MEMBER) {
    return (
      <div className="submenu submenu-actions-mobile">
        <p className="register">{t('download_unlimited_more_benefits')}</p>
        <I18nLink href="/premium">
          <a className="footer-action-wrapper">{t('upgrade_premium')}</a>
        </I18nLink>
      </div>
    );
  }

  return null;
};

const FooterMenuMobile = withRouter(
  withI18next()(({ t, lng, profile, isAuthenticated }) => {
    const [openContent, setOpenContent] = useState(false);
    const [openInformation, setOpenInformation] = useState(false);
    const [openLegal, setOpenLegal] = useState(false);
    const [openFreeStockSites, setOpenFreeStockSites] = useState(false);
    const [openOtherServices, setOpenOtherServices] = useState(false);
    const [openForCreators, setOpenForCreators] = useState(false);
    const [openSupport, setOpenSupport] = useState(false);

    const currentYear = new Date().getFullYear();

    return (
      <div className="footer-mobile">
        <div className="nav-submenu">
          <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenContent(!openContent)}
                aria-controls="Content"
                aria-expanded={openContent}
                className="submenu-item-title"
              >
                {t('footer_content')}{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenContent(!openContent)}
                  fixedWidth
                  icon={openContent ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openContent} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data">
                  {!Config.isDesignAC() && (
                    <I18nLink language={lng} href={'/collections'}>
                      <a className="menu-item-content">{t('collections')}</a>
                    </I18nLink>
                  )}
                  <I18nLink language={lng} href={'/categories'}>
                    <a className="menu-item-content">{t('categories')}</a>
                  </I18nLink>
                  <I18nLink
                    language={lng}
                    href={`/${keyConfig.get('route_popular_page')}`}
                  >
                    <a className="menu-item-content">
                      {t('footer_popular_content')}
                    </a>
                  </I18nLink>
                  <I18nLink
                    language={lng}
                    href={`/${keyConfig.get('route_new_page')}`}
                  >
                    <a className="menu-item">
                      {t(Config.getPrefixContent('footer_new_page'))}
                    </a>
                  </I18nLink>
                  {/* <I18nLink language={lng} href={'/tags'}>
                    <a className="menu-item-content">
                      {t('footer_search_trends')}
                    </a>
                  </I18nLink> */}
                  <a
                    className="menu-item-content"
                    href={
                      lng === 'tw'
                        ? 'http://tw.blog.acworks.co.jp/'
                        : 'http://en.blog.acworks.co.jp/'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('blog')}
                  </a>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenInformation(!openInformation)}
                aria-controls="Content"
                aria-expanded={openInformation}
                className="submenu-item-title"
              >
                {t('footer_information')}{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenInformation(!openInformation)}
                  fixedWidth
                  icon={openInformation ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openInformation} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data">
                  <I18nLink language={lng} href={'/premium'}>
                    <a className="menu-item-content">
                      {t('footer_plan_pricing')}
                    </a>
                  </I18nLink>
                  <I18nLink language={lng} href={'/about-us'}>
                    <a className="menu-item-content">{t('footer_about_us')}</a>
                  </I18nLink>
                  <I18nLink language={lng} href={'/extra-license'}>
                    <a className="menu-item-content">
                      {t('footer_extra_licenses')}
                    </a>
                  </I18nLink>
                  {/* {lng === 'tw' && (
                    <I18nLink language={lng} href={'/sell-your-content'}>
                      <a className="menu-item-content">
                        {t('sell_your_content')}{' '}
                        <span className="new-label">{t('new')}</span>
                      </a>
                    </I18nLink>
                  )} */}
                </div>
              </div>
            </Collapse>
          </div>

          <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenOtherServices(!openOtherServices)}
                aria-controls="Content"
                aria-expanded={openOtherServices}
                className="submenu-item-title"
              >
                {t('footer_our_free_stock_sites')}{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenOtherServices(!openOtherServices)}
                  fixedWidth
                  icon={openOtherServices ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openOtherServices} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data">
                  <a
                    className="menu-item-content"
                    href={Config.getSiteURL(SERVICE_TYPES.PHOTO_AC)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    photoAC
                  </a>
                  <a
                    className="menu-item-content"
                    href={Config.getSiteURL(SERVICE_TYPES.ILLUST_AC)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    illustAC
                  </a>
                  <a
                    className="menu-item-content"
                    href={Config.getSiteURL(SERVICE_TYPES.SILHOUETTE_AC)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    silhouetteAC
                  </a>
                  <a
                    className="menu-item-content"
                    href={Config.getSiteURL(SERVICE_TYPES.DESIGN_AC)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    templateAC
                  </a>
                  <a
                    className="menu-item-content"
                    href="https://video-ac.com/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    videoAC
                  </a>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenFreeStockSites(!openFreeStockSites)}
                aria-controls="Content"
                aria-expanded={openFreeStockSites}
                className="submenu-item-title"
              >
                {t('footer_our_other_services')}{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenFreeStockSites(!openFreeStockSites)}
                  fixedWidth
                  icon={openFreeStockSites ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openFreeStockSites} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data">
                  <a
                    className="menu-item-content"
                    href="https://www.design-ac.net/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('footer_editorac_design_tool')}
                  </a>
                  <a
                    className="menu-item-content"
                    href="https://www.freebie-ac.jp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    freebieAC
                  </a>
                  <a
                    className="menu-item-content"
                    href="https://ac-data.info/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('footer_acdata_transfer')}
                  </a>
                  {/* {renderAIProjectsDropdown(
                    t('artificial_intelligence_projects'),
                  )} */}

                  <I18nLink language={lng} href={'/ai-projects'}>
                    <a
                      className="menu-item-content"
                      rel="nofollow"
                      target="_blank"
                    >
                      {t('artificial_intelligence_projects')}
                    </a>
                  </I18nLink>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenLegal(!openLegal)}
                aria-controls="Content"
                aria-expanded={openLegal}
                className="submenu-item-title"
              >
                {t('footer_legal')}{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenLegal(!openLegal)}
                  fixedWidth
                  icon={openLegal ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openLegal} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data">
                  <I18nLink language={lng} href={'/terms'}>
                    <a className="menu-item-content" rel="nofollow">
                      {t('footer_terms_conditions')}
                    </a>
                  </I18nLink>
                  <I18nLink language={lng} href={'/privacy-policy'}>
                    <a className="menu-item-content" rel="nofollow">
                      {t('footer_privacy_policy')}
                    </a>
                  </I18nLink>
                </div>
              </div>
            </Collapse>
          </div>
          {/* <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenForCreators(!openForCreators)}
                aria-controls="Content"
                aria-expanded={openForCreators}
                className="submenu-item-title"
              >
                For Creators{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenForCreators(!openForCreators)}
                  fixedWidth
                  icon={openForCreators ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openForCreators} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data"></div>
              </div>
            </Collapse>
          </div> */}

          <div className="submenu">
            <div className="submenu-item">
              <a
                onClick={() => setOpenSupport(!openSupport)}
                aria-controls="Content"
                aria-expanded={openSupport}
                className="submenu-item-title"
              >
                {t('footer_support')}{' '}
              </a>{' '}
              <i>
                <FontAwesomeIcon
                  onClick={() => setOpenSupport(!openSupport)}
                  fixedWidth
                  icon={openSupport ? faAngleDown : faAngleUp}
                ></FontAwesomeIcon>
              </i>
            </div>
            <Collapse in={openSupport} className="submenu-item-collapse">
              <div id="Content">
                <div className="submenu-item-data">
                  <I18nLink language={lng} href={'/help-page'}>
                    <a className="menu-item-content">
                      {t('footer_faq_help_page')}
                    </a>
                  </I18nLink>

                  <I18nLink language={lng} href={'/inquiry'}>
                    <a className="menu-item-content">{t('footer_contact')}</a>
                  </I18nLink>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="submenu submenu-language-mobile">
            <ChinaWrap nonChina={<DropdownMenuLanguage />} />
          </div>
          <FooterActionsMobile
            t={t}
            lng={lng}
            profile={profile}
            isAuthenticated={isAuthenticated}
          />
          <div className="footer-divider" />
          <div className="bottom-content">
            <div className="copyright">
              <img
                src={LOGO_BY_SERVICE_TYPE[Config.serviceType]}
                className="img-logo"
                alt="logo"
              />
              &copy; 2011 - {currentYear} ACworks Co.,Ltd.{' '}
              {t('all_rights_reserved')}
            </div>
            <Payments />
          </div>
        </div>
        <style jsx>
          {`
            .name-language {
              margin-left: 8px;
            }
            .icon-dropdown-language {
              margin-left: 40px;
            }

            .submenu-language-mobile {
              display: flex;
              justify-content: center;
              padding: 16px;
            }
            .footer-mobile {
              display: block;
              width: 100%;
              margin: 32px 0;
            }
            .submenu-item {
              height: 56px;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .submenu-item-title {
              font-weight: 600;
              font-size: 16px;
              text-transform: uppercase;
              color: #1b2833;
            }
            .nav-submenu {
              background: #ffffff;
              color: #383c40;
              z-index: 100;
              text-align: left;
              cursor: initial;
            }
            .nav-submenu .submenu-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .nav-submenu .submenu-item-data {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              color: black;
            }
            .menu-item-content {
              color: ${themeVariables.colors.bodyText};
              height: 28px;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .menu-item-content:hover {
              color: ${themeVariables.colors.primary};
            }
            .nav-submenu .submenu-item .submenu-item-title {
              width: 330px;
              display: flex;
              flex-direction: row;
              align-items: center;
              text-transform: uppercase;
              color: #1b2833;
            }

            .new-label {
              color: white;
              width: 32px;
              height: 14px;
              background: #ee1818;
              font-size: 12px;
              margin-left: 6px;
              display: inline-table;
              text-align: center;
              text-transform: uppercase;
            }

            .bottom-content {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .copyright {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
            .img-logo {
              width: 120px;
              margin-bottom: 32px;
            }

            @media (min-width: 905px) {
              .footer-mobile {
                display: none;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .submenu-actions-mobile {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
            }
          `}
        </style>
      </div>
    );
  }),
);

const SocialLink = ({ name, href, icon }) => (
  <a
    className={`icon-social ${name}`}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {icon}
  </a>
);

const SocialNetworks = withI18next()(({ lng }) => {
  if (Config.isChina(lng)) {
    return <div />;
  }
  const data = Config.renderLink(lng);
  const socialLinks = [
    {
      name: 'facebook',
      link: data.link_fb,
      icon: <FontAwesomeIcon icon={faFacebookF} style={{ width: '1em' }} />,
    },
    {
      name: 'twitter',
      link: data.link_twitter,
      icon: <FontAwesomeIcon icon={faTwitter} style={{ width: '1em' }} />,
    },
    {
      name: 'pinterest',
      link: 'https://www.pinterest.jp/photo_ac',
      icon: <FontAwesomeIcon icon={faPinterest} style={{ width: '1em' }} />,
    },
    {
      name: 'instagram',
      link: 'https://www.instagram.com/en_photo_ac',
      icon: <FontAwesomeIcon icon={faInstagram} style={{ width: '1em' }} />,
    },
  ];

  return (
    <div className="footer-ac-social">
      {socialLinks.map((item) => (
        <SocialLink
          key={item.link}
          name={item.name}
          href={item.link}
          icon={item.icon}
        />
      ))}
    </div>
  );
});

const PAYMENT_METHODS = Config.isChina()
  ? [
      `${Config.staticPrefix}/static/assets/image/payment/method-alipay.png`,
      `${Config.staticPrefix}/static/assets/image/payment/method-wechat.png`,
    ]
  : [
      `${Config.staticPrefix}/static/assets/image/payment/method-visa.png`,
      `${Config.staticPrefix}/static/assets/image/payment/method-amex.png`,
      `${Config.staticPrefix}/static/assets/image/payment/method-mastercard.png`,
      `${Config.staticPrefix}/static/assets/image/payment/method-maestro.png`,
      `${Config.staticPrefix}/static/assets/image/payment/method-jcb.png`,
    ];

const PAYMENT_METHOD_CLASSES = Config.isChina()
  ? ['method-alipay', 'method-wechat']
  : [
      'method-visa',
      'method-amex',
      'method-mastercard',
      'method-maestro',
      'method-jcb',
    ];
const Payments = withI18next()(({ t }) => (
  <div className="payments">
    {t('secure_payment_methods')}
    <div className="payment-methods">
      {PAYMENT_METHODS.map((methodURL, idx) => (
        // <Image
        //   className={`lazyload payment-image ${PAYMENT_METHOD_CLASSES[idx]}`}
        //   src={methodURL}
        //   alt="Payment method"
        //   layout="fixed"
        //   objectFit="contain"
        //   width={'36px'}
        //   height={'25px'}
        //   key={methodURL}
        // />
        <img
          width={'36px'}
          height={'25px'}
          key={methodURL}
          data-src={methodURL}
          className={`lazyload payment-image ${PAYMENT_METHOD_CLASSES[idx]}`}
          alt="Payment method"
        />
      ))}
    </div>
    <style jsx>
      {`
        .payments {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          margin-top: 24px;
        }

        .payment-methods {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 8px;
        }

        .payment-image {
          height: 25px;
          margin-left: 8px;
        }
        .payment-image.visa-blank {
          height: 15px;
        }
        .payment-image.jcb {
          height: 30px;
        }
        .payment-image.mastercard,
        .payment-image.maestro,
        .payment-image.mastercard-debit {
          height: 35px;
        }
        .payment-image.american {
          height: 40px;
        }

        @media (min-width: 905px) {
          .payments {
            width: fit-content;
            margin-top: 0;
            align-items: flex-end;
          }
        }
      `}
    </style>
  </div>
));

const Footer = (props) => {
  return (
    <Container className="ac-footer">
      <div className="footer-content">
        <FooterMenuDesktop {...props} />
        <FooterMenuMobile {...props} />
      </div>
      <style jsx global>
        {`
          .ac-footer {
            z-index: 10;
            position: relative;
            background: #ffffff;
          }
          .ac-footer .footer-content {
            display: flex;
            width: 100%;
          }
          .footer-first-row,
          .footer-second-row {
            width: fit-content;
          }
          .footer-first-row {
            height: 200px;
          }
          .footer-second-row {
            margin-top: 32px;
          }

          .ac-footer .column-menu {
            display: flex;
            flex-direction: column;
            width: fit-content;
          }
          .column-menu-language,
          .column-menu-actions {
            justify-content: flex-end;
          }

          .ac-footer .menu-title {
            color: ${themeVariables.colors.bodyText};
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
          }
          .ac-footer .menu-items-container {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
          }
          .ac-footer .menu-item {
            color: ${themeVariables.colors.bodyText};
            height: 28px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .ac-footer .menu-item:hover {
            color: ${themeVariables.colors.primary};
          }
          .ac-footer .submenu-wrapper-bottom a.dropdown-item {
            color: ${themeVariables.colors.bodyText};
          }
          .ac-footer .footer-ac-social {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
          }
          .ac-footer .footer-ac-social .icon-social {
            width: 40px;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 17px;
            border-radius: 50%;
            cursor: pointer;
            margin-left: 7px;
          }
          .ac-footer .footer-ac-social .icon-social:hover {
            opacity: 0.8;
          }
          .ac-footer .footer-ac-social .icon-social.facebook {
            background: #3b5998;
          }
          .ac-footer .footer-ac-social .icon-social.twitter {
            background: #1da1f2;
          }
          .ac-footer .footer-ac-social .icon-social.pinterest {
            background: #e60023;
          }
          .ac-footer .footer-ac-social .icon-social.instagram {
            background: #c32aa3;
          }

          /* TODO: Fix to match design in mobile */
          .footer-action-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 44px;
            max-width: 248px;
            background: #1b2833;
            border-radius: ${Config.isIllustAC() ? '40px' : '4px'};
            align-items: center;
            justify-content: space-evenly;
            color: #f8f9fa;
          }
          .footer-action-wrapper:hover {
            background: ${themeVariables.colors.primary};
            color: #f8f9fa;
          }

          .footer-divider {
            width: 100%;
            border-top: 0.5px solid #c4c4c4;
            margin: 26px 0;
          }

          .lazyload:not([src]) {
            visibility: hidden;
          }

          .register {
            margin-bottom: 8px;
            text-align: center;
          }

          @media (min-width: 905px) {
            .register {
              color: ${themeVariables.colors.bodyText};
              margin-bottom: 8px;
              text-align: left;
              max-width: 178px;
            }
          }
        `}
      </style>
    </Container>
  );
};

Footer.propTypes = {
  currentLang: PropTypes.string,
  serviceType: PropTypes.string,
};
Footer.defaultProps = {
  currentLang: '',
};
export default Footer;
