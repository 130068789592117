import axios from './interceptor-axios';
import config from '~/config';

function logBehavior1(params) {
  let url;

  if (config.serviceType === 'illust_ac') {
    url = `${config.apiUrl.apiLogBehaviorIllust}/learn`;
  } else if (config.serviceType === 'photo_ac') {
    url = `${config.apiUrl.apiLogBehaviorPhoto}/learn`;
  }

  const request = {
    method: 'POST',
    url,
    data: {
      userId: params.userId,
      itemId: params.dataId,
      action: params.action,
      timestamp: Date.now(),
      price: 1,
    },
    headers: {
      'Content-Type': 'text/plain; charset=UTF-8',
    },
  };

  return axios(request);
}

function logBehavior(params) {
  let url;
  if (config.serviceType === 'illust_ac') {
    url = `${config.apiUrl.apiLogBehaviorIllust}/learn`;
  } else if (config.serviceType === 'photo_ac') {
    url = `${config.apiUrl.apiLogBehaviorPhoto}/learn`;
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify({
      userId: `${params.userId}_global`,
      itemId: `${params.dataId}`,
      action: params.action,
      timestamp: Date.now(),
      price: 1,
    }),
  });
}

export { logBehavior };
