import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import moment from 'moment';
import {
  showRegisteredUsersModal,
  loadLatestRegisteredUsers,
} from '../actions';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';

const ROLES = {
  FREE: 1,
  PREMIUM: 2,
};

let iconPremium = (
  <img
    src={`${Config.staticPrefix}/static/assets/image/premium/crown.png`}
    className="icon-premium"
    alt="crown"
  />
);
iconPremium = ReactDOMServer.renderToString(iconPremium);

@withI18next()
class LatestRegisteredUser extends React.Component {
  handleClickToast = () => {
    const { showRegisteredUsersModal, loadLatestRegisteredUsers } = this.props;
    loadLatestRegisteredUsers();
    showRegisteredUsersModal();
  };

  render() {
    const { t, log, showRegisteredUsersModal } = this.props;
    const { registered_role_id: roleId, registered_at: registeredAt } = log;
    let description = null;
    const descriptionKey =
      roleId === ROLES.PREMIUM ? 'premium_user_added' : 'free_user_added';
    if (roleId === ROLES.FREE) {
      description = t('free_user_added');
    } else if (roleId === ROLES.PREMIUM) {
      description = t('premium_user_added');
    }
    return (
      <div className="latest-registered-user" onClick={this.handleClickToast}>
        <div className="time">
          <FontAwesomeIcon
            style={{ width: 16, marginRight: 8, marginBottom: 4 }}
            icon={faUserClock}
          />
          <div className="time-string">
            {moment().format('DD MMM, YYYY hh:mm A')}
          </div>
        </div>
        <div
          className="main-description"
          dangerouslySetInnerHTML={{
            __html: t(descriptionKey, {
              icon: iconPremium,
            }),
          }}
        />
        <style jsx>
          {`
            .latest-registered-user {
              width: 100%;
            }
            .latest-registered-user .time {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              font-size: 12px;
            }
            .latest-registered-user .main-description {
              text-align: left;
              margin-top: 7px;
              font-size: 16px;
            }
            .latest-registered-user .icon-premium {
              margin-bottom: 3px;
              margin-top: -3px;
            }
            .latest-registered-user .time-string {
              font-size: 15px;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showRegisteredUsersModal: () => dispatch(showRegisteredUsersModal()),
  loadLatestRegisteredUsers: () => dispatch(loadLatestRegisteredUsers()),
});
export default connect(null, mapDispatchToProps)(LatestRegisteredUser);
