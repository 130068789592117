import { faCamera, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'clsx';
import React from 'react';
import I18nLink from '~/components/I18nLink';
import Config from '~/config';
import { Avatar } from '~/lib';
import themeVariables from '~/shared/themeVariables';
import { urls } from '~/utils';
import { USER_ROLES } from '~/utils/constants';
import IconCrown from '~/components/svg/icon-crown.svg';
import FontIcomoonIcon from '~/components/FontIcomoonIcon';
import Constant from '~/../hocs/constant';

class MobileView extends React.Component {
  state = {
    statusDropdownMenu: false,
  };

  renderUserIcon = () => {
    const { profile } = this.props;
    if (profile.role === USER_ROLES.PREMIUM) {
      return (
        <IconCrown
          style={{ height: 16, marginRight: themeVariables.space.small }}
        />
      );
    }
    if (profile.role === USER_ROLES.CREATOR) {
      return (
        <FontAwesomeIcon
          size="lg"
          style={{ width: '1em', marginRight: themeVariables.space.small }}
          icon={faCamera}
        />
      );
    }
    return null;
  };

  // Refactor nickname component
  renderNickname = () => {
    const { profile, onToggleMenu } = this.props;
    return (
      <div
        className="nickname-wrapper"
        onClick={() => {
          onToggleMenu();
          this.setState({
            statusDropdownMenu: !this.state.statusDropdownMenu,
          });
        }}
      >
        {/* {this.renderUserIcon()} */}
        {/* <span className="nickname">
          {profile.email || profile.nickname || ''}
        </span> */}
        <Avatar
          style={{ marginLeft: themeVariables.space.extraSmall }}
          src={urls.renderAvatarUrl(profile.avatar)}
          width={32}
          height={32}
        />
        <span className="icon-dropdown-container">
          <img
            className={`icon-dropdown ${this.state.statusDropdownMenu}`}
            src={`${Config.staticPrefix}/static/assets/svg/chevron-up.svg`}
            alt="dropdown icon"
            width={16}
          />
        </span>
        {/* <style jsx>
          {`
            .nickname-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .nickname {
              width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .icon-dropdown-container {
              margin-left: 12px;
            }
            .icon-dropdown {
              transform: rotate(180deg);
            }
            .icon-dropdown.true {
              transform: rotate(0deg);
            }
          `}
        </style> */}
      </div>
    );
  };

  renderMenuItem = (menuItem) => {
    const { t, lng, profile } = this.props;

    if (menuItem.routeName === 'profile') {
      return (
        <a
          key={menuItem.path}
          className="menu-item"
          href={`${menuItem.path}?lang=${lng}&serviceURL=${encodeURIComponent(
            process.browser ? window.location.href : '',
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          {menuItem.icon && (
            <FontIcomoonIcon
              style={{
                fontSize: 22,
                color: themeVariables.color.primary,
                marginRight: themeVariables.space.small,
              }}
              icon={menuItem.icon}
              fixedWidth
            />
          )}
          {t(menuItem.label)}
          <style jsx>
            {`
              .menu-item {
                padding: 0 16px;
                color: ${themeVariables.color.dark};
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                text-transform: uppercase;
                height: 44px;
              }
              .menu-item:hover {
                background: ${themeVariables.color.gray};
              }
            `}
          </style>
        </a>
      );
    } else {
      return (
        <div key={menuItem.routeName}>
          <I18nLink href={menuItem.path}>
            <a className={cn('menu-item', { disabled: menuItem.lock })}>
              {menuItem.icon && (
                <FontIcomoonIcon
                  style={{
                    fontSize: 22,
                    color: themeVariables.color.primary,
                    marginRight: themeVariables.space.small,
                  }}
                  icon={menuItem.icon}
                  fixedWidth
                />
              )}
              {/* <div className="item-title">{t(menuItem.label)}</div> */}
              {t(menuItem.label)}
              {menuItem.lock && (
                <FontAwesomeIcon
                  icon={faLock}
                  style={{ width: '1em', color: themeVariables.color.primary }}
                />
              )}
            </a>
          </I18nLink>
          <style jsx>
            {`
              .menu-item {
                padding: 0 16px;
                color: ${themeVariables.color.dark};
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                text-transform: uppercase;
                height: 44px;
              }
              .menu-item:hover {
                background: ${themeVariables.color.gray};
              }
              .menu-item.disabled {
                pointer-events: none;
                cursor: default;
                color: #ccc;
              }

              .item-title {
                flex: 1;
              }
            `}
          </style>
        </div>
      );
    }
  };

  renderMembership = () => {
    const { t, profile } = this.props;

    let membership = null;
    if (profile.role === USER_ROLES.CREATOR) {
      membership = t('creator_user');
    } else if (profile.role === USER_ROLES.FREE_MEMBER) {
      membership = t('free_user');
    } else if (profile.role === USER_ROLES.PREMIUM) {
      if (profile.plan === 'MONTHLY') {
        membership = t('premium_user_monthly');
      } else if (profile.plan === 'ONE_YEAR') {
        membership = t('premium_user_yearly');
      } else {
        membership = t('premium_user');
      }
    }

    return (
      <div className="membership">
        {this.renderUserIcon()}
        {membership}
        <style jsx>
          {`
            .membership {
              padding: 0 16px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              height: 44px;
            }
          `}
        </style>
      </div>
    );
  };

  renderMenu = () => {
    if (!process.browser) return null;

    const { t, menu, profile, logout, lng, isMenuOpen } = this.props;
    if (!isMenuOpen) return null;

    let dashboardLink = Constant.routersPathName.NOTIFICATION.path;
    if (profile.role === USER_ROLES.CREATOR) {
      dashboardLink = Constant.routersPathName.PHOTO_LIST.path;
    }

    return (
      <div className="menu-user">
        <div className="username">
          {profile.email || profile.nickname || ''}
        </div>
        {this.renderMembership()}
        <div className="menu-item dashboard">
          <I18nLink href={dashboardLink}>
            <a className="dashboard-link">
              {t('go_to_dashboard')}
              <img
                src={`${Config.staticPrefix}/static/assets/svg/next-page.svg`}
                className="icon-premium"
                alt="icon dashboard"
              />
            </a>
          </I18nLink>
        </div>
        {profile.role === USER_ROLES.FREE_MEMBER && (
          <I18nLink href="/premium">
            <a className="menu-item">
              <IconCrown
                style={{ height: 16, marginRight: themeVariables.space.small }}
              />
              {t('upgrade_now')}
            </a>
          </I18nLink>
        )}
        {menu.map((item) => this.renderMenuItem(item))}
        <a className="menu-item" onClick={logout}>
          {t('Logout')}
        </a>
        <style jsx>
          {`
            .menu-user {
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              width: 100%;
              display: flex;
              flex-direction: column;
              background: ${themeVariables.color.white};
              max-height: calc(100vh - 100%);
            }

            .username {
              padding: 0 16px;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              height: 44px;
            }

            .menu-item {
              padding: 0 16px;
              color: ${themeVariables.color.dark};
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              height: 44px;
              text-transform: uppercase;
            }
            .menu-item:hover {
              background: ${themeVariables.color.gray};
            }
            .menu-item.dashboard:hover {
              background: none;
            }

            .icon-premium {
              height: 16px;
            }
            .dashboard-link {
              background: #1b2833;
              border-radius: 4px;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px;
              color: #f8f9fa;
            }
            .dashboard-link:hover {
              background: ${themeVariables.color.primary};
            }
          `}
        </style>
      </div>
    );
  };

  render() {
    return (
      <div className="mobile-view">
        {this.renderNickname()}
        {this.renderMenu()}
        <style jsx>
          {`
            .mobile-view {
              height: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            @media (min-width: 768px) {
              .mobile-view {
                display: none;
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            .nickname-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .nickname {
              width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .icon-dropdown-container {
              margin-left: 12px;
            }
            .icon-dropdown {
              transform: rotate(180deg);
            }
            .icon-dropdown.true {
              transform: rotate(0deg);
            }
          `}
        </style>
      </div>
    );
  }
}

export default MobileView;
